import React, { useState, useEffect, useRef } from 'react';
// import { Link } from 'react-router-dom';    
import './Services.scss';
import anime from 'animejs/lib/anime.es.js';
import ReactHtmlParser from 'react-html-parser';
import ServicesBackground from './ServicesBackground';
// import data from '../../translations/es.json';

/*
const servicesInfo = {
  healthcare: {
    heading:  'Healthcare',
    copy: <>
      <div>
        We provide numerous tactics and tools to make sure your health is our #1 priority.
        Services include:
      </div>
      <div className="bullet-points">
        ● Hospice Care<br />
        ● Medication Assistance<br />
        ● Dementia and Alzheimers<br />
        ● Incontinence Care<br />
        ● Respite Care
      </div>
    </>,
  },
  waiverProgram: {
    heading: 'Waiver\nProgram',
    copy: <>
      <div>
        We accept a majority of state and local Medicaid waivers,&nbsp;including:
      </div>
      <div className="bullet-points">
        ● Aging Waiver<br />
        ● Attendant Care<br />
        ● Act 150<br /> 
        ● Independence<br />
        ● COMMCARE<br />
        ● OBRA
      </div>
    </>,
  },
  occupationalTherapy: {
    heading: 'Occupational\nTherapy',
    copy: <>
      <div>
        We're your #1 resource! Through our skilled care department, we can pair you with a
        therapist who best suits your needs,&nbsp;specializing&nbsp;in:
      </div>
      <div className="bullet-points">
        ● Occupational Therapy<br />
        ● Speech Pathology<br />
        ● Physical Therapy
      </div>
    </>,
  },
  nursingHomeTransition: {
    heading: 'Nursing Home\nTransition',
    copy: <>
      Looking to gain back your independence? We have the capabilities to help move you or a loved one
      back to a community of your choice with proper assistance&nbsp;and&nbsp;care.
    </>
  },
  personalAssistance: {
    heading: 'Personal\nAssistance',
    copy: <>
      <div>
        We offer a range of&nbsp;options:
      </div>
      <div className="bullet-points">
        ● Personal Care<br />
        ● Housekeeping<br />
        ● Laundry<br />
        ● Meal Preparation<br />
        ● Companionship<br />
        ● Dressing<br />
        ● Shopping
      </div>
    </>
  },
  military: {
    heading: 'Military',
    copy: <>
      Giving the opportunity to have “veterans caring for&nbsp;veterans.”
    </>
  },
  hoyerLift: {
    heading: 'Hoyer Lift',
    copy: <>
      If your care requires the use of a Hoyer lift, we will be sure to match you with a caregiver who has
      received proper training in&nbsp;using&nbsp;one.
    </>
  } 
}
*/
const ServicesForeground = ({services}) => { // Otherwise resets React Three Fiber canvas when hovering or clicking

  const servicesCircleRef = useRef();

  const drawSVG = (container) => {
    var pathEls = container.querySelectorAll('path');
    for (var i = 0; i < pathEls.length; i++) {
      var pathEl = pathEls[i];
      var offset = anime.setDashoffset(pathEl);
      pathEl.setAttribute('stroke-dashoffset', offset);
      anime({
        targets: pathEl,
        strokeDashoffset: [anime.setDashoffset, 0],
        duration: 1500,
      // duration: anime.random(1000, 3000),
      // delay: anime.random(0, 2000),
        easing: 'easeInOutSine',
        autoplay: true,
        begin: function(anim) {
          document.querySelector('path').setAttribute("stroke", "black");
          document.querySelector('path').setAttribute("fill", "none");
        },
      });
    }
  }
  
  useEffect(() => {
    drawSVG(servicesCircleRef.current);
  }, []);

  const [service, setService] = useState(null);
  const [serviceHeadingClass, setServiceHeadingClass] = useState('');
  const [nextService, setNextService] = useState(null);
  const [currentInfo, setCurrentInfo] = useState();
  const [shrink, setShrink] = useState(false);
  const [unclickable, setUnclickable] = useState(false);

  const goToService = (newService) => {
    setUnclickable(true);
    if (service && service!==newService) {
      setShrink(true);
      setNextService(newService);
    } else if (service!==newService) {
      setCurrentInfo(services[newService]);
      setService(newService);
    } else {
      setShrink(true);
    }
    if (!service) setServiceHeadingClass('roll-1');
  }

  const handleServicesAnimationEnd = (e) => {
    setUnclickable(false);
    if (e.animationName==='services-circle-container-shrink') {
      setCurrentInfo(services[nextService]);
      setService(nextService);
      setNextService(null);
      setShrink(false);
      // setServiceHeadingClass(nextService ? 'roll-1' : '');
    }
  }

  const handleServicesInfoHeadingAnimationEnd = (e) => {
    if (e.animationName==='services-info-heading-enlarge-1') {
      setServiceHeadingClass('roll-2');
    } else if (e.animationName==='services-info-heading-shrink-2') {
      setServiceHeadingClass('roll-1');
    } else if (!nextService && e.animationName==='services-info-heading-shrink-1') {
      setServiceHeadingClass('');
    } 
  }

  const [hoverService, setHoverService] = useState('');

  const handleMouseEnterService = (value) => {
    if (value!==hoverService) setHoverService(value);
  }

  const handleMouseLeaveService = () => {
    setHoverService('');
  }

  return (
    <div 
      id="services-circle-container" 
      className={`services enter ${(service || '') && (shrink===true ? 'enlarge shrink' : 'enlarge')} ${unclickable ? 'unclickable' : ''}`}
      onAnimationEnd={(e) => handleServicesAnimationEnd(e)}
    >
      <div id="services-circle-background"></div>
      
      <div ref={servicesCircleRef} id="services-circle">

        <div 
          className={`services services-1 ${(service==='personalAssistance' || nextService==='personalAssistance') ? 'selected' : ''} ${hoverService==='personalAssistance' ? 'hover' : ''}`} 
          onMouseLeave={handleMouseLeaveService}
        > 
          <div className="spiral-in">
            <div className="scaling">
              <div id="personal-assistance">
                <svg 
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" y="0px"
                  viewBox="-100 -100 700 700"
                >
                  <g>
                    <path id="caregiver-body" d="M309.32,312.06c0,1.43,0,2.67,0,3.91c0,42,0.01,83.99-0.01,125.99
                      c-0.01,12.58-8.72,21.84-21.22,22.66c-0.7,0.05-1.42-0.14-2.53-0.26c3.13-5.66,2.39-11.63,2.39-17.5
                      c0.03-30.92,0.01-61.85,0.01-92.77c0-13.02-5.23-19.01-18.2-20.62c2.99-10.01,5.97-19.98,8.96-29.95c1.59-5.29,3-10.64,4.86-15.83
                      c3.13-8.76,2.04-17.27-0.95-25.73c-5.59-15.82-14.41-29.8-24.72-42.9c-1.18-1.5-1.68-2.77-1.32-4.74
                      c1.9-10.22,3.61-20.47,5.52-31.51c-4.72,9.08-4.66,19.77-13.5,26.06c-8.87-7.79-18.52-13.24-30.8-12.17
                      c1.28-4.26,2.79-8.13,3.56-12.14c0.78-4.05,0.82-8.25,1.21-12.69c4.29,2.34,4.85-0.95,5.9-3.88c5.15-14.43,10.16-28.91,15.68-43.2
                      c3.32-8.59,8.75-15.89,16.29-21.41c6.36-4.65,13.58-6.81,21.41-6.83c21.31-0.04,42.62-0.09,63.94,0.01
                      c14.84,0.07,25.87,7.34,33.01,19.79c4.52,7.88,7.77,16.58,10.9,25.16c13.35,36.62,26.33,73.38,39.68,110
                      c3.95,10.85-1.63,21.34-10.93,24.49c-9.6,3.25-19.54-1.68-23.16-11.6c-8.18-22.42-16.28-44.87-24.4-67.31
                      c-1.55-4.29-3.06-8.6-5.1-12.81c0.45,2.74,0.88,5.48,1.36,8.22c6.15,35.36,12.29,70.73,18.46,106.09c1.41,8.11-2.81,13.23-11,13.23
                      c-4.06,0-8.12,0-12.65,0c0,1.39,0,2.59,0,3.8c0,42.1,0.01,84.2,0,126.3c0,12.12-7.67,20.86-19.62,22.42
                      c-10.98,1.44-21.86-6.7-23.61-17.71c-0.31-1.95-0.46-3.94-0.46-5.91c-0.03-41.58-0.02-83.16-0.02-124.74c0-1.24,0-2.48,0-3.94
                      C315.3,312.06,312.54,312.06,309.32,312.06z M335.53,161.81c3.65,0,7.3,0,10.95,0c6.69,0,6.98-0.31,6.89-7.13
                      c0-0.31,0.01-0.63,0-0.94c-0.06-2.55-1.4-3.84-3.92-3.85c-9.28-0.02-18.56-0.01-27.84-0.01c-2.57,0-3.8,1.42-3.98,3.86
                      c-0.09,1.25-0.07,2.5-0.02,3.75c0.14,3.16,1.25,4.26,4.47,4.3C326.56,161.85,331.04,161.81,335.53,161.81z"/>
                    <path id="patient-body" d="M220.91,238.15c0,20.32,0.02,40.17-0.01,60.01c-0.01,5.74-0.06,11.48-0.26,17.22
                      c-0.07,2.13-0.62,4.25-0.92,6.17c-5.17,0-10.06-0.77-14.61,0.15c-11.67,2.35-19.76,11.58-21.73,23.52
                      c-1.03,6.27-2.19,12.52-3.34,19.07c-3.42,0-6.75,0.13-10.06-0.1c-0.69-0.05-1.7-1.32-1.87-2.16c-1.08-5.32-2.02-10.66-2.91-16.02
                      c-2.67-16.18-14.62-25.92-31.47-25.1c-0.35-2.14-1.01-4.35-1.02-6.56c-0.08-24.03-0.05-48.06-0.05-72.09c0-1.14,0-2.27,0-3.41
                      c-0.21-0.15-0.42-0.29-0.63-0.44c-2.32,2.68-4.8,5.25-6.94,8.07c-6.4,8.41-11.97,17.34-15.75,27.26c-0.51,1.32-0.59,3.08-0.19,4.43
                      c4.42,15.11,8.98,30.18,13.5,45.26c2.45,8.17-1.43,15.98-9.09,18.31c-7.82,2.38-15.42-1.61-17.93-9.77
                      c-5.22-16.93-10.53-33.84-15.14-50.94c-1.09-4.04-0.47-9.1,0.92-13.17c6.74-19.75,17.58-37.07,32.79-51.47
                      c6.17-5.84,13.43-9.11,22.17-8.27c1.24,0.12,2.52,0.13,3.76-0.01c3.09-0.34,5.43,0.69,7.87,2.74
                      c17.09,14.32,41.42,14.09,58.32-0.47c1.97-1.7,3.9-2.37,6.45-2.3c6.85,0.19,13.78-0.65,20.08,3.28
                      c7.04,4.39,12.66,10.22,17.61,16.73c9.39,12.35,17.53,25.41,22.17,40.38c1.61,5.19,1.68,10.29-0.01,15.64
                      c-5.02,15.91-9.65,31.94-14.5,47.91c-1.97,6.5-7.68,10.67-14,10.42c-6.49-0.25-12.05-4.48-13.29-10.8
                      c-0.54-2.75-0.37-5.89,0.41-8.59c4.3-14.92,8.91-29.75,13.34-44.63c0.36-1.22,0.6-2.78,0.16-3.89
                      c-5.31-13.31-12.73-25.31-22.67-35.69C221.86,238.66,221.57,238.55,220.91,238.15z"/>
                    <path id="patient-right-leg" d="M191.99,471.22c-10.96-0.68-18.27-9.87-16.36-20.72
                      c6.12-34.83,12.28-69.65,18.45-104.47c1.89-10.65,12.38-17,22.61-13.55c1.21,0.41,2.25,1.81,3,2.99c2.29,3.56,3.98,7.59,6.71,10.74
                      c1.87,2.17,2.51,3.95,2.02,6.6c-2.4,13.14-4.72,26.29-7.05,39.44c-3.69,20.85-7.42,41.68-11.03,62.54
                      C208.54,465.21,201.32,471.79,191.99,471.22z"/>
                    <path id="caregiver-head" d="M313.81,9.87c20.86,0,37.67,16.79,37.58,37.53c-0.1,20.85-16.84,37.46-37.74,37.44
                      c-20.71-0.02-37.59-17.03-37.45-37.75C276.35,26.43,293.09,9.87,313.81,9.87z"/>
                    <path id="patient-left-leg" d="M167.71,466.56c-5.04,4.39-10.7,5.81-16.94,3.95c-6.38-1.9-10.71-6.18-11.96-12.8
                      c-2.85-15.06-5.44-30.17-8.12-45.26c-3.45-19.41-6.85-38.82-10.34-58.22c-0.32-1.76-0.15-2.71,1.62-3.7
                      c6.53-3.64,10.63-9.29,12.36-16.53c0.43-1.8,1.08-2.33,2.87-2.36c8.29-0.15,15.63,5.57,17.15,14.01
                      c4.85,26.89,9.57,53.81,14.24,80.73c0.29,1.67-0.15,3.51-0.45,5.24c-0.89,5.24-1.79,10.49-2.83,15.7
                      C163.97,453.97,164.62,460.36,167.71,466.56z"/>
                    <path id="patient-head" d="M211.2,176.25c0,18.95-15.27,34.12-34.31,34.09c-19.29-0.04-34.26-15.28-34.3-34.8
                      c-0.03-16.36,12.76-33.81,34.54-33.98C196.17,141.4,211.2,156.98,211.2,176.25z"/>
                    <path id="wheelchair-left" d="M116.28,460.12c3.58,0,6.71-0.08,9.82,0.05c0.88,0.04,2.16,0.48,2.52,1.14
                      c1.13,2.09,1.74,4.47,2.89,6.55c1.13,2.05,2.67,3.87,4.33,6.22c-6.71,0-12.99,0-19.56,0c0,1.56,0.03,2.79-0.01,4.02
                      c-0.2,6.47-5.01,11.53-11.12,11.7c-6.97,0.2-12.12-4.28-12.7-11.06c-0.04-0.52-0.03-1.04-0.03-1.56c0-42.29,0-84.58,0-126.86
                      c0-0.61,0-1.23,0-2.26c0.96,0.57,1.59,1.05,2.29,1.35c3.44,1.44,6.81,3.64,10.37,4.02c3.87,0.41,4.86,1.88,5.4,5.35
                      c1.68,10.82,3.75,21.58,5.62,32.37c0.19,1.12,0.15,2.29,0.15,3.43c0.01,20.57,0.01,41.14,0.01,61.71
                      C116.28,457.43,116.28,458.56,116.28,460.12z"/>
                    <path id="wheelchair-right" d="M231.21,474.1c-6.31,0-12.22,0-17.78,0c2.17-4.39,4.24-8.72,6.5-12.96
                      c0.31-0.58,1.51-0.93,2.31-0.97c2.89-0.11,5.79-0.04,8.98-0.04c0-1.54,0-2.67,0-3.8c0-14.94,0.29-29.89-0.1-44.82
                      c-0.28-10.85,1.27-21.41,3.25-31.99c1.46-7.79,2.79-15.61,4.19-23.41c0.14-0.8,0.37-1.58,0.43-1.84
                      c5.47-0.58,10.59-1.12,15.92-1.68c0.02,0.26,0.14,1.05,0.15,1.83c0.01,40.75,0.02,81.49,0,122.24c0,8.16-4.74,13.22-12.2,13.15
                      c-6.4-0.06-11.3-4.89-11.64-11.52C231.15,477.04,231.21,475.79,231.21,474.1z"/>
                    <path id="wheel-left" d="M68.76,403.01c0-17.23-0.01-34.46,0.01-51.69c0.01-4.82,2.77-8.02,7.08-8.37
                      c4.46-0.36,8.03,2.8,8.28,7.46c0.12,2.29,0.02,4.59,0.02,6.89c0,32.47,0.01,64.95-0.01,97.42c0,6.62-5.54,10.62-11.02,7.97
                      c-3.32-1.6-4.38-4.5-4.38-8C68.77,437.47,68.76,420.24,68.76,403.01z"/>
                    <path id="wheel-right" d="M278.7,403.3c0,17.33,0.03,34.66-0.02,51.99c-0.02,6.21-5.59,9.96-10.92,7.42
                      c-2.91-1.39-4.31-3.77-4.3-7.02c0.03-9.4,0.01-18.79,0.01-28.19c0-25.26,0-50.53,0-75.79c0-5.48,2.85-8.79,7.53-8.78
                      c4.69,0,7.69,3.36,7.69,8.7C278.71,368.85,278.7,386.07,278.7,403.3z"/>
                    <path id="tag" className="st0" d="M335.53,161.81c-4.48,0-8.97,0.04-13.45-0.01c-3.21-0.04-4.32-1.13-4.47-4.3
                      c-0.06-1.25-0.07-2.51,0.02-3.75c0.18-2.43,1.4-3.86,3.98-3.86c9.28,0,18.56-0.01,27.84,0.01c2.52,0.01,3.86,1.29,3.92,3.85
                      c0.01,0.31-0.01,0.63,0,0.94c0.1,6.82-0.2,7.12-6.89,7.13C342.83,161.81,339.18,161.81,335.53,161.81z"/>
                  </g>
                </svg>
              </div>
            </div>
            <div className="shadow-circle"></div>
            <div 
              className="service-label" 
              onClick={()=>goToService('personalAssistance')}
            >
              {ReactHtmlParser(services.personalAssistance.heading.replace(/\\n/g,'<br />'))}
            </div>
          </div>
          <div 
            className="action" 
            onClick={()=>goToService('personalAssistance')}
            onMouseEnter={()=>handleMouseEnterService('personalAssistance')}
          >
          </div>
        </div>

        <div 
          className={`services services-2 ${(service==='healthcare' || nextService==='healthcare') ? 'selected' : ''} ${hoverService==='healthcare' ? 'hover' : ''}`}
          onMouseLeave={handleMouseLeaveService}
        > 
          <div className="spiral-in">
            <div className="scaling">
              <div id="healthcare">
                <svg 
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" y="0px"
                  viewBox="-100 -100 700 700"
                >
                  <g>
                    <path id="stethoscope" d="M180.2,351.19c-42.04-6.23-74.14-26.3-94.9-62.44c-4.16-7.24-4.38-16.81-6.13-25.36
                      c-2.51-12.3-4.21-24.8-7.21-36.97c-9.74-39.44-20.32-78.68-29.79-118.19c-6.19-25.84,12.99-56.66,39.88-64.99
                      c2.61,6.43,5.24,12.93,7.81,19.26c-26.33,20-28.59,28.54-19.81,60.75c10.68,39.22,18.16,79.48,31.53,117.72
                      c6.62,18.93,21.33,36.45,36.19,50.62c10.71,10.21,27.14,17.25,42.02,19.74c38.37,6.4,68.92-13.2,90.66-46.36
                      c25.44-38.81,26.05-84.56,41.05-126.11c2.44-6.77,3.59-13.99,5.53-20.95c5.58-19.97,4-37.55-14.99-50.43
                      c-2.06-1.4-2.45-6.8-1.92-10.07c0.69-4.32,3.05-8.36,4.7-12.53c25.1,4.35,44.75,38.62,37.76,67
                      c-11.34,46.03-23.19,91.94-34.84,137.89c-0.55,2.19-1.92,4.41-1.73,6.51c3.63,40.92-25.17,60.48-53.62,79.12
                      c-11.01,7.21-24.87,10.6-37.89,14.02c-7.11,1.87-9.91,4.11-9.76,11.52c1.04,52.39,27.1,88.79,77.77,98.41
                      c47.12,8.95,90.38-20.21,103.89-67.26c2.45-8.53,2.66-17.9,2.72-26.89c0.28-39.67-0.03-79.34,0.24-119.01
                      c0.06-8.91-1.29-14.29-11.32-18.28c-22.32-8.88-35.66-38.13-30.24-61.25c6.12-26.1,28.05-43.81,54.71-44.17
                      c27.09-0.36,48.04,16.77,55.11,45.08c5.65,22.62-6.46,48.09-28.29,59.41c-4.39,2.28-8.86,4.39-13.74,6.8
                      c0,45.7,0.15,91.28-0.04,136.86c-0.25,62.44-52.91,115.59-115.31,116.3c-68.4,0.78-122.11-56.11-120.06-117.93
                      C180.36,363.54,180.2,358.08,180.2,351.19z"/>
                    <path id="cross" d="M108.31,161.27c0.21-32.69-2.54-29.9,30.51-29.88c22.29,0.01,22.29,0,22.27-21.92
                      c-0.03-31.76-0.03-31.76,32.28-31.73c30.62,0.03,30.62,0.03,30.63,30.3c0.01,23.22,0.12,23.33,23.92,23.35
                      c29.76,0.03,29.53,0.03,29.94,29.84c0.51,36.74-0.75,33.14-33.8,33.08c-19.27-0.04-19.86,0.77-20.09,20.05
                      c-0.41,34.37,5.9,32.89-33.03,32.86c-29.79-0.02-29.79-0.02-29.84-29.74c-0.05-23.14-0.05-23.24-23.65-23.15
                      C106.09,194.44,108.3,196.15,108.31,161.27z"/>
                    <path id="left-earbud" d="M103.07,58.73c-2.21-2.62-7.7-6.42-8.8-11.21c-0.98-4.3,1.44-11.46,4.89-14.28
                      c8.34-6.83,18.24-11.76,27.35-17.68c6.85-4.45,12.03-2.78,15.84,3.89c2.23,3.91,4.33,8.01,5.72,12.28
                      c5.56,17.15,2.44,21.75-15.5,23.71C124.01,56.38,115.45,57.35,103.07,58.73z"/>
                    <path id="right-earbud" d="M281.52,58.71c-15.21-1.76-25.56-3.08-35.94-4.11c-8.74-0.87-11.86-5.65-10.29-13.92
                      c0.67-3.56,1.36-7.15,2.54-10.56c6.46-18.75,11.51-20.49,28.27-9.99c6.53,4.1,12.85,8.6,19.63,12.22
                      c6.96,3.71,9.34,9.07,5.52,15.57C288.36,52.81,283.53,56.55,281.52,58.71z"/>
                  </g>
                </svg>
              </div>  
            </div>
            <div className="shadow-circle"></div>
            <div 
              className="service-label" 
              onClick={()=>goToService('healthcare')}
            >
              {ReactHtmlParser(services.healthcare.heading.replace(/\\n/g,'<br />'))}
            </div>
          </div>
          <div 
            className="action" 
            onClick={()=>goToService('healthcare')}
            onMouseEnter={()=>handleMouseEnterService('healthcare')}
          >
          </div>
        </div>

        <div 
          className={`services services-3 ${(service==='nursingHomeTransition' || nextService==='nursingHomeTransition') ? 'selected' : ''} ${hoverService==='nursingHomeTransition' ? 'hover' : ''}`}
          onMouseLeave={handleMouseLeaveService}
        >  
          <div className="spiral-in">
            <div className="scaling">
              <div id="nursing-home">
                <svg 
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" y="0px"
                  viewBox="-100 -100 700 700"
                >
                  <g>
                    <path id="hand" d="M425.46,475.59c-9.23-1.51-18.16-3.11-27.14-4.41c-5.65-0.82-11.36-1.35-17.07-1.72
                      c-8.06-0.53-16.14-1.07-24.21-1.09c-10.35-0.03-20.4,2.27-30.45,4.62c-10.78,2.52-21.69,4.52-32.58,6.51
                      c-7.31,1.34-14.69,2.29-22.05,3.36c-2.1,0.3-4.23,0.52-6.35,0.59c-8.01,0.24-16.04,0.75-24.04,0.5
                      c-10.34-0.33-20.3-2.53-29.72-7.19c-17.59-8.7-33.82-19.52-49.66-31c-11.36-8.23-22.84-16.32-34.07-24.73
                      c-10.45-7.83-20.71-15.91-30.93-24.05c-4.93-3.93-7.63-9.31-8.17-15.58c-0.28-3.34,2.29-5.78,5.62-6.37
                      c5.78-1.04,11.33,0.2,16.87,1.49c14.47,3.38,27.84,9.54,41.03,16.23c10.45,5.31,21.24,9.87,32.97,11.64
                      c-1.68,6.48,0.5,10.21,7.18,14.15c5.21,3.08,10.92,3.82,16.65,4.18c8.46,0.53,16.96,0.56,25.44,0.61
                      c4.36,0.03,8.73-0.24,13.09-0.55c5.44-0.38,10.63,0.84,15.86,1.99c2.31,0.51,4.71,0.64,7.09,0.83c-9.01-2.83-17.86-6.04-27.63-5.13
                      c-8.69,0.8-17.45,1.27-26.17,1.08c-7.47-0.17-15.21-0.36-21.93-4.41c-8.6-5.18-9.6-12.03-3.48-19.58c5.2-6.41,12.62-9.07,20.7-9.53
                      c7.16-0.41,14.35-0.71,21.51-0.52c6.73,0.17,13.46,0.95,20.17,1.62c6.57,0.66,12.18-2.23,17.98-4.49
                      c13.82-5.38,27.84-10.22,42.47-12.83c7.96-1.42,16.06-2.21,24.13-2.85c6.77-0.54,13.63-1.02,20.38-0.51
                      c14.35,1.08,27.86,5.15,38.88,14.86c4.62,4.06,9.94,5.53,15.51,6.92c3.19,0.79,6.38,1.6,9.58,2.38c1.85,0.45,2.61,1.36,2.59,3.47
                      c-0.12,13.95-0.06,27.9-0.06,41.84c0,11.51,0,23.03,0,34.54C425.46,473.46,425.46,474.47,425.46,475.59z"/>
                    <path id="house-roof" d="M242.59,51.26c-7.45,8.11-14.74,16.05-22.04,23.97c-2.77,3.01-5.57,6-8.39,8.96
                      c-4.44,4.66-8.94,9.25-13.35,13.93c-8.09,8.6-16.14,17.24-24.2,25.88c-4.95,5.3-9.86,10.63-14.81,15.93
                      c-8.19,8.77-16.4,17.51-24.59,26.28c-4.95,5.3-9.89,10.6-14.8,15.93c-6.04,6.56-12.22,12.98-18,19.76
                      c-6.22,7.3-19.06,8.2-25.59,0.09c-3.35-4.15-3.25-10.94,0.71-15.17c6.68-7.14,13.46-14.18,20.15-21.3
                      c8.09-8.6,16.14-17.24,24.2-25.88c5.01-5.37,10-10.76,15.01-16.13c8.12-8.7,16.27-17.38,24.39-26.08
                      c5.08-5.43,10.13-10.89,15.21-16.33c8-8.56,16-17.12,24-25.68c5.27-5.64,10.53-11.29,15.8-16.92c5.59-5.98,11.21-11.93,16.79-17.92
                      c5.21-5.6,14.32-4.95,19.06-0.02c7.36,7.65,14.67,15.35,21.96,23.07c5.17,5.47,10.27,11.02,15.41,16.52
                      c7.93,8.5,15.87,16.99,23.8,25.48c5.2,5.57,10.4,11.15,15.6,16.73c7.93,8.5,15.87,16.99,23.8,25.48
                      c5.14,5.51,10.26,11.02,15.4,16.53c8,8.56,16,17.12,24,25.68c4.88,5.23,9.78,10.45,14.6,15.74c3.48,3.82,5.47,8.11,3.37,13.35
                      c-1.89,4.7-7.59,8.21-12.62,8.56c-7.34,0.51-12.45-2.69-16.68-8.39c-2.48-3.34-5.63-6.18-8.52-9.21
                      c-4.44-4.66-8.94-9.25-13.35-13.93c-8.09-8.6-16.14-17.24-24.2-25.88c-4.95-5.3-9.86-10.63-14.81-15.93
                      c-8.19-8.77-16.4-17.51-24.59-26.28c-4.88-5.23-9.73-10.5-14.61-15.73c-8.25-8.84-16.55-17.63-24.78-26.49
                      C251.48,61.12,247.14,56.27,242.59,51.26z"/>
                    <path id="person-body" d="M237.81,259.83c3.83,2.36,4.99,6.48,4.98,11.4c-0.08,24.15-0.02,48.29,0,72.44
                      c0,0.66-0.02,1.31,0,1.97c0.05,1.81-0.61,3.35-2.54,3.37c-2.01,0.03-2.44-1.54-2.43-3.36c0.05-9.27,0.02-18.53,0.02-27.8
                      c0-16,0.06-32.01-0.09-48.01c-0.02-1.77-0.79-3.98-2.01-5.21c-2.9-2.93-7.85-1.07-8.53,3.06c-0.33,1.98-0.84,3.75-3.17,3.18
                      c-2.23-0.54-2.13-2.52-1.66-4.38c0.84-3.31,2.27-5.1,5.94-7.4c-2.1-5.71-3.87-11.58-6.39-17.1c-2.18-4.77-5.22-9.14-8.24-13.32
                      c0.75,7.53,1.66,15.05,2.21,22.6c0.47,6.44,0.15,12.84-2.06,19.07c-0.73,2.05-0.6,4.44-0.68,6.67c-0.61,17.3-1.16,34.59-1.75,51.89
                      c-0.16,4.77-0.32,9.54-0.57,14.3c-0.2,3.89-3.83,7.16-7.69,7.08c-3.99-0.08-7.29-3.28-7.45-7.28c-0.57-14.49-1.13-28.98-1.7-43.46
                      c-0.04-1.08-0.17-2.15-0.84-3.27c-0.09,0.45-0.25,0.89-0.26,1.34c-0.42,13.46-0.81,26.93-1.23,40.39
                      c-0.05,1.68-0.15,3.37-0.32,5.04c-0.46,4.35-3.75,7.39-7.78,7.25c-3.97-0.14-7.28-3.47-7.47-7.68c-0.41-9.25-0.76-18.51-1.13-27.76
                      c-0.57-14.21-1-28.42-1.78-42.62c-0.21-3.84-1.67-7.6-2.17-11.45c-1.27-9.86,0.32-19.56,2-29.23c0.13-0.72,0.2-1.46-0.27-2.55
                      c-1.36,1.87-2.83,3.68-4.07,5.63c-3.92,6.18-6.57,12.91-8.41,19.98c-0.73,2.82-2.21,5.24-5.1,6.25c-3.13,1.1-6.92-1.57-7.12-5.26
                      c-1.12-20.52,10.48-46.97,34.27-54.26c2.55-0.78,5.01-1.94,7.6-2.54c1.93-0.45,4.12-0.74,6-0.28c3.77,0.92,7.47,2.22,11.07,3.67
                      c14.61,5.89,23.59,17.07,28.41,31.62c1.91,5.77,2.57,11.98,3.38,18.05C239.09,254.21,238.23,256.72,237.81,259.83z"/>
                    <path id="house-wall-left-floor" d="M296.11,372.27c-7.2,2.6-14.4,5.2-21.6,7.8c-2.15,0.78-4.4,1.34-6.42,2.37
                      c-6.05,3.09-12.35,3.23-18.89,2.23c-3.28-0.5-6.65-0.48-9.99-0.62c-5.48-0.22-10.99-0.77-16.45-0.47
                      c-6.32,0.34-12.6,1.45-18.9,2.24c-0.99,0.12-1.98,0.45-2.97,0.45c-17.31,0.03-34.63,0.04-51.94-0.01c-1.25,0-2.63-0.23-3.72-0.79
                      c-10.87-5.6-22.23-9.88-34.14-12.67c-1.36-0.32-1.96-0.96-1.93-2.48c0.09-5.52,0.04-11.04,0.04-16.56
                      c0-50.44-0.01-100.89,0.04-151.33c0-1.69,0.4-3.47,1.01-5.05c1.25-3.23,3.88-5.1,6.35-4.87c2.78,0.26,6.18,3.55,6.6,6.61
                      c0.33,2.37,0.57,4.78,0.57,7.17c0.03,51.94,0.02,103.88,0.02,155.83c0,6.44,3.17,9.58,9.66,9.58c53.16,0,106.32,0,159.48,0
                      c1.06,0,2.12,0,3.19,0C296.12,371.88,296.11,372.07,296.11,372.27z"/>
                    <path id="heart-top" d="M317.71,250.35c-2.06-5.94-4.09-11.78-6.34-18.27c-3.48,8.93-6.7,17.18-10.15,26.04
                      c-3.38-5.91-6.49-11.35-9.82-17.19c-1.67,2.83-3.03,5.47-4.72,7.87c-0.67,0.95-2.09,1.92-3.18,1.94
                      c-10.01,0.15-20.03,0.05-30.04,0.12c-1.56,0.01-2.32-0.55-2.94-1.94c-6-13.53-3.37-25.99,5.25-37.27
                      c5.04-6.6,12.35-7.49,20.07-5.33c9.51,2.67,17.13,8.48,24.42,14.83c0.55,0.48,1.13,0.95,1.28,1.08
                      c6.01-4.19,11.61-8.55,17.64-12.19c5.81-3.5,12.23-5.69,19.23-4.32c6.52,1.27,10.58,5.7,13.42,11.31
                      c5.16,10.2,6.81,20.68,1.65,31.44c-0.38,0.8-1.49,1.78-2.27,1.79C340.1,250.38,328.97,250.35,317.71,250.35z"/>
                    <path id="house-wall-right" d="M376.03,367.67c-4.96-1.05-9.6-2.04-14.61-3.1c0-0.76,0-1.76,0-2.76
                      c0-52.87,0-105.73,0-158.6c0-5.16,2.29-8.83,5.88-10.27c2.72-1.09,4.84,0.09,6.04,1.89c1.4,2.09,2.56,4.84,2.57,7.3
                      c0.14,54.18,0.11,108.35,0.11,162.53C376.03,365.64,376.03,366.64,376.03,367.67z"/>
                    <path id="heart-bottom" d="M301.86,267.12c3.15-8.08,6.08-15.58,9.28-23.76c1.42,4,2.64,7.4,3.89,10.91
                      c11.62,0,23.21,0,35.56,0c-1.95,2.7-3.46,5.08-5.26,7.22c-11.67,13.86-26.22,24.03-41.98,32.63c-0.73,0.4-2.06,0.43-2.78,0.04
                      c-17.07-9.52-32.99-20.48-44.86-36.41c-0.6-0.8-1.1-1.67-1.93-2.95c1.36-0.08,2.31-0.19,3.27-0.19c8.98-0.01,17.96-0.13,26.94,0.06
                      c2.89,0.06,4.83-0.61,5.83-3.43c0.27-0.76,0.85-1.4,1.54-2.5C294.88,254.88,298.21,260.73,301.86,267.12z"/>
                    <path id="house-chimney" d="M347.38,116.56c-8.09-8.54-15.69-16.52-23.24-24.56c-4.45-4.74-8.85-9.52-13.19-14.37
                      c-0.66-0.74-1.06-1.89-1.16-2.9c-0.18-1.85,0.1-3.76-0.12-5.6c-0.11-0.91-0.72-1.98-1.43-2.57c-2.42-1.99-3.35-6.6-1.97-10.8
                      c0.89-2.7,2.65-3.7,6.56-3.7c10.48,0,20.97,0.11,31.45-0.05c4.83-0.07,7.45,2.27,7.1,6.91c-0.24,3.19,0.2,6.34-3.39,8.15
                      c-0.54,0.27-0.6,1.83-0.6,2.8c-0.04,14.42-0.02,28.83-0.02,43.25C347.38,114.07,347.38,115.03,347.38,116.56z"/>
                    <path id="person-head" d="M177.04,179.32c0.14-9.18,7.5-16.41,16.49-16.22c9.35,0.2,16.32,7.42,16.22,16.8
                      c-0.1,8.93-7.61,16.06-16.79,15.95C184.08,195.74,176.9,188.29,177.04,179.32z"/>
                  </g>
                </svg>
              </div>
            </div>
            <div className="shadow-circle"></div>
            <div 
              className="service-label" 
              onClick={()=>goToService('nursingHomeTransition')}
            >
              {ReactHtmlParser(services.nursingHomeTransition.heading.replace(/\\n/g,'<br />'))}
            </div>
          </div>
          <div 
            className="action" 
            onClick={()=>goToService('nursingHomeTransition')}
            onMouseEnter={()=>handleMouseEnterService('nursingHomeTransition')}
          >       
          </div>
        </div>

        <div 
          className={`services services-4 ${(service==='hoyerLift' || nextService==='hoyerLift') ? 'selected' : ''} ${hoverService==='hoyerLift' ? 'hover' : ''}`} 
          onMouseLeave={handleMouseLeaveService}
        > 
          <div className="spiral-in">
            <div className="scaling">
              <div id="hoyer-lift">
                <svg           
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" y="0px"
                  viewBox="-100 -100 700 700" /* bigger for shadow, original viewBox="0 0 500 500" */
                >
                  <g>
                    <path id="lift" d="M340.31,354.1c-14.26-6.68-26.84-12.42-39.22-18.43c-7.25-3.52-12.68-5.4-18.85,2.23
                      c-17.03,21.07-35.15,41.57-52.87,62.28c-1.36,1.59-2.95,3.04-4.88,5.01c-18.81-12.75-37.26-25.27-56.48-38.3
                      c7.21-9.84,14.7-19.94,22.02-30.11c19.95-27.71,42.6-54.45,58.72-83.53c9.99-18.03,27.75-19.53,45.2-24.59
                      c21.67-6.28,40.57,2.82,60.11,7.85c8.22,2.12,16.06,5.3,24.39,6.96c6.94,1.39,6.96,3.8,4.96,8.35
                      c-11.88,26.95-23.58,53.95-35.17,80.99C345.45,339.34,343.29,346.04,340.31,354.1z"/>
                    <path id="seat" d="M238.23,485.95c-39.32,0-78.64,0.01-117.96-0.01c-13.39-0.01-20.08-4.69-20.04-13.9
                      c0.04-8.95,7.12-13.8,20.37-13.8c73.37,0,146.75-0.11,220.12,0.25c8.71,0.04,12.2-2.3,14.74-8.64
                      c29.56-73.97,59.42-147.86,89.2-221.77c0.22-0.54,0.39-1.09,0.65-1.61c2.94-5.99,4.88-13.19,15.94-11.56
                      c8.02,1.18,12.51,9.72,9.23,18.3c-7.42,19.39-15.32,38.65-23.13,57.94c-24.94,61.61-50.08,123.16-74.68,184.86
                      c-3.06,7.67-7.29,10.36-17.53,10.22c-38.96-0.54-77.94-0.22-116.91-0.22C238.23,486,238.23,485.97,238.23,485.95z"/>
                    <path id="arms" d="M391.16,239.98c-20.01-5.48-38.83-9.89-56.87-15.84c-13.51-4.46-26.66-4.23-40.09-1.75
                      c-21.08,3.89-40.12,10.83-56.69,22.67c-8.66,6.19-19.55,11.08-30.45,14.35c-15.35,4.62-27.02-0.28-32.73-12.51
                      c-2.64-5.66-3.54-11.93-4.42-18.01c-1.17-8.1,3.67-13.85,12-18.33c32.33-17.42,68.99-27.19,104.54-39.02
                      c12.76-4.24,24.25-4.48,37.02-0.25c24.26,8.03,48.91,15.31,73.52,22.65c8.17,2.44,10.65,5.75,7.33,12.76
                      C399.38,217.16,395.8,228.02,391.16,239.98z"/>
                    <path id="head" d="M384.05,35.18c21.28-0.15,35.07,8.01,45.59,20.04c22.35,25.55,21.09,63.02-2.42,87.91
                      c-21.91,23.2-60.38,23.27-82.42,0.15c-26.32-27.61-24.12-71.55,4.79-95.12C359.56,40.03,371.23,34.87,384.05,35.18z"/>
                    <path id="legs" d="M207.44,300.22c-12.98,16.7-25.15,32.36-37.88,48.73c-7.56-4.28-14.27-8.08-21.71-12.29
                      c-11.18,13.23-22,25.98-32.75,38.77c-10.99,13.08-21.84,26.23-32.9,39.27c-8.01,9.44-25.84,12.46-38.28,6.67
                      c-13.2-6.14-20.89-20.51-13.48-30.07c21.46-27.69,43.82-54.94,66.67-81.91c10.24-12.08,22.65-22.96,33.72-34.61
                      c4.25-4.47,7.43-5.99,14.5-2.57C165.1,281.77,185.53,290.43,207.44,300.22z"/>
                    <path id="rope" d="M282.32,162.2c-8.26-29.48-16.15-57.64-24.05-85.79c-1.09,0.03-2.19,0.06-3.28,0.09
                      c-0.17,2.43-0.68,4.89-0.47,7.3c2.03,23.64,4.04,47.29,6.35,70.92c1.73,17.66,0.23,19.38-23.5,22.8
                      c-0.71-10.42-1.61-20.73-2.07-31.06c-1.76-39.48-3.21-78.98-5.22-118.45c-0.31-5.99,1.79-8.85,9.1-9.77
                      c7.53-0.95,14.95-2.44,23.44-3.86c5.75,20.03,11.36,39.47,16.91,58.91c6.75,23.64,13.31,47.32,20.22,70.93
                      c3.81,13.04,4.76,11.22-9.63,15.67C287.93,160.56,285.72,161.19,282.32,162.2z"/>
                    <path id="butt" d="M238.17,402.8c16.99-20.62,32.33-39.42,48.08-57.99c1.38-1.63,6.31-3.13,8.14-2.39
                      c13.34,5.42,26.31,11.43,42.15,18.47c-16.31,18.99-31.35,37.45-47.72,55.13c-7.21,7.78-15.57,7.88-25.96,2.87
                      c-3.58-1.72-6.86-3.88-10.09-6.01C247.93,409.68,243.26,406.33,238.17,402.8z"/>
                  </g>
                </svg>
              </div>
            </div>
            <div className="shadow-circle"></div>
            <div 
              className="service-label" 
              onClick={()=>goToService('hoyerLift')}
            >
              {ReactHtmlParser(services.hoyerLift.heading.replace(/\\n/g,'<br />'))}
            </div>
          </div>
          <div 
            className="action" 
            onClick={()=>goToService('hoyerLift')}
            onMouseEnter={()=>handleMouseEnterService('hoyerLift')}
          >
          </div>
        </div>

        <div 
          className={`services services-5 ${(service==='waiverProgram' || nextService==='waiverProgram') ? 'selected' : ''} ${hoverService==='waiverProgram' ? 'hover' : ''}`} 
          onMouseLeave={handleMouseLeaveService}
        > 
          <div className="spiral-in">
            <div className="scaling">
              <div id="waiver">
                <svg 
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" y="0px"
                  viewBox="-100 -100 700 700"
                >
                  <g>
                    <path id="paper-border" d="M335.59,445.55c0-5.63,0-10.7,0-15.76c0-32.71-0.08-65.42,0.14-98.13
                      c0.02-2.69,1.42-5.95,3.26-7.93c7.04-7.58,14.52-14.76,21.85-22.08c1.23-1.22,2.53-2.37,4.73-4.43c0.17,3.05,0.35,4.76,0.35,6.46
                      c0.02,51.25,0.04,102.5,0.03,153.74c0,13.48-5.26,18.66-18.81,18.66c-101.02,0-202.05,0-303.07,0c-11.98,0-17.08-5.16-17.08-17.27
                      c0-139.02,0-278.05,0-417.07c0-12.02,5.15-17.15,17.21-17.15c101.39,0,202.78,0,304.16,0c11.95,0,17.58,5.62,17.59,17.69
                      c0.03,24.9,0.11,49.8-0.14,74.69c-0.03,2.7-1.42,5.98-3.28,7.98c-7.04,7.59-14.52,14.77-21.86,22.08
                      c-1.21,1.21-2.57,2.28-4.94,4.36c0-32.94,0-64.41,0-96.25c-93.02,0-185.39,0-278.22,0c0,130.11,0,260.09,0,390.44
                      C150.14,445.55,242.52,445.55,335.59,445.55z"/>
                    <path id="pen" d="M473.98,134.98c-0.32,6.82-3.03,14.55-9.22,20.75c-55.7,55.78-111.44,111.52-167.14,167.29
                      c-5.64,5.64-12.19,9.46-19.9,11.65c-13.41,3.8-26.67,8.16-40.11,11.86c-2.59,0.71-6.56,0.43-8.34-1.15c-1.43-1.27-1.2-5.39-0.5-7.9
                      c3.77-13.42,8.03-26.69,11.9-40.08c2.22-7.67,6.02-14.2,11.7-19.87c45.89-45.79,91.69-91.67,137.51-137.53
                      c9.49-9.5,18.82-19.15,28.4-28.55c10.29-10.09,22.37-13.06,35.96-7.79C465.86,108.15,473.94,120.52,473.98,134.98z"/>
                    <path id="line-2" d="M196.07,173.85c-31.06,0-62.12,0.02-93.17-0.02c-8.33-0.01-13.35-4.06-13.73-10.82
                      c-0.34-6.17,3.25-10.99,9.32-12.14c2.29-0.44,4.69-0.44,7.05-0.44c60.48-0.02,120.95-0.02,181.43,0c2.17,0,4.38,0,6.51,0.33
                      c5.82,0.91,10,5.56,10.17,11.07c0.18,5.68-4.08,10.76-10.07,11.77c-1.95,0.33-3.98,0.24-5.98,0.24
                      C257.09,173.85,226.58,173.85,196.07,173.85z"/>
                    <path id="line-1" d="M196.61,112.87c-31.23,0-62.45-0.02-93.68,0.02c-4.6,0.01-8.71-0.97-11.47-4.92
                      c-2.59-3.7-3.07-7.69-0.81-11.79c2.45-4.44,6.35-6.17,11.28-6.17c26.69,0.01,53.37-0.03,80.06-0.03c35.95,0,71.89,0.01,107.84,0.05
                      c7.67,0.01,12.63,3.85,13.19,10.06c0.69,7.72-4.46,12.74-13.27,12.75c-27.23,0.05-54.46,0.03-81.69,0.04c-3.81,0-7.62,0-11.44,0
                      C196.61,112.88,196.61,112.87,196.61,112.87z"/>
                    <path id="line-3" d="M274.91,212.61c-6.9,6.89-13.72,13.88-20.8,20.59c-1.08,1.02-3.39,1.03-5.14,1.03
                      c-48.85,0.05-97.69,0.07-146.54,0.02c-8.52-0.01-13.36-4.47-13.26-11.73c0.09-6.88,5.14-11.37,13.14-11.37
                      c56.12-0.04,112.23-0.02,168.35-0.01c1.2,0,2.4,0.11,3.61,0.17C274.47,211.74,274.69,212.18,274.91,212.61z"/>
                    <path id="line-4" d="M218.11,271.95c-2.56,7.34-4.73,14.01-7.3,20.52c-0.47,1.19-2.61,2.43-3.99,2.43
                      c-35.4,0.13-70.8,0.15-106.2,0.05c-6.76-0.02-11.38-4.84-11.46-11.27c-0.09-6.72,4.45-11.63,11.57-11.69
                      c20.87-0.16,41.75-0.05,62.62-0.05C181.49,271.95,199.63,271.95,218.11,271.95z"/>
                    <path id="line-5" d="M197.69,332.67c0.59,7.71,1.14,15.02,1.74,22.8c-0.73,0.09-2.08,0.39-3.43,0.39
                      c-31.07,0.03-62.15,0.06-93.22,0c-8.66-0.02-13.79-4.71-13.62-12.1c0.12-5.29,3.23-9.33,8.41-10.66c1.74-0.45,3.58-0.7,5.38-0.7
                      c30.71-0.04,61.42-0.03,92.13-0.01C196.12,332.38,197.15,332.61,197.69,332.67z"/>
                  </g>
                </svg>
              </div>
            </div>
            <div className="shadow-circle"></div>
            <div 
              className="service-label" 
              onClick={()=>goToService('waiverProgram')}
            >
              {ReactHtmlParser(services.waiverProgram.heading.replace(/\\n/g,'<br />'))}
            </div>
          </div>
          <div 
            className="action" 
            onClick={()=>goToService('waiverProgram')}
            onMouseEnter={()=>handleMouseEnterService('waiverProgram')}
          >
          </div>
        </div>

        <div 
          className={`services services-6 ${(service==='occupationalTherapy' || nextService==='occupationalTherapy') ? 'selected' : ''} ${hoverService==='occupationalTherapy' ? 'hover' : ''}`} 
          onMouseLeave={handleMouseLeaveService}
        > 
          <div className="spiral-in">
            <div className="scaling">
              <div id="therapy"> 
                <svg 
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" y="0px"
                  viewBox="-100 -100 700 700"
                >
                  <g>
                    <path id="wheel" d="M112.78,264.19c30.92-0.29,56.35,12.36,76.65,34.72
                      c17.56,19.35,25.82,43.41,25.01,69.21c-0.72,23.27-9.06,44.75-24.81,62.63c-20.26,23.02-45.86,35.64-76.46,35.41
                      c-56.47-0.42-101.02-44.81-101.25-101.27C11.69,309.94,57.31,263.97,112.78,264.19z M112.77,446.14
                      c45.41-0.42,78.78-35.6,81.02-76.41c2.63-47.99-35.67-84.94-79.49-85.49c-44.67-0.57-81.32,34.85-82.15,78.96
                      C31.28,408.84,67.32,445.56,112.77,446.14z"/>
                    <path id="table" d="M314.88,355.48c0,21.13,0.05,42.25-0.02,63.38c-0.03,9.3-0.3,18.59-0.43,27.89
                      c-0.04,3.2,0.4,6.46-0.06,9.6c-0.66,4.54-5.9,8.46-10.15,8.14c-4.74-0.35-9.53-5.25-9.53-10c-0.01-23.99,0.04-47.99,0.06-71.98
                      c0.01-5.41-0.05-10.82,0.02-16.23c0.07-5.72,0.37-11.43,0.39-17.15c0.06-19.28-0.02-38.55,0.09-57.83c0.01-2.62-0.68-3.46-3.37-3.41
                      c-8.51,0.17-17.02,0-25.52,0.08c-7.57,0.07-15.13,0.37-22.7,0.44c-2.94,0.03-5.88-0.46-8.81-0.43c-8.1,0.09-16.19,0.38-24.29,0.48
                      c-4.72,0.06-8.71-1.52-11-5.98c-2.81-5.47,0.89-13.05,7.04-13.63c6.53-0.62,13.14-0.61,19.71-0.62
                      c33.94-0.05,67.87-0.02,101.81-0.02c7.61,0,15.23-0.04,22.84-0.05c5.99-0.01,11.98,0.05,17.97-0.02c4.1-0.04,8.19-0.37,12.28-0.35
                      c6.29,0.03,12.59,0.2,18.88,0.4c5.48,0.17,9.76,6.75,8.2,12.2c-1.56,5.46-5.17,7.59-10.98,7.61c-26.19,0.06-52.39,0.06-78.58-0.05
                      c-2.78-0.01-3.78,0.51-3.76,3.58c0.14,21.32,0.08,42.64,0.08,63.96C315,355.48,314.94,355.48,314.88,355.48z"/>
                    <path id="patient-legs" d="M189.76,298.32c6.4-0.8,12.48-1.74,18.61-2.28c4.9-0.43,8.77,2.27,10.12,7.09
                      c3.9,13.92,7.65,27.88,11.36,41.85c4.88,18.37,9.75,36.74,14.44,55.15c0.76,2.96,2.05,3.42,4.8,2.98c4.79-0.76,9.63-1.33,14.47-1.57
                      c5.92-0.29,10.03,3.77,10.39,9.8c0.3,4.91-3.65,9.52-8.91,10.2c-8.87,1.15-17.75,2.23-26.64,3.3c-4.61,0.55-9.29-2.8-10.62-7.78
                      c-3.7-13.86-6.95-27.85-11.08-41.58c-2.26-7.49-2.12-15.09-3.11-22.64c-1.75-13.3-5.7-25.95-12.54-37.53
                      c-3.08-5.21-6.85-10.01-10.29-15.01C190.4,299.8,190.19,299.19,189.76,298.32z"/>
                    <path id="patient-caregiver" d="M439.92,274.61c-1.97,3.34-3.64,6.07-5.21,8.85c-13.76,24.31-27.51,48.63-41.2,72.98c-0.71,1.25-1.16,2.85-1.15,4.28
                      c0.16,24.28,0.4,48.56,0.67,72.84c0.09,8.31,0.35,16.63,0.42,24.94c0.06,6.56-3.9,10.83-10.49,11.16
                      c-8.87,0.45-17.76,0.76-26.64,0.97c-5.7,0.13-10.31-4.48-10.37-10.04c-0.06-5.32,4.41-9.97,9.91-10.25
                      c5.53-0.28,11.06-0.5,17.08-0.76c0-4.47,0.04-9.01-0.01-13.54c-0.22-21.13-0.47-42.26-0.7-63.38c-0.06-5.45-0.26-10.91-0.05-16.35
                      c0.09-2.2,0.7-4.59,1.77-6.5c16.65-29.59,33.43-59.11,50.17-88.65c5.45-9.62,10.95-19.22,16.25-28.93c0.58-1.06,0.41-2.99-0.16-4.13
                      c-6.25-12.34-12.65-24.59-19-36.88c-3.89-7.54-7.76-15.1-11.64-22.65c-0.34-0.65-0.72-1.28-1.46-2.61c-0.54,4.5-1,8.28-1.43,12.07
                      c-0.8,6.92-1.61,13.85-2.36,20.78c-0.58,5.39-3.28,8.74-8.53,10.61c-21.52,7.68-42.94,15.62-64.43,23.37
                      c-2.15,0.77-2.84,1.84-2.8,4.14c0.15,9.27,0.06,18.55,0.06,27.83c0,0.94,0,1.88,0,3.08c-34.6,0-68.97,0-103.69,0
                      c0-2.56,0-5.18,0-8.2c-5.6,1.16-10.78,2.25-15.97,3.3c-14.84,3-29.67,6.04-44.53,8.93c-4.79,0.93-8.47-1.24-10.8-5.56
                      c-11.61-21.46-23.27-42.9-34.93-64.35c-0.35-0.65-0.8-1.24-1.63-2.52c-1.1,5.45-2.09,10.22-3.04,15
                      c-2.76,13.92-5.89,27.79-8.06,41.8c-0.98,6.35-3.24,9.17-9.8,9.19c-3.69,0.01-7.38,1.61-11.08,2.45c-0.6,0.14-1.26,0.02-2.27,0.02
                      c1.24-6.35,2.42-12.5,3.64-18.64c5-25.22,10.01-50.43,15-75.65c1.98-10,3.82-20.02,5.93-29.99c0.39-1.86-0.21-2.46-1.56-3.32
                      c-11.76-7.42-19.17-17.98-21.79-31.61c-6.59-34.27,25.51-64.82,60.31-53.92c18.15,5.69,29.2,18.51,32.55,37.12
                      c5.12,28.45-16.53,54.4-45.17,55.31c-1.33,0.04-2.66,0.01-4.22,0.01c-1.26,6.04-2.53,11.98-3.67,17.95
                      c-0.13,0.66,0.36,1.69,0.89,2.19c18.2,17.2,36.43,34.35,54.66,51.51c0.07,0.06,0.17,0.08,0.66,0.3c4.22-2.8,8.65-5.74,13.07-8.67
                      c12.24-8.13,24.5-16.22,36.69-24.43c3.72-2.5,7.55-3.42,12.32-1.78c0-2.71,0-5.14,0-7.57c0-6.31,2.31-8.66,8.54-8.69
                      c0.85,0,1.69,0,2.7,0c0-11.37,0-22.51,0-33.66c0-6.98,2.1-8.99,9.06-8.96c9.94,0.05,19.89,0,29.83,0.02
                      c5.48,0.01,7.93,2.4,7.95,7.81c0.03,11.47,0.01,22.94,0.01,34.79c1.52,0,3.11-0.01,4.7,0c0.86,0.01,1.99-0.31,2.54,0.11
                      c4.84,3.76,10.08,1.36,15.07,0.92c9.59-0.84,19.13-2.14,28.68-3.34c1.04-0.13,2.2-0.67,2.98-1.38
                      c11.52-10.35,22.95-20.78,34.48-31.11c1.39-1.25,1.64-2.14,0.71-3.84c-2.73-5-5.22-10.12-7.45-14.49
                      c-6.19,1.76-11.73,3.99-17.47,4.84c-18.37,2.71-33.65-3.44-44.69-18.28c-11.7-15.72-13.13-33.12-3.98-50.39
                      c9.09-17.16,24.19-26.1,43.66-25.19c35.64,1.66,56.93,39.63,38.85,71.25c-1.13,1.98-1.02,3.29-0.04,5.18
                      c9.52,18.35,18.92,36.75,28.4,55.12c11.85,22.97,23.7,45.95,35.63,68.88c2.3,4.42,1.29,9,0.99,13.49
                      c-0.9,13.54-2.07,27.05-3.15,40.58c-1.15,14.38-2.33,28.76-3.46,43.14c-0.64,8.09-1.48,16.18-1.64,24.28
                      c-0.06,3.08,1.34,6.25,2.35,9.3c9.55,28.93,19.17,57.85,28.76,86.77c2.41,7.26,0.32,11.91-6.77,14.94
                      c-7.28,3.1-14.57,6.17-21.88,9.21c-5.64,2.35-11.77-0.02-13.89-5.3c-2.11-5.24,0.46-11.06,5.98-13.44c4.11-1.77,8.19-3.6,12.38-5.15
                      c2.19-0.81,2.32-1.83,1.64-3.86c-7.19-21.5-14.28-43.03-21.4-64.55c-2.12-6.41-3.98-12.93-6.46-19.2
                      c-2.14-5.41-1.03-10.77-0.68-16.13c0.88-13.25,2.07-26.49,3.13-39.73c0.57-7.14,1.17-14.28,1.72-21.43
                      C440.01,277.93,439.92,276.63,439.92,274.61z M314.84,232.47c-25.58,0-50.85,0-76.86,0c0.6,4.55,1.28,8.81,1.67,13.1
                      c0.2,2.17-0.03,4.39-0.25,6.57c-0.3,2.93-0.79,5.84-1.2,8.77c26.03,0,51.29,0,76.64,0C314.84,251.34,314.84,242.01,314.84,232.47z
                      M387.96,162.14c-0.23-0.08-0.46-0.16-0.68-0.25c-0.77,0.68-1.55,1.36-2.31,2.05c-6.73,6.09-13.36,12.3-20.24,18.21
                      c-1.78,1.53-4.25,2.75-6.55,3.08c-10.39,1.51-20.84,2.66-31.26,3.92c-4.71,0.57-9.42,1.08-14.21,1.63c0,9.4,0,18.46,0,28.07
                      c1.33-0.51,2.43-0.86,3.46-1.34c1.28-0.59,2.47-1.43,3.78-1.92c20.75-7.65,41.5-15.29,62.28-22.85c1.89-0.69,2.74-1.56,2.79-3.63
                      c0.06-2.76,0.5-5.51,0.81-8.25C386.52,174.61,387.24,168.37,387.96,162.14z M298.69,209.03c0-9.29,0-18.23,0-27.14
                      c-14.38,0-28.56,0-42.69,0c0,9.17,0,18.11,0,27.14C270.31,209.03,284.41,209.03,298.69,209.03z M242.15,202.36
                      c-19.42,12.85-38.21,25.28-56.98,37.72c-5.08,3.37-9.7,3-14.18-1.24c-4.93-4.66-9.84-9.34-14.78-13.99
                      c-0.68-0.64-1.45-1.2-2.18-1.79c-0.19,0.93-0.07,1.48,0.19,1.95c3.87,7.13,7.74,14.26,11.64,21.37c2.23,4.07,2.27,4.11,6.94,3.16
                      c16.62-3.38,33.23-6.84,49.86-10.15c1.87-0.37,2.31-1.16,2.27-2.83c-0.1-3.53-0.16-7.07-0.09-10.61c0.09-4.85,2.7-7.35,7.54-7.39
                      c3.32-0.03,6.65,0,9.78,0C242.15,213.15,242.15,208.05,242.15,202.36z M286.59,139.02c-4.98,0-9.69,0.01-14.41,0
                      c-5.36-0.01-5.36-0.02-5.36,5.39c0,4.54,0,9.07,0,13.57c6.87,0,13.27,0,19.78,0C286.59,151.64,286.59,145.56,286.59,139.02z"/>
                    <path id="box-3" d="M237.69,261.37c0.6-3.46,1.26-6.58,1.64-9.73c0.24-1.98,0.23-4.02,0.02-6
                      c-0.44-4.28-1.07-8.55-1.65-13.04c26.09,0,51.38,0,76.89,0c0,9.49,0,19.01,0,28.77C289.32,261.37,263.97,261.37,237.69,261.37z"/>
                    <path id="box-2" d="M254.65,209.33c0-9.38,0-18.45,0-27.74c0.94-0.07,1.78-0.18,2.62-0.18
                      c12.65-0.01,25.3,0.05,37.95-0.06c2.3-0.02,2.96,0.69,2.92,2.94c-0.13,7.47-0.04,14.95-0.06,22.43c0,0.84-0.13,1.67-0.2,2.6
                      C283.4,209.33,269.15,209.33,254.65,209.33z"/>
                    <path id="box-1" d="M286.48,158.36c-6.63,0-13.11,0-19.8,0c0-6.47,0-12.84,0-19.47c6.51,0,13.05,0,19.8,0
                      C286.48,145.23,286.48,151.6,286.48,158.36z"/>
                  </g>
                </svg>
              </div>
            </div>
            <div className="shadow-circle"></div>
            <div 
              className="service-label" 
              onClick={()=>goToService('occupationalTherapy')}
            >
              {ReactHtmlParser(services.occupationalTherapy.heading.replace(/\\n/g,'<br />'))}
            </div>
          </div>
          <div 
            className="action" 
            onClick={()=>goToService('occupationalTherapy')}
            onMouseEnter={()=>handleMouseEnterService('occupationalTherapy')}
          >
          </div>
        </div>

        <div 
          className={`services services-7 ${(service==='military' || nextService==='military') ? 'selected' : ''} ${hoverService==='military' ? 'hover' : ''}`} 
          onMouseLeave={handleMouseLeaveService}
        > 
          <div className="spiral-in">
            <div className="scaling">
              <div id="military">
                <svg 
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" y="0px"
                  viewBox="-100 -100 700 700"
                >
                  <g>
                    <path d="M265.44,140.46c1.29-1.86,2.52-3.75,3.25-5.91c0.56-1.65,1.14-3.3,1.71-4.95c0.07-0.21,0.09-0.45,0.2-0.63
                      c1.05-1.88,2-3.83,3.21-5.6c1.8-2.65,3.75-5.2,6.21-7.31c1.62-1.39,3.33-2.61,5.43-3.13c1.33-0.33,2.69-0.68,4.05-0.73
                      c2.66-0.11,5.3,0.03,7.98,0.35c6.46,0.75,12.51,2.75,18.38,5.35c4.31,1.91,8.46,4.16,12.42,6.77c2.82,1.85,5.85,3.37,8.85,4.92
                      c3.34,1.74,6.73,3.41,10.14,5c2.05,0.96,4.19,1.71,6.28,2.57c2.19,0.9,4.34,1.93,6.57,2.73c4.53,1.62,9.07,3.22,13.66,4.66
                      c4.69,1.47,9.43,2.81,14.18,4.07c2.62,0.69,5.31,1.08,7.96,1.64c2.15,0.46,4.27,1.04,6.43,1.45c1.52,0.29,3.07,0.37,4.6,0.58
                      c2.03,0.28,4.05,0.62,6.09,0.88c0.88,0.11,1.78,0.1,2.66,0.16c3.33,0.23,6.65,0.53,9.99,0.67c4.06,0.17,8.12,0.35,12.18,0.27
                      c3.55-0.07,7.09-0.48,10.63-0.78c2.27-0.19,4.54-0.39,6.79-0.73c2.4-0.37,4.77-0.9,7.14-1.39c2.65-0.55,5.3-1.08,7.92-1.71
                      c1.87-0.45,3.72-1,5.56-1.57c1.4-0.43,2.78-0.95,4.44-1.53c-0.78,1.24-1.9,1.72-2.76,2.48c-0.96,0.85-1.96,1.65-2.96,2.45
                      c-0.96,0.76-1.94,1.49-2.93,2.21c-0.99,0.72-2.01,1.39-3,2.1c-1.04,0.75-2.04,1.55-3.09,2.29c-1,0.7-2.03,1.35-3.07,2
                      c-1.04,0.65-2.08,1.29-3.15,1.88c-1.12,0.62-2.3,1.13-3.4,1.77c-1,0.57-2.31,0.57-3.33,1.83c4.53,0.18,8.75,0.18,13.29-0.84
                      c-0.39,0.56-0.57,0.84-0.77,1.11c-2.52,3.46-5.82,5.96-9.78,7.52c-2.36,0.93-4.78,1.74-7.23,2.39c-1.53,0.41-3.17,0.44-4.77,0.6
                      c-1.21,0.13-2.43,0.2-3.64,0.3c-0.22,0.02-0.45,0.04-0.67,0.02c-2.7-0.25-5.41-0.49-8.11-0.76c-1.59-0.16-3.19-0.28-4.75-0.61
                      c-2.37-0.5-4.72-1.08-7.04-1.76c-3.34-0.98-6.66-2.06-9.97-3.14c-0.7-0.23-1.03,0.08-1.39,0.7c2.18,2,4.45,3.85,6.97,5.4
                      c2.67,1.65,5.54,2.81,8.5,3.76c1.77,0.57,3.61,0.97,5.43,1.34c3.92,0.8,7.89,1.16,11.96,1.01c-0.97,1.61-2.41,2.4-4.03,2.35
                      c-0.5-0.01-1.01,0.04-1.49,0.19c-0.24,0.07-0.41,0.41-0.61,0.63c0.25,0.13,0.49,0.31,0.76,0.38c0.17,0.04,0.38-0.09,0.68,0.11
                      c-0.87,0.44-1.72,0.96-2.63,1.3c-1.97,0.73-3.93,1.59-5.97,2c-2.75,0.56-5.49,1.33-8.36,1.21c-1.34-0.05-2.68-0.03-4.01-0.01
                      c-2.79,0.04-5.5-0.44-8.24-0.93c-3.88-0.69-7.62-1.8-11.36-2.98c-0.35-0.11-0.74-0.07-1.17-0.11c0.59,1.49,2.3,2.43,7.1,4.24
                      c2.64,0.99,5.39,0.93,8.39,1.12c-0.33,0.47-0.48,0.79-0.73,1.02c-2.17,2.09-4.84,3.35-7.64,4.27c-2.41,0.78-4.87,1.33-7.4,1.73
                      c-2.76,0.44-5.52,0.43-8.27,0.68c-2.56,0.23-5.06-0.24-7.59-0.7c-3.63-0.65-7.12-1.7-10.45-3.2c-3.53-1.59-6.95-3.44-10.39-5.22
                      c-0.98-0.51-1.87-1.17-2.81-1.77c-0.14-0.09-0.29-0.26-0.42-0.25c-0.34,0.03-0.68,0.15-1.02,0.23c0.09,0.29,0.09,0.7,0.28,0.86
                      c1.61,1.28,3.26,2.51,4.91,3.74c0.27,0.2,0.59,0.32,0.86,0.52c2.31,1.74,4.88,3.05,7.44,4.38c0.45,0.23,0.91,0.5,1.24,0.87
                      c0.63,0.7,1.56,0.29,2.25,0.74c0.45,0.29,0.99,0.44,1.62,0.71c-1.08,1.36-2.47,1.95-3.78,2.58c-3.1,1.49-6.31,2.55-9.79,2.98
                      c-3.72,0.46-7.36,0.2-10.89-0.67c-4.68-1.15-9.16-2.94-13.08-5.88c-0.46-0.35-1.02-0.56-1.6-0.86c-0.61,1.16-0.41,2.23-0.39,3.26
                      c0,0.29,0.41,0.63,0.7,0.85c0.33,0.24,0.75,0.37,1.36,0.65c-0.48,0.25-0.73,0.47-1.01,0.51c-1.37,0.16-2.04,0.83-2.48,2.23
                      c-0.64,2.03-1.72,3.9-2.9,5.73c-1.32,2.06-3.12,3.58-4.96,5.04c-1.72,1.37-3.68,2.47-5.62,3.55c-3.84,2.13-7.84,3.92-12.01,5.31
                      c-2.57,0.86-5.18,1.58-7.77,2.37c-0.2,0.06-0.39,0.19-0.75,0.38c0.66,0.83,1.2,1.65,1.88,2.33c2.06,2.07,3.16,4.68,4.04,7.36
                      c0.87,2.67,1.59,5.4,1.52,8.27c-0.06,2.37-0.69,4.58-1.62,6.72c-2.36,5.43-6.64,9.04-11.58,11.96c-2.66,1.57-5.43,2.98-8.22,4.5
                      c1.04,1,2.09,1.98,3.09,2.99c1.94,1.95,3.13,4.28,4.05,6.9c1.35,3.84,1.25,7.74,1.21,11.66c-0.02,1.61-0.74,3.08-1.14,4.61
                      c-0.53,2.01-1.67,3.59-2.78,5.23c-0.4,0.59-0.92,1.11-1.44,1.61c-3.42,3.31-7.53,5.42-12.03,6.83c-0.1,0.03-0.17,0.13-0.37,0.28
                      c0.07,0.3,0.06,0.75,0.27,1c1.93,2.33,2.93,5,3.2,8c0.07,0.81,0.46,1.64,0.37,2.42c-0.36,3.08-1.09,6-3.31,8.43
                      c-1.14,1.25-2.42,2.27-3.68,3.35c-0.96,0.83-0.94,1.03-0.05,1.98c1.62,1.74,3.26,3.46,4.76,5.3c1.21,1.48,1.69,3.31,1.89,5.21
                      c0.13,1.26,0.44,2.5,0.53,3.77c0.36,4.87-0.97,9.27-3.87,13.17c-1.93,2.6-4.56,4.36-7.43,5.76c-1.43,0.69-2.93,1.23-4.6,1.92
                      c0.29,0.48,0.44,0.89,0.71,1.17c2.19,2.26,3.24,5.11,3.31,8.11c0.06,2.89-0.3,5.87-2.1,8.38c-1.19,1.66-2.43,3.29-3.71,4.87
                      c-0.38,0.47-0.62,0.89-0.53,1.49c0.16,1.15,0.3,2.3,0.45,3.44c0.02,0.17,0.03,0.33,0.02,0.5c-0.31,4.48-1.78,8.54-4.56,12.06
                      c-1.09,1.38-2.73,2.1-4.34,2.71c-1.23,0.47-2.53,0.72-3.9,0.63c-1.64-0.11-2.68-0.71-2.98-2.3c-0.16-0.82-0.07-1.81,0.26-2.58
                      c0.49-1.15,1.31-2.16,1.94-3.26c0.76-1.34,1.47-2.71,2.19-4.07c0.06-0.12-0.04-0.32-0.08-0.59c-0.36,0-0.75-0.09-1.07,0.02
                      c-1.26,0.39-2.5,0.88-3.76,1.26c-2.04,0.61-4.37-0.63-5.08-3.13c-0.23-0.79,0.37-1.45,1-1.96c0.82-0.65,1.64-1.3,2.63-2.09
                      c-1.39-0.87-2.57-1.68-3.81-2.38c-4.43-2.5-8.51-5.46-12.02-9.17c-3.01-3.18-4.18-7.02-3.69-11.31c0.17-1.54,0.94-2.96,2.08-4.09
                      c0.69-0.69,1.45-1.32,2.29-2.08c-1-0.78-1.97-1.58-2.99-2.31c-2.22-1.58-3.83-3.65-5.01-6.07c-1.23-2.54-1.37-5.12-0.58-7.88
                      c0.41-1.42,0.77-2.83,1.37-4.2c1.31-2.96,3.2-5.43,5.67-7.48c0.34-0.28,0.65-0.58,1.11-1c-0.43-0.38-0.75-0.76-1.15-0.99
                      c-3.78-2.12-6.72-5.09-8.74-8.91c-1.58-3-2.43-6.24-1.81-9.64c0.6-3.31,1.91-6.33,4.52-8.62c0.46-0.4,0.77-0.81,0.42-1.53
                      c-3.14-0.56-5.76-2.38-8.27-4.26c-1.79-1.35-3.39-2.99-4.75-4.85c-1.41-1.93-2.63-3.95-3.46-6.18c-0.81-2.15-1.68-4.33-1.53-6.69
                      c0.22-3.47,1.56-6.5,3.74-9.21c1.77-2.2,4.06-3.78,6.23-5.52c0.4-0.32,0.77-0.68,1.32-1.16c-1.29-0.55-2.29-1-3.3-1.4
                      c-3.21-1.29-6.13-3.07-8.99-5.01c-2.6-1.76-4.49-4.05-5.85-6.81c-1.18-2.4-2.07-4.88-2.52-7.57c-0.34-2-0.52-3.95-0.48-5.95
                      c0.08-4.33,1.13-8.39,3.72-11.94c0.64-0.88,1.36-1.7,2.19-2.73c-1-0.26-1.82-0.6-2.66-0.67c-4.13-0.32-8.06-1.52-11.95-2.79
                      c-2.57-0.84-5.07-1.96-7.49-3.19c-3.22-1.63-6.26-3.58-9.06-5.9c-3.69-3.06-6.58-6.7-8.44-11.13c-0.42-1.01-0.69-2.08-1.07-3.25
                      c-0.4,0.05-0.83-0.01-1.1,0.17c-2.95,1.91-6.19,3.06-9.56,4.02c-3.02,0.87-6.07,1.24-9.17,1.21c-3.45-0.03-6.77-0.79-10-2.07
                      c-2.19-0.87-4.06-2.2-6.5-3.4c1.34-0.51,2.01-1.43,3.26-1.28c0.39,0.05,0.95-0.24,1.25-0.55c1.52-1.53,3.6-2.08,5.35-3.19
                      c2.96-1.87,5.91-3.76,8.54-6.1c0.34-0.3,0.81-0.65,0.37-1.07c-0.21-0.2-0.85-0.16-1.19,0c-0.75,0.35-1.45,0.82-2.15,1.27
                      c-4.5,2.86-9.26,5.21-14.29,6.96c-2.75,0.96-5.57,1.85-8.44,2.32c-3.04,0.5-6.17,0.88-9.27,0.21c-0.32-0.07-0.67-0.09-1-0.08
                      c-2.4,0.08-4.69-0.47-7.03-0.92c-2.02-0.39-3.97-0.93-5.9-1.62c-2.48-0.9-4.72-2.19-6.72-3.9c-0.17-0.14-0.31-0.32-0.43-0.5
                      c-0.05-0.07-0.01-0.21-0.01-0.43c1.9-0.15,3.85-0.11,5.7-0.51c1.93-0.41,3.81-1.15,5.64-1.92c1.72-0.73,3.48-1.5,4.67-3.03
                      c-0.06-0.12-0.12-0.24-0.17-0.36c-0.57,0.15-1.14,0.28-1.7,0.44c-2.91,0.83-5.82,1.66-8.73,2.5c-5.3,1.54-10.73,1.69-16.17,1.45
                      c-2.2-0.1-4.4-0.55-6.55-1.07c-2.03-0.49-4.04-1.17-5.97-1.97c-1.26-0.52-2.79-0.78-3.44-2.3c-0.08-0.18-0.48-0.31-0.73-0.31
                      c-1.7,0.01-2.54-1.38-3.72-2.36c0.41-0.03,0.83-0.11,1.24-0.07c3.02,0.27,6.02-0.21,8.97-0.63c3.59-0.51,7.09-1.59,10.5-2.93
                      c3.29-1.29,6.23-3.16,9.11-5.13c1.13-0.77,2.04-1.85,3.07-2.82c-0.42-0.65-0.75-0.99-1.53-0.69c-4.28,1.66-8.69,2.92-13.17,3.93
                      c-2.14,0.48-4.27,1.03-6.42,1.45c-1.36,0.26-2.75,0.37-4.12,0.5c-1.71,0.15-3.46,0.48-5.14,0.3c-2.58-0.27-5.18-0.07-7.77-0.63
                      c-1.6-0.35-3.24-0.6-4.85-0.97c-2.14-0.49-4.08-1.39-6.02-2.36c-3.49-1.74-6.46-4.1-8.74-7.29c-0.06-0.08-0.06-0.2-0.14-0.5
                      c4.19,0.75,8.34,0.96,12.81,0.67c-0.36-0.43-0.52-0.81-0.79-0.91c-3.89-1.44-7.4-3.54-10.75-5.95c-1.93-1.4-4.01-2.6-5.91-4.04
                      c-2.33-1.76-4.54-3.68-6.83-5.5c-0.93-0.74-1.94-1.4-2.85-2.39c0.31,0.03,0.63,0,0.92,0.09c2.79,0.91,5.53,1.97,8.36,2.74
                      c2.6,0.71,5.27,1.12,7.92,1.66c2.27,0.47,4.52,0.99,6.8,1.39c1.68,0.3,3.39,0.48,5.1,0.63c3.87,0.36,7.74,0.73,11.62,0.98
                      c2.22,0.14,4.46,0.13,6.69,0.07c4.73-0.13,9.46-0.28,14.18-0.54c2.04-0.11,4.06-0.55,6.09-0.83c1.82-0.24,3.64-0.43,5.45-0.7
                      c1.85-0.28,3.69-0.61,5.53-0.94c1.9-0.34,3.8-0.69,5.69-1.07c2.71-0.55,5.43-1.08,8.12-1.74c3.1-0.75,6.18-1.6,9.25-2.46
                      c1.54-0.43,3.05-1,4.58-1.46c7.21-2.16,14.26-4.76,21.17-7.73c4.93-2.12,9.86-4.24,14.56-6.85c3.68-2.05,7.36-4.1,11.02-6.19
                      c5.93-3.38,11.91-6.66,18.43-8.76c2.57-0.83,5.25-1.29,7.87-1.96c3.64-0.93,7.34-1.19,11.06-0.93c3.77,0.26,7.15,1.41,10.02,4.11
                      c3.11,2.94,5.64,6.29,7.67,9.99c1.28,2.33,2.33,4.82,2.98,7.47c0.39,1.62,1.37,3.1,2.18,4.6c0.57,1.07,1.29,2.07,1.95,3.1
                      c0.33,0.52,0.7,0.71,1.24,0.02c-1.2-1.12-1.13-2.62-1.11-4.12c0.03-1.9-0.03-3.79,0.02-5.69c0.04-1.7,0.73-2.64,2.12-2.71
                      c0.66-0.03,1.42,0.24,2.01,0.58c1.78,1.02,3.49,2.15,5.38,3.32c0.62-2.26,1.21-4.43,1.82-6.67c-1.12-0.95-2.17-1.86-3.22-2.75
                      c-0.59-0.5-1.21-0.98-1.78-1.5c-0.93-0.84-1.17-1.86-0.72-3.03c0.43-1.12,1.4-1.35,2.44-1.42c1.77-0.13,3.55-0.24,5.32-0.37
                      c0.27-0.02,0.54-0.11,0.85-0.18c0.81-1.9,1.6-3.79,2.41-5.68c0.35-0.82,0.95-1.36,1.85-1.52c1.33-0.24,2.25,0.22,2.82,1.51
                      c0.72,1.63,1.42,3.27,2.04,4.94c0.26,0.7,0.68,0.93,1.36,0.97c1.72,0.09,3.43,0.23,5.15,0.34c1.54,0.09,2.32,0.53,2.61,1.64
                      c0.33,1.25-0.08,2.28-1.11,3.08c-1.27,1-2.5,2.05-3.77,3.04c-0.74,0.58-1,1.19-0.69,2.16c0.56,1.74,0.98,3.53,1.5,5.43
                      c1.89-1.15,3.66-2.26,5.46-3.3c0.51-0.3,1.12-0.57,1.7-0.6c1.34-0.06,2.22,0.96,2.24,2.56c0.03,2.73,0,5.47,0,8.2
                      c0,0.28,0.02,0.55,0.03,0.83c-0.36,0.29-0.86,0.47-0.63,1.16C265.05,141.26,265.24,140.86,265.44,140.46z M214.92,190.73
                      c-1.67,0.39-3.1,0.68-4.5,1.08c-0.5,0.14-1,0.49-1.37,0.87c-3.01,3.1-6.42,5.63-10.41,7.35c-1.5,0.65-3.04,1.22-4.79,1.91
                      c0.53,0.32,0.86,0.61,1.24,0.75c1.35,0.48,2.71,0.97,4.09,1.35c2.23,0.62,4.47,1.22,6.73,1.72c1.45,0.32,2.94,0.45,4.4,0.69
                      c1.8,0.29,3.58,0.69,5.39,0.87c2.76,0.28,5.53,0.45,8.3,0.61c3.1,0.18,6.21,0.31,9.32,0.44c0.36,0.02,0.73-0.1,1.22-0.17
                      c0-0.69,0.03-1.29,0-1.89c-0.17-3.21,0.04-6.42-0.36-9.64c-0.4-3.25-0.24-6.56-0.33-9.84c-0.01-0.47-0.1-0.95-0.15-1.42
                      c-0.16-0.07-0.31-0.14-0.47-0.21c-0.94,0.95-1.31,2.33-2.11,3.39c-0.89,1.2-1.75,2.42-2.7,3.57c-0.91,1.1-1.87,2.17-2.88,3.18
                      c-1.02,1.02-2.09,1.99-3.19,2.91c-1.15,0.96-2.29,1.98-3.59,2.71c-1.21,0.67-2.04,2.05-3.5,2.12c-1.15-1.59-1.01-3.29-0.97-4.95
                      c0.04-1.88-0.25-3.78,0.58-5.6C215.07,192.12,214.92,191.56,214.92,190.73z M277.21,203.3c-0.59-0.31-0.91-0.42-1.16-0.62
                      c-2.77-2.16-5.54-4.3-8.27-6.51c-0.89-0.72-1.71-1.56-2.41-2.46c-1.43-1.84-3.14-3.48-4.22-5.59c-0.12-0.23-0.54-0.46-0.77-0.42
                      c-0.22,0.04-0.48,0.41-0.54,0.68c-0.09,0.43-0.04,0.89-0.04,1.33c-0.02,4.4-0.04,8.8-0.08,13.21c0,0.49,0.04,1.05-0.17,1.47
                      c-0.78,1.61-0.32,3.28-0.36,4.92c-0.02,0.84,0.61,1.06,1.54,0.87c1.46-0.29,2.93-0.54,4.4-0.75c1.58-0.23,3.18-0.39,4.76-0.6
                      c1.15-0.16,2.29-0.34,3.43-0.54c1.62-0.29,3.24-0.61,4.87-0.92c0.97-0.18,1.95-0.38,2.93-0.54c1.3-0.22,2.62-0.34,3.91-0.61
                      c1.98-0.43,3.94-0.98,5.92-1.44c1.66-0.39,3.35-0.65,4.99-1.1c1.13-0.31,2.2-0.86,3.58-1.41c-0.55-0.33-0.79-0.54-1.06-0.64
                      c-0.93-0.31-1.89-0.57-2.83-0.87c-2.6-0.85-4.88-2.32-7.14-3.78c-1.48-0.96-2.94-1.97-4.04-3.45c-0.58-0.78-1.46-1.32-2.6-1.26
                      c-0.64,0.03-1.28-0.28-1.94-0.34c-0.83-0.09-1.73-0.5-2.38,0.15C278.15,195.86,278.65,199.48,277.21,203.3z M257.95,256.22
                      c0.71-0.05,1.31-0.01,1.88-0.14c0.91-0.21,1.77-0.6,2.68-0.8c4.01-0.89,7.83-2.27,11.39-4.29c1.75-1,3.38-2.27,2.95-4.71
                      c-0.1-0.58-0.39-1.13-0.61-1.68c-1.47-3.79-4.47-6.14-7.86-7.98c-2.8-1.52-5.9-2.38-9.05-2.97c-0.81,0.34-0.82,1.05-0.83,1.8
                      c-0.04,4.95-0.09,9.91-0.15,14.86c-0.01,0.5,0.06,1.05-0.14,1.48C257.58,253.21,257.94,254.61,257.95,256.22z M236.49,254.17
                      c-0.07-0.98-0.09-1.74-0.18-2.49c-0.08-0.7-0.34-1.39-0.35-2.09c-0.07-4-0.09-8.01-0.14-12.02c0-0.39,0.05-0.81-0.09-1.15
                      c-0.22-0.53-0.56-0.89-1.29-0.67c-1.2,0.35-2.44,0.55-3.66,0.85c-1.6,0.39-3.25,0.67-4.77,1.26c-2.77,1.07-5.57,2.05-8.15,3.62
                      c-1.75,1.07-3.13,2.35-4.42,4.04c1.23,0.98,2.28,2.15,3.58,2.79c2.65,1.3,5.21,2.84,8.13,3.56c2.87,0.71,5.71,1.57,8.58,2.27
                      C234.49,254.33,235.35,254.17,236.49,254.17z M257.25,296.9c1.74-0.5,3.25-0.83,4.67-1.37c2.16-0.82,4.24-1.84,6.39-2.68
                      c1.64-0.64,2.51-2,3.2-3.43c0.35-0.73,0.44-1.73-0.27-2.51c-0.41-0.44-0.73-0.97-1.17-1.37c-2.71-2.48-5.8-4.44-9.09-6.04
                      c-1.08-0.52-2.27-1.18-3.74-0.54C257.25,284.87,257.25,290.79,257.25,296.9z M237.21,280.03c-0.46-0.11-0.64-0.22-0.78-0.18
                      c-2.95,0.86-5.96,1.59-8.83,2.68c-1.75,0.66-3.57,1.53-4.35,3.63c0.87,1.12,1.43,2.56,2.79,3.33c2.93,1.65,5.85,3.34,9.08,4.39
                      c0.81,0.26,1.59,0.63,2.54,1.01c0.04-0.76,0.11-1.26,0.08-1.75c-0.17-3.09-0.38-6.17-0.53-9.25
                      C237.16,282.63,237.21,281.36,237.21,280.03z M240.24,358.12c-0.16-0.64-0.37-1.1-0.38-1.57c-0.07-3.23-0.09-6.46-0.14-9.69
                      c-0.01-0.53-0.07-1.06-0.12-1.73c-0.61,0.03-1.08-0.05-1.46,0.09c-2.12,0.78-3.96,2.07-5.45,3.72c-1.05,1.16-1.27,2.15-0.34,3.91
                      c1.42,2.71,4.05,3.82,6.47,5.23C239.13,358.28,239.66,358.12,240.24,358.12z M255.36,357.54c1.23,0.21,2.07-0.42,2.95-0.81
                      c2.19-0.97,3.99-2.39,4.69-4.81c0.13-0.45,0.15-1.01,0-1.43c-1.07-2.87-3.28-4.47-6.13-5.26c-0.28-0.08-0.62,0.02-0.97,0.03
                      c-0.22,1.27-0.65,2.46-0.58,3.62c0.13,2.36-0.14,4.71-0.05,7.06C255.28,356.47,255.33,357.01,255.36,357.54z M252.39,135.37
                      c-1.69-1.1-3.31-2.14-4.93-3.19c-0.5-0.33-0.98-0.45-1.54-0.09c-1.65,1.08-3.32,2.14-5.1,3.28c2.03,1.48,3.9,2.84,5.84,4.25
                      C248.59,138.19,250.45,136.81,252.39,135.37z M238.42,317.7c-0.25-0.12-0.37-0.24-0.44-0.21c-1.66,0.58-3.35,1.07-4.94,1.8
                      c-0.95,0.44-0.94,1.38,0.01,1.98c0.96,0.61,2.06,1.04,3.14,1.42c0.73,0.26,1.5,0.87,2.47,0.12
                      C238.58,321.13,238.5,319.38,238.42,317.7z M259.99,322.16c-1.77-3.1-1.85-3.18-3.43-3.06c-0.11,1.82-0.22,3.66-0.34,5.6
                      C258.22,324.94,259.05,323.53,259.99,322.16z M235.39,374.91c1.65,1.58,2.93,3.04,5.2,3.55c0-1.34,0-2.44,0-3.54
                      c0-0.64-0.4-0.83-0.97-0.89C238.25,373.91,237.01,374.34,235.39,374.91z M254.74,381.76c-0.09,1.32-0.38,2.46,0.05,3.72
                      C256.49,383.62,256.51,382.82,254.74,381.76z M187.05,190.87c-0.07-0.1-0.14-0.21-0.21-0.31c-0.44,0.21-1.09,0.31-1.3,0.67
                      c-0.61,1.04-0.66,2.17,0.24,3.49c0-1.39,0-2.5,0-3.64C186.3,190.99,186.68,190.93,187.05,190.87z M189.06,194.54
                      c1.13-0.91,1.15-0.98,0.63-2.27C189.49,193.02,189.32,193.61,189.06,194.54z M188.41,191.07c0,0.07-0.01,0.14-0.01,0.2
                      c0.38,0,0.76,0,1.15,0c0-0.04,0-0.07,0.01-0.11C189.17,191.13,188.79,191.1,188.41,191.07z M187.2,195.39
                      c0.04,0.03,0.07,0.07,0.11,0.1c0.14-0.15,0.28-0.3,0.42-0.45c-0.04-0.03-0.07-0.06-0.11-0.09
                      C187.48,195.1,187.34,195.24,187.2,195.39z"/>
                  </g>
                </svg>
              </div>
            </div>
            <div className="shadow-circle"></div>
            <div 
              className="service-label" 
              onClick={()=>goToService('military')}
            >
              {ReactHtmlParser(services.military.heading.replace(/\\n/g,'<br />'))}
            </div>
          </div>
          <div 
              className="action" 
              onClick={()=>goToService('military')}
              onMouseEnter={()=>handleMouseEnterService('military')}
            >
          </div>
        </div>

        
      </div>

      <div id="services-info" className={service || nextService ? (shrink===true ? 'enlarge shrink' : 'enlarge') : ''}>
          <div>
            <div id="services-info-heading-1"
              className={serviceHeadingClass}
              onAnimationEnd={(e) => handleServicesInfoHeadingAnimationEnd(e)}
            >
              {ReactHtmlParser(services.title.replace(/\\n/g,'<br />'))} 
            </div>
            <div id="services-info-heading-2"
              className={serviceHeadingClass}
              onAnimationEnd={(e) => handleServicesInfoHeadingAnimationEnd(e)}
            >
              {currentInfo && ReactHtmlParser(currentInfo.heading.replace(/\\n/g,'<br />'))}  
            </div>
            <div id="services-info-copy">
              {currentInfo && 
                <div>{ReactHtmlParser(currentInfo.copy)}</div>
              }
              {currentInfo && currentInfo.bullets && currentInfo.bullets.length>0 &&
                <div className="bullet-points">
                  {currentInfo.bullets.map((bullet,index)=> 
                    <React.Fragment key={currentInfo.heading+'-bullet-'+index}>
                      ● {ReactHtmlParser(bullet)}<br />
                    </React.Fragment>
                  )}
                </div>
              }
            </div>
          </div>
      </div>
    </div>
  )
}

const Services = ({services}) => {


  return (
    <>
    <div id="services-container">
        
        <div className="background">
        
          <div className="left"></div>
          <div className="right"></div>  
        </div>
        <div className="services-background-container">
          <ServicesBackground>
            <ServicesForeground services={services} />
          </ServicesBackground>
        </div>

        
        
{/* 
        <div id="services-circle-names">
          <svg id="myCircle" xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
              <circle id="path1" cx="400" cy="400" r={375} fill="none" />
            </defs>
            <text id="myText">
              <textPath xlinkHref="#path1" startOffset="0" >
                <tspan>Personal Assistance</tspan>
              </textPath>
              <textPath xlinkHref="#path1" startOffset="400px" >
                <tspan>Healthcare</tspan>
              </textPath>
              <textPath xlinkHref="#path1" startOffset="625px" >
                <tspan>Nursing Home Transition</tspan>
              </textPath>
              <textPath xlinkHref="#path1" startOffset="1100px" >
                <tspan>Hoyer Lift</tspan>
              </textPath>
              <textPath xlinkHref="#path1" startOffset="1400px" >
                <tspan>Waiver Program</tspan>
              </textPath>
              <textPath xlinkHref="#path1" startOffset="1700px" >
                <tspan>Occupational Therapy</tspan>
              </textPath>
              <textPath xlinkHref="#path1" startOffset="2100px" >
                <tspan>Military</tspan>
              </textPath>

            </text>
          </svg>
        </div>
*/}        

    </div> 

{/*
    <div id="services-background-container"> 
      <div>
        <svg className="floating-svg" style={{animationPlayState: service ? 'paused' : 'running'}} viewBox="0 0 670 800" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">	
          <path d="M136.6,15.34c78-13,126.7-1.17,224,38.01c74.33,21.79,90,7.99,167,23.99c61.24,12.73,86.59,35.63,109,65 c60.68,75.56,6.36,189.98-100.01,207c-64.2,4.18-88.99,3-137.99,46.01c-72.1,63.28-284.1,45.38-368-105.99 C-19.89,186.01,14.13,36.92,136.6,15.34z" />
        </svg>

        <svg className="floating-svg" style={{animationPlayState: service ? 'paused' : 'running'}} viewBox="0 0 670 800" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <path d="M337.77,477.04c-56.04,0-97,0-188.22-26.58C16.02,411.56-42.3,226.81,90.77,149.04 c65-38,140.49-27.39,162.01-30.99c47.92-10.14,83.65-47.66,128-73c105-60,240.22-73.78,265,81c6.55,43.25,28.15,185.77-62,267	C516.45,460.41,389.77,477.04,337.77,477.04z" />
        </svg>

        <svg className="floating-svg" style={{animationPlayState: service ? 'paused' : 'running'}} viewBox="0 0 670 800" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <path d="M308.79,462.16c-50.89,8.21-98.72,17.52-149.4,13.92 c-53.5-3.8-81.67-12.59-105.79-31.55C9.97,410.22,6.92,349.09,5.34,317.39c-4.66-93.61,42.31-164.18,60.32-188.38 C78.8,111.36,147.43,19.14,269.81,6.53C292,4.24,374.39-4.25,447.06,50.14c62.76,46.98,80.33,112.48,93.72,162.39 c9.72,36.23,19.4,74.39,3.71,116.92c-12.9,34.98-36.57,55.59-51.97,68.67C442.58,440.57,388.31,449.33,308.79,462.16z" />
        </svg>

        <svg className="floating-svg" style={{animationPlayState: service ? 'paused' : 'running'}} viewBox="0 0 670 800" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <path d="M23.79,309.28c-35.47-48.36-21.72-125.33,19.99-185.07C115.36,21.68,294.14-14.84,422.3,15.88 c51.72,12.4,195.47,46.85,234.72,172.82c29.8,95.62-9.03,203.77-83.83,242.46c-83.28,43.08-154.12,21.28-203.77-1.93 c-64.87-30.33-79.96-70.29-147.67-81.25C165.75,338.9,84.36,391.88,23.79,309.28z"/>
        </svg>
        
        <svg className="floating-svg" style={{animationPlayState: service ? 'paused' : 'running'}} viewBox="0 0 670 800" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <path d="M453.55,93.44C332.77-34.8,46.43-37.3,9.63,177.73c-17.94,77.58,24.28,211.84,166.81,269.69 c84.74,34.39,450.06,82.2,428.44-156.43C590.3,226.94,520.94,154.46,453.55,93.44z"/>
        </svg>

        <svg className="floating-svg" style={{animationPlayState: service ? 'paused' : 'running'}} viewBox="0 0 670 800" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <path d="M296.35,6.19c2.92,0.38-3.07-0.47,0.09,0.09c91.32,16.02,87.65,62.38,162.35,79.57 c67.29,15.49,80.64-14.95,155.4,28.84c27.56,16.14,69.91,87.23,45.39,160.21c-22.43,66.75-68.42,77.63-82.24,82.24 c-90.2,30.11-118.79-25.77-204-4.81C276.24,376.22,268.9,459.7,178.95,466.61C-147.11,455.26,38.23-27.81,296.35,6.19z"/>
        </svg>
      </div>

      <div>
        <svg className="floating-svg" style={{animationPlayState: service ? 'paused' : 'running'}} viewBox="0 0 670 800" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <path d="M560.37,414.33c-90.08,56.18-224.55,65.44-304.26,14.11c-55.6-35.8-79.39-64.12-92.4-90.62 c-21.36-43.49-32.6-77.02-60.75-104.2c-44.09-42.56-83.21-37.06-97.51-88.09C-5.3,107.19,49.08,39.89,123.88,15.34 c88.3-28.98,174.95,11.62,325.2,82c139.17,65.2,208.95,99.02,217.99,158.71C679.51,338.16,574.65,405.43,560.37,414.33z"/>
        </svg>
        
        <svg className="floating-svg" style={{animationPlayState: service ? 'paused' : 'running'}} viewBox="0 0 670 800" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <path d="M3.96,204.95C1.88,108.1,81.78,17.74,159.97,7.52c69.82-9.13,81.97,53.01,186.18,62.91 C429,78.3,450.97,41.84,531.82,58.67c28.45,5.92,96.54,20.1,123.27,72.12c35.6,69.28-22.75,165.59-76.72,213.8 c-5.16,4.61-97.59,84.85-197.43,60.36c-73.25-17.97-79.31-74.84-148.84-81.33c-67.98-6.34-91.48,45.29-142.19,30.18 C36.3,337.83,5.21,263.09,3.96,204.95z"/>
        </svg>
          
        <svg className="floating-svg" style={{animationPlayState: service ? 'paused' : 'running'}} viewBox="0 0 670 800" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <path d="M136.58,56.48c49.77-31.62,109.45-47.5,183.2-47.5c82.03,0,139.14,13.88,198.41,42.31 c38.57,18.5,128.45,78.2,144.78,158c15.64,76.44-17.34,132.83-79.84,157.04c-47.57,18.42-64.49-13.15-142.79-4.32 c-64.54,7.28-76.82,17.73-155.25,56.5c-79.6,39.36-143.38,44.01-196,13.32C26.68,395.41-10.57,299.99,11.64,226.7 C26.4,177.98,59.31,105.57,136.58,56.48z"/>
        </svg>

        <svg className="floating-svg" style={{animationPlayState: service ? 'paused' : 'running'}} viewBox="0 0 670 800" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <path d="M7.24,315.16c-12.63-102.7,29.44-171.9,91.79-198.61c80.69-34.57,113.78,18.47,207.62-19.35 c78.12-31.48,80.58-78.47,150.07-90.81c9.47-1.68,99.77-16.1,159.17,52.51c51.06,58.99,55.36,114.11,43.34,179.65 c-17.6,95.99-83.41,123.98-140.6,124.77c-101.62,1.4-121.29,17.77-166.82,53.3c-75.52,58.94-130.4,66.33-187.95,56.07 C93.21,460.09,20.36,421.76,7.24,315.16z"/>
        </svg>

        <svg className="floating-svg" style={{animationPlayState: service ? 'paused' : 'running'}} viewBox="0 0 670 800" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <path d="M280.04,3.91c76.28-0.84,268.07,42.42,321.06,139.16c71.87,120.35,8.78,250.95-121.56,293.39 c-36.7,16.73-119.76,53.2-225.49,34.38c-94.64-5-310.65-118.48-228.01-323.59C93.01,23.63,186.54,4.94,280.04,3.91z"/>
        </svg>
      </div>
    </div>



    <div id="services-triangles">
      <div>
        <div className="services-triangle triangle-1">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 807.32 539.21">
            <polygon points="1.58 269.6 806.83 0.69 806.82 538.51 1.58 269.6"/>
          </svg>
        </div>

        <div className="services-triangle triangle-2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 807.32 539.21">
            <polygon points="1.58 269.6 806.83 0.69 806.82 538.51 1.58 269.6"/>
          </svg>
        </div>

        <div className="services-triangle triangle-3">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 807.32 539.21">
            <polygon points="1.58 269.6 806.83 0.69 806.82 538.51 1.58 269.6"/>
          </svg>
        </div>

        <div className="services-triangle triangle-4">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 807.32 539.21">
            <polygon points="1.58 269.6 806.83 0.69 806.82 538.51 1.58 269.6"/>
          </svg>
        </div>

        <div className="services-triangle triangle-5">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 807.32 539.21">
            <polygon points="1.58 269.6 806.83 0.69 806.82 538.51 1.58 269.6"/>
          </svg>
        </div>

        <div className="services-triangle triangle-6">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 807.32 539.21">
            <polygon points="1.58 269.6 806.83 0.69 806.82 538.51 1.58 269.6"/>
          </svg>
        </div>

        <div className="services-triangle triangle-7">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 807.32 539.21">
            <polygon points="1.58 269.6 806.83 0.69 806.82 538.51 1.58 269.6"/>
          </svg>
        </div>

      </div>
    </div>
*/}

    
{/* 
    <div id="services-triangle-container">
      <svg version="1.1" viewBox="0 0 890 540" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px">
        <polygon points="6.5,424.71 785.85,5.73 884.5,534.45"/>
      </svg>
    </div>
*/}

{/* 
    <div id="qualifications-container">
      <div id="qualifications">
        <div id="question">Are you one of the 500,000 Pennsylvanians who qualify for community health choices?</div>
        <ul>
          <li>21+ years old and living in the community or a nursing home?</li>
          <li>Receive Medicaid or Medicare (dual eligible)?</li>
          <li>Have a physical disability or traumatic brain injury?</li>
          <li>An elder Pennsylvanian?</li>
        </ul>
      </div>
      <div id="qualifications-background-shape">
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 450 450" >
          <polygon points="383.5,385 12.5,446 437.5,4"/>
        </svg>
      </div>
    </div>
*/}
    </>
  );

}

export default Services;
