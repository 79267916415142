import { useState, useEffect } from 'react';

function useIsIntersecting (targetRefs, dataTriggerForRefCreation, rootMargin, threshold = .1) {

  const [isIntersecting, setIsIntersecting] = useState([]);
  const [isAboveRootMargin, setIsAboveRootMargin] = useState([]);

  useEffect(() => {

    // BEGIN INTERSECTION OBSERVER
    let intersectionCallback = (entries, observer, index) => { 

      entries.forEach(entry => {

        // Each entry describes an intersection change for one observed
        // target element:
        //   entry.boundingClientRect
        //   entry.intersectionRatio
        //   entry.intersectionRect
        //   entry.isIntersecting
        //   entry.rootBounds
        //   entry.target
        //   entry.time

        // let section = entry.target; 
        let newIsIntersecting = [...isIntersecting];
        
        if (entry.isIntersecting && (typeof isIntersecting[index]==='undefined' || isIntersecting[index]===false)) {  
          newIsIntersecting[index]=true;
          setIsIntersecting(newIsIntersecting);
          //console.log('index isIntersecting', index)
        } else if (!entry.isIntersecting && (typeof isIntersecting[index]==='undefined' || isIntersecting[index]===true)) {
          newIsIntersecting[index]=false;
         // console.log('index isIntersecting NOT', index)
         setIsIntersecting(newIsIntersecting);
        }

        let newIsAboveRootMargin = [...isAboveRootMargin];
        newIsAboveRootMargin[index] = entry.boundingClientRect.y < entry.rootBounds.y;
        /*
        console.log(
          'index', index, 
          'isIntersecting', newIsIntersecting[index], 
          'boundingClientRect.y', entry.boundingClientRect.y, 
          'window.scrollY', window.scrollY,
          'entry.rootBounds.y', entry.rootBounds.y,
          'isAbove', newIsAboveRootMargin[index] 
        );
        */
        //console.log('entry.rootBounds', entry.rootBounds);
        
        if (typeof isAboveRootMargin[index]==='undefined' || isAboveRootMargin[index]!==newIsAboveRootMargin[index]) {  
          //console.log('index newIsAboveRootMargin', index);
          setIsAboveRootMargin(newIsAboveRootMargin);
        }
      });
    };

    let options = { 
      root: null, // null = browser viewport; otherwise, use... document.querySelector('#scrollArea') // Must be an ancestor of the target
      rootMargin: rootMargin,
      threshold: threshold,
    }

    let observables = targetRefs.current;
    let observers = [];

    observables.forEach((observable, index) => {
      observers[index] = new IntersectionObserver((entries, observer) => intersectionCallback(entries, observer, index), options);
      observers[index].observe(observable);
    }); 
    //setIsIntersecting(Array(observables.length).fill(false));
   //  console.log('Array(observables.length).fill(false)', Array(observables.length).fill(false));
    // END INTERSECTION OBSERVER

    return () => {
      observables.forEach((observable, index) => {
        if (observers[index] && observable) observers[index].unobserve(observable);
      }); 
    }

  }, [targetRefs, dataTriggerForRefCreation, isIntersecting, isAboveRootMargin, rootMargin, threshold]);

  return [isIntersecting, isAboveRootMargin];
}

export default useIsIntersecting;
