import axiosInstance from '../../common/axiosInstance';

export function fetchMeeting(meetingId) {
  return new Promise((resolve, reject) => {
    const payload = {meetingId: meetingId};
    axiosInstance.post('/safety-committee-meeting.php', payload)
      .then(response => {
        // response.status, response.statusText, response.headers, response.config 
        if (response?.data) {
          const data = response.data;
          resolve(data);
        } else {
          reject();
        }
      })
      .catch ((err) => {
        console.log('Error', err);
      })
  });
}

export function fetchMeetings() {
  return new Promise((resolve, reject) => {
    axiosInstance.get('/safety-committee-meetings.php')
      .then(response => {
        if (response?.data) {
          const data = response.data;
          resolve(data);
        } else {
          reject();
        }
      })
      .catch ((err) => {
        console.log('Error', err);
      })
  });
}

export const deleteMeeting = (payload) => {

  return new Promise((resolve, reject) => {

    axiosInstance.post('/safety-committee-meeting-crud.php', payload)
      .then(response => {
        if (response.data && 
            response.data?.success===true && 
            response.data?.delete===true && 
            response.data?.meetingId>0) {
          resolve(response.data);
        } else {
          reject(response?.data?.error || 'API error'); 
        }
      })
      .catch(error => {
        reject(error);
      })
  });
}

export const updateMeeting = (payload) => {
  return new Promise((resolve, reject) => {

    axiosInstance.post('/safety-committee-meeting-crud.php', payload)
      .then(response => {
        const { data } = response;
        const { success, update, meetingId, error } = data;
        if (success===true && update===true && meetingId>0) {
          resolve(data);
        } else if (success===false) {
          reject(error || 'API error');
        } else {
          reject(error || 'API error'); 
        }
      })
      .catch(error => {
        reject(error);
      })
  });
}
