import React, { useState, useEffect, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Map from '../Map/Map';
import './ContactUs.scss';
import './Accordion.scss';
import '../FormModal/FormModal.scss';
import useIsIntersecting from '../../hooks/useIsIntersecting2';
import getMapCoordinates from '../../utilities/getMapCoordinates';

const submitUrl = process.env.REACT_APP_API_BASE_URL+'/submit-form.php';

const offices = [
  {
    index: 0,
    name: 'Headquarters',
    address: '53 Rittenhouse Place',
    city: 'Ardmore',
    state: 'PA',
    zipCode: '19003',
    fullAddress: '53 Rittenhouse Place, Ardmore, PA 19003',
    coordinates: {
      lat: 40.0059307,
      lng: -75.2905823
    }
  },
  {
    index: 1,
    name: 'Lancaster Office',
    address: '1525 Oregon Pike, Suite 1602',
    city: 'Lancaster',
    state: 'PA',
    zipCode: '17601',
    fullAddress: '1525 Oregon Pike, Suite 1602, Lancaster, PA 17601',
    coordinates: {
      lat: 40.0629743,
      lng: -76.3040458
    }
  } 
];

const ContactUs = ({ data, englishFormData, general }) => {

  const { title, messageUs, callUs, visitUsInArdmore, visitUsInLancaster, form } = data;
 
  const [formData, setFormData]= useState({
    formName: 'Contact Us',
    firstName: '',
    lastName: '',
    email: '',
    emailConsent: false,
    phone: '',
    phoneConsent: false,
    zipCode: '',
    message: '',
    checkboxes: [],
  })

  const formRef = useRef();
  const submitButtonRef = useRef();

  /* Prevent hitting return from submitting contact form. */
  const preventSubmitWithReturn = (e) => {
      var key = e.charCode || e.keyCode || 0;     
      if (key === 13 && e.target!==document.getElementById('message')) {
          e.preventDefault();
      }
  }

  const onChangeInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })

    if (e.target.value!=='') {
      e.target.setAttribute('data-filled', 'Y');
    } else {
      e.target.setAttribute('data-filled', 'N');
    }    
  }

  const validateEmail = (e) => {
    if (e.target.value!=='') {
      // ^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$
      if (!e.target.value.match(/^([A-Za-z0-9+_-]+)(\.[A-Za-z0-9+_-]+)*@([A-Za-z0-9-]+\.)+[A-Za-z]{2,6}$/g)) {
        e.target.setCustomValidity("Invalid format."); 
        return;
      };
    }
    e.target.setCustomValidity('');
  }

  const validatePhone = (e) => {
    if (e.target.value!=='') {
        var phoneDigits = e.target.value.replace(/[^0-9]/g, '');
        if (phoneDigits.length!==10) {
          e.target.setCustomValidity('Must be 10 digits long.'); 
          return;
        }
        e.target.value = '('+phoneDigits.substr(0,3)+') '+phoneDigits.substr(3,3)+'-'+phoneDigits.substr(6,4);
        }
    e.target.setCustomValidity(''); 
  }

  const validateZipCode = (e) => {
    if (e.target.value!=='') {
        var zipDigits = e.target.value.replace(/[^0-9]/g, '');
        if (zipDigits.length!==5 && zipDigits.length!==9) {
          e.target.removeAttribute('title'); 
          e.target.setCustomValidity('Must be 5 or 9 digits long.'); 
          return;
        }
        e.target.value = (zipDigits.length===9)
          ? zipDigits.substr(0,5)+'-'+zipDigits.substr(5,4)
          : zipDigits;
        }
    e.target.setCustomValidity('');
  }


  const [submitStatus, setSubmitStatus] = useState();
  
  const submitForm = async (e) => {
    if (!formRef.current || !submitButtonRef.current) return;

    // Note: onSubmit attribute in form tag prevents the form from being submitted but still click submit button with next line to use native validate functionality
    submitButtonRef.current.click();

    if (formRef.current && formRef.current.checkValidity()) {
      setSubmitStatus('submitted');
      await fetch(submitUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(formData) 
      })
        .then(response => response.json())
        .then(data => {
          // console.log(data); 
          if (data.success===true) {
            setSubmitStatus('success');
          } else {
            console.log('API Error: ', data.error);
            setSubmitStatus('error');
          }
        })
        .catch (err => {
          console.log('API Error: ', err);
          setSubmitStatus('error');
        });
    }
  }

  const scrollToInvalid = () => {
    (function () {
      // If not done as a closure, it will fire for every invalid field (not just the first one) on form submit
      var inView = false;
      return (e) => {
        if (!inView) {
          e.target.scrollIntoView({
            behavior: 'smooth', 
            block: 'start', 
            inline: 'nearest'
          })
        }; 
        inView = true;
      }
    })();
  }

  const checkboxRefs = useRef([]);
  
  const handleChangeCheckbox = (e) => {
    let newCheckboxes = [];
    checkboxRefs.current.forEach((el, index) => {
      if (el && el.checked) newCheckboxes.push(englishFormData.checkboxes.labels[index]); // el.value
    });
    setFormData({
      ...formData,
      checkboxes: newCheckboxes
    });
  }

  const animateRefs = useRef([]);
  const isIntersecting = useIsIntersecting(animateRefs, '0px 0px 0px 0px', .5);
  const [graphicClassName, setGraphicClassName] = useState('');
  const [accordionClassName, setAccordionClassName] = useState('');
  
  useEffect(() => {
    if (isIntersecting && isIntersecting[0]===true && graphicClassName==='') setGraphicClassName(' enter');
    if (isIntersecting && isIntersecting[1]===true && accordionClassName==='') setAccordionClassName(' enter');
  }, [isIntersecting, graphicClassName, accordionClassName]);

  /*
  useEffect(() => {
    console.log('isIntersecting', isIntersecting);
  }, [isIntersecting]);
  */
 
  const [mapLocations, setMapLocations] = useState([]);

  useEffect(()=> {
    if (mapLocations.length>0 || !offices || offices.length===0) return;
    try {
      Promise.all(offices.map(office => {
        return office.coordinates?.lat && office.coordinates?.lng 
          ? office.coordinates
          : getMapCoordinates(office.fullAddress)
      })).then(coordinates => {
        const mapLocations = offices.map((office, index) => ({
          address: office.fullAddress,
          coordinates: coordinates[index]
        }));
        setMapLocations(mapLocations);
      });
    } catch (error) {
      console.log("Get map coordinates error: "+error);
    }
  }, [mapLocations]);

  const [directionLinks0, setDirectionLinks0] = useState([]);
  const [directionLinks1, setDirectionLinks1] = useState([]);
  
  const [previousAccordionIndex, setPreviousAccordionIndex] = useState(-1);
  const [accordionIndex, setAccordionIndex] = useState(-1);

  const [rippleStyles, setRippleStyles] = useState({top: 0, left: 0});

  const switchAccordion = (e, index) => {
    // e.preventDefault();
    // e.stopPropagation();
    if (accordionIndex>-1 && previousAccordionIndex!==accordionIndex) {
      setPreviousAccordionIndex(accordionIndex);
    }
    if (index===accordionIndex) {
      setAccordionIndex(-1);
    } else {
      setAccordionIndex(index); 

      // Ripple 
      const rect = e.target.getBoundingClientRect();

      // Get click coordinates
      const left = e.clientX - rect.left - 250; /* 250 = half size of .ink circle */
      const top = e.clientY - rect.top - 250;
  
      setRippleStyles({top: top+'px', left: left+'px'})
    };

  };

  const handleAnimationEndAccordion = (e, index) => {
    if (( e.animationName==='exit-accordion-content' || 
          e.animationName==='exit-accordion-content-mobile'
        ) && previousAccordionIndex>-1) {
      //setPreviousAccordionIndexClass('collapsed')
      setPreviousAccordionIndex(-1);
    }
  }




  return (
    <div className="contact-us-container">

      <div className="background">
        <div className="left"></div>
        <div className="right"></div>  
      </div>
      <div className="pink-background"></div>

      <div className="page-heading">
        {title}
      </div>

      <div className="contact-us-content">


        <div ref={el => animateRefs.current[0] = el} className={`stretching-svg-container${graphicClassName}`}>
          <div className="stretching-container stretching-park">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 738.4 680">
              <g>
                <ellipse id="background-shape" className="st0" cx="359.3" cy="306.7" rx="323.2" ry="299.2"/>
                <g id="ground">
                  <defs>
                    <ellipse id="SVGID_1_" cx="359.7" cy="311.2" rx="323.6" ry="303.8"/>
                  </defs>
                  <clipPath id="SVGID_2_">
                    <use xlinkHref="#SVGID_1_" style={{overflow: 'visible'}} />
                  </clipPath>
                  <path className="st1" d="M48.3,634h646.4v-74.6c0,0-25.2-164-200.3-164S48.3,500.8,48.3,500.8V634z"/>
                  <path className="st1" d="M44.1,485.8h646.4v-49.2c0,0-25.2-108.3-200.3-108.3S44.1,397.9,44.1,397.9V485.8z"/>
                </g>
                <g id="middle-tree">
                  <circle className="st3" cx="461.4" cy="224.8" r="65.9"/>
                  <circle className="st3" cx="437.8" cy="165.6" r="65.9"/>
                  <circle className="st3" cx="371" cy="130.1" r="65.9"/>
                  <circle className="st3" cx="318.5" cy="217.2" r="65.9"/>
                  <circle className="st3" cx="382.8" cy="215.4" r="65.9"/>
                  <line className="st4" x1="388.7" y1="392.2" x2="388.7" y2="121.6"/>
                  <line className="st4" x1="389.7" y1="206.9" x2="443.5" y2="153.1"/>
                  <line className="st4" x1="386.9" y1="250.8" x2="333.1" y2="197"/>
                </g>
                <g id="right-tree">
                  <circle className="st3" cx="672.3" cy="289.2" r="54"/>
                  <circle className="st3" cx="652.9" cy="240.8" r="54"/>
                  <circle className="st3" cx="598.2" cy="211.7" r="54"/>
                  <circle className="st3" cx="555.2" cy="283" r="54"/>
                  <circle className="st3" cx="607.8" cy="281.6" r="54"/>
                  <line className="st4" x1="612.7" y1="426.3" x2="612.7" y2="204.8"/>
                  <line className="st4" x1="613.5" y1="274.6" x2="657.6" y2="230.5"/>
                  <line className="st4" x1="611.2" y1="310.6" x2="567.1" y2="266.5"/>
                </g>
                <g id="left-tree">
                  <circle className="st3" cx="156.1" cy="327.6" r="47.7"/>
                  <circle className="st3" cx="139" cy="284.8" r="47.7"/>
                  <circle className="st3" cx="90.6" cy="259" r="47.7"/>
                  <circle className="st3" cx="52.7" cy="322.1" r="47.7"/>
                  <circle className="st3" cx="99.2" cy="320.8" r="47.7"/>
                  <line className="st4" x1="103.5" y1="448.8" x2="103.5" y2="252.9"/>
                  <line className="st4" x1="104.2" y1="314.6" x2="143.2" y2="275.7"/>
                  <line className="st4" x1="102.1" y1="346.5" x2="63.2" y2="307.5"/>
                </g>
              </g>
            </svg>
          </div>
          <div className="stretching-container stretching-lion">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 738.4 680">
              <g id="lion">
                <path className="st5" d="M704.3,465.9c0,0-35.3,6.1-48.4-20.2c-13.1-26.2,7.1-50.4,7.1-50.4v17.2c0,0,15.1-24.2,22.2-26.2
                  c-2,4,1,19.2,1,19.2s15.1-24.2,11.1-38.3c-7.1-17.2,14.1,12.1,14.1,32.3c3-7.1,9.1-18.2,9.1-18.2s2,43.3-1,49.4
                  c12.1-4,16.1-9.1,16.1-9.1S729.5,458.9,704.3,465.9z"/>
                <path className="st6" d="M588.3,535.5c0,0,84.8-7.2,93.8-54.4c8.7-45.7-8.1-36.3-8.1-36.3s-6.7,2.3-5,23.2
                  c1.7,21.1-21.2,41.3-82.7,50.4C584.3,529.5,588.3,535.5,588.3,535.5z"/>
                <path className="st6" d="M602.8,409.1c0,0,17.4,66.2,17.4,93.4c10.1,39.3-1.3,90.2-6.3,113.4c19.2,1,49.7,14.1,41.4,43.3
                  c-2.8,2.1-5.8,3.8-9.1,5c0,0-12.1,7.1-23.2,2c-5,7.1-13.1,6-22.2,5c-9.1-1-26.2-3-34.3-8.1c-2-21.2,6-36.3,6-36.3
                  s-33.3-46.4-32.3-109.9"/>
                <path className="st6" d="M484.2,393.6c0,0-27.5,54.3-34.9,62.5c-5.2,0.9-20.6-10-23.6,5.5c0,0-2.4,9.5,11.2,11.8
                  c0,0-22.5-5.6-19.2,10.1c3.3,7.3,12.2,4.9,12.2,4.9s-11.3,9.8-7,14.9c4.3,5.2,12.8,1.7,16.4-0.2c2.6-1.5,5.2-3.1,7.7-4.9
                  c0,0-8.5,11.8,1.4,14.3c4.2,1.1,17.4-5.5,18.4-23.4c18.1-27,60-56.7,70.6-89.8"/>
                <path className="st6" d="M495,402.2c0,0-39.7,103.6,50.4,158.2"/>
                <path className="st7" d="M603.8,420.7l-22.2-60.9l-42.8-9.2c0,0-19.5,25.7-41.7,25.7c-22.3,0-2.5,23.3-2.5,23.3l1.8,23.9l-2.1,6.3
                  l-5.5,4.2l2.8,37.5l38.9,76.5l15.7,13.4l19-0.9L603.8,420.7z"/>
                <path className="st5" d="M472.6,243c-11.9-14.8-25.2-32.2-15.6-48.9c4.7,4.9,8.9,10.2,12.6,15.9c1.3-14.1,4.6-28,9.7-41.2
                  c0.5,5.3,1.8,10.6,3.8,15.5c8.5-18,26-32.3,41.8-44.4c-18.5,25.1-12.3,26.7-19.2,39.9c27.5-17.6,57.9-26.9,90.5-25.7l-23,9
                  c22.7,0.8,43.1,13.3,62.3,25.5c-5.1-2-10.9-0.9-14.8,2.9c11.2,4.7,22.6,9.5,32.3,16.8c9.7,7.3,17.7,17.7,19.7,29.7
                  c-3.5-4.6-8.5-7.9-14.1-9.3c10.2,19.2,18.9,39.2,25.9,59.7c-6.2-7.8-13.3-14.9-21-21.3c1.9,26,26.3,47.3,24.8,73.3l-11.7-16.6
                  c-0.5,19.3-4.7,38.2-12.3,55.9c-1.5,3.4-5.3,7.4-8.2,5.1c-0.7-5.1-0.2-10.3,1.5-15.2c-7,18.6-17,35.9-29.6,51.2
                  c2.4-7.9,4.1-16.1,5-24.3c-12.4,5.2-26.7,12.5-37.3,20.8c1.7-12.9,7.4-26.1,7.5-39.1s-2.2-12.5-11.7-21.4c-9.5-8.9-27-8.5-36.2,0.7
                  c-10.8,10.9-6.8,12.4-9.6,27.5c-1.4,7.4-14.2,22.1-18.5,27.2c-1.4,1.7-2.4-10.4-3.9-8.8c-3.2,3.3-6,2.8-9.5,6.2
                  c-4.2,4.2-1.5-11.2-4.9-11.4c-8.7-0.5-8.8,1-20.3,11.8c-3.3-8.1-23.7-31.2-23.7-39.9c0,0,44,12.3,59.1-7.9
                  c18.9-25.3,18.9-27.5,34.9-38.9c6.6-4.8,8.6-11.6-7.9-34.6c10.9,3.1,22.2-4.5,22.2-9.6c0.1-20.1,4.1-15.9-10.5-42.1
                  C550.4,214.7,499.5,203.5,472.6,243z"/>
                <path className="st8" d="M459.1,335.8c-3.1,15.4-0.6,25.3,3.7,30.2c4.3,4.9,17.9,14.2,42.5,6.8s21.8-6.6,34.8-27.6
                  c12.9-20.9,20.7-21.1,24.4-27.2c3.7-6.2-6.2-20.3-11.1-25.9c-4.9-5.5,1.2-3.1,8-3.1s11.1-4.9,13.6-14.2c2.5-9.2-4.3-23.4-4.3-23.4
                  s-8.7-23.2-26.9-30.7s-37.6-1.7-41.3-0.7c-6.8,1.8-28.2,15.3-28.2,21c0,5.7,2.9,16.8,2.5,24.6"/>
                <path className="st6" d="M476.7,265.6c0.4-7.8-2.5-18.9-2.5-24.6s21.4-19.2,28.2-21c3.7-1,23.2-6.8,41.3,0.7s26.9,30.7,26.9,30.7
                  s6.8,14.2,4.3,23.4c-2.5,9.2-6.8,14.2-13.6,14.2l-10-0.6"/>
                <path className="st6" d="M495.2,504.3C464.7,569,497.9,624,497.9,624s-13.1-11.1-31.3-9.1c-18.2,2-24.2,28.2-19.2,32.3
                  c5,4,14.1,4,21.2,6s47.4,0,56.5-7.1c9.1-7.1,3-26.2-8.1-40.3c10.1-10.1,28.1-45.4,28.1-45.4S510.2,539.5,495.2,504.3z"/>
                <path className="st9" d="M585.5,234.1c0,0,28.1,15.3,34.9,14.5c6.8-0.9,1.7,28.1-11.9,34c1.5-6.1,2.3-12.4,2.6-18.7l-9.4,6l6.8-13.6
                  l-8.5-3.4L585.5,234.1z"/>
                <path className="st5" d="M505,223.1l5.1,10.6c0,0,9.7-10.1,35.9,0C531.7,218,518.8,220.8,505,223.1z"/>
                <path className="st5" d="M488.1,233l2.1,8.2c0,0-7.6-4.2-14.4,7.6C476.4,240.5,476,240,488.1,233z"/>
                <path className="st10" d="M520.2,253.4c-2.8-5.8-2.1-12.6,1.7-17.7"/>
                <path className="st11" d="M522.2,236.2c0,0-5.6,6.8-1.6,16.5c9.1-3.2,21.2-2.6,21.2-2.6S540.2,236,522.2,236.2z"/>
                <path className="st12" d="M523.2,252.3c0-5.1,0-15.5,5.9-15c3.8,0.3,8.2,8.1,8.2,13.2"/>
                <path className="st10" d="M517,236c0,0,12.4-2.3,20.2,4.6c2.8,2.5,4.6,5.9,5.1,9.7"/>
                <ellipse transform="matrix(0.3427 -0.9395 0.9395 0.3427 -148.3693 713.9255)" className="st9" cx="436" cy="463" rx="3.7" ry="5.4"/>
                <ellipse transform="matrix(0.953 -0.3029 0.3029 0.953 -125.4482 152.2045)" className="st9" cx="428" cy="480.6" rx="5.4" ry="3.7"/>
                
                  <ellipse transform="matrix(0.8827 -0.4699 0.4699 0.8827 -172.3357 268.1221)" className="st9" cx="450.9" cy="479.3" rx="8.4" ry="12.2"/>
                
                  <ellipse transform="matrix(0.8246 -0.5657 0.5657 0.8246 -205.0109 331.7076)" className="st9" cx="432.5" cy="496.5" rx="5.4" ry="3.7"/>
                
                  <ellipse transform="matrix(0.6973 -0.7168 0.7168 0.6973 -223.5389 477.3311)" className="st9" cx="453.4" cy="503.3" rx="5.4" ry="3.7"/>
                <path className="st11" d="M480.7,265.9l11.7-7.6c0,0-0.7-10.5-4.8-12.1c-4.1-1.6-7.9,4.6-7.9,4.6L480.7,265.9z"/>
                <path className="st13" d="M483.6,245.8c0,0-4.1,2.2-0.3,18.1c2.1-1.7,4.4-3.3,6.7-4.8C490,259.1,489.2,244.8,483.6,245.8z"/>
                <path className="st10" d="M490.5,259.5c-0.5-14.3-6.6-13.9-6.6-13.9s-5.5,0.2-0.9,19.5"/>
                <path className="st10" d="M493.9,258c0,0-7.7,3.1-10.7,7.4"/>
                <path className="st10" d="M488.4,242.6c0,0-4.5,1.4-6.8,5s-3,8-0.8,17.5"/>
                <path className="st6" d="M494.8,266.2c0,0-39.1-8.6-58.2,17.3c-5.4,12.6,10.2,44.9,22.5,52.3c4.3-4.3,6-18.1,14.1-19.4
                  c8-1.2,33.4,22.4,46.3,12.6c14.7-11.2,20.3-28.3,20.3-28.3"/>
                <path d="M522,313c5,1.4,10.1,2.8,15.1,4.3c4.8,1.3,9.5,2.9,14.1,4.9c7.8,3.7,15,8.7,21.2,14.7c3.6,3.4,6.9,7,10.1,10.9
                  c0.5,0.6,1.3-0.2,0.8-0.8c-5.5-6.9-11.8-13-18.9-18.2c-7.5-5.4-15.8-9.4-24.7-11.9c-5.8-1.7-11.6-3.3-17.4-5
                  c-0.3-0.1-0.6,0.1-0.7,0.4C521.5,312.6,521.7,313,522,313L522,313z"/>
                <path className="st10" d="M485.3,244.7c0,0,6.8,2.6,7.5,13.1"/>
                <path className="st10" d="M545.9,251.1c0,0-17.5-2.8-30.8,4.6"/>
                <path className="st14" d="M551.4,288.4c0,0-6.7-4.1-13.4,15c-10.6,30.4-28.7,35.2-52.8,18c-2.9-2.1-8.4-6.7-13.4-5.4
                  c-2.1,0.6-5.7,3.5-10.1,14.3c-0.8,1.6-1.7,3.2-2.8,4.7c-10.6-8.5-21.1-25.5-22.9-46.2c0.1-8.4,9.7,2.8,21.2,0.6
                  c15.2-2.8,32.7-13,32.7-13s15.5-11.9,37.9-5C542,275.9,551.4,288.4,551.4,288.4z"/>
                <path d="M520.3,287.9l12.8-7c4.2-2.4,8.6-4.5,13.1-6.4c8.5-3.1,17.4-4.8,26.4-4.9c5.1-0.1,10.2,0.1,15.3,0.7c0.7,0.1,0.7-1.1,0-1.2
                  c-9.1-1.1-18.2-1-27.3,0.4c-4.5,0.7-9,1.8-13.3,3.4c-4.4,1.7-8.7,3.6-12.8,5.9c-4.9,2.7-9.8,5.4-14.8,8
                  C519.1,287.2,519.7,288.2,520.3,287.9L520.3,287.9z"/>
                <path className="st15" d="M536.9,307.3c0,0-7.6,46.4-30.5,51.1c-12.7,2.5-16.4-7.8-22-9.1c-6.2-1.4-18.1,0.5-18.1,0.5
                  s11.9-19.7,10.9-32.5C508,332.3,516.5,344.2,536.9,307.3z"/>
                <path className="st16" d="M478.1,348.6c-9.6,1.3,9.7-9.5,15.2-3.3c12.1-3.3,16.7,11.9,16.7,11.9s-8.6,3.1-12.9,0.9s-8.6-6.9-8.6-6.9
                  C485.5,349,481.8,348.1,478.1,348.6z"/>
                <path className="st10" d="M552.7,300.1c0,0-16.6,3.7-19.1-6.2"/>
                <path d="M532.7,299.8l14.2-2.2c4.8-0.8,9.6-1.3,14.4-1.5c9-0.1,17.9,1.4,26.3,4.3c4.9,1.6,9.7,3.6,14.3,5.9c0.7,0.3,1.2-0.7,0.6-1
                  c-8.2-4.1-16.9-7.1-25.8-9c-4.4-0.9-8.8-1.3-13.3-1.4c-4.8,0-9.6,0.4-14.4,1.2c-5.5,0.8-11.1,1.7-16.7,2.6
                  C531.7,298.8,532,299.9,532.7,299.8L532.7,299.8z"/>
                <path className="st9" d="M497.5,276.4c0,0-6.2-6.2-19.1,0s-19.1,11.7-27.7,11.7c-4.3,0-8.6-4.6-10.8-5.3c-2.2-0.7-3.2,1.5-3.2,1.5
                  s-2.8,5,4.7,13.7c6.7,7.8,9.2,9.2,22.1,6.2s6.2-8,13.5-13.5c7.4-5.5,12.9-3.1,16.6-5.5S497.5,276.4,497.5,276.4z"/>
                <path d="M453.2,319.5c-5.3,7.6-9.1,16.1-10.3,25.4c-0.7,7.1-0.2,14.3,1.5,21.2c0.7,3.3,1.7,6.6,2.5,10c0.2,0.7,1.3,0.4,1.1-0.3
                  c-1.5-6-3.2-12-3.9-18.1c-1-7.6-0.2-15.3,2.3-22.6c1.9-5.3,4.5-10.3,7.8-14.9C454.6,319.5,453.6,318.9,453.2,319.5L453.2,319.5z"/>
                <path d="M447.2,310.4c-9.6,3.5-17.8,9.9-23.7,18.2c-2.4,3.5-4.2,7.4-5.4,11.4c-0.1,0.3,0.2,0.6,0.5,0.7c0.3,0,0.5-0.1,0.6-0.4
                  c3.5-12.1,12.7-21.3,23.8-26.8c1.5-0.7,3-1.4,4.5-2C448.2,311.2,447.8,310.1,447.2,310.4L447.2,310.4z"/>
                <path d="M441.8,302.1c-7.9-2.4-16.4-2-24,1.2c-6.7,2.9-12.4,7.6-17.4,12.9c-2.5,2.7-4.9,5.6-7.2,8.5c-0.5,0.6,0.4,1.4,0.8,0.8
                  c8.5-10.7,18.8-21.8,33.2-23.6c4.8-0.5,9.7-0.1,14.3,1.3c0.3,0.1,0.6-0.2,0.7-0.5C442.3,302.5,442.1,302.2,441.8,302.1L441.8,302.1
                  z"/>
                <ellipse transform="matrix(0.9853 -0.1711 0.1711 0.9853 -34.5467 93.681)" cx="526.3" cy="247.3" rx="3.2" ry="4.9"/>
                <ellipse transform="matrix(0.9853 -0.1711 0.1711 0.9853 -37.0379 86.7)" cx="484.6" cy="258.3" rx="2.7" ry="4.1"/>
                <circle cx="515.1" cy="310.6" r="1.7"/>
                <circle cx="523.5" cy="299.7" r="1.7"/>
                <circle cx="515.3" cy="289.7" r="1.7"/>
                <circle cx="458.1" cy="324.1" r="1.7"/>
                <circle cx="458.3" cy="313" r="1.7"/>
                <circle cx="444.7" cy="306.8" r="1.7"/>
                <path className="st6" d="M594.5,252.9c-13.8-17.2-1.1-23.3-1.1-23.3s9-6.7,16.3,16.5c0.6-6.2-1.8-21.9,7.6-22.5
                  c14.6-0.9,10.9,7.6,11.9,21.3c1.1-5.9-1.1-16.7,9.8-14.8c19,7.8,3.8,26.6,2.6,38c-1.9,18.6-2.4,35.1-4.2,52.8
                  c-1,9.5-15.2,67.5-32.2,81.8c-17,14.3-45.2-0.6-45.2-0.6c-22.3-13-3.5-61.6,23.6-81.5c5.5-18.9,7-25.8,8.4-46
                  c-4.4-4.5-16.9-13.5-13.5-22.4c0,0,2.1-6.5,11.6-5.3"/>
                <path className="st10" d="M468.9,652.5c0,0-5.9-15.3,2.8-26.1"/>
                <path className="st10" d="M640.3,666.1c0,0,5.9-15.3-2.8-26.1"/>
                <path className="st10" d="M623.3,665.8c0,0,5.9-15.3-2.8-26.1"/>
                <path className="st10" d="M454.9,649.2c0,0-5.9-15.3,2.8-26.1"/>
              </g>
            </svg>
          </div>
          <div className="stretching-container stretching-patient">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 738.4 680">
              <g id="patient">
                <path className="st17" d="M237.7,326.2c0,0-4.6-30.9-13.4-40.3c-4.3-4.7,20.4-9.3,20.4-9.3s19.6,41.4,35.6,43.2
                  C286,320.4,237.7,326.2,237.7,326.2z"/>
                <g>
                  <path className="st18" d="M220,284.1c0.9,0.7,1.9,1.1,3.1,1.2c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c0,0-0.4,0-0.4,0l0,0h-0.1
                    h-0.2l-0.3-0.1l-0.2-0.1c-0.1,0-0.1,0,0,0h-0.1c-0.4-0.2-0.9-0.5-1.3-0.8c-0.3-0.2-0.7-0.1-0.9,0.2c0,0,0,0,0,0
                    C219.5,283.5,219.6,284,220,284.1L220,284.1z"/>
                  <path className="st18" d="M247.1,293.3c-0.7,0.8-1.1,1.9-1.2,3c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7c0-0.1,0-0.2,0-0.4
                    c0,0.1,0,0.1,0,0.1c0-0.1,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.2,0.1-0.3l0.1-0.2c0-0.1,0-0.1,0,0c0,0,0-0.1,0-0.1
                    c0.2-0.4,0.4-0.8,0.7-1.2c0.2-0.3,0.2-0.7-0.1-0.9c-0.3-0.2-0.7-0.2-0.9,0.1C247.1,293.2,247,293.2,247.1,293.3L247.1,293.3z"/>
                  <path className="st17" d="M251.6,260.4c1.1,9.1,0.6,18.9-0.2,21.8c-4.2,14.5-2.6,19.8-14.8,15c-15.6-6.2-25.3-7.1-34.1-24.3
                    c-9.4-18.6-3.5-37.1,12-42.2C229.9,225.5,248.8,238.5,251.6,260.4z"/>
                  <path className="st19" d="M235.3,280.2c4.4-1.9,7.1-6.3,6.7-11.1l-18.6,8.9C223.4,278,229.2,283,235.3,280.2z"/>
                  <path className="st20" d="M235.1,242.3c-2.8-0.5-5.5,0.6-8.1,1.8c-0.8,0.4-0.1,1.6,0.7,1.2c2.1-1.1,4.6-2.1,7-1.7
                    c0.4,0.1,0.7-0.1,0.9-0.5C235.7,242.7,235.4,242.4,235.1,242.3L235.1,242.3z"/>
                  <path className="st20" d="M211.6,250.4c-2.7,1-4.6,3.2-6.1,5.5c-0.2,0.3-0.1,0.7,0.2,0.9c0.3,0.2,0.7,0.1,0.9-0.2c1.4-2,3-4,5.3-4.9
                    c0.4-0.1,0.6-0.4,0.6-0.8c-0.1-0.4-0.4-0.6-0.8-0.6C211.6,250.4,211.6,250.4,211.6,250.4z"/>
                  <path className="st21" d="M224.4,260.8c0,0-0.4,5.9,0.8,6.5s4.4-0.5,4.4-0.5"/>
                  <circle className="st22" cx="232.8" cy="250.5" r="2"/>
                  <circle className="st22" cx="214.3" cy="259" r="2"/>
                  <path className="st17" d="M200.5,265.8c0,0-3.9-0.8-3.9,0.8c0,1.5,3.1,9.5,6.4,12.1c3.3,2.6,3.3-6.2,3.3-6.2L200.5,265.8z"/>
                  <path className="st17" d="M244.2,245.4c0,0,1.9-3.4,3.1-2.4c1.2,1,5.3,8.5,5.1,12.7c-0.2,4.2-6.9-1.4-6.9-1.4L244.2,245.4z"/>
                  <path className="st20" d="M200.6,267.9c-5.4-12.9,0.3-9.5,1-24.1c10.7-4.3,16.2-9,26.8-10c11.1-0.9,18.5,12.2,18.5,12.2
                    s-7.7-18.6-15.5-22.5C221.4,215.4,173.5,228.3,200.6,267.9z"/>
                  <path className="st18" d="M233.4,256.5c2.1,0.2,4.3-0.2,6.1-1.3c0.3-0.2,0.4-0.6,0.2-0.9c-0.2-0.3-0.6-0.4-0.9-0.2
                    c-1.6,0.9-3.5,1.3-5.4,1.1c-0.4,0-0.7,0.4-0.6,0.8C232.8,256.2,233,256.5,233.4,256.5L233.4,256.5z"/>
                  <path className="st18" d="M218,261.5L218,261.5l-0.2,0.2l-0.2,0.3c-0.2,0.2-0.3,0.4-0.5,0.6c-0.3,0.4-0.7,0.7-1,1
                    c-0.3,0.3-0.7,0.6-1.1,0.8c-0.2,0.1-0.4,0.2-0.6,0.3l0,0l-0.2,0.1l-0.4,0.1c-0.4,0.1-0.6,0.5-0.5,0.8c0,0,0,0,0,0
                    c0.1,0.4,0.5,0.6,0.9,0.5c2-0.8,3.8-2.2,5-4c0.2-0.3,0.1-0.8-0.2-1C218.7,261,218.2,261.1,218,261.5L218,261.5z"/>
                  <path className="st18" d="M222.2,237.7c-0.9,0.6-1.8,1-2.8,1.3c-0.9,0.2-1.7,0.3-2.6,0.5c-0.8,0.2-1.6,0.5-2.3,1
                    c-0.9,0.7-1.5,1.5-1.9,2.5c-0.2,0.3-0.1,0.7,0.3,0.9c0.3,0.2,0.7,0.1,0.9-0.2c0,0,0,0,0,0c0.3-0.9,0.9-1.7,1.7-2.2
                    c0.7-0.4,1.4-0.6,2.1-0.8c1.9-0.2,3.7-0.9,5.4-1.9C223.6,238.3,222.9,237.1,222.2,237.7L222.2,237.7z"/>
                  <path className="st18" d="M220.8,242.5l-0.4,0.2l-0.2,0.1l-0.1,0.1l0,0L220,243h-0.2l-0.4,0.1c-0.4,0.1-0.9,0.2-1.4,0.3
                    c-1.1,0.2-2,0.9-2.4,2c-0.2,0.3-0.1,0.7,0.3,1c0.3,0.2,0.7,0.1,0.9-0.2c0,0,0,0,0,0c0.1-0.1,0.1-0.3,0.2-0.4
                    c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.2,0-0.1c0.1-0.1,0.2-0.2,0.2-0.3c0,0,0.1-0.1,0.1-0.1l0.1-0.1l0.3-0.2c0.1,0,0.1,0,0,0l0.1-0.1
                    c0.2-0.1,0.4-0.1,0.6-0.2c1-0.1,2-0.5,2.9-1c0.3-0.2,0.4-0.6,0.2-1C221.5,242.4,221.1,242.3,220.8,242.5L220.8,242.5z"/>
                  <path className="st18" d="M246,269.5c0,0.2,0,0.5,0,0.7s0,0.3,0,0.4c0,0.1,0,0.1,0,0.2c0-0.1,0-0.1,0,0v0.1c0,0.2-0.1,0.5-0.2,0.7
                    c0,0.1,0,0.1-0.1,0.2c0-0.1,0-0.1,0,0v0.1c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.3-0.1,0.7,0.2,0.9c0,0,0,0,0,0c0.3,0.2,0.8,0.1,1-0.2
                    c0.6-1,0.8-2.1,0.7-3.2c0-0.4-0.3-0.7-0.7-0.7C246.4,268.9,246.1,269.2,246,269.5L246,269.5z"/>
                  <path className="st18" d="M241.6,265.4h0.7h0.4c0.1,0,0.1,0,0.2,0c-0.1,0-0.1,0,0,0h0.1c0.2,0,0.5,0.1,0.7,0.2h0.2l0,0h0.1
                    c0.1,0.1,0.2,0.1,0.3,0.2c0.3,0.2,0.7,0.1,0.9-0.2c0.2-0.3,0.1-0.8-0.2-1c-1-0.6-2.1-0.8-3.2-0.7c-0.4,0-0.7,0.2-0.8,0.6
                    c0,0.4,0.2,0.7,0.6,0.8c0.1,0,0.1,0,0.2,0L241.6,265.4z"/>
                </g>
                <path className="st17" d="M231.8,207.3c0,0-11.4-1.7-19.6-7.6c-8.2-5.9,4.5-3,7.2-0.4c0,0-13.9-18.5-11.4-20.4c2.4-1.8,3.4-1,5.3-1.6
                  c4.3-1.5,7,3.9,9.5,4.2c3.9,0.3,17.7,25.8,17.7,25.8L231.8,207.3z"/>
                <path className="st23" d="M168.1,467.9c0,0,1.7-24.2,17.8-22.8s14.4,26.9,14.4,26.9l4.2,176.2l-8.7,0.8l-4.9-182.3c0,0,0-11.7-7-11.7
                  s-7.6,12.7-7.6,12.7L168.1,467.9z"/>
                <path className="st17" d="M191.8,435.9c0,0-18.5,8.3-18.9,16.9s14.5-2.4,14.5-2.4s3.9,8.2,7.4,7.8c3.5-0.4,3.1-17.2,3.1-17.2
                  L191.8,435.9z"/>
                <path className="st22" d="M220.7,652.8c0,0,2.6-6.9,9-10.4c6.5-3.5,20-7.3,20-7.3l11.6,0.4l1.5,15.4L220.7,652.8z"/>
                <path className="st22" d="M264.5,652.6c0,0,2.1-7.1,8.3-11c6.2-3.9,19.5-8.6,19.5-8.6l11.6-0.4l2.5,15.2L264.5,652.6z"/>
                <path className="st24" d="M237.8,493.2c0.7,36.5,1.9,141.8,1.9,141.8l25.1,3.6c0,0,4-118.3,4-130.5c0-12.2,5.7-73.5,5.7-73.5
                  s5,58.7,5,65.9s0.3,135.2,0.3,135.2l28.6,1.4c0,0,2.9-138.6,2.9-157.2s4.9-71.6-1.6-82.3c-6.4-10.7-72.8,3.5-72.8,3.5
                  S237,456.7,237.8,493.2z"/>
                <path className="st22" d="M314.1,423l-78.9,4.3l-7.6-75.3l-29.2,92.5l-8.6-8.2c0,0,15.1-100.5,18.3-110.3c3.2-9.7,25.2-22.1,25.2-22.1
                  s7.9,10.4,16.8,6.1c10.5-5.1,3.8-16.2,3.8-16.2s25.2-18.8,18.9-39.1c-9-18.2-42.1-46.1-42.1-46.1s2.1-8.4,6.3-10.5
                  c22.7,8.7,53.9,50,53.9,50s5.9,3.4-4.7,58.3C308.4,341,314.1,423,314.1,423z"/>
                <polygon className="st22" points="193.3,655.2 195.7,646.7 204.6,646.9 207.4,654.9"/>
              </g>

            </svg>
          </div>
        </div>


        <div ref={el => animateRefs.current[1] = el} className={`accordion-container${accordionClassName}`}>
          <div className="accordion">
            <dl>
              <dt 
                className={`accordion-title tile-even ripple${accordionIndex===0 ? ' expanded' : ''}`} 
                onClick={(e) => switchAccordion(e, 0)}
              >
                <div>{messageUs}</div> 
                {accordionIndex===0 &&
                  <span className="ink" style={rippleStyles}></span>
                }
              </dt>

              <dd 
                className={`accordion-content
                  ${accordionIndex===0 ? ' expand-content' : ''}
                  ${previousAccordionIndex===0 ? ' collapse-content' : ''}
                  ${accordionIndex!==0 && previousAccordionIndex!==0 ? ' collapsed' : ''}
                `}
                onAnimationEnd={(e) => handleAnimationEndAccordion(e, 0)}
              >
                <div className="form-wrapper">
                  <div className="form-container" onKeyPress={(e) => preventSubmitWithReturn(e)}>
                    {submitStatus==='success'  
                      ? <div id="success-message">
                          <div>{ReactHtmlParser(form.successMessage.header)}</div>
                          <div>{ReactHtmlParser(form.successMessage.copy)}</div>
                        </div> 
                      : <form id="form" ref={formRef} autoComplete="on" onSubmit={(e) => e.preventDefault()} method="post" acceptCharset="utf-8">
                          <div className="form-row">
                            <div className="form-field">
                              <label className="form-input-label-tag">
                                <input className="form-input-field" name="firstName" id="first-name" type="text" placeholder="&nbsp;" 
                                    data-filled={formData.firstName!=='' ? 'Y' : 'N'} 
                                    value={formData.firstName}
                                    onChange={(e) => onChangeInput(e)}
                                    onInvalid={(e) => scrollToInvalid(e)} 
                                    required
                                /> 
                                <span className="form-input-desc">{form.firstName}</span>
                                <span className="form-input-border"></span>
                              </label>
                            </div>
                            <div className="form-field">
                              <label className="form-input-label-tag">
                                <input className="form-input-field" name="lastName" id="last-name" type="text" placeholder="&nbsp;" 
                                  data-filled={formData.lastName!=='' ? 'Y' : 'N'}
                                  value={formData.lastName}  
                                  onChange={(e) => onChangeInput(e)} 
                                  onInvalid={(e) => scrollToInvalid(e)}
                                  required 
                                />
                                <span className="form-input-desc">{form.lastName}</span>
                                <span className="form-input-border"></span>
                              </label>
                            </div>
                          </div>

                          <div className="form-row phone-zipcode-row">
                            <div className="form-field">
                              <label className="form-input-label-tag">
                                  <input className="form-input-field" name="zipCode" id="zipCode" placeholder="&nbsp;"
                                    data-filled={formData.zipCode!=='' ? 'Y' : 'N'} 
                                    value={formData.zipCode}  
                                    onChange={(e) => onChangeInput(e)} 
                                    onBlur={(e) => validateZipCode(e)} 
                                    onInvalid={(e) => scrollToInvalid(e)}
                                    maxLength={10}
                                  />
                                  <span className="form-input-desc">{form.zipCode}</span>
                                  <span className="form-input-border"></span>
                              </label>
                            </div>
                            <div className="form-field">
                              <label className="form-input-label-tag">
                                <input className="form-input-field" name="phone" id="phone" type="tel" placeholder="&nbsp;"
                                  data-filled={formData.phone!=='' ? 'Y' : 'N'} 
                                  value={formData.phone}  
                                  onChange={(e) => onChangeInput(e)} 
                                  onBlur={(e) => validatePhone(e)} 
                                  onInvalid={(e) => scrollToInvalid(e)}
                                />
                                <span className="form-input-desc">{form.phone}</span>
                                <span className="form-input-border"></span>
                              </label>
                            </div>
                          </div>

                          <div className="form-row phone-consent-row">
                            <div className="form-row checklist">
                                <div className="cbx">
                                  <input 
                                    id="cbx-phoneConsent"
                                    type="checkbox" 
                                    name="phoneConsent" 
                                    value={formData.phoneConsent}
                                    onClick={(e) => handleChangeCheckbox(e)}
                                    required={formData.phone!==''} 
                                  />
                                  <label htmlFor="cbx-phoneConsent"></label>
                                  <svg width="15" height="14" viewBox="0 0 15 14" fill="none">
                                    <path d="M2 8.36364L6.23077 12L13 2"></path>
                                  </svg>
                                </div>
                                <div>{general.phoneConsent}</div>
                            </div>
                          </div>

                          <div className="form-row">
                              <div className="form-field">
                                  <label className="form-input-label-tag">
                                      <input className="form-input-field" name="email" id="email" placeholder="&nbsp;" 
                                          data-filled={formData.email!=='' ? 'Y' : 'N'} 
                                          value={formData.email}
                                          onChange={(e) => onChangeInput(e)} 
                                          onBlur={(e) => validateEmail(e)} 
                                          onInvalid={(e) => scrollToInvalid(e)}
                                          required 
                                      />
                                      <span className="form-input-desc">{form.email}</span>
                                      <span className="form-input-border"></span>
                                  </label>
                              </div>
                          </div>

                          <div className="form-row email-consent-row">
                            <div className="form-row checklist">
                                <div className="cbx">
                                  <input 
                                    id="cbx-emailConsent"
                                    type="checkbox" 
                                    name="emailConsent" 
                                    value={formData.emailConsent}
                                    onClick={(e) => handleChangeCheckbox(e)} 
                                    required
                                  />
                                  <label htmlFor="cbx-emailConsent"></label>
                                  <svg width="15" height="14" viewBox="0 0 15 14" fill="none">
                                    <path d="M2 8.36364L6.23077 12L13 2"></path>
                                  </svg>
                                </div>
                                <div>{general.emailConsent}</div>
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="form-field">
                              <label className="form-input-label-tag">
                                <textarea className="form-input-field" name="message" id="message" placeholder="&nbsp;" 
                                  data-filled={formData.message!=='' ? 'Y' : 'N'} 
                                  onChange={(e) => onChangeInput(e)} 
                                  value={formData.message}
                                  onInvalid={(e) => scrollToInvalid(e)} 
                                /> 
                                <span id="message-desc" className="form-input-desc">{form.message}</span>
                                <span className="form-input-border"></span>
                              </label>
                            </div>
                          </div>

                          <div className="form-row checklist-header">
                            {form.checkboxes.header}
                          </div>

                          {form.checkboxes.labels.map((label, index) => (
                            <div key={'checklist-row-'+index} className="form-row checklist">
                              <div className="cbx">
                                <input 
                                  id={'cbx'+index} 
                                  type="checkbox" 
                                  name="checkboxes[]" 
                                  ref={el => checkboxRefs.current[index] = el}
                                  value={label}
                                  onClick={(e) => handleChangeCheckbox(e)} 
                                />
                                <label htmlFor={'cbx'+index}></label>
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none">
                                  <path d="M2 8.36364L6.23077 12L13 2"></path>
                                </svg>
                              </div>
                              <div>{label}</div>
                            </div>
                          ))}

                          <svg id="goo-svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1">
                            <defs>
                              <filter id="goo">
                                <feGaussianBlur in="SourceGraphic" stdDeviation="4" result="blur"></feGaussianBlur>
                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7" result="goo"></feColorMatrix>
                                <feBlend in="SourceGraphic" in2="goo"></feBlend>
                              </filter>
                            </defs>
                          </svg>


                          <button ref={submitButtonRef} type="submit" style={{display: 'none'}} />

                          <div id="form-submit-button" onClick={(e)=>submitForm(e)} >
                              <div id="form-submit-button-background" className={submitStatus}>
                                {submitStatus==='submitted' 
                                  ? <svg id="submit-spinner" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="0px" height="0px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                      <circle cx="40" cy="40" fill="none" stroke="#e15b64" strokeWidth="5" r="10" strokeDasharray="47.12388980384689 17.707963267948966">
                                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 40 40;360 40 40" keyTimes="0;1"></animateTransform>
                                      </circle>
                                    </svg>
                                  : <>{form.submitButton}</>
                                }
                              </div>
                          </div>
                
                          {submitStatus==='error' && 
                            <div id="form-submit-error">
                              <div id="icon-error" />
                              {form.errorMessage}
                            </div>
                          }
                
                        </form>
                        
                    }
                  </div>
                </div>
              </dd>


              <dt 
                className={`accordion-title tile-odd ripple${accordionIndex===1 ? ' expanded' : ''}`} 
                onClick={(e) => switchAccordion(e, 1)}
              >
                <div>{callUs}</div>
                {accordionIndex===1 &&
                  <span className="ink" style={rippleStyles}></span>
                } 
              </dt>

              <dd 
                className={`accordion-content
                  ${accordionIndex===1 ? ' expand-content' : ''}
                  ${previousAccordionIndex===1 ? ' collapse-content' : ''}
                  ${accordionIndex!==1 && previousAccordionIndex!==1 ? ' collapsed' : ''}
                `}
                onAnimationEnd={(e) => handleAnimationEndAccordion(e, 1)}
              >
                <div className="contact-phone"><a href="tel:267-571-5570">(267) 571-5570</a></div>
              </dd>

              <dt 
                className={`accordion-title tile-even ripple${accordionIndex===2 ? ' expanded' : ''}`} 
                onClick={(e) => switchAccordion(e, 2)}
              >         
                <div>{visitUsInArdmore}</div> 
                {accordionIndex===2 &&
                  <span className="ink" style={rippleStyles}></span>
                }
              </dt>
              
              <dd 
                className={`accordion-content
                  ${accordionIndex===2 ? ' expand-content' : ''}
                  ${previousAccordionIndex===2 ? ' collapse-content' : ''}
                  ${accordionIndex!==2 && previousAccordionIndex!==2 ? ' collapsed' : ''}
                `}
                onAnimationEnd={(e) => handleAnimationEndAccordion(e, 2)}
              >
                <div className="map-wrapper">
                  <div>
                    <div className="map-container">
                      <div>
                        <Map
                          mapZoom={14}
                          mapLocations={mapLocations.length>0 && [mapLocations[0]]}
                          directionLinks={directionLinks0}
                          setDirectionLinks={setDirectionLinks0}
                        />
                      </div>
                      <a className="directions-link" href={directionLinks0[0]} target="_blank" rel="noreferrer">
                        <div></div>
                      </a>
                    </div>
                    <div className="location-address">
                      {offices && offices.length>0 &&
                      <>
                      <div>
                        {offices[0].address}
                      </div>
                      <div>
                        {offices[0].city}, {offices[0].state} {offices[0].zipCode}
                      </div>
                      </>
                      }
                    </div>
                  </div>
                </div>
              </dd>

              <dt 
                className={`accordion-title tile-odd ripple${accordionIndex===3 ? ' expanded' : ''}`} 
                onClick={(e) => switchAccordion(e, 3)}
              >  
                <div>{visitUsInLancaster}</div> 
                {accordionIndex===3 &&
                  <span className="ink" style={rippleStyles}></span>
                }
              </dt>

              <dd 
                className={`accordion-content
                  ${accordionIndex===3 ? ' expand-content' : ''}
                  ${previousAccordionIndex===3 ? ' collapse-content' : ''}
                  ${accordionIndex!==3 && previousAccordionIndex!==3 ? ' collapsed' : ''}
                `}
                onAnimationEnd={(e) => handleAnimationEndAccordion(e, 3)}
              >
                <div className="map-wrapper">
                  <div>
                    <div className="map-container">
                      <div>
                        <Map
                          mapZoom={14}
                          mapLocations={mapLocations.length>1 && [mapLocations[1]]}
                          directionLinks={directionLinks1}
                          setDirectionLinks={setDirectionLinks1}
                        />
                      </div>
                      <a className="directions-link" href={directionLinks1[0]} target="_blank" rel="noreferrer">
                        <div></div>
                      </a>
                    </div>   
                    <div className="location-address">
                      {offices && offices.length>1 &&
                      <>
                      <div>
                        {offices[1].address}
                      </div>
                      <div>
                        {offices[1].city}, {offices[1].state} {offices[1].zipCode}
                      </div>
                      </>
                      }
                    </div>
                  </div>
                </div>                
              </dd>
            </dl>
          </div>
        </div>

      </div>

    </div>
  )
}

export default ContactUs;
