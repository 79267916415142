import React, { useState, useEffect, useRef } from 'react';
import { enableBodyScroll } from 'body-scroll-lock'; // disableBodyScroll, // from 'body-scroll-lock'; // clearAllBodyScrollLocks
import ReactHtmlParser from 'react-html-parser';
import useDisableBodyScroll from '../../hooks/useDisableBodyScroll';
import useIsBreakpoint from '../../hooks/useIsBreakpoint';
import './PatientResourcesModal.scss';

// const isSafari = navigator.userAgent.indexOf("Safari") !== -1;
// const isIphone = navigator.userAgent.indexOf("iPhone") !== -1; 
// const isTouchDevice = window.matchMedia('(hover: none)').matches===true; 
// const isMobileIosSafari = isSafari && isTouchDevice;

const PatientResourcesModal = ({ setShowResourcesModal, county, modalTransformOrigin, data, language }) => {

  const isSmallScreen = useIsBreakpoint(1024);

  const modalRef = useRef();
  const modalWrapperRef = useRef(); /* Different use case since want to scroll entire screen */
  const closeIconTopRef = useRef();
  const closeIconBottomRef = useRef();

  /*
  const scrollToTop = () => {
    var ua = window.navigator.userAgent;
    if (ua.indexOf('MSIE ') > 0 ||
    ua.indexOf('Trident/') > 0 ||
    ua.indexOf('Edge/') > 0) {
      modalWrapperRef.current.scrollTo(0,0);
    } else {
      modalWrapperRef.current.scroll({ 
        top: 0, 
        left: 0, 
        behavior: 'smooth'
      });
    }
  }
  */
 
  useDisableBodyScroll(modalRef);

  /*
    useDisableBodyScroll(modalRef.current, {reserveScrollBarGap: true});

    useEffect(() => {
      scrollToTop();
    }, []);
  */

/*
useEffect(() => {
  const modalElement = modalRef.current;
  // console.log('isMobileIosSafari',isMobileIosSafari);
    if (!modalElement) return;

    // Fix for iOs Safari
    // disableBodyLock is not working for iOs Safari... 
    // Scrolling sometimes freezes at bottom or top of modal
    // The solution is to prevent touch events from working on anything except menu element and elements inside of it */
/*    const handleTouchMove = (e) => {
      if (!modalRef.current) return;

      const insideMenu = [];

      modalRef.current
          .querySelectorAll("*")
          .forEach((node) => insideMenu.push(node));

      const targetIsMenu = e.target.id === modalRef.current.id;
      // console.log('e.target ', e.target, e.target.id);
      const modalContainsTarget = insideMenu.includes(e.target);
      const shouldPreventDefault = !(targetIsMenu || modalContainsTarget); 
      // console.log('shouldPreventDefault',shouldPreventDefault); 
      if (shouldPreventDefault) e.preventDefault();
    };

    if (isMobileIosSafari) {  
      window.addEventListener("touchmove", handleTouchMove, {passive: false});
    } else {
      const options = {reserveScrollBarGap: true};
      disableBodyScroll(modalElement, options);
    }

    scrollToTop();

    return () => {
      if (isMobileIosSafari) {
        window.removeEventListener("touchmove", handleTouchMove, {passive: false}); 
      }
    }
  }, []);
*/

  const [thingsToDoHeadingMarginTop, setThingsToDoHeadingMarginTop]= useState(0);

  useEffect(() => {
    let marginTop;
    if (!isSmallScreen) {
      marginTop = county.offices && 
        county.offices.length>0 && 
        county.offices.length%2===1 
          ? '40px' 
          : (!county.offices ||
            (county.offices && 
            county.offices.length===0)
            ? '-40px'
            : '0');
      } else {
        marginTop='40px'
      }
      setThingsToDoHeadingMarginTop(marginTop);
  }, [isSmallScreen, county]);
  

  const [modalClass, setModalClass] = useState('show');
  const [modalScrollTop, setModalScrollTop] = useState(0);

  const closeModal = (e) => {
      if (
        !e ||
        (modalRef.current && !modalRef.current.contains(e.target)) ||
        (closeIconTopRef && closeIconTopRef.current.contains(e.target)) ||
        (closeIconBottomRef && closeIconBottomRef.current.contains(e.target))
      ) {
        setModalScrollTop(modalRef.current.scrollTop);
        setModalClass('hide');
      }
  }

  const handleModalOnEndAnimation = (e) => {
    if (e.animationName==='exit-resources-modal') {
      enableBodyScroll(modalRef.current); /* Must enable before modal disappears */
      setShowResourcesModal(false);
    }
  }

  const Office = ({ office }) => {

    let { officeName, address, addressLine2, city, state, postal, hours, phone } = office;

    if (address) {
      address = address
        .replace(' Street', ' St.')
        .replace(' Avenue', ' Ave.')
        .replace(' Boulevard', ' Blvd.')
        .replace(' Floor', ' Flr.')
        .replace(' Suite', ' Ste.')
        .replace(' Room', ' Rm.')
        .replace(' Building', ' Bldg.');
    }

    if (addressLine2) {
      addressLine2 = addressLine2
        .replace(' Street', ' St.')
        .replace(' Avenue', ' Ave.')
        .replace(' Boulevard', ' Blvd.')
        .replace(' Floor', ' Flr.')
        .replace(' Suite', ' Ste.')
        .replace(' Room', ' Rm.')
        .replace(' Building', ' Bldg.');
    }

    postal = postal.slice(0,5);
      
    let query = address+', ';
    if (addressLine2 && addressLine2!=='' && addressLine2.indexOf('P.O. Box')<0) query += addressLine2+', ';
    query += city+', '+state+' '+postal;
    query=encodeURIComponent(query);

    return (
      <div className="office">
        <div className="office-name">{ReactHtmlParser(officeName.replace(/\\n/g,'<br />'))}</div>
        {address &&
          <div className="office-address">
            <a href={'https://www.google.com/maps/search/?api=1&query='+query} target="_blank" rel="noreferrer">
              {address}
              {addressLine2 && addressLine2!=='' && <><br />{addressLine2}</>}<br />
              {city}, {state} {postal}
            </a>
          </div>
         }
        {hours && hours.start!=='' && hours.end!=='' &&
          <div className="office-hours">
              {hours.start} - {hours.end}
          </div>
        }
        {phone && phone.length>0 && 
          <div className="office-phone">
              {phone.map((phone, index) => {
               /* 
                const number = phone.number
                  .replaceAll('-', '<span>•</span>')
                  .replaceAll('(', '')
                  .replaceAll(') ', '<span>•</span>');
                */
                const number = phone.number.substring(0, 3)+'<span>•</span>'+
                               phone.number.substring(3, 6)+'<span>•</span>'+
                               phone.number.substring(6, 10);
                return (
                  <React.Fragment key={'phone-'+index}>
                    {phone.label} <a href={'tel: '+phone.number}>{ReactHtmlParser(number)}</a><br />
                  </React.Fragment>
                )
              })}
          </div>
        }
      </div>
    )
  }

  const Image = ({ thing }) => {
    return (
      <div style={{
          backgroundImage: `url(${thing.imageUrl})`, // process.env.PUBLIC_URL+thing.imageUrl
          backgroundSize: thing.circleBackgroundSize!==''
            ? thing.circleBackgroundSize
            : 'cover',
          backgroundPosition: thing.circleBackgroundPosition!=='' 
            ? thing.circleBackgroundPosition
            : 'center center'
        }}
      >
        <div style={{
          backgroundImage: `url(${thing.imageFilteredUrl})` // process.env.PUBLIC_URL+thing.imageFilteredUrl
        }}>  
        </div>
      </div>
    )
  };

  return (
    <div 
      id="resources-modal-wrapper" 
      ref={modalWrapperRef}
      onClick={(e) => closeModal(e)}
      className={modalClass}
      onAnimationEnd={(e) => handleModalOnEndAnimation(e)}
    >
      <div 
        id="resources-modal-container" 
        ref={modalRef}
        style={
          {transformOrigin: modalTransformOrigin.left+'px '+(modalTransformOrigin.top + modalScrollTop)+'px'}
        }
      >
        
        <div id="resources-modal-content">

          <div ref={closeIconTopRef} className="icon-close-modal-top" onClick={(e) => closeModal(e)}></div>
          <div ref={closeIconBottomRef} className="icon-close-modal-bottom" onClick={(e) => closeModal(e)}></div>

          <div id="resources-modal-header">
            {county.name}
          </div>

          <div className="resources-offices">
            {county.offices && county.offices.length>0 && county.offices.map((office, index) => 
              <div 
                key={'office-'+index}
                className="office"
              >
                <Office office={office} />
              </div>
            )}
          </div>

          {county.thingsToDo && county.thingsToDo.length>0 && 
            <>
              <div 
                className="things-to-do-heading"
                style={{
                  marginTop:  thingsToDoHeadingMarginTop
                }}
              >
                <div style={{paddingTop: language==='es' ? '30px' : '50px'}}>{ReactHtmlParser(data[language].thingsToDo.replace(/\\n/g,'<br />'))}</div>
              </div>
              <div className="things-to-do">

                  {county.thingsToDo.map((thing, index) => (
                    <div 
                      key={'things-to-do'+index}
                      className={`thing-to-do${thing.imageLinkUrl && thing.imageLinkUrl!=='' ? ' clickable' : ''}`} 
                    >
                      <div className="thing-to-do-clip">

                          {thing.imageLinkUrl && thing.imageLinkUrl!==''
                            ? <a href={thing.imageLinkUrl} target="_blank" rel="noreferrer">
                                <Image thing={thing} />
                              </a>
                            : <Image thing={thing} />
                          }

                      </div>

                      <div className="thing-to-do-name">
                        <svg viewBox="0 0 100 100" width="100" height="100">
                          <defs>
                            <path id={'circle-'+index}
                              d="M 50, 50
                                m -37, 0
                                a 37,37 0 1,1 74,0
                                a 37,37 0 1,1 -74,0"/>
                          </defs>
                          <text fontSize="4">
                            <textPath 
                              xlinkHref={'#circle-'+index} 
                              fill="#fff"
                              textAnchor="middle" 
                              startOffset="50%"
                            >
                              {thing?.copy[language]?.caption || thing?.copy?.en?.caption}
                            </textPath>
                          </text>
                        </svg>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          }
        </div>  
      </div>
    </div>
  )
}

export default PatientResourcesModal;
