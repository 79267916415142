import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchMeeting, fetchMeetings, deleteMeeting, updateMeeting } from './safetyCommitteeAPI';
// import getMapCoordinates from '../../utilities/getMapCoordinates';

const initialState = {
  // meeting: {},
  meetings: [],
  meetingsLoaded: false,
  status: 'idle'
};

export const getSafetyCommitteeMeetingAsync = createAsyncThunk(
  'safetyCommittee/fetchMeeting',
  async (meetingId) => {
    const response = await fetchMeeting(meetingId);
    const defaultMeeting = {
      meetingId: 0,
      adminId: 0,
      meetingDate: '',
      copy: {
        en: {
          body: '',
        },
        es: {
          body: '',
        }
      }
    };
    const result = {
      ...response,
      meeting: {
        ...defaultMeeting,
        ...response?.meeting,
        copy: {
          en: {
            ...defaultMeeting.copy.en,
            ...response?.meeting?.copy?.en,
          },
          es: {
            ...defaultMeeting.copy.es,
            ...response?.meeting?.copy?.es,
          }
        }
      }
    }
    return result;
  }
);

export const getSafetyCommitteeMeetingsAsync = createAsyncThunk(
  'safetyCommittee/fetchMeetings',
  async () => {
    const response = await fetchMeetings();
    return response;
  }
);

export const deleteSafetyCommitteeMeetingAsync = createAsyncThunk(
  'safetyCommittee/deleteMeetingAsync',
  async (payload) => {
      const response = await deleteMeeting(payload);
      // The value we return becomes the `fulfilled` action payload
      return response;
  }  
);

export const updateSafetyCommitteeMeetingAsync = createAsyncThunk(
  'safetyCommittee/updateMeeting',
  async (payload) => {
      const response = await updateMeeting(payload);
      return response;
  }  
);

export const safetyCommitteeSlice = createSlice({
  name: 'safetyCommittee',
  initialState,
  reducers: {
    /*
    setCoordinates: (state, action) => {
      action.payload.forEach((coordinates, index) => {
        state.offices[index].coordinates = coordinates;
      })
    },
    */
  },

  extraReducers: (builder) => {
    builder
      .addCase(getSafetyCommitteeMeetingsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSafetyCommitteeMeetingsAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.meetings = action.payload?.meetings;
        state.meetingsLoaded = true;
      })
      .addCase(getSafetyCommitteeMeetingAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSafetyCommitteeMeetingAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // state.meeting = action.payload?.meeting;
        state.meetings = (state.meetings.find(meeting => meeting.meetingId === action.payload.meeting.meetingId))
            ? state.meetings.map(meeting => meeting.meetingId===action.payload.meeting.meetingId ? action.payload.meeting : meeting) // updated
            : [...state.meetings, action.payload.meeting];
      })
      .addCase(deleteSafetyCommitteeMeetingAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteSafetyCommitteeMeetingAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.meetings = state.meetings.filter(meeting => meeting.meetingId !== action.payload.meetingId);
      })
      .addCase(updateSafetyCommitteeMeetingAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateSafetyCommitteeMeetingAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        if (action.payload?.meeting) {
          state.meetings = (state.meetings.find(meeting => meeting.meetingId === action.payload.meetingId))
            ? state.meetings.map(meeting => meeting.meetingId===action.payload.meetingId ? action.payload.meeting : meeting) // updated
            : [...state.meetings, action.payload.meeting]; // added
        }
      })
  },
});

// export const { setCoordinates } = articlesSlice.actions;

// export const selectSafetyCommitteeMeeting = (state) => state.safetyCommittee.meeting;
export const selectSafetyCommitteeMeetings = (state) => {
  const meetings = [...state?.safetyCommittee?.meetings];
  return meetings && meetings.sort((a, b) => new Date(b.meetingDate) - new Date(a.meetingDate));
};
export const selectSafetyCommitteeMeetingsStatus = (state) => state.safetyCommittee.status;

export default safetyCommitteeSlice.reducer;
