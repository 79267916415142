import React, { useState, useEffect, useRef, useMemo, useCallback, useLayoutEffect } from 'react'; 
import { useParams, useNavigate, useLocation } from 'react-router-dom'; // Outlet
import { useDispatch, useSelector } from 'react-redux';
import useIsIntersecting from '../../hooks/useIsIntersecting3';
import useIsMobile from '../../hooks/useIsMobile';
// import useScreenWidth from '../../hooks/useScreenWidth';
import ReactHtmlParser from 'react-html-parser';
// import ModalPortal from '../ModalPortal/ModalPortal';
import useResizeObserver from '../../hooks/useResizeObserver';
import anime from 'animejs';
import { getArticlesAsync, selectArticles, selectArticlesStatus } from '../../features/articles/articlesSlice';
import useIsBreakpoint from '../../hooks/useIsBreakpoint';
import Loader from '../Loader/Loader';
import Error404 from '../Error404/Error404';
import './Articles.scss';

// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'; // clearAllBodyScrollLocks

// import data from '../../translations/es.json';

// const { detect } = require('detect-browser');

const Blobs = [
  <svg viewBox="0 0 700 600" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px">
    <path d="M202.51,123.82c132.38-54.73,343.57-37.94,419.65,87.91c8.48,14.03,47.75,83.12,25.71,160.89
      c-12.8,45.18-40.99,74.57-51.42,84.59c-16.37,15.75-42.7,41.23-85.42,47.27c-6.44,0.91-65.21,8.38-102.01-27.37
      c-22.32-21.68-17.03-40.3-45.61-60.54c-4.24-3.01-22.15-15.19-48.93-19.07c-26.35-3.82-46.57,2.53-51.42,4.15
      c-18.78,6.27-25.28,14.49-37.32,21.56c-31.59,18.55-78.77,15.97-114.45-3.32c-3.4-1.84-53.25-29.66-63.86-87.08
      c-9.83-53.2,20.37-95.4,38.15-120.25C93.93,200.88,128.93,154.25,202.51,123.82z"/>
  </svg>,
  <svg viewBox="0 0 1400 770" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px">
    <path d="M 262.9,252.2 C 210.1,338.2 212.6,487.6 288.8,553.9 372.2,626.5 511.2,517.8 620.3,536.3 750.6,558.4 860.3,723 987.3,686.5 1089,657.3 1168,534.7 1173,429.2 1178,313.7 1096,189.1 995.1,130.7 852.1,47.07 658.8,78.95 498.1,119.2 410.7,141.1 322.6,154.8 262.9,252.2 Z" />
  </svg>,
  
  <svg viewBox="0 0 700 600" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px">
    <path d="M493.03,536.41c-95.99,46.3-275.25,44.84-403.04-81.27c-127.79-126.11-56.17-252.22,53.36-249.42
      c109.54,2.8,172.73-35.03,226.1-98.09c53.36-63.06,162.9-91.08,241.55,7.01C689.64,212.73,703.68,434.82,493.03,536.41z"/>
  </svg>,
  <svg viewBox="0 0 700 600" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px">
    <path d="M631.27,207.38c-50.29-69.46-138.39-80.58-191.76-73.36c-53.37,7.22-112.56,3.92-158.22-52.24
      C235.63,25.62,153.3,20.35,108.16,65.57C59.33,114.48-7.13,233.34,81.52,371.54C138,459.6,224.29,517.37,299.16,547.39
      c74.87,30.02,137.36,41.14,174.65,11.18c37.29-29.95,15.59-64.15,7.96-79.51c-7.63-15.36-19.35-60.66,66.34-76.88
      C650.16,382.88,687.52,285.07,631.27,207.38z"/>
  </svg>,

  <svg viewBox="0 0 700 600" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px">
    <path d="M232.4,110.49C14.46,186.21-80.67,421.86,157.69,441.5c207.65,17.11,261.23,117.1,372.39,102.4
      c147.67-19.52,145.8-226.49,146.01-226.53C680.2,58.12,466.81,29.04,232.4,110.49z"/>
  </svg>,
  <svg viewBox="0 0 700 600" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px">
    <path d="M75.67,299.51C-47.19,487.71,187.55,574.32,336,574.32c145.99,0,310.97-55.07,310.97-224.32
      C646.98-85.67,286.55-23.53,75.67,299.51z"/>
  </svg>,

  <svg viewBox="0 0 700 600" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px">
    <path d="M562.89,138.99c-94.11-69.33-205.1-95.16-292.81-96.6c-87.71-1.44-154.98,11.91-179.49,58.05
      c-24.51,46.13,11.8,73.15,25.99,86.13c14.19,12.98,45.26,55.56-33.54,106.69c-93.84,60.89-89.45,177.85,0,236.45
      c79.97,52.39,172.85,28.84,223.16,0c50.31-28.84,110.93-49.02,180.59-8.65c69.66,40.37,154.27,13.01,180.12-52.2
      C694.86,398.34,710.59,247.81,562.89,138.99z"/>
  </svg>,
  <svg viewBox="0 0 700 600" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px">
    <path d="M173.28,135.77c70.6,53.17,133.46,68.24,191.4,33.78c64.84-38.56,103.41-81.01,181.35-67.41
      c97.89,17.09,170.67,131.99,140.05,267.17c-15.12,66.74-57.07,153.31-151.84,189.09c-69.59,26.27-157.05-2.53-205.5-62.3
      c-35.72-44.06-83.8-95.63-160.55-96.12c-76.76-0.49-147.33-39.3-164.8-119.7C-15.95,191.31,90.23,73.22,173.28,135.77z"/>
  </svg>,

  <svg viewBox="0 0 700 600" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px">
    <path d="M244.6,239.34c-27.78,14.73-62.79,15.58-93.89,20.73c-28.97,4.79-81.74,25.82-102.84,48.61
      c-36.46,39.37-34.26,125.35,20.82,152.82c63.2,31.52,142.61,2.59,211.08-6.76c105.68-14.44,128.42,70.47,231.21,85.08
      c39.31,5.59,95.42-6.67,114.66-48.32c24.43-52.86-7.98-113.6,28.67-163.75c13.7-18.75,50.61-104.2-39.95-135.28
      c-32.41-11.12-36.68-4.45-69.81-12.69c-31.87-7.93-39.98-28.68-57.12-57.12c-18.8-31.2-82.59-44.63-135.73-17.85
      C294.85,133.43,296.21,211.98,244.6,239.34z"/>
  </svg>,
  <svg viewBox="0 0 700 600" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px">
    <path d="M348.82,60.69c20.42,0.61,64.81,2.44,84.57,6.78c17.27,3.8,34.38,8.59,51.25,13.92c10.5,3.32,20.45,8.4,30.6,12.81
      c15.73,6.83,30.58,15.27,44.78,24.87c13.83,9.35,26.65,19.9,37.98,32.18c17.15,18.58,29.66,39.77,34.69,64.76
      c1.26,6.24,0.27,12.89,0.88,19.31c1.54,16.19-3.3,30.92-10.63,44.82c-9.4,17.81-23.4,31.3-40.93,41.36
      c-15.06,8.64-31.32,13.31-48.12,16.92c-6.95,1.49-13.68,4.23-20.35,6.83c-12.42,4.84-25.09,9.25-37,15.18
      c-20.47,10.19-38.96,23.3-55.43,39.45c-15.28,14.99-27.8,31.91-38.69,50.11c-4.75,7.93-8.08,16.71-12.86,24.63
      c-6.14,10.17-12.52,20.29-19.8,29.66c-14.27,18.37-32.7,31.73-53.9,41.08c-10.85,4.79-22.04,9.18-34.08,9.82
      c-12.91,0.68-25.93,1.76-38.75,0.73c-9.57-0.76-19.07-4.09-28.31-7.14c-23.42-7.74-43.93-20.5-62.25-36.99
      c-21.33-19.2-38.87-41.27-51.2-67.24c-6.13-12.92-12.73-25.81-16.97-39.39c-4.85-15.55-8.72-31.7-10.46-47.86
      c-1.95-18.09-2.02-36.54-1.01-54.72c0.68-12.22,3.91-24.48,7.37-36.32c3.99-13.66,8.62-27.29,14.6-40.18
      c5.39-11.62,12.64-22.44,19.71-33.18c5.83-8.86,11.98-17.66,19.1-25.47c10.31-11.29,21.26-22.07,32.63-32.3
      c9.11-8.19,18.82-15.9,29.04-22.64c15.2-10.04,31.32-18.68,48.54-24.83c16.67-5.95,33.38-11.96,50.45-16.5
      C288.29,67.39,327.79,60.54,348.82,60.69L348.82,60.69z"/>
  </svg>,

  <svg viewBox="0 0 700 600" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px">
    <path d="M513.86,125.96c27.64-5.4,52.55-6.31,80.95,6.85c96.83,44.85,64.19,152.92,23.63,214.09
      c-48.71,76.51-130.36,126.47-214.03,157.33c-120.36,38.44-221.87,1.15-309.54-82.29C65.62,389.48,52.38,357.52,43,324.43
      C6.69,201.99,74.52,81.19,212.12,101.57c55.96,11.62,105.7,43.65,163.07,47.44C445.43,153.64,473.81,132.42,513.86,125.96"/>
  </svg>,
  <svg viewBox="0 0 700 600" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px">
    <path d="M128.12,341.96c84.87-13,145.58,3.04,179.3,59.19c37.72,62.83,51.19,116.9,124.13,141.13
      c91.61,30.43,206.76-34.38,242.78-164.13c17.78-64.06,21.73-157.42-42.89-231.65c-47.45-54.51-135.53-70.11-204.49-41.27
      c-50.83,21.25-115.7,43.25-181.6,8.35c-65.9-34.9-144.13-34.18-196.08,26.57C-8.21,207.36,28.29,357.25,128.12,341.96z"/>
  </svg>
];

function Articles({ data, general, baseUrl, language }) {

  const isOneColumn = useIsBreakpoint(1600); /* Must match value in Articles.scss */

  const dispatch = useDispatch();
  const articles = useSelector(selectArticles); // mockSlots
  const articlesStatus = useSelector(selectArticlesStatus);

  /*
  const [dispatchNow, setDispatchNow] = useState(false);

  useEffect(() => {
    if (articlesStatus==='idle') setDispatchNow(true);
  }, [articlesStatus]);
*/

  useEffect(() => {
    if (articlesStatus==='idle') dispatch(getArticlesAsync());
  }, [articlesStatus, dispatch]);

  const isMobile = useIsMobile();
  const [scaleFactor, setScaleFactor] = useState();

  useEffect(()=> {
    const factor = isMobile ? .5 : 1;
    setScaleFactor(factor);
  }, [isMobile]);

  const morphRefs = useRef([]);
  const morphPathRefs = useRef([]);
  // const contentRelatedRef = useRef();
  const wrapRefs = useRef([]);
  const containerRefs = useRef([]);
  const imageRefs = useRef([]);
  const headlineRefs = useRef([]);
  const step = useRef();

  // let DOM = {};
  // DOM.svg = morphRef.current;
  // DOM.shapeEl = morphPathRef.current;
  // DOM.footer = contentRelatedRef.current;

  // Helper vars and functions.
  /*
  const extend = function(a, b) {
    for( let key in b ) { 
      if( b.hasOwnProperty( key ) ) {
        a[key] = b[key];
      }
    }
    return a;
  };
  */

  // const initialMorphPath = 'M 262.9,252.2 C 210.1,338.2 212.6,487.6 288.8,553.9 372.2,626.5 511.2,517.8 620.3,536.3 750.6,558.4 860.3,723 987.3,686.5 1089,657.3 1168,534.7 1173,429.2 1178,313.7 1096,189.1 995.1,130.7 852.1,47.07 658.8,78.95 498.1,119.2 410.7,141.1 322.6,154.8 262.9,252.2 Z';
  
  const initialMorphPath = 'M 415.6,206.3 C 407.4,286.6 415.5,381.7 473.6,462.9 531.7,544.2 482.5,637.6 579.7,685.7 676.9,733.8 826.2,710.7 890.4,632.4 954.2,554 926.8,487.6 937.7,370 948.6,252.4 937.7,201.5 833.4,105.4 729.3,9.338 602.2,13.73 530.6,41.91 459,70.08 423.9,126.1 415.6,206.3 Z';
  
  const shapes = useMemo(()=>{
    return [
      {
        index: 0,
        // path: 'M379.5,321.5Q334,403,250.5,385Q167,367,99.5,303.5Q32,240,80,142.5Q128,45,223,75Q318,105,371.5,172.5Q425,240,379.5,321.5Z',
        // pathAlt: 'M360.5,303Q312,366,223,395Q134,424,90,332Q46,240,96,158.5Q146,77,229,96Q312,115,360.5,177.5Q409,240,360.5,303Z',
        path: 'M 415.6,206.3 C 407.4,286.6 438.1,373.6 496.2,454.8 554.3,536.1 497,597.2 579.7,685.7 662.4,774.1 834.3,731.7 898.5,653.4 962.3,575 967.1,486 937.7,370 909.3,253.9 937.7,201.5 833.4,105.4 729.3,9.338 602.2,13.73 530.6,41.91 459,70.08 423.9,126.1 415.6,206.3 Z',
        pathAlt: 'M 415.6,206.3 C 407.4,286.6 415.5,381.7 473.6,462.9 531.7,544.2 482.5,637.6 579.7,685.7 676.9,733.8 826.2,710.7 890.4,632.4 954.2,554 926.8,487.6 937.7,370 948.6,252.4 937.7,201.5 833.4,105.4 729.3,9.338 602.2,13.73 530.6,41.91 459,70.08 423.9,126.1 415.6,206.3 Z',
        scaleX: 2 * scaleFactor,
        scaleY: 1 * scaleFactor,
        rotate: 0,
        tx: 0,
        ty: 100,
        fill: {
          color: '#b7e1dd',
          duration: 500,
          easing: 'linear'
        },
        animation: {
          path: {
            duration: 1000,
            easing: 'easeOutElastic',
            elasticity: 400
          },
          svg: {
            duration: 1000,
            easing: 'easeOutQuad'
          }
        }
      },
      {
        index: 1,
        path: 'M 262.9,252.2 C 210.1,338.2 212.6,487.6 288.8,553.9 372.2,626.5 511.2,517.8 620.3,536.3 750.6,558.4 860.3,723 987.3,686.5 1089,657.3 1168,534.7 1173,429.2 1178,313.7 1096,189.1 995.1,130.7 852.1,47.07 658.8,78.95 498.1,119.2 410.7,141.1 322.6,154.8 262.9,252.2 Z',
        pathAlt: 'M 262.9,252.2 C 210.1,338.2 273.3,400.5 298.5,520 323.7,639.6 511.2,537.2 620.3,555.7 750.6,577.8 872.2,707.4 987.3,686.5 1102,665.6 1218,547.8 1173,429.2 1128,310.6 1096,189.1 995.1,130.7 852.1,47.07 658.8,78.95 498.1,119.2 410.7,141.1 322.6,154.8 262.9,252.2 Z',
        scaleX: 1.3 * scaleFactor,
        scaleY: 1.8 * scaleFactor,
        rotate: 70,
        tx: 0,
        ty: -100,
        fill: {
          color: '#f9b9b9', 
          duration: 500,
          easing: 'linear'
        },
        animation: {
          path: {
            duration: 1000,
            easing: 'easeInOutQuad'
          },
          svg: {
            duration: 1000,
            easing: 'easeInOutQuad'
          }
        }
      },
      {
        index: 2,
        path: 'M 415.6,206.3 C 407.4,286.6 438.1,373.6 496.2,454.8 554.3,536.1 497,597.2 579.7,685.7 662.4,774.1 834.3,731.7 898.5,653.4 962.3,575 967.1,486 937.7,370 909.3,253.9 937.7,201.5 833.4,105.4 729.3,9.338 602.2,13.73 530.6,41.91 459,70.08 423.9,126.1 415.6,206.3 Z',
        pathAlt: 'M 415.6,206.3 C 407.4,286.6 415.5,381.7 473.6,462.9 531.7,544.2 482.5,637.6 579.7,685.7 676.9,733.8 826.2,710.7 890.4,632.4 954.2,554 926.8,487.6 937.7,370 948.6,252.4 937.7,201.5 833.4,105.4 729.3,9.338 602.2,13.73 530.6,41.91 459,70.08 423.9,126.1 415.6,206.3 Z',
        scaleX: 1.9 * scaleFactor,
        scaleY: 1 * scaleFactor,
        rotate: 0,
        tx: 0,
        ty: 100,
        fill: {
          color: '#e26574', 
          duration: 500,
          easing: 'linear'
        },
        animation: {
          path: {
            duration: 1000,
            easing: 'easeInOutQuad'
          },
          svg: {
            duration: 1000,
            easing: 'easeInOutQuad'
          }
        }
      },
      {
        index: 3,
        path: 'M 262.9,252.2 C 210.1,338.2 212.6,487.6 288.8,553.9 372.2,626.5 511.2,517.8 620.3,536.3 750.6,558.4 860.3,723 987.3,686.5 1089,657.3 1168,534.7 1173,429.2 1178,313.7 1096,189.1 995.1,130.7 852.1,47.07 658.8,78.95 498.1,119.2 410.7,141.1 322.6,154.8 262.9,252.2 Z',
        pathAlt: 'M 262.9,252.2 C 210.1,338.2 273.3,400.5 298.5,520 323.7,639.6 511.2,537.2 620.3,555.7 750.6,577.8 872.2,707.4 987.3,686.5 1102,665.6 1218,547.8 1173,429.2 1128,310.6 1096,189.1 995.1,130.7 852.1,47.07 658.8,78.95 498.1,119.2 410.7,141.1 322.6,154.8 262.9,252.2 Z',
        scaleX: 1.3 * scaleFactor,
        scaleY: 1 * scaleFactor,
        rotate: -70,
        tx: 0,
        ty: 150,
        fill: {
          color: '#9984c1', 
          duration: 500,
          easing: 'linear'
        },
        animation: {
          path: {
            duration: 1000,
            easing: 'easeInOutQuad'
          },
          svg: {
            duration: 1000,
            easing: 'easeInOutQuad'
          }
        }
      },
      {
        index: 4,
        path: 'M 262.9,252.2 C 210.1,338.2 212.6,487.6 288.8,553.9 372.2,626.5 511.2,517.8 620.3,536.3 750.6,558.4 860.3,723 987.3,686.5 1089,657.3 1168,534.7 1173,429.2 1178,313.7 1096,189.1 995.1,130.7 852.1,47.07 658.8,78.95 498.1,119.2 410.7,141.1 322.6,154.8 262.9,252.2 Z',
        pathAlt: 'M 262.9,252.2 C 210.1,338.2 273.3,400.5 298.5,520 323.7,639.6 511.2,537.2 620.3,555.7 750.6,577.8 872.2,707.4 987.3,686.5 1102,665.6 1218,547.8 1173,429.2 1128,310.6 1096,189.1 995.1,130.7 852.1,47.07 658.8,78.95 498.1,119.2 410.7,141.1 322.6,154.8 262.9,252.2 Z',
        scaleX: 1.5 * scaleFactor,
        scaleY: 2 * scaleFactor,
        rotate: -20,
        tx: 0,
        ty: -50,
        fill: {
          color: '#b6d2f2', 
          duration: 500,
          easing: 'linear'
        },
        animation: {
          path: {
            duration: 1000,
            easing: 'easeInOutQuad'
          },
          svg: {
            duration: 1000,
            easing: 'easeInOutQuad'
          }
        }
      },
      {
        index: 5,
        path: 'M 383.8,163.4 C 335.8,352.3 591.6,317.1 608.7,420.8 625.8,524.5 580.5,626 647.3,688 714,750 837.1,760.5 940.9,661.5 1044,562.3 1041,455.8 975.8,393.6 909.8,331.5 854.2,365.4 784.4,328.1 714.6,290.8 771.9,245.2 733.1,132.4 694.2,19.52 431.9,-25.48 383.8,163.4 Z',
        pathAlt: 'M 383.8,163.4 C 345.5,324.9 591.6,317.1 608.7,420.8 625.8,524.5 595.1,597 647.3,688 699.5,779 837.1,760.5 940.9,661.5 1044,562.3 1068,444.4 975.8,393.6 884,342.8 854.2,365.4 784.4,328.1 714.6,290.8 820.3,237.2 733.1,132.4 645.9,27.62 422.1,1.919 383.8,163.4 Z',
        scaleX: 1.9 * scaleFactor,
        scaleY: 1.1 * scaleFactor,
        rotate: 40,
        tx: -100,
        ty: 200,
        fill: {
          color: '#ef889f', 
          duration: 500,
          easing: 'linear'
        },
        animation: {
          path: {
            duration: 1000,
            easing: 'easeInOutQuad'
          },
          svg: {
            duration: 1000,
            easing: 'easeInOutQuad'
          }
        }
      },
      {
        index: 6,
        path: 'M 415.6,206.3 C 407.4,286.6 438.1,373.6 496.2,454.8 554.3,536.1 497,597.2 579.7,685.7 662.4,774.1 834.3,731.7 898.5,653.4 962.3,575 967.1,486 937.7,370 909.3,253.9 937.7,201.5 833.4,105.4 729.3,9.338 602.2,13.73 530.6,41.91 459,70.08 423.9,126.1 415.6,206.3 Z',
        pathAlt: 'M 415.6,206.3 C 407.4,286.6 415.5,381.7 473.6,462.9 531.7,544.2 482.5,637.6 579.7,685.7 676.9,733.8 826.2,710.7 890.4,632.4 954.2,554 926.8,487.6 937.7,370 948.6,252.4 937.7,201.5 833.4,105.4 729.3,9.338 602.2,13.73 530.6,41.91 459,70.08 423.9,126.1 415.6,206.3 Z',
        scaleX: 2 * scaleFactor,
        scaleY: 1 * scaleFactor,
        rotate: 0,
        tx: 0,
        ty: 100,
        fill: {
          color: '#b7e1dd', 
          duration: 500,
          easing: 'linear'
        },
        animation: {
          path: {
            duration: 1000,
            easing: 'easeOutElastic',
            elasticity: 400
          },
          svg: {
            duration: 1000,
            easing: 'easeOutQuad'
          }
        }
      },
    ];
  }, [scaleFactor]);

  const initShapeLoop = useCallback((i) => {
    i = i || 0;
    // anime.remove(morphPathRefs.current[i]);
    anime({
      targets: morphPathRefs.current[i],
      easing: 'linear',
      d: [
        {value: shapes[i].pathAlt, duration:1000}, 
        {value: shapes[i].path, duration:1000}
      ],
      loop: true,
      fill: {
        value: shapes[i].fill.color,
        duration: shapes[i].fill.duration,
        easing: shapes[i].fill.easing
      },
      direction: 'alternate',
      /*
      complete: function() {
        console.log('complete initShapeLoop');
      }
      */
    });
  }, [shapes]);
/*
  const initShapeEl = useCallback(() => {
    anime.remove(morphRef.current);
    const index = 1;
    anime({
      targets: morphRef.current,
      duration: 1500,
      easing: 'linear',
      scaleX: shapes[index].scaleX,
      scaleY: shapes[index].scaleY,
      translateX: shapes[index].tx+'px',
      translateY: shapes[index].ty+'px',
      rotate: shapes[index].rotate+'deg',
      /*
      complete: function() {
        console.log('complete initShapeEl');
       // initShapeLoop(index);
      }
      */
/*
    });

    initShapeLoop(index);
  },[shapes, initShapeLoop]);
*/
  // const [pageInitiated, setPageInitiated] = useState(false);
  const [pageNotFound, setPageNotFound] = useState(null);

  useEffect(()=> {
    // Initiate morphing background shapes and mouse responsive movement

    // from http://www.quirksmode.org/js/events_properties.html#position
    const getMousePos = (e) => {
      let posx = 0;
      let posy = 0;
  
      if (!e) e = window.event;
      if (e.pageX || e.pageY) 	{
        posx = e.pageX;
        posy = e.pageY;
      }
      else if (e.clientX || e.clientY) 	{
        posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
        posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
      }
      return { 
        x : posx, 
        y : posy 
      };
    };
  
    const setLayout = (e, index) => {
      // Mouse position relative to the document.
      const mousepos = getMousePos(e);
  
      // Document scrolls
      const docScrolls = {
        left : document.body.scrollLeft + document.documentElement.scrollLeft, 
        top : document.body.scrollTop + document.documentElement.scrollTop
      };
  
      const bounds = containerRefs.current[index].getBoundingClientRect();
  
      // Mouse position relative to the main element (this.DOM.el).
      const relmousepos = { 
        x : mousepos.x - bounds.left - docScrolls.left, 
        y : mousepos.y - bounds.top - docScrolls.top 
      };
  
      const options = {
        movement: {
          img : { translation : {x: -40, y: -40} },
          headline : { translation : {x: 20, y: 20} },
        }
      };
  
      // Movement settings for the animatable elements.
      const t = {
        img: options.movement.img.translation,
        headline: options.movement.headline.translation,
      };
        
      const transforms = {
        img : {
          x: (-1*t.img.x - t.img.x)/bounds.width*relmousepos.x + t.img.x,
          y: (-1*t.img.y - t.img.y)/bounds.height*relmousepos.y + t.img.y
        },
        headline : {
          x: (-1*t.headline.x - t.headline.x)/bounds.width*relmousepos.x + t.headline.x,
          y: (-1*t.headline.y - t.headline.y)/bounds.height*relmousepos.y + t.headline.y
        }
      };
  
      imageRefs.current[index].style.transform = 'translateX(' + parseInt(transforms.img.x) + 'px) translateY(' + parseInt(transforms.img.y) + 'px)';
      headlineRefs.current[index].style.transform = 'translateX(' + parseInt(transforms.headline.x) + 'px) translateY(' + parseInt(transforms.headline.y) + 'px)';
    };


    const handleMouseEnter = (e, index) => {
      anime.remove(imageRefs.current[index]);
      anime.remove(headlineRefs.current[index]);
    };
    
    const handleMouseMove = (e, index) => {
      requestAnimationFrame(() => setLayout(e, index));
    };
    
    const handleMouseLeave = (e, index) => {
      requestAnimationFrame(() => {
        anime({
          targets: [
            imageRefs.current[index], 
            headlineRefs.current[index]
          ],
          duration: 1500,
          easing: 'easeOutElastic',
          elasticity: 400,
          translateX: 0,
          translateY: 0
        });
      });
    };

    const contentLayouts = containerRefs.current;

    if (articlesStatus==='succeeded' && contentLayouts.length===articles.length) {
      // initShapeEl();
      // initShapeLoop(0);
    
      // Movement on hover 
      contentLayouts.forEach((el, index) => {
        el.addEventListener('mousemove', (e) => handleMouseMove(e, index));
        el.addEventListener('mouseleave', (e) => handleMouseLeave(e, index));
        el.addEventListener('mouseenter', (e) => handleMouseEnter(e, index));
      });
  
    } 

    return () => {
      if (articlesStatus==='succeeded' && contentLayouts.length===articles.length) {
        contentLayouts.forEach((el, index) => {
          el.removeEventListener('mousemove', (e) => handleMouseMove(e, index));
          el.removeEventListener('mouseleave', (e) => handleMouseLeave(e, index));
          el.removeEventListener('mouseenter', (e) => handleMouseEnter(e, index));
        });
      }
    }

  }, [articlesStatus, shapes, containerRefs, articles, initShapeLoop]);


  const [rootMargin, setRootMargin] = useState();
  const [isIntersecting, isAboveRootMargin] = useIsIntersecting(wrapRefs, articles, rootMargin);

  useEffect(() => {
    // const margin = isMobile ? Math.floor(screenWidth * -.1) :  Math.floor(screenWidth * -.2);
    // const margin = isMobile ? -100 : -150; // Values act like CSS margin
    const margin = -150;
    const newRootMargin = margin.toString()+'px 0px '+margin.toString()+'px 0px';
    if (rootMargin!==newRootMargin) setRootMargin(newRootMargin);
  }, [isMobile, rootMargin]);

  const [showArticleIndex, setShowArticleIndex] = useState(-1);

  const [itemClass, setItemClass] = useState([]);

  useEffect(() => {
    if (showArticleIndex<0) { // Don't animate when article is expanded to improve performance

      let newItemClass = [...itemClass];

      let itemClassChange = false;

      isIntersecting.forEach((value, index) => {
        //const i = index ? index : wrapRefs.current.length;
        const i = index%(shapes.length-1)+1;
        step.current = i;

        if (value) {
          newItemClass[index]='enter-animation';
          if (itemClass[index]!=='enter-animation') {
            itemClassChange=true;
          }
        }

        /*
        if (value) {
          initShapeLoop(i);
        } else {
          anime.remove(morphPathRefs.current[i]);
        }
        */
      })
   
      if (itemClassChange) setItemClass(newItemClass);
    }
  }, [isIntersecting, isAboveRootMargin, step, initShapeLoop, shapes, showArticleIndex, itemClass]);


  const scrollToElTop = useCallback((el, offset) => {
    const rect = el.getBoundingClientRect();
    const y = rect.top + window.scrollY + offset; 
    scrollToY(y);
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const [pathname, setPathname] = useState();
  const [previousPathname, setPreviousPathname] = useState();
  const previousScrollOnOpenRef = useRef(0);

  useEffect(()=> {
    if (pathname!==location.pathname) setPathname(location.pathname);
    if (location.state?.previousPathname && previousPathname!==location.state.previousPathname) {
      setPreviousPathname(location.state.previousPathname);
      if (location.state.scrollOnOpen>0) previousScrollOnOpenRef.current = location.state.scrollOnOpen;
    }
  }, [location, pathname, previousPathname]);

  const openArticleLink = (e, index, article) => {
    if (isPageReloaded) setIsPageReloaded(false);
    navigate(baseUrl+'/blog/'+article.urlTag, {
      state: {
        scrollOnOpen: window.scrollY,
        previousPathname: pathname
      }
    });
  }

  const expandedArticleContainerLeftRef = useRef(0);

  const pageAccessedByReload = () => (
    (window.performance.navigation && window.performance.navigation.type === 1) ||
      window.performance
        .getEntriesByType('navigation')
        .map((nav) => nav.type)
        .includes('reload')
  );

  const [isPageReloaded, setIsPageReloaded] = useState(false); // Set to false after article is opened

  useEffect(()=> {
    const reloaded = pageAccessedByReload();
    setIsPageReloaded(reloaded);
  }, []);

  const wrapRefBounds = useResizeObserver(wrapRefs);
  /*
  useEffect(()=> {
    console.log('showArticleIndex', showArticleIndex);
    console.log('wrapRefBounds', wrapRefBounds);
    if (!wrapRefBounds || !wrapRefBounds[showArticleIndex]) return;
    expandedArticleContainerLeftRef.current=wrapRefBounds[showArticleIndex].left +
      (wrapRefBounds[showArticleIndex].width-600)/2 // 600 = width of containerRef which is centered in wrapRef
    console.log('expandedArticleContainerLeftRef', wrapRefBounds[showArticleIndex].left +
    (wrapRefBounds[showArticleIndex].width-600)/2);
  }, [wrapRefBounds, showArticleIndex]);
  */

  /*
  useEffect(()=> {
    console.log('wrapRefs', wrapRefs);
  }, [wrapRefs, wrapRefBounds]);

  useEffect(()=> {
    console.log('wrapRefBounds', wrapRefBounds);
  }, [wrapRefBounds]);
*/

  const [resizeDatetime, setResizeDatetime] = useState(new Date()); // Do not delete; triggers rerender

  useLayoutEffect(()=> {
    const handleResize = () => {
      // console.log('handleResize window');
      if (containerRefs.current && containerRefs.current[showArticleIndex]) {
        setTimeout(() =>{
          const bounds = containerRefs.current[showArticleIndex].getBoundingClientRect();
          const offset = isOneColumn ? 0 : 0;
          // console.log('isOneColumn', isOneColumn);
          // console.log('bounds resize', bounds);
          expandedArticleContainerLeftRef.current=bounds.left-offset;
          setResizeDatetime(new Date()); // Trigger rerender
        }, 0);
      };
    }
    window.addEventListener('resize', handleResize, false);
    return () => {
      window.removeEventListener('resize', handleResize, false);
    }
  }, [showArticleIndex, isOneColumn])


  const openArticle = useCallback((e, index) => {
    // anime.remove(morphRefs.current[index]); // Stop the morphing animation to preserve memory... needed especially in Safari
    // anime.remove(morphPathRefs.current[index]);
    // console.log('openArticle');
    const offset = isOneColumn ? 0 : 0; // 60 = (600 container width for one column - 480 container width for two columns) / 2
    if (expandedArticleContainerLeftRef.current===0) {
      if (previousPathname && !isPageReloaded) {
        // console.log('NO WAIT');
        const bounds = containerRefs.current[index].getBoundingClientRect();
        //console.log('bounds', bounds);
        expandedArticleContainerLeftRef.current=bounds.left-offset; 
      } else {
        // console.log('WAIT 500MS');
        // On page reload, must first wait until render is finished
        setTimeout(() => {
          const bounds = containerRefs.current[index].getBoundingClientRect();
          //console.log('bounds2222 ', bounds);
          expandedArticleContainerLeftRef.current=bounds.left-offset;
        }, 500);
      }
    }

    setShowArticleIndex(index);
   
    setTimeout(()=> {
      const offset = -20;// previousPathname===baseUrl+'/blog' ? 100 : -20;
      scrollToElTop(wrapRefs.current[index], offset); /* 100 for margin-top: -100px on article-wrap */
    }, 500);

  }, [scrollToElTop, previousPathname, isPageReloaded, isOneColumn]);

  const closeArticleLink = () => {
    navigate(baseUrl+'/blog', {
      state: {
        // scrollOnOpen: windowScrollOnOpen,
        previousPathname: pathname
      }
    });
  }

  const closeArticle = useCallback((previousScrollOnOpen) => {
    expandedArticleContainerLeftRef.current=0;
    setTimeout(()=> {
      scrollToY(previousScrollOnOpen);
    }, 0);
    setArticleBackgroundHeight(0);
    // initShapeEl();
    initShapeLoop();
    setShowArticleIndex(-1);
    setBodyHeight(0);
  }, [initShapeLoop]);


  const scrollToY = (y) => {
    var ua = window.navigator.userAgent;
    if (ua.indexOf('MSIE ') > 0 ||
    ua.indexOf('Trident/') > 0 ||
    ua.indexOf('Edge/') > 0) {
      window.scrollTo(0,y);
    } else {
      window.scroll({ 
        top: y, 
        left: 0, 
        behavior: 'smooth'
      });
    }
  }

  const { articleUrlTag } = useParams();
  
/*
  useEffect(() => {
   if (!pageInitiated && articles && articles.length>0) {
      if (articleUrlTag) {
        const articleIndex = articles.findIndex(article => article.urlTag === articleUrlTag);
        if (articleIndex>-1) {
          console.log('setPageNotFound(false)', containerRefs.current.length);
          setPageNotFound(false);
        } else {
          console.log('setPageNotFound(true)', containerRefs.current.length);
          setPageNotFound(true);
        }
      } else if (typeof articleUrlTag === 'undefined') {
        console.log('setPageNotFound(false) 222', containerRefs.current.length);
        setPageNotFound(false);
      }
      if (pageInitiated===false) {
        console.log('setPageInitiated(true)', containerRefs.current.length);
        setTimeout(()=> {
          console.log('setTimeout setPageInitiated(true)', containerRefs.current.length);
          setPageInitiated(true);
        }, 0);
      }
    }
  }, [articles, articleUrlTag, pageInitiated]);  
*/

  useEffect(() => {
    if (articleUrlTag && articles && articles.length>0) {
      const articleIndex = articles.findIndex(article => article.urlTag === articleUrlTag);
      setPageNotFound(articleIndex>-1 ? false : true);
    } else {
      setPageNotFound(false);
    }
   }, [articles, articleUrlTag]); 

  useEffect(() => {
    if (articles && articles.length>0) {
      if (articleUrlTag) {
        const articleIndex = articles.findIndex(article => article.urlTag === articleUrlTag);
        const article = articles.find(article =>  article.urlTag === articleUrlTag);
        if (articleIndex>-1) {
          if (showArticleIndex!==articleIndex) {
            if (itemClass[articleIndex]!=='enter-animation') {
              let newItemClass = [...itemClass];
              newItemClass[articleIndex]='enter-animation';
              setItemClass(newItemClass);
            }
            setTimeout(() => openArticle(null, articleIndex, article), 600);
          }
        }
      } else if (typeof articleUrlTag === 'undefined') {
        if (showArticleIndex>-1) {
          closeArticle(previousScrollOnOpenRef.current);
          previousScrollOnOpenRef.current = 0;
        }
      }
    } 
   }, [articles, articleUrlTag, openArticle, closeArticle, showArticleIndex, itemClass]);  


  const bodyHeightRef = useRef();
  const [bodyHeight, setBodyHeight] = useState(0);
  const [articleBackgroundHeight, setArticleBackgroundHeight] = useState(0);

  const setHeights = () => {
    if (bodyHeightRef.current) {
      const rect = bodyHeightRef.current.getBoundingClientRect();
      setBodyHeight(bodyHeightRef.current.clientHeight + rect.top + window.scrollY + 300);
      setArticleBackgroundHeight(bodyHeightRef.current.offsetTop + bodyHeightRef.current.clientHeight + 350);
    }
  }

  const handleAnimationEndBody = (e) => {
    if (e.animationName.indexOf('expand-body')>-1) setHeights();
  }

  useLayoutEffect(()=> {
    const handleResize = () => {
      if (showArticleIndex>0 && bodyHeightRef.current) setTimeout(() => {setHeights()}, 300); 
    }
    setTimeout(() => {
      setHeights();
    }, 1000); // setTimeout to let render animation finish first (mostly, body takes 1.5s...use handleAnimationEndBody to correct)

    window.addEventListener('resize', handleResize, false);
    return () => {
      window.removeEventListener('resize', handleResize, false);
    }
  }, [bodyHeightRef, showArticleIndex])

  const [loadedImages, setLoadedImages] = useState([]);

  const handleLoadImage = (index) => {
    const newLoadedImages = [...loadedImages];
    newLoadedImages[index] = true;
    setLoadedImages(newLoadedImages);
  }

  return pageNotFound===null
    ? <div id="articles-container">
        <div className="background">
          <div className="left"></div>
          <div className="right"></div>  
        </div>
        <div className="pink-background"></div>
      </div>
    : pageNotFound===true
    ? <Error404 />
    : <div id="articles-container">

        <div className="background">
          <div className="left"></div>
          <div className="right"></div>  
        </div>
        <div className="pink-background"></div>
        
        <div 
          className="article-links-container"
          style={{height: bodyHeight || 'initial'}}
        >
        
          <div className="page-header">{general.articles}</div>
          
          <div className={`fade-background ${showArticleIndex>-1 ? 'expand' : 'collapse'}`}>
          </div>

            {(!articles || articles.length===0 || !loadedImages[0]) &&
              <Loader />
            }

            <div 
              id="article-layout"
              className={`${showArticleIndex>0 ? 'expand' : 'collapse'}`}
            >
              {articles && articles.length>0 && articles.map((article, i) => (
                  <div 
                    key={'article-'+i} 
                    id={'article-wrap-'+i}
                    ref={el => wrapRefs.current[i] = el} 
                    className={
                      `article-wrap ${i===showArticleIndex ? 'expand' : 'collapse'}
                      ${loadedImages[i]===true ? ' enter' : ''}
                      ${i===showArticleIndex && 
                        (showArticleIndex===0 || 
                          ( showArticleIndex===1 && 
                            wrapRefBounds && 
                            wrapRefBounds['article-wrap-0'] && 
                            wrapRefBounds['article-wrap-1'] && 
                            Math.abs(parseInt(wrapRefBounds['article-wrap-0'].top)-parseInt(wrapRefBounds['article-wrap-1'].top))<100
                          )
                        )
                          ? ' first' 
                          : ''
                        }
                      ${i===showArticleIndex && previousPathname!==baseUrl+'/blog' ? ' landing-page' : ''}`
                    }   
                  >
                    <div className={itemClass[i] || 'pre-animation'}>
                      <div 
                        ref={el => containerRefs.current[i] = el} 
                        className={`article-container article-container-${(i%6)+1}${i===showArticleIndex ? ' expand' : ''}`}
                      >
                        <div 
                          className="article-container-movement"
                          style={{left: i===showArticleIndex ? -1 * expandedArticleContainerLeftRef.current : 0}}
                        >
                          <div
                            ref={el => imageRefs.current[i] = el} 
                            className={`article-image${i===showArticleIndex ? ' expand' : ''}`}
                            onClick={(e) => openArticleLink(e, i, article)}
                          >
                            <img src={article?.imageUrl} alt="" onLoad={() => handleLoadImage(i)} />
                          </div>
                          <div 
                            ref={el => headlineRefs.current[i] = el} 
                            className={`headline${i===showArticleIndex ? ' expand' : ''}`}
                            onClick={(e) => openArticleLink(e, i, article)}
                          >
                            <h3>{article?.copy[language]?.headline || article?.copy?.en?.headline}</h3>
                          </div>
                          
                          <div 
                            className="lede"
                            onClick={(e) => openArticleLink(e, i, article)}
                          >
                            {article?.copy[language]?.lede || article?.copy?.en?.lede }
                          </div>
                          {i===showArticleIndex && 
                            <>
                              <div className="byline">
                                {article?.byline && 
                                  <>By {article?.byline}</>
                                }
                              </div>
                              <div 
                                ref={bodyHeightRef} 
                                className="body"
                                onAnimationEnd={handleAnimationEndBody}
                              >
                                {ReactHtmlParser(article?.copy[language]?.body || article?.copy?.en?.body)}
                              </div>
                            </>
                          }
                        </div>
                      </div>
                      {/* 
                      <div className={'triangle-wrapper triangle-wrapper-'+((i%6)+1)}>
                        <div 
                          className="triangle-container"
                          onClick={(e) => openArticleLink(e, i, article)}
                        >
                          <div className="triangle"></div>
                        </div>
                      </div>
                      */}
                      {/* 
                      <div className={'morph-wrap-item morph-wrap-'+((i%6)+1)}>
                        <svg ref={el => morphRefs.current[i] = el} className="morph" width="1400" height="770" viewBox="0 0 1400 770">
                          <path 
                            ref={el => morphPathRefs.current[i] = el} 
                            d={initialMorphPath}
                          />
                        </svg>
                      </div>
                      */}
                      <div className={'blobs blob-'+((i%12)+1)}>
                        <div>
                        {Blobs[i]}
                        </div>
                      </div>
                      <div 
                        className="article-background"
                        style={{
                          height: articleBackgroundHeight, 
                          left: wrapRefBounds && wrapRefBounds['article-wrap-'+i] && wrapRefBounds['article-wrap-'+i].left ? -wrapRefBounds['article-wrap-'+i].left : 0
                        }}
                      >
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>

            {showArticleIndex>-1 && 
              <div 
                className="icon-close"
                onClick={closeArticleLink}
              >
              </div>
            }
        </div>
    
      </div>
}

export default Articles;
