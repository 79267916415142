import React, { useState, useEffect, useRef } from 'react';
// import { Link } from 'react-router-dom'; 
import Birds from './Birds/Birds';
import Clouds2 from './Clouds/Clouds2';
import useIsIntersecting from '../../hooks/useIsIntersecting';
import useIsMobile from '../../hooks/useIsMobile';
// import useScreenWidth from '../../hooks/useScreenWidth';
// import anime from 'animejs';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './Home.scss';

// import data from '../../translations/es.json';

/*
const slides = [
  [
    'Make their own decisions.',
    'Participate in designing their own individualized service plan.'
  ],
  [
    'Have proper support in their homes.',
    'Live with dignity and respect.'
  ],
  [
    'Engage as an active member in their communities.'
  ],
  [
    'Have proper support in maintaining employment.'
  ],
  [
    'Equal access to economical, educational, social and political opportunities.'
  ]
];
*/
function Home({ data }) {

  const { blocks } = data;
  const { slides } = blocks[2];

  const [playStreet, setPlayStreet] = useState(1); // Default = 1

  const playAnimation = (e, id) => {
    if (e.animationName==='enter-townhouses' && 
        id==='townhouses' && 
        playStreet<2) {
        setPlayStreet(2);
    }
  }

  const animateRefs = useRef([]);
  const [isIntersecting, setIsIntersecting] = useState([]);
  // const screenWidth=useScreenWidth();
  const [rootMargin, setRootMargin] = useState('0px 0px -300px 0px');
  const isMobile = useIsMobile();
  
  useEffect(() => {
    animateRefs.current = animateRefs.current.slice(0, document.querySelectorAll('.intersection').length); 
  }, [playStreet]);
  
  useIsIntersecting(animateRefs, isIntersecting, setIsIntersecting, rootMargin, playStreet);
  
  useEffect(() => {
    // const margin = isMobile ? Math.floor(screenWidth * -.1) :  Math.floor(screenWidth * -.2);
    const margin = isMobile ? -100 : -300;
    const newRootMargin = '0px 0px '+margin.toString()+'px 0px';
    if (rootMargin!==newRootMargin) setRootMargin(newRootMargin);
  }, [isMobile, rootMargin]);


  const [currentSlide, setCurrentSlide] = useState(0);
 // const [jumpSlide, setJumpSlide] = useState(-1);
  // const [slideClass, setSlideClass] = useState('');

  const sliderTimer = useRef();

  /*
  useEffect(() => {

    if (isIntersecting[1] && jumpSlide!==-2) {

      sliderTimer.current = setInterval(() => {
        setSlideClass('start-flip');
      }, 4500);
      
      if (jumpSlide>-1) setSlideClass('start-flip');

      return () => {
        if (jumpSlide<0) clearInterval(sliderTimer.current);
      }
    }

  },[isIntersecting, jumpSlide]);
*/

useEffect(() => {

  if (isIntersecting[1]) {

    const nextSlide = currentSlide===slides.length - 1 ? 0 : currentSlide + 1;

    sliderTimer.current = setInterval(() => {
      setCurrentSlide(nextSlide);
    }, 6000);
    
    return () => {
      if (sliderTimer.current) clearInterval(sliderTimer.current);
    }
  }

},[isIntersecting, currentSlide, slides]);

/*
  const finishFlipSlide = (e) => {
    if (e.animationName==='begin-flip' || e.animationName==='begin-flip-mobile') {
      let newCurrentSlide= jumpSlide>-1 ? jumpSlide : currentSlide+1;
      if (newCurrentSlide>slides.length-1) newCurrentSlide=0;
      setCurrentSlide(newCurrentSlide);
      setSlideClass('end-flip');
      if (jumpSlide>-1) setJumpSlide(-2);
    } 
  }

  const goToSlide = (index) => {
    if (currentSlide===index) return;
    if (sliderTimer.current) clearInterval(sliderTimer.current);
    setJumpSlide(index);
  }
  */

  const goToSlide = (index) => {
    if (currentSlide!==index) setCurrentSlide(index);
  }
  
/*
  const drawSVG = (container) => {
    var pathEls = container.querySelectorAll('path, line, rect, ellipse, circle');
    for (var i = 0; i < pathEls.length; i++) {
      var pathEl = pathEls[i];
      var offset = anime.setDashoffset(pathEl);
      pathEl.setAttribute('stroke-dashoffset', offset);
      anime({
        targets: pathEl,
        strokeDashoffset: [anime.setDashoffset, 0],
        duration: 1000,
    /*
        fill: pathEl.nodeName === 'rect' && pathEl.classList[0]==='st0'
          ? '#009AC7' // fluid
          : pathEl.classList && pathEl.classList[0]==='st6'
          ? '#00467F' // lettering
          : pathEl.nodeName === 'rect' && pathEl.classList && pathEl.classList[0]==='st7'
          ? '#fff' // plastic
          : 'rgba(0,0,0,0)', // body,
    */
    //  duration: anime.random(1000, 3000),
    //  delay: anime.random(0, 2000),
 /*       delay: function(el, i) { return i * 250 },
        easing: 'easeInOutSine',
        autoplay: true,
        /*
        begin: function(anim) {
          var lineEls = container.querySelectorAll('line');
          for (var i = 0; i < lineEls.length; i++) {
            lineEls[i].setAttribute("stroke", "#00467F");
          }
          var pathEls = container.querySelectorAll('path');
          for (i = 0; i < pathEls.length; i++) {
            pathEls[i].setAttribute("stroke", "#00467F");
          }
        },
        */
/*      complete: () => {}
      });
    }
  }
*/

  /*
  useEffect(() => {
    if (isIntersecting[0]===true) {
      drawSVG(animateRefs.current[0]);
    }
  }, [isIntersecting]);
*/

  return (
    <div id="home-container" className={playStreet>1 ? 'play exit-mask' : playStreet>0 ? 'play' : ''}>
        <div className="background">
          <div className="left"></div>
          <div className="right"></div>  
        </div>
        <div className="pink-background"></div>

        <div id="section-1" className="foreground">
          <div className="left">
            <div className="heading">{blocks[0].heading}</div>
            <div>
              {blocks[0].copy}
            </div>
          </div>
          <div id="street-scene" className="right">
            <div id="street-container">
              <div id="ground-container">
                <svg 
                  id="ground" 
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" y="0px"
                  viewBox="0 0 1400 850"
                >
                  <path className="st0" d="M21.3,768c2.9-7.1,5.6-13.4,8.2-19.8c9.9-24.1,19.6-48.3,29.6-72.3c8.4-20.2,18.7-39.3,33.1-56.1
                    c21.3-24.9,46.8-43.8,76.9-56.7c15.2-6.5,30.9-10.7,47.1-13.5c4.9-0.9,10-1,15-1c329.4,0,658.9,0,988.3-0.1c4,0,6.6,1.2,9.4,3.9
                    c14.5,14.2,29.3,28.1,44,42.1c15,14.3,30.7,28,44.9,43.2c17.3,18.5,32.4,38.8,39.7,63.7c3.6,12.1,4.4,24.2,0.4,36.2
                    c-3.9,11.5-12.5,19.1-23.5,23.9c-12.2,5.3-25,6.8-38.3,6.8c-422.4-0.1-844.9-0.1-1267.3-0.1C26.5,768,24.3,768,21.3,768z"/>
                  {/* 
                    <path id="background-shape" className="st1" d="M678.1,44.1c0,0,106.7,1.2,228.4,43.4c125.9,43.7,241.4,46.2,307.3,68.6
                          c76.3,25.9,208.8,119.1,132,275.9c-71.1,110.9-302.9,122.5-407.8,198.7c-89.2,64.7-326.2,40.5-450.5-73.6S222.6,76.7,678.1,44.1z"/>
                  */}
                  
                </svg>
              
              </div>
              
              <div id="people-container">
                <svg 
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" y="0px"
                  viewBox="0 0 1400 850"
                  
                > 
                  <g id="card-table">
                    <ellipse className="st17" cx="429.6" cy="581.6" rx="92.9" ry="27.1"/>
                    <path className="st17" d="M437.7,664.1c0,0,24.4,6.5,23.7,11.4s-64.7,5.3-66.4,0.8c-1.8-4.5,23.9-12.2,23.9-12.2v-79h18.9L437.7,664.1z"/>
                    <path className="st14" d="M306.6,554.2c-1,1.9-0.8,9.8-0.8,9.8l-10.2-4c0.3-2.1,0.7-4.1,1.3-6.1c-4.9-2.8-8.1-9.2-8.9-12.2
                      c1.5-0.8,1.1-17.6,15.6-22.2c12.4-2.6,18.6-2.3,18.6-2.3s0.8,12.5,0,20.5c0.4,1.4,2.3,2.8,1.6,3.8s-1.2,0.5-3.3,1.2
                      C319.4,549.4,313.5,554.4,306.6,554.2z"/>
                    <path className="st24" d="M347.6,636l-52.5-0.2l-1.6-22.2l20.4,1c2.6,0.2,5.1-0.1,7.6-0.6c5.3-1.2,22.6-2.3,22.6-2.3s14.5-2.1,16,4.7
                      c1.3,5.5,6.3,63.6,6.3,63.6l-18.7,1.4L347.6,636z"/>
                    <path className="st25" d="M319.1,547.4l-10.7-4.5c0,0,0,8.5,6.5,9.3"/>
                    <path className="st26" d="M310.4,528.6c1.4-0.8,2.9-1.1,4.5-0.9"/>
                    <path className="st24" d="M327.6,620c0,0-0.9-5.7-2.6-18.5c-1-7.3-2.8-14.3-5.6-21.1c-2.1-5.3-6.8-11.8-9.3-17.2
                      c-4.3-9.4-14.9-6.1-14.9-6.1s-7.6,1-6.7,27.1c0.8,24.1,6.1,39.4,6.1,40.5S327.6,620,327.6,620z"/>
                    <path className="st14" d="M332.3,570c0,0,4.9-5.1,8.8-4.2c2.5,0.6,6.2,0.2,8.9,5.6c-1.9,1.5-8.7-1.8-8.7-1.8l-32,32.6
                      c0,0-3.8-2.9-11.9-16.5c7.1-1.1,10.4-1.6,10.4-1.6l3.9,4.4L332.3,570z"/>
                    <polyline className="st27" points="293.3,573.2 295.3,586 308.7,583.7 306.9,572.3 	"/>
                    <path className="st3" d="M378.1,687.6c-1.5-2.4-3.7-4.2-6.4-5.3c-4.2-1.4-12.6-2.3-12.6-2.3l-6.9,1.4l0.7,9.3L378.1,687.6z"/>
                    <path className="st28" d="M347.6,635.8H288v-53.5c0,0-1.4-5.9-4.7-5.7s-2.4,6.7-2.4,6.7v61.1h65.9L347.6,635.8z"/>
                    <path className="st3" d="M321.2,510.3c0,0,2.6,1.6,1.1,10.2c-5.9-2.2-13.9,2.4-21.7,6.6c-11.3,6-12,15.8-12,15.8s-12.6-23.1-1.1-30.8
                      C303.2,501.7,321.2,510.3,321.2,510.3z"/>
                    <circle className="st3" cx="315.2" cy="532.9" r="1.2"/>
                    <rect x="280.9" y="637.6" className="st28" width="7.4" height="57.9"/>
                    <rect x="339.4" y="637.6" className="st28" width="7.4" height="57.9"/>
                    <rect x="291.4" y="639.5" className="st28" width="7.4" height="41.2"/>
                    <rect x="335.7" y="639.5" className="st28" width="7.4" height="41.2"/>
                    <path className="st29" d="M552,554.2c1,1.9,0.8,9.8,0.8,9.8l10.2-4c-0.3-2.1-0.7-4.1-1.3-6.1c4.9-2.8,8-9.2,8.9-12.2
                      c-1.5-0.8-1.2-17.6-15.6-22.2c-12.4-2.6-18.6-2.3-18.6-2.3s-0.8,12.5,0,20.5c-0.4,1.4-2.3,2.8-1.6,3.8s1.2,0.5,3.3,1.2
                      C539.2,549.4,545.1,554.4,552,554.2z"/>
                    <path className="st29" d="M499.9,628.1c0,0,0.4,53.9-0.4,54.7c-1.3,0.9-2.7,1.8-4.2,2.5l2.1,6.2l7.2,1.3c0,0,0.4-8.9,0.6-12.1
                      c0.1-2.4,1.2-35.3,1.2-35.3L499.9,628.1z"/>
                    <path className="st17" d="M509.5,635.4l54.1,2.8l1-21.3l-20.4,1c0,0-3.4-0.5-7.6-0.6c-12.7-0.5-25-1.7-25-1.7s-11.7-2.1-12.8,3.4
                      s0.2,59,0.2,59l9.3,1.7L509.5,635.4z"/>
                    <path className="st6" d="M545.4,509.1c0,0-10.7,2.8-9.2,11.3c5.9-2.2,7.4,0.8,17.7,5.3c7.6,1.2-0.8,27.1,7,31.2c11.3,6,16-1.6,16-1.6
                      s3.4-32.9-5.8-43.3C561.3,501.2,545.4,509.1,545.4,509.1z"/>
                    <path className="st25" d="M539.5,547.4l10.7-4.5c0,0,0,8.5-6.6,9.3"/>
                    <path className="st26" d="M548.1,528.6c-1.4-0.8-2.9-1.1-4.5-0.9"/>
                    <path className="st26" d="M543.3,537c-1,0.1-1.9-0.1-2.8-0.6"/>
                    <path className="st26" d="M542.2,523c-2.3,0-3-1.9-5.1-0.7"/>
                    <path className="st26" d="M552.9,542.4c0.1,0.5,0,1-0.3,1.4"/>
                    <path className="st26" d="M550.6,539.8c0.5,0,1,0.1,1.4,0.3"/>
                    <path className="st30" d="M537.5,617.8c0,0,0.4-8.3,2.1-21.1c0.8-5.9-5-12.2-2.5-19.2c1.5-4.3,8.9-8.9,11.4-14.3
                      c4.3-9.4,14.8-6.1,14.8-6.1s7.6,1,6.7,27.1c-0.8,24.1-6.1,39.4-6.1,40.5S537.5,617.8,537.5,617.8z"/>
                    <path className="st29" d="M526.3,570c0,0-4.9-5.1-8.8-4.2c-2.5,0.6-6.2,0.2-8.9,5.6c1.9,1.5,8.7-1.8,8.7-1.8l32,32.6
                      c0,0,3.8-2.9,11.9-16.5c-7.1-1.1-10.4-1.6-10.4-1.6l-4,4.4L526.3,570z"/>
                    <polyline className="st31" points="565.3,573.2 563.2,586 549.8,583.7 551.7,572.3 	"/>
                    <path className="st3" d="M480.4,690.4c1.5-2.4,3.7-4.2,6.4-5.3c4.2-1.4,12.6-2.3,12.6-2.3l6.8,1.4l-0.7,9.3L480.4,690.4z"/>
                    <path className="st28" d="M511,635.8h59.6v-53.5c0,0,1.4-5.9,4.7-5.7s2.3,6.7,2.3,6.7v61.1h-65.9L511,635.8z"/>
                    <circle className="st3" cx="543.3" cy="532.9" r="1.2"/>
                    <rect x="570.2" y="637.6" className="st28" width="7.4" height="57.9"/>
                    <rect x="511.7" y="637.6" className="st28" width="7.4" height="57.9"/>
                    <rect x="559.7" y="639.5" className="st28" width="7.4" height="41.2"/>
                    <rect x="515.4" y="639.5" className="st28" width="7.4" height="41.2"/>
                    <path className="st32" d="M363.2,582.3l14.8,1.3c2.4,0.2,4.1,2.3,3.9,4.7l-0.4,5c-0.2,2.4-2.3,4.1-4.7,3.9l-14.8-1.3
                      c-2.4-0.2-4.1-2.3-3.9-4.7l0.4-5C358.8,583.9,360.9,582.1,363.2,582.3z"/>
                    <path className="st32" d="M481.3,584.9l14.7-2c2.4-0.3,4.5,1.3,4.9,3.7l0.7,5c0.3,2.4-1.3,4.5-3.7,4.9l-14.7,2
                      c-2.4,0.3-4.5-1.3-4.9-3.7l-0.7-5C477.3,587.4,478.9,585.2,481.3,584.9z"/>
                    <path className="st32" d="M420.3,577.1h14.8c2.4,0,4.3,1.9,4.3,4.3v5.1c0,2.4-1.9,4.3-4.3,4.3h-14.8c-2.4,0-4.3-1.9-4.3-4.3v-5.1
                      C416,579.1,418,577.1,420.3,577.1z"/>
                    <path className="st32" d="M361.7,567.5l14.6-2.4c2.4-0.4,4.6,1.2,5,3.6l0.8,5c0.4,2.4-1.2,4.6-3.6,5l-14.6,2.4c-2.4,0.4-4.6-1.2-5-3.6
                      l-0.8-5C357.7,570.1,359.3,567.9,361.7,567.5z"/>
                    <circle className="st17" cx="369.9" cy="572.7" r="3.3"/>
                    <circle className="st17" cx="489.9" cy="590.3" r="3.3"/>
                    <path className="st32" d="M483.6,564.7l14.5,3c2.3,0.5,3.8,2.8,3.4,5.1l-1,5c-0.5,2.3-2.8,3.8-5.1,3.4l-14.5-3
                      c-2.3-0.5-3.8-2.8-3.4-5.1l1-5C479,565.7,481.3,564.2,483.6,564.7z"/>
                    <path className="st32" d="M420.3,562h14.8c2.4,0,4.3,1.9,4.3,4.3v5.1c0,2.4-1.9,4.3-4.3,4.3h-14.8c-2.4,0-4.3-1.9-4.3-4.3v-5.1
                      C416,563.9,418,562,420.3,562z"/>
                    <circle className="st17" cx="427.8" cy="568.9" r="3.3"/>
                    <circle className="st3" cx="370" cy="589.1" r="3.3"/>
                    <circle className="st3" cx="490" cy="572.6" r="3.3"/>
                    <circle className="st3" cx="427.8" cy="584.1" r="3.3"/>
                  </g>
                  <g id="wheelchair">
                    <path className="st3" d="M1137.3,573.1c0,0-13.5,4.7-9.6,15.3c9.6,25.7,27.9,9.4,27.9,9.4l15.5,7.8c0,0,6.5-18.3-3.7-28.8
                      C1155.3,564.3,1137.3,573.1,1137.3,573.1z"/>
                    <path className="st12" d="M1090.1,666.8c0,0-0.3-7.8,2.8-8.3c1.5-0.1,2.9,0.3,4,1.3l-0.2,2.9c-0.8-0.6-1.7-1-2.7-1.1
                      c-0.8-0.1-0.8,3-0.8,3L1090.1,666.8z"/>
                    <path className="st29" d="M1144.7,640.4c0,0-8,13.8-9.6,15.1s-22.3,7.4-23.5,7.4c-1.3,0-7.1-2.3-10.7,2.3c1.6,2.3,2.6,0.7,3.5,1
                      s-5.8,1.9-5.2,2.9s2.9,5.8,6.8,5.2s7.4-5.8,7.4-5.8s25.8-6.6,28.1-7.6s10.5-16.3,10.5-16.3L1144.7,640.4z"/>
                    <path className="st29" d="M1150.1,625.8c1.4,2.1,2.1,11,2.1,11l11.8-10.2l-2.9-2.3c6.9-2.8,9.5-15.6,10.1-19.2
                      c-1.8-0.8-5.4-9.3-17.4-11.5c-9.3-1.3-18.3-4-26.8-8.1c0,0,0.3,15.7,3.3,23.3c-0.3,1.6-2.2,3.5-1.3,4.6c0.9,1.1,1.4,0.4,4,0.9
                      C1134.9,621.9,1142.3,626.8,1150.1,625.8z"/>
                    <path className="st14" d="M1061.5,645.7c1.1,2,0.8,10.3,0.8,10.3l10.8-4.2c-0.3-2.2-0.7-4.3-1.3-6.4c5.2-3,8.5-9.7,9.4-12.9
                      c-1.6-0.9-1.2-18.6-16.5-23.4c-13.1-2.7-19.6-2.5-19.6-2.5s-0.9,13.3,0,21.6c-0.4,1.5-2.4,3-1.7,4.1c0.7,1.1,1.3,0.5,3.5,1.2
                      C1048,640.6,1054.3,645.9,1061.5,645.7z"/>
                    <circle className="st33" cx="1018" cy="788.9" r="11.6"/>
                    <line className="st34" x1="1057.9" y1="749.6" x2="1085" y2="762"/>
                    <line className="st34" x1="1054.7" y1="762" x2="1084.5" y2="763.4"/>
                    <line className="st34" x1="1057.1" y1="776.6" x2="1084.3" y2="764.6"/>
                    <line className="st34" x1="1064.2" y1="787.2" x2="1085" y2="765.9"/>
                    <line className="st34" x1="1075.3" y1="793.9" x2="1086.4" y2="766.3"/>
                    <line className="st34" x1="1087.8" y1="796.5" x2="1087.9" y2="766.8"/>
                    <line className="st34" x1="1101.1" y1="794.6" x2="1090.4" y2="766.8"/>
                    <line className="st34" x1="1112" y1="787.9" x2="1091.7" y2="766.1"/>
                    <line className="st34" x1="1119.3" y1="777.1" x2="1092.2" y2="764.7"/>
                    <line className="st34" x1="1122.5" y1="764.8" x2="1092.7" y2="763.3"/>
                    <line className="st34" x1="1120.1" y1="750.2" x2="1092.9" y2="762.2"/>
                    <line className="st34" x1="1113" y1="739.6" x2="1092.2" y2="760.9"/>
                    <line className="st34" x1="1101.9" y1="732.8" x2="1090.8" y2="760.4"/>
                    <line className="st35" x1="1017.4" y1="745.6" x2="1017.4" y2="776.5"/>
                    <line className="st35" x1="1090.3" y1="692.7" x2="1090.3" y2="723.6"/>
                    <path className="st6" d="M1095.6,664.1l-2,4.4l16.4,10.2c1,0,1.8-0.5,2.3-1.3c0.7-1.3,1.5-2.8-0.6-3.2S1095.6,664.1,1095.6,664.1z"/>
                    <path className="st6" d="M1096.8,658.2l-2,4.5l16.4,10.2c1,0,1.8-0.5,2.4-1.3c0.6-1.3,1.5-2.8-0.7-3.2S1096.8,658.2,1096.8,658.2z"/>
                    <path className="st14" d="M1015.6,712c0,0-29.4,50.6-30.5,50.8c-1.7,0.1-3.4,0-5.1-0.2l-1.8,6.7l5.6,5.4c0,0,5.6-7.6,7.6-10.3
                      c1.5-2.1,24.3-29,24.3-29L1015.6,712z"/>
                    <polygon className="st4" points="985.8,760.9 991.6,765 1015.6,735.5 1015.6,711 	"/>
                    <path className="st4" d="M1018.8,710.4c0,0,20.9,0.5,26.4,1.8c2.6,0.6,5.4,0.8,8.1,0.7l21.5-1l-1,22.5l-58.2,1L1018.8,710.4z"/>
                    <path className="st6b" d="M1035.9,720c0,0,0.8-8.1,8.7-8.5s43.9,0,43.9,0v29.3h-52.2C1036.3,740.8,1035.1,720,1035.9,720z"/>
                    <path className="st12" d="M1068.9,695.3c1.2-1.4,0-3.8,0-3.8h19.3c0,0,0.5-14.7,0.3-19.3s0-6,1.9-7.5s5.1-0.5,5.1-0.5s-0.3,5-2,4.4
                      s-1.7,0.2-1.7,0.2v76.5h-71.3l-1.6,32l-3.5-0.2l-0.7-31.7c-0.8-0.1-1.5,0-2.3,0.2c-1.7,1.4-24.3,32.2-25.8,34.2s-2.9,1.7-2.9,1.7
                      s-14.5-10.6-16.3-14.2c-1.7-3.6,1.9-3.1,1.9-3.1l14.9,13.5c0,0,19.3-25.7,23.1-31.5c3.8-5.8,8.2-5.5,8.2-5.5v-33.9
                      c0-8.7,3.9-11,3.9-11s6.3,0,3.3,1.2c-3.1,1.2-4.3,7-4.3,7v36.6h70v-45.2L1068.9,695.3z"/>
                    <path className="st14" d="M1028.3,689.1c0,0-6,0-7.9,1.9s-4.9,4.3-3.6,10.5c2.6,0,6-7.3,6-7.3h40.4c0,0,4.1,1.3,7.7-15.4
                      c-6.4-1.1-12-1.9-12-1.9l-1.5,9.8L1028.3,689.1z"/>
                    <polyline className="st36" points="1075.6,665.7 1073.4,679.2 1059.3,676.9 	"/>
                    <circle className="st37" cx="1089.2" cy="763.4" r="35.7"/>
                    <circle className="st38" cx="1089.1" cy="763.3" r="38.1"/>
                    <path className="st39" d="M1057.1,743.1c6.9-11.7,19.8-18.7,33.4-18.1"/>
                    <path className="st39" d="M1127.1,769.3c-2,13.5-11.3,24.8-24.1,29.3"/>
                    <line className="st34" x1="1076.1" y1="732.2" x2="1086.8" y2="760"/>
                    <line className="st34" x1="1065.2" y1="738.8" x2="1085.5" y2="760.6"/>
                    <line className="st34" x1="1089.4" y1="730.2" x2="1089.3" y2="760"/>
                    <circle className="st32" cx="1088.4" cy="762.2" r="3.9"/>
                    <circle className="st40" cx="1018" cy="788.9" r="12.4"/>
                    <path className="st39" d="M1007.9,781.9c0,0,3.2-5.3,9.5-5.1"/>
                    <path className="st39" d="M1029.7,791.9c-0.6,3.8-3.2,7-6.8,8.3"/>
                    <path className="st20" d="M1054.6,598c0,0-11.3,3-9.7,12c6.3-2.3,10.9,5,18.7,5.6c17.7,2.7,17,17.8,17,17.8s13.8-20.6,4.1-31.6
                      C1074.5,590.3,1054.6,598,1054.6,598z"/>
                    <polygon className="st29" points="1143.2,782.1 1142.1,786.6 1147.4,789.2 1151,781.3 	"/>
                    <polygon className="st29" points="1207.1,782.1 1210.7,785.1 1215.4,781.6 1210,774.8 	"/>
                    <path className="st24" d="M1163.1,703.6c0,0-8.3,39.6-9.9,44.2c0,0-11.7,32.3-11.8,35.2c9.4,3.9,9.6,3.9,9.6,3.9s14.2-31.3,19.1-53
                      c0.7-24.4,2.5-25.6,2.5-25.6L1163.1,703.6z"/>
                    <path className="st41" d="M1134.5,618.5l11.3-4.8c0,0,0,9-6.9,9.8"/>
                    <path className="st3" d="M1144.2,599.6c-1.5-0.9-3.3-1.2-5-1.1c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6c1.5-0.2,3.1,0.1,4.4,0.9
                      c0.3,0.2,0.7,0.1,0.8-0.2l0,0C1144.6,600.1,1144.5,599.7,1144.2,599.6L1144.2,599.6z"/>
                    <path className="st41" d="M1048.4,638.4l11.3-4.8c0,0,0,9-6.9,9.8"/>
                    <path className="st3" d="M1057.8,618.1c-1.5-0.9-3.3-1.2-5.1-1.1c-0.3,0-0.6,0.4-0.5,0.7c0,0.3,0.3,0.5,0.5,0.5
                      c1.5-0.2,3.1,0.1,4.4,0.9c0.3,0.2,0.7,0.1,0.8-0.2l0,0C1058.2,618.7,1058.1,618.3,1057.8,618.1L1057.8,618.1z"/>
                    <path className="st42" d="M1052.3,626.9h-0.7h-0.4h-0.2h-0.1c-0.2,0-0.5-0.1-0.7-0.2c-0.1,0-0.1,0-0.2-0.1h-0.1l-0.3-0.2
                      c-0.3-0.2-0.7-0.1-0.9,0.2c-0.2,0.3-0.1,0.7,0.2,0.9c1,0.6,2.1,0.8,3.3,0.7c0.3,0,0.6-0.3,0.6-0.6
                      C1053,627.2,1052.7,626.9,1052.3,626.9L1052.3,626.9z"/>
                    <path className="st42" d="M1051.2,612.1c-1-0.1-1.9-0.4-2.7-0.9c-1-0.4-2.1-0.3-3,0.3c-0.3,0.2-0.3,0.6-0.1,0.9
                      c0.2,0.2,0.5,0.3,0.7,0.2s0.4-0.2,0.7-0.3h0.2c0,0,0.2,0,0.1,0h0.3h0.2c-0.1,0,0,0,0,0c0.1,0,0.2,0,0.3,0.1l0.3,0.1
                      c0.2,0.1-0.1,0,0.1,0l0.1,0.1c0.9,0.5,1.9,0.8,2.9,0.8c0.3,0,0.6-0.4,0.5-0.7C1051.7,612.3,1051.5,612.1,1051.2,612.1L1051.2,612.1
                      z"/>
                    <path className="st42" d="M1049.3,613.7c-0.1,0-0.2,0-0.3,0c0,0,0.2,0,0,0h-0.1c-0.1,0-0.3-0.1-0.4-0.1l0,0h-0.1l-0.2-0.1l-0.4-0.2
                      c-0.6-0.2-1.3-0.2-1.8,0.2c-0.3,0.2-0.4,0.6-0.2,0.8l0,0c0.2,0.3,0.6,0.4,0.8,0.2l0.1-0.1l0,0l0,0h0.1h0.1c0.1,0,0.1,0,0,0h0.2h0.2
                      h0.2c0.1,0-0.2-0.1,0,0h0.1c0.3,0.1,0.5,0.2,0.8,0.3s0.7,0.2,1,0.2s0.6-0.3,0.6-0.6C1049.9,614,1049.6,613.7,1049.3,613.7
                      L1049.3,613.7z"/>
                    <path className="st42" d="M1061.9,633.1c0,0.2,0,0.5,0,0.7v-0.2c0,0.2-0.1,0.4-0.1,0.5l0.1-0.2l-0.1,0.3c-0.1,0.1-0.1,0.3-0.1,0.5
                      c0.1,0.3,0.4,0.5,0.8,0.4c0.2-0.1,0.3-0.2,0.4-0.3c0.3-0.5,0.4-1.2,0.4-1.8c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.1-0.3-0.2-0.4-0.2
                      c-0.2,0-0.3,0.1-0.4,0.2C1062,632.8,1061.9,633,1061.9,633.1L1061.9,633.1z"/>
                    <path className="st42" d="M1060.1,631.1c0.2,0,0.5,0,0.7,0h-0.2c0.2,0,0.4,0.1,0.5,0.1l-0.2-0.1c0.1,0,0.2,0.1,0.3,0.1
                      c0.1,0.1,0.3,0.1,0.5,0.1s0.3-0.1,0.4-0.3c0.2-0.3,0.1-0.7-0.2-0.8l0,0c-0.5-0.3-1.2-0.4-1.8-0.4c-0.2,0-0.3,0.1-0.4,0.2
                      c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.5C1059.8,631.1,1060,631.1,1060.1,631.1L1060.1,631.1z"/>
                    <polygon className="st19" points="1170.9,707.4 1170.7,730.9 1171.5,746 1169.2,732 1168.1,707.8 	"/>
                    <path className="st24" d="M1170.2,741.7c0,0,0.3,7.4,5.5,10s24.6,22.4,26.2,24.6s4.5,7,4.5,7s6.5-2.9,6.8-7.1c-11-21.8-30-34.6-30-34.6
                      l4.2-36.8l-18.3,1.7C1169.1,706.5,1167.6,730,1170.2,741.7z"/>
                    <path className="st24" d="M1150.1,632.8c0,0,7.9-10.7,13.8-6.9s9,10.7,14.1,27.6c3.3,10.6,7.7,20.9,13.1,30.7c1,2.1,3.8,12.4,3.1,16.2
                      c-0.7,3.8-17.7,9.3-25.6,9s-10.2-1.3-10.2-1.3s4.1-22.8,2-32.8s-7.2-11.4-7.2-11.4s-12.5-5.4-3-14.9c-2.4-2-5-3.7-7.8-5.2
                      L1150.1,632.8z"/>
                    <path className="st29" d="M1144.7,643.7c0,0-8,13.8-9.6,15.1s-22.3,7.4-23.5,7.4c-1.3,0-7.1-2.3-10.7,2.3c1.6,2.3,2.6,0.7,3.5,1
                      s-5.8,1.9-5.2,2.9s2.9,5.8,6.8,5.2s7.4-5.8,7.4-5.8s25.8-6.6,28.1-7.6s10.5-16.3,10.5-16.3L1144.7,643.7z"/>
                    <polyline className="st43" points="1163.1,641 1154,650.8 1142.7,643.7 	"/>
                    <circle className="st20" cx="1089.4" cy="630.6" r="8.2"/>
                    <polygon className="st3" points="1106.1,666.2 1136.3,657.3 1140,651.7 1139.3,657.1 1104.6,667.4 	"/>
                    <path className="st3" d="M1041.1,716.4c0,0,1-32.3,16.5-61.2c5.2-9.6,15.6-6.6,15.6-6.6s8,1,7.5,28.5c-0.5,25.5-7.6,36.1-7.6,37.3
                      S1041.1,716.4,1041.1,716.4z"/>
                    <circle className="st3" cx="1018.2" cy="788.7" r="3.9"/>
                    <path className="st28" d="M1022.7,694.2c0,0-2.1,3.4,3,3.4h40.6c0,0,3-0.2,3-4.3s-4.7-3-4.7-3L1022.7,694.2z"/>
                    <path className="st3" d="M963.9,758.5c2.7-1.3,5.8-1.6,8.7-0.9c4.5,1.2,12.5,5.3,12.5,5.3l5.2,5.2l-6,7.8L963.9,758.5z"/>
                    <path className="st28" d="M1018.2,731.8h63v-56.6c0,0,1.4-6.2,5-6c3.5,0.2,2.5,7,2.5,7v64.5H1019L1018.2,731.8z"/>
                    <circle className="st3" cx="1167.8" cy="573.9" r="8.7"/>
                    <circle className="st3" cx="1088.4" cy="762.2" r="5.5"/>
                    <circle className="st3" cx="1138.8" cy="604.6" r="1.2"/>
                    <circle className="st3" cx="1052.4" cy="623.2" r="1.2"/>
                    <path className="st44" d="M1090.4,664.7c0.7-0.6,1.7-0.9,2.6-0.7C1092.1,664,1091.3,664.3,1090.4,664.7z"/>
                    <path className="st3" d="M1120.5,790.4c2-2.2,4.7-3.7,7.7-4.2c4.6-0.7,13.6,0,13.6,0l6.8,2.8l-2.5,9.5L1120.5,790.4z"/>
                    <path className="st3" d="M1202.1,805.4c-0.8-2.9-0.7-6,0.4-8.7c1.9-4.3,7.1-11.5,7.1-11.5l5.9-4.4l6.8,7.1L1202.1,805.4z"/>
                  </g>
                </svg>
              
              </div>

              <div id="townhouses-container">
                <svg 
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" y="0px"
                  viewBox="0 0 1400 850"
                  className={playStreet>0 ? 'play' : ''}
                >   
                  <path 
                      id="background-shape" 
                      className="st1" 
                      d="M678.1,44.1c0,0,106.7,1.2,228.4,43.4c125.9,43.7,241.4,46.2,307.3,68.6c76.3,25.9,242.4,132.6,132,275.9
                      c-58.2,75.5-114.3,107.8-196.8,124.6c-17.6,3.6-66.2,9.9-82.6,15.6c-55.1,19.3-94.8,37.2-128.5,58.5
                      c-93.2,58.9-326.2,40.5-450.5-73.6S222.6,76.7,678.1,44.1z"/>
                  <defs>
                    <path 
                      id="SVGID_1" 
                      d="M678.1,44.1c0,0,106.7,1.2,228.4,43.4c125.9,43.7,241.4,46.2,307.3,68.6c76.3,25.9,242.4,132.6,132,275.9
                      c-58.2,75.5-114.3,107.8-196.8,124.6c-17.6,3.6-66.2,9.9-82.6,15.6c-55.1,19.3-94.8,37.2-128.5,58.5
                      c-93.2,58.9-326.2,40.5-450.5-73.6S222.6,76.7,678.1,44.1z"/>
                  </defs>

                  <clipPath id="SVGID_2">
                    <use xlinkHref="#SVGID_1" style={{overflow: "visible"}} />
                  </clipPath>

                  <g id="townhouses">
                    <g id="townhouse-3" className="st2">
                      <polyline className="st3" points="1310.8,140.9 1330.8,108.9 1317.9,107.8 1322.8,91.3 1226.8,89.7 1141.8,39.1 1038.8,106.5 
                        1051,106.5 1053.3,116.5"/>
                      <path className="st4" d="M1063.3,162.5v414.8h249.8V156.5c0,0,6.4-6.8-3-6.8V143c0,0,8.8-4.1,10.1-7.4
                        s8.8-23,8.8-23l-18.9-0.7l6.1-14.9l-92.5-0.7l-82.4-48.6l-87.1,56l3.4,11.5l-15.5,0.7l23.6,24.3l-0.7,9.4l-6.1,3.4L1063.3,162.5
                        z"/>
                      <polyline className="st5" points="1311.9,456.9 1215.9,456.9 1203.6,461 1080.5,461 1064.6,457.2 				"/>
                      <path className="st6" d="M1291.2,577.2H1226c-2.4,0,5.7-140.1,5.7-140.1c0-2.4,1.9-4.3,4.3-4.3h55.2c2.4,0,4.3,1.9,4.3,4.3
                        C1295.6,437.1,1293.6,577.2,1291.2,577.2z"/>
                      <path className="st7" d="M1229.9,577.2h-13.2c-2.4,0-4.3-1.9-4.3-4.3l6.3-67.4c0-2.4-4.3-72.6-2-72.6h13.2c2.4,0,4.3,1.9,4.3,4.3
                        v135.7C1234.2,575.2,1232.3,577.1,1229.9,577.2z"/>
                      <polyline className="st5" points="1311.9,266.3 1215.9,266.3 1203.6,270.4 1080.5,270.4 1064.6,266.6 				"/>
                      <polyline className="st5" points="1311.9,279.4 1215.9,279.4 1203.6,283.5 1080.5,283.5 1064.6,279.7 				"/>
                      <polyline className="st5" points="1311.9,291.4 1215.9,291.4 1203.6,295.5 1080.5,295.5 1064.6,291.7 				"/>
                      <polyline className="st5" points="1311.9,303.4 1215.9,303.4 1203.6,307.5 1080.5,307.5 1064.6,303.6 				"/>
                      <polyline className="st5" points="1311.9,315.6 1215.9,315.6 1203.6,319.7 1080.5,319.7 1064.6,315.9 				"/>
                      <polyline className="st5" points="1311.9,328.5 1215.9,328.5 1203.6,332.6 1080.5,332.6 1064.6,328.8 				"/>
                      <polyline className="st5" points="1311.9,343 1215.9,343 1203.6,347.2 1080.5,347.2 1064.6,343.3 				"/>
                      <polyline className="st5" points="1311.9,356 1215.9,356 1203.6,360.1 1080.5,360.1 1064.6,356.3 				"/>
                      <polyline className="st5" points="1311.9,367.9 1215.9,367.9 1203.6,372 1080.5,372 1064.6,368.2 				"/>
                      <polyline className="st5" points="1311.9,380.1 1215.9,380.1 1203.6,384.3 1080.5,384.3 1064.6,380.4 				"/>
                      <polyline className="st5" points="1311.9,393.1 1215.9,393.1 1203.6,397.2 1080.5,397.2 1064.6,393.3 				"/>
                      <polyline className="st5" points="1311.9,407.6 1215.9,407.6 1203.6,411.7 1080.5,411.7 1064.6,407.9 				"/>
                      <polyline className="st5" points="1311.9,420.5 1215.9,420.5 1203.6,424.6 1080.5,424.6 1064.6,420.8 				"/>
                      <polyline className="st5" points="1311.9,432.4 1215.9,432.4 1203.6,436.6 1080.5,436.6 1064.6,432.7 				"/>
                      <polyline className="st5" points="1311.9,444.7 1215.9,444.7 1203.6,448.8 1080.5,448.8 1064.6,445 				"/>
                      <polyline className="st5" points="1311.9,185.9 1215.9,185.9 1203.6,190 1080.5,190 1064.6,186.2 				"/>
                      <polyline className="st5" points="1311.9,199.2 1215.9,199.2 1203.6,203.3 1080.5,203.3 1064.6,199.4 				"/>
                      <polyline className="st5" points="1311.9,212.1 1215.9,212.1 1203.6,216.2 1080.5,216.2 1064.6,212.4 				"/>
                      <polyline className="st5" points="1311.9,225 1215.9,225 1203.6,229.1 1080.5,229.1 1064.6,225.3 				"/>
                      <polyline className="st5" points="1311.9,158.2 1215.9,158.2 1203.6,162.3 1080.5,162.3 1058.9,155.6 				"/>
                      <line className="st5" x1="1169.9" y1="74.6" x2="1114.6" y2="74.6"/>
                      <line className="st5" x1="1159.5" y1="67.3" x2="1123.7" y2="67.3"/>
                      <line className="st5" x1="1292" y1="110.1" x2="1236.7" y2="110.1"/>
                      <line className="st5" x1="1281.6" y1="102.8" x2="1245.8" y2="102.8"/>
                      <line className="st5" x1="1236.7" y1="141" x2="1292" y2="141"/>
                      <line className="st5" x1="1247.1" y1="148.3" x2="1282.8" y2="148.3"/>
                      <line className="st5" x1="1103.3" y1="141" x2="1173.6" y2="141"/>
                      <line className="st5" x1="1116.5" y1="148.6" x2="1162" y2="148.6"/>
                      <polyline className="st5" points="1311.9,171.1 1215.9,171.1 1203.6,175.2 1080.5,175.2 1064.6,171.4 				"/>
                      <polyline className="st5" points="1311.9,238.3 1215.9,238.3 1203.6,242.4 1080.5,242.4 1064.6,238.6 				"/>
                      <polyline className="st5" points="1311.9,251 1215.9,251 1203.6,255.2 1080.5,255.2 1064.6,251.3 				"/>
                      <path className="st8" d="M1111.9,183.6h20.2c2.4,0,4.3,1.9,4.3,4.3v78c0,2.4-1.9,4.3-4.3,4.3h-20.2c-2.4,0-4.3-1.9-4.3-4.3v-78
                        C1107.6,185.6,1109.5,183.6,1111.9,183.6z"/>
                      <path className="st8" d="M1148,183.6h20.2c2.4,0,4.3,1.9,4.3,4.3v78c0,2.4-1.9,4.3-4.3,4.3H1148c-2.4,0-4.3-1.9-4.3-4.3v-78
                        C1143.7,185.6,1145.6,183.6,1148,183.6z"/>
                      <line className="st9" x1="1108.4" y1="223.7" x2="1135.6" y2="223.7"/>
                      <line className="st9" x1="1144.6" y1="223.7" x2="1172" y2="223.7"/>
                      <path className="st8" d="M1111.9,336h20.2c2.4,0,4.3,1.9,4.3,4.3v78c0,2.4-1.9,4.3-4.3,4.3h-20.2c-2.4,0-4.3-1.9-4.3-4.3v-78
                        C1107.6,338,1109.5,336,1111.9,336z"/>
                      <path className="st8" d="M1148,336h20.2c2.4,0,4.3,1.9,4.3,4.3v78c0,2.4-1.9,4.3-4.3,4.3H1148c-2.4,0-4.3-1.9-4.3-4.3v-78
                        C1143.7,338,1145.6,336,1148,336z"/>
                      <line className="st9" x1="1108.4" y1="376.1" x2="1135.6" y2="376.1"/>
                      <line className="st9" x1="1144.6" y1="376.1" x2="1172" y2="376.1"/>
                      <path className="st8" d="M1250.8,193.3h20.2c2.4,0,4.3,1.9,4.3,4.3v68.3c0,2.4-1.9,4.3-4.3,4.3h-20.2c-2.4,0-4.3-1.9-4.3-4.3v-68.3
                        C1246.5,195.2,1248.5,193.3,1250.8,193.3z"/>
                      <line className="st9" x1="1246.4" y1="228.9" x2="1275.3" y2="228.9"/>
                      <path className="st6" d="M1089.7,473.1h91.6c2.4,0,4.3,1.9,4.3,4.3v95c0,2.4-1.9,4.3-4.3,4.3h-91.6c-2.4,0-4.3-1.9-4.3-4.3v-95
                        C1085.3,475.1,1087.3,473.1,1089.7,473.1z"/>
                      <line className="st9" x1="1092.4" y1="484.2" x2="1177.5" y2="484.2"/>
                      <line className="st9" x1="1238.2" y1="454.1" x2="1290.7" y2="454.1"/>
                      <line className="st9" x1="1238.2" y1="465.1" x2="1290.7" y2="465.1"/>
                      <line className="st9" x1="1238.2" y1="475.7" x2="1290.7" y2="475.7"/>
                      <line className="st9" x1="1238.2" y1="486.7" x2="1290.7" y2="486.7"/>
                      <line className="st9" x1="1232.3" y1="497.7" x2="1290.7" y2="497.7"/>
                      <line className="st9" x1="1232.3" y1="508.7" x2="1290.7" y2="508.7"/>
                      <line className="st9" x1="1232.3" y1="521.5" x2="1290.7" y2="521.5"/>
                      <line className="st9" x1="1232.3" y1="532.6" x2="1290.7" y2="532.6"/>
                      <line className="st9" x1="1232.3" y1="545.1" x2="1290.7" y2="545.1"/>
                      <line className="st9" x1="1232.3" y1="557.2" x2="1290.7" y2="557.2"/>
                      <line className="st9" x1="1232.3" y1="571.4" x2="1290.7" y2="571.4"/>
                      <line className="st9" x1="1092.4" y1="496.7" x2="1177.5" y2="496.7"/>
                      <line className="st9" x1="1092.4" y1="509.2" x2="1177.5" y2="509.2"/>
                      <line className="st9" x1="1092.4" y1="521.5" x2="1177.5" y2="521.5"/>
                      <line className="st9" x1="1092.4" y1="534" x2="1177.5" y2="534"/>
                      <line className="st9" x1="1092.4" y1="546.4" x2="1177.5" y2="546.4"/>
                      <line className="st9" x1="1092.4" y1="557.1" x2="1177.5" y2="557.1"/>
                      <line className="st9" x1="1092.4" y1="569.5" x2="1177.5" y2="569.5"/>
                      <path className="st10" d="M1232.9,116.2h61.5c2.4,0,4.3,1.9,4.3,4.3v8.9c0,2.4-1.9,4.3-4.3,4.3h-61.5c-2.4,0-4.3-1.9-4.3-4.3v-8.9
                        C1228.6,118.1,1230.5,116.2,1232.9,116.2z"/>
                      <path className="st10" d="M1103.4,116.2h75.5c2.4,0,4.3,1.9,4.3,4.3v8.3c0,2.4-1.9,4.3-4.3,4.3h-75.5c-2.4,0-4.3-1.9-4.3-4.3v-8.3
                        C1099.1,118.1,1101,116.2,1103.4,116.2z"/>
                      <path className="st10" d="M1106.2,86.1L1106.2,86.1c2.4,0,4.3,1.9,4.3,4.3V98c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3
                        v-7.6C1101.9,88,1103.8,86.1,1106.2,86.1z"/>
                      <path className="st10" d="M1120.7,86.1L1120.7,86.1c2.4,0,4.3,1.9,4.3,4.3V98c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3
                        v-7.6C1116.4,88,1118.3,86.1,1120.7,86.1z"/>
                      <path className="st10" d="M1135.3,86.1L1135.3,86.1c2.4,0,4.3,1.9,4.3,4.3V98c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3
                        v-7.6C1130.9,88,1132.9,86.1,1135.3,86.1z"/>
                      <path className="st10" d="M1149.7,86.1L1149.7,86.1c2.4,0,4.3,1.9,4.3,4.3V98c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3
                        v-7.6C1145.4,88,1147.3,86.1,1149.7,86.1z"/>
                      <path className="st10" d="M1163.7,86.1L1163.7,86.1c2.4,0,4.3,1.9,4.3,4.3V98c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3
                        v-7.6C1159.4,88,1161.3,86.1,1163.7,86.1z"/>
                      <path className="st10" d="M1178.1,86.1L1178.1,86.1c2.4,0,4.3,1.9,4.3,4.3V98c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3
                        v-7.6C1173.8,88,1175.7,86.1,1178.1,86.1z"/>
                      <path className="st7" d="M1288.6,577.2h13.2c2.4,0,4.3-1.9,4.3-4.3l-6.3-67.4c0-2.4,4.3-72.6,2-72.6h-13.1c-2.4,0-4.3,1.9-4.3,4.3
                        v135.7C1284.4,575.2,1286.3,577.1,1288.6,577.2z"/>
                      <path className="st3" d="M1210.5,309.2l8,7.3l2.4,129.1h82.6V317.3l8.1-8.5l-45.7-34.2c0,0,3.9-10.2-4.5-10.2
                        c-6.2,0-7.6,5.2-5.2,10.8C1229.5,293.9,1210.5,309.2,1210.5,309.2z"/>
                      <path className="st6" d="M1242.8,326.8h11.8c2.4,0,4.3,1.9,4.3,4.3V429c0,2.4-1.9,4.3-4.3,4.3h-11.8c-2.4,0-4.3-1.9-4.3-4.3v-97.9
                        C1238.5,328.8,1240.4,326.8,1242.8,326.8z"/>
                      <path className="st6" d="M1268.9,326.8h11.8c2.4,0,4.3,1.9,4.3,4.3V429c0,2.4-1.9,4.3-4.3,4.3h-11.8c-2.4,0-4.3-1.9-4.3-4.3v-97.9
                        C1264.6,328.8,1266.5,326.8,1268.9,326.8z"/>
                      <path className="st11" d="M1242.6,309.7h38.4c2.2,0,3.9,1.8,3.9,3.9v1.5c0,2.2-1.8,3.9-3.9,3.9h-38.4c-2.2,0-3.9-1.8-3.9-3.9v-1.5
                        C1238.7,311.5,1240.4,309.7,1242.6,309.7z"/>
                      <path className="st11" d="M1242.2,293.2h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                        C1240.2,294.1,1241.1,293.2,1242.2,293.2z"/>
                      <path className="st11" d="M1250.2,293.2h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                        C1248.2,294.1,1249.1,293.2,1250.2,293.2z"/>
                      <path className="st11" d="M1258.2,293.2h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                        C1256.2,294.1,1257.1,293.2,1258.2,293.2z"/>
                      <path className="st11" d="M1266.2,293.2h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                        C1264.2,294.1,1265,293.2,1266.2,293.2z"/>
                      <path className="st11" d="M1273.8,293.2h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                        C1271.8,294.1,1272.7,293.2,1273.8,293.2z"/>
                      <path className="st11" d="M1281.8,293.2h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                        C1279.8,294.1,1280.7,293.2,1281.8,293.2z"/>
                      <circle className="st6" cx="1233.6" cy="380.8" r="3.9"/>
                      <polygon className="st12" points="1220.9,417.4 1213.3,419.2 1208.5,447.8 1214.8,447.6 1236.5,447 1233.7,417.4 				"/>
                      <polygon className="st12" points="1298.6,417.4 1306.2,419.2 1310.9,447.8 1304.6,447.6 1283,447 1285.8,417.4 				"/>
                    </g>       
                    <g id="townhouse-2" className="st13">
                      <polyline className="st3" points="994.4,141.3 1014.4,109.4 1001.5,108.2 1006.4,91.7 
                        910.4,90.1 825.4,39.6 722.4,107 734.6,107 736.9,116.9"/>
                      <path className="st14" d="M746.9,162.9v414.8h249.8V156.9c0,0,6.4-6.8-3-6.8v-6.8c0,0,8.8-4.1,10.1-7.4s8.8-23,8.8-23l-18.9-0.7
                        l6.1-14.9l-92.5-0.7L824.9,48l-87.1,56l3.4,11.5l-15.5,0.7l23.6,24.3l-0.7,9.4l-6.1,3.4L746.9,162.9z"/>
                      <polyline className="st15" points="995.6,457.3 899.5,457.3 887.2,461.4 764.1,461.4 748.3,457.6 				"/>
                      <path className="st6" d="M974.9,577.6h-65.2c-2.4,0,5.7-140.1,5.7-140.1c0-2.4,1.9-4.3,4.3-4.3h55.2c2.4,0,4.3,1.9,4.3,4.3
                        C979.2,437.5,977.2,577.6,974.9,577.6z"/>
                      <path className="st7" d="M913.5,577.6h-13.2c-2.4,0-4.3-1.9-4.3-4.3l6.3-67.4c0-2.4-4.3-72.6-2-72.6h13.2c2.4,0,4.3,1.9,4.3,4.3
                        v135.7C917.8,575.6,915.9,577.6,913.5,577.6z"/>
                      <polyline className="st15" points="995.6,266.7 899.5,266.7 887.2,270.8 764.1,270.8 748.3,267 				"/>
                      <polyline className="st15" points="995.6,279.8 899.5,279.8 887.2,283.9 764.1,283.9 748.3,280.1 				"/>
                      <polyline className="st15" points="995.6,291.8 899.5,291.8 887.2,296 764.1,296 748.3,292.1 				"/>
                      <polyline className="st15" points="995.6,303.8 899.5,303.8 887.2,307.9 764.1,307.9 748.3,304.1 				"/>
                      <polyline className="st15" points="995.6,316 899.5,316 887.2,320.1 764.1,320.1 748.3,316.3 				"/>
                      <polyline className="st15" points="995.6,328.9 899.5,328.9 887.2,333.1 764.1,333.1 748.3,329.2 				"/>
                      <polyline className="st15" points="995.6,343.5 899.5,343.5 887.2,347.6 764.1,347.6 748.3,343.8 				"/>
                      <polyline className="st15" points="995.6,356.4 899.5,356.4 887.2,360.5 764.1,360.5 748.3,356.7 				"/>
                      <polyline className="st15" points="995.6,368.3 899.5,368.3 887.2,372.4 764.1,372.4 748.3,368.6 				"/>
                      <polyline className="st15" points="995.6,380.6 899.5,380.6 887.2,384.7 764.1,384.7 748.3,380.8 				"/>
                      <polyline className="st15" points="995.6,393.5 899.5,393.5 887.2,397.6 764.1,397.6 748.3,393.8 				"/>
                      <polyline className="st15" points="995.6,408 899.5,408 887.2,412.1 764.1,412.1 748.3,408.3 				"/>
                      <polyline className="st15" points="995.6,420.9 899.5,420.9 887.2,425 764.1,425 748.3,421.2 				"/>
                      <polyline className="st15" points="995.6,432.9 899.5,432.9 887.2,437 764.1,437 748.3,433.1 				"/>
                      <polyline className="st15" points="995.6,445.1 899.5,445.1 887.2,449.2 764.1,449.2 748.3,445.4 				"/>
                      <polyline className="st15" points="995.6,186.3 899.5,186.3 887.2,190.5 764.1,190.5 748.3,186.6 				"/>
                      <polyline className="st15" points="995.6,199.6 899.5,199.6 887.2,203.7 764.1,203.7 748.3,199.9 				"/>
                      <polyline className="st15" points="995.6,212.5 899.5,212.5 887.2,216.6 764.1,216.6 748.3,212.8 				"/>
                      <polyline className="st15" points="995.6,225.4 899.5,225.4 887.2,229.5 764.1,229.5 748.3,225.7 				"/>
                      <polyline className="st15" points="995.6,158.6 899.5,158.6 887.2,162.7 764.1,162.7 742.5,156 				"/>
                      <line className="st15" x1="853.5" y1="75" x2="798.2" y2="75"/>
                      <line className="st15" x1="843.1" y1="67.7" x2="807.4" y2="67.7"/>
                      <line className="st15" x1="975.6" y1="110.5" x2="920.3" y2="110.5"/>
                      <line className="st15" x1="965.2" y1="103.2" x2="929.5" y2="103.2"/>
                      <line className="st15" x1="920.3" y1="141.4" x2="975.6" y2="141.4"/>
                      <line className="st15" x1="786.9" y1="141.4" x2="857.2" y2="141.4"/>
                      <line className="st15" x1="800.1" y1="149.1" x2="845.6" y2="149.1"/>
                      <polyline className="st15" points="995.6,171.5 899.5,171.5 887.2,175.6 764.1,175.6 748.3,171.8 				"/>
                      <polyline className="st15" points="995.6,238.7 899.5,238.7 887.2,242.9 764.1,242.9 748.3,239 				"/>
                      <polyline className="st15" points="995.6,251.5 899.5,251.5 887.2,255.6 764.1,255.6 748.3,251.8 				"/>
                      <path className="st8" d="M795.5,184.1h20.2c2.4,0,4.3,1.9,4.3,4.3v78c0,2.4-1.9,4.3-4.3,4.3h-20.2c-2.4,0-4.3-1.9-4.3-4.3v-78
                        C791.2,186,793.1,184.1,795.5,184.1z"/>
                      <path className="st8" d="M831.6,184.1h20.2c2.4,0,4.3,1.9,4.3,4.3v78c0,2.4-1.9,4.3-4.3,4.3h-20.2c-2.4,0-4.3-1.9-4.3-4.3v-78
                        C827.3,186,829.2,184.1,831.6,184.1z"/>
                      <line className="st9" x1="792" y1="224.2" x2="819.2" y2="224.2"/>
                      <line className="st9" x1="828.2" y1="224.2" x2="855.6" y2="224.2"/>
                      <path className="st8" d="M795.5,336.5h20.2c2.4,0,4.3,1.9,4.3,4.3v78c0,2.4-1.9,4.3-4.3,4.3h-20.2c-2.4,0-4.3-1.9-4.3-4.3v-78
                        C791.2,338.4,793.1,336.5,795.5,336.5z"/>
                      <path className="st8" d="M831.6,336.5h20.2c2.4,0,4.3,1.9,4.3,4.3v78c0,2.4-1.9,4.3-4.3,4.3h-20.2c-2.4,0-4.3-1.9-4.3-4.3v-78
                        C827.3,338.4,829.2,336.5,831.6,336.5z"/>
                      <line className="st9" x1="792" y1="376.6" x2="819.2" y2="376.6"/>
                      <line className="st9" x1="828.2" y1="376.6" x2="855.6" y2="376.6"/>
                      <path className="st8" d="M934.5,193.7h20.2c2.4,0,4.3,1.9,4.3,4.3v68.3c0,2.4-1.9,4.3-4.3,4.3h-20.2c-2.4,0-4.3-1.9-4.3-4.3V198
                        C930.1,195.6,932.1,193.7,934.5,193.7z"/>
                      <line className="st9" x1="930" y1="229.3" x2="958.9" y2="229.3"/>
                      <path className="st16" d="M773.3,473.6h91.6c2.4,0,4.3,1.9,4.3,4.3v95c0,2.4-1.9,4.3-4.3,4.3h-91.6c-2.4,0-4.3-1.9-4.3-4.3v-95
                        C769,475.5,770.9,473.6,773.3,473.6z"/>
                      <path className="st9" d="M921.6,338.8h50.5c2.4,0,4.3,1.9,4.3,4.3v96.4c0,2.4-1.9,4.3-4.3,4.3h-50.5c-2.4,0-4.3-1.9-4.3-4.3v-96.4
                        C917.3,340.8,919.3,338.8,921.6,338.8z"/>
                      <line className="st9" x1="776" y1="484.7" x2="861.1" y2="484.7"/>
                      <line className="st9" x1="921.9" y1="454.5" x2="974.3" y2="454.5"/>
                      <line className="st9" x1="921.9" y1="465.6" x2="974.3" y2="465.6"/>
                      <line className="st9" x1="921.9" y1="476.1" x2="974.3" y2="476.1"/>
                      <line className="st9" x1="921.9" y1="487.2" x2="974.3" y2="487.2"/>
                      <line className="st9" x1="915.9" y1="498.1" x2="974.3" y2="498.1"/>
                      <line className="st9" x1="915.9" y1="509.2" x2="974.3" y2="509.2"/>
                      <line className="st9" x1="915.9" y1="521.9" x2="974.3" y2="521.9"/>
                      <line className="st9" x1="915.9" y1="533" x2="974.3" y2="533"/>
                      <line className="st9" x1="915.9" y1="545.5" x2="974.3" y2="545.5"/>
                      <line className="st9" x1="915.9" y1="557.6" x2="974.3" y2="557.6"/>
                      <line className="st9" x1="915.9" y1="571.8" x2="974.3" y2="571.8"/>
                      <line className="st9" x1="776" y1="497.1" x2="861.1" y2="497.1"/>
                      <line className="st9" x1="776" y1="509.6" x2="861.1" y2="509.6"/>
                      <line className="st9" x1="776" y1="521.9" x2="861.1" y2="521.9"/>
                      <line className="st9" x1="776" y1="534.4" x2="861.1" y2="534.4"/>
                      <line className="st9" x1="776" y1="546.8" x2="861.1" y2="546.8"/>
                      <line className="st9" x1="776" y1="557.5" x2="861.1" y2="557.5"/>
                      <line className="st9" x1="776" y1="570" x2="861.1" y2="570"/>
                      <path className="st10" d="M916.5,116.6h61.4c2.4,0,4.3,1.9,4.3,4.3v8.9c0,2.4-1.9,4.3-4.3,4.3h-61.4c-2.4,0-4.3-1.9-4.3-4.3v-8.9
                        C912.2,118.5,914.1,116.6,916.5,116.6z"/>
                      <path className="st10" d="M787,116.6h75.5c2.4,0,4.3,1.9,4.3,4.3v8.3c0,2.4-1.9,4.3-4.3,4.3H787c-2.4,0-4.3-1.9-4.3-4.3v-8.3
                        C782.7,118.5,784.6,116.6,787,116.6z"/>
                      <path className="st10" d="M789.9,86.5L789.9,86.5c2.4,0,4.3,1.9,4.3,4.3v7.6c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3v-7.6
                        C785.5,88.4,787.5,86.5,789.9,86.5z"/>
                      <path className="st10" d="M804.3,86.5L804.3,86.5c2.4,0,4.3,1.9,4.3,4.3v7.6c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3v-7.6
                        C800,88.4,801.9,86.5,804.3,86.5z"/>
                      <path className="st10" d="M818.9,86.5L818.9,86.5c2.4,0,4.3,1.9,4.3,4.3v7.6c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3v-7.6
                        C814.6,88.4,816.5,86.5,818.9,86.5z"/>
                      <path className="st10" d="M833.3,86.5L833.3,86.5c2.4,0,4.3,1.9,4.3,4.3v7.6c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3v-7.6
                        C829,88.4,830.9,86.5,833.3,86.5z"/>
                      <path className="st10" d="M847.3,86.5L847.3,86.5c2.4,0,4.3,1.9,4.3,4.3v7.6c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3v-7.6
                        C843,88.4,844.9,86.5,847.3,86.5z"/>
                      <path className="st10" d="M861.7,86.5L861.7,86.5c2.4,0,4.3,1.9,4.3,4.3v7.6c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3v-7.6
                        C857.4,88.4,859.4,86.5,861.7,86.5z"/>
                      <path className="st17" d="M894,309.6l8,7.3l2.4,129.1H987V317.7l8.1-8.5L949.4,275c0,0,3.9-10.2-4.5-10.2c-6.2,0-7.6,5.2-5.2,10.8
                        C913.1,294.4,894,309.6,894,309.6z"/>
                      <path className="st6" d="M926.4,327.3h11.8c2.4,0,4.3,1.9,4.3,4.3v97.9c0,2.4-1.9,4.3-4.3,4.3h-11.8c-2.4,0-4.3-1.9-4.3-4.3v-97.9
                        C922.1,329.2,924.1,327.3,926.4,327.3z"/>
                      <path className="st6" d="M952.5,327.3h11.8c2.4,0,4.3,1.9,4.3,4.3v97.9c0,2.4-1.9,4.3-4.3,4.3h-11.8c-2.4,0-4.3-1.9-4.3-4.3v-97.9
                        C948.2,329.2,950.2,327.3,952.5,327.3z"/>
                      <path className="st11" d="M926.2,310.1h38.4c2.2,0,3.9,1.8,3.9,3.9v1.5c0,2.2-1.8,3.9-3.9,3.9h-38.4c-2.2,0-3.9-1.8-3.9-3.9V314
                        C922.3,311.9,924.1,310.1,926.2,310.1z"/>
                      <path className="st11" d="M925.8,293.6h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                        C923.8,294.5,924.7,293.6,925.8,293.6z"/>
                      <path className="st11" d="M933.8,293.6h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                        C931.8,294.5,932.7,293.6,933.8,293.6z"/>
                      <path className="st11" d="M941.8,293.6h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                        C939.8,294.5,940.7,293.6,941.8,293.6z"/>
                      <path className="st11" d="M949.8,293.6h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                        C947.8,294.5,948.7,293.6,949.8,293.6z"/>
                      <path className="st11" d="M957.5,293.6h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                        C955.5,294.5,956.4,293.6,957.5,293.6z"/>
                      <path className="st11" d="M965.4,293.6h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                        C963.4,294.5,964.3,293.6,965.4,293.6z"/>
                      <circle className="st6" cx="917.2" cy="381.2" r="3.9"/>
                      <polygon className="st12" points="904.5,417.8 896.9,419.6 892.2,448.2 898.4,448 920.1,447.5 917.3,417.8 				"/>
                      <path className="st7" d="M972.2,577.6h13.2c2.4,0,4.3-1.9,4.3-4.3l-6.3-67.4c0-2.4,4.3-72.6,2-72.6h-13.2c-2.4,0-4.3,1.9-4.3,4.3
                        v135.7C967.9,575.7,969.9,577.6,972.2,577.6z"/>
                      <polygon className="st12" points="982.2,417.8 989.8,419.6 994.6,448.2 988.3,448 966.6,447.5 969.4,417.8 				"/>
                      <line className="st15" x1="930.7" y1="148.7" x2="966.5" y2="148.7"/>
                    </g>
                    <g id="townhouse-1" className="st18">
                      <polyline className="st3" points="686.7,141.3 706.7,109.4 693.9,108.2 698.7,91.7 602.7,90.1 517.7,39.6 414.7,107 426.9,107 
                        429.2,116.9"/>
                      <path className="st19" d="M439.2,162.9v414.8H689V156.9c0,0,6.4-6.8-3-6.8v-6.8c0,0,8.8-4.1,10.1-7.4c1.4-3.4,8.8-23,8.8-23
                        l-18.9-0.7l6-14.8l-92.5-0.7l-82.4-48.6l-87.1,56l3.4,11.5l-15.5,0.7l23.6,24.3l-0.7,9.4l-6.1,3.4L439.2,162.9z"/>
                      <polyline className="st5" points="687.9,457.3 591.9,457.3 579.5,461.4 456.4,461.4 440.6,457.6 				"/>
                      <path className="st6" d="M667.2,577.6h-65.1c-2.4,0,5.7-140.1,5.7-140.1c0-2.4,1.9-4.3,4.3-4.3h55.2c2.4,0,4.3,1.9,4.3,4.3
                        C671.5,437.5,669.6,577.6,667.2,577.6z"/>
                      <path className="st7" d="M605.8,577.6h-13.2c-2.4,0-4.3-1.9-4.3-4.3l6.3-67.4c0-2.4-4.3-72.6-2-72.6h13.2c2.4,0,4.3,1.9,4.3,4.3
                        v135.7C610.1,575.6,608.2,577.6,605.8,577.6z"/>
                      <polyline className="st5" points="687.9,266.7 591.9,266.7 579.5,270.8 456.4,270.8 440.6,267 				"/>
                      <polyline className="st5" points="687.9,279.8 591.9,279.8 579.5,283.9 456.4,283.9 440.6,280.1 				"/>
                      <polyline className="st5" points="687.9,291.8 591.9,291.8 579.5,296 456.4,296 440.6,292.1 				"/>
                      <polyline className="st5" points="687.9,303.8 591.9,303.8 579.5,307.9 456.4,307.9 440.6,304.1 				"/>
                      <polyline className="st5" points="687.9,316 591.9,316 579.5,320.1 456.4,320.1 440.6,316.3 				"/>
                      <polyline className="st5" points="687.9,328.9 591.9,328.9 579.5,333.1 456.4,333.1 440.6,329.2 				"/>
                      <polyline className="st5" points="687.9,343.5 591.9,343.5 579.5,347.6 456.4,347.6 440.6,343.8 				"/>
                      <polyline className="st5" points="687.9,356.4 591.9,356.4 579.5,360.5 456.4,360.5 440.6,356.7 				"/>
                      <polyline className="st5" points="687.9,368.3 591.9,368.3 579.5,372.4 456.4,372.4 440.6,368.6 				"/>
                      <polyline className="st5" points="687.9,380.6 591.9,380.6 579.5,384.7 456.4,384.7 440.6,380.8 				"/>
                      <polyline className="st5" points="687.9,393.5 591.9,393.5 579.5,397.6 456.4,397.6 440.6,393.8 				"/>
                      <polyline className="st5" points="687.9,408 591.9,408 579.5,412.1 456.4,412.1 440.6,408.3 				"/>
                      <polyline className="st5" points="687.9,420.9 591.9,420.9 579.5,425 456.4,425 440.6,421.2 				"/>
                      <polyline className="st5" points="687.9,432.9 591.9,432.9 579.5,437 456.4,437 440.6,433.1 				"/>
                      <polyline className="st5" points="687.9,445.1 591.9,445.1 579.5,449.2 456.4,449.2 440.6,445.4 				"/>
                      <polyline className="st5" points="687.9,186.3 591.9,186.3 579.5,190.5 456.4,190.5 440.6,186.6 				"/>
                      <polyline className="st5" points="687.9,199.6 591.9,199.6 579.5,203.7 456.4,203.7 440.6,199.9 				"/>
                      <polyline className="st5" points="687.9,212.5 591.9,212.5 579.5,216.6 456.4,216.6 440.6,212.8 				"/>
                      <polyline className="st5" points="687.9,225.4 591.9,225.4 579.5,229.5 456.4,229.5 440.6,225.7 				"/>
                      <polyline className="st5" points="687.9,158.6 591.9,158.6 579.5,162.7 456.4,162.7 434.9,156 				"/>
                      <line className="st5" x1="545.8" y1="75" x2="490.6" y2="75"/>
                      <line className="st5" x1="535.4" y1="67.7" x2="499.7" y2="67.7"/>
                      <line className="st5" x1="667.9" y1="110.5" x2="612.7" y2="110.5"/>
                      <line className="st5" x1="657.5" y1="103.2" x2="621.8" y2="103.2"/>
                      <line className="st5" x1="612.7" y1="141.4" x2="667.9" y2="141.4"/>
                      <line className="st5" x1="623" y1="148.7" x2="658.8" y2="148.7"/>
                      <line className="st5" x1="479.2" y1="141.4" x2="549.6" y2="141.4"/>
                      <line className="st5" x1="492.4" y1="149.1" x2="538" y2="149.1"/>
                      <polyline className="st5" points="687.9,171.5 591.9,171.5 579.5,175.6 456.4,175.6 440.6,171.8 				"/>
                      <polyline className="st5" points="687.9,238.7 591.9,238.7 579.5,242.9 456.4,242.9 440.6,239 				"/>
                      <polyline className="st5" points="687.9,251.5 591.9,251.5 579.5,255.6 456.4,255.6 440.6,251.8 				"/>
                      <path className="st8" d="M487.8,184.1H508c2.4,0,4.3,1.9,4.3,4.3v78c0,2.4-1.9,4.3-4.3,4.3h-20.2c-2.4,0-4.3-1.9-4.3-4.3v-78
                        C483.5,186,485.5,184.1,487.8,184.1z"/>
                      <path className="st8" d="M523.9,184.1h20.2c2.4,0,4.3,1.9,4.3,4.3v78c0,2.4-1.9,4.3-4.3,4.3h-20.2c-2.4,0-4.3-1.9-4.3-4.3v-78
                        C519.6,186,521.6,184.1,523.9,184.1z"/>
                      <line className="st9" x1="484.3" y1="224.2" x2="511.5" y2="224.2"/>
                      <line className="st9" x1="520.5" y1="224.2" x2="548" y2="224.2"/>
                      <path className="st8" d="M487.8,336.5H508c2.4,0,4.3,1.9,4.3,4.3v78c0,2.4-1.9,4.3-4.3,4.3h-20.2c-2.4,0-4.3-1.9-4.3-4.3v-78
                        C483.5,338.4,485.5,336.5,487.8,336.5z"/>
                      <path className="st8" d="M523.9,336.5h20.2c2.4,0,4.3,1.9,4.3,4.3v78c0,2.4-1.9,4.3-4.3,4.3h-20.2c-2.4,0-4.3-1.9-4.3-4.3v-78
                        C519.6,338.4,521.6,336.5,523.9,336.5z"/>
                      <line className="st9" x1="484.3" y1="376.6" x2="511.5" y2="376.6"/>
                      <line className="st9" x1="520.5" y1="376.6" x2="548" y2="376.6"/>
                      <path className="st8" d="M626.8,193.7H647c2.4,0,4.3,1.9,4.3,4.3v68.3c0,2.4-1.9,4.3-4.3,4.3h-20.2c-2.4,0-4.3-1.9-4.3-4.3V198
                        C622.5,195.6,624.4,193.7,626.8,193.7z"/>
                      <line className="st9" x1="622.3" y1="229.3" x2="651.2" y2="229.3"/>
                      <path className="st20" d="M465.6,473.6h91.6c2.4,0,4.3,1.9,4.3,4.3v95c0,2.4-1.9,4.3-4.3,4.3h-91.6c-2.4,0-4.3-1.9-4.3-4.3v-95
                        C461.3,475.5,463.2,473.6,465.6,473.6z"/>
                      <path className="st9" d="M614,338.8h50.5c2.4,0,4.3,1.9,4.3,4.3v96.4c0,2.4-1.9,4.3-4.3,4.3H614c-2.4,0-4.3-1.9-4.3-4.3v-96.4
                        C609.6,340.8,611.6,338.8,614,338.8z"/>
                      <line className="st9" x1="468.3" y1="484.7" x2="553.4" y2="484.7"/>
                      <line className="st9" x1="614.2" y1="454.5" x2="666.6" y2="454.5"/>
                      <line className="st9" x1="614.2" y1="465.6" x2="666.6" y2="465.6"/>
                      <line className="st9" x1="614.2" y1="476.1" x2="666.6" y2="476.1"/>
                      <line className="st9" x1="614.2" y1="487.2" x2="666.6" y2="487.2"/>
                      <line className="st9" x1="608.3" y1="498.1" x2="666.6" y2="498.1"/>
                      <line className="st9" x1="608.3" y1="509.2" x2="666.6" y2="509.2"/>
                      <line className="st9" x1="608.3" y1="521.9" x2="666.6" y2="521.9"/>
                      <line className="st9" x1="608.3" y1="533" x2="666.6" y2="533"/>
                      <line className="st9" x1="608.3" y1="545.5" x2="666.6" y2="545.5"/>
                      <line className="st9" x1="608.3" y1="557.6" x2="666.6" y2="557.6"/>
                      <line className="st9" x1="608.3" y1="571.8" x2="666.6" y2="571.8"/>
                      <line className="st9" x1="468.3" y1="497.1" x2="553.4" y2="497.1"/>
                      <line className="st9" x1="468.3" y1="509.6" x2="553.4" y2="509.6"/>
                      <line className="st9" x1="468.3" y1="521.9" x2="553.4" y2="521.9"/>
                      <line className="st9" x1="468.3" y1="534.4" x2="553.4" y2="534.4"/>
                      <line className="st9" x1="468.3" y1="546.8" x2="553.4" y2="546.8"/>
                      <line className="st9" x1="468.3" y1="557.5" x2="553.4" y2="557.5"/>
                      <line className="st9" x1="468.3" y1="570" x2="553.4" y2="570"/>
                      <path className="st10" d="M608.8,116.6h61.5c2.4,0,4.3,1.9,4.3,4.3v8.9c0,2.4-1.9,4.3-4.3,4.3h-61.5c-2.4,0-4.3-1.9-4.3-4.3v-8.9
                        C604.5,118.5,606.4,116.6,608.8,116.6z"/>
                      <path className="st10" d="M479.3,116.6h75.5c2.4,0,4.3,1.9,4.3,4.3v8.3c0,2.4-1.9,4.3-4.3,4.3h-75.5c-2.4,0-4.3-1.9-4.3-4.3v-8.3
                        C475,118.5,477,116.6,479.3,116.6z"/>
                      <path className="st10" d="M482.2,86.5L482.2,86.5c2.4,0,4.3,1.9,4.3,4.3v7.6c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3v-7.6
                        C477.9,88.4,479.8,86.5,482.2,86.5z"/>
                      <path className="st10" d="M496.6,86.5L496.6,86.5c2.4,0,4.3,1.9,4.3,4.3v7.6c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3v-7.6
                        C492.3,88.4,494.2,86.5,496.6,86.5z"/>
                      <path className="st10" d="M511.2,86.5L511.2,86.5c2.4,0,4.3,1.9,4.3,4.3v7.6c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3v-7.6
                        C506.9,88.4,508.8,86.5,511.2,86.5z"/>
                      <path className="st10" d="M525.6,86.5L525.6,86.5c2.4,0,4.3,1.9,4.3,4.3v7.6c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3v-7.6
                        C521.3,88.4,523.3,86.5,525.6,86.5z"/>
                      <path className="st10" d="M539.6,86.5L539.6,86.5c2.4,0,4.3,1.9,4.3,4.3v7.6c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3v-7.6
                        C535.3,88.4,537.2,86.5,539.6,86.5z"/>
                      <path className="st10" d="M554.1,86.5L554.1,86.5c2.4,0,4.3,1.9,4.3,4.3v7.6c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3v-7.6
                        C549.7,88.4,551.7,86.5,554.1,86.5z"/>
                      <path className="st10" d="M586.4,309.6l8,7.3l2.4,129.1h82.6V317.7l8.1-8.5L641.8,275c0,0,4-10.2-4.5-10.2c-6.2,0-7.6,5.2-5.2,10.8
                        C605.4,294.4,586.4,309.6,586.4,309.6z"/>
                      <path className="st6" d="M618.8,327.3h11.8c2.4,0,4.3,1.9,4.3,4.3v97.9c0,2.4-1.9,4.3-4.3,4.3h-11.8c-2.4,0-4.3-1.9-4.3-4.3v-97.9
                        C614.5,329.2,616.4,327.3,618.8,327.3z"/>
                      <path className="st6" d="M644.9,327.3h11.8c2.4,0,4.3,1.9,4.3,4.3v97.9c0,2.4-1.9,4.3-4.3,4.3h-11.8c-2.4,0-4.3-1.9-4.3-4.3v-97.9
                        C640.5,329.2,642.5,327.3,644.9,327.3z"/>
                      <path className="st11" d="M618.5,310.1h38.4c2.2,0,3.9,1.8,3.9,3.9v1.5c0,2.2-1.8,3.9-3.9,3.9h-38.4c-2.2,0-3.9-1.8-3.9-3.9V314
                        C614.6,311.9,616.4,310.1,618.5,310.1z"/>
                      <path className="st11" d="M618.2,293.6h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                        C616.2,294.5,617.1,293.6,618.2,293.6z"/>
                      <path className="st11" d="M626.1,293.6h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                        C624.1,294.5,625,293.6,626.1,293.6z"/>
                      <path className="st11" d="M634.1,293.6h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                        C632.1,294.5,633,293.6,634.1,293.6z"/>
                      <path className="st11" d="M642.1,293.6h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                        C640.1,294.5,641,293.6,642.1,293.6z"/>
                      <path className="st11" d="M649.8,293.6h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                        C647.8,294.5,648.7,293.6,649.8,293.6z"/>
                      <path className="st11" d="M657.7,293.6h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                        C655.7,294.5,656.6,293.6,657.7,293.6z"/>
                      <circle className="st6" cx="609.5" cy="381.2" r="3.9"/>
                      <polygon className="st12" points="596.8,417.8 589.2,419.6 584.5,448.2 590.8,448 612.4,447.5 609.6,417.8 				"/>
                      <path className="st7" d="M664.6,577.6h13.2c2.4,0,4.3-1.9,4.3-4.3l-6.3-67.4c0-2.4,4.3-72.6,2-72.6h-13.2c-2.4,0-4.3,1.9-4.3,4.3
                        v135.7C660.3,575.7,662.2,577.6,664.6,577.6z"/>
                      <polygon className="st12" points="674.5,417.8 682.1,419.6 686.9,448.2 680.6,448 658.9,447.5 661.7,417.8 				"/>
                    </g>
                  </g>
                  <g id="trees">
                    <g id="right-tree">
                      <circle className="st21" cx="990.2" cy="515.4" r="24.4"/>
                      <circle className="st21" cx="998.9" cy="493.5" r="24.4"/>
                      <circle className="st21" cx="1023.6" cy="480.4" r="24.4"/>
                      <circle className="st21" cx="1043" cy="512.6" r="24.4"/>
                      <circle className="st21" cx="1019.3" cy="511.9" r="24.4"/>
                      <line className="st22" x1="1017" y1="477.3" x2="1017" y2="577.3"/>
                      <line className="st22" x1="996.8" y1="488.9" x2="1016.7" y2="508.8"/>
                      <line className="st22" x1="1037.6" y1="505.1" x2="1017.7" y2="525"/>
                    </g>
                    <g id="left-tall-tree">
                      <ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 118.649 1128.4586)" className="st23" cx="722.5" cy="494.5" rx="33.1" ry="33.1"/>
                      <ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 157.9179 1115.2042)" className="st23" cx="734.4" cy="464.8" rx="33.1" ry="33.1"/>
                      <circle className="st21" cx="767.9" cy="447" r="33.1"/>
                      <circle className="st21" cx="794.1" cy="490.7" r="33.1"/>
                      <circle className="st21" cx="761.9" cy="489.8" r="33.1"/>
                      <line className="st22" x1="759" y1="442.8" x2="759" y2="578.4"/>
                      <line className="st22" x1="731.5" y1="458.5" x2="758.5" y2="485.5"/>
                      <line className="st22" x1="786.9" y1="480.6" x2="759.9" y2="507.6"/>
                    </g>
                    <g id="left-short-tree">
                      <circle className="st21" cx="686.7" cy="515.4" r="24.4"/>
                      <circle className="st21" cx="695.5" cy="493.5" r="24.4"/>
                      <circle className="st21" cx="720.2" cy="480.4" r="24.4"/>
                      <circle className="st21" cx="739.6" cy="512.6" r="24.4"/>
                      <ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 95.7777 1136.4639)" className="st23" cx="715.8" cy="511.9" rx="24.4" ry="24.4"/>
                      <line className="st22" x1="713.6" y1="477.3" x2="713.6" y2="577.3"/>
                      <line className="st22" x1="693.3" y1="488.9" x2="713.2" y2="508.8"/>
                      <line className="st22" x1="734.2" y1="505.1" x2="714.3" y2="525"/>
                    </g>
                  </g>
                
                  <svg 
                    id="lion-on-steps"
                    version="1.1" 
                    xmlns="http://www.w3.org/2000/svg" 
                    xmlnsXlink="http://www.w3.org/1999/xlink" 
                    x="0px" y="0px"
                    viewBox="0 0 1400 850"
                    className={playStreet>0 ? 'play' : ''}
                  >
                    <path className="st17" d="M591.1,459.3c0,0,8.7,1.5,11.9-5s-1.7-12.4-1.7-12.4v4.2c0,0-3.7-6-5.5-6.5c0.5,1-0.3,4.7-0.3,4.7
                      s-3.7-6-2.7-9.5c1.7-4.2-3.5,3-3.5,8c-0.7-1.7-2.2-4.5-2.2-4.5s-0.5,10.7,0.2,12.2c-1.5-0.4-2.9-1.2-4-2.2
                      C583.4,448.4,584.9,457.6,591.1,459.3z"/>
                    <path className="st45" d="M619.7,476.5c0,0-20.9-1.8-23.1-13.4c-2.2-11.3,2-9,2-9s1.7,0.6,1.2,5.7c-0.4,5.1,5.2,10.2,20.4,12.4
                      C620.6,473.7,620.4,475.2,619.7,476.5z"/>
                    <path d="M619.7,476.7L619.7,476.7c-3.7-0.4-7.3-1.2-10.8-2.4c-7.3-2.5-11.6-6.4-12.5-11.2c-1-5.1-0.8-8.1,0.5-9
                      c0.5-0.3,1.2-0.4,1.7-0.1c0,0,1.7,0.7,1.3,5.9c-0.3,3.4,2,9.6,20.3,12.3c0.1,0,0.1,0.1,0.1,0.1c0.4,1.5,0.2,3-0.5,4.3
                      C619.8,476.6,619.8,476.7,619.7,476.7z M597.8,454.1c-0.3,0-0.5,0.1-0.7,0.2c-0.8,0.6-1.5,2.6-0.3,8.8c2.1,11,21.4,13.2,22.9,13.3
                      c0.6-1.2,0.7-2.6,0.5-3.9c-18.4-2.7-20.7-9-20.4-12.6c0.4-4.9-1.1-5.6-1.1-5.6C598.3,454.1,598.1,454.1,597.8,454.1z"/>
                    <path className="st45" d="M624.6,439.2c0,0-4.3,16.3-4.3,23c-2.5,9.7,0.3,22.3,1.6,28c-4.7,0.3-12.3,3.5-10.2,10.7
                      c0.7,0.5,1.4,0.9,2.2,1.3c0,0,3,1.7,5.7,0.5c1.2,1.7,3.2,1.5,5.5,1.2s6.5-0.8,8.5-2c0.3-3.1-0.2-6.2-1.5-9c5.3-8.1,8-17.5,8-27.1"/>
                    <path d="M622.8,504.2c-1.3,0.1-2.5-0.4-3.3-1.4c-2.7,1.1-5.5-0.4-5.7-0.5c-0.8-0.3-1.6-0.7-2.3-1.3c0,0,0,0-0.1-0.1
                      c-0.7-2.2-0.4-4.5,0.9-6.4c2-2.8,6.1-4.3,9.2-4.5v0c-1.3-5.9-4-18.2-1.5-27.8c0-6.7,4.3-22.9,4.3-23s0.1-0.1,0.2-0.1
                      c0.1,0,0.1,0.1,0.1,0.2c0,0.2-4.3,16.4-4.3,23l0,0c-2.4,9.5,0.2,21.8,1.5,27.6l0.1,0.3v0.1l-0.1,0.1c-3.1,0.2-7.2,1.6-9.2,4.4
                      c-1.2,1.8-1.6,4-0.9,6.1c0.7,0.5,1.4,0.9,2.2,1.2l0,0c0,0,3,1.7,5.6,0.5c0.1,0,0.1,0,0.2,0c1.2,1.6,3.1,1.4,5.2,1.2h0.1
                      c1.9-0.2,6.3-0.7,8.3-1.9c0.3-3-0.2-6-1.5-8.8c0,0,0-0.1,0-0.2c5.2-8,7.9-17.4,7.9-27c0-0.1,0.1-0.2,0.1-0.2l0,0l0,0
                      c0.1,0,0.2,0.1,0.2,0.2l0,0c0,9.6-2.7,19-7.9,27.1c1.3,2.8,1.8,5.9,1.5,9c0,0,0,0.1-0.1,0.1c-2.1,1.3-6.6,1.8-8.5,2h-0.1
                      C624.3,504.1,623.5,504.2,622.8,504.2z"/>
                    <path className="st45" d="M649.2,435.9c0,0,14-5.4,16.2-7c0.4-1.3-2-5.3,1.9-5.7c0,0,2.4-0.3,2.6,3c0,0-0.8-5.7,3-4.5
                      c1.7,1,0.9,3.1,0.9,3.1s2.7-2.5,3.8-1.3c1.2,1.2,0.1,3.2-0.5,4c-0.4,0.6-0.9,1.2-1.4,1.8c0,0,3.1-1.8,3.5,0.7
                      c0.2,1.1-1.8,4.1-6.2,4c-7.1,3.8-15.4,13.3-23.8,15.1"/>
                    <path d="M649.3,449.2c-0.1,0-0.1,0-0.1-0.1s0-0.1,0.1-0.2l0,0c5.2-1.1,10.3-5.1,15.2-9c2.7-2.3,5.5-4.3,8.6-6.1h0.1
                      c1.9,0.2,3.8-0.5,5.2-1.9c0.5-0.5,0.8-1.1,0.9-1.8c0-0.4-0.3-0.8-0.6-1c-1-0.5-2.6,0.5-2.6,0.5c-0.1,0-0.1,0-0.2,0
                      c0-0.1,0-0.1,0-0.2c0,0,0.8-0.9,1.4-1.8c0.3-0.4,1.7-2.6,0.5-3.8c-1-1.1-3.6,1.3-3.6,1.4c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1,0-0.2
                      c0-0.1,0.7-2-0.8-2.9c-0.6-0.3-1.4-0.2-1.9,0.2c-1,0.8-0.9,2.8-0.8,3.7c0,0.1,0,0.3,0,0.4s-0.1,0.2-0.1,0.2l0,0
                      c-0.1,0-0.1,0-0.2-0.1l0,0c0-0.2,0-0.3,0-0.5c-0.1-0.8-0.4-1.5-1-2c-0.4-0.3-0.9-0.5-1.4-0.4c-0.7,0-1.3,0.3-1.8,0.8
                      c-0.6,0.8-0.4,2.1-0.2,3.1c0.2,0.5,0.2,1.1,0.1,1.6v0.1c-2.2,1.6-15.7,6.8-16.2,7.1c-0.1,0-0.2,0-0.2-0.1s0-0.2,0.1-0.2
                      s13.8-5.3,16.1-7c0-0.5,0-1-0.1-1.5c-0.2-1.1-0.5-2.5,0.2-3.4c0.5-0.6,1.2-0.9,2-0.9c0.6-0.1,1.2,0.1,1.6,0.5
                      c0.3,0.3,0.6,0.6,0.8,1c0-1,0.3-2,1-2.7c0.6-0.5,1.5-0.6,2.2-0.2l0,0c1,0.6,1.4,1.7,1.1,2.8c0.8-0.7,2.7-2,3.7-1s0.5,2.9-0.5,4.2
                      c-0.3,0.4-0.6,0.8-1,1.3c0.7-0.3,1.5-0.4,2.2-0.2c0.5,0.3,0.8,0.7,0.8,1.3c0,0.8-0.4,1.5-0.9,2.1c-1.4,1.4-3.4,2.2-5.4,2
                      c-3,1.8-5.8,3.8-8.5,6C659.7,444,654.6,448.1,649.3,449.2L649.3,449.2z"/>
                    <path className="st45" d="M651.2,437.5c0,0,9.8,25.5-12.4,39"/>
                    <path d="M638.7,476.7c-0.1,0-0.1,0-0.1-0.1s0-0.2,0-0.2c8.8-5,14.3-14.3,14.5-24.4c0.2-4.9-0.5-9.8-2.1-14.4c0-0.1,0-0.2,0.1-0.2
                      l0,0c0.1,0,0.2,0,0.2,0.1c1.6,4.7,2.3,9.6,2.1,14.5C653.2,462.2,647.7,471.5,638.7,476.7L638.7,476.7z"/>
                    <path className="st45" d="M629,455.3l1.7-13.8l9.6-16.7c0,0,4.8,6.3,10.3,6.3s0.6,5.7,0.6,5.7l-0.5,5.9l0.5,1.6l1.4,1l-0.7,9.3
                      l-9.6,18.9l-3.9,3.3l-4.7-0.2L629,455.3z"/>
                    <path className="st17" d="M656.7,398.2c2.9-3.6,6.2-7.9,3.9-12c-1.1,1.2-2.2,2.5-3.1,3.9c-0.3-3.5-1.1-7-2.4-10.3
                      c-0.1,1.3-0.4,2.6-0.9,3.8c-2.1-4.4-6.4-8-10.3-11c4.6,6.2,3,6.6,4.7,9.9c-6.6-4.4-14.4-6.6-22.3-6.3l5.7,2.2
                      c-5.6,0.2-10.6,3.3-15.4,6.3c1.3-0.5,2.7-0.2,3.7,0.7c-2.8,1-5.5,2.4-8,4.1s-4.2,4.4-4.9,7.3c0.9-1.1,2.1-1.9,3.5-2.3
                      c-2.5,4.7-4.7,9.7-6.4,14.7c1.5-1.9,3.3-3.7,5.2-5.3c-0.5,6.4-6.5,11.7-6.1,18.1l2.9-4.1c0.1,4.7,1.1,9.4,3,13.8
                      c0.4,0.8,1.3,1.8,2,1.2c0.2-1.3,0-2.5-0.4-3.7c1.7,4.6,4.2,8.8,7.3,12.6c-0.6-2-1-4-1.2-6c3.3,1.3,6.4,3,9.2,5.1
                      c-0.4-3.2-1.8-6.4-1.8-9.6s0.5-3.1,2.9-5.3c2.6-2.2,6.4-2.1,8.9,0.2c2.7,2.7,1.7,3.1,2.4,6.8c0.4,1.8,3.5,5.5,4.6,6.7
                      c0.3,0.4,0.6-2.6,1-2.2c0.8,0.8,1.5,0.7,2.3,1.5c1,1,0.4-2.8,1.2-2.8c2.2-0.1,2.2,0.2,5,2.9c0.8-2,5.8-7.7,5.9-9.8
                      c0,0-10.9,3-14.6-2c-4.7-6.3-4.7-6.8-8.6-9.6c-1.6-1.2-2.1-2.9,2-8.5c-2.7,0.8-5.5-1.1-5.5-2.4c0-5-1-3.9,2.6-10.4
                      C637.5,391.2,650,388.5,656.7,398.2z"/>
                    <path className="st45" d="M634.5,460.2c4.1,3.5,1.3,5.6,1.3,5.6s-1.9,2-4.7-3.3c0.1,1.5,1.4,5.2-0.8,5.8c-3.5,0.9-3-1.3-3.8-4.7
                      c0,1.5,1,4-1.7,4c-5-1-1.5-6.4-2.3-9.1c-1.3-4.3-1-8.6-1.3-13c-0.2-2.4,0.7-17,4.2-21.3s11-1.9,11-1.9c5.8,2.8,3.6,14.8-2.1,20.8
                      c-0.5,3.8-0.5,7.7,0,11.5c1.3,0.9,4.7,2.5,4.3,4.8c0,0-0.2,1.7-2.6,1.8"/>
                    <path d="M629,468.6c-0.4,0-0.8-0.1-1.1-0.3c-0.7-0.4-0.9-1.3-1.1-2.4c0,0.5-0.1,0.9-0.4,1.3c-0.4,0.4-1,0.6-1.6,0.6l0,0
                      c-3.4-0.7-3-3.5-2.6-5.9c0.3-1.1,0.3-2.2,0.1-3.3c-0.8-3-1.2-6.1-1.1-9.2c0-1.3-0.1-2.6-0.2-3.9c-0.2-2.4,0.7-17.1,4.2-21.4
                      s11.1-1.9,11.2-1.9c1.8,1,3.1,2.8,3.3,4.9c0.9,4.9-1.5,12-5.3,16.1c-0.5,3.8-0.5,7.6,0,11.4c0.2,0.2,0.5,0.3,0.8,0.5
                      c1.5,1,3.9,2.5,3.5,4.4c0,0-0.3,1.7-2.6,1.9c0.5,0.7,0.9,1.5,0.9,2.4c0,0.8-0.4,1.5-1,2c-0.4,0.3-0.8,0.5-1.3,0.4
                      c-1-0.1-2.1-1.2-3.2-3l0.1,0.3c0.3,1.7,0.8,4.3-1.1,4.8C629.9,468.6,629.5,468.6,629,468.6z M626.5,463.5c0.1,0,0.1,0,0.1,0.1
                      c0.2,0.7,0.3,1.3,0.4,1.9c0.2,1.3,0.4,2.2,1,2.6c0.7,0.3,1.5,0.3,2.2,0c1.6-0.4,1.2-2.8,0.9-4.5c-0.1-0.5-0.1-0.9-0.2-1.2
                      c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.1,0,0.1,0.1c1.5,2.8,2.6,3.5,3.4,3.6c0.4,0.1,0.8,0,1.1-0.3c0.5-0.4,0.9-1.1,0.9-1.8
                      c0-1.1-0.7-2.3-2.2-3.6c-0.1-0.1-0.1-0.2,0-0.2c0.1-0.1,0.2-0.1,0.2,0c0.4,0.4,0.8,0.8,1.2,1.2l0,0c2.2-0.1,2.4-1.7,2.4-1.7
                      c0.3-1.8-1.9-3.2-3.4-4.1c-0.3-0.2-0.6-0.4-0.8-0.5l-0.1-0.1c-0.5-3.8-0.5-7.7,0-11.5v-0.1c3.8-4.1,6.2-11,5.3-15.9
                      c-0.2-2-1.4-3.7-3.1-4.7c0,0-7.4-2.3-10.8,1.8c-1.8,2.2-2.9,7.4-3.4,11.3c-0.5,3.3-0.7,6.6-0.7,9.9c0.1,1.3,0.1,2.6,0.2,3.9
                      c0,3.1,0.3,6.1,1.1,9.1c0.2,1.1,0.2,2.3-0.1,3.4c-0.4,2.4-0.8,4.9,2.3,5.6c0.5,0.1,1-0.1,1.4-0.5c0.5-0.5,0.4-1.5,0.2-2.4
                      c0-0.4-0.1-0.7-0.1-1c0-0.1,0.1-0.1,0.1-0.2L626.5,463.5z"/>
                    <path className="st4" d="M660,421.1c0.8,3.8,0.2,6.2-0.9,7.4s-4.4,3.5-10.5,1.7s-5.4-1.6-8.6-6.8s-5.1-5.2-6-6.7s1.5-5,2.7-6.4
                      s-0.3-0.8-2-0.8s-2.7-1.2-3.3-3.5s1.1-5.8,1.1-5.8s2.1-5.7,6.6-7.6c4.5-1.8,9.3-0.4,10.2-0.2c1.7,0.4,7,3.8,7,5.2s-0.7,4.1-0.6,6.1
                      "/>
                    <path d="M652.9,431.1c-1.5,0-2.9-0.3-4.4-0.7c-5.5-1.6-5.5-1.7-7.8-5.4l-0.9-1.4c-1.2-2.1-2.7-3.9-4.6-5.3c-0.5-0.4-1-0.8-1.4-1.4
                      c-1-1.7,1.7-5.4,2.8-6.5c0.4-0.5,0.4-0.6,0.4-0.6c-0.3-0.1-0.7-0.1-1,0c-0.4,0-0.8,0.1-1.3,0.1c-1.7,0-2.8-1.2-3.5-3.6
                      s1-5.7,1.1-5.9c0.4-1.1,1-2.1,1.6-3.1c1.2-2,3-3.5,5.1-4.5c2-0.8,5.4-1.5,10.2-0.2h0.1c1.6,0.4,7.1,3.8,7.1,5.3
                      c0,0.8-0.1,1.6-0.3,2.4c-0.2,1.2-0.3,2.4-0.3,3.7c0,0.1-0.1,0.2-0.1,0.2l0,0c-0.1,0-0.1,0-0.2-0.1l0,0c0-1.3,0.1-2.5,0.4-3.7
                      c0.1-0.8,0.2-1.6,0.3-2.3c0-0.5-1.1-1.6-2.8-2.8c-1.2-0.9-2.6-1.6-4-2.2h-0.1c-4.7-1.3-8-0.6-10,0.2c-4.4,1.8-6.5,7.4-6.5,7.5
                      s-1.7,3.5-1.1,5.7c0.6,2.2,1.6,3.4,3.2,3.4c0.4,0,0.9,0,1.2-0.1c0.7-0.1,1.1-0.1,1.3,0.1s0,0.5-0.5,0.9c-1.2,1.4-3.6,4.8-2.7,6.2
                      c0.4,0.5,0.8,1,1.3,1.3c1.9,1.5,3.5,3.3,4.7,5.4l0.9,1.5c2.2,3.7,2.2,3.7,7.6,5.3c0.7,0.2,7.1,2,10.3-1.6c1.2-1.4,1.5-4,0.9-7.3
                      c0-0.1,0-0.2,0.1-0.2l0,0c0.1,0,0.1,0,0.2,0.1l0,0c0.9,4.6-0.2,6.7-0.9,7.6C657.6,430.3,655.3,431.2,652.9,431.1z"/>
                    <path className="st45" d="M655.7,403.8c-0.1-1.9,0.6-4.7,0.6-6.1s-5.3-4.8-7-5.2c-0.9-0.2-5.7-1.7-10.2,0.2c-4.5,1.9-6.6,7.6-6.6,7.6
                      s-1.7,3.5-1.1,5.8c0.6,2.3,1.7,3.5,3.3,3.5l2.5-0.1"/>
                    <path className="st46" d="M634.8,409.7c-1.7,0-2.8-1.2-3.5-3.6c-0.6-2.4,1-5.7,1.1-5.9c0.4-1.1,1-2.1,1.7-3.1c1.2-2,3-3.5,5.1-4.5
                      c2-0.8,5.3-1.5,10.1-0.2h0.1c1.6,0.4,7.1,3.8,7.1,5.3c0,0.8-0.1,1.6-0.3,2.4c-0.2,1.2-0.3,2.4-0.4,3.7c0,0.1-0.1,0.2-0.1,0.2l0,0
                      l0,0c-0.1,0-0.1-0.1-0.1-0.1c0-1.3,0.1-2.5,0.3-3.7c0.1-0.8,0.2-1.6,0.3-2.3c0-0.5-1.1-1.6-2.8-2.8c-1.2-0.9-2.6-1.6-4-2.2h-0.1
                      c-4.7-1.3-8-0.6-10,0.2c-4.4,1.8-6.5,7.4-6.5,7.5s-1.6,3.5-1.1,5.7C632.3,408.5,633.4,409.7,634.8,409.7l2.5-0.1
                      c0.1,0,0.1,0,0.2,0.1l0,0c0,0.1-0.1,0.2-0.1,0.2l0,0L634.8,409.7L634.8,409.7z"/>
                    <path className="st45" d="M651,462.7c4.4,9.4,4.2,20.3-0.7,29.5c2.2-1.7,4.9-2.5,7.7-2.2c4.5,0.5,6,7,4.7,8s-3.5,1-5.2,1.5
                      s-11.7,0-13.9-1.7s-0.7-6.5,2-10c-2.5-2.5-7-11.2-7-11.2S647.4,471.3,651,462.7z"/>
                    <path d="M655.2,499.7c-3.7,0-9.9-0.6-11.6-2c-0.7-0.5-1.1-1.3-1.1-2.1c-0.3-2.1,0.9-5.2,3-7.9c-2.5-2.6-6.7-10.8-6.9-11.2
                      c0-0.1,0-0.2,0.1-0.2l0,0c0.1-0.1,8.7-5.2,12.3-13.8c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0.1c4.3,9.2,4.2,19.9-0.4,29.1
                      c2.1-1.4,4.7-2.1,7.3-1.9c2.7,0.3,4.4,2.7,5,4.9c0.5,1.5,0.4,2.9-0.2,3.3c-1,0.6-2.2,1-3.4,1.1c-0.6,0.1-1.2,0.2-1.8,0.4
                      C656.9,499.7,656,499.7,655.2,499.7z M638.9,476.5c0.5,1.1,4.6,8.8,6.9,11c0,0.1,0,0.1,0,0.2c-2,2.6-3.3,5.8-3,7.8
                      c0.1,0.7,0.4,1.4,1,1.9c2.2,1.7,12.1,2.2,13.8,1.7c0.6-0.2,1.2-0.3,1.9-0.4c1.2-0.1,2.3-0.5,3.3-1.1c0.5-0.4,0.5-1.6,0.1-3
                      c-0.6-2.1-2.2-4.4-4.7-4.7c-2.7-0.2-5.4,0.5-7.6,2.2c-0.1,0.1-0.1,0.1-0.2,0c0-0.1,0-0.1,0-0.2c4.7-9,5-19.8,0.8-29.1
                      C647.6,470.9,640,475.9,638.9,476.5z"/>
                    <path className="st19" d="M628.8,396c0,0-6.9,3.8-8.6,3.6s-0.4,6.9,2.9,8.4c-0.4-1.5-0.6-3.1-0.6-4.6l2.3,1.5l-1.7-3.4l2.1-0.8
                      L628.8,396z"/>
                    <path className="st17" d="M648.7,393.3l-1.3,2.6c0,0-2.4-2.5-8.8,0C642.1,392.1,645.3,392.7,648.7,393.3z"/>
                    <path className="st17" d="M652.9,395.8l-0.5,2c0,0,1.9-1,3.5,1.9C655.7,397.6,655.8,397.5,652.9,395.8z"/>
                    <path className="st32" d="M644.4,396.5c0.8,1.2,1,2.7,0.4,4.1c-1.7-0.5-3.5-0.7-5.2-0.6C639.6,400,640,396.5,644.4,396.5z"/>
                    <path className="st10" d="M644.2,400.5c0-1.3,0-3.8-1.5-3.7c-0.9,0.1-2,2-2,3.2"/>
                    <path d="M644.3,400.5H644c0-1,0-2.7-0.7-3.4c-0.2-0.2-0.4-0.2-0.6-0.2c-0.8,0.1-1.9,1.9-1.9,3.1h-0.3c0-1.3,1.1-3.3,2.2-3.4
                      c0.3,0,0.6,0.1,0.9,0.3C644.3,397.6,644.3,399.5,644.3,400.5z"/>
                    <ellipse transform="matrix(0.9689 -0.2473 0.2473 0.9689 -84.5565 178.2627)" className="st19" cx="667.3" cy="425.7" rx="0.9" ry="1.3"/>
                    <ellipse transform="matrix(0.3962 -0.9182 0.9182 0.3962 16.2885 873.0147)" className="st19" cx="671.9" cy="424.1" rx="1.3" ry="0.9"/>
                    <ellipse transform="matrix(0.5556 -0.8315 0.8315 0.5556 -59.0469 748.9982)" className="st19" cx="671.1" cy="429.7" rx="2.1" ry="3"/>
                    <ellipse transform="matrix(0.6451 -0.7641 0.7641 0.6451 -85.5167 667.4296)" className="st19" cx="675.7" cy="425.8" rx="1.3" ry="0.9"/>
                    <ellipse transform="matrix(0.7827 -0.6224 0.6224 0.7827 -121.0983 514.8981)" className="st19" cx="676.8" cy="430.9" rx="1.3" ry="0.9"/>
                    <path className="st32" d="M654.7,403.9l-2.9-1.9c0,0,0.2-2.6,1.2-3s2,1.1,2,1.1L654.7,403.9z"/>
                    <path className="st10" d="M654,398.9c0,0,1,0.5,0.1,4.5c-0.5-0.4-1.1-0.8-1.7-1.2C652.4,402.2,652.6,398.7,654,398.9z"/>
                    <path className="st45" d="M651.2,403.9c0,0,9.7-2.1,14.4,4.3c1.3,3.1-2.5,11.1-5.5,12.9c-1.1-1.1-1.5-4.5-3.5-4.8s-8.2,5.5-11.4,3.1
                      c-2.3-1.8-4-4.3-5-7"/>
                    <path d="M660,421.3L660,421.3c-0.5-0.6-0.9-1.2-1.1-1.9c-0.5-1.3-1.2-2.7-2.3-2.9c-0.7-0.1-2,0.6-3.4,1.4c-2.7,1.5-6,3.3-8.1,1.7
                      c-2.3-1.9-4-4.3-5.1-7.1c0-0.1,0-0.2,0.1-0.2s0.2,0,0.2,0.1l0,0c1,2.7,2.7,5.1,5,6.9c2,1.5,5.2-0.3,7.7-1.7
                      c1.5-0.8,2.9-1.6,3.6-1.4c1.3,0.2,2,1.7,2.6,3c0.2,0.6,0.5,1.1,0.8,1.6c3-1.9,6.7-9.6,5.4-12.6c-4.6-6.2-14.1-4.2-14.2-4.2
                      c-0.1,0-0.2,0-0.2-0.1s0-0.2,0.1-0.2l0,0c0.1,0,9.8-2.1,14.5,4.3l0,0c0.6,1.5,0.2,4.1-1.1,7c-1.3,2.8-3,5.1-4.5,6
                      C660.1,421.2,660.1,421.2,660,421.3z"/>
                    <path className="st4" d="M637.2,409.4c0,0,1.7-1,3.3,3.7c2.6,7.5,7.1,8.7,13,4.4c0.7-0.5,2.1-1.7,3.3-1.3c0.5,0.1,1.4,0.9,2.5,3.5
                      c0.2,0.4,0.4,0.8,0.7,1.1c3.3-2.9,5.3-7,5.7-11.4c0-2.1-2.4,0.7-5.2,0.2c-2.8-0.7-5.5-1.8-8.1-3.2c-2.8-1.8-6.2-2.3-9.4-1.2
                      C640.8,406.1,638.7,407.5,637.2,409.4z"/>
                    <path className="st19" d="M640.8,414.1c0,0,1.9,11.5,7.5,12.6c3.1,0.6,4-1.9,5.4-2.2c1.5-0.2,3-0.1,4.5,0.1c0,0-2.9-4.9-2.7-8
                      C648,420.2,645.9,423.2,640.8,414.1z"/>
                    <path d="M649.3,426.9c-0.3,0-0.7,0-1-0.1c-2.4-0.5-4.5-2.8-6-6.8c-0.7-1.9-1.3-3.9-1.6-5.9c0-0.1,0-0.1,0.1-0.2
                      c0.1,0,0.1,0,0.2,0.1c4.3,7.7,6.3,6.7,11.7,3.8c0.8-0.4,1.8-0.9,2.8-1.4c0,0,0.1,0,0.2,0l0.1,0.1c-0.2,3.1,2.6,7.9,2.7,7.9
                      c0,0,0,0.1,0,0.2l-0.1,0.1c-1.5-0.2-2.9-0.3-4.4-0.1c-0.5,0.2-0.9,0.5-1.3,0.9C651.7,426.3,650.5,426.9,649.3,426.9z M641.1,414.9
                      c0.6,2.7,2.7,10.6,7.2,11.6c1.5,0.3,3-0.2,3.9-1.3c0.4-0.4,0.9-0.7,1.4-0.9c1.4-0.2,2.8-0.2,4.2,0.1c-0.6-1-2.6-4.8-2.6-7.6
                      c-0.9,0.5-1.8,0.9-2.6,1.3C647.6,420.9,645.3,422.1,641.1,414.9L641.1,414.9z"/>
                    <path className="st17" d="M655.3,424.3c2.4,0.3-2.4-2.4-3.8-0.8c-3-0.8-4.1,2.9-4.1,2.9s2.1,0.8,3.2,0.2c0.8-0.5,1.5-1,2.1-1.7
                      C653.5,424.4,654.4,424.1,655.3,424.3z"/>
                    <path d="M649.6,426.9c-0.8,0-1.5-0.2-2.2-0.4h-0.1v-0.1c0,0,1.2-3.8,4.2-3c1-1,3.2-0.2,4,0.3c0.4,0.3,0.6,0.5,0.5,0.7
                      s-0.3,0.2-0.8,0.2c-0.9-0.1-1.7,0.1-2.5,0.6c-0.6,0.7-1.4,1.3-2.2,1.7C650.3,426.9,650,426.9,649.6,426.9z M647.6,426.3
                      c0.9,0.4,2,0.4,2.9,0.2c0.8-0.4,1.5-1,2.1-1.7c0.8-0.6,1.7-0.8,2.7-0.7l0,0c0.2,0,0.3,0,0.5,0c-0.6-0.5-1.3-0.8-2-0.9
                      c-0.6-0.1-1.6-0.3-2.1,0.3l-0.1,0.1h-0.1C649,422.9,647.9,425.6,647.6,426.3L647.6,426.3z"/>
                    <path className="st19" d="M650.5,406.4c0,0,1.5-1.5,4.7,0s4.7,2.9,6.8,2.9c1.1,0,2.1-1.1,2.7-1.3c0.3-0.1,0.7,0.1,0.8,0.4
                      c0,0,0.7,1.2-1.2,3.4c-1.7,1.9-2.3,2.3-5.5,1.5s-1.5-2-3.3-3.3s-3.2-0.8-4.1-1.4C650.9,408,650.5,407.2,650.5,406.4z"/>
                    <path d="M644.9,400.9L644.9,400.9c-0.1,0-0.2-0.1-0.1-0.2l0,0c0.7-1.4,0.5-3-0.4-4.2c-0.1-0.1-0.1-0.2,0-0.2c0.1-0.1,0.2-0.1,0.2,0
                      c1,1.3,1.1,3.1,0.4,4.6C645,400.9,645,400.9,644.9,400.9z"/>
                    <path d="M639.5,400.1L639.5,400.1c-0.1,0-0.1-0.1-0.1-0.1l0,0c0.1-1,0.6-1.8,1.3-2.5c2-1.7,5-1.2,5.1-1.2s0.1,0.1,0.1,0.2l0,0
                      c0,0.1-0.1,0.1-0.2,0.1c0,0-3-0.5-4.9,1.1c-0.7,0.6-1.1,1.4-1.2,2.3C639.7,400.1,639.6,400.2,639.5,400.1z"/>
                    <ellipse transform="matrix(0.1711 -0.9853 0.9853 0.1711 139.9787 964.9034)" cx="643.4" cy="399.3" rx="1.2" ry="0.8"/>
                    <ellipse transform="matrix(0.1711 -0.9853 0.9853 0.1711 145.8667 977.2951)" cx="653.7" cy="402" rx="1" ry="0.7"/>
                    <path d="M654.1,403.8L654.1,403.8c-0.1,0-0.1-0.1-0.1-0.2l0,0c0.7-3.1,0.4-4.1,0.2-4.5c-0.1-0.1-0.1-0.1-0.2-0.2c0,0-1.4,0-1.5,3.3
                      c0,0.1-0.1,0.1-0.1,0.1l0,0c-0.1,0-0.1-0.1-0.1-0.1l0,0c0.1-2,0.6-2.9,1-3.2c0.2-0.2,0.5-0.3,0.8-0.3c0.2,0,0.4,0.1,0.5,0.3
                      c0.3,0.5,0.6,1.7-0.1,4.7C654.3,403.8,654.2,403.8,654.1,403.8L654.1,403.8z"/>
                    <path d="M654,403.9c-0.1,0-0.1,0-0.1-0.1c-0.7-0.8-1.6-1.4-2.6-1.8c-0.1,0-0.1-0.1-0.1-0.2l0,0c0-0.1,0.1-0.1,0.2-0.1
                      c1,0.4,2,1,2.7,1.9c0.1,0.1,0.1,0.2,0,0.2L654,403.9z"/>
                    <path d="M654.6,403.8L654.6,403.8c-0.1,0-0.1-0.1-0.1-0.2c0.6-2.4,0.3-3.4-0.2-4.2c-0.4-0.5-0.9-1-1.6-1.2c-0.1,0-0.1-0.1-0.1-0.2
                      l0,0c0-0.1,0.1-0.1,0.2-0.1l0,0c0.7,0.2,1.3,0.7,1.8,1.3c0.5,0.9,0.8,2,0.2,4.4C654.8,403.8,654.7,403.8,654.6,403.8L654.6,403.8z"/>
                    <circle cx="646.2" cy="414.9" r="0.4"/>
                    <path d="M644.4,415.2c-2.4,0.6-4.8,1.3-7.1,2.2c-2,0.9-3.8,2.1-5.3,3.6c-0.9,0.9-1.8,1.8-2.6,2.8c-0.2,0.2,0.1,0.5,0.3,0.3
                      c1.4-1.7,2.9-3.2,4.7-4.5s3.9-2.3,6.1-2.9l4.1-1.2c0.1,0,0.1-0.2,0.1-0.3C644.6,415.2,644.5,415.2,644.4,415.2L644.4,415.2z"/>
                    <path d="M651.7,402L651.7,402c-0.1,0-0.1-0.1-0.1-0.2c0.2-2.7,1.9-3.3,2-3.4c0.1,0,0.2,0,0.2,0.1s0,0.2-0.1,0.2s-1.6,0.6-1.8,3.1
                      C651.9,402,651.8,402,651.7,402L651.7,402z"/>
                    <path d="M646.2,401.5L646.2,401.5c-3.3-1.8-7.5-1.1-7.6-1.1s-0.2-0.1-0.2-0.1c0-0.1,0.1-0.2,0.1-0.2l0,0c0,0,4.4-0.7,7.7,1.1
                      c0.1,0,0.1,0.1,0.1,0.2S646.3,401.5,646.2,401.5z"/>
                    <circle cx="644.1" cy="412.2" r="0.4"/>
                    <circle cx="646.1" cy="409.7" r="0.4"/>
                    <path d="M645,409c-2-1.2-4.2-2.3-6.3-3.3c-2.1-0.8-4.3-1.2-6.6-1.3c-1.3,0-2.6,0-3.9,0.2c-0.2,0-0.2,0.4,0,0.4
                      c2.8-0.4,5.7-0.2,8.5,0.5c2.9,0.8,5.5,2.4,8.1,3.8C645.1,409.4,645.3,409.1,645,409z"/>
                    <path d="M638.6,412.6c-0.6,0-1.1-0.1-1.7-0.2c-0.1,0-0.1-0.1-0.1-0.2s0.1-0.1,0.2-0.1c0,0,4,0.9,4.5-1.4c0-0.1,0.1-0.1,0.2-0.1
                      c0.1,0,0.1,0.1,0.1,0.2l0,0C641.4,412.3,639.8,412.6,638.6,412.6z"/>
                    <path d="M641.9,411.9c-2.4-0.5-4.7-0.8-7.1-0.9c-2.3,0-4.5,0.3-6.7,1.1c-1.2,0.4-2.4,0.9-3.5,1.5c-0.2,0.1,0,0.4,0.2,0.3
                      c2.5-1.3,5.3-2.1,8.2-2.4c3-0.3,6,0.4,8.9,0.9C642.1,412.2,642.2,411.9,641.9,411.9L641.9,411.9z"/>
                    <circle cx="660.3" cy="418.2" r="0.4"/>
                    <circle cx="660.2" cy="415.5" r="0.4"/>
                    <circle cx="663.6" cy="414" r="0.4"/>
                    <path d="M661.2,417.3c1.3,1.8,2.2,4,2.5,6.2c0.2,1.7,0,3.4-0.4,5.1c-0.2,0.8-0.4,1.6-0.6,2.4c0,0.1,0.1,0.2,0.2,0.2
                      c0.1,0,0.2,0,0.2-0.1c0.4-1.5,0.8-3,1-4.6c0.2-1.9,0-3.9-0.6-5.7c-0.5-1.3-1.1-2.5-1.9-3.7C661.4,416.9,661.1,417.1,661.2,417.3
                      L661.2,417.3z"/>
                    <path d="M662.9,415.2c2.3,0.8,4.2,2.4,5.6,4.3c0.6,0.8,1,1.8,1.3,2.8c0,0.1,0.2,0.1,0.3,0.1s0.1-0.1,0.1-0.2c-1-3-3.1-5.5-6-6.8
                      c-0.4-0.2-0.8-0.4-1.2-0.5C662.7,414.7,662.6,415.1,662.9,415.2L662.9,415.2z"/>
                    <path d="M664.3,413.1c1.9-0.6,4-0.5,5.9,0.4c1.6,0.8,3,1.8,4.1,3.1c0.6,0.6,1.1,1.3,1.7,2c0.1,0.2,0.4-0.1,0.3-0.3
                      c-2.2-2.7-4.8-5.5-8.4-5.9c-1.2-0.1-2.4,0-3.6,0.3c-0.1,0-0.1,0.2-0.1,0.3C664.2,413.1,664.3,413.1,664.3,413.1L664.3,413.1z"/>
                    <path d="M657.6,499.5L657.6,499.5c-0.1,0-0.1-0.1-0.1-0.2l0,0c0,0,1.4-3.7-0.7-6.3c-0.1-0.1-0.1-0.2,0-0.2c0.1-0.1,0.1-0.1,0.2,0
                      l0,0c2.2,2.7,0.7,6.5,0.7,6.6C657.8,499.5,657.7,499.6,657.6,499.5z"/>
                    <path d="M615.5,502.9c-0.1,0-0.1,0-0.1-0.1c0,0-1.5-3.9,0.7-6.6c0.1-0.1,0.1-0.1,0.2,0c0.1,0.1,0.1,0.1,0,0.2l0,0
                      c-2.1,2.5-0.7,6.2-0.7,6.3C615.6,502.8,615.6,502.9,615.5,502.9L615.5,502.9z"/>
                    <path d="M619.7,502.8c-0.1,0-0.1,0-0.1-0.1c0,0-1.5-3.9,0.7-6.6c0.1-0.1,0.1-0.1,0.2,0c0.1,0.1,0.1,0.2,0,0.2
                      c-2.1,2.5-0.7,6.2-0.7,6.3C619.8,502.7,619.8,502.8,619.7,502.8L619.7,502.8z"/>
                    <path d="M661,498.7L661,498.7c-0.1,0-0.1-0.1-0.1-0.2l0,0c0,0,1.4-3.7-0.7-6.3c-0.1-0.1-0.1-0.2,0-0.2c0.1-0.1,0.2-0.1,0.2,0
                      c2.2,2.7,0.7,6.5,0.7,6.5C661.2,498.7,661.1,498.7,661,498.7z"/>
                  </svg>
                </svg>
              </div>

            </div>

          </div>
          <div id="mask-container" className={playStreet>1 ? 'hide' : ''}>
            <div className="left"></div>
            <div className="right">
              <div id="mask-container-right-div">
                <div id="mask">
                  <svg
                    version="1.1" 
                    xmlns="http://www.w3.org/2000/svg" 
                    xmlnsXlink="http://www.w3.org/1999/xlink" 
                    x="0px" y="0px"
                    viewBox="0 0 1400 850"
                  >
                    <path id="background-shape-cutout" className="st0" d="M0,0v850h1400V0H0z M1345.8,432c-58.2,75.5-114.3,107.8-196.8,124.6c-17.6,3.6-66.2,9.9-82.6,15.6
                      c-55.1,19.3-94.8,37.2-128.5,58.5c-93.2,58.9-326.2,40.5-450.5-73.6S222.6,76.7,678.1,44.1c0,0,106.7,1.2,228.4,43.4
                      c125.9,43.7,241.4,46.2,307.3,68.6C1290.1,182,1456.2,288.7,1345.8,432z"/>
                    <path id="background-shape2" className="st1" d="M1345.8,432c-58.2,75.5-114.3,107.8-196.8,124.6c-17.6,3.6-66.2,9.9-82.6,15.6c-55.1,19.3-94.8,37.2-128.5,58.5
                      c-93.2,58.9-326.2,40.5-450.5-73.6S222.6,76.7,678.1,44.1c0,0,106.7,1.2,228.4,43.4c125.9,43.7,241.4,46.2,307.3,68.6
                      C1290.1,182,1456.2,288.7,1345.8,432z"/>
                  </svg>
                </div>

                <div id="masked-townhouses-container">
                  <svg 
                    version="1.1" 
                    xmlns="http://www.w3.org/2000/svg" 
                    xmlnsXlink="http://www.w3.org/1999/xlink" 
                    x="0px" y="0px"
                    viewBox="0 0 1400 850"
                    className={playStreet>0 ? 'play' : ''}
                  >
                    <g id="townhouses" onAnimationEnd={(e) => playAnimation(e, 'townhouses')}>
                      <g id="townhouse-3" className="st2">
                        <polyline className="st3" points="1310.8,140.9 1330.8,108.9 1317.9,107.8 1322.8,91.3 1226.8,89.7 1141.8,39.1 1038.8,106.5 
                          1051,106.5 1053.3,116.5"/>
                        <path className="st4" d="M1063.3,162.5v414.8h249.8V156.5c0,0,6.4-6.8-3-6.8V143c0,0,8.8-4.1,10.1-7.4
                          s8.8-23,8.8-23l-18.9-0.7l6.1-14.9l-92.5-0.7l-82.4-48.6l-87.1,56l3.4,11.5l-15.5,0.7l23.6,24.3l-0.7,9.4l-6.1,3.4L1063.3,162.5
                          z"/>
                        <polyline className="st5" points="1311.9,456.9 1215.9,456.9 1203.6,461 1080.5,461 1064.6,457.2 				"/>
                        <path className="st6" d="M1291.2,577.2H1226c-2.4,0,5.7-140.1,5.7-140.1c0-2.4,1.9-4.3,4.3-4.3h55.2c2.4,0,4.3,1.9,4.3,4.3
                          C1295.6,437.1,1293.6,577.2,1291.2,577.2z"/>
                        <path className="st7" d="M1229.9,577.2h-13.2c-2.4,0-4.3-1.9-4.3-4.3l6.3-67.4c0-2.4-4.3-72.6-2-72.6h13.2c2.4,0,4.3,1.9,4.3,4.3
                          v135.7C1234.2,575.2,1232.3,577.1,1229.9,577.2z"/>
                        <polyline className="st5" points="1311.9,266.3 1215.9,266.3 1203.6,270.4 1080.5,270.4 1064.6,266.6 				"/>
                        <polyline className="st5" points="1311.9,279.4 1215.9,279.4 1203.6,283.5 1080.5,283.5 1064.6,279.7 				"/>
                        <polyline className="st5" points="1311.9,291.4 1215.9,291.4 1203.6,295.5 1080.5,295.5 1064.6,291.7 				"/>
                        <polyline className="st5" points="1311.9,303.4 1215.9,303.4 1203.6,307.5 1080.5,307.5 1064.6,303.6 				"/>
                        <polyline className="st5" points="1311.9,315.6 1215.9,315.6 1203.6,319.7 1080.5,319.7 1064.6,315.9 				"/>
                        <polyline className="st5" points="1311.9,328.5 1215.9,328.5 1203.6,332.6 1080.5,332.6 1064.6,328.8 				"/>
                        <polyline className="st5" points="1311.9,343 1215.9,343 1203.6,347.2 1080.5,347.2 1064.6,343.3 				"/>
                        <polyline className="st5" points="1311.9,356 1215.9,356 1203.6,360.1 1080.5,360.1 1064.6,356.3 				"/>
                        <polyline className="st5" points="1311.9,367.9 1215.9,367.9 1203.6,372 1080.5,372 1064.6,368.2 				"/>
                        <polyline className="st5" points="1311.9,380.1 1215.9,380.1 1203.6,384.3 1080.5,384.3 1064.6,380.4 				"/>
                        <polyline className="st5" points="1311.9,393.1 1215.9,393.1 1203.6,397.2 1080.5,397.2 1064.6,393.3 				"/>
                        <polyline className="st5" points="1311.9,407.6 1215.9,407.6 1203.6,411.7 1080.5,411.7 1064.6,407.9 				"/>
                        <polyline className="st5" points="1311.9,420.5 1215.9,420.5 1203.6,424.6 1080.5,424.6 1064.6,420.8 				"/>
                        <polyline className="st5" points="1311.9,432.4 1215.9,432.4 1203.6,436.6 1080.5,436.6 1064.6,432.7 				"/>
                        <polyline className="st5" points="1311.9,444.7 1215.9,444.7 1203.6,448.8 1080.5,448.8 1064.6,445 				"/>
                        <polyline className="st5" points="1311.9,185.9 1215.9,185.9 1203.6,190 1080.5,190 1064.6,186.2 				"/>
                        <polyline className="st5" points="1311.9,199.2 1215.9,199.2 1203.6,203.3 1080.5,203.3 1064.6,199.4 				"/>
                        <polyline className="st5" points="1311.9,212.1 1215.9,212.1 1203.6,216.2 1080.5,216.2 1064.6,212.4 				"/>
                        <polyline className="st5" points="1311.9,225 1215.9,225 1203.6,229.1 1080.5,229.1 1064.6,225.3 				"/>
                        <polyline className="st5" points="1311.9,158.2 1215.9,158.2 1203.6,162.3 1080.5,162.3 1058.9,155.6 				"/>
                        <line className="st5" x1="1169.9" y1="74.6" x2="1114.6" y2="74.6"/>
                        <line className="st5" x1="1159.5" y1="67.3" x2="1123.7" y2="67.3"/>
                        <line className="st5" x1="1292" y1="110.1" x2="1236.7" y2="110.1"/>
                        <line className="st5" x1="1281.6" y1="102.8" x2="1245.8" y2="102.8"/>
                        <line className="st5" x1="1236.7" y1="141" x2="1292" y2="141"/>
                        <line className="st5" x1="1247.1" y1="148.3" x2="1282.8" y2="148.3"/>
                        <line className="st5" x1="1103.3" y1="141" x2="1173.6" y2="141"/>
                        <line className="st5" x1="1116.5" y1="148.6" x2="1162" y2="148.6"/>
                        <polyline className="st5" points="1311.9,171.1 1215.9,171.1 1203.6,175.2 1080.5,175.2 1064.6,171.4 				"/>
                        <polyline className="st5" points="1311.9,238.3 1215.9,238.3 1203.6,242.4 1080.5,242.4 1064.6,238.6 				"/>
                        <polyline className="st5" points="1311.9,251 1215.9,251 1203.6,255.2 1080.5,255.2 1064.6,251.3 				"/>
                        <path className="st8" d="M1111.9,183.6h20.2c2.4,0,4.3,1.9,4.3,4.3v78c0,2.4-1.9,4.3-4.3,4.3h-20.2c-2.4,0-4.3-1.9-4.3-4.3v-78
                          C1107.6,185.6,1109.5,183.6,1111.9,183.6z"/>
                        <path className="st8" d="M1148,183.6h20.2c2.4,0,4.3,1.9,4.3,4.3v78c0,2.4-1.9,4.3-4.3,4.3H1148c-2.4,0-4.3-1.9-4.3-4.3v-78
                          C1143.7,185.6,1145.6,183.6,1148,183.6z"/>
                        <line className="st9" x1="1108.4" y1="223.7" x2="1135.6" y2="223.7"/>
                        <line className="st9" x1="1144.6" y1="223.7" x2="1172" y2="223.7"/>
                        <path className="st8" d="M1111.9,336h20.2c2.4,0,4.3,1.9,4.3,4.3v78c0,2.4-1.9,4.3-4.3,4.3h-20.2c-2.4,0-4.3-1.9-4.3-4.3v-78
                          C1107.6,338,1109.5,336,1111.9,336z"/>
                        <path className="st8" d="M1148,336h20.2c2.4,0,4.3,1.9,4.3,4.3v78c0,2.4-1.9,4.3-4.3,4.3H1148c-2.4,0-4.3-1.9-4.3-4.3v-78
                          C1143.7,338,1145.6,336,1148,336z"/>
                        <line className="st9" x1="1108.4" y1="376.1" x2="1135.6" y2="376.1"/>
                        <line className="st9" x1="1144.6" y1="376.1" x2="1172" y2="376.1"/>
                        <path className="st8" d="M1250.8,193.3h20.2c2.4,0,4.3,1.9,4.3,4.3v68.3c0,2.4-1.9,4.3-4.3,4.3h-20.2c-2.4,0-4.3-1.9-4.3-4.3v-68.3
                          C1246.5,195.2,1248.5,193.3,1250.8,193.3z"/>
                        <line className="st9" x1="1246.4" y1="228.9" x2="1275.3" y2="228.9"/>
                        <path className="st6" d="M1089.7,473.1h91.6c2.4,0,4.3,1.9,4.3,4.3v95c0,2.4-1.9,4.3-4.3,4.3h-91.6c-2.4,0-4.3-1.9-4.3-4.3v-95
                          C1085.3,475.1,1087.3,473.1,1089.7,473.1z"/>
                        <line className="st9" x1="1092.4" y1="484.2" x2="1177.5" y2="484.2"/>
                        <line className="st9" x1="1238.2" y1="454.1" x2="1290.7" y2="454.1"/>
                        <line className="st9" x1="1238.2" y1="465.1" x2="1290.7" y2="465.1"/>
                        <line className="st9" x1="1238.2" y1="475.7" x2="1290.7" y2="475.7"/>
                        <line className="st9" x1="1238.2" y1="486.7" x2="1290.7" y2="486.7"/>
                        <line className="st9" x1="1232.3" y1="497.7" x2="1290.7" y2="497.7"/>
                        <line className="st9" x1="1232.3" y1="508.7" x2="1290.7" y2="508.7"/>
                        <line className="st9" x1="1232.3" y1="521.5" x2="1290.7" y2="521.5"/>
                        <line className="st9" x1="1232.3" y1="532.6" x2="1290.7" y2="532.6"/>
                        <line className="st9" x1="1232.3" y1="545.1" x2="1290.7" y2="545.1"/>
                        <line className="st9" x1="1232.3" y1="557.2" x2="1290.7" y2="557.2"/>
                        <line className="st9" x1="1232.3" y1="571.4" x2="1290.7" y2="571.4"/>
                        <line className="st9" x1="1092.4" y1="496.7" x2="1177.5" y2="496.7"/>
                        <line className="st9" x1="1092.4" y1="509.2" x2="1177.5" y2="509.2"/>
                        <line className="st9" x1="1092.4" y1="521.5" x2="1177.5" y2="521.5"/>
                        <line className="st9" x1="1092.4" y1="534" x2="1177.5" y2="534"/>
                        <line className="st9" x1="1092.4" y1="546.4" x2="1177.5" y2="546.4"/>
                        <line className="st9" x1="1092.4" y1="557.1" x2="1177.5" y2="557.1"/>
                        <line className="st9" x1="1092.4" y1="569.5" x2="1177.5" y2="569.5"/>
                        <path className="st10" d="M1232.9,116.2h61.5c2.4,0,4.3,1.9,4.3,4.3v8.9c0,2.4-1.9,4.3-4.3,4.3h-61.5c-2.4,0-4.3-1.9-4.3-4.3v-8.9
                          C1228.6,118.1,1230.5,116.2,1232.9,116.2z"/>
                        <path className="st10" d="M1103.4,116.2h75.5c2.4,0,4.3,1.9,4.3,4.3v8.3c0,2.4-1.9,4.3-4.3,4.3h-75.5c-2.4,0-4.3-1.9-4.3-4.3v-8.3
                          C1099.1,118.1,1101,116.2,1103.4,116.2z"/>
                        <path className="st10" d="M1106.2,86.1L1106.2,86.1c2.4,0,4.3,1.9,4.3,4.3V98c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3
                          v-7.6C1101.9,88,1103.8,86.1,1106.2,86.1z"/>
                        <path className="st10" d="M1120.7,86.1L1120.7,86.1c2.4,0,4.3,1.9,4.3,4.3V98c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3
                          v-7.6C1116.4,88,1118.3,86.1,1120.7,86.1z"/>
                        <path className="st10" d="M1135.3,86.1L1135.3,86.1c2.4,0,4.3,1.9,4.3,4.3V98c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3
                          v-7.6C1130.9,88,1132.9,86.1,1135.3,86.1z"/>
                        <path className="st10" d="M1149.7,86.1L1149.7,86.1c2.4,0,4.3,1.9,4.3,4.3V98c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3
                          v-7.6C1145.4,88,1147.3,86.1,1149.7,86.1z"/>
                        <path className="st10" d="M1163.7,86.1L1163.7,86.1c2.4,0,4.3,1.9,4.3,4.3V98c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3
                          v-7.6C1159.4,88,1161.3,86.1,1163.7,86.1z"/>
                        <path className="st10" d="M1178.1,86.1L1178.1,86.1c2.4,0,4.3,1.9,4.3,4.3V98c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3
                          v-7.6C1173.8,88,1175.7,86.1,1178.1,86.1z"/>
                        <path className="st7" d="M1288.6,577.2h13.2c2.4,0,4.3-1.9,4.3-4.3l-6.3-67.4c0-2.4,4.3-72.6,2-72.6h-13.1c-2.4,0-4.3,1.9-4.3,4.3
                          v135.7C1284.4,575.2,1286.3,577.1,1288.6,577.2z"/>
                        <path className="st3" d="M1210.5,309.2l8,7.3l2.4,129.1h82.6V317.3l8.1-8.5l-45.7-34.2c0,0,3.9-10.2-4.5-10.2
                          c-6.2,0-7.6,5.2-5.2,10.8C1229.5,293.9,1210.5,309.2,1210.5,309.2z"/>
                        <path className="st6" d="M1242.8,326.8h11.8c2.4,0,4.3,1.9,4.3,4.3V429c0,2.4-1.9,4.3-4.3,4.3h-11.8c-2.4,0-4.3-1.9-4.3-4.3v-97.9
                          C1238.5,328.8,1240.4,326.8,1242.8,326.8z"/>
                        <path className="st6" d="M1268.9,326.8h11.8c2.4,0,4.3,1.9,4.3,4.3V429c0,2.4-1.9,4.3-4.3,4.3h-11.8c-2.4,0-4.3-1.9-4.3-4.3v-97.9
                          C1264.6,328.8,1266.5,326.8,1268.9,326.8z"/>
                        <path className="st11" d="M1242.6,309.7h38.4c2.2,0,3.9,1.8,3.9,3.9v1.5c0,2.2-1.8,3.9-3.9,3.9h-38.4c-2.2,0-3.9-1.8-3.9-3.9v-1.5
                          C1238.7,311.5,1240.4,309.7,1242.6,309.7z"/>
                        <path className="st11" d="M1242.2,293.2h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                          C1240.2,294.1,1241.1,293.2,1242.2,293.2z"/>
                        <path className="st11" d="M1250.2,293.2h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                          C1248.2,294.1,1249.1,293.2,1250.2,293.2z"/>
                        <path className="st11" d="M1258.2,293.2h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                          C1256.2,294.1,1257.1,293.2,1258.2,293.2z"/>
                        <path className="st11" d="M1266.2,293.2h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                          C1264.2,294.1,1265,293.2,1266.2,293.2z"/>
                        <path className="st11" d="M1273.8,293.2h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                          C1271.8,294.1,1272.7,293.2,1273.8,293.2z"/>
                        <path className="st11" d="M1281.8,293.2h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                          C1279.8,294.1,1280.7,293.2,1281.8,293.2z"/>
                        <circle className="st6" cx="1233.6" cy="380.8" r="3.9"/>
                        <polygon className="st12" points="1220.9,417.4 1213.3,419.2 1208.5,447.8 1214.8,447.6 1236.5,447 1233.7,417.4 				"/>
                        <polygon className="st12" points="1298.6,417.4 1306.2,419.2 1310.9,447.8 1304.6,447.6 1283,447 1285.8,417.4 				"/>
                      </g>       
                      <g id="townhouse-2" className="st13">
                        <polyline className="st3" points="994.4,141.3 1014.4,109.4 1001.5,108.2 1006.4,91.7 
                          910.4,90.1 825.4,39.6 722.4,107 734.6,107 736.9,116.9"/>
                        <path className="st14" d="M746.9,162.9v414.8h249.8V156.9c0,0,6.4-6.8-3-6.8v-6.8c0,0,8.8-4.1,10.1-7.4s8.8-23,8.8-23l-18.9-0.7
                          l6.1-14.9l-92.5-0.7L824.9,48l-87.1,56l3.4,11.5l-15.5,0.7l23.6,24.3l-0.7,9.4l-6.1,3.4L746.9,162.9z"/>
                        <polyline className="st15" points="995.6,457.3 899.5,457.3 887.2,461.4 764.1,461.4 748.3,457.6 				"/>
                        <path className="st6" d="M974.9,577.6h-65.2c-2.4,0,5.7-140.1,5.7-140.1c0-2.4,1.9-4.3,4.3-4.3h55.2c2.4,0,4.3,1.9,4.3,4.3
                          C979.2,437.5,977.2,577.6,974.9,577.6z"/>
                        <path className="st7" d="M913.5,577.6h-13.2c-2.4,0-4.3-1.9-4.3-4.3l6.3-67.4c0-2.4-4.3-72.6-2-72.6h13.2c2.4,0,4.3,1.9,4.3,4.3
                          v135.7C917.8,575.6,915.9,577.6,913.5,577.6z"/>
                        <polyline className="st15" points="995.6,266.7 899.5,266.7 887.2,270.8 764.1,270.8 748.3,267 				"/>
                        <polyline className="st15" points="995.6,279.8 899.5,279.8 887.2,283.9 764.1,283.9 748.3,280.1 				"/>
                        <polyline className="st15" points="995.6,291.8 899.5,291.8 887.2,296 764.1,296 748.3,292.1 				"/>
                        <polyline className="st15" points="995.6,303.8 899.5,303.8 887.2,307.9 764.1,307.9 748.3,304.1 				"/>
                        <polyline className="st15" points="995.6,316 899.5,316 887.2,320.1 764.1,320.1 748.3,316.3 				"/>
                        <polyline className="st15" points="995.6,328.9 899.5,328.9 887.2,333.1 764.1,333.1 748.3,329.2 				"/>
                        <polyline className="st15" points="995.6,343.5 899.5,343.5 887.2,347.6 764.1,347.6 748.3,343.8 				"/>
                        <polyline className="st15" points="995.6,356.4 899.5,356.4 887.2,360.5 764.1,360.5 748.3,356.7 				"/>
                        <polyline className="st15" points="995.6,368.3 899.5,368.3 887.2,372.4 764.1,372.4 748.3,368.6 				"/>
                        <polyline className="st15" points="995.6,380.6 899.5,380.6 887.2,384.7 764.1,384.7 748.3,380.8 				"/>
                        <polyline className="st15" points="995.6,393.5 899.5,393.5 887.2,397.6 764.1,397.6 748.3,393.8 				"/>
                        <polyline className="st15" points="995.6,408 899.5,408 887.2,412.1 764.1,412.1 748.3,408.3 				"/>
                        <polyline className="st15" points="995.6,420.9 899.5,420.9 887.2,425 764.1,425 748.3,421.2 				"/>
                        <polyline className="st15" points="995.6,432.9 899.5,432.9 887.2,437 764.1,437 748.3,433.1 				"/>
                        <polyline className="st15" points="995.6,445.1 899.5,445.1 887.2,449.2 764.1,449.2 748.3,445.4 				"/>
                        <polyline className="st15" points="995.6,186.3 899.5,186.3 887.2,190.5 764.1,190.5 748.3,186.6 				"/>
                        <polyline className="st15" points="995.6,199.6 899.5,199.6 887.2,203.7 764.1,203.7 748.3,199.9 				"/>
                        <polyline className="st15" points="995.6,212.5 899.5,212.5 887.2,216.6 764.1,216.6 748.3,212.8 				"/>
                        <polyline className="st15" points="995.6,225.4 899.5,225.4 887.2,229.5 764.1,229.5 748.3,225.7 				"/>
                        <polyline className="st15" points="995.6,158.6 899.5,158.6 887.2,162.7 764.1,162.7 742.5,156 				"/>
                        <line className="st15" x1="853.5" y1="75" x2="798.2" y2="75"/>
                        <line className="st15" x1="843.1" y1="67.7" x2="807.4" y2="67.7"/>
                        <line className="st15" x1="975.6" y1="110.5" x2="920.3" y2="110.5"/>
                        <line className="st15" x1="965.2" y1="103.2" x2="929.5" y2="103.2"/>
                        <line className="st15" x1="920.3" y1="141.4" x2="975.6" y2="141.4"/>
                        <line className="st15" x1="786.9" y1="141.4" x2="857.2" y2="141.4"/>
                        <line className="st15" x1="800.1" y1="149.1" x2="845.6" y2="149.1"/>
                        <polyline className="st15" points="995.6,171.5 899.5,171.5 887.2,175.6 764.1,175.6 748.3,171.8 				"/>
                        <polyline className="st15" points="995.6,238.7 899.5,238.7 887.2,242.9 764.1,242.9 748.3,239 				"/>
                        <polyline className="st15" points="995.6,251.5 899.5,251.5 887.2,255.6 764.1,255.6 748.3,251.8 				"/>
                        <path className="st8" d="M795.5,184.1h20.2c2.4,0,4.3,1.9,4.3,4.3v78c0,2.4-1.9,4.3-4.3,4.3h-20.2c-2.4,0-4.3-1.9-4.3-4.3v-78
                          C791.2,186,793.1,184.1,795.5,184.1z"/>
                        <path className="st8" d="M831.6,184.1h20.2c2.4,0,4.3,1.9,4.3,4.3v78c0,2.4-1.9,4.3-4.3,4.3h-20.2c-2.4,0-4.3-1.9-4.3-4.3v-78
                          C827.3,186,829.2,184.1,831.6,184.1z"/>
                        <line className="st9" x1="792" y1="224.2" x2="819.2" y2="224.2"/>
                        <line className="st9" x1="828.2" y1="224.2" x2="855.6" y2="224.2"/>
                        <path className="st8" d="M795.5,336.5h20.2c2.4,0,4.3,1.9,4.3,4.3v78c0,2.4-1.9,4.3-4.3,4.3h-20.2c-2.4,0-4.3-1.9-4.3-4.3v-78
                          C791.2,338.4,793.1,336.5,795.5,336.5z"/>
                        <path className="st8" d="M831.6,336.5h20.2c2.4,0,4.3,1.9,4.3,4.3v78c0,2.4-1.9,4.3-4.3,4.3h-20.2c-2.4,0-4.3-1.9-4.3-4.3v-78
                          C827.3,338.4,829.2,336.5,831.6,336.5z"/>
                        <line className="st9" x1="792" y1="376.6" x2="819.2" y2="376.6"/>
                        <line className="st9" x1="828.2" y1="376.6" x2="855.6" y2="376.6"/>
                        <path className="st8" d="M934.5,193.7h20.2c2.4,0,4.3,1.9,4.3,4.3v68.3c0,2.4-1.9,4.3-4.3,4.3h-20.2c-2.4,0-4.3-1.9-4.3-4.3V198
                          C930.1,195.6,932.1,193.7,934.5,193.7z"/>
                        <line className="st9" x1="930" y1="229.3" x2="958.9" y2="229.3"/>
                        <path className="st16" d="M773.3,473.6h91.6c2.4,0,4.3,1.9,4.3,4.3v95c0,2.4-1.9,4.3-4.3,4.3h-91.6c-2.4,0-4.3-1.9-4.3-4.3v-95
                          C769,475.5,770.9,473.6,773.3,473.6z"/>
                        <path className="st9" d="M921.6,338.8h50.5c2.4,0,4.3,1.9,4.3,4.3v96.4c0,2.4-1.9,4.3-4.3,4.3h-50.5c-2.4,0-4.3-1.9-4.3-4.3v-96.4
                          C917.3,340.8,919.3,338.8,921.6,338.8z"/>
                        <line className="st9" x1="776" y1="484.7" x2="861.1" y2="484.7"/>
                        <line className="st9" x1="921.9" y1="454.5" x2="974.3" y2="454.5"/>
                        <line className="st9" x1="921.9" y1="465.6" x2="974.3" y2="465.6"/>
                        <line className="st9" x1="921.9" y1="476.1" x2="974.3" y2="476.1"/>
                        <line className="st9" x1="921.9" y1="487.2" x2="974.3" y2="487.2"/>
                        <line className="st9" x1="915.9" y1="498.1" x2="974.3" y2="498.1"/>
                        <line className="st9" x1="915.9" y1="509.2" x2="974.3" y2="509.2"/>
                        <line className="st9" x1="915.9" y1="521.9" x2="974.3" y2="521.9"/>
                        <line className="st9" x1="915.9" y1="533" x2="974.3" y2="533"/>
                        <line className="st9" x1="915.9" y1="545.5" x2="974.3" y2="545.5"/>
                        <line className="st9" x1="915.9" y1="557.6" x2="974.3" y2="557.6"/>
                        <line className="st9" x1="915.9" y1="571.8" x2="974.3" y2="571.8"/>
                        <line className="st9" x1="776" y1="497.1" x2="861.1" y2="497.1"/>
                        <line className="st9" x1="776" y1="509.6" x2="861.1" y2="509.6"/>
                        <line className="st9" x1="776" y1="521.9" x2="861.1" y2="521.9"/>
                        <line className="st9" x1="776" y1="534.4" x2="861.1" y2="534.4"/>
                        <line className="st9" x1="776" y1="546.8" x2="861.1" y2="546.8"/>
                        <line className="st9" x1="776" y1="557.5" x2="861.1" y2="557.5"/>
                        <line className="st9" x1="776" y1="570" x2="861.1" y2="570"/>
                        <path className="st10" d="M916.5,116.6h61.4c2.4,0,4.3,1.9,4.3,4.3v8.9c0,2.4-1.9,4.3-4.3,4.3h-61.4c-2.4,0-4.3-1.9-4.3-4.3v-8.9
                          C912.2,118.5,914.1,116.6,916.5,116.6z"/>
                        <path className="st10" d="M787,116.6h75.5c2.4,0,4.3,1.9,4.3,4.3v8.3c0,2.4-1.9,4.3-4.3,4.3H787c-2.4,0-4.3-1.9-4.3-4.3v-8.3
                          C782.7,118.5,784.6,116.6,787,116.6z"/>
                        <path className="st10" d="M789.9,86.5L789.9,86.5c2.4,0,4.3,1.9,4.3,4.3v7.6c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3v-7.6
                          C785.5,88.4,787.5,86.5,789.9,86.5z"/>
                        <path className="st10" d="M804.3,86.5L804.3,86.5c2.4,0,4.3,1.9,4.3,4.3v7.6c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3v-7.6
                          C800,88.4,801.9,86.5,804.3,86.5z"/>
                        <path className="st10" d="M818.9,86.5L818.9,86.5c2.4,0,4.3,1.9,4.3,4.3v7.6c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3v-7.6
                          C814.6,88.4,816.5,86.5,818.9,86.5z"/>
                        <path className="st10" d="M833.3,86.5L833.3,86.5c2.4,0,4.3,1.9,4.3,4.3v7.6c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3v-7.6
                          C829,88.4,830.9,86.5,833.3,86.5z"/>
                        <path className="st10" d="M847.3,86.5L847.3,86.5c2.4,0,4.3,1.9,4.3,4.3v7.6c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3v-7.6
                          C843,88.4,844.9,86.5,847.3,86.5z"/>
                        <path className="st10" d="M861.7,86.5L861.7,86.5c2.4,0,4.3,1.9,4.3,4.3v7.6c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3v-7.6
                          C857.4,88.4,859.4,86.5,861.7,86.5z"/>
                        <path className="st17" d="M894,309.6l8,7.3l2.4,129.1H987V317.7l8.1-8.5L949.4,275c0,0,3.9-10.2-4.5-10.2c-6.2,0-7.6,5.2-5.2,10.8
                          C913.1,294.4,894,309.6,894,309.6z"/>
                        <path className="st6" d="M926.4,327.3h11.8c2.4,0,4.3,1.9,4.3,4.3v97.9c0,2.4-1.9,4.3-4.3,4.3h-11.8c-2.4,0-4.3-1.9-4.3-4.3v-97.9
                          C922.1,329.2,924.1,327.3,926.4,327.3z"/>
                        <path className="st6" d="M952.5,327.3h11.8c2.4,0,4.3,1.9,4.3,4.3v97.9c0,2.4-1.9,4.3-4.3,4.3h-11.8c-2.4,0-4.3-1.9-4.3-4.3v-97.9
                          C948.2,329.2,950.2,327.3,952.5,327.3z"/>
                        <path className="st11" d="M926.2,310.1h38.4c2.2,0,3.9,1.8,3.9,3.9v1.5c0,2.2-1.8,3.9-3.9,3.9h-38.4c-2.2,0-3.9-1.8-3.9-3.9V314
                          C922.3,311.9,924.1,310.1,926.2,310.1z"/>
                        <path className="st11" d="M925.8,293.6h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                          C923.8,294.5,924.7,293.6,925.8,293.6z"/>
                        <path className="st11" d="M933.8,293.6h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                          C931.8,294.5,932.7,293.6,933.8,293.6z"/>
                        <path className="st11" d="M941.8,293.6h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                          C939.8,294.5,940.7,293.6,941.8,293.6z"/>
                        <path className="st11" d="M949.8,293.6h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                          C947.8,294.5,948.7,293.6,949.8,293.6z"/>
                        <path className="st11" d="M957.5,293.6h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                          C955.5,294.5,956.4,293.6,957.5,293.6z"/>
                        <path className="st11" d="M965.4,293.6h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                          C963.4,294.5,964.3,293.6,965.4,293.6z"/>
                        <circle className="st6" cx="917.2" cy="381.2" r="3.9"/>
                        <polygon className="st12" points="904.5,417.8 896.9,419.6 892.2,448.2 898.4,448 920.1,447.5 917.3,417.8 				"/>
                        <path className="st7" d="M972.2,577.6h13.2c2.4,0,4.3-1.9,4.3-4.3l-6.3-67.4c0-2.4,4.3-72.6,2-72.6h-13.2c-2.4,0-4.3,1.9-4.3,4.3
                          v135.7C967.9,575.7,969.9,577.6,972.2,577.6z"/>
                        <polygon className="st12" points="982.2,417.8 989.8,419.6 994.6,448.2 988.3,448 966.6,447.5 969.4,417.8 				"/>
                        <line className="st15" x1="930.7" y1="148.7" x2="966.5" y2="148.7"/>
                      </g>
                      <g id="townhouse-1" className="st18">
                        <polyline className="st3" points="686.7,141.3 706.7,109.4 693.9,108.2 698.7,91.7 602.7,90.1 517.7,39.6 414.7,107 426.9,107 
                          429.2,116.9"/>
                        <path className="st19" d="M439.2,162.9v414.8H689V156.9c0,0,6.4-6.8-3-6.8v-6.8c0,0,8.8-4.1,10.1-7.4c1.4-3.4,8.8-23,8.8-23
                          l-18.9-0.7l6-14.8l-92.5-0.7l-82.4-48.6l-87.1,56l3.4,11.5l-15.5,0.7l23.6,24.3l-0.7,9.4l-6.1,3.4L439.2,162.9z"/>
                        <polyline className="st5" points="687.9,457.3 591.9,457.3 579.5,461.4 456.4,461.4 440.6,457.6 				"/>
                        <path className="st6" d="M667.2,577.6h-65.1c-2.4,0,5.7-140.1,5.7-140.1c0-2.4,1.9-4.3,4.3-4.3h55.2c2.4,0,4.3,1.9,4.3,4.3
                          C671.5,437.5,669.6,577.6,667.2,577.6z"/>
                        <path className="st7" d="M605.8,577.6h-13.2c-2.4,0-4.3-1.9-4.3-4.3l6.3-67.4c0-2.4-4.3-72.6-2-72.6h13.2c2.4,0,4.3,1.9,4.3,4.3
                          v135.7C610.1,575.6,608.2,577.6,605.8,577.6z"/>
                        <polyline className="st5" points="687.9,266.7 591.9,266.7 579.5,270.8 456.4,270.8 440.6,267 				"/>
                        <polyline className="st5" points="687.9,279.8 591.9,279.8 579.5,283.9 456.4,283.9 440.6,280.1 				"/>
                        <polyline className="st5" points="687.9,291.8 591.9,291.8 579.5,296 456.4,296 440.6,292.1 				"/>
                        <polyline className="st5" points="687.9,303.8 591.9,303.8 579.5,307.9 456.4,307.9 440.6,304.1 				"/>
                        <polyline className="st5" points="687.9,316 591.9,316 579.5,320.1 456.4,320.1 440.6,316.3 				"/>
                        <polyline className="st5" points="687.9,328.9 591.9,328.9 579.5,333.1 456.4,333.1 440.6,329.2 				"/>
                        <polyline className="st5" points="687.9,343.5 591.9,343.5 579.5,347.6 456.4,347.6 440.6,343.8 				"/>
                        <polyline className="st5" points="687.9,356.4 591.9,356.4 579.5,360.5 456.4,360.5 440.6,356.7 				"/>
                        <polyline className="st5" points="687.9,368.3 591.9,368.3 579.5,372.4 456.4,372.4 440.6,368.6 				"/>
                        <polyline className="st5" points="687.9,380.6 591.9,380.6 579.5,384.7 456.4,384.7 440.6,380.8 				"/>
                        <polyline className="st5" points="687.9,393.5 591.9,393.5 579.5,397.6 456.4,397.6 440.6,393.8 				"/>
                        <polyline className="st5" points="687.9,408 591.9,408 579.5,412.1 456.4,412.1 440.6,408.3 				"/>
                        <polyline className="st5" points="687.9,420.9 591.9,420.9 579.5,425 456.4,425 440.6,421.2 				"/>
                        <polyline className="st5" points="687.9,432.9 591.9,432.9 579.5,437 456.4,437 440.6,433.1 				"/>
                        <polyline className="st5" points="687.9,445.1 591.9,445.1 579.5,449.2 456.4,449.2 440.6,445.4 				"/>
                        <polyline className="st5" points="687.9,186.3 591.9,186.3 579.5,190.5 456.4,190.5 440.6,186.6 				"/>
                        <polyline className="st5" points="687.9,199.6 591.9,199.6 579.5,203.7 456.4,203.7 440.6,199.9 				"/>
                        <polyline className="st5" points="687.9,212.5 591.9,212.5 579.5,216.6 456.4,216.6 440.6,212.8 				"/>
                        <polyline className="st5" points="687.9,225.4 591.9,225.4 579.5,229.5 456.4,229.5 440.6,225.7 				"/>
                        <polyline className="st5" points="687.9,158.6 591.9,158.6 579.5,162.7 456.4,162.7 434.9,156 				"/>
                        <line className="st5" x1="545.8" y1="75" x2="490.6" y2="75"/>
                        <line className="st5" x1="535.4" y1="67.7" x2="499.7" y2="67.7"/>
                        <line className="st5" x1="667.9" y1="110.5" x2="612.7" y2="110.5"/>
                        <line className="st5" x1="657.5" y1="103.2" x2="621.8" y2="103.2"/>
                        <line className="st5" x1="612.7" y1="141.4" x2="667.9" y2="141.4"/>
                        <line className="st5" x1="623" y1="148.7" x2="658.8" y2="148.7"/>
                        <line className="st5" x1="479.2" y1="141.4" x2="549.6" y2="141.4"/>
                        <line className="st5" x1="492.4" y1="149.1" x2="538" y2="149.1"/>
                        <polyline className="st5" points="687.9,171.5 591.9,171.5 579.5,175.6 456.4,175.6 440.6,171.8 				"/>
                        <polyline className="st5" points="687.9,238.7 591.9,238.7 579.5,242.9 456.4,242.9 440.6,239 				"/>
                        <polyline className="st5" points="687.9,251.5 591.9,251.5 579.5,255.6 456.4,255.6 440.6,251.8 				"/>
                        <path className="st8" d="M487.8,184.1H508c2.4,0,4.3,1.9,4.3,4.3v78c0,2.4-1.9,4.3-4.3,4.3h-20.2c-2.4,0-4.3-1.9-4.3-4.3v-78
                          C483.5,186,485.5,184.1,487.8,184.1z"/>
                        <path className="st8" d="M523.9,184.1h20.2c2.4,0,4.3,1.9,4.3,4.3v78c0,2.4-1.9,4.3-4.3,4.3h-20.2c-2.4,0-4.3-1.9-4.3-4.3v-78
                          C519.6,186,521.6,184.1,523.9,184.1z"/>
                        <line className="st9" x1="484.3" y1="224.2" x2="511.5" y2="224.2"/>
                        <line className="st9" x1="520.5" y1="224.2" x2="548" y2="224.2"/>
                        <path className="st8" d="M487.8,336.5H508c2.4,0,4.3,1.9,4.3,4.3v78c0,2.4-1.9,4.3-4.3,4.3h-20.2c-2.4,0-4.3-1.9-4.3-4.3v-78
                          C483.5,338.4,485.5,336.5,487.8,336.5z"/>
                        <path className="st8" d="M523.9,336.5h20.2c2.4,0,4.3,1.9,4.3,4.3v78c0,2.4-1.9,4.3-4.3,4.3h-20.2c-2.4,0-4.3-1.9-4.3-4.3v-78
                          C519.6,338.4,521.6,336.5,523.9,336.5z"/>
                        <line className="st9" x1="484.3" y1="376.6" x2="511.5" y2="376.6"/>
                        <line className="st9" x1="520.5" y1="376.6" x2="548" y2="376.6"/>
                        <path className="st8" d="M626.8,193.7H647c2.4,0,4.3,1.9,4.3,4.3v68.3c0,2.4-1.9,4.3-4.3,4.3h-20.2c-2.4,0-4.3-1.9-4.3-4.3V198
                          C622.5,195.6,624.4,193.7,626.8,193.7z"/>
                        <line className="st9" x1="622.3" y1="229.3" x2="651.2" y2="229.3"/>
                        <path className="st20" d="M465.6,473.6h91.6c2.4,0,4.3,1.9,4.3,4.3v95c0,2.4-1.9,4.3-4.3,4.3h-91.6c-2.4,0-4.3-1.9-4.3-4.3v-95
                          C461.3,475.5,463.2,473.6,465.6,473.6z"/>
                        <path className="st9" d="M614,338.8h50.5c2.4,0,4.3,1.9,4.3,4.3v96.4c0,2.4-1.9,4.3-4.3,4.3H614c-2.4,0-4.3-1.9-4.3-4.3v-96.4
                          C609.6,340.8,611.6,338.8,614,338.8z"/>
                        <line className="st9" x1="468.3" y1="484.7" x2="553.4" y2="484.7"/>
                        <line className="st9" x1="614.2" y1="454.5" x2="666.6" y2="454.5"/>
                        <line className="st9" x1="614.2" y1="465.6" x2="666.6" y2="465.6"/>
                        <line className="st9" x1="614.2" y1="476.1" x2="666.6" y2="476.1"/>
                        <line className="st9" x1="614.2" y1="487.2" x2="666.6" y2="487.2"/>
                        <line className="st9" x1="608.3" y1="498.1" x2="666.6" y2="498.1"/>
                        <line className="st9" x1="608.3" y1="509.2" x2="666.6" y2="509.2"/>
                        <line className="st9" x1="608.3" y1="521.9" x2="666.6" y2="521.9"/>
                        <line className="st9" x1="608.3" y1="533" x2="666.6" y2="533"/>
                        <line className="st9" x1="608.3" y1="545.5" x2="666.6" y2="545.5"/>
                        <line className="st9" x1="608.3" y1="557.6" x2="666.6" y2="557.6"/>
                        <line className="st9" x1="608.3" y1="571.8" x2="666.6" y2="571.8"/>
                        <line className="st9" x1="468.3" y1="497.1" x2="553.4" y2="497.1"/>
                        <line className="st9" x1="468.3" y1="509.6" x2="553.4" y2="509.6"/>
                        <line className="st9" x1="468.3" y1="521.9" x2="553.4" y2="521.9"/>
                        <line className="st9" x1="468.3" y1="534.4" x2="553.4" y2="534.4"/>
                        <line className="st9" x1="468.3" y1="546.8" x2="553.4" y2="546.8"/>
                        <line className="st9" x1="468.3" y1="557.5" x2="553.4" y2="557.5"/>
                        <line className="st9" x1="468.3" y1="570" x2="553.4" y2="570"/>
                        <path className="st10" d="M608.8,116.6h61.5c2.4,0,4.3,1.9,4.3,4.3v8.9c0,2.4-1.9,4.3-4.3,4.3h-61.5c-2.4,0-4.3-1.9-4.3-4.3v-8.9
                          C604.5,118.5,606.4,116.6,608.8,116.6z"/>
                        <path className="st10" d="M479.3,116.6h75.5c2.4,0,4.3,1.9,4.3,4.3v8.3c0,2.4-1.9,4.3-4.3,4.3h-75.5c-2.4,0-4.3-1.9-4.3-4.3v-8.3
                          C475,118.5,477,116.6,479.3,116.6z"/>
                        <path className="st10" d="M482.2,86.5L482.2,86.5c2.4,0,4.3,1.9,4.3,4.3v7.6c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3v-7.6
                          C477.9,88.4,479.8,86.5,482.2,86.5z"/>
                        <path className="st10" d="M496.6,86.5L496.6,86.5c2.4,0,4.3,1.9,4.3,4.3v7.6c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3v-7.6
                          C492.3,88.4,494.2,86.5,496.6,86.5z"/>
                        <path className="st10" d="M511.2,86.5L511.2,86.5c2.4,0,4.3,1.9,4.3,4.3v7.6c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3v-7.6
                          C506.9,88.4,508.8,86.5,511.2,86.5z"/>
                        <path className="st10" d="M525.6,86.5L525.6,86.5c2.4,0,4.3,1.9,4.3,4.3v7.6c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3v-7.6
                          C521.3,88.4,523.3,86.5,525.6,86.5z"/>
                        <path className="st10" d="M539.6,86.5L539.6,86.5c2.4,0,4.3,1.9,4.3,4.3v7.6c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3v-7.6
                          C535.3,88.4,537.2,86.5,539.6,86.5z"/>
                        <path className="st10" d="M554.1,86.5L554.1,86.5c2.4,0,4.3,1.9,4.3,4.3v7.6c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3v-7.6
                          C549.7,88.4,551.7,86.5,554.1,86.5z"/>
                        <path className="st10" d="M586.4,309.6l8,7.3l2.4,129.1h82.6V317.7l8.1-8.5L641.8,275c0,0,4-10.2-4.5-10.2c-6.2,0-7.6,5.2-5.2,10.8
                          C605.4,294.4,586.4,309.6,586.4,309.6z"/>
                        <path className="st6" d="M618.8,327.3h11.8c2.4,0,4.3,1.9,4.3,4.3v97.9c0,2.4-1.9,4.3-4.3,4.3h-11.8c-2.4,0-4.3-1.9-4.3-4.3v-97.9
                          C614.5,329.2,616.4,327.3,618.8,327.3z"/>
                        <path className="st6" d="M644.9,327.3h11.8c2.4,0,4.3,1.9,4.3,4.3v97.9c0,2.4-1.9,4.3-4.3,4.3h-11.8c-2.4,0-4.3-1.9-4.3-4.3v-97.9
                          C640.5,329.2,642.5,327.3,644.9,327.3z"/>
                        <path className="st11" d="M618.5,310.1h38.4c2.2,0,3.9,1.8,3.9,3.9v1.5c0,2.2-1.8,3.9-3.9,3.9h-38.4c-2.2,0-3.9-1.8-3.9-3.9V314
                          C614.6,311.9,616.4,310.1,618.5,310.1z"/>
                        <path className="st11" d="M618.2,293.6h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                          C616.2,294.5,617.1,293.6,618.2,293.6z"/>
                        <path className="st11" d="M626.1,293.6h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                          C624.1,294.5,625,293.6,626.1,293.6z"/>
                        <path className="st11" d="M634.1,293.6h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                          C632.1,294.5,633,293.6,634.1,293.6z"/>
                        <path className="st11" d="M642.1,293.6h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                          C640.1,294.5,641,293.6,642.1,293.6z"/>
                        <path className="st11" d="M649.8,293.6h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                          C647.8,294.5,648.7,293.6,649.8,293.6z"/>
                        <path className="st11" d="M657.7,293.6h0.8c1.1,0,2,0.9,2,2v4.9c0,1.1-0.9,2-2,2h-0.8c-1.1,0-2-0.9-2-2v-4.9
                          C655.7,294.5,656.6,293.6,657.7,293.6z"/>
                        <circle className="st6" cx="609.5" cy="381.2" r="3.9"/>
                        <polygon className="st12" points="596.8,417.8 589.2,419.6 584.5,448.2 590.8,448 612.4,447.5 609.6,417.8 				"/>
                        <path className="st7" d="M664.6,577.6h13.2c2.4,0,4.3-1.9,4.3-4.3l-6.3-67.4c0-2.4,4.3-72.6,2-72.6h-13.2c-2.4,0-4.3,1.9-4.3,4.3
                          v135.7C660.3,575.7,662.2,577.6,664.6,577.6z"/>
                        <polygon className="st12" points="674.5,417.8 682.1,419.6 686.9,448.2 680.6,448 658.9,447.5 661.7,417.8 				"/>
                      </g>
                    </g>
                    <g id="trees">
                      <g id="right-tree">
                        <circle className="st21" cx="990.2" cy="515.4" r="24.4"/>
                        <circle className="st21" cx="998.9" cy="493.5" r="24.4"/>
                        <circle className="st21" cx="1023.6" cy="480.4" r="24.4"/>
                        <circle className="st21" cx="1043" cy="512.6" r="24.4"/>
                        <circle className="st21" cx="1019.3" cy="511.9" r="24.4"/>
                        <line className="st22" x1="1017" y1="477.3" x2="1017" y2="577.3"/>
                        <line className="st22" x1="996.8" y1="488.9" x2="1016.7" y2="508.8"/>
                        <line className="st22" x1="1037.6" y1="505.1" x2="1017.7" y2="525"/>
                      </g>
                      <g id="left-tall-tree">
                        <ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 118.649 1128.4586)" className="st23" cx="722.5" cy="494.5" rx="33.1" ry="33.1"/>
                        <ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 157.9179 1115.2042)" className="st23" cx="734.4" cy="464.8" rx="33.1" ry="33.1"/>
                        <circle className="st21" cx="767.9" cy="447" r="33.1"/>
                        <circle className="st21" cx="794.1" cy="490.7" r="33.1"/>
                        <circle className="st21" cx="761.9" cy="489.8" r="33.1"/>
                        <line className="st22" x1="759" y1="442.8" x2="759" y2="578.4"/>
                        <line className="st22" x1="731.5" y1="458.5" x2="758.5" y2="485.5"/>
                        <line className="st22" x1="786.9" y1="480.6" x2="759.9" y2="507.6"/>
                      </g>
                      <g id="left-short-tree">
                        <circle className="st21" cx="686.7" cy="515.4" r="24.4"/>
                        <circle className="st21" cx="695.5" cy="493.5" r="24.4"/>
                        <circle className="st21" cx="720.2" cy="480.4" r="24.4"/>
                        <circle className="st21" cx="739.6" cy="512.6" r="24.4"/>
                        <ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 95.7777 1136.4639)" className="st23" cx="715.8" cy="511.9" rx="24.4" ry="24.4"/>
                        <line className="st22" x1="713.6" y1="477.3" x2="713.6" y2="577.3"/>
                        <line className="st22" x1="693.3" y1="488.9" x2="713.2" y2="508.8"/>
                        <line className="st22" x1="734.2" y1="505.1" x2="714.3" y2="525"/>
                      </g>
                      <rect id="tree-cover" x="350" y="586" width="838.41" height="131.7"/>
                    </g>
                  </svg>
                </div>
              
                <div id="over-mask-lion-container">
                  <div>
                    <svg 
                      id="mask-lion"
                      version="1.1" 
                      xmlns="http://www.w3.org/2000/svg" 
                      xmlnsXlink="http://www.w3.org/1999/xlink" 
                      x="0px" y="0px"
                      viewBox="0 0 1400 850"
                      className={playStreet>0 ? 'play' : ''}
                    >
                      <path className="st17" d="M591.1,459.3c0,0,8.7,1.5,11.9-5s-1.7-12.4-1.7-12.4v4.2c0,0-3.7-6-5.5-6.5c0.5,1-0.3,4.7-0.3,4.7
                        s-3.7-6-2.7-9.5c1.7-4.2-3.5,3-3.5,8c-0.7-1.7-2.2-4.5-2.2-4.5s-0.5,10.7,0.2,12.2c-1.5-0.4-2.9-1.2-4-2.2
                        C583.4,448.4,584.9,457.6,591.1,459.3z"/>
                      <path className="st45" d="M619.7,476.5c0,0-20.9-1.8-23.1-13.4c-2.2-11.3,2-9,2-9s1.7,0.6,1.2,5.7c-0.4,5.1,5.2,10.2,20.4,12.4
                        C620.6,473.7,620.4,475.2,619.7,476.5z"/>
                      <path d="M619.7,476.7L619.7,476.7c-3.7-0.4-7.3-1.2-10.8-2.4c-7.3-2.5-11.6-6.4-12.5-11.2c-1-5.1-0.8-8.1,0.5-9
                        c0.5-0.3,1.2-0.4,1.7-0.1c0,0,1.7,0.7,1.3,5.9c-0.3,3.4,2,9.6,20.3,12.3c0.1,0,0.1,0.1,0.1,0.1c0.4,1.5,0.2,3-0.5,4.3
                        C619.8,476.6,619.8,476.7,619.7,476.7z M597.8,454.1c-0.3,0-0.5,0.1-0.7,0.2c-0.8,0.6-1.5,2.6-0.3,8.8c2.1,11,21.4,13.2,22.9,13.3
                        c0.6-1.2,0.7-2.6,0.5-3.9c-18.4-2.7-20.7-9-20.4-12.6c0.4-4.9-1.1-5.6-1.1-5.6C598.3,454.1,598.1,454.1,597.8,454.1z"/>
                      <path className="st45" d="M624.6,439.2c0,0-4.3,16.3-4.3,23c-2.5,9.7,0.3,22.3,1.6,28c-4.7,0.3-12.3,3.5-10.2,10.7
                        c0.7,0.5,1.4,0.9,2.2,1.3c0,0,3,1.7,5.7,0.5c1.2,1.7,3.2,1.5,5.5,1.2s6.5-0.8,8.5-2c0.3-3.1-0.2-6.2-1.5-9c5.3-8.1,8-17.5,8-27.1"/>
                      <path d="M622.8,504.2c-1.3,0.1-2.5-0.4-3.3-1.4c-2.7,1.1-5.5-0.4-5.7-0.5c-0.8-0.3-1.6-0.7-2.3-1.3c0,0,0,0-0.1-0.1
                        c-0.7-2.2-0.4-4.5,0.9-6.4c2-2.8,6.1-4.3,9.2-4.5v0c-1.3-5.9-4-18.2-1.5-27.8c0-6.7,4.3-22.9,4.3-23s0.1-0.1,0.2-0.1
                        c0.1,0,0.1,0.1,0.1,0.2c0,0.2-4.3,16.4-4.3,23l0,0c-2.4,9.5,0.2,21.8,1.5,27.6l0.1,0.3v0.1l-0.1,0.1c-3.1,0.2-7.2,1.6-9.2,4.4
                        c-1.2,1.8-1.6,4-0.9,6.1c0.7,0.5,1.4,0.9,2.2,1.2l0,0c0,0,3,1.7,5.6,0.5c0.1,0,0.1,0,0.2,0c1.2,1.6,3.1,1.4,5.2,1.2h0.1
                        c1.9-0.2,6.3-0.7,8.3-1.9c0.3-3-0.2-6-1.5-8.8c0,0,0-0.1,0-0.2c5.2-8,7.9-17.4,7.9-27c0-0.1,0.1-0.2,0.1-0.2l0,0l0,0
                        c0.1,0,0.2,0.1,0.2,0.2l0,0c0,9.6-2.7,19-7.9,27.1c1.3,2.8,1.8,5.9,1.5,9c0,0,0,0.1-0.1,0.1c-2.1,1.3-6.6,1.8-8.5,2h-0.1
                        C624.3,504.1,623.5,504.2,622.8,504.2z"/>
                      <path className="st45" d="M649.2,435.9c0,0,14-5.4,16.2-7c0.4-1.3-2-5.3,1.9-5.7c0,0,2.4-0.3,2.6,3c0,0-0.8-5.7,3-4.5
                        c1.7,1,0.9,3.1,0.9,3.1s2.7-2.5,3.8-1.3c1.2,1.2,0.1,3.2-0.5,4c-0.4,0.6-0.9,1.2-1.4,1.8c0,0,3.1-1.8,3.5,0.7
                        c0.2,1.1-1.8,4.1-6.2,4c-7.1,3.8-15.4,13.3-23.8,15.1"/>
                      <path d="M649.3,449.2c-0.1,0-0.1,0-0.1-0.1s0-0.1,0.1-0.2l0,0c5.2-1.1,10.3-5.1,15.2-9c2.7-2.3,5.5-4.3,8.6-6.1h0.1
                        c1.9,0.2,3.8-0.5,5.2-1.9c0.5-0.5,0.8-1.1,0.9-1.8c0-0.4-0.3-0.8-0.6-1c-1-0.5-2.6,0.5-2.6,0.5c-0.1,0-0.1,0-0.2,0
                        c0-0.1,0-0.1,0-0.2c0,0,0.8-0.9,1.4-1.8c0.3-0.4,1.7-2.6,0.5-3.8c-1-1.1-3.6,1.3-3.6,1.4c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1,0-0.2
                        c0-0.1,0.7-2-0.8-2.9c-0.6-0.3-1.4-0.2-1.9,0.2c-1,0.8-0.9,2.8-0.8,3.7c0,0.1,0,0.3,0,0.4s-0.1,0.2-0.1,0.2l0,0
                        c-0.1,0-0.1,0-0.2-0.1l0,0c0-0.2,0-0.3,0-0.5c-0.1-0.8-0.4-1.5-1-2c-0.4-0.3-0.9-0.5-1.4-0.4c-0.7,0-1.3,0.3-1.8,0.8
                        c-0.6,0.8-0.4,2.1-0.2,3.1c0.2,0.5,0.2,1.1,0.1,1.6v0.1c-2.2,1.6-15.7,6.8-16.2,7.1c-0.1,0-0.2,0-0.2-0.1s0-0.2,0.1-0.2
                        s13.8-5.3,16.1-7c0-0.5,0-1-0.1-1.5c-0.2-1.1-0.5-2.5,0.2-3.4c0.5-0.6,1.2-0.9,2-0.9c0.6-0.1,1.2,0.1,1.6,0.5
                        c0.3,0.3,0.6,0.6,0.8,1c0-1,0.3-2,1-2.7c0.6-0.5,1.5-0.6,2.2-0.2l0,0c1,0.6,1.4,1.7,1.1,2.8c0.8-0.7,2.7-2,3.7-1s0.5,2.9-0.5,4.2
                        c-0.3,0.4-0.6,0.8-1,1.3c0.7-0.3,1.5-0.4,2.2-0.2c0.5,0.3,0.8,0.7,0.8,1.3c0,0.8-0.4,1.5-0.9,2.1c-1.4,1.4-3.4,2.2-5.4,2
                        c-3,1.8-5.8,3.8-8.5,6C659.7,444,654.6,448.1,649.3,449.2L649.3,449.2z"/>
                      <path className="st45" d="M651.2,437.5c0,0,9.8,25.5-12.4,39"/>
                      <path d="M638.7,476.7c-0.1,0-0.1,0-0.1-0.1s0-0.2,0-0.2c8.8-5,14.3-14.3,14.5-24.4c0.2-4.9-0.5-9.8-2.1-14.4c0-0.1,0-0.2,0.1-0.2
                        l0,0c0.1,0,0.2,0,0.2,0.1c1.6,4.7,2.3,9.6,2.1,14.5C653.2,462.2,647.7,471.5,638.7,476.7L638.7,476.7z"/>
                      <path className="st45" d="M629,455.3l1.7-13.8l9.6-16.7c0,0,4.8,6.3,10.3,6.3s0.6,5.7,0.6,5.7l-0.5,5.9l0.5,1.6l1.4,1l-0.7,9.3
                        l-9.6,18.9l-3.9,3.3l-4.7-0.2L629,455.3z"/>
                      <path className="st17" d="M656.7,398.2c2.9-3.6,6.2-7.9,3.9-12c-1.1,1.2-2.2,2.5-3.1,3.9c-0.3-3.5-1.1-7-2.4-10.3
                        c-0.1,1.3-0.4,2.6-0.9,3.8c-2.1-4.4-6.4-8-10.3-11c4.6,6.2,3,6.6,4.7,9.9c-6.6-4.4-14.4-6.6-22.3-6.3l5.7,2.2
                        c-5.6,0.2-10.6,3.3-15.4,6.3c1.3-0.5,2.7-0.2,3.7,0.7c-2.8,1-5.5,2.4-8,4.1s-4.2,4.4-4.9,7.3c0.9-1.1,2.1-1.9,3.5-2.3
                        c-2.5,4.7-4.7,9.7-6.4,14.7c1.5-1.9,3.3-3.7,5.2-5.3c-0.5,6.4-6.5,11.7-6.1,18.1l2.9-4.1c0.1,4.7,1.1,9.4,3,13.8
                        c0.4,0.8,1.3,1.8,2,1.2c0.2-1.3,0-2.5-0.4-3.7c1.7,4.6,4.2,8.8,7.3,12.6c-0.6-2-1-4-1.2-6c3.3,1.3,6.4,3,9.2,5.1
                        c-0.4-3.2-1.8-6.4-1.8-9.6s0.5-3.1,2.9-5.3c2.6-2.2,6.4-2.1,8.9,0.2c2.7,2.7,1.7,3.1,2.4,6.8c0.4,1.8,3.5,5.5,4.6,6.7
                        c0.3,0.4,0.6-2.6,1-2.2c0.8,0.8,1.5,0.7,2.3,1.5c1,1,0.4-2.8,1.2-2.8c2.2-0.1,2.2,0.2,5,2.9c0.8-2,5.8-7.7,5.9-9.8
                        c0,0-10.9,3-14.6-2c-4.7-6.3-4.7-6.8-8.6-9.6c-1.6-1.2-2.1-2.9,2-8.5c-2.7,0.8-5.5-1.1-5.5-2.4c0-5-1-3.9,2.6-10.4
                        C637.5,391.2,650,388.5,656.7,398.2z"/>
                      <path className="st45" d="M634.5,460.2c4.1,3.5,1.3,5.6,1.3,5.6s-1.9,2-4.7-3.3c0.1,1.5,1.4,5.2-0.8,5.8c-3.5,0.9-3-1.3-3.8-4.7
                        c0,1.5,1,4-1.7,4c-5-1-1.5-6.4-2.3-9.1c-1.3-4.3-1-8.6-1.3-13c-0.2-2.4,0.7-17,4.2-21.3s11-1.9,11-1.9c5.8,2.8,3.6,14.8-2.1,20.8
                        c-0.5,3.8-0.5,7.7,0,11.5c1.3,0.9,4.7,2.5,4.3,4.8c0,0-0.2,1.7-2.6,1.8"/>
                      <path d="M629,468.6c-0.4,0-0.8-0.1-1.1-0.3c-0.7-0.4-0.9-1.3-1.1-2.4c0,0.5-0.1,0.9-0.4,1.3c-0.4,0.4-1,0.6-1.6,0.6l0,0
                        c-3.4-0.7-3-3.5-2.6-5.9c0.3-1.1,0.3-2.2,0.1-3.3c-0.8-3-1.2-6.1-1.1-9.2c0-1.3-0.1-2.6-0.2-3.9c-0.2-2.4,0.7-17.1,4.2-21.4
                        s11.1-1.9,11.2-1.9c1.8,1,3.1,2.8,3.3,4.9c0.9,4.9-1.5,12-5.3,16.1c-0.5,3.8-0.5,7.6,0,11.4c0.2,0.2,0.5,0.3,0.8,0.5
                        c1.5,1,3.9,2.5,3.5,4.4c0,0-0.3,1.7-2.6,1.9c0.5,0.7,0.9,1.5,0.9,2.4c0,0.8-0.4,1.5-1,2c-0.4,0.3-0.8,0.5-1.3,0.4
                        c-1-0.1-2.1-1.2-3.2-3l0.1,0.3c0.3,1.7,0.8,4.3-1.1,4.8C629.9,468.6,629.5,468.6,629,468.6z M626.5,463.5c0.1,0,0.1,0,0.1,0.1
                        c0.2,0.7,0.3,1.3,0.4,1.9c0.2,1.3,0.4,2.2,1,2.6c0.7,0.3,1.5,0.3,2.2,0c1.6-0.4,1.2-2.8,0.9-4.5c-0.1-0.5-0.1-0.9-0.2-1.2
                        c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.1,0,0.1,0.1c1.5,2.8,2.6,3.5,3.4,3.6c0.4,0.1,0.8,0,1.1-0.3c0.5-0.4,0.9-1.1,0.9-1.8
                        c0-1.1-0.7-2.3-2.2-3.6c-0.1-0.1-0.1-0.2,0-0.2c0.1-0.1,0.2-0.1,0.2,0c0.4,0.4,0.8,0.8,1.2,1.2l0,0c2.2-0.1,2.4-1.7,2.4-1.7
                        c0.3-1.8-1.9-3.2-3.4-4.1c-0.3-0.2-0.6-0.4-0.8-0.5l-0.1-0.1c-0.5-3.8-0.5-7.7,0-11.5v-0.1c3.8-4.1,6.2-11,5.3-15.9
                        c-0.2-2-1.4-3.7-3.1-4.7c0,0-7.4-2.3-10.8,1.8c-1.8,2.2-2.9,7.4-3.4,11.3c-0.5,3.3-0.7,6.6-0.7,9.9c0.1,1.3,0.1,2.6,0.2,3.9
                        c0,3.1,0.3,6.1,1.1,9.1c0.2,1.1,0.2,2.3-0.1,3.4c-0.4,2.4-0.8,4.9,2.3,5.6c0.5,0.1,1-0.1,1.4-0.5c0.5-0.5,0.4-1.5,0.2-2.4
                        c0-0.4-0.1-0.7-0.1-1c0-0.1,0.1-0.1,0.1-0.2L626.5,463.5z"/>
                      <path className="st4" d="M660,421.1c0.8,3.8,0.2,6.2-0.9,7.4s-4.4,3.5-10.5,1.7s-5.4-1.6-8.6-6.8s-5.1-5.2-6-6.7s1.5-5,2.7-6.4
                        s-0.3-0.8-2-0.8s-2.7-1.2-3.3-3.5s1.1-5.8,1.1-5.8s2.1-5.7,6.6-7.6c4.5-1.8,9.3-0.4,10.2-0.2c1.7,0.4,7,3.8,7,5.2s-0.7,4.1-0.6,6.1
                        "/>
                      <path d="M652.9,431.1c-1.5,0-2.9-0.3-4.4-0.7c-5.5-1.6-5.5-1.7-7.8-5.4l-0.9-1.4c-1.2-2.1-2.7-3.9-4.6-5.3c-0.5-0.4-1-0.8-1.4-1.4
                        c-1-1.7,1.7-5.4,2.8-6.5c0.4-0.5,0.4-0.6,0.4-0.6c-0.3-0.1-0.7-0.1-1,0c-0.4,0-0.8,0.1-1.3,0.1c-1.7,0-2.8-1.2-3.5-3.6
                        s1-5.7,1.1-5.9c0.4-1.1,1-2.1,1.6-3.1c1.2-2,3-3.5,5.1-4.5c2-0.8,5.4-1.5,10.2-0.2h0.1c1.6,0.4,7.1,3.8,7.1,5.3
                        c0,0.8-0.1,1.6-0.3,2.4c-0.2,1.2-0.3,2.4-0.3,3.7c0,0.1-0.1,0.2-0.1,0.2l0,0c-0.1,0-0.1,0-0.2-0.1l0,0c0-1.3,0.1-2.5,0.4-3.7
                        c0.1-0.8,0.2-1.6,0.3-2.3c0-0.5-1.1-1.6-2.8-2.8c-1.2-0.9-2.6-1.6-4-2.2h-0.1c-4.7-1.3-8-0.6-10,0.2c-4.4,1.8-6.5,7.4-6.5,7.5
                        s-1.7,3.5-1.1,5.7c0.6,2.2,1.6,3.4,3.2,3.4c0.4,0,0.9,0,1.2-0.1c0.7-0.1,1.1-0.1,1.3,0.1s0,0.5-0.5,0.9c-1.2,1.4-3.6,4.8-2.7,6.2
                        c0.4,0.5,0.8,1,1.3,1.3c1.9,1.5,3.5,3.3,4.7,5.4l0.9,1.5c2.2,3.7,2.2,3.7,7.6,5.3c0.7,0.2,7.1,2,10.3-1.6c1.2-1.4,1.5-4,0.9-7.3
                        c0-0.1,0-0.2,0.1-0.2l0,0c0.1,0,0.1,0,0.2,0.1l0,0c0.9,4.6-0.2,6.7-0.9,7.6C657.6,430.3,655.3,431.2,652.9,431.1z"/>
                      <path className="st45" d="M655.7,403.8c-0.1-1.9,0.6-4.7,0.6-6.1s-5.3-4.8-7-5.2c-0.9-0.2-5.7-1.7-10.2,0.2c-4.5,1.9-6.6,7.6-6.6,7.6
                        s-1.7,3.5-1.1,5.8c0.6,2.3,1.7,3.5,3.3,3.5l2.5-0.1"/>
                      <path className="st46" d="M634.8,409.7c-1.7,0-2.8-1.2-3.5-3.6c-0.6-2.4,1-5.7,1.1-5.9c0.4-1.1,1-2.1,1.7-3.1c1.2-2,3-3.5,5.1-4.5
                        c2-0.8,5.3-1.5,10.1-0.2h0.1c1.6,0.4,7.1,3.8,7.1,5.3c0,0.8-0.1,1.6-0.3,2.4c-0.2,1.2-0.3,2.4-0.4,3.7c0,0.1-0.1,0.2-0.1,0.2l0,0
                        l0,0c-0.1,0-0.1-0.1-0.1-0.1c0-1.3,0.1-2.5,0.3-3.7c0.1-0.8,0.2-1.6,0.3-2.3c0-0.5-1.1-1.6-2.8-2.8c-1.2-0.9-2.6-1.6-4-2.2h-0.1
                        c-4.7-1.3-8-0.6-10,0.2c-4.4,1.8-6.5,7.4-6.5,7.5s-1.6,3.5-1.1,5.7C632.3,408.5,633.4,409.7,634.8,409.7l2.5-0.1
                        c0.1,0,0.1,0,0.2,0.1l0,0c0,0.1-0.1,0.2-0.1,0.2l0,0L634.8,409.7L634.8,409.7z"/>
                      <path className="st45" d="M651,462.7c4.4,9.4,4.2,20.3-0.7,29.5c2.2-1.7,4.9-2.5,7.7-2.2c4.5,0.5,6,7,4.7,8s-3.5,1-5.2,1.5
                        s-11.7,0-13.9-1.7s-0.7-6.5,2-10c-2.5-2.5-7-11.2-7-11.2S647.4,471.3,651,462.7z"/>
                      <path d="M655.2,499.7c-3.7,0-9.9-0.6-11.6-2c-0.7-0.5-1.1-1.3-1.1-2.1c-0.3-2.1,0.9-5.2,3-7.9c-2.5-2.6-6.7-10.8-6.9-11.2
                        c0-0.1,0-0.2,0.1-0.2l0,0c0.1-0.1,8.7-5.2,12.3-13.8c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0.1c4.3,9.2,4.2,19.9-0.4,29.1
                        c2.1-1.4,4.7-2.1,7.3-1.9c2.7,0.3,4.4,2.7,5,4.9c0.5,1.5,0.4,2.9-0.2,3.3c-1,0.6-2.2,1-3.4,1.1c-0.6,0.1-1.2,0.2-1.8,0.4
                        C656.9,499.7,656,499.7,655.2,499.7z M638.9,476.5c0.5,1.1,4.6,8.8,6.9,11c0,0.1,0,0.1,0,0.2c-2,2.6-3.3,5.8-3,7.8
                        c0.1,0.7,0.4,1.4,1,1.9c2.2,1.7,12.1,2.2,13.8,1.7c0.6-0.2,1.2-0.3,1.9-0.4c1.2-0.1,2.3-0.5,3.3-1.1c0.5-0.4,0.5-1.6,0.1-3
                        c-0.6-2.1-2.2-4.4-4.7-4.7c-2.7-0.2-5.4,0.5-7.6,2.2c-0.1,0.1-0.1,0.1-0.2,0c0-0.1,0-0.1,0-0.2c4.7-9,5-19.8,0.8-29.1
                        C647.6,470.9,640,475.9,638.9,476.5z"/>
                      <path className="st19" d="M628.8,396c0,0-6.9,3.8-8.6,3.6s-0.4,6.9,2.9,8.4c-0.4-1.5-0.6-3.1-0.6-4.6l2.3,1.5l-1.7-3.4l2.1-0.8
                        L628.8,396z"/>
                      <path className="st17" d="M648.7,393.3l-1.3,2.6c0,0-2.4-2.5-8.8,0C642.1,392.1,645.3,392.7,648.7,393.3z"/>
                      <path className="st17" d="M652.9,395.8l-0.5,2c0,0,1.9-1,3.5,1.9C655.7,397.6,655.8,397.5,652.9,395.8z"/>
                      <path className="st32" d="M644.4,396.5c0.8,1.2,1,2.7,0.4,4.1c-1.7-0.5-3.5-0.7-5.2-0.6C639.6,400,640,396.5,644.4,396.5z"/>
                      <path className="st10" d="M644.2,400.5c0-1.3,0-3.8-1.5-3.7c-0.9,0.1-2,2-2,3.2"/>
                      <path d="M644.3,400.5H644c0-1,0-2.7-0.7-3.4c-0.2-0.2-0.4-0.2-0.6-0.2c-0.8,0.1-1.9,1.9-1.9,3.1h-0.3c0-1.3,1.1-3.3,2.2-3.4
                        c0.3,0,0.6,0.1,0.9,0.3C644.3,397.6,644.3,399.5,644.3,400.5z"/>
                      <ellipse transform="matrix(0.9689 -0.2473 0.2473 0.9689 -84.5565 178.2627)" className="st19" cx="667.3" cy="425.7" rx="0.9" ry="1.3"/>
                      <ellipse transform="matrix(0.3962 -0.9182 0.9182 0.3962 16.2885 873.0147)" className="st19" cx="671.9" cy="424.1" rx="1.3" ry="0.9"/>
                      <ellipse transform="matrix(0.5556 -0.8315 0.8315 0.5556 -59.0469 748.9982)" className="st19" cx="671.1" cy="429.7" rx="2.1" ry="3"/>
                      <ellipse transform="matrix(0.6451 -0.7641 0.7641 0.6451 -85.5167 667.4296)" className="st19" cx="675.7" cy="425.8" rx="1.3" ry="0.9"/>
                      <ellipse transform="matrix(0.7827 -0.6224 0.6224 0.7827 -121.0983 514.8981)" className="st19" cx="676.8" cy="430.9" rx="1.3" ry="0.9"/>
                      <path className="st32" d="M654.7,403.9l-2.9-1.9c0,0,0.2-2.6,1.2-3s2,1.1,2,1.1L654.7,403.9z"/>
                      <path className="st10" d="M654,398.9c0,0,1,0.5,0.1,4.5c-0.5-0.4-1.1-0.8-1.7-1.2C652.4,402.2,652.6,398.7,654,398.9z"/>
                      <path className="st45" d="M651.2,403.9c0,0,9.7-2.1,14.4,4.3c1.3,3.1-2.5,11.1-5.5,12.9c-1.1-1.1-1.5-4.5-3.5-4.8s-8.2,5.5-11.4,3.1
                        c-2.3-1.8-4-4.3-5-7"/>
                      <path d="M660,421.3L660,421.3c-0.5-0.6-0.9-1.2-1.1-1.9c-0.5-1.3-1.2-2.7-2.3-2.9c-0.7-0.1-2,0.6-3.4,1.4c-2.7,1.5-6,3.3-8.1,1.7
                        c-2.3-1.9-4-4.3-5.1-7.1c0-0.1,0-0.2,0.1-0.2s0.2,0,0.2,0.1l0,0c1,2.7,2.7,5.1,5,6.9c2,1.5,5.2-0.3,7.7-1.7
                        c1.5-0.8,2.9-1.6,3.6-1.4c1.3,0.2,2,1.7,2.6,3c0.2,0.6,0.5,1.1,0.8,1.6c3-1.9,6.7-9.6,5.4-12.6c-4.6-6.2-14.1-4.2-14.2-4.2
                        c-0.1,0-0.2,0-0.2-0.1s0-0.2,0.1-0.2l0,0c0.1,0,9.8-2.1,14.5,4.3l0,0c0.6,1.5,0.2,4.1-1.1,7c-1.3,2.8-3,5.1-4.5,6
                        C660.1,421.2,660.1,421.2,660,421.3z"/>
                      <path className="st4" d="M637.2,409.4c0,0,1.7-1,3.3,3.7c2.6,7.5,7.1,8.7,13,4.4c0.7-0.5,2.1-1.7,3.3-1.3c0.5,0.1,1.4,0.9,2.5,3.5
                        c0.2,0.4,0.4,0.8,0.7,1.1c3.3-2.9,5.3-7,5.7-11.4c0-2.1-2.4,0.7-5.2,0.2c-2.8-0.7-5.5-1.8-8.1-3.2c-2.8-1.8-6.2-2.3-9.4-1.2
                        C640.8,406.1,638.7,407.5,637.2,409.4z"/>
                      <path className="st19" d="M640.8,414.1c0,0,1.9,11.5,7.5,12.6c3.1,0.6,4-1.9,5.4-2.2c1.5-0.2,3-0.1,4.5,0.1c0,0-2.9-4.9-2.7-8
                        C648,420.2,645.9,423.2,640.8,414.1z"/>
                      <path d="M649.3,426.9c-0.3,0-0.7,0-1-0.1c-2.4-0.5-4.5-2.8-6-6.8c-0.7-1.9-1.3-3.9-1.6-5.9c0-0.1,0-0.1,0.1-0.2
                        c0.1,0,0.1,0,0.2,0.1c4.3,7.7,6.3,6.7,11.7,3.8c0.8-0.4,1.8-0.9,2.8-1.4c0,0,0.1,0,0.2,0l0.1,0.1c-0.2,3.1,2.6,7.9,2.7,7.9
                        c0,0,0,0.1,0,0.2l-0.1,0.1c-1.5-0.2-2.9-0.3-4.4-0.1c-0.5,0.2-0.9,0.5-1.3,0.9C651.7,426.3,650.5,426.9,649.3,426.9z M641.1,414.9
                        c0.6,2.7,2.7,10.6,7.2,11.6c1.5,0.3,3-0.2,3.9-1.3c0.4-0.4,0.9-0.7,1.4-0.9c1.4-0.2,2.8-0.2,4.2,0.1c-0.6-1-2.6-4.8-2.6-7.6
                        c-0.9,0.5-1.8,0.9-2.6,1.3C647.6,420.9,645.3,422.1,641.1,414.9L641.1,414.9z"/>
                      <path className="st17" d="M655.3,424.3c2.4,0.3-2.4-2.4-3.8-0.8c-3-0.8-4.1,2.9-4.1,2.9s2.1,0.8,3.2,0.2c0.8-0.5,1.5-1,2.1-1.7
                        C653.5,424.4,654.4,424.1,655.3,424.3z"/>
                      <path d="M649.6,426.9c-0.8,0-1.5-0.2-2.2-0.4h-0.1v-0.1c0,0,1.2-3.8,4.2-3c1-1,3.2-0.2,4,0.3c0.4,0.3,0.6,0.5,0.5,0.7
                        s-0.3,0.2-0.8,0.2c-0.9-0.1-1.7,0.1-2.5,0.6c-0.6,0.7-1.4,1.3-2.2,1.7C650.3,426.9,650,426.9,649.6,426.9z M647.6,426.3
                        c0.9,0.4,2,0.4,2.9,0.2c0.8-0.4,1.5-1,2.1-1.7c0.8-0.6,1.7-0.8,2.7-0.7l0,0c0.2,0,0.3,0,0.5,0c-0.6-0.5-1.3-0.8-2-0.9
                        c-0.6-0.1-1.6-0.3-2.1,0.3l-0.1,0.1h-0.1C649,422.9,647.9,425.6,647.6,426.3L647.6,426.3z"/>
                      <path className="st19" d="M650.5,406.4c0,0,1.5-1.5,4.7,0s4.7,2.9,6.8,2.9c1.1,0,2.1-1.1,2.7-1.3c0.3-0.1,0.7,0.1,0.8,0.4
                        c0,0,0.7,1.2-1.2,3.4c-1.7,1.9-2.3,2.3-5.5,1.5s-1.5-2-3.3-3.3s-3.2-0.8-4.1-1.4C650.9,408,650.5,407.2,650.5,406.4z"/>
                      <path d="M644.9,400.9L644.9,400.9c-0.1,0-0.2-0.1-0.1-0.2l0,0c0.7-1.4,0.5-3-0.4-4.2c-0.1-0.1-0.1-0.2,0-0.2c0.1-0.1,0.2-0.1,0.2,0
                        c1,1.3,1.1,3.1,0.4,4.6C645,400.9,645,400.9,644.9,400.9z"/>
                      <path d="M639.5,400.1L639.5,400.1c-0.1,0-0.1-0.1-0.1-0.1l0,0c0.1-1,0.6-1.8,1.3-2.5c2-1.7,5-1.2,5.1-1.2s0.1,0.1,0.1,0.2l0,0
                        c0,0.1-0.1,0.1-0.2,0.1c0,0-3-0.5-4.9,1.1c-0.7,0.6-1.1,1.4-1.2,2.3C639.7,400.1,639.6,400.2,639.5,400.1z"/>
                      <ellipse transform="matrix(0.1711 -0.9853 0.9853 0.1711 139.9787 964.9034)" cx="643.4" cy="399.3" rx="1.2" ry="0.8"/>
                      <ellipse transform="matrix(0.1711 -0.9853 0.9853 0.1711 145.8667 977.2951)" cx="653.7" cy="402" rx="1" ry="0.7"/>
                      <path d="M654.1,403.8L654.1,403.8c-0.1,0-0.1-0.1-0.1-0.2l0,0c0.7-3.1,0.4-4.1,0.2-4.5c-0.1-0.1-0.1-0.1-0.2-0.2c0,0-1.4,0-1.5,3.3
                        c0,0.1-0.1,0.1-0.1,0.1l0,0c-0.1,0-0.1-0.1-0.1-0.1l0,0c0.1-2,0.6-2.9,1-3.2c0.2-0.2,0.5-0.3,0.8-0.3c0.2,0,0.4,0.1,0.5,0.3
                        c0.3,0.5,0.6,1.7-0.1,4.7C654.3,403.8,654.2,403.8,654.1,403.8L654.1,403.8z"/>
                      <path d="M654,403.9c-0.1,0-0.1,0-0.1-0.1c-0.7-0.8-1.6-1.4-2.6-1.8c-0.1,0-0.1-0.1-0.1-0.2l0,0c0-0.1,0.1-0.1,0.2-0.1
                        c1,0.4,2,1,2.7,1.9c0.1,0.1,0.1,0.2,0,0.2L654,403.9z"/>
                      <path d="M654.6,403.8L654.6,403.8c-0.1,0-0.1-0.1-0.1-0.2c0.6-2.4,0.3-3.4-0.2-4.2c-0.4-0.5-0.9-1-1.6-1.2c-0.1,0-0.1-0.1-0.1-0.2
                        l0,0c0-0.1,0.1-0.1,0.2-0.1l0,0c0.7,0.2,1.3,0.7,1.8,1.3c0.5,0.9,0.8,2,0.2,4.4C654.8,403.8,654.7,403.8,654.6,403.8L654.6,403.8z"/>
                      <circle cx="646.2" cy="414.9" r="0.4"/>
                      <path d="M644.4,415.2c-2.4,0.6-4.8,1.3-7.1,2.2c-2,0.9-3.8,2.1-5.3,3.6c-0.9,0.9-1.8,1.8-2.6,2.8c-0.2,0.2,0.1,0.5,0.3,0.3
                        c1.4-1.7,2.9-3.2,4.7-4.5s3.9-2.3,6.1-2.9l4.1-1.2c0.1,0,0.1-0.2,0.1-0.3C644.6,415.2,644.5,415.2,644.4,415.2L644.4,415.2z"/>
                      <path d="M651.7,402L651.7,402c-0.1,0-0.1-0.1-0.1-0.2c0.2-2.7,1.9-3.3,2-3.4c0.1,0,0.2,0,0.2,0.1s0,0.2-0.1,0.2s-1.6,0.6-1.8,3.1
                        C651.9,402,651.8,402,651.7,402L651.7,402z"/>
                      <path d="M646.2,401.5L646.2,401.5c-3.3-1.8-7.5-1.1-7.6-1.1s-0.2-0.1-0.2-0.1c0-0.1,0.1-0.2,0.1-0.2l0,0c0,0,4.4-0.7,7.7,1.1
                        c0.1,0,0.1,0.1,0.1,0.2S646.3,401.5,646.2,401.5z"/>
                      <circle cx="644.1" cy="412.2" r="0.4"/>
                      <circle cx="646.1" cy="409.7" r="0.4"/>
                      <path d="M645,409c-2-1.2-4.2-2.3-6.3-3.3c-2.1-0.8-4.3-1.2-6.6-1.3c-1.3,0-2.6,0-3.9,0.2c-0.2,0-0.2,0.4,0,0.4
                        c2.8-0.4,5.7-0.2,8.5,0.5c2.9,0.8,5.5,2.4,8.1,3.8C645.1,409.4,645.3,409.1,645,409z"/>
                      <path d="M638.6,412.6c-0.6,0-1.1-0.1-1.7-0.2c-0.1,0-0.1-0.1-0.1-0.2s0.1-0.1,0.2-0.1c0,0,4,0.9,4.5-1.4c0-0.1,0.1-0.1,0.2-0.1
                        c0.1,0,0.1,0.1,0.1,0.2l0,0C641.4,412.3,639.8,412.6,638.6,412.6z"/>
                      <path d="M641.9,411.9c-2.4-0.5-4.7-0.8-7.1-0.9c-2.3,0-4.5,0.3-6.7,1.1c-1.2,0.4-2.4,0.9-3.5,1.5c-0.2,0.1,0,0.4,0.2,0.3
                        c2.5-1.3,5.3-2.1,8.2-2.4c3-0.3,6,0.4,8.9,0.9C642.1,412.2,642.2,411.9,641.9,411.9L641.9,411.9z"/>
                      <circle cx="660.3" cy="418.2" r="0.4"/>
                      <circle cx="660.2" cy="415.5" r="0.4"/>
                      <circle cx="663.6" cy="414" r="0.4"/>
                      <path d="M661.2,417.3c1.3,1.8,2.2,4,2.5,6.2c0.2,1.7,0,3.4-0.4,5.1c-0.2,0.8-0.4,1.6-0.6,2.4c0,0.1,0.1,0.2,0.2,0.2
                        c0.1,0,0.2,0,0.2-0.1c0.4-1.5,0.8-3,1-4.6c0.2-1.9,0-3.9-0.6-5.7c-0.5-1.3-1.1-2.5-1.9-3.7C661.4,416.9,661.1,417.1,661.2,417.3
                        L661.2,417.3z"/>
                      <path d="M662.9,415.2c2.3,0.8,4.2,2.4,5.6,4.3c0.6,0.8,1,1.8,1.3,2.8c0,0.1,0.2,0.1,0.3,0.1s0.1-0.1,0.1-0.2c-1-3-3.1-5.5-6-6.8
                        c-0.4-0.2-0.8-0.4-1.2-0.5C662.7,414.7,662.6,415.1,662.9,415.2L662.9,415.2z"/>
                      <path d="M664.3,413.1c1.9-0.6,4-0.5,5.9,0.4c1.6,0.8,3,1.8,4.1,3.1c0.6,0.6,1.1,1.3,1.7,2c0.1,0.2,0.4-0.1,0.3-0.3
                        c-2.2-2.7-4.8-5.5-8.4-5.9c-1.2-0.1-2.4,0-3.6,0.3c-0.1,0-0.1,0.2-0.1,0.3C664.2,413.1,664.3,413.1,664.3,413.1L664.3,413.1z"/>
                      <path d="M657.6,499.5L657.6,499.5c-0.1,0-0.1-0.1-0.1-0.2l0,0c0,0,1.4-3.7-0.7-6.3c-0.1-0.1-0.1-0.2,0-0.2c0.1-0.1,0.1-0.1,0.2,0
                        l0,0c2.2,2.7,0.7,6.5,0.7,6.6C657.8,499.5,657.7,499.6,657.6,499.5z"/>
                      <path d="M615.5,502.9c-0.1,0-0.1,0-0.1-0.1c0,0-1.5-3.9,0.7-6.6c0.1-0.1,0.1-0.1,0.2,0c0.1,0.1,0.1,0.1,0,0.2l0,0
                        c-2.1,2.5-0.7,6.2-0.7,6.3C615.6,502.8,615.6,502.9,615.5,502.9L615.5,502.9z"/>
                      <path d="M619.7,502.8c-0.1,0-0.1,0-0.1-0.1c0,0-1.5-3.9,0.7-6.6c0.1-0.1,0.1-0.1,0.2,0c0.1,0.1,0.1,0.2,0,0.2
                        c-2.1,2.5-0.7,6.2-0.7,6.3C619.8,502.7,619.8,502.8,619.7,502.8L619.7,502.8z"/>
                      <path d="M661,498.7L661,498.7c-0.1,0-0.1-0.1-0.1-0.2l0,0c0,0,1.4-3.7-0.7-6.3c-0.1-0.1-0.1-0.2,0-0.2c0.1-0.1,0.2-0.1,0.2,0
                        c2.2,2.7,0.7,6.5,0.7,6.5C661.2,498.7,661.1,498.7,661,498.7z"/>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

          </div>
        
        </div>
        {playStreet>1 &&
          <>
            <Birds />
            <Clouds2 />
          </>
        }

        <div id="section-2"> 

          <div 
            id="section-2B"
            className={isIntersecting[1] ? 'enter' : playStreet>1 ? 'show' : 'hide'}
          >
            <div 
              id="triangle-1" 
              ref={el => animateRefs.current[0] = el} 
              className={'triangle intersection'+(((!isMobile && isIntersecting[2]) || (isMobile && isIntersecting[0])) ? ' enter' : '')}
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                xmlnsXlink="http://www.w3.org/1999/xlink" 
                x="0px" 
                y="0px"
                viewBox="0 0 2600 2725"
              >
                <path id="triangle-1" className="st0" d="M2325,641C1823.42,1101.15,856.46,2066.86,401.33,2570.59C232.75,1919.58-138.96,618.3-339-24
                  C319.66,158.52,1657.05,491.17,2325,641z"/>
                {/* <text transform="matrix(1 0 0 1 301.001 1113.6549)" className="st3 st4 st5">WE BELIEVE ALL INDIVIDUALS HAVE A RIGHT TO:</text> */}
              </svg>
            </div>
            
            <div 
              id="triangle-1b" 
              ref={el => animateRefs.current[0] = el} 
              className={'triangle intersection'+(((!isMobile && isIntersecting[2]) || (isMobile && isIntersecting[0])) ? ' enter' : '')}
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                xmlnsXlink="http://www.w3.org/1999/xlink" 
                x="0px" 
                y="0px"
                viewBox="0 0 2600 2725"
              >
                <path id="triangle-1b" className="st0" d="M2325,641C1823.42,1101.15,856.46,2066.86,401.33,2570.59C232.75,1919.58-138.96,618.3-339-24
                  C319.66,158.52,1657.05,491.17,2325,641z"/>
                {/* <text transform="matrix(1 0 0 1 301.001 1113.6549)" className="st3 st4 st5">WE BELIEVE ALL INDIVIDUALS HAVE A RIGHT TO:</text> */}
              </svg>
            </div>

            <div 
              id="triangle-2-intersection" // triangle-2 svg starts too far up at top of section 2
              ref={el => animateRefs.current[1] = el} 
              className="intersection"
            >
            </div> 

            <div id="triangle-2" className="triangle">
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                xmlnsXlink="http://www.w3.org/1999/xlink" 
                x="0px" 
                y="0px"
                viewBox="0 0 1920 2725"
              >
                <path className="st1" d="M1715.48,979.57L374.7,1995.83L-1551.38,975L1715.48,979.57z"/>
              </svg>
            </div>

            <div id="triangle-3-container" 
              className="triangle"
              // className={'triangle '+slideClass} 
              // onAnimationEnd={finishFlipSlide}
            >
              
              <div id="triangle-3" className="triangle">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" 
                  y="0px"
                  viewBox="0 0 1960 2725"
                >
                  <path className="st2" d="M-127.66,1140.78l2070.95,159.89l-890.33,905.88L-127.66,1140.78z"/>
                </svg>
              </div>

              <div className="slide">
                <TransitionGroup>
                  <CSSTransition
                      classNames="fade"
                      key={'slide-wrapper-'+currentSlide} 
                      timeout={{
                        enter: 1000,
                        exit: 500,
                      }} /* enter and exit occur simultaenously */
                      // enter={false}
                      // exit={false}
                      /*
                      addEndListener={(node, done) => {
                        node.addEventListener("transitionend", done, false);
                      }}
                      */
                    >
                      <div className="slide-content">
                        <ul>
                          {slides[currentSlide].map((slide, index) => {
                            return slide 
                              ? <li key={'slide-'+index}>{slide}</li>
                              : <React.Fragment key={'slide-'+index} /> 
                          })}
                        </ul>
                      </div>
                    </CSSTransition>
                </TransitionGroup>
                <div className="slider-dots">
                  {slides.map((slide, index) => 
                    <div 
                      key={'dot-'+index} 
                      className={currentSlide===index ? 'dot active' : 'dot'}
                      onClick={() => goToSlide(index)}
                    >
                        <div></div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div id="lion">
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                xmlnsXlink="http://www.w3.org/1999/xlink" 
                x="0px" 
                y="0px"
                viewBox="0 0 1920 2725"
              >
                <path className="st3" d="M80.14,1607.92c0,0,37.89,6.53,51.83-21.78s-7.4-54-7.4-54v18.29c0,0-16.11-26.13-23.95-28.31
                  c2.18,4.35-1.31,20.47-1.31,20.47s-16.11-26.13-11.76-41.37c7.4-18.29-15.24,13.07-15.24,34.84c-3.05-7.4-9.58-19.6-9.58-19.6
                  s-2.18,46.6,0.87,53.13c-6.53-1.74-12.63-5.23-17.42-9.58C46.61,1560.45,53.14,1600.52,80.14,1607.92z"/>
                <path className="st6" d="M204.7,1682.83c0,0-91.02-7.84-100.6-58.36c-9.58-49.21,8.71-39.2,8.71-39.2s7.4,2.61,5.23,24.82
                  c-1.74,22.21,22.65,44.42,88.84,54C208.62,1670.64,207.74,1677.17,204.7,1682.83z"/>
                <path d="M204.7,1683.7L204.7,1683.7c-16.11-1.74-31.79-5.23-47.03-10.45c-31.79-10.89-50.52-27.87-54.44-48.78
                  c-4.36-22.21-3.48-35.28,2.18-39.2c2.18-1.31,5.23-1.74,7.4-0.44c0,0,7.4,3.05,5.66,25.69c-1.31,14.81,8.71,41.81,88.41,53.57
                  c0.44,0,0.44,0.44,0.44,0.44c1.74,6.53,0.87,13.07-2.18,18.73C205.13,1683.27,205.13,1683.7,204.7,1683.7z M109.32,1585.28
                  c-1.31,0-2.18,0.44-3.05,0.87c-3.48,2.61-6.53,11.32-1.31,38.32c9.15,47.91,93.2,57.49,99.73,57.92
                  c2.61-5.23,3.05-11.32,2.18-16.98c-80.13-11.76-90.15-39.2-88.84-54.87c1.74-21.34-4.79-24.39-4.79-24.39
                  C111.5,1585.28,110.63,1585.28,109.32,1585.28z"/>
                <path className="st6" d="M226.04,1520.39c0,0-18.73,70.99-18.73,100.17c-10.89,42.24,1.31,97.12,6.97,121.94
                  c-20.47,1.31-53.57,15.24-44.42,46.6c3.05,2.18,6.1,3.92,9.58,5.66c0,0,13.07,7.4,24.82,2.18c5.23,7.4,13.94,6.53,23.95,5.23
                  c10.02-1.31,28.31-3.48,37.02-8.71c1.31-13.5-0.87-27-6.53-39.2c23.08-35.28,34.84-76.21,34.84-118.02"/>
                <path d="M218.2,1803.47c-5.66,0.44-10.89-1.74-14.37-6.1c-11.76,4.79-23.95-1.74-24.82-2.18c-3.48-1.31-6.97-3.05-10.02-5.66
                  c0,0,0,0-0.44-0.44c-3.05-9.58-1.74-19.6,3.92-27.87c8.71-12.19,26.57-18.73,40.07-19.6v0c-5.66-25.69-17.42-79.26-6.53-121.07
                  c0-29.18,18.73-99.73,18.73-100.17s0.44-0.44,0.87-0.44c0.44,0,0.44,0.44,0.44,0.87c0,0.87-18.73,71.42-18.73,100.17l0,0
                  c-10.45,41.37,0.87,94.94,6.53,120.2l0.44,1.31v0.44l-0.44,0.44c-13.5,0.87-31.36,6.97-40.07,19.16
                  c-5.23,7.84-6.97,17.42-3.92,26.57c3.05,2.18,6.1,3.92,9.58,5.23l0,0c0,0,13.07,7.4,24.39,2.18c0.44,0,0.44,0,0.87,0
                  c5.23,6.97,13.5,6.1,22.65,5.23h0.44c8.27-0.87,27.44-3.05,36.15-8.27c1.31-13.07-0.87-26.13-6.53-38.32c0,0,0-0.44,0-0.87
                  c22.65-34.84,34.41-75.78,34.41-117.59c0-0.44,0.44-0.87,0.44-0.87l0,0l0,0c0.44,0,0.87,0.44,0.87,0.87l0,0
                  c0,41.81-11.76,82.75-34.41,118.02c5.66,12.19,7.84,25.69,6.53,39.2c0,0,0,0.44-0.44,0.44c-9.15,5.66-28.74,7.84-37.02,8.71h-0.44
                  C224.73,1803.03,221.24,1803.47,218.2,1803.47z"/>
                <path className="st6" d="M333.17,1506.01c0,0,60.97-23.52,70.55-30.49c1.74-5.66-8.71-23.08,8.27-24.82c0,0,10.45-1.31,11.32,13.07
                  c0,0-3.48-24.82,13.07-19.6c7.4,4.36,3.92,13.5,3.92,13.5s11.76-10.89,16.55-5.66c5.23,5.23,0.44,13.94-2.18,17.42
                  c-1.74,2.61-3.92,5.23-6.1,7.84c0,0,13.5-7.84,15.24,3.05c0.87,4.79-7.84,17.86-27,17.42c-30.92,16.55-67.07,57.92-103.65,65.76"/>
                <path d="M333.61,1563.94c-0.44,0-0.44,0-0.44-0.44s0-0.44,0.44-0.87l0,0c22.65-4.79,44.86-22.21,66.2-39.2
                  c11.76-10.02,23.95-18.73,37.45-26.57h0.44c8.27,0.87,16.55-2.18,22.65-8.27c2.18-2.18,3.48-4.79,3.92-7.84
                  c0-1.74-1.31-3.48-2.61-4.36c-4.36-2.18-11.32,2.18-11.32,2.18c-0.44,0-0.44,0-0.87,0c0-0.44,0-0.44,0-0.87c0,0,3.48-3.92,6.1-7.84
                  c1.31-1.74,7.4-11.32,2.18-16.55c-4.36-4.79-15.68,5.66-15.68,6.1c-0.44,0-0.44,0-0.87,0c-0.44,0-0.44-0.44,0-0.87
                  c0-0.44,3.05-8.71-3.48-12.63c-2.61-1.31-6.1-0.87-8.27,0.87c-4.36,3.48-3.92,12.19-3.48,16.11c0,0.44,0,1.31,0,1.74
                  s-0.44,0.87-0.44,0.87l0,0c-0.44,0-0.44,0-0.87-0.44l0,0c0-0.87,0-1.31,0-2.18c-0.44-3.48-1.74-6.53-4.36-8.71
                  c-1.74-1.31-3.92-2.18-6.1-1.74c-3.05,0-5.66,1.31-7.84,3.48c-2.61,3.48-1.74,9.15-0.87,13.5c0.87,2.18,0.87,4.79,0.44,6.97v0.44
                  c-9.58,6.97-68.37,29.61-70.55,30.92c-0.44,0-0.87,0-0.87-0.44s0-0.87,0.44-0.87s60.1-23.08,70.12-30.49c0-2.18,0-4.36-0.44-6.53
                  c-0.87-4.79-2.18-10.89,0.87-14.81c2.18-2.61,5.23-3.92,8.71-3.92c2.61-0.44,5.23,0.44,6.97,2.18c1.31,1.31,2.61,2.61,3.48,4.36
                  c0-4.36,1.31-8.71,4.36-11.76c2.61-2.18,6.53-2.61,9.58-0.87l0,0c4.36,2.61,6.1,7.4,4.79,12.19c3.48-3.05,11.76-8.71,16.11-4.36
                  s2.18,12.63-2.18,18.29c-1.31,1.74-2.61,3.48-4.36,5.66c3.05-1.31,6.53-1.74,9.58-0.87c2.18,1.31,3.48,3.05,3.48,5.66
                  c0,3.48-1.74,6.53-3.92,9.15c-6.1,6.1-14.81,9.58-23.52,8.71c-13.07,7.84-25.26,16.55-37.02,26.13
                  C378.9,1541.29,356.69,1559.15,333.61,1563.94L333.61,1563.94z"/>
                <path className="st6" d="M341.88,1512.98c0,0,42.68,111.05-54,169.85"/>
                <path d="M287.44,1683.7c-0.44,0-0.44,0-0.44-0.44s0-0.87,0-0.87c38.32-21.78,62.28-62.28,63.15-106.26
                  c0.87-21.34-2.18-42.68-9.15-62.71c0-0.44,0-0.87,0.44-0.87l0,0c0.44,0,0.87,0,0.87,0.44c6.97,20.47,10.02,41.81,9.15,63.15
                  C350.59,1620.55,326.64,1661.05,287.44,1683.7L287.44,1683.7z"/>
                <path className="st6" d="M245.2,1590.5l7.4-60.1l41.81-72.73c0,0,20.9,27.44,44.86,27.44c23.95,0,2.61,24.82,2.61,24.82l-2.18,25.69
                  l2.18,6.97l6.1,4.36l-3.05,40.5l-41.81,82.31l-16.98,14.37l-20.47-0.87L245.2,1590.5z"/>
                <path className="st3" d="M365.83,1341.83c12.63-15.68,27-34.41,16.98-52.26c-4.79,5.23-9.58,10.89-13.5,16.98
                  c-1.31-15.24-4.79-30.49-10.45-44.86c-0.44,5.66-1.74,11.32-3.92,16.55c-9.15-19.16-27.87-34.84-44.86-47.91
                  c20.03,27,13.07,28.74,20.47,43.12c-28.74-19.16-62.71-28.74-97.12-27.44l24.82,9.58c-24.39,0.87-46.16,14.37-67.07,27.44
                  c5.66-2.18,11.76-0.87,16.11,3.05c-12.19,4.36-23.95,10.45-34.84,17.86c-10.89,7.4-18.29,19.16-21.34,31.79
                  c3.92-4.79,9.15-8.27,15.24-10.02c-10.89,20.47-20.47,42.24-27.87,64.02c6.53-8.27,14.37-16.11,22.65-23.08
                  c-2.18,27.87-28.31,50.95-26.57,78.83l12.63-17.86c0.44,20.47,4.79,40.94,13.07,60.1c1.74,3.48,5.66,7.84,8.71,5.23
                  c0.87-5.66,0-10.89-1.74-16.11c7.4,20.03,18.29,38.32,31.79,54.87c-2.61-8.71-4.36-17.42-5.23-26.13
                  c14.37,5.66,27.87,13.07,40.07,22.21c-1.74-13.94-7.84-27.87-7.84-41.81s2.18-13.5,12.63-23.08c11.32-9.58,27.87-9.15,38.76,0.87
                  c11.76,11.76,7.4,13.5,10.45,29.61c1.74,7.84,15.24,23.95,20.03,29.18c1.31,1.74,2.61-11.32,4.36-9.58
                  c3.48,3.48,6.53,3.05,10.02,6.53c4.36,4.36,1.74-12.19,5.23-12.19c9.58-0.44,9.58,0.87,21.78,12.63
                  c3.48-8.71,25.26-33.53,25.7-42.68c0,0-47.47,13.07-63.58-8.71c-20.47-27.44-20.47-29.61-37.45-41.81
                  c-6.97-5.23-9.15-12.63,8.71-37.02c-11.76,3.48-23.95-4.79-23.95-10.45c0-21.78-4.36-16.98,11.32-45.29
                  C282.22,1311.34,336.65,1299.58,365.83,1341.83z"/>
                <path className="st6" d="M269.15,1611.84c17.86,15.24,5.66,24.39,5.66,24.39s-8.27,8.71-20.47-14.37c0.44,6.53,6.1,22.65-3.48,25.26
                  c-15.24,3.92-13.07-5.66-16.55-20.47c0,6.53,4.36,17.42-7.4,17.42c-21.78-4.36-6.53-27.87-10.02-39.63
                  c-5.66-18.73-4.36-37.45-5.66-56.62c-0.87-10.45,3.05-74.04,18.29-92.76c15.24-18.73,47.91-8.27,47.91-8.27
                  c25.26,12.19,15.68,64.46-9.15,90.59c-2.18,16.55-2.18,33.53,0,50.08c5.66,3.92,20.47,10.89,18.73,20.9c0,0-0.87,7.4-11.32,7.84"/>
                <path d="M245.2,1648.42c-1.74,0-3.48-0.44-4.79-1.31c-3.05-1.74-3.92-5.66-4.79-10.45c0,2.18-0.44,3.92-1.74,5.66
                  c-1.74,1.74-4.36,2.61-6.97,2.61l0,0c-14.81-3.05-13.07-15.24-11.32-25.69c1.31-4.79,1.31-9.58,0.44-14.37
                  c-3.48-13.07-5.23-26.57-4.79-40.07c0-5.66-0.44-11.32-0.87-16.98c-0.87-10.45,3.05-74.47,18.29-93.2
                  c15.24-18.73,48.34-8.27,48.78-8.27c7.84,4.36,13.5,12.19,14.37,21.34c3.92,21.34-6.53,52.26-23.08,70.12
                  c-2.18,16.55-2.18,33.1,0,49.65c0.87,0.87,2.18,1.31,3.48,2.18c6.53,4.36,16.98,10.89,15.24,19.16c0,0-1.31,7.4-11.32,8.27
                  c2.18,3.05,3.92,6.53,3.92,10.45c0,3.48-1.74,6.53-4.36,8.71c-1.74,1.31-3.48,2.18-5.66,1.74c-4.36-0.44-9.15-5.23-13.94-13.07
                  l0.44,1.31c1.31,7.4,3.48,18.73-4.79,20.9C249.12,1648.42,247.38,1648.42,245.2,1648.42z M234.31,1626.21c0.44,0,0.44,0,0.44,0.44
                  c0.87,3.05,1.31,5.66,1.74,8.27c0.87,5.66,1.74,9.58,4.36,11.32c3.05,1.31,6.53,1.31,9.58,0c6.97-1.74,5.23-12.19,3.92-19.6
                  c-0.44-2.18-0.44-3.92-0.87-5.23c0-0.44,0.44-0.87,0.87-0.87c0.44,0,0.44,0,0.44,0.44c6.53,12.19,11.32,15.24,14.81,15.68
                  c1.74,0.44,3.48,0,4.79-1.31c2.18-1.74,3.92-4.79,3.92-7.84c0-4.79-3.05-10.02-9.58-15.68c-0.44-0.44-0.44-0.87,0-0.87
                  c0.44-0.44,0.87-0.44,0.87,0c1.74,1.74,3.48,3.48,5.23,5.23l0,0c9.58-0.44,10.45-7.4,10.45-7.4c1.31-7.84-8.27-13.94-14.81-17.86
                  c-1.31-0.87-2.61-1.74-3.48-2.18l-0.44-0.44c-2.18-16.55-2.18-33.53,0-50.08v-0.44c16.55-17.86,27-47.91,23.08-69.25
                  c-0.87-8.71-6.1-16.11-13.5-20.47c0,0-32.23-10.02-47.03,7.84c-7.84,9.58-12.63,32.23-14.81,49.21
                  c-2.18,14.37-3.05,28.74-3.05,43.12c0.44,5.66,0.44,11.32,0.87,16.98c0,13.5,1.31,26.57,4.79,39.63
                  c0.87,4.79,0.87,10.02-0.44,14.81c-1.74,10.45-3.48,21.34,10.02,24.39c2.18,0.44,4.36-0.44,6.1-2.18
                  c2.18-2.18,1.74-6.53,0.87-10.45c0-1.74-0.44-3.05-0.44-4.36c0-0.44,0.44-0.44,0.44-0.87L234.31,1626.21z"/>
                <path className="st7" d="M380.21,1441.56c3.48,16.55,0.87,27-3.92,32.23c-4.79,5.23-19.16,15.24-45.73,7.4s-23.52-6.97-37.45-29.61
                  c-13.94-22.65-22.21-22.65-26.13-29.18c-3.92-6.53,6.53-21.78,11.76-27.87s-1.31-3.48-8.71-3.48s-11.76-5.23-14.37-15.24
                  c-2.61-10.02,4.79-25.26,4.79-25.26s9.15-24.82,28.74-33.1c19.6-7.84,40.5-1.74,44.42-0.87c7.4,1.74,30.49,16.55,30.49,22.65
                  c0,6.1-3.05,17.86-2.61,26.57"/>
                <path d="M349.28,1485.11c-6.53,0-12.63-1.31-19.16-3.05c-23.95-6.97-23.95-7.4-33.97-23.52l-3.92-6.1
                  c-5.23-9.15-11.76-16.98-20.03-23.08c-2.18-1.74-4.36-3.48-6.1-6.1c-4.36-7.4,7.4-23.52,12.19-28.31c1.74-2.18,1.74-2.61,1.74-2.61
                  c-1.31-0.44-3.05-0.44-4.36,0c-1.74,0-3.48,0.44-5.66,0.44c-7.4,0-12.19-5.23-15.24-15.68s4.36-24.82,4.79-25.69
                  c1.74-4.79,4.36-9.15,6.97-13.5c5.23-8.71,13.07-15.24,22.21-19.6c8.71-3.48,23.52-6.53,44.42-0.87h0.44
                  c6.97,1.74,30.92,16.55,30.92,23.08c0,3.48-0.44,6.97-1.31,10.45c-0.87,5.23-1.31,10.45-1.31,16.11c0,0.44-0.44,0.87-0.44,0.87l0,0
                  c-0.44,0-0.44,0-0.87-0.44l0,0c0-5.66,0.44-10.89,1.74-16.11c0.44-3.48,0.87-6.97,1.31-10.02c0-2.18-4.79-6.97-12.19-12.19
                  c-5.23-3.92-11.32-6.97-17.42-9.58h-0.44c-20.47-5.66-34.84-2.61-43.55,0.87c-19.16,7.84-28.31,32.23-28.31,32.66
                  s-7.4,15.24-4.79,24.82c2.61,9.58,6.97,14.81,13.94,14.81c1.74,0,3.92,0,5.23-0.44c3.05-0.44,4.79-0.44,5.66,0.44
                  c0.87,0.87,0,2.18-2.18,3.92c-5.23,6.1-15.68,20.9-11.76,27c1.74,2.18,3.48,4.36,5.66,5.66c8.27,6.53,15.24,14.37,20.47,23.52
                  l3.92,6.53c9.58,16.11,9.58,16.11,33.1,23.08c3.05,0.87,30.92,8.71,44.86-6.97c5.23-6.1,6.53-17.42,3.92-31.79
                  c0-0.44,0-0.87,0.44-0.87l0,0c0.44,0,0.44,0,0.87,0.44l0,0c3.92,20.03-0.87,29.18-3.92,33.1
                  C369.75,1481.62,359.74,1485.54,349.28,1485.11z"/>
                <path className="st6" d="M361.48,1366.22c-0.44-8.27,2.61-20.47,2.61-26.57c0-6.1-23.08-20.9-30.49-22.65
                  c-3.92-0.87-24.82-7.4-44.42,0.87s-28.74,33.1-28.74,33.1s-7.4,15.24-4.79,25.26c2.61,10.02,7.4,15.24,14.37,15.24l10.89-0.44"/>
                <path className="st8" d="M270.46,1391.91c-7.4,0-12.19-5.23-15.24-15.68c-2.61-10.45,4.36-24.82,4.79-25.69
                  c1.74-4.79,4.36-9.15,7.4-13.5c5.23-8.71,13.07-15.24,22.21-19.6c8.71-3.48,23.08-6.53,43.99-0.87h0.44
                  c6.97,1.74,30.92,16.55,30.92,23.08c0,3.48-0.44,6.97-1.31,10.45c-0.87,5.23-1.31,10.45-1.74,16.11c0,0.44-0.44,0.87-0.44,0.87l0,0
                  l0,0c-0.44,0-0.44-0.44-0.44-0.44c0-5.66,0.44-10.89,1.31-16.11c0.44-3.48,0.87-6.97,1.31-10.02c0-2.18-4.79-6.97-12.19-12.19
                  c-5.23-3.92-11.32-6.97-17.42-9.58h-0.44c-20.47-5.66-34.84-2.61-43.55,0.87c-19.16,7.84-28.31,32.23-28.31,32.66
                  s-6.97,15.24-4.79,24.82c2.61,9.58,7.4,14.81,13.94,14.81l10.45-0.44c0.44,0,0.44,0,0.87,0.44l0,0c0,0.44-0.44,0.87-0.44,0.87l0,0
                  L270.46,1391.91L270.46,1391.91z"/>
                <path className="st6" d="M341.01,1622.73c19.16,40.94,18.29,88.41-3.05,128.47c9.58-7.4,21.34-10.89,33.53-9.58
                  c19.6,2.18,26.13,30.49,20.47,34.84c-5.66,4.36-15.24,4.36-22.65,6.53s-50.95,0-60.54-7.4c-9.58-7.4-3.05-28.31,8.71-43.55
                  c-10.89-10.89-30.49-48.78-30.49-48.78S325.33,1660.18,341.01,1622.73z"/>
                <path d="M359.3,1783.87c-16.11,0-43.12-2.61-50.52-8.71c-3.05-2.18-4.79-5.66-4.79-9.15c-1.31-9.15,3.92-22.65,13.07-34.41
                  c-10.89-11.32-29.18-47.03-30.05-48.78c0-0.44,0-0.87,0.44-0.87l0,0c0.44-0.44,37.89-22.65,53.57-60.1c0-0.44,0.44-0.44,0.44-0.44
                  c0.44,0,0.44,0,0.44,0.44c18.73,40.07,18.29,86.67-1.74,126.73c9.15-6.1,20.47-9.15,31.79-8.27c11.76,1.31,19.16,11.76,21.78,21.34
                  c2.18,6.53,1.74,12.63-0.87,14.37c-4.36,2.61-9.58,4.36-14.81,4.79c-2.61,0.44-5.23,0.87-7.84,1.74
                  C366.7,1783.87,362.78,1783.87,359.3,1783.87z M288.31,1682.83c2.18,4.79,20.03,38.32,30.05,47.91c0,0.44,0,0.44,0,0.87
                  c-8.71,11.32-14.37,25.26-13.07,33.97c0.44,3.05,1.74,6.1,4.36,8.27c9.58,7.4,52.7,9.58,60.1,7.4c2.61-0.87,5.23-1.31,8.27-1.74
                  c5.23-0.44,10.02-2.18,14.37-4.79c2.18-1.74,2.18-6.97,0.44-13.07c-2.61-9.15-9.58-19.16-20.47-20.47
                  c-11.76-0.87-23.52,2.18-33.1,9.58c-0.44,0.44-0.44,0.44-0.87,0c0-0.44,0-0.44,0-0.87c20.47-39.2,21.78-86.23,3.48-126.73
                  C326.2,1658.44,293.1,1680.22,288.31,1682.83z"/>
                <path className="st9" d="M244.33,1332.25c0,0-30.05,16.55-37.45,15.68c-7.4-0.87-1.74,30.05,12.63,36.58
                  c-1.74-6.53-2.61-13.5-2.61-20.03l10.02,6.53l-7.4-14.81l9.15-3.48L244.33,1332.25z"/>
                <path className="st3" d="M330.99,1320.49l-5.66,11.32c0,0-10.45-10.89-38.32,0C302.25,1315.26,316.19,1317.87,330.99,1320.49z"/>
                <path className="st3" d="M349.28,1331.37l-2.18,8.71c0,0,8.27-4.36,15.24,8.27C361.48,1339.21,361.91,1338.78,349.28,1331.37z"/>
                <path className="st10" d="M312.27,1334.42c3.48,5.23,4.36,11.76,1.74,17.86c-7.4-2.18-15.24-3.05-22.65-2.61
                  C291.36,1349.67,293.1,1334.42,312.27,1334.42z"/>
                <path className="st11" d="M311.4,1351.84c0-5.66,0-16.55-6.53-16.11c-3.92,0.44-8.71,8.71-8.71,13.94"/>
                <path d="M311.83,1351.84h-1.31c0-4.36,0-11.76-3.05-14.81c-0.87-0.87-1.74-0.87-2.61-0.87c-3.48,0.44-8.27,8.27-8.27,13.5h-1.31
                  c0-5.66,4.79-14.37,9.58-14.81c1.31,0,2.61,0.44,3.92,1.31C311.83,1339.21,311.83,1347.49,311.83,1351.84z"/>
              
                <ellipse transform="matrix(0.9689 -0.2473 0.2473 0.9689 -348.6878 147.3623)" className="st9" cx="412.26" cy="1461.71" rx="3.92" ry="5.66"/>
              
                <ellipse transform="matrix(0.3962 -0.9182 0.9182 0.3962 -1074.7332 1275.1874)" className="st9" cx="432.17" cy="1454.72" rx="5.66" ry="3.92"/>
              
                <ellipse transform="matrix(0.5556 -0.8315 0.8315 0.5556 -1039.3217 1013.8688)" className="st9" cx="428.76" cy="1479.16" rx="9.15" ry="13.07"/>
              
                <ellipse transform="matrix(0.6451 -0.7641 0.7641 0.6451 -957.76 861.7704)" className="st9" cx="448.81" cy="1461.91" rx="5.66" ry="3.92"/>
              
                <ellipse transform="matrix(0.7827 -0.6224 0.6224 0.7827 -825.1387 604.8757)" className="st9" cx="453.68" cy="1484.13" rx="5.66" ry="3.92"/>
                
                <path className="st10" d="M357.12,1366.65l-12.63-8.27c0,0,0.87-11.32,5.23-13.07c4.36-1.74,8.71,4.79,8.71,4.79L357.12,1366.65z"/>
                <path className="st11" d="M354.07,1344.88c0,0,4.36,2.18,0.44,19.6c-2.18-1.74-4.79-3.48-7.4-5.23
                  C347.11,1359.25,347.98,1344,354.07,1344.88z"/>
                <path className="st6" d="M341.88,1366.65c0,0,42.24-9.15,62.71,18.73c5.66,13.5-10.89,48.34-23.95,56.18
                  c-4.79-4.79-6.53-19.6-15.24-20.9c-8.71-1.31-35.71,23.95-49.65,13.5c-10.02-7.84-17.42-18.73-21.78-30.49"/>
                <path d="M380.21,1442.43h-0.44c-1.74-2.61-3.48-5.23-4.36-8.27c-2.18-5.66-5.23-11.76-10.02-12.63c-3.05-0.44-8.71,2.61-14.81,6.1
                  c-11.76,6.53-26.13,14.37-35.28,7.4c-10.02-8.27-17.42-18.73-22.21-30.92c0-0.44,0-0.87,0.44-0.87s0.87,0,0.87,0.44l0,0
                  c4.36,11.76,11.76,22.21,21.78,30.05c8.71,6.53,22.65-1.31,33.53-7.4c6.53-3.48,12.63-6.97,15.68-6.1
                  c5.66,0.87,8.71,7.4,11.32,13.07c0.87,2.61,2.18,4.79,3.48,6.97c13.07-8.27,29.18-41.81,23.52-54.87
                  c-20.03-27-61.41-18.29-61.84-18.29c-0.44,0-0.87,0-0.87-0.44s0-0.87,0.44-0.87l0,0c0.44,0,42.68-9.15,63.15,18.73l0,0
                  c2.61,6.53,0.87,17.86-4.79,30.49c-5.66,12.19-13.07,22.21-19.6,26.13C380.64,1441.99,380.64,1441.99,380.21,1442.43z"/>
                <path className="st7" d="M280.91,1390.6c0,0,7.4-4.36,14.37,16.11c11.32,32.66,30.92,37.89,56.62,19.16c3.05-2.18,9.15-7.4,14.37-5.66
                  c2.18,0.44,6.1,3.92,10.89,15.24c0.87,1.74,1.74,3.48,3.05,4.79c14.37-12.63,23.08-30.49,24.82-49.65c0-9.15-10.45,3.05-22.65,0.87
                  c-12.19-3.05-23.95-7.84-35.28-13.94c-12.19-7.84-27-10.02-40.94-5.23C296.59,1376.23,287.44,1382.33,280.91,1390.6z"/>
                <path className="st9" d="M296.59,1411.07c0,0,8.27,50.08,32.66,54.87c13.5,2.61,17.42-8.27,23.52-9.58c6.53-0.87,13.07-0.44,19.6,0.44
                  c0,0-12.63-21.34-11.76-34.84C327.94,1437.64,318.8,1450.7,296.59,1411.07z"/>
                <path d="M333.61,1466.82c-1.31,0-3.05,0-4.36-0.44c-10.45-2.18-19.6-12.19-26.13-29.61c-3.05-8.27-5.66-16.98-6.97-25.69
                  c0-0.44,0-0.44,0.44-0.87c0.44,0,0.44,0,0.87,0.44c18.73,33.53,27.44,29.18,50.95,16.55c3.48-1.74,7.84-3.92,12.19-6.1
                  c0,0,0.44,0,0.87,0l0.44,0.44c-0.87,13.5,11.32,34.41,11.76,34.41c0,0,0,0.44,0,0.87l-0.44,0.44c-6.53-0.87-12.63-1.31-19.16-0.44
                  c-2.18,0.87-3.92,2.18-5.66,3.92C344.06,1464.2,338.83,1466.82,333.61,1466.82z M297.89,1414.56
                  c2.61,11.76,11.76,46.16,31.36,50.52c6.53,1.31,13.07-0.87,16.98-5.66c1.74-1.74,3.92-3.05,6.1-3.92
                  c6.1-0.87,12.19-0.87,18.29,0.44c-2.61-4.36-11.32-20.9-11.32-33.1c-3.92,2.18-7.84,3.92-11.32,5.66
                  C326.2,1440.69,316.19,1445.91,297.89,1414.56L297.89,1414.56z"/>
                <path className="st3" d="M359.74,1455.49c10.45,1.31-10.45-10.45-16.55-3.48c-13.07-3.48-17.86,12.63-17.86,12.63s9.15,3.48,13.94,0.87
                  c3.48-2.18,6.53-4.35,9.15-7.4C351.9,1455.93,355.82,1454.62,359.74,1455.49z"/>
                <path d="M334.91,1466.82c-3.48,0-6.53-0.87-9.58-1.74h-0.44v-0.44c0,0,5.23-16.55,18.29-13.07c4.36-4.36,13.94-0.87,17.42,1.31
                  c1.74,1.31,2.61,2.18,2.18,3.05s-1.31,0.87-3.48,0.87c-3.92-0.44-7.4,0.44-10.89,2.61c-2.61,3.05-6.1,5.66-9.58,7.4
                  C337.96,1466.82,336.65,1466.82,334.91,1466.82z M326.2,1464.2c3.92,1.74,8.71,1.74,12.63,0.87c3.48-1.74,6.53-4.35,9.15-7.4
                  c3.48-2.61,7.4-3.48,11.76-3.05l0,0c0.87,0,1.31,0,2.18,0c-2.61-2.18-5.66-3.48-8.71-3.92c-2.61-0.44-6.97-1.31-9.15,1.31
                  l-0.44,0.44h-0.44C332.3,1449.4,327.51,1461.16,326.2,1464.2L326.2,1464.2z"/>
                <path className="st9" d="M338.83,1377.54c0,0,6.53-6.53,20.47,0c13.94,6.53,20.47,12.63,29.61,12.63c4.79,0,9.15-4.79,11.76-5.66
                  c1.31-0.44,3.05,0.44,3.48,1.74c0,0,3.05,5.23-5.23,14.81c-7.4,8.27-10.02,10.02-23.95,6.53c-13.94-3.48-6.53-8.71-14.37-14.37
                  c-7.84-5.66-13.94-3.48-17.86-6.1C340.57,1384.51,338.83,1381.02,338.83,1377.54z"/>
                <path d="M314.44,1353.59L314.44,1353.59c-0.44,0-0.87-0.44-0.44-0.87l0,0c3.05-6.1,2.18-13.07-1.74-18.29
                  c-0.44-0.44-0.44-0.87,0-0.87c0.44-0.44,0.87-0.44,0.87,0c4.36,5.66,4.79,13.5,1.74,20.03
                  C314.88,1353.59,314.88,1353.59,314.44,1353.59z"/>
                <path d="M290.93,1350.1L290.93,1350.1c-0.44,0-0.44-0.44-0.44-0.44l0,0c0.44-4.36,2.61-7.84,5.66-10.89
                  c8.71-7.4,21.78-5.23,22.21-5.23c0.44,0,0.44,0.44,0.44,0.87l0,0c0,0.44-0.44,0.44-0.87,0.44c0,0-13.07-2.18-21.34,4.79
                  c-3.05,2.61-4.79,6.1-5.23,10.02C291.8,1350.1,291.36,1350.54,290.93,1350.1z"/>
                <ellipse transform="matrix(0.1711 -0.9853 0.9853 0.1711 -1071.1243 1419.7382)" cx="308.2" cy="1346.45" rx="5.23" ry="3.48"/>
                <ellipse transform="matrix(0.1711 -0.9853 0.9853 0.1711 -1045.4818 1473.7047)" cx="353.1" cy="1358.19" rx="4.36" ry="3.05"/>
                <path d="M354.51,1366.22L354.51,1366.22c-0.44,0-0.44-0.44-0.44-0.87l0,0c3.05-13.5,1.74-17.86,0.87-19.6
                  c-0.44-0.44-0.44-0.44-0.87-0.87c0,0-6.1,0-6.53,14.37c0,0.44-0.44,0.44-0.44,0.44l0,0c-0.44,0-0.44-0.44-0.44-0.44l0,0
                  c0.44-8.71,2.61-12.63,4.36-13.94c0.87-0.87,2.18-1.31,3.48-1.31c0.87,0,1.74,0.44,2.18,1.31c1.31,2.18,2.61,7.4-0.44,20.47
                  C355.38,1366.22,354.95,1366.22,354.51,1366.22L354.51,1366.22z"/>
                <path d="M354.07,1366.65c-0.44,0-0.44,0-0.44-0.44c-3.05-3.48-6.97-6.1-11.32-7.84c-0.44,0-0.44-0.44-0.44-0.87l0,0
                  c0-0.44,0.44-0.44,0.87-0.44c4.36,1.74,8.71,4.36,11.76,8.27c0.44,0.44,0.44,0.87,0,0.87L354.07,1366.65z"/>
                <path d="M356.69,1366.22L356.69,1366.22c-0.44,0-0.44-0.44-0.44-0.87c2.61-10.45,1.31-14.81-0.87-18.29
                  c-1.74-2.18-3.92-4.36-6.97-5.23c-0.44,0-0.44-0.44-0.44-0.87l0,0c0-0.44,0.44-0.44,0.87-0.44l0,0c3.05,0.87,5.66,3.05,7.84,5.66
                  c2.18,3.92,3.48,8.71,0.87,19.16C357.56,1366.22,357.12,1366.22,356.69,1366.22L356.69,1366.22z"/>
                <circle cx="320.11" cy="1414.56" r="1.74"/>
                <path d="M312.27,1415.86c-10.45,2.61-20.9,5.66-30.92,9.58c-8.71,3.92-16.55,9.15-23.08,15.68c-3.92,3.92-7.84,7.84-11.32,12.19
                  c-0.87,0.87,0.44,2.18,1.31,1.31c6.1-7.4,12.63-13.94,20.47-19.6c7.84-5.66,16.98-10.02,26.57-12.63l17.86-5.23
                  c0.44,0,0.44-0.87,0.44-1.31C313.14,1415.86,312.7,1415.86,312.27,1415.86L312.27,1415.86z"/>
                <path d="M344.06,1358.38L344.06,1358.38c-0.44,0-0.44-0.44-0.44-0.87c0.87-11.76,8.27-14.37,8.71-14.81c0.44,0,0.87,0,0.87,0.44
                  s0,0.87-0.44,0.87c-0.44,0-6.97,2.61-7.84,13.5C344.93,1358.38,344.49,1358.38,344.06,1358.38L344.06,1358.38z"/>
                <path d="M320.11,1356.2L320.11,1356.2c-14.37-7.84-32.66-4.79-33.1-4.79s-0.87-0.44-0.87-0.44c0-0.44,0.44-0.87,0.44-0.87l0,0
                  c0,0,19.16-3.05,33.53,4.79c0.44,0,0.44,0.44,0.44,0.87S320.54,1356.2,320.11,1356.2z"/>
                <circle cx="310.96" cy="1402.8" r="1.74"/>
                <circle cx="319.67" cy="1391.91" r="1.74"/>
                <path d="M314.88,1388.86c-8.71-5.23-18.29-10.02-27.44-14.37c-9.15-3.48-18.73-5.23-28.74-5.66c-5.66,0-11.32,0-16.98,0.87
                  c-0.87,0-0.87,1.74,0,1.74c12.19-1.74,24.82-0.87,37.02,2.18c12.63,3.48,23.95,10.45,35.28,16.55
                  C315.31,1390.6,316.19,1389.3,314.88,1388.86z"/>
                <path d="M287.01,1404.54c-2.61,0-4.79-0.44-7.4-0.87c-0.44,0-0.44-0.44-0.44-0.87s0.44-0.44,0.87-0.44c0,0,17.42,3.92,19.6-6.1
                  c0-0.44,0.44-0.44,0.87-0.44c0.44,0,0.44,0.44,0.44,0.87l0,0C299.2,1403.23,292.23,1404.54,287.01,1404.54z"/>
                <path d="M301.38,1401.49c-10.45-2.18-20.47-3.48-30.92-3.92c-10.02,0-19.6,1.31-29.18,4.79c-5.23,1.74-10.45,3.92-15.24,6.53
                  c-0.87,0.44,0,1.74,0.87,1.31c10.89-5.66,23.08-9.15,35.71-10.45c13.07-1.31,26.13,1.74,38.76,3.92
                  C302.25,1402.8,302.68,1401.49,301.38,1401.49L301.38,1401.49z"/>
                <circle cx="381.51" cy="1428.93" r="1.74"/>
                <circle cx="381.08" cy="1417.17" r="1.74"/>
                <circle cx="395.88" cy="1410.64" r="1.74"/>
                <path d="M385.43,1425.01c5.66,7.84,9.58,17.42,10.89,27c0.87,7.4,0,14.81-1.74,22.21c-0.87,3.48-1.74,6.97-2.61,10.45
                  c0,0.44,0.44,0.87,0.87,0.87c0.44,0,0.87,0,0.87-0.44c1.74-6.53,3.48-13.07,4.36-20.03c0.87-8.27,0-16.98-2.61-24.82
                  c-2.18-5.66-4.79-10.89-8.27-16.11C386.3,1423.27,385,1424.14,385.43,1425.01L385.43,1425.01z"/>
                <path d="M392.84,1415.86c10.02,3.48,18.29,10.45,24.39,18.73c2.61,3.48,4.36,7.84,5.66,12.19c0,0.44,0.87,0.44,1.31,0.44
                  s0.44-0.44,0.44-0.87c-4.36-13.07-13.5-23.95-26.13-29.61c-1.74-0.87-3.48-1.74-5.23-2.18
                  C391.96,1413.69,391.53,1415.43,392.84,1415.86L392.84,1415.86z"/>
                <path d="M398.93,1406.72c8.27-2.61,17.42-2.18,25.7,1.74c6.97,3.48,13.07,7.84,17.86,13.5c2.61,2.61,4.79,5.66,7.4,8.71
                  c0.44,0.87,1.74-0.44,1.31-1.31c-9.58-11.76-20.9-23.95-36.58-25.69c-5.23-0.44-10.45,0-15.68,1.31c-0.44,0-0.44,0.87-0.44,1.31
                  C398.5,1406.72,398.93,1406.72,398.93,1406.72L398.93,1406.72z"/>
                <path d="M369.75,1783L369.75,1783c-0.44,0-0.44-0.44-0.44-0.87l0,0c0,0,6.1-16.11-3.05-27.44c-0.44-0.44-0.44-0.87,0-0.87
                  c0.44-0.44,0.44-0.44,0.87,0l0,0c9.58,11.76,3.05,28.31,3.05,28.74C370.62,1783,370.19,1783.43,369.75,1783z"/>
                <path d="M186.4,1797.8c-0.44,0-0.44,0-0.44-0.44c0,0-6.53-16.98,3.05-28.74c0.44-0.44,0.44-0.44,0.87,0c0.44,0.44,0.44,0.44,0,0.87
                  l0,0c-9.15,10.89-3.05,27-3.05,27.44C186.84,1797.37,186.84,1797.8,186.4,1797.8L186.4,1797.8z"/>
                <path d="M204.7,1797.37c-0.44,0-0.44,0-0.44-0.44c0,0-6.53-16.98,3.05-28.74c0.44-0.44,0.44-0.44,0.87,0
                  c0.44,0.44,0.44,0.87,0,0.87c-9.15,10.89-3.05,27-3.05,27.44C205.13,1796.93,205.13,1797.37,204.7,1797.37L204.7,1797.37z"/>
                <path d="M384.56,1779.51L384.56,1779.51c-0.44,0-0.44-0.44-0.44-0.87l0,0c0,0,6.1-16.11-3.05-27.44c-0.44-0.44-0.44-0.87,0-0.87
                  c0.44-0.44,0.87-0.44,0.87,0c9.58,11.76,3.05,28.31,3.05,28.31C385.43,1779.51,385,1779.51,384.56,1779.51z"/>
              </svg>
            </div>

            <div id="we-believe">
              <div className="heading">{blocks[2].heading}</div>
            </div>


          </div>

          <div 
            id="section-2A" 
            ref={el => animateRefs.current[2] = el} 
            className={'intersection '+(isIntersecting[2] ? 'enter' : playStreet>1 ? 'show' : 'hide')}
          >

                <div id="kitchen-scene">
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    xmlnsXlink="http://www.w3.org/1999/xlink" 
                    x="0px" 
                    y="0px"
                    viewBox="0 0 1920 825"
                  >
                    <g id="kitchen-floor">
                      <path className="st12" d="M812.57,567.06h899.77c94.16,0,106.05,68.27,22.15,156.38l-81.59,85.69H713.78
                        c-98.87,0-93.89-73.88,5.98-161.05L812.57,567.06z"/>
                    </g>
                    <g id="kitchen-background">
                      <path id="kitchen-background-shape" className="st13" d="M1699.06,355.31c-3.73,75.09-21.71,143.75-82.4,240.68
                        c-70.74,41.13-84.36,51.5-264.21,82.51c-26.74,4.61-252.18,19.06-330.65,0c-143.67-34.91-238.78-226-115.15-378.35
                        c70.74-87.19,212-45.72,333.94-111.87c108.42-58.8,215.25-131.6,289.52-131.6C1663.36,56.68,1710.16,132.22,1699.06,355.31z"/>
                      <g>
                        <defs>
                          <path id="SVGID_3_" d="M1699.06,355.31c-3.73,75.09-21.71,143.75-82.4,240.68c-70.74,41.13-84.36,51.5-264.21,82.51
                            c-26.74,4.61-252.18,19.06-330.65,0c-143.67-34.91-238.78-226-115.15-378.35c70.74-87.19,212-45.72,333.94-111.87
                            c108.42-58.8,215.25-131.6,289.52-131.6C1663.36,56.68,1710.16,132.22,1699.06,355.31z"/>
                        </defs>
                        <clipPath id="SVGID_4_">
                          <use xlinkHref="#SVGID_3_"  style={{overflow: 'visible'}} />
                        </clipPath>
                        <g id="kitchen-counter-pot-shelves" className="st14">
                          <g id="kitchen-counter">

                            <rect x="832.75" y="366.77" className="st15" width="949.06" height="226.15"/>
                            <rect x="879.55" y="381.34" className="st16" width="124.67" height="47.28"/>
                            <rect x="879.55" y="435.62" className="st16" width="124.67" height="138.35"/>
                            <rect x="1026.11" y="443.71" className="st16" width="148.83" height="96.05"/>
                            <rect x="747.72" y="381.34" className="st16" width="124.67" height="47.28"/>
                            <rect x="747.72" y="435.62" className="st16" width="124.67" height="138.35"/>
                            <circle className="st10" cx="810.32" cy="405.83" r="9.05"/>
                            <rect x="1198.95" y="381.34" className="st16" width="192.51" height="47.28"/>
                            <rect x="1199.02" y="435.62" className="st16" width="192.51" height="138.35"/>
                            <rect x="1538.95" y="381.34" className="st16" width="124.67" height="47.28"/>
                            <rect x="1538.95" y="435.62" className="st16" width="124.67" height="138.35"/>
                            <rect x="1670.59" y="381.34" className="st16" width="124.67" height="47.28"/>
                            <rect x="1670.59" y="435.62" className="st16" width="124.67" height="138.35"/>
                            <circle className="st10" cx="1733.19" cy="405.83" r="9.05"/>
                            <rect x="1404.99" y="381.34" className="st16" width="124.67" height="47.28"/>
                            <rect x="1404.99" y="435.62" className="st16" width="124.67" height="138.35"/>
                            <line className="st16" x1="1295.27" y1="436.14" x2="1295.27" y2="574.59"/>
                            <line className="st16" x1="1014.49" y1="367.97" x2="1014.49" y2="592.73"/>
                            <line className="st16" x1="1187.63" y1="367.97" x2="1187.63" y2="592.73"/>
                            <line className="st16" x1="1187.75" y1="424.07" x2="1014.68" y2="424.07"/>
                            <rect x="725.83" y="357.2" className="st17" width="462.34" height="10.01"/>
                            <rect x="1021.7" y="351.53" className="st10" width="158.41" height="4.66"/>
                            <rect x="1403.87" y="357.2" className="st17" width="462.34" height="10.01"/>
                            
                            <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 142.723 1064.3728)" className="st18" cx="1356.17" cy="359.9" rx="5.59" ry="7.99"/>
                            
                            <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 150.0901 1082.151)" className="st18" cx="1381.32" cy="359.9" rx="7.99" ry="5.59"/>
                            <path className="st18" d="M1363.6,301.3v73.13h10.19V297.6c0,0,1-22.05-14.81-22.22c-19.44-0.21-16.67,17.59-16.67,17.59h10.19
                              c0,0-1.53-6.94,5.86-7.41C1364.57,285.16,1363.6,301.3,1363.6,301.3z"/>
                            <circle className="st19" cx="942.15" cy="405.83" r="9.05"/>
                            <circle className="st19" cx="1168.42" cy="395.92" r="9.05"/>
                            <circle className="st19" cx="1143.02" cy="395.92" r="9.05"/>
                            <circle className="st19" cx="1060.65" cy="395.92" r="9.05"/>
                            <circle className="st19" cx="1035.25" cy="395.92" r="9.05"/>
                            <ellipse className="st19" cx="1101.72" cy="395.92" rx="19.51" ry="9.05"/>
                            <ellipse className="st19" cx="1100.78" cy="461.21" rx="74.46" ry="3.02"/>
                            <ellipse className="st19" cx="1100.78" cy="507.18" rx="74.46" ry="3.02"/>
                            <circle className="st19" cx="1601.55" cy="405.83" r="9.05"/>
                            <circle className="st19" cx="1467.59" cy="405.83" r="9.05"/>
                          </g>
                          <g id="kitchen-pot">
                            <path className="st19" d="M1123.72,322.26h45.45c2.39,0,4.32,1.93,4.32,4.32v20.38c0,2.39-1.93,4.32-4.32,4.32h-45.45
                              c-2.39,0-4.32-1.93-4.32-4.32v-20.38C1119.4,324.2,1121.34,322.26,1123.72,322.26z"/>
                            <path className="st19" d="M1173.3,330.98h44.52c1.14,0,2.06,0.92,2.06,2.06v1.19c0,1.14-0.92,2.06-2.06,2.06h-44.52
                              c-1.14,0-2.06-0.92-2.06-2.06v-1.19C1171.24,331.91,1172.16,330.98,1173.3,330.98z"/>
                          </g> 
                          <g id="kitchen-shelves">
                          <path className="st11" d="M1492.04,198.33c-2.36,4.86-7.8,3.94-9.83-0.24s-2.66-17.53-2.66-17.53h15.31
                            C1494.86,180.56,1494.4,193.46,1492.04,198.33z"/>
                          <path className="st20" d="M1492.04,200.37c-2.36,4.86-7.8,3.94-9.83-0.24s-2.66-17.53-2.66-17.53h15.31
                            C1494.86,182.6,1494.4,195.5,1492.04,200.37z"/>
                          <path className="st21" d="M1533.84,204.47h9.71c0.84,0,1.53,0.68,1.53,1.53v0.47c0,0.85-0.69,1.53-1.53,1.53h-9.71
                            c-0.84,0-1.53-0.68-1.53-1.53V206C1532.31,205.16,1533,204.47,1533.84,204.47z"/>
                          <path className="st11" d="M1549.81,188.82c-5.62,5.62-18.55,4.55-23.37-0.27c-4.82-4.82-6.33-20.24-6.33-20.24h36.4
                            C1556.51,168.31,1555.43,183.2,1549.81,188.82z"/>
                          <path className="st22" d="M1559.95,143.43c0,0,6,11.39,6,22.49s-6,40.93-6,40.93h26.25v-25.33c0,0,13.47,1,13-11.1
                            c-0.25-6.19-3.56-8.37-13.3-9.15v-6.63L1559.95,143.43z"/>
                          <path className="st23" d="M1585.85,165.76v11.75c0,0,9.35,1.26,9.77-6.3S1585.85,165.76,1585.85,165.76z"/>
                          <path className="st22" d="M1492.04,202.54c-2.36,4.87-7.8,3.95-9.83-0.23s-2.66-17.53-2.66-17.53h15.31
                            C1494.86,184.78,1494.4,197.68,1492.04,202.54z"/>
                          <path className="st21" d="M1492.04,205.23c-2.36,4.87-7.8,3.95-9.83-0.23s-2.66-17.53-2.66-17.53h15.31
                            C1494.86,187.47,1494.4,200.37,1492.04,205.23z"/>
                          <path className="st11" d="M1509.8,198.33c-2.36,4.86-7.8,3.94-9.83-0.24s-2.66-17.53-2.66-17.53h15.31
                            C1512.62,180.56,1512.16,193.46,1509.8,198.33z"/>
                          <path className="st20" d="M1549.81,192.81c-5.62,5.62-18.55,4.55-23.37-0.27c-4.82-4.82-6.33-20.24-6.33-20.24h36.4
                            C1556.51,172.3,1555.43,187.19,1549.81,192.81z"/>
                          <path className="st22" d="M1549.81,196.79c-5.62,5.62-18.55,4.56-23.37-0.27c-4.82-4.83-6.33-20.24-6.33-20.24h36.4
                            C1556.51,176.28,1555.43,191.18,1549.81,196.79z"/>
                          <path className="st21" d="M1549.81,200.91c-5.62,5.62-18.55,4.55-23.37-0.27c-4.82-4.82-6.33-20.24-6.33-20.24h36.4
                            C1556.51,180.4,1555.43,195.29,1549.81,200.91z"/>
                          <path className="st20" d="M1509.8,200.37c-2.36,4.86-7.8,3.94-9.83-0.24s-2.66-17.53-2.66-17.53h15.31
                            C1512.62,182.6,1512.16,195.5,1509.8,200.37z"/>
                          <path className="st22" d="M1509.8,202.54c-2.36,4.87-7.8,3.95-9.83-0.23s-2.66-17.53-2.66-17.53h15.31
                            C1512.62,184.78,1512.16,197.68,1509.8,202.54z"/>
                          <path className="st24" d="M1509.8,205.23c-2.36,4.87-7.8,3.95-9.83-0.23s-2.66-17.53-2.66-17.53h15.31
                            C1512.62,187.47,1512.16,200.37,1509.8,205.23z"/>
                          <line className="st25" x1="1522.28" y1="182.49" x2="1547.7" y2="182.49"/>
                          <line className="st25" x1="1522.28" y1="177.93" x2="1547.7" y2="177.93"/>
                          <line className="st25" x1="1522.28" y1="174.13" x2="1547.7" y2="174.13"/>
                          <line className="st25" x1="1522.28" y1="169.82" x2="1547.7" y2="169.82"/>
                          <path className="st26" d="M1629.38,230.38h19.75c2.39,0,4.32,1.93,4.32,4.32v37.75c0,2.39-1.93,4.32-4.32,4.32h-19.75
                            c-2.39,0-4.32-1.93-4.32-4.32V234.7C1625.06,232.32,1626.99,230.38,1629.38,230.38z"/>
                          <path className="st26" d="M1629.04,221.91h20.31c2.39,0,4.32,1.93,4.32,4.32l0,0c0,2.39-1.93,4.32-4.32,4.32h-20.31
                            c-2.39,0-4.32-1.93-4.32-4.32l0,0C1624.72,223.85,1626.66,221.91,1629.04,221.91z"/>
                          <path className="st26" d="M1597.32,247.18h19.75c2.39,0,4.32,1.93,4.32,4.32v21.26c0,2.39-1.93,4.32-4.32,4.32h-19.75
                            c-2.39,0-4.32-1.93-4.32-4.32V251.5C1593,249.12,1594.94,247.18,1597.32,247.18z"/>
                          <path className="st26" d="M1596.98,238.7h20.31c2.39,0,4.32,1.93,4.32,4.32l0,0c0,2.39-1.93,4.32-4.32,4.32h-20.31
                            c-2.39,0-4.32-1.93-4.32-4.32l0,0C1592.66,240.64,1594.6,238.7,1596.98,238.7z"/>
                          <path className="st26" d="M1564.03,260h19.75c2.39,0,4.32,1.93,4.32,4.32v8.72c0,2.39-1.93,4.32-4.32,4.32h-19.75
                            c-2.39,0-4.32-1.93-4.32-4.32v-8.72C1559.71,261.94,1561.65,260,1564.03,260z"/>
                          <path className="st26" d="M1563.69,251.53H1584c2.39,0,4.32,1.93,4.32,4.32l0,0c0,2.39-1.93,4.32-4.32,4.32h-20.31
                            c-2.39,0-4.32-1.93-4.32-4.32l0,0C1559.37,253.47,1561.31,251.53,1563.69,251.53z"/>
                          <path className="st27" d="M1629.06,223.76c0,0-1.85,0.52-2,2.11s2,2.52,2,2.52"/>
                          <path className="st27" d="M1596.87,240.48c0,0-1.85,0.53-2,2.12s2,2.51,2,2.51"/>
                          <path className="st27" d="M1563.14,253.58c0,0-1.85,0.53-2,2.12c-0.15,1.59,2,2.51,2,2.51"/>
                          <line className="st27" x1="1627.08" y1="270.85" x2="1627.08" y2="235.73"/>
                          <line className="st27" x1="1594.74" y1="272.8" x2="1594.74" y2="250.78"/>
                          <line className="st25" x1="1584.33" y1="205.01" x2="1584.33" y2="182.99"/>
                          <line className="st25" x1="1498.84" y1="181.63" x2="1508.26" y2="181.63"/>
                          <line className="st25" x1="1498.84" y1="183.62" x2="1508.26" y2="183.62"/>
                          <line className="st23" x1="1498.84" y1="186.14" x2="1508.26" y2="186.14"/>
                          <line className="st25" x1="1498.84" y1="188.45" x2="1508.26" y2="188.45"/>
                          <line className="st25" x1="1480.53" y1="181.63" x2="1489.94" y2="181.63"/>
                          <line className="st25" x1="1480.53" y1="183.62" x2="1489.94" y2="183.62"/>
                          <line className="st25" x1="1480.53" y1="186.14" x2="1489.94" y2="186.14"/>
                          <line className="st25" x1="1480.53" y1="188.45" x2="1489.94" y2="188.45"/>
                          <line className="st27" x1="1561.3" y1="274.34" x2="1561.3" y2="263.05"/>
                          <path className="st19" d="M1472.71,208.45h200.96c2.39,0,4.32,1.93,4.32,4.32v0.41c0,2.39-1.93,4.32-4.32,4.32h-200.96
                            c-2.39,0-4.32-1.93-4.32-4.32v-0.41C1468.39,210.39,1470.33,208.45,1472.71,208.45z"/>
                          <path className="st19" d="M1472.71,277.81h200.96c2.39,0,4.32,1.93,4.32,4.32v0.41c0,2.39-1.93,4.32-4.32,4.32h-200.96
                            c-2.39,0-4.32-1.93-4.32-4.32v-0.41C1468.39,279.75,1470.33,277.81,1472.71,277.81z"/>
                        </g>
                        </g>
                      </g>
                    </g>
                    <g id="kitchen-foreground">
                      <g id="Table">
                        <rect x="1243.25" y="556.59" className="st20" width="20.03" height="161.6"/>
                        <rect x="1358.89" y="556.59" className="st20" width="20.03" height="161.6"/>
                        <rect x="1201.34" y="538.05" className="st20" width="215.67" height="19.37"/>
                      </g>
                      <g id="People">
                        <path className="st19" d="M1424.46,345.82c0,0-23.94,8.26-16.92,27.11c16.92,45.47,49.24,16.66,49.24,16.66l27.33,13.85
                          c0,0,11.47-32.31-6.5-51C1456.21,330.25,1424.46,345.82,1424.46,345.82z"/>
                        <path className="st28" d="M1440.43,465.88c0,0-14.12,24.43-17,26.71s-39.3,13.08-41.59,13.08s-12.56-4-18.84,4
                          c2.85,4,4.57,1.15,6.28,1.72c1.71,0.57-10.28,3.42-9.14,5.14s5.14,10.27,12,9.13s13.13-10.28,13.13-10.28s45.68-11.72,49.68-13.41
                          s18.58-28.74,18.58-28.74L1440.43,465.88z"/>
                        <path className="st28" d="M1446.95,438.89c2.43,3.65,3.65,19.49,3.65,19.49l20.94-18l-5.08-4.16c12.13-5,16.82-27.57,17.84-33.91
                          c-3.23-1.38-9.58-16.49-30.75-20.32c-25.6-3.12-47.3-14.3-47.3-14.3s0.61,27.72,5.76,41.26c-0.49,2.86-3.94,6.14-2.37,8.07
                          c1.38,1.7,2.54,0.78,7,1.63C1418.95,428.34,1429.29,440.67,1446.95,438.89z"/>
                        <line className="st29" x1="1451.68" y1="435.98" x2="1456.4" y2="452.2"/>
                        <polygon className="st28" points="1434.88,715.09 1432.96,723.02 1442.27,727.68 1448.57,713.72 		"/>
                        <path className="st30" d="M1469.95,576.38c0,0-14.69,70.05-17.54,78c0,0-20.7,57.1-20.88,62.18c16.56,6.85,16.91,6.9,16.91,6.9
                          s25.13-55.39,33.69-93.65c1.27-43.16,4.42-45.17,4.42-45.17L1469.95,576.38z"/>
                        <path className="st30" d="M1448.78,453.51c0,0,14-18.89,24.37-12.19s15.84,18.89,25,48.73c5.84,18.82,13.6,36.98,23.15,54.21
                          c1.83,3.66,6.7,21.93,5.48,28.63c-1.22,6.7-31.29,16.46-45.19,15.84s-18-2.33-18-2.33s7.17-40.31,3.52-58
                          c-3.65-17.69-12.79-20.1-12.79-20.1s-22.08-9.51-5.26-26.38c-4.23-3.52-8.82-6.58-13.7-9.13L1448.78,453.51z"/>
                        <path className="st31" d="M1419.44,426.05l19.92-8.42c0,0,0,15.91-12.24,17.38"/>
                        <path className="st19" d="M1436.72,392.46c-2.63-1.76-5.93-2-9-1.89c-0.69,0.01-1.24,0.56-1.25,1.25c0.01,0.69,0.56,1.24,1.25,1.25
                          c0.69,0,1.39,0,2.08,0c0.67,0.02,1.34,0.08,2,0.18c0.68,0.11,1.35,0.27,2,0.5l0.44,0.17l0.32,0.16c0.34,0.16,0.68,0.35,1,0.55
                          c0.6,0.34,1.36,0.14,1.71-0.45c0.33-0.6,0.13-1.35-0.45-1.71L1436.72,392.46z"/>
                        <path className="st28" d="M1150.83,502.4c-1.87,3.59-1.42,18.24-1.42,18.24l-19.1-7.41c0.47-3.85,1.26-7.66,2.35-11.38
                          c-9.15-5.29-15-17.13-16.6-22.85c2.83-1.59,2.15-32.85,29.18-41.38c23.22-4.8,34.66-4.37,34.66-4.37s1.56,23.43,0,38.23
                          c0.73,2.57,4.22,5.24,3,7.16c-1.11,1.7-2.26,1-6.25,2.19C1175.49,489.97,1167.21,502.28,1150.83,502.4z"/>
                        <path className="st28" d="M1248.14,640.4c0,0-0.79,100.65,0.76,102.1c1.55,1.45,7.74,4.7,7.74,4.7l-3.91,11.6l-13.52,2.52
                          c0,0-0.78-16.64-1.11-22.61c-0.24-4.51-7.55-66.33-7.55-66.33L1248.14,640.4z"/>
                        <path className="st3" d="M1232.01,661.05l-102.9-1.83l-1.78-39.81l38,1.78c4.8,0.28,9.61-0.13,14.29-1.22
                          c9.84-2.27,46.72-3.17,46.72-3.17s18-5.77,23.89,3s-0.38,113.5-0.38,113.5l-17.42,3.21L1232.01,661.05z"/>
                        <path className="st20" d="M1163.05,418.17c0,0,19.89,5.23,17.08,21.17c-11.05-4-13.83,1.47-33,9.85c-14.23,2.21,1.53,50.6-13,58.31
                          c-21.07,11.2-29.8-3-29.8-3s-6.3-61.42,10.92-80.77C1133.31,403.42,1163.05,418.17,1163.05,418.17z"/>
                        <path className="st31" d="M1174.12,489.58l-19.92-8.42c0,0,0,15.91,12.24,17.37"/>
                        <path className="st19" d="M1158.59,455.69c0.51-0.35,1.07-0.64,1.65-0.86c0.65-0.23,1.32-0.41,2-0.53h0.29l0.55-0.06l1.05-0.07
                          c0.71,0,1.43,0,2.15,0c0.69-0.01,1.24-0.56,1.25-1.25c-0.01-0.69-0.56-1.24-1.25-1.25c-3.07-0.1-6.37,0.13-9,1.89
                          c-0.58,0.36-0.78,1.11-0.45,1.71c0.35,0.59,1.11,0.79,1.71,0.45L1158.59,455.69z"/>
                        <path className="st32" d="M1167.06,470.87c1.92,0.2,3.85-0.2,5.53-1.16c0.28-0.21,0.33-0.61,0.12-0.88c-0.17-0.23-0.48-0.31-0.75-0.2
                          c-1.49,0.84-3.2,1.19-4.9,1c-0.35,0.04-0.59,0.36-0.55,0.7c0.04,0.29,0.26,0.51,0.55,0.55V470.87z"/>
                        <path className="st32" d="M1169.05,444.73c1.75-0.11,3.46-0.6,5-1.44c0.59-0.31,1.24-0.5,1.9-0.56c0.85-0.01,1.68,0.23,2.39,0.69
                          c0.32,0.14,0.69-0.01,0.83-0.33c0.11-0.27,0.03-0.57-0.2-0.75c-1.48-0.94-3.32-1.09-4.93-0.4c-1.65,0.68-3.12,1.52-5,1.54
                          c-0.35,0.04-0.59,0.36-0.55,0.7c0.04,0.29,0.26,0.51,0.55,0.55L1169.05,444.73z"/>
                        <path className="st32" d="M1177.6,446.97c0.35-0.04,0.59-0.36,0.55-0.7c-0.04-0.29-0.26-0.51-0.55-0.55c-0.35,0.04-0.59,0.36-0.55,0.7
                          C1177.09,446.71,1177.32,446.94,1177.6,446.97z"/>
                        <path className="st32" d="M1148.4,480.21c-0.1,1.01,0.11,2.03,0.61,2.91c0.18,0.29,0.56,0.39,0.86,0.22c0.3-0.17,0.39-0.55,0.22-0.85
                          c0,0,0,0,0,0c-0.06-0.1-0.12-0.19-0.17-0.29v-0.08c0-0.06,0,0,0,0s0-0.08-0.05-0.11c-0.07-0.21-0.12-0.42-0.16-0.64v-0.06
                          c0-0.07,0-0.07,0,0c-0.01-0.06-0.01-0.11,0-0.17v-0.32c0-0.22,0-0.45,0-0.67c0.04-0.35-0.2-0.66-0.55-0.7
                          c-0.35-0.04-0.66,0.2-0.7,0.55c-0.01,0.05-0.01,0.11,0,0.16L1148.4,480.21z"/>
                        <path className="st32" d="M1153.3,474.97c-1.01-0.1-2.03,0.12-2.91,0.62c-0.29,0.18-0.39,0.56-0.22,0.86c0.17,0.3,0.55,0.39,0.85,0.22
                          c0,0,0,0,0,0c0.1-0.06,0.2-0.12,0.3-0.17h0.07c0.06,0,0.05,0,0,0c0.04-0.03,0.08-0.04,0.12-0.05c0.2-0.07,0.42-0.13,0.63-0.16l0,0
                          c0,0,0.13,0,0.17,0h0.32h0.67c0.34,0,0.62-0.28,0.63-0.62c-0.01-0.34-0.29-0.62-0.63-0.63V474.97z"/>
                        <path className="st33" d="M1177.78,621.11c0,0-0.76-15.47-3.92-39.42c-1.46-11,9.3-22.79,4.65-35.85c-2.87-8-16.6-16.6-21.21-26.66
                          c-8-17.46-27.73-11.3-27.73-11.3s-14.17,1.9-12.59,50.55c1.46,45.06,11.36,73.54,11.39,75.66S1177.78,621.11,1177.78,621.11z"/>
                        <path className="st28" d="M1198.79,531.97c0,0,9.19-9.49,16.38-7.88c4.69,1,11.5,0.43,16.61,10.38c-3.53,2.85-16.29-3.36-16.29-3.36
                          l-59.7,60.86c0,0-7.1-5.44-22.23-30.89c13.25-2.07,19.44-2.93,19.44-2.93l7.25,8.22L1198.79,531.97z"/>
                        <polyline className="st34" points="1125.96,537.82 1129.82,561.7 1154.81,557.54 1151.35,536.1 		"/>
                        <path className="st11" d="M1370.23,511.67c-4.87,4.86-6.92,4.18-11.1,0c-4.18-4.18-9.18-17.77-9.18-17.77h30.5
                          C1380.45,493.9,1375.1,506.8,1370.23,511.67z"/>
                        <polygon className="st9" points="1382.27,511.63 1424.91,496.45 1439.67,475.45 1427.82,496.87 1382.86,513.3 		"/>
                        <polygon className="st28" points="1548.1,716.58 1554.31,721.89 1562.68,715.71 1553.09,703.76 		"/>
                        <polygon className="st9" points="1485.54,585.38 1485.26,626.78 1486.69,653.51 1482.67,628.79 1480.66,585.96 		"/>
                        <path className="st28" d="M1440.43,472.97c0,0-14.12,24.43-17,26.71s-39.3,13.08-41.59,13.08s-12.56-4-18.84,4
                          c2.85,4,4.57,1.15,6.28,1.72c1.71,0.57-10.28,3.42-9.14,5.14s5.14,10.28,12,9.13s13.13-10.28,13.13-10.28s45.68-11.69,49.68-13.4
                          s18.58-28.74,18.58-28.74L1440.43,472.97z"/>
                        <polyline className="st35" points="1471.93,465.69 1457.26,484.26 1436.28,473.35 		"/>
                        <path className="st30" d="M1484.42,645.84c0,0,0.57,13.13,9.71,17.7s43.48,39.53,46.34,43.53c2.86,4,7.9,12.43,7.9,12.43
                          s11.42-5.14,12-12.56c-19.43-38.6-53.1-61.1-53.1-61.1l7.42-65.1l-32.3,2.9C1482.39,583.64,1479.85,625.28,1484.42,645.84z"/>
                        <path className="st19" d="M1394.71,731.17c0,0,5.38-6.31,13.53-7.46s23.94,0,23.94,0l12.12,4.87l-4.35,16.84L1394.71,731.17z"/>
                        <path className="st19" d="M1538.8,757.81c0,0-2.57-7.9,0.72-15.45c3.29-7.55,12.52-20.39,12.52-20.39l10.49-7.78l12.06,12.54
                          L1538.8,757.81z"/>
                        <circle className="st19" cx="1478.28" cy="347.32" r="15.3"/>
                        <circle className="st19" cx="1427.13" cy="401.53" r="2.2"/>
                        <path className="st19" d="M1284.39,756.65c0,0-4.15-7.19-12-9.81s-23.44-4.34-23.44-4.34l-12.8,2.58l1.25,17.35L1284.39,756.65z"/>
                        <path className="st36" d="M1346.6,520.42l3.69-11.72c0,0,1.06-3.34,7.06-3.55c3.49-0.12,13.21-0.34,20.74-0.51
                          c5.41-0.12,5.87,4.06,5.87,4.06l-3.39,13.77c0,0-21.16,1.8-29,2.07C1343.73,524.81,1346.6,520.42,1346.6,520.42z"/>
                        <circle className="st19" cx="1166.92" cy="462.6" r="2.2"/>
                      </g>
                      <g id="Table_Legs">
                        <rect x="1216.49" y="556.59" className="st20" width="20.03" height="216.48"/>
                        <rect x="1386.59" y="556.6" className="st20" width="20.03" height="216.48"/>
                      </g>
                      <g id="Chair">
                        <rect x="1102.86" y="658.07" className="st24" width="13.89" height="108.14"/>
                        <rect x="1222.45" y="658.07" className="st24" width="13.89" height="108.14"/>
                        <rect x="1122.46" y="661.58" className="st24" width="13.89" height="77"/>
                        <rect x="1205.26" y="661.58" className="st24" width="13.89" height="77"/>
                        <path className="st24" d="M1227.34,654.65h-111.33v-100c0,0-2.56-11-8.78-10.62c-6.22,0.38-4.4,12.45-4.4,12.45v114.08h123.12
                          L1227.34,654.65z"/>
                      </g>
                    </g>
                  </svg>
                </div>
              
                <div id="independent-living">
                  <div className="heading">{blocks[1].heading}</div>
                  <div className="copy">{blocks[1].copy}</div>
                </div>

          </div>

        </div>
      
        <div id="section-3" 
          className={playStreet>1 ? 'show' : 'hide'}
        >

            <div id="triangles-container">
              <div 
                id="triangle-c"
                className={isIntersecting[3] ? 'enter' : ''}
              >
                <svg 
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" 
                  y="0px"
                  viewBox="0 0 2600 2230"  
                >            
                  <path id="triangle-1" className="st17" d="M-70.69,2915.28L1605.8,1378.92L231.91,1258.26L-70.69,2915.28z"/>
                </svg>
              </div>

              <div 
                id="triangle-c-intersection"
                ref={el => animateRefs.current[3] = el} 
                className="intersection"
              >
              </div>

              <div 
                id="triangle-b"
                className={isIntersecting[4] ? 'enter' : ''}
              >
                <svg 
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" 
                  y="0px"
                  viewBox="0 0 2600 2230"  
                >
                  <path id="triangle-2" className="st1" d="M1791.83,260.29c22.79,255.06,45.58,510.12,68.36,765.18
                    c83.03,929.38,166.07,1858.76,249.1,2788.14c-1205.42-518.5-2410.84-1037-3616.27-1555.5L1791.83,260.29z"/>
                </svg>
              </div>
              
              <div 
                id="triangle-b-intersection"
                ref={el => animateRefs.current[4] = el} 
                className="intersection"
              >
              </div>

              <div 
                ref={el => animateRefs.current[5] = el} 
                id="triangle-a"
                className={isIntersecting[5] ? 'intersection enter' : 'intersection'}
              >
                <svg 
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" 
                  y="0px"
                  viewBox="0 0 2600 2230"  
                >
                  <path className="st0" d="M-22.57-1.03l2616.02,651.41l-2875.43,532.86"/>
                </svg>
              </div>
            </div>
{/* 
            <div 
              id="footer"
              className={playStreet>1 ? 'show' : 'hide'}
            >
              <svg 
                version="1.1" 
                xmlns="http://www.w3.org/2000/svg" 
                xmlnsXlink="http://www.w3.org/1999/xlink" 
                x="0px" 
                y="0px"
                viewBox="0 0 2600 2230"  
              >            
                <path className="st18" d="M1422.29,1875.68l-2023.2,170.63l0.72,353.97l3690,62l0.29-339.83L1422.29,1875.68
                  z"/>
              </svg>
            </div>
*/}
            <div id="owners" className={isIntersecting[5] ? 'enter' : ''}>
              <svg 
                version="1.1" 
                xmlns="http://www.w3.org/2000/svg" 
                xmlnsXlink="http://www.w3.org/1999/xlink" 
                x="0px" 
                y="0px"
                viewBox="0 0 2600 2230"  
              >
                <g id="owners">
                  <path id="owners-background-shape" className="st2" d="M965.63,491.9c0,0-57.17,264.11,193.31,275
                    s322.99-32.28,438.37-73.51c130.47-46.63,166.04-114.35,163.36-204.2s-23.11-227.75-114.31-310.4
                    c-110.96-100.57-304.89-135.2-451.91-57.23C1086.93,178.59,976.51,325.81,965.63,491.9z"/>
                  <g>
                    <defs>
                      <path id="SVGID_5_" d="M965.63,491.9c0,0-57.17,264.11,193.31,275s322.99-32.28,438.37-73.51
                        c130.47-46.63,166.04-114.35,163.36-204.2s-23.11-227.75-114.31-310.4c-110.96-100.57-304.89-135.2-451.91-57.23
                        C1086.93,178.59,976.51,325.81,965.63,491.9z"/>
                    </defs>
                    <clipPath id="SVGID_6_">
                      <use xlinkHref="#SVGID_5_"  style={{overflow: "visible"}}/>
                    </clipPath>
                    <g className="st4">
                      <path id="road" className="st3" d="M916.66,702.12c5.36-8.16,13.25-24.1,70.18-34.25
                        c64.46-11.49,285.42-63.89,352.47-157.4c24.9-33.96,27.16-58.86-31.69-67.97c-58.86-9.11-174.3-9.05-217.31-18.11
                        c-43.01-9.06-69.75-18.08,11.32-33.95c78.15-15.31,153.02-12.34,119.98-15.85c-41.03-4.36-400.57-10.29-400.57-10.29l49.87-11.38
                        c0,0,384.72,11.63,411.25,19.52c20.49,6.11-7.95,11.79-32.98,14.06c-17.65,1.61-101.45,7.83-106.66,14.08
                        c-5.21,6.25,6.25,10.87,76.07,14c0,0,251.15-3.58,304.3,75.62s-16.41,163.42-140.9,276.43
                        c-61.66,55.97-152.37,156.8-187.28,177.97c-103.9,63.03-239.45-119.3-233.69-110.51l-17.54-20.17
                        c-22.59-31.08-35.1-58.67-34.56-75.81C908.99,725.35,909.91,712.4,916.66,702.12z"/>
                      
                      <g id="road-markings">
                        <path className="st2" d="M898.6,178.8c-0.7,0-0.7,1.2,0,1.2C899.3,180,899.3,178.8,898.6,178.8z"/>
                        <path className="st19" d="M1369.8,588.9c-4.4,5.2-8.9,10.5-13.5,15.5c-4.6,5-9.5,9.9-14.4,14.7c-2.8,2.7-5.7,5.4-8.6,8
                          c-0.4,0.4,0.2,0.7,0.6,0.5c5.2-4.7,10.2-9.3,15-14.3c4.9-4.9,9.5-9.9,14-15c2.6-2.9,5.1-5.8,7.5-8.8
                          C1370.7,588.9,1370.1,588.5,1369.8,588.9z"/>
                        <path className="st19" d="M1309.4,651.1c-6.9,7.9-14.1,15.5-21.5,22.9c-7.3,7.4-14.9,14.4-22.7,21.3c-4.5,3.9-9,7.8-13.6,11.6
                          c-0.6,0.5,0.4,1.2,0.8,0.7c8.2-6.7,16.1-13.6,23.7-20.7c7.6-7.2,15-14.4,22.1-21.9c4-4.3,8-8.6,12-13.1
                          C1310.7,651.4,1309.8,650.6,1309.4,651.1z"/>
                        <path className="st20" d="M1382.7,447.3c-7.1-2-14.2-4.2-21.2-6.2c-0.7-0.2-1.1,1-0.4,1.2c7.1,2,14.2,4.2,21.2,6.2
                          C1383.1,448.8,1383.4,447.6,1382.7,447.3z"/>
                        <path className="st21" d="M1334.2,434.3c-4.7-1-9.5-1.9-14.1-2.7c-0.7-0.1-1.1,1-0.4,1.2c4.7,1,9.5,1.9,14.1,2.7
                          C1334.6,435.5,1334.9,434.5,1334.2,434.3z"/>
                        <path className="st22" d="M1294.9,426.7c-3.1-0.2-6-0.4-9.1-0.6c-1.1-0.1-1.1,2,0,2.1c3.1,0.2,6,0.4,9.1,0.6
                          C1296,429,1296,426.8,1294.9,426.7z"/>
                        <path className="st23" d="M1269.4,426.3h-3.9c-0.4,0-0.6,0.2-0.6,0.6c0,0.4,0.2,0.6,0.6,0.6h3.9c0.4,0,0.6-0.2,0.6-0.6
                          C1270,426.6,1269.7,426.3,1269.4,426.3z"/>
                      </g>
                     
                      <g id="right-person">
                        <path className="st5" d="M1476.89,362.65c0,0,19.15,17.56,22.92,19.3c4.41,2.04,6.67-0.33,6.67-0.33s19.98-18.99,19.98-23.14
                          c6.82,37.33,2.66,48.23,2.66,48.23l-24.96,81.79C1504.16,488.5,1474.4,412.54,1476.89,362.65z"/>
                        <polyline className="st6" points="1471.06,383.68 1485.14,397.76 1503.19,383.32 1520.67,397.76 1534.75,383.68 				"/>
                        <path className="st5" d="M1406.04,645.65c0,0,9.42-12.57,19.87-12.05c-2.09-6.26-9.93-21.94-9.93-21.94l-19.84,9.93L1406.04,645.65z
                          "/>
                        <path className="st7" d="M1405.54,640.4c0,0,6.8,21.94,6.8,25.59c0,3.65,4.7,17.24,10.97,20.9s8.35,5.22,9.4,2.61
                          s-6.78-6.8-10.97-14.11c14.63,12.02,19.33,15.67,20.9,13.12c1.57-2.55-7.85-9.4-7.85-9.4c-4-2.9-7.08-6.89-8.87-11.49
                          c12.53,13.58,18.96,21.71,22.98,15.67c2.25-3.37-7.31-8.35-7.31-8.35s-8.88-7.83-10.45-13.58c8.88,7.83,18.8,18.28,21.94,15.16
                          c3.14-3.12-9.38-15.23-9.38-15.23l-0.52-4.7c0,0-8.29-18.65-17.27-22.94C1416.92,629.36,1405.54,640.4,1405.54,640.4z"/>
                        <path className="st7" d="M1613.63,638.55c0,0-9.23,21.03-9.65,24.66s-6.62,16.6-13.26,19.52c-6.64,2.92-8.9,4.26-9.63,1.54
                          c-0.74-2.72,7.51-5.96,12.48-12.77c-15.88,10.28-20.97,13.38-22.23,10.61c-1.25-2.77,8.85-8.45,8.85-8.45
                          c4.31-2.42,7.83-6.04,10.12-10.42c-14,12.08-21.69,19.61-24.61,12.97c-2.92-6.64,8.2-7.48,8.2-7.48s9.72-6.78,11.92-12.32
                          c-9.72,6.78-20.76,16.05-23.51,12.58c-2.75-3.47,11.05-13.99,11.05-13.99l1.05-4.61l19.72-20.89L1613.63,638.55z"/>
                        <polygon className="st8" points="1439.08,632.11 1435.77,805.98 1503.31,805.03 1507.32,677.73 1513.52,805.6 1584.91,807.44 
                          1589.51,623.92 				"/>
                        <path className="st8" d="M1467.27,367.1c0,0-7.98,10.73-13,12.47c-5.32,1.86-14.8,5.65-14.8,5.65l-25.95,16.8
                          c0,0-10.81,0.83-11.64,15.8s-6.65,26.6-7.49,34.09c-0.83,7.49-9.88,30.79-12.33,40.54c-2.25,8.87-10.95,28.52-10.95,36.83
                          s-3.33,7.49,0,20.78c3.33,13.3,15.8,71.54,27.42,89.81c2,4.21,8.69,9.48,8.69,9.48s-4.61-16.16-1.19-20.27
                          c5.69-6.89,17.61-5.38,17.61-5.38s-12.62-56.98-12.62-70.35s3.33-14.96,3.33-22.44c0-7.48,19.08-36.59,19.08-44.12v49.07
                          c0,11.64-1.63,36.44-1.63,41.44s-4.77,16.81-5.62,24.3c-0.85,7.49-6.06,23.23-1.9,35.7c4.16,12.47,26.6,27.42,42.4,27.42
                          c15.8,0,28.26,14.23,28.26-39.04c4.98,36.58,5.82,47.4,37.42,44.91s43.24-10.81,43.24-10.81s22.45-12.47,22.45-19.08
                          s-5-25.77-5-28.27c0-2.5-7.07-31.88-7.07-40.2s-1.19-71.22-2.07-80.37c-0.88-9.15-0.82-15.8-0.82-15.8s23.28,50.72,23.28,59.04
                          c0,8.31,2.49,11.65-0.83,28.27s-6.65,29.94-6.65,37.42c0,7.48-11.64,19.96,2.5,25.78c14.14,5.82,15.8,16.69,10.8,22.45
                          c14.96-26.61,36.58-80.65,36.58-94.79c0-14.14-1.66-20.78-1.66-25.78s-5.82-24.11-5.82-24.11s-1.67-14.96-6.65-26.6
                          c-4.98-11.64-24.03-56.59-24.03-64.9s-15.8-13.31-15.8-13.31s-27.72-16.2-35.77-20.8c-2.16-1.19-32-19.33-32-19.33
                          s2.07,34.3-6.24,60.07c-8.31,25.77-16.63,69.02-16.63,69.02s-28.21-71.91-27.42-125.96
                          C1473.5,362.53,1467.27,367.1,1467.27,367.1z"/>
                        <circle className="st9" cx="1504.92" cy="531.14" r="4.03"/>
                        <circle className="st9" cx="1502.86" cy="561.52" r="4.03"/>
                        <path className="st10" d="M1435.68,392.18l8.22,22.07l15.79-0.63l-13.89,11.36c0,0,51.8,62,55.59,84.66c0,0-8.94,101.95-6.37,116.03
                          "/>
                        <path className="st10" d="M1563.28,387.12l-3.79,25.27l-15.79-5.69l12,16.42c0,0-35.37,53.66-38.53,63.8"/>
                        <path className="st5" d="M1597.24,615.32l-3.14,15.16l2.61,4.7c0,0,7.82-0.14,10.97,5.75c3.28,6.14,1.56,9.92,1.56,9.92
                          s9.92-10.45,9.92-21.94C1619.16,617.41,1597.24,615.32,1597.24,615.32z"/>
                        <path className="st10" d="M1465.25,585.58c0,0-3.43,17.89-12.35,18.53c-8.92,0.64-24.41,1.63-24.41,1.63"/>
                        <path className="st10" d="M1552,585.58c0,0,3.42,17.89,12.34,18.53s24.42,1.63,24.42,1.63"/>
                        <circle className="st11" cx="1502.08" cy="393.6" r="2.44"/>
                        <circle className="st11" cx="1502.08" cy="417.54" r="2.44"/>
                        <circle className="st11" cx="1502.08" cy="441.73" r="2.44"/>
                        <circle className="st11" cx="1502.66" cy="465.56" r="2.44"/>
                        <path className="st12" d="M1456.62,314.7c0,0-1.31-21.57-2.72-29.36c-1.41-7.79,8.49-16.99,10.61-21.24
                          c2.12-4.24,11.33-16.99,20.53-16.99s29.45-2.8,39.35,4.98c9.9,7.79,22.66,26.23,16.94,60.91
                          c-11.33,1.42-11.01-11.39-11.01-11.39L1456.62,314.7z"/>
                        <path className="st7" d="M1465.21,303.03c0,0,6.38-4.96,6.38-11.33s-4.24-21.24,10.61-26.9c14.86-5.66,29.73-2.83,32.56-2.12
                          c2.84,0.7,13.45,7.79,14.87,26.9c0.94,8.54,8.73,21.36,8.73,21.36s7.56-13.27,6.87,8.48c-0.21,15.1-0.91,15.35-6.18,13.97
                          c-4.24,0-15.39,49.95-36.63,49.95c-21.24,0-37.92-47.04-37.92-47.04s-9.91,6.37-9.91-8.5c0-10.73-0.26-18.91,3.03-19.39
                          c2.39-0.31,4.69,1,5.63,3.22C1463.96,308.13,1465.21,303.03,1465.21,303.03z"/>
                        <path className="st13" d="M1499.02,312.41c0,0-5.38,9.97-3.84,11.92c1.54,1.96,8.06,2.68,8.06,2.68"/>
                        <path className="st12" d="M1477.42,301.96c0.61-0.42,1.26-0.77,1.96-1.03c0.8-0.28,1.62-0.49,2.46-0.63h0.33
                          c0.22-0.04,0.44-0.06,0.67-0.07c0.41,0,0.82-0.07,1.19-0.08c0.86,0,1.72,0,2.58,0c0.82-0.01,1.48-0.67,1.49-1.49
                          c-0.02-0.82-0.68-1.47-1.49-1.49c-3.67-0.11-7.6,0.15-10.73,2.27c-0.71,0.41-0.95,1.33-0.54,2.04c0.41,0.71,1.33,0.95,2.04,0.54
                          L1477.42,301.96z"/>
                        <path className="st12" d="M1462.11,309.2c0,0-1.29,23.85,2.72,35.4s14.24,27.58,28.47,27.58s23.57,0,33.8-8.9
                          c10.23-8.9,11.72-32.9,11.72-40.46s-0.14-15.16-0.14-15.16s-1.74-3.15-2.21-1.01c-1.59,7.27-5.1,20.4-6.26,25.04
                          c-1.29,5.2-8.9,8.45-8.9,8.45s-5.77-11.38-18.9-10.58c-8.44,0.5-14.99,2.91-19.36,8.8c-2.73,3.67-10.95-2.29-12.45-8.01
                          c-0.92-3.45-5.16-15.27-6.74-21.94C1463.43,306.5,1462.11,309.2,1462.11,309.2z"/>
                        <circle className="st12" cx="1517.33" cy="309.22" r="2.53"/>
                        <circle className="st12" cx="1484.79" cy="309.22" r="2.53"/>
                        <path className="st14" d="M1500.7,352.96c6.26,0.16,11.93-3.67,14.12-9.54l-27.21,0.47C1487.61,343.89,1491.84,352.96,1500.7,352.96
                          z"/>
                        <path className="st12" d="M1524.46,298.17c-3.15-2.1-7.07-2.38-10.73-2.25c-0.82,0-1.49,0.67-1.49,1.49c0,0.82,0.67,1.49,1.49,1.49
                          c0.83,0,1.67,0,2.49,0c0.8,0.03,1.59,0.1,2.38,0.21c0.81,0.13,1.61,0.33,2.38,0.6l0.52,0.2l0.38,0.18
                          c0.39,0.2,0.76,0.42,1.12,0.67c0.72,0.41,1.63,0.17,2.05-0.54c0.39-0.71,0.15-1.61-0.54-2.04L1524.46,298.17z"/>
                        <path d="M1536.44,297.56c0,0-22.76,0.44-27.59,2.21c-1.16,0.36-2.28,0.81-3.37,1.34l-8.35,0.43c-1.76-0.74-3.62-1.19-5.52-1.32
                          h-28.62v5.25l3.09,0.19c0,0,2.38,13.94,5.47,14.82c3.09,0.88,15.5,1.32,18.28,0.88c2.78-0.44,4.48-3.58,6.21-6.61
                          c1.73-3.03,2.06-8.81,6.2-8.81c4.14,0,3.46,13.21,10.73,13.21c7.27,0,16.57,0,18.63-2.65s3.11-13.87,3.11-13.87l2.06-0.21
                          L1536.44,297.56z M1489.93,317.38h-15.5c-4.48,0-5.17-12.77-5.17-12.77l14.83-0.88c0,0,9.3-0.87,11.03,2.21
                          s-3.52,11.45-5.25,11.45H1489.93z M1528.09,314.4l-15.06,1.32c-1.67,0.14-7.15-7.76-5.63-10.98c1.53-3.22,10.59-3.14,10.59-3.14
                          l14.44-0.38c0,0-0.05,12.79-4.4,13.18H1528.09z"/>
                      </g>
                      <g id="left-person">
                        <path className="st15" d="M1127.71,275.42c0,0,7.15-7.48,23.7-7.48s23.07,8.11,24.32,9.66c1.25,1.55,7.79,9.36,9.66,17.47
                          s5.79,21.07,5.48,30.12c-4.05,4.05-11.64,7.23-11.64,7.23s-3.58-22.06-3.93-27.97c-0.36-5.91-6.06-3.76-9.18-4.38
                          c-3.12-0.62-7.24-4.69-12.84-4.38c-5.6,0.31-11.07,3.14-18.64,5.69c-7.57,2.55-10.73,16.86-11.36,19.67
                          c-0.63,2.81-5.38,10.39-5.38,10.39s-2.55-6.02-5.96-8.2C1111.95,299.85,1113.7,286.96,1127.71,275.42z"/>
                        <path className="st7" d="M1114.22,324.03c-1.74-2.07-4.44-6.2-6.02,0.45c-0.95,5.83-0.95,11.78,0,17.61
                          c0.93,3.71,2.33,12.51,6.95,7.4c4.17,11.59,12.26,27.16,12.26,27.16l2.25,8.91c0,0,0.78,6.55,4.48,8.41
                          c3.71,8.35,1.38,32.89,23.62,33.39c22.24,0.5,18.06-17.6,18.06-17.6s-7.77-6.86-3.14-12.41c2.97-10.25,5.41-20.64,7.31-31.13
                          c0,0,1.98-6.09,5.09-12.97c3.24-0.47,7.42-2.78,7.42-10.19c0-7.42,7.42-30.57-6.49-16.2c-6.49-29.19-6.49-33.39-6.49-33.39
                          s-1.85-2.33-6.49-1.4c-4.64,0.93-16.69,4.17-23.16,3.71c-6.46-0.47-18.06-4.64-21.77-2.31s-6.02,11.58-6.02,18.06
                          s-4.17,19.91-4.17,19.91S1115.98,326.1,1114.22,324.03z"/>
                        <path className="st13" d="M1153.11,329.68c0,0-5.38,9.97-3.84,11.92c1.54,1.96,8.06,2.68,8.06,2.68"/>
                        <path className="st14" d="M1153.1,363.46c6.26,0.16,11.93-3.67,14.12-9.54l-27.21,0.47C1140,354.39,1144.24,363.46,1153.1,363.46z"
                          />
                        <path className="st5" d="M1128.32,380.48c0,0,19.15,17.58,22.92,19.32c4.4,2.03,6.67-0.35,6.67-0.35s19.98-18.98,19.98-23.14
                          c6.82,37.33,2.66,48.23,2.66,48.23l-24.96,81.8C1155.59,506.34,1125.81,430.37,1128.32,380.48z"/>
                        <polyline className="st6" points="1122.49,401.5 1136.57,415.58 1154.62,401.14 1172.1,415.58 1186.18,401.5 				"/>
                        <path className="st5" d="M1057.47,663.46c0,0,9.41-12.57,19.87-12.04c-2.1-6.27-4.77-22.3-4.77-22.3l-25.04,10.28L1057.47,663.46z"
                          />
                        <path className="st7" d="M1056.95,658.22c0,0,6.8,21.94,6.8,25.6s4.7,17.24,10.97,20.89c6.27,3.65,8.35,5.22,9.4,2.61
                          s-6.78-6.78-10.97-14.11c14.63,12.02,19.33,15.68,20.89,13.12c1.56-2.56-7.83-9.41-7.83-9.41c-4.01-2.89-7.09-6.89-8.87-11.49
                          c12.53,13.58,19.56,22.07,22.98,15.68c1.32-2.48-7.31-8.35-7.31-8.35s-8.88-7.85-10.45-13.58c8.87,7.83,18.8,18.28,21.94,15.14
                          s-9.41-15.14-9.41-15.14l-0.52-4.71c0,0-8.25-18.64-17.23-22.98C1068.35,647.16,1056.95,658.22,1056.95,658.22z"/>
                        <path className="st7" d="M1265.05,656.37c0,0-9.22,21.03-9.63,24.67s-6.62,16.6-13.26,19.52c-6.64,2.92-8.9,4.24-9.63,1.53
                          c-0.74-2.72,7.51-5.96,12.48-12.77c-15.88,10.29-20.97,13.39-22.24,10.61c-1.26-2.78,8.85-8.45,8.85-8.45
                          c4.31-2.42,7.82-6.04,10.12-10.41c-13.99,12.08-21.99,19.73-24.6,12.97c-1.37-3.58,8.2-7.48,8.2-7.48s9.71-6.78,11.92-12.32
                          c-9.71,6.78-20.75,16.04-23.5,12.57c-2.75-3.47,11.05-13.99,11.05-13.99l1.05-4.61l19.72-20.89L1265.05,656.37z"/>
                        <path className="st5" d="M1248.65,633.16l-3.12,15.14l2.61,4.7c0,0,7.82-0.14,10.97,5.75c3.28,6.15,1.56,9.92,1.56,9.92
                          s9.92-10.45,9.92-21.93C1270.59,635.26,1248.65,633.16,1248.65,633.16z"/>
                        <polygon className="st16" points="1090.51,649.95 1087.2,823.81 1154.74,822.87 1158.74,695.56 1164.95,823.44 1236.34,825.26 
                          1240.93,641.74 				"/>
                        <path className="st16" d="M1118.68,384.93c0,0-7.97,10.73-12.98,12.48c-5.32,1.86-14.8,5.64-14.8,5.64l-25.95,16.8
                          c0,0-10.81,0.83-11.64,15.8c-0.82,14.96-6.65,26.61-7.49,34.09s-9.88,30.8-12.34,40.54c-2.24,8.87-10.95,28.51-10.95,36.83
                          c0,8.32-3.31,7.48,0,20.78c3.31,13.31,15.8,71.54,27.42,89.8c2,4.21,8.69,9.54,8.69,9.54s-4.63-16.16-1.19-20.27
                          c5.69-6.9,17.61-7.66,17.61-7.66s-12.62-54.71-12.62-67.97s3.33-14.96,3.33-22.45s19.08-36.58,19.08-44.12v48.98
                          c0,11.65,1.67,34.92,1.67,39.92s-5.82,22.44-6.65,29.93c-0.83,7.49-8.35,19.08-4.16,31.6c4.19,12.52,26.61,27.42,42.41,27.42
                          c15.8,0,28.27,14.14,28.27-39.07c4.98,36.58,5.82,47.39,37.42,44.89s43.24-10.8,43.24-10.8s22.45-12.47,22.45-19.08
                          c0-6.61-4.39-25.85-5-28.27c-0.97-3.91-10.8-29.94-10.8-38.25s2.49-73.16,1.66-82.27c-0.83-9.11-0.83-15.8-0.83-15.8
                          s23.29,50.71,23.29,59.04c0,8.32,2.49,11.64-0.83,28.27c-3.33,16.63-6.65,29.93-6.65,37.42s-11.6,19.84,2.58,25.68
                          s15.79,16.63,10.8,22.45c14.96-26.6,36.58-80.65,36.58-94.78c0-14.13-1.66-20.8-1.66-25.78c0-4.98-5.82-24.11-5.82-24.11
                          s-1.67-14.98-6.65-26.61c-4.98-11.64-24.11-56.54-24.11-64.85c0-8.31-15.8-13.31-15.8-13.31s-27.72-16.19-35.77-20.78
                          c-2.17-1.19-32-19.33-32-19.33s2.07,34.29-6.25,60.07c-8.32,25.78-16.62,69.02-16.62,69.02s-28.22-71.91-27.42-125.96
                          C1124.87,380.37,1118.68,384.93,1118.68,384.93z"/>
                        <circle className="st9" cx="1156.35" cy="548.96" r="4.03"/>
                        <circle className="st9" cx="1154.29" cy="579.35" r="4.03"/>
                        <path className="st10" d="M1087.17,410l8.16,22.13l15.79-0.63l-13.89,11.36c0,0,51.8,61.88,55.53,84.66c0,0-8.93,101.95-6.36,116.04
                          "/>
                        <path className="st10" d="M1214.75,404.95l-3.79,25.27l-15.79-5.68l11.95,16.42c0,0-35.37,53.66-38.53,63.79"/>
                        <path className="st10" d="M1116.68,603.42c0,0-3.43,17.89-12.35,18.52c-8.92,0.63-24.41,1.63-24.41,1.63"/>
                        <path className="st10" d="M1203.41,603.42c0,0,3.43,17.89,12.35,18.52c8.92,0.63,24.41,1.63,24.41,1.63"/>
                        <circle className="st11" cx="1153.51" cy="411.42" r="2.44"/>
                        <circle className="st11" cx="1153.51" cy="435.38" r="2.44"/>
                        <circle className="st11" cx="1153.51" cy="459.56" r="2.44"/>
                        <circle className="st11" cx="1154.09" cy="483.38" r="2.44"/>
                        <path className="st12" d="M1176.34,313.21c-3.14-2.1-7.06-2.38-10.73-2.25c-0.82,0-1.49,0.67-1.49,1.49c0,0.82,0.67,1.49,1.49,1.49
                          c0.83,0,1.67,0,2.49,0c0.78,0.03,1.55,0.1,2.33,0.21c0.81,0.12,1.61,0.32,2.38,0.6l0.54,0.2c-0.14,0,0.32,0.16,0.38,0.19
                          c0.39,0.19,0.76,0.41,1.12,0.66c0.71,0.41,1.62,0.17,2.04-0.54c0.41-0.71,0.17-1.62-0.54-2.04L1176.34,313.21z"/>
                        <path className="st12" d="M1132.03,315.78c0.61-0.41,1.27-0.75,1.96-1.01c0.8-0.29,1.62-0.5,2.46-0.63l0.33-0.06l0.66-0.07
                          c0.42,0,0.84-0.02,1.25-0.07c0.86,0,1.72,0,2.58,0c0.82,0,1.49-0.67,1.49-1.49c0-0.82-0.67-1.49-1.49-1.49
                          c-3.67-0.12-7.6,0.16-10.73,2.25c-0.69,0.43-0.93,1.33-0.54,2.04C1130.4,315.96,1131.31,316.2,1132.03,315.78
                          C1132.03,315.78,1132.03,315.78,1132.03,315.78L1132.03,315.78z"/>
                        <circle className="st12" cx="1167.02" cy="323.05" r="2.53"/>
                        <circle className="st12" cx="1139.39" cy="323.05" r="2.53"/>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>

            <div id="copy-a" className={isIntersecting[5] ? 'copy enter' : 'copy'}>
              <div>{blocks[3].copy}</div>
              <div className="quote-icon-1">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" y="0px" 
                  viewBox="0 0 100 125">
                      <path d="M48.87,42.353c0-11.276-9.14-20.417-20.417-20.417c-11.277,0-19.462,8.826-20.417,20.062   c-1.458,17.145,11.567,31.5,27.38,36.067c-5.39-3.259-9.15-8.927-9.777-15.519c0.923,0.131,1.857,0.224,2.814,0.224   C39.73,62.77,48.87,53.63,48.87,42.353z"/>
                </svg>
              </div>
              <div className="quote-icon-2">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" y="0px" 
                  viewBox="0 0 100 125">
                      <path d="M48.87,42.353c0-11.276-9.14-20.417-20.417-20.417c-11.277,0-19.462,8.826-20.417,20.062   c-1.458,17.145,11.567,31.5,27.38,36.067c-5.39-3.259-9.15-8.927-9.777-15.519c0.923,0.131,1.857,0.224,2.814,0.224   C39.73,62.77,48.87,53.63,48.87,42.353z"/>
                </svg>
              </div>
              
            </div>

                  

            
            <div id="copy-b" className={isIntersecting[4] ? 'copy enter' : 'copy'}>
              <div id="left-shape"></div>
              <div id="right-shape"></div>
              {blocks[4].copy}  
            </div>

            <div id="copy-c" className={isIntersecting[3] ? 'copy enter' : 'copy'}>
              <div id="left-shape"></div>
              <div id="right-shape"></div>
              {blocks[5].copy}  
            </div>

        </div>

    </div> 
      
  );

}

export default Home;
