import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { enableBodyScroll } from 'body-scroll-lock'; // disableBodyScroll, // from 'body-scroll-lock'; // clearAllBodyScrollLocks
import ReactHtmlParser from 'react-html-parser';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import useDisableBodyScroll from '../../hooks/useDisableBodyScroll';
import useIsBreakpoint from '../../hooks/useIsBreakpoint';
import ToggleSwitchLabeled from '../Shared/ToggleSwitchLabeled';

import './DirectoryModal.scss';

// const isSafari = navigator.userAgent.indexOf("Safari") !== -1;
// const isIphone = navigator.userAgent.indexOf("iPhone") !== -1; 
// const isTouchDevice = window.matchMedia('(hover: none)').matches===true; 
// const isMobileIosSafari = isSafari && isTouchDevice;

const DirectoryModal = ({ setShowDirectoryModal, language, profiles, goToProfile }) => {

  const isSmallScreen = useIsBreakpoint(1024);

  const modalRef = useRef();
  const modalWrapperRef = useRef(); /* Different use case since want to scroll entire screen */
  const closeIconTopRef = useRef();
  const closeIconBottomRef = useRef();

  /*
  const scrollToTop = () => {
    var ua = window.navigator.userAgent;
    if (ua.indexOf('MSIE ') > 0 ||
    ua.indexOf('Trident/') > 0 ||
    ua.indexOf('Edge/') > 0) {
      modalWrapperRef.current.scrollTo(0,0);
    } else {
      modalWrapperRef.current.scroll({ 
        top: 0, 
        left: 0, 
        behavior: 'smooth'
      });
    }
  }
  */
 
  useDisableBodyScroll(modalRef);

  /*
    useDisableBodyScroll(modalRef.current, {reserveScrollBarGap: true});

    useEffect(() => {
      scrollToTop();
    }, []);
  */

/*
useEffect(() => {
  const modalElement = modalRef.current;
  // console.log('isMobileIosSafari',isMobileIosSafari);
    if (!modalElement) return;

    // Fix for iOs Safari
    // disableBodyLock is not working for iOs Safari... 
    // Scrolling sometimes freezes at bottom or top of modal
    // The solution is to prevent touch events from working on anything except menu element and elements inside of it */
/*    const handleTouchMove = (e) => {
      if (!modalRef.current) return;

      const insideMenu = [];

      modalRef.current
          .querySelectorAll("*")
          .forEach((node) => insideMenu.push(node));

      const targetIsMenu = e.target.id === modalRef.current.id;
      // console.log('e.target ', e.target, e.target.id);
      const modalContainsTarget = insideMenu.includes(e.target);
      const shouldPreventDefault = !(targetIsMenu || modalContainsTarget); 
      // console.log('shouldPreventDefault',shouldPreventDefault); 
      if (shouldPreventDefault) e.preventDefault();
    };

    if (isMobileIosSafari) {  
      window.addEventListener("touchmove", handleTouchMove, {passive: false});
    } else {
      const options = {reserveScrollBarGap: true};
      disableBodyScroll(modalElement, options);
    }

    scrollToTop();

    return () => {
      if (isMobileIosSafari) {
        window.removeEventListener("touchmove", handleTouchMove, {passive: false}); 
      }
    }
  }, []);
*/


  const [modalClass, setModalClass] = useState('show');
  const [modalScrollTop, setModalScrollTop] = useState(0);

  const closeModal = (e) => {
      if (
        !e ||
        (modalRef.current && !modalRef.current.contains(e.target)) ||
        (closeIconTopRef && closeIconTopRef.current.contains(e.target)) ||
        (closeIconBottomRef && closeIconBottomRef.current.contains(e.target))
      ) {
        setModalScrollTop(modalRef.current.scrollTop);
        setModalClass('hide');
      }
  }

  const handleModalOnEndAnimation = (e) => {
    if (e.animationName==='exit-directory-modal') {
      enableBodyScroll(modalRef.current); /* Must enable before modal disappears */
      setShowDirectoryModal(false);
    }
  }

  const [searchTerm, setSearchTerm] = useState('');

  const onChangeSearch = (e) => {
    setSearchTerm(e.target.value); 
  }

  const [sortedProfiles, setSortedProfiles] = useState();

  const [sortBy, setSortBy] = useState('firstName');

  const toggleSortBy = () => {
    const newSortBy = (sortBy==='firstName') ? 'lastName' : 'firstName';
    setSortBy(newSortBy);
  };
  

  useEffect(()=> {
    const sorted = profiles.slice().sort((a, b) => {
      if (sortBy==='firstName') {
        return a.fullName > b.fullName ? 1 : a.fullName > b.fullName ? 1 : 0
      } else {
        return a.lastName+a.firstName > b.lastName+b.firstName ? 1 : a.lastName+a.firstName > b.lastName+b.firstName ? 1 : 0
      }
    });
    setSortedProfiles(sorted);
  }, [profiles, sortBy]);

  const [searchLetters, setSearchLetters] = useState();

  useEffect(()=> {
    const letters = [];
    profiles.forEach(profile => {
      let letter = (sortBy==='firstName')
        ? profile.firstName.substring(0,1).toLowerCase()
        : profile.lastName.substring(0,1).toLowerCase();
      if (letters.indexOf(letter)<0) letters.push(letter);
    })
    letters.sort()
    setSearchLetters(letters);
  }, [profiles, sortBy]);

  const [selectedLetter, setSelectedLetter] = useState('all');
  const [selectedProfiles, setSelectedProfiles] = useState();

  useEffect(()=> {
    if (!sortedProfiles) return;
    // console.log('selectedLetter', selectedLetter);
    const selected = sortedProfiles.filter((profile) => {
      if (selectedLetter==='all') return true;
      if (sortBy==='firstName') {
        return profile?.firstName.substring(0,1).toLowerCase()===selectedLetter
      } else {
        return profile?.lastName.substring(0,1).toLowerCase()===selectedLetter
      }
    })
    // console.log('selectedProfiles', selected);
    setSelectedProfiles(selected);
  }, [sortedProfiles, selectedLetter, sortBy]);


  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(()=> {
    if (selectedLetter) {
      const alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
      const index = 1 + alphabet.findIndex(letter => letter===selectedLetter.toLowerCase());
      // console.log('selectedIndex', selectedIndex);
      setSelectedIndex(index);
    }
  }, [selectedLetter, selectedIndex]);


  const selectProfile = (profileId) => {
    const index = profiles.findIndex(profile => profile.profileId === profileId);
    goToProfile(index);
    closeModal();
  } 

  // const nodeRefs = useRef([]);
  // const nodeRef = useRef(null); /* Avoid warning: React-Transition-Group: findDOMNode is deprecated in StrictMode */
  const profilesHeightRef = useRef(null);
  const abcHeightRef = useRef(null);

  const [profilesHeight, setProfilesHeight] = useState(0);
  const [abcHeight, setAbcHeight] = useState(0);

  useLayoutEffect(()=> {
    const handleResize = () => {
      setTimeout(() => {
        if (profilesHeightRef.current) {
          setProfilesHeight(profilesHeightRef.current.clientHeight);
        } else {
          handleResize();
        }
        if (abcHeightRef.current) {
          setAbcHeight(abcHeightRef.current.clientHeight);
        } else {
          handleResize();
        }
      }, 300); // setTimeout to let render finish first
    }
    handleResize();
    window.addEventListener('resize', handleResize, false);
    return () => {
      window.removeEventListener('resize', handleResize, false);
    }
  }, [selectedLetter, setProfilesHeight, setAbcHeight]);


  return (
    <div 
      id="directory-modal-wrapper" 
      ref={modalWrapperRef}
      onClick={(e) => closeModal(e)}
      className={modalClass}
      onAnimationEnd={(e) => handleModalOnEndAnimation(e)}
    >
      <div 
        id="directory-modal-container" 
        ref={modalRef}
      >
        
        <div id="directory-modal-content">

          <div ref={closeIconTopRef} className="icon-close-modal-top" onClick={(e) => closeModal(e)}></div>
          <div ref={closeIconBottomRef} className="icon-close-modal-bottom" onClick={(e) => closeModal(e)}></div>

          <div id="directory-modal-header">
            {language==='es' ? 'Directorio del equipo' : 'Team Directory'}
            {/*directoryTitle*/}
          </div>
{/* 
          <div className="directory-modal-search-wrapper">
            <div className="directory-modal-search-container"> 
              <input className="search-field" name="search" id="search" type="text" placeholder={directory?.name || "Name"} 
                value={searchTerm}
                onChange={(e) => onChangeSearch(e)}
              /> 
              <div className="search-icon"></div>
            </div>
          </div>
*/}


          <ToggleSwitchLabeled 
            labels={{
              left: 'By First Name',
              right: 'By Last Name'
            }} 
            isLeft={sortBy==='firstName'}
            setIsLeft={toggleSortBy} 
          />

          <div className="search-alphabet-wrapper" style={{height: (abcHeight>0 ? abcHeight : '600') + 'px'}}>
            <TransitionGroup
              component={null} // Prevent rendering div
            >
              <CSSTransition
                classNames="slide"
                key={'alphabet-wrapper-'+sortBy} 
                // nodeRef={nodeRefs.current[selectedIndex]} 
                // nodeRef={heightRef}
                timeout={{
                  enter: 1000,
                  exit: 500,
                }} /* enter and exit occur simultaenously */
                // enter={false}
                // exit={false}
                /*
                addEndListener={(node, done) => {
                  node.addEventListener("transitionend", done, false);
                }}
                */
              >
                <div ref={el => abcHeightRef.current = el} className="search-alphabet-container">
                  <div className="search-alphabet">
                    <div         
                      className={`search-all${selectedLetter==='all' ? ' selected' : ''}`}
                      // onClick={() => setSelectedLetter(profile?.fullName.substring(0,1))}
                      onClick={() => setSelectedLetter('all')}
                      style={{
                        // transform: `rotate(${-15+Math.round(30*Math.random())}deg)`,
                      }}
                    >
                      <div>all</div>
                    </div>
                    {//sortedProfiles && sortedProfiles.length>0 && sortedProfiles.map((profile, index) => (
                      searchLetters && searchLetters.length > 0 && searchLetters.map((letter, index) => (
                      <React.Fragment key={'search-letter-'+index}>
                      {// (index===0 || profile?.fullName.substring(0,1) !== sortedProfiles[index-1]?.fullName.substring(0,1)) &&
                        <div 
                          className={`search-letter${selectedLetter===letter ? ' selected' : ''}`}
                        // onClick={() => setSelectedLetter(profile?.fullName.substring(0,1))}
                          onClick={() => setSelectedLetter(letter)}
                          style={{
                            transform: `rotate(${-15+Math.round(30*Math.random())}deg)`,
                          }}
                        >
                          <div>{letter}{/*profile?.fullName.substring(0,1)*/}</div>
                        </div>
                      }
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>

          <div className="directory-profiles-wrapper" style={{height: profilesHeight+'px'}}>
            <TransitionGroup
              component={null} // Prevent rendering div
            >
              <CSSTransition
                classNames="slide"
                key={'group-wrapper-'+selectedLetter+'-'+sortBy} 
                // nodeRef={nodeRefs.current[selectedIndex]} 
                // nodeRef={heightRef}
                timeout={{
                  enter: 1000,
                  exit: 500,
                }} /* enter and exit occur simultaenously */
                // enter={false}
                // exit={false}
                /*
                addEndListener={(node, done) => {
                  node.addEventListener("transitionend", done, false);
                }}
                */
              >
                <div 
                  // ref={el => nodeRefs.current[selectedIndex] = el}
                  // ref={nodeRefs.current[selectedIndex]}
                  // ref={nodeRef}
                  key={'group-'+selectedLetter+'-'+sortBy} 
                  className="directory-profiles-container"
                  //ref={heightRef} // This doesn't work, ref becomes null on window resize
                  ref={el => profilesHeightRef.current = el}
                > {/* transition classes are applied here */} 
                  <div className="directory-profiles">
                    {selectedProfiles && selectedProfiles.length>0 && selectedProfiles.map((profile, index) => {

                      /*
                      const profileClass = index%3===0 || index===0 || index===1 
                        ? 'one' 
                        : (index%3===1 || index===0) && index!==1 
                          ? 'two' 
                          : 'three';
                      */
                     
                      return (
                        <div
                          key={'profile-'+index}
                          style={{
                            /*
                            marginLeft: profile?.copy['en']?.title==='President'
                              ? 300
                              : -60+Math.round(60*Math.random()),
                            marginRight: profile?.copy['en']?.title==='Chief Executive Officer'
                              ? 300
                              : -60+Math.round(60*Math.random()),
                            marginTop: profile?.copy['en']?.title==='President' || profile?.copy['en']?.title==='Chief Executive Officer'
                              ? 40
                              : -Math.round(100*Math.random()),
                            marginBottom: profile?.copy['en']?.title==='President' || profile?.copy['en']?.title==='Chief Executive Officer'
                              ? 40
                              : 0,
                            */
                            transform: `rotate(${-15+Math.round(30*Math.random())}deg)`,
                            transition: '.6s'
                          }}
                        >
                          {/*(index===0 || profile?.fullName.substring(0,1) !== profiles[index-1]?.fullName.substring(0,1)) &&
                            profile?.copy['en']?.title!=='President' &&
                            profile?.copy['en']?.title!=='Chief Executive Officer' &&
                            <div 
                              className="directory-letter"
                              style={{
                                transform: `rotate(${-15+Math.round(30*Math.random())}deg)`,
                              }}
                            >
                              <div>{profile?.fullName.substring(0,1)}</div>
                            </div>
                          */}
                          <div 
                            className="photo"
                            // className={'profile '+profileClass}
                            onClick={() => selectProfile(profile.profileId)}
                          >
                            <div className="photo-wrap">
                              <div className="side side-front">
                                <div>
                                  <img src={profile.images[0].imageUrl} alt={profile?.fullName} />
                                  {/* src={process.env.PUBLIC_URL+'/images/'+photo.src} */}
                                </div>
                                <div className="caption">
                                  <div>{profile?.fullName}</div>
                                  <div>{ReactHtmlParser(profile?.copy[language]?.title.replace(/\\n/g,'<br />'))}
                                  </div>
                                </div>
                              </div>
                            </div>


        {/*
                          <div className="profile-name">{profile?.fullName}</div>
                          <div className="profile-title">{profile?.copy[language]?.title}</div>
        */}
                        </div>

                      </div>
                      )
                    })}
                  </div>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </div>  
      </div>
    </div>
  )
}

export default DirectoryModal;
