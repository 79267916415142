import { useState, useEffect } from 'react';

function useIsBreakpoint (breakpoint) {
  // Is the browser window width less than the given breakpoint?
  
  const [isBreakpoint, setIsBreakpoint] = useState(false);

  useEffect(()=> {
    const handleResize = () => {
      const newIsBreakpoint = window.innerWidth<=breakpoint;
      if (isBreakpoint!==newIsBreakpoint) setIsBreakpoint(newIsBreakpoint);
    }
    handleResize();
    window.addEventListener('resize', handleResize, false);
    return () => {
      window.removeEventListener('resize', handleResize, false);
    }
  },[breakpoint, isBreakpoint])

  return isBreakpoint;
}

export default useIsBreakpoint;