import React, { } from 'react';
import './Error404.scss';
import '../Shared/LogoIcon.scss';

const Error404 = ({data, englishFormData}) => {

  return (
    <div className="not-found-container">

      <div className="background">
        <div className="left"></div>
        <div className="right"></div>  
      </div>
      <div className="pink-background"></div>

      <div className="page-heading">
        <div>
          <div>4<span>0</span>4</div>
          <div className="error-lion-head"></div>
        </div>
      </div>

      <div className="not-found-content">
        <div className="copy-form-wrapper">
          <div className="not-found-copy">
            Sorry, page not found
          </div>
        </div>
      </div>

    </div>
  )
}

export default Error404;
