import React, { useState, useEffect } from 'react';
import { useLocation, Link } from "react-router-dom";  
import ReactHtmlParser from 'react-html-parser';
import SafetyCommittee from '../SafetyCommittee/SafetyCommittee';

// import Error404 from '../Error/Error404';   
// import scrollToTop from '../utilities/scrollToTop';

//const env = dotenv.config().parsed;
// var hostServer = REACT_APP_HOST_SERVER; /* Requires npm install --save-dev dotenv to be installed */
import './Footer.scss';
// import esopLogo from '../../images/esop-logo.png';
import phaLogo from '../../images/pha-logo.png';
import providerOfChoice2022Logo from '../../images/provider-of-choice-seal-2022.png';
import providerOfChoice2023Logo from '../../images/provider-of-choice-seal-2023.png';

// import data from '../../translations/es.json';


function Footer({data, baseUrl, language}) {

  
  const { 
    general, home, services, qualifyingForCare, caregivers, resources, 
    employment, employeeResources, employeeAssistance, contactUs, privacyPolicy 
  } = data; // ourTeam, articles, 
  // const { footer } = general;
  // const { emergency } = footer;


  const [showPicker, setShowPicker] = useState(false);

  /*
  const openPicker =()=>{
    if (showPicker===false) setShowPicker(true);
  }
  */
 
  const togglePicker = ()=> {
    setShowPicker(!showPicker);
  }

  const closePicker =()=>{
    if (showPicker===true) setShowPicker(false);
  }


  const location = useLocation();

  useEffect(() => {
    setShowPicker(false);
  }, [location]);


  // const dispatch = useDispatch();
  return (
 
  <div id="footer">
    <SafetyCommittee 
      showPicker={showPicker}
      closePicker={closePicker} 
      general={general}
      language={language}
    />
    <div id="footer-top">
      <div id="footer-top-shape">
        <svg 
          version="1.1" 
          xmlns="http://www.w3.org/2000/svg" 
          xmlnsXlink="http://www.w3.org/1999/xlink" 
          x="0px" 
          y="0px"
          viewBox="0 0 3691.01 586.6" 
        >            
          <path className="cls-1" d="M2593.49,4410.4,570.28,4581l.72,354,3690,62,.29-339.83Z" transform="translate(-570.28 -4410.4)"/>
        </svg>
      </div>
    </div>
    <div id="footer-bottom">
      <div id="footer-links">
        <ul> 
          <li><Link to={baseUrl}>{home && home[language].title}</Link></li>
          <li><Link to={baseUrl+'/our-team'}>{general && general[language].ourTeam}</Link></li>
          <li><Link to={baseUrl+'/services'}>{services && services[language].title}</Link></li>
          <li><Link to={baseUrl+'/qualifying-for-care'}>{qualifyingForCare && qualifyingForCare[language].title}</Link></li>
          <li><Link to={baseUrl+'/caregivers'}>{caregivers && caregivers[language].title}</Link></li>
          <li><Link to={baseUrl+'/patient-resources'}>{resources && resources[language].title}</Link></li>
          <li><Link to={baseUrl+'/blog'}>{general && general[language].articles}</Link></li>
          <li><Link to={baseUrl+'/employment'}>{employment && employment[language].title}</Link></li>
          <li><Link to={baseUrl+'/employee-resources'}>{employeeResources && employeeResources[language].title}</Link></li>
          <li><Link to={baseUrl+'/employee-assistance'}>{employeeAssistance && employeeAssistance[language].title}</Link></li>
          <li><Link to={baseUrl+'/contact-us'}>{contactUs && contactUs[language].title}</Link></li>
        </ul>  
      </div>
      <div className="social-container">
        <a href="https://www.facebook.com/Red-Lion-Health-Care-277654772876850" target="_blank" rel="noreferrer"><div className="facebook-icon"></div></a>
        <a href="https://www.instagram.com/redlion_healthcare" target="_blank" rel="noreferrer"><div className="instagram-icon"></div></a>
        <a href="https://twitter.com/RedLionHomeCare" target="_blank" rel="noreferrer"><div className="twitter-icon"></div></a>
      </div>
      <div id="footer-logos">
        {/*<div><img src={esopLogo} target="_blank" alt="ESOP" /></div>*/}
        <div className="provider-of-choice">
          <div><img src={providerOfChoice2022Logo} alt="Provider of Choice 2022" /></div>
          <div><img src={providerOfChoice2023Logo} alt="Provider of Choice 2023" /></div>
        </div>
        <div>
          <div><img className="pha-logo" src={phaLogo} alt="Pennsylvania Homecare Association" /></div>
          <div id="safety-committee" onClick={togglePicker}>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 202.45 214.17">
                <path d="M132,82.18c-6.85,14.11-13.12,27.48-19.89,40.58a15.68,15.68,0,0,1-7.22,6.7c-10.1,4.28-20.52,7.78-30.7,11.88a9.2,9.2,0,0,0-4.58,4.56c-3.6,8.58-6.75,17.35-10.15,26-2.33,5.94-6.55,8.09-11.69,6.13-4.44-1.69-6.1-6.56-4-12.24,4.11-10.88,8.11-21.82,12.66-32.52,1-2.4,3.8-4.61,6.3-5.69,7.72-3.34,15.69-6.08,24.53-9.42-2-1-3-1.6-4.17-2-9.24-3.32-18.44-6.74-27.78-9.76a8.86,8.86,0,0,0-6.28.72c-8,4.49-15.7,9.4-23.57,14.07-5.3,3.15-10,2.42-12.71-1.83-2.88-4.46-1.46-9.29,3.89-12.59,8.64-5.32,17.55-10.23,26-15.9,5.29-3.57,10.13-3.88,15.89-1.55C66.3,92.5,74.37,95.09,82.58,98,87.82,87.22,93,76.63,98.32,65.54c-7.33-4-14.49-8.14-21.85-11.88a10,10,0,0,1-6-8.45c-1.24-10.57-2.68-21.13-4-31.7-.51-4.17,1.88-7.71,5.63-8C76.22,5.09,79,7,79.55,11.19c1.3,9,2.29,18.16,3.84,27.18a8.35,8.35,0,0,0,3.77,5.49c14.53,7,29.26,13.52,43.84,20.39a27.78,27.78,0,0,1,8.07,5.51c7.64,7.9,14.92,16.15,22.43,24.17a8.55,8.55,0,0,1,2.19,9.09c-3,10-5.62,20.18-8.48,30.26-1.43,5.05-4.47,7-8.71,5.92-3.79-1-5.46-4.39-4.16-9.28,2.22-8.36,4.72-16.64,6.93-25,.37-1.39.47-3.54-.33-4.45C143.68,94.5,138.13,88.77,132,82.18Z" transform="translate(-11.28 -5.42)"/>
                <path d="M119.63,176.45c12.42-1.4,24.66-2.4,36.75-4.32,5.42-.86,9-11.39,6.18-17.47-1.28-2.79-.74-4.57.68-6.75,5.07-7.77,9.38-16.16,15.29-23.22,6.8-8.12,17.5-9.23,27.21-10.92,6.22-1.08,10.21,6.9,6.64,12.51q-20.64,32.4-41.59,64.6c-3.46,5.3-9,7.72-15.25,8.4-11.41,1.26-22.83,2.34-34.2,3.83a46.4,46.4,0,0,0-25.83,12c-6.07,5.54-11.78,5.81-18.64,1.49-3.36-2.11-6.65-4.33-10.79-7,9.2-15,18.09-29.75,27.31-44.31,4.82-7.61,12.23-10.74,21.2-11.07,11.31-.42,22.6-1.41,33.9-2.06,5.46-.31,8.53,2.29,8.8,7.15.26,4.71-2.59,7.67-7.95,8.13-8,.69-15.92,1.45-23.9,1.9C121.24,169.53,119.51,171.48,119.63,176.45Z" transform="translate(-11.28 -5.42)"/>
                <path d="M144.6,41.66a16.31,16.31,0,0,1-16.8,16.56A16.62,16.62,0,0,1,128,25C137.59,25,144.64,32,144.6,41.66Z" transform="translate(-11.28 -5.42)"/>
              </svg>
            </div>
            <div>{general && general[language]?.footer.safetyCommittee}</div>
          </div>
        </div>
      </div>
      <div id="emergency-situations">
        <span>{general && general[language]?.footer.emergency.title}</span>: {general && ReactHtmlParser(general[language]?.footer?.emergency?.copy)}
      </div>
      <div id="privacy-policy">
        <Link to={baseUrl+'/privacy-policy'}>{privacyPolicy && privacyPolicy[language].title.toLowerCase()}</Link>
      </div>
    </div>
  </div>

  );
}

export default Footer;
