import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import './PrivacyPolicy.scss';
// import useIsIntersecting from '../../hooks/useIsIntersecting2';


const PrivacyPolicy = ({ data }) => {

  const { title, copy } = data;

  /*
  const animateRefs = useRef([]);
  const isIntersecting = useIsIntersecting(animateRefs, '0px 0px 0px 0px', .5);
  const [graphicClassName, setGraphicClassName] = useState('');

  useEffect(() => {
    if (isIntersecting && isIntersecting[0]===true && graphicClassName==='') setGraphicClassName(' enter');
  }, [isIntersecting, graphicClassName]);
  */

  /*
  useEffect(() => {
    console.log('isIntersecting', isIntersecting);
  }, [isIntersecting]);
  */

  return (
    <div className="privacy-container">

      <div className="background">
        <div className="left"></div>
        <div className="right"></div>  
      </div>
      <div className="pink-background"></div>

      <div className="page-heading">
        {title}
      </div>

      <div className="privacy-content">

        <div className="copy-form-wrapper">

          <div className="lion-background">
            <div></div>
          </div>

          <div className="privacy-copy">
            {ReactHtmlParser(copy)}
          </div>

        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy;
