import React, { useState, useEffect, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import './Employment.scss';
import '../FormModal/FormModal.scss';
import useIsIntersecting from '../../hooks/useIsIntersecting2';

const submitUrl = process.env.REACT_APP_API_BASE_URL+'/submit-form.php';

const Employment = ({ data, englishFormData, general }) => {

  const { title, copy, form } = data;
 
  const [formData, setFormData]= useState({
    formName: 'Employment',
    firstName: '',
    lastName: '',
    email: '',
    emailConsent: false,
    phone: '',
    phoneConsent: false,
    zipCode: '',
    message: '',
    checkboxes: [],
  })

  const formRef = useRef();
  const submitButtonRef = useRef();

  /* Prevent hitting return from submitting contact form. */
  const preventSubmitWithReturn = (e) => {
    var key = e.charCode || e.keyCode || 0;     
    if (key === 13 && e.target!==document.getElementById('message')) {
      e.preventDefault();
    }
  }

  const onChangeInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })

    if (e.target.value!=='') {
      e.target.setAttribute('data-filled', 'Y');
    } else {
      e.target.setAttribute('data-filled', 'N');
    }    
  }

  const validateEmail = (e) => {
    if (e.target.value!=='') {
      // ^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$
      if (!e.target.value.match(/^([A-Za-z0-9+_-]+)(\.[A-Za-z0-9+_-]+)*@([A-Za-z0-9-]+\.)+[A-Za-z]{2,6}$/g)) {
        e.target.setCustomValidity("Invalid format."); 
        return;
      };
    }
    e.target.setCustomValidity('');
  }

  const validatePhone = (e) => {
    if (e.target.value!=='') {
        var phoneDigits = e.target.value.replace(/[^0-9]/g, '');
        if (phoneDigits.length!==10) {
          e.target.setCustomValidity('Must be 10 digits long.'); 
          return;
        }
        e.target.value = '('+phoneDigits.substr(0,3)+') '+phoneDigits.substr(3,3)+'-'+phoneDigits.substr(6,4);
        }
    e.target.setCustomValidity(''); 
  }

  const validateZipCode = (e) => {
    if (e.target.value!=='') {
        var zipDigits = e.target.value.replace(/[^0-9]/g, '');
        if (zipDigits.length!==5 && zipDigits.length!==9) {
          e.target.removeAttribute('title'); 
          e.target.setCustomValidity('Must be 5 or 9 digits long.'); 
          return;
        }
        e.target.value = (zipDigits.length===9)
          ? zipDigits.substr(0,5)+'-'+zipDigits.substr(5,4)
          : zipDigits;
        }
    e.target.setCustomValidity('');
  }


  const [submitStatus, setSubmitStatus] = useState();
  
  const submitForm = async (e) => {
    if (!formRef.current || !submitButtonRef.current) return;

    // Note: onSubmit attribute in form tag prevents the form from being submitted but still click submit button with next line to use native validate functionality
    submitButtonRef.current.click();

    if (formRef.current && formRef.current.checkValidity()) {
      setSubmitStatus('submitted');
      await fetch(submitUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(formData) 
      })
        .then(response => response.json())
        .then(data => {
          // console.log(data); 
          if (data.success===true) {
            setSubmitStatus('success');
          } else {
            console.log('API Error: ', data.error);
            setSubmitStatus('error');
          }
        })
        .catch (err => {
          console.log('API Error: ', err);
          setSubmitStatus('error');
        });
    }
  }

  const scrollToInvalid = () => {
    (function () {
      // If not done as a closure, it will fire for every invalid field (not just the first one) on form submit
      var inView = false;
      return (e) => {
        if (!inView) {
          e.target.scrollIntoView({
            behavior: 'smooth', 
            block: 'start', 
            inline: 'nearest'
          })
        }; 
        inView = true;
      }
    })();
  }

  const checkboxRefs = useRef([]);
  
  const handleChangeCheckbox = (e) => {
    let newCheckboxes = [];
    checkboxRefs.current.forEach((el, index) => {
      if (el && el.checked) newCheckboxes.push(englishFormData.checkboxes.labels[index]); // el.value
    });
    setFormData({
      ...formData,
      checkboxes: newCheckboxes
    });
  }

  const animateRefs = useRef([]);
  const isIntersecting = useIsIntersecting(animateRefs, '0px 0px 0px 0px', .5);
  const [graphicClassName, setGraphicClassName] = useState('');

  useEffect(() => {
    if (isIntersecting && isIntersecting[0]===true && graphicClassName==='') setGraphicClassName(' enter');
  }, [isIntersecting, graphicClassName]);

  /*
  useEffect(() => {
    console.log('isIntersecting', isIntersecting);
  }, [isIntersecting]);
  */

  return (
    <div className="employment-container">

      <div className="background">
        <div className="left"></div>
        <div className="right"></div>  
      </div>
      <div className="pink-background"></div>

      <div className="page-heading">
        {title}
      </div>

      <div className="employment-content">

        <div className="copy-form-wrapper">

          <div className="employment-copy">
            {ReactHtmlParser(copy)}
{/*
            <div className="employment-triangle">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 807.32 539.21">
                <polygon points="1.58 269.6 806.83 0.69 806.82 538.51 1.58 269.6"/>
              </svg>
            </div>
 */}
          </div>

          <div className="form-container" onKeyDown={(e) => preventSubmitWithReturn(e)}>
            {submitStatus==='success'  
              ? <div id="success-message">
                  <div>{ReactHtmlParser(form.successMessage.header)}</div>
                  <div>{ReactHtmlParser(form.successMessage.copy)}</div>
                </div> 
              : <form id="form" ref={formRef} autoComplete="on" onSubmit={(e) => e.preventDefault()} method="post" acceptCharset="utf-8">
                  <div className="form-row">
                      <div className="form-field">
                          <label className="form-input-label-tag">
                              <input className="form-input-field" name="firstName" id="first-name" type="text" placeholder="&nbsp;" 
                                  data-filled={formData.firstName!=='' ? 'Y' : 'N'} 
                                  value={formData.firstName}
                                  onChange={(e) => onChangeInput(e)}
                                  onInvalid={(e) => scrollToInvalid(e)} 
                                  required
                              /> 
                              <span className="form-input-desc">{form.firstName}</span>
                              <span className="form-input-border"></span>
                          </label>
                      </div>
                      <div className="form-field">
                          <label className="form-input-label-tag">
                              <input className="form-input-field" name="lastName" id="last-name" type="text" placeholder="&nbsp;" 
                                  data-filled={formData.lastName!=='' ? 'Y' : 'N'}
                                  value={formData.lastName}  
                                  onChange={(e) => onChangeInput(e)} 
                                  onInvalid={(e) => scrollToInvalid(e)}
                                  required 
                              />
                              <span className="form-input-desc">{form.lastName}</span>
                              <span className="form-input-border"></span>
                          </label>
                      </div>
                  </div>

                  <div className="form-row phone-zipcode-row">
                    <div className="form-field">
                      <label className="form-input-label-tag">
                        <input className="form-input-field" name="zipCode" id="zipCode" placeholder="&nbsp;"
                          data-filled={formData.zipCode!=='' ? 'Y' : 'N'} 
                          value={formData.zipCode}  
                          onChange={(e) => onChangeInput(e)} 
                          onBlur={(e) => validateZipCode(e)} 
                          onInvalid={(e) => scrollToInvalid(e)}
                          maxLength={10}
                        />
                        <span className="form-input-desc">{form.zipCode}</span>
                        <span className="form-input-border"></span>
                      </label>
                    </div>
                    <div className="form-field">
                      <label className="form-input-label-tag">
                        <input className="form-input-field" name="phone" id="phone" type="tel" placeholder="&nbsp;"
                          data-filled={formData.phone!=='' ? 'Y' : 'N'} 
                          value={formData.phone}  
                          onChange={(e) => onChangeInput(e)} 
                          onBlur={(e) => validatePhone(e)} 
                          onInvalid={(e) => scrollToInvalid(e)}
                        />
                        <span className="form-input-desc">{form.phone}</span>
                        <span className="form-input-border"></span>
                      </label>
                    </div>
                  </div>


                  <div className="form-row phone-consent-row">
                    <div className="form-row checklist">
                        <div className="cbx">
                          <input 
                            id="cbx-phoneConsent"
                            type="checkbox" 
                            name="phoneConsent" 
                            value={formData.phoneConsent}
                            onClick={(e) => handleChangeCheckbox(e)}
                            required={formData.phone!==''} 
                          />
                          <label htmlFor="cbx-phoneConsent"></label>
                          <svg width="15" height="14" viewBox="0 0 15 14" fill="none">
                            <path d="M2 8.36364L6.23077 12L13 2"></path>
                          </svg>
                        </div>
                        <div>{general.phoneConsent}</div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-field">
                      <label className="form-input-label-tag">
                        <input className="form-input-field" name="email" id="email" placeholder="&nbsp;" 
                          data-filled={formData.email!=='' ? 'Y' : 'N'} 
                          value={formData.email}
                          onChange={(e) => onChangeInput(e)} 
                          onBlur={(e) => validateEmail(e)} 
                          onInvalid={(e) => scrollToInvalid(e)}
                          required 
                        />
                        <span className="form-input-desc">{form.email}</span>
                        <span className="form-input-border"></span>
                      </label>
                    </div>
                  </div>
 
                  <div className="form-row email-consent-row">
                    <div className="form-row checklist">
                        <div className="cbx">
                          <input 
                            id="cbx-emailConsent"
                            type="checkbox" 
                            name="emailConsent" 
                            value={formData.emailConsent}
                            onClick={(e) => handleChangeCheckbox(e)} 
                            required
                          />
                          <label htmlFor="cbx-emailConsent"></label>
                          <svg width="15" height="14" viewBox="0 0 15 14" fill="none">
                            <path d="M2 8.36364L6.23077 12L13 2"></path>
                          </svg>
                        </div>
                        <div>{general.emailConsent}</div>
                    </div>
                  </div>
            

                  <div className="form-row">
                    <div className="form-field">
                      <label className="form-input-label-tag">
                        <textarea className="form-input-field" name="message" id="message" placeholder="&nbsp;" 
                          data-filled={formData.message!=='' ? 'Y' : 'N'} 
                          onChange={(e) => onChangeInput(e)} 
                          value={formData.message}
                          onInvalid={(e) => scrollToInvalid(e)} 
                        /> 
                        <span id="message-desc" className="form-input-desc">{form.message}</span>
                        <span className="form-input-border"></span>
                      </label>
                    </div>
                  </div>

                  <div className="form-row checklist-header">
                    {form.checkboxes.header}
                  </div>

                  {form.checkboxes.labels.map((label, index) => (
                    <div key={'checklist-row-'+index} className="form-row checklist">
                      <div className="cbx">
                        <input 
                          id={'cbx'+index} 
                          type="checkbox" 
                          name="checkboxes[]" 
                          ref={el => checkboxRefs.current[index] = el}
                          value={label}
                          onClick={(e) => handleChangeCheckbox(e)} 
                        />
                        <label htmlFor={'cbx'+index}></label>
                        <svg width="15" height="14" viewBox="0 0 15 14" fill="none">
                          <path d="M2 8.36364L6.23077 12L13 2"></path>
                        </svg>
                      </div>
                      <div>{label}</div>
                    </div>
                  ))}

                  <svg id="goo-svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1">
                    <defs>
                      <filter id="goo">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="4" result="blur"></feGaussianBlur>
                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7" result="goo"></feColorMatrix>
                        <feBlend in="SourceGraphic" in2="goo"></feBlend>
                      </filter>
                    </defs>
                  </svg>


                  <button ref={submitButtonRef} type="submit" style={{display: 'none'}} />

                  <div id="form-submit-button" onClick={(e)=>submitForm(e)} >
                      <div id="form-submit-button-background" className={submitStatus}>
                        {submitStatus==='submitted' 
                          ? <svg id="submit-spinner" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="0px" height="0px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                              <circle cx="40" cy="40" fill="none" stroke="#e15b64" strokeWidth="5" r="10" strokeDasharray="47.12388980384689 17.707963267948966">
                                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 40 40;360 40 40" keyTimes="0;1"></animateTransform>
                              </circle>
                            </svg>
                          : <>{form.submitButton}</>
                        }
                      </div>
                  </div>
        
                  {submitStatus==='error' && 
                    <div id="form-submit-error">
                      <div id="icon-error" />
                      {form.errorMessage}
                    </div>
                  }
        
                </form>
                
            }
          </div>

        </div>

        <div ref={el => animateRefs.current[0] = el} className={`employment-svg-container${graphicClassName}`}>
          <div className="shelves-container">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 1200 958.6">
              <path id="background-shape" className="st0" d="M974.6,579.4c-22.3,71.8-80.4,151.2-260.3,182.2
                c-26.8,4.6-341.1,44-419.5,24.9c-143.7-34.9-238.8-226-115.1-378.4C250.4,321,415.6,146.8,551.3,118
                C855.2,53.4,1084.9,224.4,974.6,579.4z"/>
              <g id="shelves">
                <defs>
                  <path id="SVGID_1_" d="M974.6,579.4c-22.3,71.8-80.4,151.2-260.3,182.2c-26.8,4.6-341.1,44-419.5,24.9
                    c-143.7-34.9-238.8-226-115.1-378.4C250.4,321,415.6,146.8,551.3,118C855.2,53.4,1084.9,224.4,974.6,579.4z"/>
                </defs>
                <clipPath id="SVGID_2_">
                  <use xlinkHref="#SVGID_1_" style={{overflow: 'visible'}}/>
                </clipPath>
                <g className="st1">
                  <path className="st2" d="M856.2,340.7h21.7c2.4,0,4.3,1.9,4.3,4.3v7.2c0,2.4-1.9,4.3-4.3,4.3h-21.7c-2.4,0-4.3-1.9-4.3-4.3v-7.2
                    C851.8,342.7,853.8,340.7,856.2,340.7z"/>
                  <line className="st3" x1="856.9" y1="345.5" x2="877.3" y2="345.5"/>
                  <line className="st3" x1="856.9" y1="352" x2="877.3" y2="352"/>
                  <path className="st2" d="M717.6,340.7h21.7c2.4,0,4.3,1.9,4.3,4.3v7.2c0,2.4-1.9,4.3-4.3,4.3h-21.7c-2.4,0-4.3-1.9-4.3-4.3v-7.2
                    C713.2,342.7,715.2,340.7,717.6,340.7z"/>
                  <line className="st3" x1="718.3" y1="345.5" x2="738.7" y2="345.5"/>
                  <line className="st3" x1="718.3" y1="352" x2="738.7" y2="352"/>
                  <path className="st2" d="M602.2,340.7h21.7c2.4,0,4.3,1.9,4.3,4.3v7.2c0,2.4-1.9,4.3-4.3,4.3h-21.7c-2.4,0-4.3-1.9-4.3-4.3v-7.2
                    C597.9,342.7,599.8,340.7,602.2,340.7z"/>
                  <line className="st3" x1="602.9" y1="345.5" x2="623.3" y2="345.5"/>
                  <line className="st3" x1="602.9" y1="352" x2="623.3" y2="352"/>
                  <path className="st2" d="M440.3,340.7h21.7c2.4,0,4.3,1.9,4.3,4.3v7.2c0,2.4-1.9,4.3-4.3,4.3h-21.7c-2.4,0-4.3-1.9-4.3-4.3v-7.2
                    C435.9,342.7,437.9,340.7,440.3,340.7z"/>
                  <line className="st3" x1="441" y1="345.5" x2="461.4" y2="345.5"/>
                  <line className="st3" x1="441" y1="352" x2="461.4" y2="352"/>
                  <path className="st2" d="M279.2,340.7h21.7c2.4,0,4.3,1.9,4.3,4.3v7.2c0,2.4-1.9,4.3-4.3,4.3h-21.7c-2.4,0-4.3-1.9-4.3-4.3v-7.2
                    C274.9,342.7,276.8,340.7,279.2,340.7z"/>
                  <line className="st3" x1="279.9" y1="345.5" x2="300.3" y2="345.5"/>
                  <line className="st3" x1="279.9" y1="352" x2="300.3" y2="352"/>
                  <path className="st4" d="M875.5,239.3h79.4c2.4,0,4.3,1.9,4.3,4.3v92.7c0,2.4-1.9,4.3-4.3,4.3h-79.4c-2.4,0-4.3-1.9-4.3-4.3v-92.7
                    C871.1,241.2,873.1,239.3,875.5,239.3z"/>
                  <circle className="st5" cx="914.6" cy="290.4" r="28.6"/>
                  <line className="st6" x1="953.1" y1="333.9" x2="876" y2="333.9"/>
                  <line className="st6" x1="876" y1="244.4" x2="953.1" y2="244.4"/>
                  <path className="st4" d="M783,239.3h79.4c2.4,0,4.3,1.9,4.3,4.3v92.7c0,2.4-1.9,4.3-4.3,4.3H783c-2.4,0-4.3-1.9-4.3-4.3v-92.7
                    C778.7,241.2,780.6,239.3,783,239.3z"/>
                  <path className="st7" d="M690.7,310.2H770c2.4,0,4.3,1.9,4.3,4.3v21.8c0,2.4-1.9,4.3-4.3,4.3h-79.4c-2.4,0-4.3-1.9-4.3-4.3v-21.8
                    C686.3,312.1,688.3,310.2,690.7,310.2z"/>
                  <path className="st8" d="M487.9,254.5h46.4c2.4,0,4.3,1.9,4.3,4.3v77.6c0,2.4-1.9,4.3-4.3,4.3h-46.4c-2.4,0-4.3-1.9-4.3-4.3v-77.6
                    C483.6,256.4,485.5,254.5,487.9,254.5z"/>
                  <circle className="st5" cx="822.1" cy="290.4" r="28.6"/>
                  <line className="st6" x1="783.5" y1="333.9" x2="860.6" y2="333.9"/>
                  <line className="st6" x1="783.5" y1="244.4" x2="860.6" y2="244.4"/>
                  <line className="st6" x1="492" y1="261" x2="492" y2="336"/>
                  <path className="st8" d="M427.8,254.5h46.4c2.4,0,4.3,1.9,4.3,4.3v77.6c0,2.4-1.9,4.3-4.3,4.3h-46.4c-2.4,0-4.3-1.9-4.3-4.3v-77.6
                    C423.5,256.4,425.4,254.5,427.8,254.5z"/>
                  <line className="st6" x1="431.9" y1="261" x2="431.9" y2="336"/>
                  <path className="st8" d="M368.5,254.5h46.4c2.4,0,4.3,1.9,4.3,4.3v77.6c0,2.4-1.9,4.3-4.3,4.3h-46.4c-2.4,0-4.3-1.9-4.3-4.3v-77.6
                    C364.1,256.4,366.1,254.5,368.5,254.5z"/>
                  <line className="st6" x1="372.6" y1="261" x2="372.6" y2="336"/>
                  <ellipse transform="matrix(0.9955 -9.515081e-02 9.515081e-02 0.9955 -27.6076 70.9873)" className="st9" cx="730.6" cy="325" rx="36.1" ry="3.3"/>
                  <path className="st7" d="M690.7,278.2H770c2.4,0,4.3,1.9,4.3,4.3v21.8c0,2.4-1.9,4.3-4.3,4.3h-79.4c-2.4,0-4.3-1.9-4.3-4.3v-21.8
                    C686.3,280.2,688.3,278.2,690.7,278.2z"/>    
                  <ellipse transform="matrix(0.9955 -9.515081e-02 9.515081e-02 0.9955 -24.5661 70.8431)" className="st9" cx="730.6" cy="293" rx="36.1" ry="3.3"/>
                  <path className="st7" d="M690.7,245.9H770c2.4,0,4.3,1.9,4.3,4.3V272c0,2.4-1.9,4.3-4.3,4.3h-79.4c-2.4,0-4.3-1.9-4.3-4.3v-21.8
                    C686.3,247.8,688.3,245.9,690.7,245.9z"/>
                  <ellipse transform="matrix(0.9955 -9.515081e-02 9.515081e-02 0.9955 -21.4848 70.6961)" className="st9" cx="730.6" cy="260.6" rx="36.1" ry="3.3"/>
                  <path className="st7" d="M690.7,213.7H770c2.4,0,4.3,1.9,4.3,4.3v21.8c0,2.4-1.9,4.3-4.3,4.3h-79.4c-2.4,0-4.3-1.9-4.3-4.3V218
                    C686.3,215.6,688.3,213.7,690.7,213.7z"/>
                  <ellipse transform="matrix(0.995 -9.950372e-02 9.950372e-02 0.995 -18.8336 74.2545)" className="st9" cx="735" cy="225.9" rx="36.3" ry="3.3"/>
                  <polygon className="st10" points="552.9,286.8 557.9,340 606.2,340 611.6,286.5 		"/>
                  <path className="st10" d="M555.7,275.1h52.9c2.4,0,4.3,1.9,4.3,4.3v0.9c0,2.4-1.9,4.3-4.3,4.3h-52.9c-2.4,0-4.3-1.9-4.3-4.3v-0.9
                    C551.3,277.1,553.3,275.1,555.7,275.1z"/>
                  <polygon className="st11" points="294.6,251.3 299.5,340 347.8,340 353.2,250.9 		"/>
                  <path className="st11" d="M297.3,231.9h52.9c2.4,0,4.3,1.9,4.3,4.3v7.3c0,2.4-1.9,4.3-4.3,4.3h-52.9c-2.4,0-4.3-1.9-4.3-4.3v-7.3
                    C293,233.8,294.9,231.9,297.3,231.9z"/>
                  <polygon className="st11" points="229.7,251.3 234.6,340 282.9,340 288.3,250.9 		"/>
                  <path className="st11" d="M232.4,231.9h52.9c2.4,0,4.3,1.9,4.3,4.3v7.3c0,2.4-1.9,4.3-4.3,4.3h-52.9c-2.4,0-4.3-1.9-4.3-4.3v-7.3
                    C228.1,233.8,230,231.9,232.4,231.9z"/>
                  <polygon className="st10" points="552.9,218.2 557.9,271.5 606.2,271.5 611.6,218 		"/>
                  <path className="st10" d="M555.7,206.6h52.9c2.4,0,4.3,1.9,4.3,4.3v0.9c0,2.4-1.9,4.3-4.3,4.3h-52.9c-2.4,0-4.3-1.9-4.3-4.3v-0.9
                    C551.3,208.5,553.3,206.6,555.7,206.6z"/>
                  <polygon className="st10" points="617.9,286.8 622.8,340 671.2,340 676.5,286.5 		"/>
                  <path className="st10" d="M620.6,275.1h52.9c2.4,0,4.3,1.9,4.3,4.3v0.9c0,2.4-1.9,4.3-4.3,4.3h-52.9c-2.4,0-4.3-1.9-4.3-4.3v-0.9
                    C616.3,277.1,618.2,275.1,620.6,275.1z"/>
                  <polygon className="st10" points="617.9,218.2 622.8,271.5 671.2,271.5 676.5,218 		"/>
                  <path className="st10" d="M620.6,206.6h52.9c2.4,0,4.3,1.9,4.3,4.3v0.9c0,2.4-1.9,4.3-4.3,4.3h-52.9c-2.4,0-4.3-1.9-4.3-4.3v-0.9
                    C616.3,208.5,618.2,206.6,620.6,206.6z"/>
                  <path className="st2" d="M856.2,511.9h21.7c2.4,0,4.3,1.9,4.3,4.3v7.2c0,2.4-1.9,4.3-4.3,4.3h-21.7c-2.4,0-4.3-1.9-4.3-4.3v-7.2
                    C851.8,513.8,853.8,511.9,856.2,511.9z"/>
                  <line className="st3" x1="856.9" y1="516.7" x2="877.3" y2="516.7"/>
                  <line className="st3" x1="856.9" y1="523.1" x2="877.3" y2="523.1"/>
                  <path className="st2" d="M717.6,511.9h21.7c2.4,0,4.3,1.9,4.3,4.3v7.2c0,2.4-1.9,4.3-4.3,4.3h-21.7c-2.4,0-4.3-1.9-4.3-4.3v-7.2
                    C713.2,513.8,715.2,511.9,717.6,511.9z"/>
                  <line className="st3" x1="718.3" y1="516.7" x2="738.7" y2="516.7"/>
                  <line className="st3" x1="718.3" y1="523.1" x2="738.7" y2="523.1"/>
                  <path className="st2" d="M602.2,511.9h21.7c2.4,0,4.3,1.9,4.3,4.3v7.2c0,2.4-1.9,4.3-4.3,4.3h-21.7c-2.4,0-4.3-1.9-4.3-4.3v-7.2
                    C597.9,513.8,599.8,511.9,602.2,511.9z"/>
                  <line className="st3" x1="602.9" y1="516.7" x2="623.3" y2="516.7"/>
                  <line className="st3" x1="602.9" y1="523.1" x2="623.3" y2="523.1"/>
                  <path className="st2" d="M440.3,511.9h21.7c2.4,0,4.3,1.9,4.3,4.3v7.2c0,2.4-1.9,4.3-4.3,4.3h-21.7c-2.4,0-4.3-1.9-4.3-4.3v-7.2
                    C435.9,513.8,437.9,511.9,440.3,511.9z"/>
                  <line className="st3" x1="441" y1="516.7" x2="461.4" y2="516.7"/>
                  <line className="st3" x1="441" y1="523.1" x2="461.4" y2="523.1"/>
                  <path className="st2" d="M279.2,511.9h21.7c2.4,0,4.3,1.9,4.3,4.3v7.2c0,2.4-1.9,4.3-4.3,4.3h-21.7c-2.4,0-4.3-1.9-4.3-4.3v-7.2
                    C274.9,513.8,276.8,511.9,279.2,511.9z"/>
                  <line className="st3" x1="279.9" y1="516.7" x2="300.3" y2="516.7"/>
                  <line className="st3" x1="279.9" y1="523.1" x2="300.3" y2="523.1"/>
                  <path className="st4" d="M875.5,410.4h79.4c2.4,0,4.3,1.9,4.3,4.3v92.7c0,2.4-1.9,4.3-4.3,4.3h-79.4c-2.4,0-4.3-1.9-4.3-4.3v-92.7
                    C871.1,412.4,873.1,410.4,875.5,410.4z"/>
                  <circle className="st5" cx="914.6" cy="461.5" r="28.6"/>
                  <line className="st6" x1="876" y1="505" x2="953.1" y2="505"/>
                  <line className="st6" x1="953.1" y1="415.6" x2="876" y2="415.6"/>
                  <path className="st4" d="M783,410.4h79.4c2.4,0,4.3,1.9,4.3,4.3v92.7c0,2.4-1.9,4.3-4.3,4.3H783c-2.4,0-4.3-1.9-4.3-4.3v-92.7
                    C778.7,412.4,780.6,410.4,783,410.4z"/>
                  <path className="st7" d="M690.7,481.3H770c2.4,0,4.3,1.9,4.3,4.3v21.8c0,2.4-1.9,4.3-4.3,4.3h-79.4c-2.4,0-4.3-1.9-4.3-4.3v-21.8
                    C686.3,483.3,688.3,481.3,690.7,481.3z"/>
                  <path className="st8" d="M487.9,425.6h46.4c2.4,0,4.3,1.9,4.3,4.3v77.6c0,2.4-1.9,4.3-4.3,4.3h-46.4c-2.4,0-4.3-1.9-4.3-4.3v-77.6
                    C483.6,427.5,485.5,425.6,487.9,425.6z"/>
                  <circle className="st5" cx="822.1" cy="461.5" r="28.6"/>
                  <line className="st6" x1="783.5" y1="505" x2="860.6" y2="505"/>
                  <line className="st6" x1="783.5" y1="415.6" x2="860.6" y2="415.6"/>
                  <line className="st6" x1="492" y1="432.1" x2="492" y2="507.1"/>
                  <path className="st8" d="M427.8,425.6h46.4c2.4,0,4.3,1.9,4.3,4.3v77.6c0,2.4-1.9,4.3-4.3,4.3h-46.4c-2.4,0-4.3-1.9-4.3-4.3v-77.6
                    C423.5,427.5,425.4,425.6,427.8,425.6z"/>
                  <line className="st6" x1="431.9" y1="432.1" x2="431.9" y2="507.1"/>
                  <path className="st8" d="M368.5,425.6h46.4c2.4,0,4.3,1.9,4.3,4.3v77.6c0,2.4-1.9,4.3-4.3,4.3h-46.4c-2.4,0-4.3-1.9-4.3-4.3v-77.6
                    C364.1,427.5,366.1,425.6,368.5,425.6z"/>
                  <line className="st6" x1="372.6" y1="432.1" x2="372.6" y2="507.1"/>
                  <ellipse transform="matrix(0.9955 -9.515081e-02 9.515081e-02 0.9955 -43.8911 71.7631)" className="st9" cx="730.5" cy="496.1" rx="36.1" ry="3.3"/>
                  <path className="st7" d="M690.7,449.4H770c2.4,0,4.3,1.9,4.3,4.3v21.8c0,2.4-1.9,4.3-4.3,4.3h-79.4c-2.4,0-4.3-1.9-4.3-4.3v-21.8
                    C686.3,451.3,688.3,449.4,690.7,449.4z"/>
                  <ellipse transform="matrix(0.9955 -9.515081e-02 9.515081e-02 0.9955 -40.8506 71.6181)" className="st9" cx="730.5" cy="464.2" rx="36.1" ry="3.3"/>
                  <path className="st7" d="M690.7,417H770c2.4,0,4.3,1.9,4.3,4.3v21.8c0,2.4-1.9,4.3-4.3,4.3h-79.4c-2.4,0-4.3-1.9-4.3-4.3v-21.8
                    C686.3,418.9,688.3,417,690.7,417z"/>
                  <ellipse transform="matrix(0.9955 -9.515081e-02 9.515081e-02 0.9955 -37.7702 71.4721)" className="st9" cx="730.6" cy="431.8" rx="36.1" ry="3.3"/>
                  <path className="st7" d="M690.7,384.8H770c2.4,0,4.3,1.9,4.3,4.3V411c0,2.4-1.9,4.3-4.3,4.3h-79.4c-2.4,0-4.3-1.9-4.3-4.3v-21.8
                    C686.3,386.7,688.3,384.8,690.7,384.8z"/>
                  <ellipse transform="matrix(0.9955 -9.515081e-02 9.515081e-02 0.9955 -34.7051 71.3255)" className="st9" cx="730.6" cy="399.6" rx="36.1" ry="3.3"/>
                  <polygon className="st10" points="552.9,457.9 557.9,511.2 606.2,511.2 611.6,457.6 		"/>
                  <path className="st10" d="M555.7,446.3h52.9c2.4,0,4.3,1.9,4.3,4.3v0.9c0,2.4-1.9,4.3-4.3,4.3h-52.9c-2.4,0-4.3-1.9-4.3-4.3v-0.9
                    C551.3,448.2,553.3,446.3,555.7,446.3z"/>
                  <polygon className="st11" points="294.6,422.5 299.5,511.2 347.8,511.2 353.2,422 		"/>
                  <path className="st11" d="M297.3,403h52.9c2.4,0,4.3,1.9,4.3,4.3v7.3c0,2.4-1.9,4.3-4.3,4.3h-52.9c-2.4,0-4.3-1.9-4.3-4.3v-7.3
                    C293,405,294.9,403,297.3,403z"/>
                  <polygon className="st11" points="229.7,422.5 234.6,511.2 282.9,511.2 288.3,422 		"/>
                  <path className="st11" d="M232.4,403h52.9c2.4,0,4.3,1.9,4.3,4.3v7.3c0,2.4-1.9,4.3-4.3,4.3h-52.9c-2.4,0-4.3-1.9-4.3-4.3v-7.3
                    C228.1,405,230,403,232.4,403z"/>
                  <polygon className="st10" points="552.9,389.4 557.9,442.6 606.2,442.6 611.6,389.1 		"/>
                  <path className="st10" d="M555.7,377.7h52.9c2.4,0,4.3,1.9,4.3,4.3v0.9c0,2.4-1.9,4.3-4.3,4.3h-52.9c-2.4,0-4.3-1.9-4.3-4.3v-0.9
                    C551.3,379.7,553.3,377.7,555.7,377.7z"/>
                  <polygon className="st10" points="617.9,457.9 622.8,511.2 671.2,511.2 676.5,457.6 		"/>
                  <path className="st10" d="M620.6,446.3h52.9c2.4,0,4.3,1.9,4.3,4.3v0.9c0,2.4-1.9,4.3-4.3,4.3h-52.9c-2.4,0-4.3-1.9-4.3-4.3v-0.9
                    C616.3,448.2,618.2,446.3,620.6,446.3z"/>
                  <polygon className="st10" points="617.9,389.4 622.8,442.6 671.2,442.6 676.5,389.1 		"/>
                  <path className="st10" d="M620.6,377.7h52.9c2.4,0,4.3,1.9,4.3,4.3v0.9c0,2.4-1.9,4.3-4.3,4.3h-52.9c-2.4,0-4.3-1.9-4.3-4.3v-0.9
                    C616.3,379.7,618.2,377.7,620.6,377.7z"/>
                  <path className="st2" d="M856.2,681.1h21.7c2.4,0,4.3,1.9,4.3,4.3v7.2c0,2.4-1.9,4.3-4.3,4.3h-21.7c-2.4,0-4.3-1.9-4.3-4.3v-7.2
                    C851.8,683,853.8,681.1,856.2,681.1z"/>
                  <line className="st3" x1="856.9" y1="685.9" x2="877.3" y2="685.9"/>
                  <line className="st3" x1="856.9" y1="692.3" x2="877.3" y2="692.3"/>
                  <path className="st2" d="M717.6,681.1h21.7c2.4,0,4.3,1.9,4.3,4.3v7.2c0,2.4-1.9,4.3-4.3,4.3h-21.7c-2.4,0-4.3-1.9-4.3-4.3v-7.2
                    C713.2,683,715.2,681.1,717.6,681.1z"/>
                  <line className="st3" x1="718.3" y1="685.9" x2="738.7" y2="685.9"/>
                  <line className="st3" x1="718.3" y1="692.3" x2="738.7" y2="692.3"/>
                  <path className="st2" d="M602.2,681.1h21.7c2.4,0,4.3,1.9,4.3,4.3v7.2c0,2.4-1.9,4.3-4.3,4.3h-21.7c-2.4,0-4.3-1.9-4.3-4.3v-7.2
                    C597.9,683,599.8,681.1,602.2,681.1z"/>
                  <line className="st3" x1="602.9" y1="685.9" x2="623.3" y2="685.9"/>
                  <line className="st3" x1="602.9" y1="692.3" x2="623.3" y2="692.3"/>
                  <path className="st2" d="M440.3,681.1h21.7c2.4,0,4.3,1.9,4.3,4.3v7.2c0,2.4-1.9,4.3-4.3,4.3h-21.7c-2.4,0-4.3-1.9-4.3-4.3v-7.2
                    C435.9,683,437.9,681.1,440.3,681.1z"/>
                  <line className="st3" x1="441" y1="685.9" x2="461.4" y2="685.9"/>
                  <line className="st3" x1="441" y1="692.3" x2="461.4" y2="692.3"/>
                  <path className="st2" d="M279.2,681.1h21.7c2.4,0,4.3,1.9,4.3,4.3v7.2c0,2.4-1.9,4.3-4.3,4.3h-21.7c-2.4,0-4.3-1.9-4.3-4.3v-7.2
                    C274.9,683,276.8,681.1,279.2,681.1z"/>
                  <line className="st3" x1="279.9" y1="685.9" x2="300.3" y2="685.9"/>
                  <line className="st3" x1="279.9" y1="692.3" x2="300.3" y2="692.3"/>
                  <path className="st4" d="M875.5,579.7h79.4c2.4,0,4.3,1.9,4.3,4.3v92.7c0,2.4-1.9,4.3-4.3,4.3h-79.4c-2.4,0-4.3-1.9-4.3-4.3V584
                    C871.1,581.6,873.1,579.7,875.5,579.7z"/>
                  <circle className="st5" cx="914.6" cy="630.7" r="28.6"/>
                  <line className="st6" x1="876" y1="674.2" x2="953.1" y2="674.2"/>
                  <line className="st6" x1="876" y1="584.8" x2="953.1" y2="584.8"/>
                  <path className="st4" d="M783,579.7h79.4c2.4,0,4.3,1.9,4.3,4.3v92.7c0,2.4-1.9,4.3-4.3,4.3H783c-2.4,0-4.3-1.9-4.3-4.3V584
                    C778.7,581.6,780.6,579.7,783,579.7z"/>
                  <path className="st7" d="M690.7,650.6H770c2.4,0,4.3,1.9,4.3,4.3v21.8c0,2.4-1.9,4.3-4.3,4.3h-79.4c-2.4,0-4.3-1.9-4.3-4.3v-21.8
                    C686.3,652.5,688.3,650.6,690.7,650.6z"/>
                  <path className="st8" d="M487.9,594.8h46.4c2.4,0,4.3,1.9,4.3,4.3v77.6c0,2.4-1.9,4.3-4.3,4.3h-46.4c-2.4,0-4.3-1.9-4.3-4.3v-77.6
                    C483.6,596.8,485.5,594.8,487.9,594.8z"/>
                  <circle className="st5" cx="822.1" cy="630.7" r="28.6"/>
                  <line className="st6" x1="783.5" y1="674.2" x2="860.6" y2="674.2"/>
                  <line className="st6" x1="783.5" y1="584.8" x2="860.6" y2="584.8"/>
                  <line className="st6" x1="492" y1="601.3" x2="492" y2="676.4"/>
                  <path className="st8" d="M427.8,594.8h46.4c2.4,0,4.3,1.9,4.3,4.3v77.6c0,2.4-1.9,4.3-4.3,4.3h-46.4c-2.4,0-4.3-1.9-4.3-4.3v-77.6
                    C423.5,596.8,425.4,594.8,427.8,594.8z"/>
                  <line className="st6" x1="431.9" y1="601.3" x2="431.9" y2="676.4"/>
                  <path className="st8" d="M368.5,594.8h46.4c2.4,0,4.3,1.9,4.3,4.3v77.6c0,2.4-1.9,4.3-4.3,4.3h-46.4c-2.4,0-4.3-1.9-4.3-4.3v-77.6
                    C364.1,596.8,366.1,594.8,368.5,594.8z"/>
                  <line className="st6" x1="372.6" y1="601.3" x2="372.6" y2="676.4"/>
                  <ellipse transform="matrix(0.9955 -9.515081e-02 9.515081e-02 0.9955 -59.9928 72.5301)" className="st9" cx="730.5" cy="665.3" rx="36.1" ry="3.3"/>
                  <path className="st7" d="M690.7,618.6H770c2.4,0,4.3,1.9,4.3,4.3v21.8c0,2.4-1.9,4.3-4.3,4.3h-79.4c-2.4,0-4.3-1.9-4.3-4.3v-21.8
                    C686.3,620.5,688.3,618.6,690.7,618.6z"/>
                  <ellipse transform="matrix(0.9955 -9.515081e-02 9.515081e-02 0.9955 -56.9523 72.385)" className="st9" cx="730.5" cy="633.4" rx="36.1" ry="3.3"/>
                  <path className="st7" d="M690.7,586.2H770c2.4,0,4.3,1.9,4.3,4.3v21.8c0,2.4-1.9,4.3-4.3,4.3h-79.4c-2.4,0-4.3-1.9-4.3-4.3v-21.8
                    C686.3,588.2,688.3,586.2,690.7,586.2z"/>
                  <ellipse transform="matrix(0.9955 -9.515081e-02 9.515081e-02 0.9955 -53.8719 72.2391)" className="st9" cx="730.5" cy="601" rx="36.1" ry="3.3"/>
                  <path className="st7" d="M690.7,554H770c2.4,0,4.3,1.9,4.3,4.3v21.8c0,2.4-1.9,4.3-4.3,4.3h-79.4c-2.4,0-4.3-1.9-4.3-4.3v-21.8
                    C686.3,556,688.3,554,690.7,554z"/>
                  <ellipse transform="matrix(0.9955 -9.515081e-02 9.515081e-02 0.9955 -50.8067 72.0925)" className="st9" cx="730.5" cy="568.8" rx="36.1" ry="3.3"/>
                  <polygon className="st10" points="552.9,627.1 557.9,680.4 606.2,680.4 611.6,626.9 		"/>
                  <path className="st10" d="M555.7,615.5h52.9c2.4,0,4.3,1.9,4.3,4.3v0.9c0,2.4-1.9,4.3-4.3,4.3h-52.9c-2.4,0-4.3-1.9-4.3-4.3v-0.9
                    C551.3,617.4,553.3,615.5,555.7,615.5z"/>
                  <polygon className="st11" points="294.6,591.7 299.5,680.4 347.8,680.4 353.2,591.2 		"/>
                  <path className="st11" d="M297.3,572.3h52.9c2.4,0,4.3,1.9,4.3,4.3v7.3c0,2.4-1.9,4.3-4.3,4.3h-52.9c-2.4,0-4.3-1.9-4.3-4.3v-7.3
                    C293,574.2,294.9,572.3,297.3,572.3z"/>
                  <polygon className="st11" points="229.7,591.7 234.6,680.4 282.9,680.4 288.3,591.2 		"/>
                  <path className="st11" d="M232.4,572.3h52.9c2.4,0,4.3,1.9,4.3,4.3v7.3c0,2.4-1.9,4.3-4.3,4.3h-52.9c-2.4,0-4.3-1.9-4.3-4.3v-7.3
                    C228.1,574.2,230,572.3,232.4,572.3z"/>
                  <polygon className="st10" points="552.9,558.6 557.9,611.9 606.2,611.9 611.6,558.3 		"/>
                  <path className="st10" d="M555.7,547h52.9c2.4,0,4.3,1.9,4.3,4.3v0.9c0,2.4-1.9,4.3-4.3,4.3h-52.9c-2.4,0-4.3-1.9-4.3-4.3v-0.9
                    C551.3,548.9,553.3,547,555.7,547z"/>
                  <polygon className="st10" points="617.9,627.1 622.8,680.4 671.2,680.4 676.5,626.9 		"/>
                  <path className="st10" d="M620.6,615.5h52.9c2.4,0,4.3,1.9,4.3,4.3v0.9c0,2.4-1.9,4.3-4.3,4.3h-52.9c-2.4,0-4.3-1.9-4.3-4.3v-0.9
                    C616.3,617.4,618.2,615.5,620.6,615.5z"/>
                  <polygon className="st10" points="617.9,558.6 622.8,611.9 671.2,611.9 676.5,558.3 		"/>
                  <path className="st10" d="M620.6,547h52.9c2.4,0,4.3,1.9,4.3,4.3v0.9c0,2.4-1.9,4.3-4.3,4.3h-52.9c-2.4,0-4.3-1.9-4.3-4.3v-0.9
                    C616.3,548.9,618.2,547,620.6,547z"/>
                  <path className="shelf st12" d="M83.4,340.7h935.2c2.4,0,4.3,1.9,4.3,4.3v7.2c0,2.4-1.9,4.3-4.3,4.3H83.4c-2.4,0-4.3-1.9-4.3-4.3v-7.2
                    C79.1,342.7,81,340.7,83.4,340.7z"/>
                  <path className="shelf st12" d="M83.4,511.9h935.2c2.4,0,4.3,1.9,4.3,4.3v7.2c0,2.4-1.9,4.3-4.3,4.3H83.4c-2.4,0-4.3-1.9-4.3-4.3v-7.2
                    C79.1,513.8,81,511.9,83.4,511.9z"/>
                  <path className="shelf st12" d="M83.4,681.1h935.2c2.4,0,4.3,1.9,4.3,4.3v7.2c0,2.4-1.9,4.3-4.3,4.3H83.4c-2.4,0-4.3-1.9-4.3-4.3v-7.2
                    C79.1,683,81,681.1,83.4,681.1z"/>
                </g>
              </g>
            </svg>
          </div>
          <div className="patient-container">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 1200 958.6">
              <g id="patient">
                <path className="st8" d="M947.9,545.4c1.9,3.6,1.4,18.2,1.4,18.2l19.1-7.4c-0.5-3.9-1.3-7.7-2.3-11.4c9.2-5.3,15-17.1,16.6-22.8
                  c-2.8-1.6-2.2-32.8-29.2-41.4c-23.2-4.8-34.7-4.4-34.7-4.4s-1.5,23.4,0,38.2c-0.7,2.6-4.2,5.2-3,7.2c1.1,1.7,2.3,1,6.3,2.2
                  C923.3,533,931.5,545.3,947.9,545.4z"/>
                <circle className="st13" cx="871.1" cy="798.5" r="20.5"/>
                <line className="st14" x1="941.5" y1="729.1" x2="989.3" y2="751"/>
                <line className="st14" x1="935.9" y1="750.9" x2="988.4" y2="753.5"/>
                <line className="st14" x1="940" y1="776.7" x2="988.2" y2="755.5"/>
                <line className="st14" x1="952.7" y1="795.4" x2="989.4" y2="757.8"/>
                <line className="st14" x1="972.3" y1="807.4" x2="992" y2="758.6"/>
                <line className="st14" x1="994.4" y1="812" x2="994.5" y2="759.4"/>
                <line className="st14" x1="1017.8" y1="808.5" x2="998.9" y2="759.4"/>
                <line className="st14" x1="1037.1" y1="796.8" x2="1001.3" y2="758.3"/>
                <line className="st14" x1="1050" y1="777.7" x2="1002.2" y2="755.8"/>
                <line className="st14" x1="1055.6" y1="755.9" x2="1003.1" y2="753.3"/>
                <line className="st14" x1="1051.5" y1="730.1" x2="1003.4" y2="751.3"/>
                <line className="st14" x1="1038.9" y1="711.4" x2="1002.1" y2="749"/>
                <line className="st14" x1="1019.2" y1="699.4" x2="999.6" y2="748.2"/>
                <line className="st15" x1="869.9" y1="722" x2="869.9" y2="776.6"/>
                <line className="st15" x1="998.7" y1="628.5" x2="998.7" y2="683.1"/>
                <path className="st8" d="M866.8,662.6c0,0-51.9,89.5-54,89.8s-9-0.3-9-0.3l-3.1,11.8l9.9,9.5c0,0,9.8-13.4,13.4-18.3
                  c2.7-3.6,42.9-51.2,42.9-51.2L866.8,662.6z"/>
                <polygon className="st16" points="814.1,748.9 824.3,756.3 866.8,704 866.8,660.9 	"/>
                <path className="st16" d="M872.4,659.8c0,0,36.9,0.9,46.7,3.2c4.7,1.1,9.5,1.5,14.3,1.2l38-1.8l-1.8,39.8L866.7,704L872.4,659.8z"/>
                <path className="st9" d="M902.5,676.8c0,0,1.5-14.3,15.4-15s77.6,0,77.6,0v51.8h-92.3C903.3,713.6,901.1,676.8,902.5,676.8z"/>
                <path className="st7" d="M961,633.1c2.1-2.4,0-6.7,0-6.7h34.2c0,0,0.9-26,0.6-34.2s0-10.6,3.3-13.3s9.1-0.9,9.1-0.9s-0.6,8.8-3.6,7.9
                  s-2.9,0.3-2.9,0.3v135.2H875.7l-2.8,56.5l-6.2-0.3l-1.2-55.9c0,0-3.1-0.3-4,0.4c-3,2.4-42.9,56.8-45.7,60.5s-5.1,3-5.1,3
                  s-25.7-18.8-28.7-25.1s3.3-5.4,3.3-5.4l26.3,23.9c0,0,34.2-45.4,40.8-55.7s14.5-9.7,14.5-9.7v-59.8c0-15.4,7-19.3,7-19.3
                  s11.2,0,5.7,2.1s-7.6,12.4-7.6,12.4v64.7h123.7v-79.9L961,633.1z"/>
                <path className="st8" d="M889.1,622.1c0,0-10.6,0-14,3.4s-8.7,7.5-6.4,18.5c4.5,0,10.6-12.8,10.6-12.8h71.4c0,0,7.2,2.3,13.6-27.2
                  c-11.3-1.9-21.2-3.4-21.2-3.4l-2.6,17.4L889.1,622.1z"/>
                <polyline className="st17" points="972.8,580.8 968.9,604.7 944,600.5 	"/>
                <ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 93.4202 1616.7822)" className="st18" cx="996.9" cy="753.5" rx="63.1" ry="63.1"/>
                <circle className="st19" cx="996.6" cy="753.2" r="67.3"/>
                <path className="st20" d="M940,717.5c12.3-20.7,34.9-33,59-31.9"/>
                <path className="st20" d="M1063.7,763.9c-3.5,23.8-19.9,43.8-42.6,51.9"/>
                <line className="st14" x1="973.7" y1="698.3" x2="992.6" y2="747.4"/>
                <line className="st14" x1="954.4" y1="710" x2="990.3" y2="748.5"/>
                <line className="st14" x1="997.1" y1="694.8" x2="997" y2="747.4"/>
                <circle className="st2" cx="995.4" cy="751.2" r="6.8"/>
                <circle className="st21" cx="871" cy="798.5" r="21.9"/>
                <path className="st20" d="M853.1,786c0,0,5.7-9.3,16.7-9"/>
                <path className="st20" d="M891.7,803.7c-1,6.7-5.6,12.4-12,14.7"/>
                <path className="st22" d="M935.7,461.2c0,0-19.9,5.2-17.1,21.2c11-4,19.3,8.8,33,9.8c31.3,4.9,30,31.4,30,31.4s24.4-36.4,7.2-55.8
                  C970.8,447.5,935.7,461.2,935.7,461.2z"/>
                <path className="st23" d="M924.7,532.6l19.9-8.4c0,0,0,15.9-12.3,17.4"/>
                <path className="st12" d="M941.4,496.5c-2.6-1.8-5.9-2-9-1.9c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c0.7,0,1.4,0,2.1,0
                  c0.7,0,1.3,0.1,2,0.2c0.7,0.1,1.4,0.3,2,0.5l0.5,0.2l0.3,0.2c0.3,0.2,0.6,0.3,0.9,0.5c0.6,0.3,1.4,0.1,1.7-0.4
                  C942.3,497.7,942.1,496.9,941.4,496.5L941.4,496.5L941.4,496.5z"/>
                <path className="st24" d="M931.7,512.6c-1.7,0.2-3.4-0.2-4.9-1c-0.3-0.2-0.7-0.1-0.8,0.2c0,0,0,0,0,0c-0.2,0.3-0.1,0.7,0.2,0.9
                  c1.7,0.9,3.6,1.3,5.5,1.2c0.3,0,0.6-0.4,0.5-0.7C932.2,512.9,932,512.6,931.7,512.6L931.7,512.6z"/>
                <path className="st24" d="M929.7,486.5c-1.8,0-3.3-0.9-5-1.5c-1.6-0.7-3.5-0.5-4.9,0.4c-0.3,0.2-0.3,0.6-0.1,0.9
                  c0.2,0.2,0.5,0.3,0.7,0.2c0.7-0.5,1.5-0.7,2.3-0.7c0.7,0.1,1.4,0.2,2,0.6c1.5,0.8,3.2,1.3,5,1.4c0.3,0,0.6-0.4,0.5-0.7
                  C930.3,486.7,930,486.5,929.7,486.5L929.7,486.5z"/>
                <path className="st24" d="M926.3,489.3c-0.5,0-0.9-0.1-1.4-0.3h-0.1l-0.2-0.1l-0.3-0.1l-0.7-0.3c-0.9-0.4-2-0.3-2.8,0.2
                  c-0.3,0.2-0.3,0.6-0.1,0.9c0.2,0.2,0.5,0.3,0.7,0.2c0.2-0.1,0.4-0.2,0.6-0.3h0.1l0,0c0.1,0,0.2,0,0.3,0h0.2c-0.1,0,0,0,0,0l0.3,0.1
                  l0.3,0.1h0l0.1,0.1c0.9,0.5,1.8,0.7,2.8,0.8c0.3,0,0.6-0.4,0.5-0.7C926.7,489.6,926.5,489.4,926.3,489.3L926.3,489.3z"/>
                <path className="st24" d="M949.1,523.2c0,0.2,0,0.4,0,0.7s0,0.2,0,0.3c0,0.1,0,0.1,0,0.2c0-0.1,0-0.1,0,0v0.1c0,0.2-0.1,0.4-0.2,0.6
                  c0,0,0,0.1,0,0.1c0-0.1,0-0.1,0,0v0.1c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.3-0.2,0.7,0.1,0.9c0.3,0.2,0.7,0.2,0.9-0.1
                  c0,0,0.1-0.1,0.1-0.1c0.5-0.9,0.7-1.9,0.6-2.9c0-0.3-0.4-0.6-0.7-0.5c-0.3,0-0.5,0.3-0.5,0.5L949.1,523.2z"/>
                <path className="st24" d="M945.5,519.2h0.7h0.3c0.1,0,0.1,0,0.2,0l0,0c0.2,0,0.4,0.1,0.6,0.2c0,0,0.1,0,0.1,0c-0.1,0-0.1,0,0,0h0.1
                  l0.3,0.2c0.3,0.2,0.7,0.1,0.9-0.2c0,0,0,0,0,0c0.2-0.3,0.1-0.7-0.2-0.8c-0.9-0.5-1.9-0.7-2.9-0.6c-0.3,0-0.6,0.4-0.5,0.7
                  C945,518.9,945.2,519.1,945.5,519.2L945.5,519.2z"/>
                <path className="st25" d="M911.7,670.4c0,0,1.7-57.1,29.1-108.1c9.1-16.9,27.6-11.7,27.6-11.7s14.2,1.7,13.3,50.4
                  c-0.9,45.1-13.4,63.8-13.4,66S911.7,670.4,911.7,670.4z"/>
                <circle className="st12" cx="871.4" cy="798.2" r="6.8"/>
                <path className="st26" d="M1008.1,578l-3.6,7.9l29,18c0,0,3,0,4.2-2.3s2.7-4.9-1.1-5.7S1008.1,578,1008.1,578z"/>
                <path className="st26" d="M879.3,631.1c0,0-3.8,6,5.3,6h71.8c0,0,5.3-0.4,5.3-7.6s-8.3-5.3-8.3-5.3L879.3,631.1z"/>
                <path className="st12" d="M775.4,744.7c0,0,7.4-3.7,15.4-1.6s22,9.3,22,9.3l9.3,9.2l-10.6,13.8L775.4,744.7z"/>
                <path className="st26" d="M871.4,697.7h111.3v-100c0,0,2.6-11,8.8-10.6s4.4,12.5,4.4,12.5v114H872.8L871.4,697.7z"/>
                <circle className="st12" cx="995.4" cy="751.3" r="9.7"/>
                <circle className="st12" cx="931.8" cy="505.6" r="2.2"/>
              </g>
            </svg>
          </div>
          <div className="caregiver-container">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 1200 958.6">
              <g id="basket-contents">
                <path d="M468.9,613.1c-1.2-6.6-3.8-12.5-8.7-16.9c-2-1.8-4.9-2.8-7.2-4.3c-1.7-1.1-3.4-2.3-4.9-3.6c-0.7-0.7-1.2-1.5-1.4-2.4
                  c-0.7-2,0.4-4.2,2.4-4.8c0.6-0.2,1.2-0.2,1.8-0.2c0.8,0.1,1.6,0.3,2.5,0.5c0.4-3.8,1.5-4.7,5.2-4.2c0.8-2.2,1.6-4.5,4.4-4.9
                  c2.3-0.5,4.7,0.5,5.9,2.5c4.2-2,5.5-1.4,6.7,3.2c0.9-0.5,1.7-1.1,2.6-1.5c2.7-1.4,5.2,0.3,5.1,3.4c-0.2,2.5-1.4,4.8-3.3,6.5
                  c-2.8,2.5-4.8,5.8-5.9,9.4c-1.3,4.2-2.2,8.5-3.2,12.9c-0.2,1.4-0.2,2.8-0.2,4.3c6,0.9,8,2.5,9.1,8.4c1.1,5.8,1.7,11.6,2,17.5
                  c0.6,15-0.1,30-2,44.9c0,0.3-0.1,0.6-0.2,1.4c-0.3-0.4-0.6-0.8-0.8-1.2c-6.4-16.1-12.2-32.4-15.5-49.5c-0.8-3.8-1.2-7.7-1.3-11.7
                  C462,617,463.5,615.2,468.9,613.1z M478.6,678.1l0.7,0.1v0.1l0.4-3.8v0.1l-0.1-0.7v0.1c0.4-3.1,0.9-6.1,1.3-9.1l-3.1-1.8l3.2-1.3
                  c0-2.6,0.1-5.1,0.1-7.6c0.1-1-0.6-1.9-1.7-2c-0.1,0-0.2,0-0.2,0c-0.9-0.1-1.8-0.4-2.7-0.7V651c0.7-0.1,1.4-0.3,2-0.4
                  c2.5-0.4,2.9-0.9,2.8-3.3c-0.2-3.3-0.3-6.7-0.5-10.2l-4.5-0.1l-0.1-0.7c0.7-0.2,1.4-0.4,2-0.6c1.3,0,2.3-1.1,2.2-2.3
                  c0.3-3.8-0.6-7.5-1.6-11.2c-4.5,1.7-5.3,1.7-6.1,0.1l5.6-1.7c-0.6-4-2.1-5.8-4.8-6.2l-1.6,2.8c-1.3,0-2.7-0.2-4-0.5
                  c-2.5-0.9-4.2-0.3-4.5,2.2c-0.2,2.9-0.3,5.9-0.2,8.8c0.2,0.8,0.5,1.7,0.9,2.4l3-2.9l-0.5,2l1.8,1.2v0.5l-4.5,0.7
                  c0.6,3.2,1.1,6.4,1.8,9.5c0.3,1.7,1.8,3.1,3.5,3.3c0.5,0.2,1.1,0.4,1.6,0.6l-0.1,0.6l-3.6,0.5c0.8,2.6,1.8,5,2.3,7.4
                  c0.5,2.3,1,4.4,3.7,4.9c-1.7,2.5-0.6,7.3,2.8,10.3l-1.6,0.8l4.3,3.6v-0.1l-0.3,1l-1.5,1.1v-0.1l0.6,1.7l0,0l1.1,0.6l0.1,0.8V678.1z
                  M469,587.9l-0.6-0.3c0.3-1.8,0.4-3.7,0.5-5.5c0-2.2-0.3-4.4-0.8-6.5c-0.4-1.7-2-2.7-3.7-2.5c-3,0.1-4.7,1.5-4.5,4.6
                  c0.2,2.6,1.2,5.1,2,7.6c0.3,1,0.7,1.9,1.1,2.9l-0.5,0.3c-0.5-0.3-0.9-0.6-1.4-0.9c-3-1.8-5.9-3.7-9-5.4c-1.4-0.9-3.3-0.6-4.3,0.9
                  c0,0,0,0.1-0.1,0.1c-0.9,1.5-0.5,3.4,0.9,4.4c1.8,1.5,3.7,3,5.7,4.2c1.6,1,3.4,1.6,4.9,2.7c4.8,3.5,7.5,8.5,9.4,14
                  c0.2,0.6,0.4,1.2,0.8,2.2c0.7-3.5,1.3-6.6,2-9.6c1.5-5.8,3.4-11.5,8-15.7c0.8-0.9,1.5-1.9,2-2.9c1.2-2.1,1.1-4.1-0.1-5.2
                  c-1-0.9-2.8-0.7-4.5,1.1c-2.2,2.2-4.1,4.8-6.1,7.2C470.2,586.4,469.6,587.2,469,587.9z"/>
                <path className="st2" d="M476.7,676.5c-1-2.5-0.8-5.5-2.4-6.8l1.6-0.8c-3.4-3-4.5-7.8-2.8-10.3c-2.7-0.5-3.2-2.6-3.7-4.9
                  c-0.5-2.5-1.5-4.8-2.3-7.4l3.6-0.5l0.1-0.6c-0.5-0.3-1-0.5-1.6-0.6c-1.8-0.2-3.2-1.6-3.5-3.3c-0.7-3.1-1.2-6.3-1.8-9.5l4.5-0.7
                  v-0.5l-1.8-1.2l0.5-2l-3,2.9c-0.4-0.8-0.7-1.6-0.9-2.4c-0.1-2.9-0.1-5.9,0.2-8.8c0.3-2.6,2-3.2,4.5-2.2c1.3,0.3,2.7,0.5,4,0.5
                  l1.6-2.7c2.7,0.3,4.1,2.1,4.8,6.2l-5.6,1.7c0.8,1.6,1.6,1.6,6.1-0.1c1,3.7,2,7.3,1.6,11.2c0,1.3-1,2.3-2.2,2.3
                  c-0.7,0.1-1.4,0.3-2,0.6l0.1,0.7l4.5,0.1c0.2,3.5,0.4,6.9,0.5,10.2c0.1,2.5-0.3,2.9-2.8,3.3c-0.7,0.1-1.4,0.3-2,0.4v0.7
                  c0.9,0.3,1.8,0.5,2.7,0.7c1,0,1.9,0.8,1.9,1.8c0,0.1,0,0.2,0,0.2c0.1,2.5,0,5.1-0.1,7.6l-3.2,1.3l3.1,1.8
                  c-0.4,2.9-0.9,11.8-1.4,14.8C479.2,679.6,479.5,679.9,476.7,676.5z"/>
                <path className="st2" d="M469,587.9c0.6-0.8,1.2-1.6,1.8-2.3c2-2.4,3.9-5,6.1-7.2c1.8-1.8,3.5-2,4.5-1.1c1.3,1.1,1.3,3.1,0.1,5.2
                  c-0.5,1.1-1.2,2.1-2,2.9c-4.6,4.3-6.6,9.9-8,15.7c-0.8,3-1.3,6.1-2,9.6c-0.4-1-0.6-1.6-0.8-2.2c-1.8-5.5-4.6-10.5-9.4-14
                  c-1.5-1.1-3.4-1.7-4.9-2.7c-2-1.3-3.9-2.7-5.7-4.2c-1.4-1-1.7-2.9-0.9-4.4c0.9-1.4,2.8-1.9,4.2-1c0,0,0.1,0,0.1,0.1
                  c3,1.7,6,3.6,9,5.4c0.5,0.3,0.9,0.6,1.4,0.9l0.5-0.3c-0.4-0.9-0.8-1.9-1.1-2.9c-0.7-2.5-1.7-5-2-7.6c-0.3-3.1,1.5-4.5,4.5-4.6
                  c1.7-0.2,3.3,0.9,3.7,2.5c0.5,2.1,0.8,4.3,0.8,6.5c0,1.9-0.2,3.7-0.5,5.5L469,587.9z"/>
                <g>
                  <path d="M475.5,651.7c0.5-7.2,1.1-14.4,1.6-21.7c0.2-3.3,1.2-6.5,2.9-9.3c2-3.4,3.9-6.8,5.8-10.2c1-1.7,1.7-3.7,1.9-5.6
                    c0.3-1.8,0.4-3.6,0.5-5.4c0-0.4-0.1-0.7-0.4-0.9c-0.4-0.4-0.8-0.8-1.1-1.2c-0.1-0.1-0.1-0.2-0.1-0.3c0.2-2,0.3-4.1,0.5-6.1
                    c0.1-0.7,0.6-1.3,1.2-1.5c0.6-0.3,1.3-0.4,2-0.4c5.1,0.4,10.2,0.8,15.3,1.2c0.8,0,1.5,0.2,2.3,0.3c1.3,0.1,2.3,1.2,2.2,2.5
                    c-0.1,1.5-0.2,3.1-0.2,4.6c0,1.1-0.7,2.1-1.7,2.5c-0.3,0.1-0.5,0.4-0.5,0.8c-0.2,2.3-0.3,4.6-0.2,6.8c0.2,1.9,0.7,3.8,1.5,5.5
                    c1.3,3.5,2.7,7,4,10.5c1.1,2.8,1.5,5.9,1.2,8.9c-1.1,14.7-2.2,29.3-3.3,44c0,0.2,0,0.4,0,0.6c0,2.2-1.3,3.5-3,4.5
                    c-0.8,0.5-1.7,0.7-2.7,0.6c-9.1-0.7-18.2-1.4-27.3-2.1c-0.8-0.2-1.6-0.6-2.1-1.2c-1-0.8-1.7-1.9-2-3.2c0-0.2,0-0.4,0-0.6
                    L475.5,651.7L475.5,651.7z M491.2,599.3c-0.1,1.2-0.2,2.3-0.3,3.4c-0.1,3.2-1,6.4-2.6,9.2c-1.8,3.3-3.7,6.6-5.5,9.8
                    c-0.9,1.4-1.6,2.9-2.1,4.5c-0.4,1.9-0.7,3.9-0.9,5.8c-1.1,14.3-2.2,28.5-3.3,42.8c0,1.3,0.9,2.4,2.1,2.7c0.6,0.1,1.3,0.2,1.9,0.2
                    c5.1,0.4,10.2,0.8,15.3,1.2c3.2,0.2,6.4,0.4,9.6,0.6c1.4,0,2.5-1,2.6-2.4c1-14.1,2.1-28.2,3.1-42.3c0.1-1.7,0.1-3.3,0.1-5
                    c-0.1-2.1-0.6-4.2-1.5-6.1c-1.4-3.7-2.8-7.4-4.2-11.1c-0.8-2.1-1.2-4.4-1.2-6.7c0.1-1.7,0.2-3.4,0.3-5.1c0-0.2,0-0.4,0-0.5
                    L491.2,599.3z M489.5,596.2l17.5,1.3l0.3-4.2l-17.8-1.3L489.5,596.2z"/>
                  <path className="st2" d="M491.2,599.3l13.6,1c0,0.2,0,0.4,0,0.5c-0.1,1.7-0.2,3.4-0.3,5.1c0,2.3,0.4,4.6,1.2,6.7
                    c1.4,3.7,2.8,7.4,4.2,11.1c0.9,1.9,1.4,4,1.5,6.1c0,1.7,0,3.3-0.1,5c-1,14.1-2.1,28.2-3.1,42.3c-0.1,1.4-1.3,2.4-2.6,2.4
                    c-3.2-0.2-6.4-0.4-9.6-0.6c-5.1-0.4-10.2-0.8-15.3-1.2c-0.6,0-1.3-0.1-1.9-0.2c-1.3-0.3-2.1-1.4-2.1-2.7
                    c1.1-14.3,2.2-28.5,3.3-42.8c0.2-2,0.5-3.9,0.9-5.8c0.5-1.6,1.1-3.1,2.1-4.5c1.8-3.3,3.7-6.6,5.5-9.8c1.6-2.8,2.5-6,2.6-9.2
                    C491,601.5,491.1,600.4,491.2,599.3z M505.7,649.4L505.7,649.4c0.3-3.4,0.5-6.8,0.8-10.2c0.2-1.3-0.7-2.5-2.1-2.7
                    c-0.1,0-0.1,0-0.2,0c-1.7-0.2-3.4-0.3-5.1-0.5c-4.3-0.3-8.6-0.6-12.9-0.9c-0.5,0-1.1,0.1-1.5,0.5c-0.7,0.5-1.1,1.4-1.1,2.2
                    c-0.3,4.8-0.7,9.5-1,14.3c-0.1,2-0.3,3.9-0.3,5.9c0,0.5,0,1,0.2,1.4c0.3,0.8,1.1,1.3,1.9,1.3c0.3,0,0.6,0.1,0.9,0.1
                    c3.8,0.3,7.5,0.6,11.3,0.9c2,0.2,4.1,0.2,6.1,0.4c0.5,0,0.9-0.2,1.2-0.5c0.6-0.4,1-1.1,1-1.8C505.2,656.2,505.4,652.8,505.7,649.4
                    z"/>
                  <path className="st2" d="M489.5,596.2l0-4.3l17.8,1.3l-0.3,4.2L489.5,596.2z"/>
                  <path d="M505.7,649.4c-0.3,3.4-0.5,6.9-0.8,10.3c0,0.7-0.4,1.4-1,1.8c-0.3,0.3-0.8,0.5-1.2,0.5c-2-0.1-4.1-0.2-6.1-0.4
                    c-3.8-0.3-7.5-0.6-11.3-0.9c-0.3,0-0.6-0.1-0.9-0.1c-0.8,0-1.6-0.5-1.9-1.3c-0.2-0.4-0.3-0.9-0.2-1.4c0.1-2,0.2-3.9,0.3-5.9
                    c0.3-4.8,0.7-9.5,1-14.3c0-0.9,0.4-1.7,1.1-2.2c0.4-0.3,0.9-0.5,1.5-0.5c4.3,0.3,8.6,0.6,12.9,0.9c1.7,0.1,3.4,0.3,5.1,0.5
                    c1.3,0.1,2.3,1.2,2.2,2.6c0,0.1,0,0.1,0,0.2C506.2,642.6,505.9,646,505.7,649.4L505.7,649.4z M486.6,637.9l-1.5,20.1l17.1,1.3
                    l1.5-20.1L486.6,637.9z"/>
                  <path className="st5" d="M486.5,637.9l17.1,1.3l-1.5,20.1l-17.1-1.3L486.5,637.9z"/>
                </g>
                <path className="st27" d="M556.2,628.7l38.4,7.8c2.3,0.5,3.8,2.8,3.4,5.1l-10.5,51.6c-0.5,2.3-2.8,3.8-5.1,3.4l-38.4-7.8
                  c-2.3-0.5-3.8-2.8-3.4-5.1l10.5-51.6C551.6,629.7,553.8,628.2,556.2,628.7z"/>
              </g>
              <g id="caregiver">
                <path d="M573.5,322.4c-1.7,4.6-8,19.1-6.4,35s14.3,38.1,42.9,36.5s22.2-57.2,22.2-57.2s0.7-10.1-1.9-16.8s-10.6-12.7-10.6-12.7
                  s-4.4-6.4-8.4-6.4s-11.4,2.3-11.4,2.3s-5,0-8.3,1.6s-6.4,9.8-6.4,9.8S575.2,317.8,573.5,322.4z"/>
                <path className="st28" d="M448.4,590.1l178.3,36c2.1,0.4,3.5,2.5,3,4.6l0,0c-0.4,2.1-2.5,3.5-4.6,3l-178.3-36c-2.1-0.4-3.5-2.5-3-4.6
                  l0,0C444.3,591,446.3,589.6,448.4,590.1z"/>
                <path className="st5" d="M435.8,680.6l163.6,33.1c1,0.2,1.6,1.2,1.4,2.2v0c-0.2,1-1.2,1.6-2.2,1.4l-163.6-33.1c-1-0.2-1.6-1.2-1.4-2.2
                  v0C433.9,681,434.8,680.4,435.8,680.6z"/>
                <path className="st29" d="M445.9,585.7l182.6,36.9c2.1,0.4,3.5,2.5,3,4.6l0,0c-0.4,2.1-2.5,3.5-4.6,3l-182.6-36.9
                  c-2.1-0.4-3.5-2.5-3-4.6l0,0C441.8,586.6,443.8,585.3,445.9,585.7z"/>
                <path className="st30" d="M626.2,632.8l-180.9-36.6c-0.7-0.2-1.4,0.3-1.5,1c0,0,0,0,0,0l-10.3,81.5c-0.1,0.7,0.3,1.3,1,1.5l169,34.2
                  c0.7,0.1,1.4-0.3,1.5-1c0,0,0,0,0,0l22.2-79.1C627.3,633.7,626.9,633,626.2,632.8z M451.5,675.1c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6
                  c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1c0,0,0,0,0,0l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L451.5,675.1z M453.8,663.7
                  c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1c0,0,0,0,0,0l7.8,1.6
                  c0.7,0.1,1.1,0.8,1,1.5L453.8,663.7z M456.1,652.3c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8
                  c0.1-0.7,0.8-1.1,1.5-1c0,0,0,0,0,0l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L456.1,652.3z M458.4,640.9c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6
                  c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.9c0.1-0.7,0.8-1.1,1.5-1c0,0,0,0,0,0l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L458.4,640.9z M460.7,629.5
                  c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.5c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1c0,0,0,0,0,0l7.8,1.6
                  c0.7,0.1,1.1,0.8,1,1.5L460.7,629.5z M463,618.2c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8
                  c0.1-0.7,0.8-1.1,1.5-1c0,0,0,0,0,0l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L463,618.2z M463,677.4c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6
                  c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L463,677.4z M465.3,666
                  c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L465.3,666z
                  M467.6,654.6c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6
                  c0.7,0.1,1.1,0.8,1,1.5L467.6,654.6z M469.9,643.3c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8
                  c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L469.9,643.3z M472.2,631.9c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6
                  c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L472.2,631.9z M474.5,620.5
                  c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5
                  L474.5,620.5z M474.4,679.7c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6
                  c0.7,0.1,1.1,0.8,1,1.5L474.4,679.7z M476.7,668.3c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5c0,0,0,0,0,0l1.6-7.8
                  c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L476.7,668.3z M479,656.9c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6
                  c-0.7-0.1-1.1-0.8-1-1.5c0,0,0,0,0,0l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L479,656.9z M481.3,645.6
                  c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5c0,0,0,0,0,0l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6
                  c0.7,0.1,1.1,0.8,1,1.5c0,0,0,0,0,0L481.3,645.6z M483.6,634.2c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8
                  c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5c0,0,0,0,0,0L483.6,634.2z M485.9,622.8c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6
                  c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5c0,0,0,0,0,0L485.9,622.8z M486,682
                  c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L486,682z
                  M488.3,670.6c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.5c-0.7-0.1-1.1-0.8-1-1.5c0,0,0,0,0,0l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6
                  c0.7,0.1,1.1,0.8,1,1.5L488.3,670.6z M490.6,659.2c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5c0,0,0,0,0,0l1.6-7.8
                  c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L490.6,659.2z M492.9,647.9c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6
                  c-0.7-0.1-1.1-0.8-1-1.5c0,0,0,0,0,0l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L492.9,647.9z M495.2,636.5
                  c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5c0,0,0,0,0,0l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6
                  c0.7,0.1,1.1,0.8,1,1.5L495.2,636.5z M497.5,625.1c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8
                  c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5c0,0,0,0,0,0L497.5,625.1z M497.1,684.2c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6
                  c-0.7-0.1-1.1-0.8-1-1.5c0,0,0,0,0,0l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5c0,0,0,0,0,0L497.1,684.2z
                  M499.4,672.9c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6
                  c0.7,0.1,1.1,0.8,1,1.5c0,0,0,0,0,0L499.4,672.9z M501.7,661.5c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8
                  c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5c0,0,0,0,0,0L501.7,661.5z M504,650.1c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6
                  c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5c0,0,0,0,0,0L504,650.1z M506.3,638.7
                  c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.5c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5
                  L506.3,638.7z M508.6,627.3c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6
                  c0.7,0.1,1.1,0.8,1,1.5L508.6,627.3z M508.6,686.6c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5c0,0,0,0,0,0l1.6-7.8
                  c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L508.6,686.6z M510.9,675.2c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6
                  c-0.7-0.1-1.1-0.8-1-1.5c0,0,0,0,0,0l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L510.9,675.2z M513.2,663.8
                  c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5
                  L513.2,663.8z M515.5,652.4c-0.1,0.7-0.8,1.1-1.5,1c0,0,0,0,0,0l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1
                  l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L515.5,652.4z M517.8,641c-0.1,0.7-0.8,1.1-1.5,1c0,0,0,0,0,0l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5
                  l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L517.8,641z M520.1,629.7c-0.1,0.7-0.8,1.1-1.5,1c0,0,0,0,0,0
                  l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5c0,0,0,0,0,0L520.1,629.7z
                  M542.5,693.4c-0.1,0.7-0.8,1.1-1.5,1c0,0,0,0,0,0l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5c0,0,0,0,0,0l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1
                  c0,0,0,0,0,0l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L542.5,693.4z M544.8,682c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5
                  l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1c0,0,0,0,0,0l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5c0,0,0,0,0,0L544.8,682z M547.1,670.7
                  c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5
                  c0,0,0,0,0,0L547.1,670.7z M549.4,659.3c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1
                  l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5c0,0,0,0,0,0L549.4,659.3z M551.7,647.9c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5
                  c0,0,0,0,0,0l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L551.7,647.9z M554,636.5c-0.1,0.7-0.8,1.1-1.5,1
                  l-7.8-1.5c-0.7-0.1-1.1-0.8-1-1.5c0,0,0,0,0,0l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L554,636.5z M554,695.8
                  c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1c0,0,0,0,0,0l7.8,1.6
                  c0.7,0.1,1.1,0.8,1,1.5L554,695.8z M556.3,684.4c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8
                  c0.1-0.7,0.8-1.1,1.5-1c0,0,0,0,0,0l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L556.3,684.4z M558.6,673c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6
                  c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L558.6,673z M561,661.7
                  c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5
                  c0,0,0,0,0,0L561,661.7z M563.3,650.3c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1
                  l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5c0,0,0,0,0,0L563.3,650.3z M565.6,638.9c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5
                  l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L565.6,638.9z M565.5,698.1c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6
                  c-0.7-0.1-1.1-0.8-1-1.5c0,0,0,0,0,0l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L565.5,698.1z M567.8,686.7
                  c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5c0,0,0,0,0,0l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6
                  c0.7,0.1,1.1,0.8,1,1.5L567.8,686.7z M570.1,675.3c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8
                  c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5c0,0,0,0,0,0L570.1,675.3z M572.4,664c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6
                  c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5c0,0,0,0,0,0L572.4,664z M574.7,652.6
                  c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5
                  c0,0,0,0,0,0L574.7,652.6z M577,641.2c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1
                  l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5c0,0,0,0,0,0L577,641.2z M577,700.4c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5
                  l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5c0,0,0,0,0,0L577,700.4z M579.3,689.1c-0.1,0.7-0.8,1.1-1.5,1
                  l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L579.3,689.1z M581.6,677.7
                  c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5c0,0,0,0,0,0l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6
                  c0.7,0.1,1.1,0.8,1,1.5L581.6,677.7z M583.9,666.3c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5c0,0,0,0,0,0l1.6-7.8
                  c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L583.9,666.3z M586.2,654.9c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6
                  c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L586.2,654.9z M588.5,643.5
                  c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.5c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5
                  L588.5,643.5z M588.1,702.7c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6
                  c0.7,0.1,1.1,0.8,1,1.5L588.1,702.7z M590.4,691.3c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8
                  c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L590.4,691.3z M592.7,679.9c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6
                  c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L592.7,679.9z M595,668.5
                  c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.5c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L595,668.5z
                  M597.3,657.2c-0.1,0.7-0.8,1.1-1.5,1l-7.9-1.6c-0.7-0.1-1.1-0.8-1-1.5c0,0,0,0,0,0l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6
                  c0.7,0.1,1.1,0.8,1,1.5L597.3,657.2z M599.6,645.8c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5c0,0,0,0,0,0l1.6-7.8
                  c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L599.6,645.8z M599.6,705c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6
                  c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L599.6,705z M601.9,693.6
                  c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5
                  L601.9,693.6z M604.2,682.3c-0.1,0.7-0.8,1.1-1.5,1l-7.7-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6
                  c0.7,0.1,1.1,0.8,1,1.5L604.2,682.3z M606.5,670.9c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8
                  c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L606.5,670.9z M608.8,659.5c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6
                  c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5L608.8,659.5z M611.1,648.1
                  c-0.1,0.7-0.8,1.1-1.5,1l-7.8-1.6c-0.7-0.1-1.1-0.8-1-1.5l1.6-7.8c0.1-0.7,0.8-1.1,1.5-1l7.8,1.6c0.7,0.1,1.1,0.8,1,1.5
                  L611.1,648.1z"/>
                <path className="st12" d="M506.3,597.5l27.8-25.8c1.6-1.5,4.1-1.4,5.6,0.2l0,0c1.5,1.6,1.4,4.1-0.2,5.6l-27.8,25.8
                  c-1.6,1.5-4.1,1.4-5.6-0.2l0,0C504.6,601.5,504.7,599,506.3,597.5z"/>
                <path className="st12" d="M567.5,613.9L555.2,578c-0.7-2.1,0.4-4.3,2.5-5l0,0c2.1-0.7,4.3,0.4,5,2.5l12.3,35.8c0.7,2.1-0.4,4.3-2.5,5
                  l0,0C570.4,617,568.2,615.9,567.5,613.9z"/>
                <circle className="st12" cx="507.4" cy="602.1" r="1.7"/>
                <circle className="st12" cx="571.5" cy="615.4" r="1.7"/>
                <path className="st8" d="M554.4,553.7c0,0,3.8,6.7,6.4,21c1.3,6.9,2,9.4-0.2,9.9c-1.8,0.4-5-6-4.7-9.7c0,0,1.2,8-0.9,8.5
                  c-4.7,1.1-4.6-4.6-4.6-4.6s1,6.8-3.2,5.6s-2.5-6.4-2.5-6.4s-1.1,5.9-3.5,4.3c-5.3-3.6-0.9-10.7-0.9-10.7s-2.8,6.5-4.8,3.3
                  c-3-4.8,11.4-31,11.4-31L554.4,553.7z"/>
                <polygon className="st28" points="580.2,540.7 663.4,538.3 663.4,534.2 	"/>
                <path className="st5" d="M574.7,539.5l88.6-3.3c0,0-1.3-27.3-1.3-51.1s-5.4-50.2-5.4-64.5s17.3-25,20.5-37.7s24-66.8,11.2-90.6
                  c-12.6-7.9-23.8-4.1-23.8-4.1v42.9c0,6.4-7.9,22.2-7.9,27s-23.8,23.8-23.8,23.8l-17.5,7.9c-17.5,7.9-28.6-1.6-28.6-1.6
                  s-26,5.7-33.7,17.6c-9.4,14.4-20.9,64.8-20.9,82.3s6.2,73.9,6.2,73.9l26.1,0.9c0,0-1.6-57.9-1.6-70.7s1.2-17.5,7.3-48
                  C572.7,476,577.1,540.7,574.7,539.5z"/>
                <path className="st31" d="M556.2,574.9c-2.2-8.8-3.1-8.9-4.4-8.6c-2.6,0.5-1.7,9.4-1.7,9.4l-0.4-7.4c0.1-2.8-1.5-2.3-1.5-2.3
                  s-1.9-1.1-3.3,9c-0.4-5.1,1.8-9.8-1.2-10.2s-3.6,6.8-3.6,6.8s1.2-7-0.3-7.6c-0.9-0.3-1.9,0.1-2.4,0.9"/>
                <path className="st12" d="M603.9,785.7c0,0,4.1-10.1,13.7-14.9s29.8-10,29.8-10l17.1,1l1.6,22.8L603.9,785.7z"/>
                <path className="st12" d="M545.4,798.4c0,0,4.5-9.9,14.4-14.3s30.2-8.7,30.2-8.7l17,1.8l0.6,22.8L545.4,798.4z"/>
                <path className="st5" d="M659.9,536.1c0,0,5.3,16,5.3,41.4s6,196.3,6,196.3s-3.5-2.8-20.7-2.8s-17.9-7.7-17.9-7.7v-38.2
                  c0,0-7.8-65.1-7.8-79.4s-4.3-54.5-4.3-54.5s-1.8,45.6-1.8,64.7s-1.7,43.8-1.7,69.3s0.4,39-4.7,55.6c-22.5,3.1-33-3.3-33-3.3
                  s-5.5-176.7-5.5-198.9s2.9-53.6,2.9-53.6L659.9,536.1z"/>
                <path className="st8" d="M674.6,294c0,0-9.2-13.8-11-28.2s7.5,1.9,7.5,7.2c0,0,6.1-32.6,10.4-31.8s4.3,2.7,6.8,4.1
                  c5.7,3.1,2.6,11.1,4.9,14.1c3.4,4.5-10.1,43.6-10.1,43.6L674.6,294z"/>
              </g>
            </svg>
          </div>
{/* 
          <div className="apply-button-container">
            <a href="https://hiring.redlionhomecare.com" target="_blank" rel="noreferrer">
              <div className="apply-button form-submit-button">
                <div className="form-submit-button-background">
                  {form.jobApplicationButton}
                </div>
              </div>
            </a>
          </div>
*/}
        </div>

      </div>

    </div>
  )
}

export default Employment;
