import { useState, useEffect } from 'react';

function useIsIntersecting (targetRefs, rootMargin, threshold = .1) {

  const [isIntersecting, setIsIntersecting] = useState([]);

  useEffect(() => {

    // BEGIN INTERSECTION OBSERVER
    let intersectionCallback = (entries, observer, index) => { 

      entries.forEach(entry => {

        // Each entry describes an intersection change for one observed
        // target element:
        //   entry.boundingClientRect
        //   entry.intersectionRatio
        //   entry.intersectionRect
        //   entry.isIntersecting
        //   entry.rootBounds
        //   entry.target
        //   entry.time

        // let section = entry.target; 
        let newIsIntersecting = [...isIntersecting];
        
        if (entry.isIntersecting && (!isIntersecting[index] || isIntersecting[index]===false)) {  
          newIsIntersecting[index]=true;
          setIsIntersecting(newIsIntersecting);
        } else if (!entry.isIntersecting && (!isIntersecting[index] || isIntersecting[index]===true)) {
          // newIsIntersecting[index]=false;
         // setIsIntersecting(newIsIntersecting);
        }

      });
    };

    let options = { 
      root: null, // null = browser viewport; otherwise, use... document.querySelector('#scrollArea') // Must be an ancestor of the target
      rootMargin: rootMargin,
      threshold: threshold,
    }

    let observables = targetRefs.current;
    let observers = [];

    observables.forEach((observable, index) => {
      observers[index] = new IntersectionObserver((entries, observer) => intersectionCallback(entries, observer, index), options);
      observers[index].observe(observable);
    }); 
    // END INTERSECTION OBSERVER

    return () => {
      observables.forEach((observable, index) => {
        if (observers[index] && observable) observers[index].unobserve(observable);
      }); 
    }

  }, [targetRefs, isIntersecting, rootMargin, threshold]);

  return isIntersecting;
}

export default useIsIntersecting;
