import React, { useState, useEffect, useRef } from 'react'; 
import useIsIntersecting2 from '../../hooks/useIsIntersecting2';
import useIsMobile from '../../hooks/useIsMobile';
import ModalPortal from '../ModalPortal/ModalPortal';
import PatientResourcesModal from './PatientResourcesModal';

// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'; // clearAllBodyScrollLocks
import './PatientResources.scss';
//import data from '../../translations/es.json';

const { detect } = require('detect-browser');


function PatientResources({ data, language }) {
  
  const { counties } = data;

  const browser = detect();

  const [processedCounties, setProcessedCounties] = useState([]);

  /*
  useEffect(()=> {

    console.log('data', data);
 
  }, [data]);
  */

  useEffect(()=> {
    if (!counties || counties.length===0) return;
    
    const processedCounties = counties.map((county, index) => {
      return {...county, index: index};
    });

    setProcessedCounties(processedCounties);
  }, [counties]);

  const [redraw, setRedraw] = useState(0);

  const rerender = () => {
    const newRedraw = redraw+1;
    setRedraw(newRedraw);
  }

  const [searchTerm, setSearchTerm] = useState('');

  const onChangeSearch = (e) => {
    setSearchTerm(e.target.value); 
  }


  const animateRefs = useRef([]);
  const [rootMargin, setRootMargin] = useState();
  const isMobile = useIsMobile();

  /*
  useEffect(() => {
    animateRefs.current = animateRefs.current.slice(0, processedCounties.length); 
    console.log('animateRefs', animateRefs.current);
  }, [processedCounties]);
  */

  const isIntersecting = useIsIntersecting2(animateRefs, rootMargin);
  const [countyClassNames, setCountyClassNames] = useState([]);

  useEffect(() => {
    // const margin = isMobile ? Math.floor(screenWidth * -.1) :  Math.floor(screenWidth * -.2);
    const margin = isMobile ? -100 : -150;
    const newRootMargin = '0px 0px '+margin.toString()+'px 0px';
    if (rootMargin!==newRootMargin) setRootMargin(newRootMargin);
  }, [isMobile, rootMargin]);

  useEffect(() => {
    const newCountyClassNames = [...countyClassNames];
    isIntersecting.forEach((el, index) => {
      if (!countyClassNames[index] || countyClassNames[index]==='') {
        newCountyClassNames[index]= 'enter';
        setCountyClassNames(newCountyClassNames);
      } 
    })
  }, [countyClassNames, isIntersecting]);

  const handleOnAnimationEndCounty = (e, index) => {
    if (e.animationName==='enter-county' || e.animationName==='unhover-county') {
      const newCountyClassNames = [...countyClassNames];
      newCountyClassNames[index] = 'entered';
      setCountyClassNames(newCountyClassNames);
    } 
  }

  const handleOnMouseOverCounty = (e, index) => {
    const newCountyClassNames = [...countyClassNames];
    newCountyClassNames[index] = 'entered hover';
    setCountyClassNames(newCountyClassNames);
  }

  const handleOnMouseOutCounty = (e, index) => {
    const newCountyClassNames = [...countyClassNames];
    newCountyClassNames[index] = 'entered unhover';
    setCountyClassNames(newCountyClassNames);
  }

  const [showResourcesModal, setShowResourcesModal] = useState(false);
  const [selectedCounty, setSelectedCounty] = useState();
  const [modalTransformOrigin, setModalTransformOrigin] = useState({left: 50, top: 50});

  const openResourcesModal = (e, county) => {
    setSelectedCounty(county);
    setShowResourcesModal(true);

    // Grow modal from center of opening element
    const { top, left, width, height } = e.target.getBoundingClientRect();
    setModalTransformOrigin({
      left: Math.round(left+(width/2)-240),
      top: Math.round(top+(height/2))
    });
  }


  return (
    <div id="resources-container">

      <div className="background">
        <div className="left"></div>
        <div className="right"></div>  
      </div>
      <div className="pink-background"></div>
      
      <div className="page-header">{data[language].title}</div>
      
      <div className="search-resources-wrapper">
        <div className="search-resources-container"> 
          <input className="search-field" name="search" id="search" type="text" placeholder={data[language].countiesAndDistricts} 
            value={searchTerm}
            onChange={(e) => onChangeSearch(e)}
          /> 
          <div className="search-icon"></div>
        </div>

      </div>

      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
          {/* Scale (1/width, 1/height) converts path coordinates to range between 0 and 1 */}
          {/* viewBox="0 0 670 440" */}
          <clipPath 
            id="shape-1-clippath" 
            clipPathUnits="objectBoundingBox"
            transform="scale(0.0014925373134328, 0.0022727272727272)"
          >
            <path d="M136.6,15.34c78-13,126.7-1.17,224,38.01c74.33,21.79,90,7.99,167,23.99c61.24,12.73,86.59,35.63,109,65 c60.68,75.56,6.36,189.98-100.01,207c-64.2,4.18-88.99,3-137.99,46.01c-72.1,63.28-284.1,45.38-368-105.99 C-19.89,186.01,14.13,36.92,136.6,15.34z" />
          </clipPath>

          <clipPath 
            id="shape-3-clippath" 
            clipPathUnits="objectBoundingBox"
            transform="scale(0.0014925373134328, .00208333333333333)"
          >
            <path d="M337.77,477.04c-56.04,0-97,0-188.22-26.58C16.02,411.56-42.3,226.81,90.77,149.04 c65-38,140.49-27.39,162.01-30.99c47.92-10.14,83.65-47.66,128-73c105-60,240.22-73.78,265,81c6.55,43.25,28.15,185.77-62,267	C516.45,460.41,389.77,477.04,337.77,477.04z" />
          </clipPath>

          <clipPath 
            id="shape-4-clippath" 
            clipPathUnits="objectBoundingBox"
            transform="scale(0.0014925373134328, .00208333333333333)"
          >
            <path d="M308.79,462.16c-50.89,8.21-98.72,17.52-149.4,13.92 c-53.5-3.8-81.67-12.59-105.79-31.55C9.97,410.22,6.92,349.09,5.34,317.39c-4.66-93.61,42.31-164.18,60.32-188.38 C78.8,111.36,147.43,19.14,269.81,6.53C292,4.24,374.39-4.25,447.06,50.14c62.76,46.98,80.33,112.48,93.72,162.39 c9.72,36.23,19.4,74.39,3.71,116.92c-12.9,34.98-36.57,55.59-51.97,68.67C442.58,440.57,388.31,449.33,308.79,462.16z" />
          </clipPath>

          <clipPath 
            id="shape-5-clippath" 
            clipPathUnits="objectBoundingBox"
            transform="scale(0.0014925373134328, .00208333333333333)"
          >
            <path d="M23.79,309.28c-35.47-48.36-21.72-125.33,19.99-185.07C115.36,21.68,294.14-14.84,422.3,15.88 c51.72,12.4,195.47,46.85,234.72,172.82c29.8,95.62-9.03,203.77-83.83,242.46c-83.28,43.08-154.12,21.28-203.77-1.93 c-64.87-30.33-79.96-70.29-147.67-81.25C165.75,338.9,84.36,391.88,23.79,309.28z"/>
          </clipPath>

          <clipPath 
            id="shape-6-clippath" 
            clipPathUnits="objectBoundingBox"
            transform="scale(0.0014925373134328, .00208333333333333)"
          >
            <path d="M560.37,414.33c-90.08,56.18-224.55,65.44-304.26,14.11c-55.6-35.8-79.39-64.12-92.4-90.62 c-21.36-43.49-32.6-77.02-60.75-104.2c-44.09-42.56-83.21-37.06-97.51-88.09C-5.3,107.19,49.08,39.89,123.88,15.34 c88.3-28.98,174.95,11.62,325.2,82c139.17,65.2,208.95,99.02,217.99,158.71C679.51,338.16,574.65,405.43,560.37,414.33z"/>
          </clipPath>
          
          <clipPath 
            id="shape-7-clippath" 
            clipPathUnits="objectBoundingBox"
            transform="scale(0.0014925373134328, .00208333333333333)"
          >
            <path d="M3.96,204.95C1.88,108.1,81.78,17.74,159.97,7.52c69.82-9.13,81.97,53.01,186.18,62.91 C429,78.3,450.97,41.84,531.82,58.67c28.45,5.92,96.54,20.1,123.27,72.12c35.6,69.28-22.75,165.59-76.72,213.8 c-5.16,4.61-97.59,84.85-197.43,60.36c-73.25-17.97-79.31-74.84-148.84-81.33c-67.98-6.34-91.48,45.29-142.19,30.18 C36.3,337.83,5.21,263.09,3.96,204.95z"/>
          </clipPath>
            
          <clipPath 
            id="shape-9-clippath" 
            clipPathUnits="objectBoundingBox"
            transform="scale(0.0014925373134328, .00208333333333333)"
          >
            <path d="M136.58,56.48c49.77-31.62,109.45-47.5,183.2-47.5c82.03,0,139.14,13.88,198.41,42.31 c38.57,18.5,128.45,78.2,144.78,158c15.64,76.44-17.34,132.83-79.84,157.04c-47.57,18.42-64.49-13.15-142.79-4.32 c-64.54,7.28-76.82,17.73-155.25,56.5c-79.6,39.36-143.38,44.01-196,13.32C26.68,395.41-10.57,299.99,11.64,226.7 C26.4,177.98,59.31,105.57,136.58,56.48z"/>
          </clipPath>

          <clipPath 
            id="shape-10-clippath" 
            clipPathUnits="objectBoundingBox"
            transform="scale(0.0014925373134328, .00208333333333333)"
          >
            <path d="M7.24,315.16c-12.63-102.7,29.44-171.9,91.79-198.61c80.69-34.57,113.78,18.47,207.62-19.35 c78.12-31.48,80.58-78.47,150.07-90.81c9.47-1.68,99.77-16.1,159.17,52.51c51.06,58.99,55.36,114.11,43.34,179.65 c-17.6,95.99-83.41,123.98-140.6,124.77c-101.62,1.4-121.29,17.77-166.82,53.3c-75.52,58.94-130.4,66.33-187.95,56.07 C93.21,460.09,20.36,421.76,7.24,315.16z"/>
          </clipPath>
          
          <clipPath 
            id="shape-11-clippath" 
            clipPathUnits="objectBoundingBox"
            transform="scale(0.0014925373134328, .00208333333333333)"
          >
            <path d="M453.55,93.44C332.77-34.8,46.43-37.3,9.63,177.73c-17.94,77.58,24.28,211.84,166.81,269.69 c84.74,34.39,450.06,82.2,428.44-156.43C590.3,226.94,520.94,154.46,453.55,93.44z"/>
          </clipPath>

          <clipPath 
            id="shape-12-clippath" 
            clipPathUnits="objectBoundingBox"
            transform="scale(0.0014925373134328, .00208333333333333)"
          >
            <path d="M296.35,6.19c2.92,0.38-3.07-0.47,0.09,0.09c91.32,16.02,87.65,62.38,162.35,79.57 c67.29,15.49,80.64-14.95,155.4,28.84c27.56,16.14,69.91,87.23,45.39,160.21c-22.43,66.75-68.42,77.63-82.24,82.24 c-90.2,30.11-118.79-25.77-204-4.81C276.24,376.22,268.9,459.7,178.95,466.61C-147.11,455.26,38.23-27.81,296.35,6.19z"/>
          </clipPath>
          
          <clipPath 
            id="shape-13-clippath" 
            clipPathUnits="objectBoundingBox"
            transform="scale(0.0014925373134328, .00208333333333333)"
          >
            <path d="M280.04,3.91c76.28-0.84,268.07,42.42,321.06,139.16c71.87,120.35,8.78,250.95-121.56,293.39 c-36.7,16.73-119.76,53.2-225.49,34.38c-94.64-5-310.65-118.48-228.01-323.59C93.01,23.63,186.54,4.94,280.04,3.91z"/>
          </clipPath>

          
          <clipPath 
            id="triangle-1-clippath" 
            clipPathUnits="objectBoundingBox"
            transform="scale(0.0011904761904761, .00166666666666667)"
          >
            <polyline points="13.93,311.12 744.92,594.7 777.47,4.76"/>
          </clipPath>

          <clipPath 
            id="triangle-2-clippath" 
            clipPathUnits="objectBoundingBox"
            transform="scale(0.0011904761904761, .00166666666666667)"
          >
            <polygon points="11.25,21.56 829.22,6.38 596.46,593.25"/>
          </clipPath>

          <clipPath 
            id="triangle-3-clippath" 
            clipPathUnits="objectBoundingBox"
            transform="scale(0.0011904761904761, .00166666666666667)"
          >
            <path d="M268.17,3.81L766.4,538.78L8.11,595.46"/>
          </clipPath>

          <clipPath 
            id="triangle-4-clippath" 
            clipPathUnits="objectBoundingBox"
            transform="scale(0.0011904761904761, .00166666666666667)"
          >
            <path d="M726.04,12.82L15.19,375.44l745.2,140.09"/>
          </clipPath>
          
          <clipPath 
            id="triangle-5-clippath" 
            clipPathUnits="objectBoundingBox"
            transform="scale(0.0011904761904761, .00166666666666667)"
          >
            <polygon points="245.77,11.98 680.66,474.62 13.74,483.93"/>
          </clipPath>

          <clipPath 
            id="triangle-6-clippath" 
            clipPathUnits="objectBoundingBox"
            transform="scale(0.0011904761904761, .00166666666666667)"
          >
            <polygon points="719.9,238.37 251.03,567.49 8.61,166.46"/>
          </clipPath>

          <clipPath 
            id="triangle-7-clippath" 
            clipPathUnits="objectBoundingBox"
            transform="scale(0.0011904761904761, .00166666666666667)"
          >
            <polygon points="415.45,14.11 645.42,523.75 12.1,389.73"/>
          </clipPath>

          <clipPath 
            id="triangle-8-clippath" 
            clipPathUnits="objectBoundingBox"
            transform="scale(0.0011904761904761, .00166666666666667)"
          >
            <polygon points="10.78,7.38 828.75,22.56 243.54,594.25"/>
          </clipPath>

          <clipPath 
            id="triangle-9-clippath" 
            clipPathUnits="objectBoundingBox"
            transform="scale(0.0011904761904761, .00166666666666667)"
          >
            <polygon points="803.34,441.56 11.28,534.6 237.19,18.19 "/>
          </clipPath>

          <clipPath 
            id="triangle-10-clippath" 
            clipPathUnits="objectBoundingBox"
            transform="scale(0.0011904761904761, .00166666666666667)"
          >
            <polygon points="840,452.93 43.93,509.77 678.29,11.83 "/>
          </clipPath>

          <clipPath 
            id="triangle-11-clippath" 
            clipPathUnits="objectBoundingBox"
            transform="scale(0.0011904761904761, .00166666666666667)"
          >
            <polygon points="680.83,12.81 477.83,546.81 11.83,37.81"/>
          </clipPath>

          <clipPath 
            id="triangle-12-clippath" 
            clipPathUnits="objectBoundingBox"
            transform="scale(0.0011904761904761, .00166666666666667)"
          >
            <polygon points="12.79,399.24 827.21,481.8 655.74,12.98"/>
          </clipPath>

          
        </defs>
      </svg>

      <div className="counties-container">
        
        {processedCounties
          // .filter(county=> county.name.toLowerCase().indexOf(searchTerm.toLowerCase())>-1)
          .map((county, index) => {
            let cutoutNumber = (county.index%12)+1;
            
            return (
              <div 
                key={'county-'+county.index}
                ref={el => animateRefs.current[index] = el} 
                className={`county 
                  county-${county.index+1} 
                  shape-${cutoutNumber} 
                  ${typeof(countyClassNames[index])!=='undefined' ? countyClassNames[index] : ''}
                  ${searchTerm==='' || 
                    (county.countyName.toLowerCase().indexOf(searchTerm.toLowerCase())>-1) ||
                    (county.offices && county.offices.filter(office => office.officeName.toLowerCase().indexOf(searchTerm.toLowerCase())>-1).length>0)
                      ? 'include' 
                      : 'exclude'
                    }
                `} 
                onTransitionEnd={rerender}
                onAnimationEnd={(e) => handleOnAnimationEndCounty(e, county.index)}
                onMouseOver={(e) => handleOnMouseOverCounty(e, county.index)}
                onMouseOut={(e) => handleOnMouseOutCounty(e, county.index)}
                onClick={(e) => openResourcesModal(e, county)}
              >
                <div className="clipped-image">   
                  <div style={{backgroundImage: `url(${process.env.PUBLIC_URL+'/images/resources/'+county.mainImageFile})`}}>
                    <div className={browser.name} style={{backgroundImage: `url(${process.env.PUBLIC_URL+'/images/resources/'+county.mainImageFile.replace(/.(jpg|png|webp)/, '-filter.jpg')})`}}>  
                    </div>
                  </div>
                </div>
                <div className="triangle">
                  <div className="county-name">{county.countyName}</div>
                   <div style={{opacity:0}}>{redraw}</div>{/*  If don't rerender, zooming causes clipped images to disappear some times */}
                </div>
              </div>
            )
        })}
        <div className="county filler"></div>
        <div className="county filler"></div>
      </div>
    
      <ModalPortal showModal={showResourcesModal}>
        <PatientResourcesModal 
          setShowResourcesModal={setShowResourcesModal} 
          county={selectedCounty} 
          modalTransformOrigin={modalTransformOrigin}
          data={data}
          language={language}
        />
      </ModalPortal>
     
    </div>
  )
}

export default PatientResources;
