import axiosInstance from '../../common/axiosInstance';

export function fetchPages() {
  return new Promise((resolve, reject) => {
    axiosInstance.get('/pages-json.php')
      .then(response => {
        // console.log('response', response);
        if (response) {
          resolve(response);
        } else {
          reject();
        }
      })
      .catch ((err) => {
        console.log('Error', err);
      })
  });
}
