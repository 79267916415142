import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom'; // useLocation, useParams 
// import { TransitionGroup, CSSTransition } from "react-transition-group";
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import OurTeam from './components/OurTeam/OurTeam';
import Services from './components/Services/Services';
import Caregivers from './components/Caregivers/Caregivers';
import Employment from './components/Employment/Employment';
import QualifyingForCare from './components/QualifyingForCare/QualifyingForCare';
import EmployeeResources from './components/EmployeeResources/EmployeeResources';
import PatientResources from './components/PatientResources/PatientResources';
// import EmployeeAssistance from './components/EmployeeAssistance/EmployeeAssistance';
import Articles from './components/Articles/Articles';
import ContactUs from './components/ContactUs/ContactUs';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import Error404 from './components/Error404/Error404';

import { useSelector, useDispatch } from 'react-redux'; 
import { getPagesAsync, selectPages } from './features/pages/pagesSlice'; // selectPagesStatus
import Loader from './components/Loader/Loader';

// import english from './translations/en.json';
// import spanish from './translations/es.json';
import './App.scss';

// <OR>
// import devToolsEnhancer from 'remote-redux-devtools';
// const store = createStore(rootReducer, devToolsEnhancer());

const Pages = ({ pages, language }) => {

    const { 
      general, home, ourTeam, services, qualifyingForCare, caregivers, employeeResources, employment, 
      // employeeAssistance, 
      resources, articles, contactUs, privacyPolicy
     } = pages;

    const [baseUrl, setBaseUrl] = useState('');
    // const [data, setData] = useState(english);

    useEffect(()=> {
      const path = (language!=='' && language!=='en') ? '/'+language : '';
      setBaseUrl(process.env.PUBLIC_URL+path);
      // setData(language==='es' ? spanish : english);
    }, [language]);

    return (
      <>
        <Header  
          data={pages}
          baseUrl={baseUrl} 
          language={language} 
        />

        <Routes>
          <Route 
            path="/our-team"
            element={
              <OurTeam ourTeam={ourTeam} general={general[language]} language={language} />
            }
          />

          <Route  
            path="/services"
            element={
              <Services services={services[language]} />
            }
          />

          <Route 
            path="/qualifying-for-care"
            element={
              <QualifyingForCare data={qualifyingForCare[language]} englishFormData={qualifyingForCare?.en?.form} />
            }
          />

          <Route 
            path="/caregivers"
            element={
              <Caregivers data={caregivers[language]} language={language}/>
            }
          />


          <Route 
            path="/patient-resources"
            element={
              <PatientResources data={resources} language={language} />
            }
          />

          <Route 
            path="/caregiver-resources"
            element={
              <EmployeeResources data={employeeResources[language]} />
            }
          />

          <Route 
            path="/employee-resources"
            element={
              <EmployeeResources data={employeeResources[language]} />
            }
          />

          <Route 
            path="/employment"
            element={
              <Employment data={employment[language]} englishFormData={employment?.en?.form} general={general[language]} />
            }
          />

{/*
          <Route 
            path="/employee-assistance"
            element={
              <EmployeeAssistance data={employeeAssistance[language]} englishFormData={employeeAssistance?.en?.form} />
            }
          />
 */}


          <Route path="/blog" element={
            <Articles 
              data={articles[language]} 
              general={general[language]}
              baseUrl={baseUrl} 
              language={language} 
            />
            }
          >
            <Route path=":articleUrlTag" element={<div>child 1</div>} />
            <Route path="" element={<div>child 2</div>} />
          </Route>

          <Route 
            path="/contact-us"
            element={
              <ContactUs data={contactUs[language]} englishFormData={contactUs?.en?.form} general={general[language]} />
            }
          />

          <Route 
            path="/privacy-policy"
            element={
              <PrivacyPolicy data={privacyPolicy[language]} />
            }
          />

          <Route 
            path="/" 
            element={<Home data={home[language]} />}
          />

          <Route 
            path='/404-error' 
            element={<Error404 />}
          />

          <Route 
            path="*" 
            element={<Error404 />}
          />

        </Routes>

        <Footer  
          data={pages} 
          baseUrl={baseUrl}
          language={language}
        />
      </>
    );
}

const App = () => {

  const dispatch = useDispatch();

  const pages = useSelector(selectPages);
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    const getPages = async () => {
      //console.log('getPages');
      const response = await dispatch(getPagesAsync()).unwrap();
      setStatus('loaded');
      /*
      if (response?.page?.data) {
        const data = JSON.parse(response.pages);
        console.log('pages', data);
      }
      */
    }
    
    if (status==='loading') getPages();
    if (status==='' && !pages) setStatus('loading');
    if (status==='' && pages) setStatus('loaded');
  }, [status, pages, dispatch]);


  return !pages || pages.length===0
    ? <Loader />
    : (
    <div id="page">
      <Routes>
        <Route path={process.env.PUBLIC_URL+'/es/*'} element={<Pages pages={pages} language="es" />} /> 
        <Route path={process.env.PUBLIC_URL+'/*'} element={<Pages pages={pages} language="en" />} /> 
      </Routes>
      {/*
        <div className="background">
          <div className="left"></div>
          <div className="right"></div>
        </div>
      */}
    </div>
  );

}

export default App; 

