import React, { useState, useEffect, useRef} from 'react';
import { enableBodyScroll } from 'body-scroll-lock'  // from 'body-scroll-lock'; // clearAllBodyScrollLocks
import useDisableBodyScroll from '../../hooks/useDisableBodyScroll';
import ReactHtmlParser, { convertNodeToElement, processNodes } from 'react-html-parser';
//import Loader from '../Loader/Loader';
import '../FormModal/FormModal.scss';
import './ResourcesModal.scss';
import { indexOf } from 'lodash-es';

const ResourcesModal = ({setShowResourcesModal, modalTitle, modalCopy}) => {

  const modalRef = useRef();
  const closeIconRef = useRef();

  const scrollToTop = () => {
    var ua = window.navigator.userAgent;
    if (ua.indexOf('MSIE ') > 0 ||
    ua.indexOf('Trident/') > 0 ||
    ua.indexOf('Edge/') > 0) {
      modalRef.current.scrollTo(0,0);
    } else {
      modalRef.current.scroll({ 
        top: 0, 
        left: 0, 
        behavior: 'smooth'
      });
    }
  }

  useEffect(()=> {
    scrollToTop();
  }, []);

  useDisableBodyScroll(modalRef);

  const [modalClass, setModalClass] = useState('show');

  const closeModal = (e) => {
      if (
        !e ||
        (modalRef.current && !modalRef.current.contains(e.target)) ||
        (closeIconRef && closeIconRef.current.contains(e.target))
      ) {
        setModalClass('hide');
      }
  }

  const handleModalOnEndAnimation = (e) => {
    if (e.animationName==='exit-form-modal') {
      enableBodyScroll(modalRef.current); /* Must enable before modal disappears */
      setShowResourcesModal(false);
    }
  }

  const transform = (node, index)=> {
    if (node.type === "tag" && node.name === "a") {
      if (node.attribs.href.indexOf('tel:')<0 && 
          node.attribs.href.indexOf('mailto:')<0) {
        node.attribs.target = "_blank";
        node.attribs.rel="noreferrer"
      };
      
      node.attribs.class = "external-link";
      // console.log(node);
      // console.log(index);
      return convertNodeToElement(node, index, transform);
    }
  }

  const options = {
    decodeEntities: true,
    transform
  };

  return (
    <div 
      id="form-modal-wrapper" 
      onClick={(e) => closeModal(e)}
      className={modalClass}
      onAnimationEnd={(e) => handleModalOnEndAnimation(e)}
    >
      <div id="form-modal-container" className="resources-modal-container">
        
        <div id="form-modal-content" ref={modalRef}>

          <div ref={closeIconRef} className="icon-close-modal" onClick={(e) => closeModal(e)}></div>


             <div id="form-modal-header">{modalTitle}</div>
            
            <div id="form-container">

              {ReactHtmlParser(modalCopy, options)}
              
            </div>

        </div>  
      </div>
    </div>
  )
}

export default ResourcesModal;
