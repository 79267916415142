import { configureStore } from '@reduxjs/toolkit';
// import authReducer from '../features/auth/authSlice';
import articlesReducer from '../features/articles/articlesSlice';
import pagesReducer from '../features/pages/pagesSlice';
// import usersReducer from '../features/users/usersSlice';
// import counterReducer from '../features/counter/counterSlice';
import safetyCommitteeReducer from '../features/safetyCommittee/safetyCommitteeSlice';


let reducer = {  
  // auth: authReducer,
  pages: pagesReducer,
  articles: articlesReducer,
  // users: usersReducer,
  safetyCommittee: safetyCommitteeReducer,
};

/*
const preloadedState = {
    counter: [    
      {      
        text: 'Eat food',      
        completed: true,    
      },    
      {      
        text: 'Exercise',      
        completed: false,    
      },  
    ],  
    visibilityFilter: 'SHOW_COMPLETED',
  }
*/

export const store = configureStore({  
  reducer,  
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),  
  devTools: process.env.NODE_ENV !== 'production',
  // window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,  
  // preloadedState,  
  // enhancers: [reduxBatch],
})

// The store has been created with these options:
// - The slice reducers were automatically passed to combineReducers()
// - redux-thunk and redux-logger were added as middleware
// - The Redux DevTools Extension is disabled for production
// - The middleware, batch, and devtools enhancers were composed together
