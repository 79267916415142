import React, { useState, useEffect, useRef } from 'react';
import useScreenWidth from '../../hooks/useScreenWidth';
import './SlideNavigation.scss';

function SlideNavigation(props) {
      
  const { slides, dotsTotal, profileIndex, nextProfileIndex, goToProfile, slideDots, setSlideDots, dotsScroll, setDotsScroll } = props; 

  // dotsTotal is the number of displayed dots as opposed to the total number of slides
  
  const dotsRef = useRef();

  const slidesTotal = slides.length;

  const [dotIndexStartView, setDotIndexStartView] = useState(0);  
  const dotWidth = 50;
  const sliderWidth = (dotsTotal*dotWidth)+'px'; 
  
  const screenWidth = useScreenWidth();

  useEffect(()=>{
    if (slideDots===0) return;
    let newIndex = dotIndexStartView+slideDots;
    if (newIndex>slides.length-dotsTotal) newIndex=slides.length-dotsTotal;
    if (newIndex<0) newIndex=0;
    if (newIndex!==dotIndexStartView) setDotIndexStartView(newIndex);
    if (newIndex===0 && dotsScroll>0) setDotsScroll(0);
    setSlideDots(0);
  },[slideDots, setSlideDots, dotIndexStartView, dotsScroll, setDotsScroll, dotsTotal, setDotIndexStartView, slides]);

  useEffect(()=>{
    if (nextProfileIndex===-1) return; // User pressed an arrow
    const dotIndexEndView = dotIndexStartView+dotsTotal-1;
    if (nextProfileIndex>dotIndexEndView-2 && slidesTotal>nextProfileIndex) {
        let newDotIndexStartView = nextProfileIndex-(dotsTotal-3);
        if (newDotIndexStartView>slidesTotal-dotsTotal) newDotIndexStartView=slidesTotal-dotsTotal;
        setDotIndexStartView(newDotIndexStartView);
    } else if (nextProfileIndex===dotIndexEndView-1 && slidesTotal===nextProfileIndex+2) {
        setDotIndexStartView(slidesTotal-dotsTotal);
    } else if (nextProfileIndex<dotIndexStartView+2 && dotIndexStartView>1) {
        let newDotIndexStartView = nextProfileIndex-2;
        if (newDotIndexStartView<0) newDotIndexStartView=0;
        setDotIndexStartView(newDotIndexStartView);
    } else if (nextProfileIndex<3 && dotIndexStartView===1) {
        setDotIndexStartView(0);
    }
  },[nextProfileIndex, slideDots, profileIndex, slidesTotal, dotIndexStartView, dotsTotal, setDotIndexStartView]);
  
  useEffect(()=>{
    // If browswer is resized and dotsTotal changes...
    if (slideDots===0 && nextProfileIndex===-1) {
      if (dotIndexStartView>slidesTotal-dotsTotal) setDotIndexStartView(slidesTotal-dotsTotal);
    }
  }, [dotsTotal, slideDots, nextProfileIndex, dotIndexStartView, slidesTotal]);

  useEffect(()=>{
    if (dotsScroll!==dotIndexStartView*dotWidth) {
      setDotsScroll(dotIndexStartView*dotWidth);
    }
  }, [dotsScroll, dotIndexStartView, setDotsScroll]);

  const [sliderMargin, setSliderMargin] = useState('0 0 0 20px');

  useEffect(()=> {
    // Needed correction to center dots when at beginning or end of slider
    const dotIndexEndView = dotIndexStartView+dotsTotal-1;

    if (dotIndexStartView<1) {
        setSliderMargin('0 0 0 20px');
      } else if (dotIndexEndView>slidesTotal-2) {
        setSliderMargin('0 20px 0 0');
      } else {
        setSliderMargin('0');
      }
  },[dotIndexStartView, dotsTotal, slidesTotal]);
  
  const [controlDotsContainerStyle, setControlDotsContainerStyle] = useState({});

  useEffect(()=> {
    if (screenWidth>600) {
      setControlDotsContainerStyle({clip: `rect(0, ${(dotsTotal*50)+36}px, 120px, -36px)`});
    } else {
      setControlDotsContainerStyle({});
    }
  }, [screenWidth, dotsTotal])

  return (
    <div 
      className="control-dots-container"
      style={controlDotsContainerStyle} /* ${screenWidth<600 ? 300 : (dotsTotal*50)+18}px, 120px, -36px)`}}*/ /* Bottom extended for shadows */
    > 
      <div 
        ref={dotsRef} 
        className={slidesTotal>dotsTotal ? 'control-dots scroll-dots' : 'control-dots'}
        style={{
          width: sliderWidth, 
          margin: sliderMargin, 
          transform: `translateX(-${dotsScroll}px)`
        }}
      > 
        {slides.map((slide, index) => {

          if (!slide.images || !slide.images[0] || !slide.images[0].imageUrl) return <></>;

          const isSelected = index === profileIndex;
          const dotIndexEndView = dotIndexStartView+dotsTotal-1;
          let dotClass = "nav-dot";

          if (isSelected) dotClass += ' selected';
        
          if (slidesTotal>dotsTotal) {

            // Set dot size at beginning of dot strip
            if (dotIndexStartView>0) {
              if (index===dotIndexStartView+1) {
                dotClass += ' sm-dot left';
              } else if (index===dotIndexStartView) {
                dotClass += ' xs-dot left';
              } else if (index<dotIndexStartView) {
                dotClass += ' no-dot left';
              }
            } else if (dotIndexStartView===1) {
              if (index===1) {
                dotClass += ' sm-dot left';
              } else if (index===0){
                dotClass += ' xs-dot left';
              }
            }

            // Set dots at end of dot strip
            if (dotIndexEndView<slidesTotal-1) {
              if (index===dotIndexEndView-1) {
                dotClass += ' sm-dot right';
              } else if (index===dotIndexEndView) {
                dotClass += ' xs-dot right';
              } else if (index>dotIndexEndView) {
                dotClass += ' no-dot right';
            }
            } else if (dotIndexEndView===slidesTotal-2) {
              if (index===dotIndexEndView) {
                dotClass += ' sm-dot right';
              } else if (index>dotIndexEndView){
                dotClass += ' xs-dot right';
              }
            }
          }

          return (
            <div
              key={'slide-nav-'+index}
              className={dotClass}
              onClick={()=>goToProfile(index)}
              onKeyDown={()=>goToProfile(index)}          
            >
              <div>
                <img src={slide.images[0].imageUrl} alt={slide.fullName} title={slide.fullName} />
                {/* src={process.env.PUBLIC_URL+'/images/'+slide.photos[0].src}  */}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
}

export default SlideNavigation;
