import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchPages } from './pagesAPI';
// import getMapCoordinates from '../../utilities/getMapCoordinates';

const initialState = {
  pages: [],
  pagesLoaded: false,
  status: 'idle'
};


export const getPagesAsync = createAsyncThunk(
  'pages/fetchPages',
  async () => {
    const response = await fetchPages();
    return response?.data;
  }
);

export const pagesSlice = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    /*
    setCoordinates: (state, action) => {
      action.payload.forEach((coordinates, index) => {
        state.offices[index].coordinates = coordinates;
      })
    },
    */
  },

  extraReducers: (builder) => {
    builder
      .addCase(getPagesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPagesAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.pages = action.payload?.pages;
        state.pagesLoaded = true;
      })
  },
});

// export const { setCoordinates } = articlesSlice.actions;
export const selectPages = (state) => state.pages.pages;
export const selectPagesStatus = (state) => state.pages.status;

export default pagesSlice.reducer;
