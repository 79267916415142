import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enableBodyScroll } from 'body-scroll-lock'; // disableBodyScroll, // from 'body-scroll-lock'; // clearAllBodyScrollLocks
import ReactHtmlParser from 'react-html-parser';
import { parse, isValid, setDate, parseISO } from 'date-fns'; //  addMinutes, areIntervalsOverlapping, isBefore, addDays, compareAsc, differenceInMinutes
import { enUS, es } from 'date-fns/locale'; 
import { format } from 'date-fns-tz'; // utcToZonedTime, zonedTimeToUtc 
import { getSafetyCommitteeMeetingAsync } from '../../features/safetyCommittee/safetyCommitteeSlice';
import useDisableBodyScroll from '../../hooks/useDisableBodyScroll';
import useIsBreakpoint from '../../hooks/useIsBreakpoint';
import Loader from '../Loader/Loader';
import './SafetyCommitteeModal.scss';

// const isSafari = navigator.userAgent.indexOf("Safari") !== -1;
// const isIphone = navigator.userAgent.indexOf("iPhone") !== -1; 
// const isTouchDevice = window.matchMedia('(hover: none)').matches===true; 
// const isMobileIosSafari = isSafari && isTouchDevice;

const SafetyCommitteeModal = ({ setShowSafetyCommitteeModal, meeting, general, language }) => {

  const isSmallScreen = useIsBreakpoint(1024);

  const modalRef = useRef();
  const modalWrapperRef = useRef(); /* Different use case since want to scroll entire screen */
  const closeIconTopRef = useRef();
  const closeIconBottomRef = useRef();


  const dispatch = useDispatch();

  useEffect(()=> {
    if (meeting && !meeting?.copy?.en) dispatch(getSafetyCommitteeMeetingAsync(meeting.meetingId));
  }, [meeting, dispatch]);

  /*
  const scrollToTop = () => {
    var ua = window.navigator.userAgent;
    if (ua.indexOf('MSIE ') > 0 ||
    ua.indexOf('Trident/') > 0 ||
    ua.indexOf('Edge/') > 0) {
      modalWrapperRef.current.scrollTo(0,0);
    } else {
      modalWrapperRef.current.scroll({ 
        top: 0, 
        left: 0, 
        behavior: 'smooth'
      });
    }
  }
  */
 
  useDisableBodyScroll(modalRef);

  /*
    useDisableBodyScroll(modalRef.current, {reserveScrollBarGap: true});

    useEffect(() => {
      scrollToTop();
    }, []);
  */

/*
useEffect(() => {
  const modalElement = modalRef.current;
  // console.log('isMobileIosSafari',isMobileIosSafari);
    if (!modalElement) return;

    // Fix for iOs Safari
    // disableBodyLock is not working for iOs Safari... 
    // Scrolling sometimes freezes at bottom or top of modal
    // The solution is to prevent touch events from working on anything except menu element and elements inside of it */
/*    const handleTouchMove = (e) => {
      if (!modalRef.current) return;

      const insideMenu = [];

      modalRef.current
          .querySelectorAll("*")
          .forEach((node) => insideMenu.push(node));

      const targetIsMenu = e.target.id === modalRef.current.id;
      // console.log('e.target ', e.target, e.target.id);
      const modalContainsTarget = insideMenu.includes(e.target);
      const shouldPreventDefault = !(targetIsMenu || modalContainsTarget); 
      // console.log('shouldPreventDefault',shouldPreventDefault); 
      if (shouldPreventDefault) e.preventDefault();
    };

    if (isMobileIosSafari) {  
      window.addEventListener("touchmove", handleTouchMove, {passive: false});
    } else {
      const options = {reserveScrollBarGap: true};
      disableBodyScroll(modalElement, options);
    }

    scrollToTop();

    return () => {
      if (isMobileIosSafari) {
        window.removeEventListener("touchmove", handleTouchMove, {passive: false}); 
      }
    }
  }, []);
*/

  const [modalClass, setModalClass] = useState('show');
  const [modalScrollTop, setModalScrollTop] = useState(0);

  const closeModal = (e) => {
      if (
        !e ||
        (modalRef.current && !modalRef.current.contains(e.target)) ||
        (closeIconTopRef && closeIconTopRef.current.contains(e.target)) ||
        (closeIconBottomRef && closeIconBottomRef.current.contains(e.target))
      ) {
        setModalScrollTop(modalRef.current.scrollTop);
        setModalClass('hide');
      }
  }

  const handleModalOnEndAnimation = (e) => {
    if (e.animationName==='exit-meeting-modal') {
      enableBodyScroll(modalRef.current); /* Must enable before modal disappears */
      setShowSafetyCommitteeModal(false);
    }
  }

  const [meetingDate, setMeetingDate] = useState();

  useEffect(()=> {
    if (meeting && meeting.meetingDate) {
      setMeetingDate(format(parseISO(meeting.meetingDate), 'MMMM Y', { locale: language === 'es' ? es : enUS }))
    }
  }, [meeting, language]);

  return (
    <div 
      id="meeting-modal-wrapper" 
      ref={modalWrapperRef}
      onClick={(e) => closeModal(e)}
      className={modalClass}
      onAnimationEnd={(e) => handleModalOnEndAnimation(e)}
    >
      <div 
        id="meeting-modal-container" 
        ref={modalRef}
      >
        
        <div id="meeting-modal-content">

          <div ref={closeIconTopRef} className="icon-close-modal-top" onClick={(e) => closeModal(e)}></div>
          <div ref={closeIconBottomRef} className="icon-close-modal-bottom" onClick={(e) => closeModal(e)}></div>

          <div id="meeting-modal-header">
            {general && general[language]?.footer?.safetyCommittee}
          </div>

          <div id="meeting-modal-subheader">
            {general && general[language]?.footer?.meetingMinutes}
          </div>

          <div id="meeting-modal-date">
            {meetingDate}
          </div>

          <div className="meeting-modal-copy">
            {!meeting?.copy?.en
              ? <Loader stroke="#d3bdf1" />
              : ReactHtmlParser(meeting.copy[language].body)
            }
          </div>

        </div>  
      </div>
    </div>
  )
}

export default SafetyCommitteeModal;
