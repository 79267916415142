import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Link, useLocation } from "react-router-dom"; // useParams
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'; // clearAllBodyScrollLocks

// import Error404 from '../Error/Error404';   
// import scrollToTop from '../utilities/scrollToTop';

//const env = dotenv.config().parsed;
// var hostServer = REACT_APP_HOST_SERVER; /* Requires npm install --save-dev dotenv to be installed */
import './Header.scss';
import Logo from '../../images/logo-6.png';

//import data from '../../translations/es.json';


function Header({ data, baseUrl, language }) {

  const { 
    general, home, 
    // services, 
    qualifyingForCare, caregivers, resources, 
    employment, employeeResources, 
    // employeeAssistance, 
    contactUs 
  } = data; // ourTeam, articles, 

  // const params = useParams();
  // const { language } = params;

  // const dispatch = useDispatch();
  const menuRef = useRef();
  const closeMenuIconRef = useRef();

  const [menuClass, setMenuClass] = useState(''); // options: ''/show/hide
  
  const location = useLocation();

  const scrollToTop = () => {
    var ua = window.navigator.userAgent;
    if (ua.indexOf('MSIE ') > 0 ||
    ua.indexOf('Trident/') > 0 ||
    ua.indexOf('Edge/') > 0) {
      window.scrollTo(0,0);
    } else {
      window.scroll({ 
        top: 0, 
        left: 0, 
        behavior: 'smooth'
      });
    }
  }

  
  useEffect(()=> {
    if (location.pathname.indexOf(baseUrl+'/blog')!==0) {
      setTimeout(() => scrollToTop(), 20);
    }
  }, [location, baseUrl]);
  
  
  const openMenu = (e) => {
    if (menuClass!=='show') {   
      var options = {reserveScrollBarGap: true};
      disableBodyScroll(menuRef.current, options);
      document.body.style.pointerEvents='none';
      setMenuClass('show');
    }
  }

  const closeMenu = useCallback ((e) => {
    if (menuClass!=='show') return; 
    // console.log('closeMenu');
    // console.log(e.currentTarget.tagName);
    if (!e ||
      e.currentTarget.tagName === 'A' || // Using e.target.tagName prevents link navigation from working in iOs for some bizarre reason
      closeMenuIconRef.current.contains(e.target) ||
      !menuRef.current.contains(e.target)) {
        setMenuClass('hide');     
    }
  },[menuClass]);

  
  const handleMenuTransitionEnd = (e) => {
    if (menuClass==='hide') {
      setMenuClass('');
      enableBodyScroll(menuRef.current);
      document.body.style.pointerEvents='auto';
    }
  }

  return (
    <>
      <div id="header" className="home">
        <div id="logo"><Link to={baseUrl}><img src={Logo} alt="Red Lion Home Care"/></Link></div>
        <div className="globe-icon">
          <div className="languages">
            {/*!language || language === 'en'
              ? <>English</>
              : <Link to={location.pathname.replace('/es','')}>English</Link>
            */} 
            <Link to={location.pathname.replace('/es','')}>English</Link>  
            &nbsp;&bull;&nbsp;
            {/*language === 'es' 
              ? <>Español</>
              : <Link to={location.pathname.replace(process.env.PUBLIC_URL, process.env.PUBLIC_URL+'/es')}>Español</Link>
            */}
            <Link to={location.pathname.indexOf('/es')>-1 ? location.pathname : location.pathname.replace(process.env.PUBLIC_URL, process.env.PUBLIC_URL+'/es')}>Español</Link>
          </div>
        </div>
        <div className="social-container">
          <a title="Facebook" href="https://www.facebook.com/Red-Lion-Health-Care-277654772876850" target="_blank" rel="noreferrer"><div className="facebook-icon"></div></a>
          <a title="Instagram" href="https://www.instagram.com/redlion_healthcare" target="_blank" rel="noreferrer"><div className="instagram-icon"></div></a>
          <a title="Twitter" href="https://twitter.com/RedLionHomeCare" target="_blank" rel="noreferrer"><div className="twitter-icon"></div></a>
        </div>
        <div id="burger-menu-container">
          <div id="menu-word" onClick={openMenu}>{general && general[language].menu}</div>
          {/*<div id="burger-menu" onClick={openMenu}><div></div></div>*/}
        </div>
        <div className="menu-links">
          <ul> 
            <li><Link to={baseUrl}>{home && home[language].title}</Link></li>
            <li><Link to={baseUrl+'/our-team'}>{general && general[language].ourTeam}</Link></li>
            {/*<li><Link to={baseUrl+'/services'}>{services && services[language].title}</Link></li>*/}
            <li><Link to={baseUrl+'/qualifying-for-care'}>{qualifyingForCare && qualifyingForCare[language].title}</Link></li>
            <li><Link to={baseUrl+'/caregivers'}>{caregivers && caregivers[language].title}</Link></li>
            <li><Link to={baseUrl+'/patient-resources'}>{resources && resources[language].title}</Link></li>
            <li><Link to={baseUrl+'/blog'}>{general && general[language].articles}</Link></li>
            {/* <li><Link to={baseUrl+'/heroes-home-care'}>Heroes to Home Care</Link></li> */}
            <li><Link to={baseUrl+'/employment'}>{employment && employment[language].title}</Link></li>
            <li><Link to={baseUrl+'/employee-resources'}>{employeeResources && employeeResources[language].title}</Link></li>
            {/* <li><Link to={baseUrl+'/employee-assistance'}>{employeeAssistance && employeeAssistance[language].title}</Link></li> */}
            {/* <li><Link to={baseUrl+'/esop'}>ESOP</Link></li> */}
            
            
            <li><Link to={baseUrl+'/contact-us'}>{contactUs && contactUs[language].title}</Link></li>
          </ul>  
        </div>
      </div>

      <div id="fade-screen" className={menuClass} onClick={closeMenu}></div>
      <div id="side-menu-container" className={menuClass} onTransitionEnd={handleMenuTransitionEnd}>
        <div id="side-menu" ref={menuRef}>
          <div id="close-menu-icon" ref={closeMenuIconRef} onClick={closeMenu}><div><div></div></div></div>
          <div id="side-menu-background"></div>
          <div id="side-menu-links">
            <ul> 
              <li><Link to={baseUrl} onClick={closeMenu}>{home && home[language].title}</Link></li>
              <li><Link to={baseUrl+'/our-team'} onClick={closeMenu}>{general && general[language].ourTeam}</Link></li>
              {/*<li><Link to={baseUrl+'/services'} onClick={closeMenu}>{services && services[language].title}</Link></li>*/}
              <li><Link to={baseUrl+'/qualifying-for-care'} onClick={closeMenu}>{qualifyingForCare && qualifyingForCare[language].title}</Link></li>
              <li><Link to={baseUrl+'/caregivers'} onClick={closeMenu}>{caregivers && caregivers[language].title}</Link></li>
              <li><Link to={baseUrl+'/patient-resources'} onClick={closeMenu}>{resources && resources[language].title}</Link></li>
              <li><Link to={baseUrl+'/blog'} onClick={closeMenu}>{general && general[language].articles}</Link></li>
              {/* <li><Link to={baseUrl+'/heroes-home-care'} onClick={closeMenu}>Heroes to Home Care</Link></li> */}
              <li><Link to={baseUrl+'/employment'} onClick={closeMenu}>{employment && employment[language].title}</Link></li>
              <li><Link to={baseUrl+'/employee-resources'} onClick={closeMenu}>{employeeResources && employeeResources[language].title}</Link></li>
              {/* <li><Link to={baseUrl+'/employee-assistance'} onClick={closeMenu}>{employeeAssistance && employeeAssistance[language].title}</Link></li> */}
              {/* <li><Link to={baseUrl+'/esop'} onClick={closeMenu}>ESOP</Link></li> */}
              <li><Link to={baseUrl+'/contact-us'} onClick={closeMenu}>{contactUs && contactUs[language].title}</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
