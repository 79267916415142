import { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { enableBodyScroll } from 'body-scroll-lock';  // from 'body-scroll-lock'; // disableBodyScroll, clearAllBodyScrollLocks
import useDisableBodyScroll from '../../hooks/useDisableBodyScroll';
// import { ModalWrapper, ModalContainer, ModalContent, CloseIconContainer, CloseIcon, ModalHeader } from './modalStyles';


const PortalContent = ({ setShowModal, children }) => {

    const modalRef = useRef();
    const modalWrapperRef = useRef();
    const closeIconRef = useRef();

    const scrollToTop = () => {
      var ua = window.navigator.userAgent;
      if (ua.indexOf('MSIE ') > 0 ||
      ua.indexOf('Trident/') > 0 ||
      ua.indexOf('Edge/') > 0) {
        modalWrapperRef.current.scrollTo(0,0);
      } else {
        modalWrapperRef.current.scroll({ 
          top: 0, 
          left: 0, 
          behavior: 'smooth'
        });
      }
    }

    useEffect(()=> {
      setTimeout(scrollToTop, 0);
    }, []);

    useDisableBodyScroll(modalRef);

    const [modalClass, setModalClass] = useState('show');

    const closeModal = (e) => {
        if (
          !e ||
          ( modalRef.current && !modalRef.current.contains(e.target) ) ||
          (closeIconRef && closeIconRef.current && closeIconRef.current.contains(e.target))
        ) {
          setModalClass('hide');
        }
    }

    const handleModalOnEndAnimation = (e) => {
      if (e.animationName==='exit-modal') {
        enableBodyScroll(modalRef.current); /* Must enable before modal disappears */
        setShowModal(false);
      }
    }

    return (
      <div id="modal-wrapper" 
        ref={modalWrapperRef}
        onClick={(e) => closeModal(e)}
        className={modalClass}
        onAnimationEnd={(e) => handleModalOnEndAnimation(e)}
      >
        <div id="modal-container" className={modalClass}>
        
          <div id="modal-content" ref={modalRef}>
    
            <div id="close-icon-container" ref={closeIconRef} onClick={(e) => closeModal(e)}>
              <div id="close-icon" icon="close" />
            </div>
    
            {children}
            
          </div>  
        </div>
      </div>
    );
};


const ModalPortal = ({ showModal, children }) => { // setShowModal
  
  /*
  const [modalRoot, setModalRoot] = useState();

  useEffect(()=> {
    const root = typeof document !== `undefined` ? document.getElementById('modal-root') : null;
    setModalRoot(root);
  }, [])
  */

 return showModal // && modalRoot
    ? createPortal(<>{children}</>, document.getElementById('modal-root')) // <PortalContent setShowModal={setShowModal}>{children}</PortalContent>
    : null
};

export default ModalPortal;