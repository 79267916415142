import { useState, useEffect } from 'react';

function useIsMobile () {
  const [isMobile, setIsMobile] = useState(window.innerWidth<=600);

  useEffect(()=> {
    const handleResize = () => {
      const newIsMobile = window.innerWidth<=600;
      if (isMobile!==newIsMobile) setIsMobile(newIsMobile);
    }
    window.addEventListener('resize', handleResize, false);
    return () => {
      window.removeEventListener('resize', handleResize, false);
    }
  },[isMobile])

  return isMobile;
}

export default useIsMobile;