import React, { useState, useEffect, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import useIsIntersecting from '../../hooks/useIsIntersecting2';
import ModalPortal from '../ModalPortal/ModalPortal';
import ResourcesModal from './ResourcesModal';
import ResourcesEmbedModal from './ResourcesEmbedModal';
import './EmployeeResources.scss';

const EmployeeResources = ({ data }) => {

  const { 
    title, newBenefitsButton, newBenefitsModalTitle, 
    benefitsGuidebookButton, newBenefitsModalCopy, eitcButton, 
    eitcModalTitle, eitcModalCopy, tbEducationButton, jobApplicationButton,
    handbookCopy, handbookButton, examButton 
  } = data;
  
  const animateRefs = useRef([]);
  const isIntersecting = useIsIntersecting(animateRefs, '0px 0px 0px 0px', .3);
  const [animationClassNames, setAnimationClassNames] = useState([]);

  useEffect(() => {
    if (!isIntersecting || !animateRefs || animateRefs.current.length===0) return;
    animateRefs.current.forEach((ref, index) => {
      if (isIntersecting[index]===true && !animationClassNames[index]) {
        let classes = [...animationClassNames];
        classes[index]='enter';
        setAnimationClassNames(classes);
      }
    });
  }, [isIntersecting, animationClassNames]);

  const [showResourcesEmbedModal, setShowResourcesEmbedModal] = useState(false);
  const [showResourcesModal, setShowResourcesModal] = useState(false);

  const [modalName, setModalName] = useState();
  const [modalTitle, setModalTitle] = useState();
  const [modalCopy, setModalCopy] = useState();

  const openResourcesEmbedModal = (newModalName) => {
    setShowResourcesModal(false);
    setModalName(newModalName)
    setShowResourcesEmbedModal(true);
  }


  const openResourcesModal = (newModalName) => {
    setShowResourcesEmbedModal(false);
    if (newModalName==='newBenefits') {
      setModalTitle(newBenefitsModalTitle);
      setModalCopy(newBenefitsModalCopy);
    } else if (newModalName==='eitc') {
      setModalTitle(eitcModalTitle);
      setModalCopy(eitcModalCopy);
    } else {
      return;
    }
    setShowResourcesModal(true);
  }
  


  return (
    <div className="employee-resources-container">

      <div className="background">
        <div className="left"></div>
        <div className="right"></div>  
      </div>
      <div className="pink-background"></div>

      <div className="page-heading">
        {title}
      </div>

      <div className="employee-resources-content">
        <div>
          <div 
            ref={el => animateRefs.current[0] = el} 
            className={`section employee-resources-copy ${animationClassNames[0] || ''}`}
          >

            <div>
              <div className="employee-resources-button" onClick={() => openResourcesEmbedModal('employeeHandbook')} >
                <div className="employee-resources-button-background">
                  {ReactHtmlParser(handbookButton)}
                </div>
              </div>
            </div>

            <div>
                <div className="employee-resources-button" onClick={() => openResourcesEmbedModal('benefitsGuidebook')}>
                    <div className="employee-resources-button-background">
                      {ReactHtmlParser(benefitsGuidebookButton)}
                    </div>
                </div>
            </div>

            <div>
              <div className="employee-resources-button" onClick={() => openResourcesModal('newBenefits')} >
                  <div className="employee-resources-button-background">
                    {ReactHtmlParser(newBenefitsButton)}
                  </div>
              </div>
            </div>

            <div>
              <div className="employee-resources-button" onClick={() => openResourcesModal('eitc')} >
                  <div className="employee-resources-button-background">
                    {ReactHtmlParser(eitcButton)}
                  </div>
              </div>
            </div>

            <div>
              <div className="employee-resources-button" onClick={() => openResourcesEmbedModal('tbEducation')} >
                  <div className="employee-resources-button-background">
                    {ReactHtmlParser(tbEducationButton)}
                  </div>
              </div>
            </div>
{/* 
            <div>
              <a href={process.env.PUBLIC_URL+'/pdfs/tb-education.pdf'} target="_blank" rel="noreferrer">
                <div className="employee-resources-button">
                    <div className="employee-resources-button-background">
                      {ReactHtmlParser(tbEducationButton)}
                    </div>
                </div>
              </a>
            </div>
*/}

            <div>
              <a href="https://hiring.redlionhomecare.com" target="_blank" rel="noreferrer">
                <div className="employee-resources-button">
                    <div className="employee-resources-button-background">
                      {ReactHtmlParser(jobApplicationButton)}
                    </div>
                </div>
              </a>
            </div>

            <div>
              <div className="section-exam-copy">
                {ReactHtmlParser(handbookCopy)}
              </div>

              <div className="employee-resources-button" onClick={() => openResourcesEmbedModal('exam')} >
                <div className="employee-resources-button-background">
                  {ReactHtmlParser(examButton)}
                </div>
              </div>
            </div>

          </div>

          <div 
            ref={el => animateRefs.current[1] = el} 
            className={`caregiver-exam-svg-container ${animationClassNames[1] || ''}`}
          >
            <div>
              <div className="background-shape-container">
                <svg 
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" y="0px"
                  viewBox="0 0 2550 1720"
                >
                  <path id="background-shape" className="st1" d="M965.63,1141.05c-88.52-47.23-200.04-49.97-299.14-66.47
                    c-92.3-15.37-260.42-82.81-327.64-155.89C222.7,792.43,229.71,516.68,405.19,428.58c201.35-101.09,454.34-8.31,672.51,21.7
                    c336.69,46.31,409.15-226.02,736.63-272.87c125.25-17.92,304,21.4,365.31,154.97c77.82,169.54-25.41,364.33,91.35,525.18
                    c43.64,60.12,326.29,313.22,37.79,412.9c-103.25,35.67-209.17,58.81-314.72,85.24c-101.55,25.43-200.32,55.83-291.49,109.86
                    c-121.7,72.11-255.59,179.69-395.7,107.02C1126.59,1479.06,1130.07,1228.8,965.63,1141.05z"/>
                </svg>
              </div>

              <div className="plant-container">
                <svg 
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" y="0px"
                  viewBox="0 0 2550 1720"
                >
                  <g id="plant">
                    <path className="st2" d="M433.86,1155.06c0,0,307.36-147.04,257.53-354.18c-40.04-166.48-79.12,43.63-169.73,206.05
                      c-7.11,12.75-27.03,3.42-21.04-9.86c58.84-130.39,135.47-332.97,73.21-388.95c-94-84.51-73.92-22.11-107.07,147.25
                      c-33.15,169.37-84.67,189.58-78.84,74.68c5.83-114.91,114.71-270.29,72.7-325.02c-42.01-54.74-142.48-105.98-183.75-134.55
                      c-41.27-28.56-176.09,173.73-133.49,250.17c42.6,76.44,162.93,162.11,166.43,239.88c3.5,77.77-93.02-31.41-140.56-95.43
                      c-47.54-64.02-87.72-95.27-101.02,2.84C54.94,866.05,158.7,948.4,244.23,982.66c85.54,34.26,125.17,81.02,81.84,67.47
                      c-43.33-13.55-173-65.87-204.42-107.05C90.23,901.91,75.66,1130.11,433.86,1155.06z"/>
                    <path className="st3" d="M441.37,1138.27c0.65-0.84,0.81-1.99,0.32-3C330.35,905.83,301.35,564.42,301.07,561
                      c-0.13-1.61-1.59-2.81-3.25-2.68c-1.66,0.13-2.9,1.55-2.77,3.16c0.28,3.43,29.36,345.88,141.18,576.29
                      c0.71,1.46,2.51,2.08,4.02,1.39C440.7,1138.96,441.08,1138.65,441.37,1138.27z"/>
                  </g>
                </svg>
              </div>

              <div className="laptop-container">
                <svg 
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" y="0px"
                  viewBox="0 0 2550 1720"
                >
                  <g id="laptop">
                    <g id="computer">
                      <g className="st4">
                        <path className="st5" d="M1764.25,1637.21c-20.06,0-38.27-4.39-52.65-12.69l-557.6-321.93c-19.2-11.09-30.21-28.63-30.21-48.13
                          c0-21.37,13.26-41.29,36.38-54.63l316.44-182.7c18.16-10.48,40.86-16.25,63.94-16.25c20.06,0,38.27,4.39,52.66,12.69
                          l557.6,321.93c19.2,11.09,30.21,28.63,30.21,48.12c0,21.37-13.26,41.28-36.38,54.63l-316.45,182.7
                          C1810.03,1631.44,1787.33,1637.21,1764.25,1637.21z"/>
                        <path className="st6" d="M1478,1019.55c18.11-10.45,40.92-16.05,63.75-15.8c9.22,0.11,18,1.3,26.15,3.2
                          c8.77,2.05,18.99,6.2,26.04,10.27l555.47,320.7c19.11,11.03,29.07,28.51,27.92,47.51c-0.32,5.28-1.56,10.41-3.38,15.46
                          c-4.86,13.54-18.6,27.96-34.18,36.96l-311.94,180.1c-4,2.31-9.27,4.93-13.69,6.75c-14.97,6.15-31.98,9.61-49.21,9.7
                          c-19.75,0.11-37.8-4.13-51.94-12.3l-557.6-321.93c-19.15-11.06-29.06-28.6-27.81-47.61c0.3-4.54,1.29-8.96,2.65-13.34
                          c4.49-14.35,18.71-29.7,35.07-39.14L1478,1019.55z"/>
                        <path className="st7" d="M1479.4,1021.97c18.06-10.43,40.98-15.86,63.56-15.35c9.2,0.23,17.9,1.66,25.94,3.65
                          c7.87,1.94,19.43,6.93,25.77,10.59l553.34,319.47c19.02,10.98,27.95,28.38,25.62,46.9c-0.63,5.04-2.24,9.87-4.15,14.74
                          c-4.74,12.04-20.56,27.37-34.59,35.47l-307.44,177.5c-3.6,2.08-9.49,5.07-13.46,6.73c-14.7,6.13-31.44,9.73-48.4,9.92
                          c-19.44,0.21-37.32-3.87-51.22-11.9l-557.6-321.93c-19.11-11.03-27.93-28.54-25.41-47.1c0.59-4.33,1.91-8.49,3.39-12.71
                          c4.46-12.78,20.95-29.1,35.67-37.6L1479.4,1021.97z"/>
                        <path className="st8" d="M1480.79,1024.38c18.01-10.4,41.04-15.66,63.37-14.89c9.18,0.34,17.79,2.02,25.73,4.1
                          c6.97,1.82,19.87,7.66,25.51,10.92l551.22,318.24c18.93,10.93,26.87,28.24,23.33,46.28c-0.94,4.79-2.91,9.31-4.93,14.01
                          c-4.55,10.57-22.52,26.78-34.99,33.98l-302.93,174.9c-3.2,1.85-9.7,5.22-13.22,6.71c-14.43,6.12-30.9,9.86-47.59,10.14
                          c-19.13,0.32-36.85-3.61-50.51-11.5l-557.6-321.93c-19.06-11-26.84-28.47-23.01-46.58c0.87-4.11,2.54-8.01,4.12-12.09
                          c4.35-11.22,23.2-28.51,36.28-36.06L1480.79,1024.38z"/>
                        <path className="st9" d="M1482.19,1026.8c17.96-10.37,41.1-15.46,63.17-14.44c9.16,0.45,17.68,2.38,25.53,4.55
                          c6.08,1.68,20.31,8.39,25.25,11.24l549.09,317.02c18.84,10.88,25.81,28.08,21.04,45.67c-1.23,4.54-3.57,8.73-5.71,13.29
                          c-4.28,9.11-24.48,26.19-35.39,32.49l-298.43,172.3c-2.8,1.61-9.91,5.36-12.98,6.69c-14.16,6.1-30.36,9.98-46.78,10.35
                          c-18.81,0.43-36.37-3.35-49.79-11.1l-557.6-321.93c-19.01-10.98-25.79-28.38-20.61-46.07c1.14-3.9,3.16-7.51,4.86-11.47
                          c4.15-9.67,25.44-27.91,36.89-34.52L1482.19,1026.8z"/>
                        <path className="st10" d="M1483.58,1029.21c17.91-10.34,41.15-15.26,62.98-13.98c9.13,0.56,17.57,2.74,25.32,5
                          c5.19,1.51,20.75,9.12,24.98,11.57l546.96,315.79c18.75,10.83,24.8,27.92,18.75,45.06c-1.51,4.29-4.22,8.14-6.49,12.56
                          c-3.94,7.68-26.44,25.6-35.8,31l-293.93,169.7c-2.4,1.38-10.12,5.52-12.75,6.67c-13.89,6.09-29.82,10.11-45.97,10.57
                          c-18.5,0.53-35.9-3.09-49.08-10.7l-557.6-321.93c-18.96-10.95-24.78-28.3-18.22-45.55c1.4-3.68,3.77-7,5.59-10.85
                          c3.86-8.16,27.68-27.32,37.5-32.98L1483.58,1029.21z"/>
                        <path className="st11" d="M1484.98,1031.63c17.86-10.31,41.21-15.06,62.79-13.53c9.11,0.68,17.45,3.1,25.11,5.45
                          c4.3,1.32,21.19,9.85,24.72,11.89L2142.43,1350c18.66,10.77,23.82,27.77,16.45,44.45c-1.78,4.04-4.09,8.07-7.27,11.84
                          c-2.23,6.84-28.41,25.01-36.2,29.51L1826,1602.89c-2,1.15-10.33,5.67-12.51,6.65c-13.62,6.07-29.28,10.23-45.16,10.79
                          c-18.19,0.64-35.42-2.83-48.36-10.31l-557.6-321.93c-18.92-10.92-23.81-28.22-15.82-45.04c1.65-3.47,3.64-6.96,6.32-10.23
                          c2.02-7.24,29.93-26.73,38.1-31.44L1484.98,1031.63z"/>
                        <path className="st12" d="M1486.38,1034.05c17.82-10.28,41.27-14.86,62.6-13.08c9.09,0.79,17.33,3.46,24.9,5.9
                          c3.43,1.1,21.64,10.59,24.46,12.21l542.7,313.33c18.57,10.72,22.89,27.64,14.16,43.83c-2.05,3.8-4.62,7.59-8.05,11.11
                          c-1.79,5.47-30.37,24.42-36.6,28.02l-284.92,164.5c-1.6,0.92-10.54,5.84-12.28,6.63c-13.36,6.06-28.73,10.35-44.35,11.01
                          c-17.88,0.75-34.95-2.57-47.65-9.91l-557.6-321.93c-18.87-10.9-22.89-28.17-13.42-44.52c1.89-3.26,4.14-6.55,7.06-9.61
                          c1.62-5.79,32.17-26.13,38.71-29.91L1486.38,1034.05z"/>
                        <path className="st13" d="M1487.77,1036.46c17.77-10.26,41.33-14.66,62.41-12.62c9.07,0.9,17.21,3.82,24.7,6.35
                          c2.56,0.86,22.08,11.32,24.19,12.54l540.58,312.1c18.48,10.67,21.99,27.55,11.87,43.22c-2.3,3.56-5.16,7.11-8.82,10.39
                          c-1.34,4.1-32.33,23.83-37.01,26.53l-280.41,161.9c-1.2,0.69-10.74,6-12.04,6.6c-13.09,6.04-28.19,10.48-43.54,11.23
                          c-17.56,0.85-34.47-2.31-46.94-9.51l-557.6-321.93c-18.82-10.87-22.01-28.16-11.02-44.01c2.12-3.06,4.64-6.14,7.79-8.99
                          c1.21-4.34,34.41-25.54,39.32-28.37L1487.77,1036.46z"/>
                        <path className="st14" d="M1489.17,1038.88c17.72-10.23,41.39-14.46,62.22-12.17c9.05,1.02,17.07,4.17,24.49,6.8
                          c1.7,0.6,22.52,12.05,23.93,12.86l538.45,310.87c18.39,10.62,21.13,27.49,9.58,42.61c-2.55,3.33-5.69,6.63-9.6,9.67
                          c-0.89,2.73-34.29,23.24-37.41,25.04l-275.91,159.3c-0.8,0.46-10.94,6.18-11.81,6.58c-12.83,6.03-27.65,10.6-42.73,11.44
                          c-17.25,0.96-34-2.06-46.22-9.11l-557.6-321.93c-18.78-10.84-21.16-28.19-8.62-43.49c2.35-2.87,5.14-5.73,8.52-8.37
                          c0.81-2.9,36.65-24.94,39.93-26.83L1489.17,1038.88z"/>
                        <path className="st15" d="M1490.56,1041.3c17.67-10.2,41.44-14.26,62.02-11.71c9.03,1.13,16.94,4.52,24.28,7.25
                          c0.84,0.31,22.96,12.78,23.67,13.19l536.32,309.65c18.3,10.56,20.29,27.47,7.28,41.99c-2.79,3.11-6.22,6.15-10.38,8.94
                          c-0.45,1.37-36.25,22.65-37.81,23.55l-271.4,156.7c-0.4,0.23-11.14,6.36-11.57,6.56c-12.57,6.02-27.11,10.73-41.92,11.66
                          c-16.94,1.07-33.52-1.8-45.51-8.71l-557.6-321.93c-18.73-10.81-20.34-28.27-6.22-42.98c2.58-2.69,5.64-5.32,9.26-7.75
                          c0.4-1.45,38.9-24.35,40.53-25.29L1490.56,1041.3z"/>
                        <path d="M1726.94,1597.94l-557.6-321.93c-22.02-12.71-19.26-34.92,6.17-49.6l316.45-182.7c25.43-14.68,63.89-16.27,85.91-3.56
                          l557.6,321.93c22.02,12.71,19.26,34.92-6.17,49.6l-316.45,182.7C1787.42,1609.06,1748.96,1610.65,1726.94,1597.94z"/>
                      </g>
                      <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="1176.4939" y1="1318.5344" x2="2211.6267" y2="1318.5344">
                        <stop offset="0.0556" style={{stopColor:'#8179B8'}} />
                        <stop offset="0.2335" style={{stopColor:'#837AB9'}} />
                        <stop offset="0.3212" style={{stopColor:'#8B7FBC'}} />
                        <stop offset="0.3891" style={{stopColor:'#9887C2'}} />
                        <stop offset="0.4465" style={{stopColor:'#AA93CB'}} />
                        <stop offset="0.4949" style={{stopColor:'#C1A1D5'}} />
                        <stop offset="0.5067" style={{stopColor:'#C4A5D7'}} />
                        <stop offset="0.5196" style={{stopColor:'#CDB3DD'}} />
                        <stop offset="0.5329" style={{stopColor:'#DBC8E7'}} />
                        <stop offset="0.5466" style={{stopColor:'#EFE7F4'}} />
                        <stop offset="0.5556" style={{stopColor:'#FFFFFF'}} />
                        <stop offset="0.5678" style={{stopColor:'#ECE0F3'}} />
                        <stop offset="0.5801" style={{stopColor:'#DDC8EA'}} />
                        <stop offset="0.5915" style={{stopColor:'#D4B9E5'}} />
                        <stop offset="0.601" style={{stopColor:'#D1B4E3'}} />
                        <stop offset="0.658" style={{stopColor:'#B395D6'}} />
                        <stop offset="0.7155" style={{stopColor:'#9C7DCB'}} />
                        <stop offset="0.7687" style={{stopColor:'#8E6EC5'}} />
                        <stop offset="0.8131" style={{stopColor:'#8969C3'}} />
                        <stop offset="1" style={{stopColor:'#B086CC'}} />
                      </linearGradient>
                      <path className="st16" d="M1726.94,1597.43l-557.6-321.93c-9.81-5.67-14.7-13.22-14.86-21.14c-0.01-0.25-0.01-27.37,0-27.62
                        c0.18-9.64,7.3,7.1,21.03-0.83l316.45-182.7c25.43-14.68,63.89-16.27,85.91-3.56l557.6,321.93c7.35,4.24,12.68-13.77,14.59-8.04
                        c0.64,1.91,0.23,27.18,0.27,29.16c0.21,9.87-6.92,20.34-21.03,28.48l-316.45,182.7
                        C1787.42,1608.55,1748.96,1610.14,1726.94,1597.43z"/>
                      <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="1154.4749" y1="1291.4237" x2="2150.3237" y2="1291.4237">
                        <stop offset="0" style={{stopColor:'#2F054A'}} />
                        <stop offset="1" style={{stopColor:'#5824B0'}} />
                      </linearGradient>
                      <path className="st17" d="M1726.94,1570.32l-557.6-321.93c-22.02-12.71-19.26-34.92,6.17-49.6l316.45-182.7
                        c25.43-14.68,63.89-16.27,85.91-3.56l557.6,321.93c22.02,12.71,19.26,34.92-6.17,49.6l-316.45,182.7
                        C1787.42,1581.44,1748.96,1583.03,1726.94,1570.32z"/>
                      <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="1165.6123" y1="1291.4244" x2="2139.1855" y2="1291.4244">
                        <stop offset="0" style={{stopColor:'#756AFF'}} />
                        <stop offset="1" style={{stopColor:'#5F27BD'}} />
                      </linearGradient>
                      <path className="st18" d="M1764.25,1567.77c-12.55,0-23.82-2.52-31.74-7.09l-557.6-321.93c-3.47-2-9.29-6.22-9.29-11.9
                        c0-5.94,5.78-12.82,15.46-18.41l316.45-182.71c11.72-6.77,27.41-10.65,43.03-10.65c12.55,0,23.82,2.52,31.74,7.09l557.6,321.93
                        c3.47,2,9.29,6.22,9.29,11.9c0,5.94-5.78,12.82-15.46,18.42l-316.45,182.7C1795.55,1563.88,1779.87,1567.77,1764.25,1567.77z"/>
                      <path className="st19" d="M2129.29,1379.45l-316.45,182.7c-25.43,14.68-63.89,16.27-85.91,3.56l-557.6-321.93
                        c-9.13-5.27-13.94-12.19-14.7-19.51c-1.14,9,3.66,17.75,14.7,24.12l557.6,321.93c22.02,12.71,60.48,11.12,85.91-3.56l316.45-182.7
                        c14.88-8.59,21.93-19.75,20.86-30.08C2149.02,1362.93,2141.98,1372.13,2129.29,1379.45z"/>
                      <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="1946.283" y1="1448.6522" x2="1492.3813" y2="1105.5247" gradientTransform="matrix(0.866 0.5 -1.5 0.2887 2136.1921 16.7821)">
                        <stop offset="0" style={{stopColor:'#3E1682'}} />
                        <stop offset="1" style={{stopColor:'#4B1C9A'}} />
                      </linearGradient>
                      <path className="st20" d="M1826.03,1451.73l-452.8-261.42c-9.07-5.23-7.93-14.38,2.54-20.42l170-98.15
                        c10.47-6.04,26.31-6.7,35.37-1.47l452.8,261.42c9.07,5.23,7.93,14.38-2.54,20.42l-170,98.15
                        C1850.93,1456.31,1835.09,1456.97,1826.03,1451.73z"/>
                      <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="1577.4227" y1="1062.5933" x2="1831.7026" y2="1461.6451">
                        <stop offset="0.0556" style={{stopColor:'#A000E9'}}/>
                        <stop offset="0.2832" style={{stopColor:'#8900BD'}}/>
                        <stop offset="0.3953" style={{stopColor:'#8300B5'}}/>
                        <stop offset="0.6748" style={{stopColor:'#8200BD'}}/>
                        <stop offset="0.7657" style={{stopColor:'#9300D5'}}/>
                        <stop offset="0.8494" style={{stopColor:'#9D00E4'}}/>
                        <stop offset="0.9192" style={{stopColor:'#A000E9'}}/>
                      </linearGradient>
                      <path className="st21" d="M1555.68,1090.84l29.02,16.75c3.69,2.13,9.65,2.14,13.32,0.02c3.67-2.12,3.65-5.56-0.04-7.69l-29.02-16.75
                        c-3.69-2.13-9.65-2.14-13.32-0.02C1551.97,1085.27,1551.99,1088.71,1555.68,1090.84z M1605.36,1119.52l13.74,7.94
                        c3.69,2.13,9.65,2.14,13.32,0.02c3.67-2.12,3.65-5.56-0.04-7.69l-13.74-7.94c-3.69-2.13-9.65-2.14-13.32-0.02
                        S1601.67,1117.39,1605.36,1119.52z M1639.77,1139.39l13.74,7.94c3.69,2.13,9.65,2.14,13.32,0.02c3.67-2.12,3.65-5.56-0.04-7.69
                        l-13.74-7.94c-3.69-2.13-9.65-2.14-13.32-0.02C1636.06,1133.82,1636.08,1137.26,1639.77,1139.39z M1674.18,1159.25l13.74,7.94
                        c3.69,2.13,9.65,2.14,13.32,0.02c3.67-2.12,3.65-5.56-0.04-7.69l-13.74-7.94c-3.69-2.13-9.65-2.14-13.32-0.02
                        C1670.47,1153.68,1670.49,1157.12,1674.18,1159.25z M1708.59,1179.12l13.74,7.94c3.69,2.13,9.65,2.14,13.32,0.02
                        c3.67-2.12,3.65-5.56-0.04-7.69l-13.74-7.94c-3.69-2.13-9.65-2.14-13.32-0.02C1704.88,1173.55,1704.9,1176.99,1708.59,1179.12z
                        M1743,1198.99l13.74,7.94c3.69,2.13,9.65,2.14,13.32,0.02c3.67-2.12,3.65-5.56-0.04-7.69l-13.74-7.94
                        c-3.69-2.13-9.65-2.14-13.32-0.02C1739.29,1193.41,1739.31,1196.86,1743,1198.99z M1777.4,1218.85l13.74,7.94
                        c3.69,2.13,9.65,2.14,13.32,0.02c3.67-2.12,3.65-5.56-0.04-7.69l-13.74-7.94c-3.69-2.13-9.65-2.14-13.32-0.02
                        C1773.7,1213.28,1773.71,1216.72,1777.4,1218.85z M1811.81,1238.72l13.74,7.94c3.69,2.13,9.65,2.14,13.32,0.02
                        c3.67-2.12,3.65-5.56-0.04-7.69l-13.74-7.94c-3.69-2.13-9.65-2.14-13.32-0.02C1808.1,1233.14,1808.12,1236.59,1811.81,1238.72z
                        M1846.22,1258.58l13.74,7.94c3.69,2.13,9.65,2.14,13.32,0.02c3.67-2.12,3.65-5.56-0.04-7.69l-13.74-7.93
                        c-3.69-2.13-9.65-2.14-13.32-0.02C1842.51,1253.01,1842.53,1256.45,1846.22,1258.58z M1880.63,1278.45l13.74,7.93
                        c3.69,2.13,9.65,2.14,13.32,0.02c3.67-2.12,3.65-5.56-0.04-7.69l-13.74-7.94c-3.69-2.13-9.65-2.14-13.32-0.02
                        C1876.92,1272.87,1876.94,1276.32,1880.63,1278.45z M1915.04,1298.31l13.74,7.94c3.69,2.13,9.65,2.14,13.32,0.02
                        c3.67-2.12,3.65-5.56-0.04-7.69l-13.74-7.93c-3.69-2.13-9.65-2.14-13.32-0.02C1911.33,1292.74,1911.35,1296.18,1915.04,1298.31z
                        M1949.45,1318.18l13.74,7.93c3.69,2.13,9.65,2.14,13.32,0.02c3.67-2.12,3.65-5.56-0.04-7.69l-13.74-7.94
                        c-3.69-2.13-9.65-2.14-13.32-0.02C1945.74,1312.61,1945.76,1316.05,1949.45,1318.18z M1984.57,1338.46l13.74,7.94
                        c3.69,2.13,9.65,2.14,13.32,0.02c3.67-2.12,3.65-5.56-0.04-7.69l-13.74-7.94c-3.69-2.13-9.65-2.14-13.32-0.02
                        C1980.86,1332.88,1980.88,1336.33,1984.57,1338.46z M1524.74,1109.77l11.9,6.87c4.2,2.42,10.99,2.44,15.16,0.03l11.82-6.83
                        c4.17-2.41,4.15-6.33-0.05-8.75l-11.9-6.87c-4.2-2.42-10.99-2.44-15.16-0.03l-11.82,6.83
                        C1520.52,1103.43,1520.54,1107.35,1524.74,1109.77z M1586.2,1136.54l11.82-6.83c4.17-2.41,4.15-6.33-0.05-8.75l-11.9-6.87
                        c-4.2-2.42-10.99-2.44-15.16-0.03l-11.82,6.83c-4.17,2.41-4.15,6.33,0.05,8.75l11.9,6.87
                        C1575.24,1138.93,1582.03,1138.95,1586.2,1136.54z M1593.55,1149.5l11.9,6.87c4.2,2.42,10.99,2.44,15.16,0.03l11.82-6.83
                        c4.17-2.41,4.15-6.33-0.05-8.75l-11.9-6.87c-4.2-2.42-10.99-2.44-15.16-0.03l-11.82,6.83
                        C1589.33,1143.16,1589.35,1147.08,1593.55,1149.5z M1627.96,1169.37l11.9,6.87c4.2,2.42,10.99,2.44,15.16,0.03l11.82-6.83
                        c4.17-2.41,4.15-6.33-0.05-8.75l-11.9-6.87c-4.2-2.42-10.99-2.44-15.16-0.03l-11.82,6.83
                        C1623.74,1163.03,1623.76,1166.95,1627.96,1169.37z M1701.21,1180.55l-11.9-6.87c-4.2-2.42-10.99-2.44-15.16-0.03l-11.82,6.83
                        c-4.17,2.41-4.15,6.33,0.05,8.75l11.9,6.87c4.2,2.42,10.99,2.44,15.16,0.03l11.82-6.83
                        C1705.43,1186.9,1705.41,1182.98,1701.21,1180.55z M1735.61,1200.42l-11.9-6.87c-4.2-2.42-10.99-2.44-15.16-0.03l-11.82,6.83
                        c-4.17,2.41-4.15,6.33,0.05,8.75l11.9,6.87c4.2,2.42,10.99,2.44,15.16,0.03l11.82-6.83
                        C1739.84,1206.76,1739.81,1202.84,1735.61,1200.42z M1770.02,1220.28l-11.9-6.87c-4.2-2.42-10.99-2.44-15.16-0.03l-11.82,6.83
                        c-4.17,2.41-4.15,6.33,0.05,8.75l11.9,6.87c4.2,2.42,10.99,2.44,15.16,0.03l11.82-6.83
                        C1774.24,1226.63,1774.22,1222.71,1770.02,1220.28z M1804.43,1240.15l-11.9-6.87c-4.2-2.42-10.99-2.44-15.16-0.03l-11.82,6.83
                        c-4.17,2.41-4.15,6.33,0.05,8.75l11.9,6.87c4.2,2.42,10.99,2.44,15.16,0.03l11.82-6.83
                        C1808.65,1246.49,1808.63,1242.57,1804.43,1240.15z M1838.84,1260.02l-11.9-6.87c-4.2-2.42-10.99-2.44-15.16-0.03l-11.82,6.83
                        c-4.17,2.41-4.15,6.33,0.05,8.75l11.9,6.87c4.2,2.42,10.99,2.44,15.16,0.03l11.82-6.83
                        C1843.06,1266.36,1843.04,1262.44,1838.84,1260.02z M1873.25,1279.88l-11.9-6.87c-4.2-2.43-10.99-2.44-15.16-0.03l-11.82,6.83
                        c-4.17,2.41-4.15,6.33,0.05,8.75l11.9,6.87c4.2,2.42,10.99,2.44,15.16,0.03l11.82-6.83
                        C1877.47,1286.22,1877.45,1282.31,1873.25,1279.88z M1868.82,1308.43l11.9,6.87c4.2,2.42,10.99,2.44,15.16,0.03l11.82-6.83
                        c4.17-2.41,4.15-6.33-0.05-8.75l-11.9-6.87c-4.2-2.42-10.99-2.44-15.16-0.03l-11.82,6.83
                        C1864.6,1302.09,1864.62,1306,1868.82,1308.43z M1942.06,1319.61l-11.9-6.87c-4.2-2.42-10.99-2.44-15.16-0.03l-11.82,6.83
                        c-4.17,2.41-4.15,6.33,0.05,8.75l11.9,6.87c4.2,2.42,10.99,2.44,15.16,0.03l11.82-6.83
                        C1946.29,1325.96,1946.26,1322.04,1942.06,1319.61z M1992.46,1348.71l-27.17-15.69c-4.2-2.42-10.99-2.44-15.16-0.03l-11.82,6.83
                        c-4.17,2.41-4.15,6.33,0.05,8.75l27.17,15.69c4.2,2.42,10.99,2.44,15.16,0.03l11.82-6.83
                        C1996.68,1355.05,1996.66,1351.13,1992.46,1348.71z M1492.87,1128.17l27.17,15.69c4.2,2.42,10.99,2.44,15.16,0.03l11.82-6.83
                        c4.17-2.41,4.15-6.33-0.05-8.75l-27.17-15.69c-4.2-2.42-10.99-2.44-15.16-0.03l-11.82,6.83
                        C1488.65,1121.83,1488.67,1125.75,1492.87,1128.17z M1542.55,1156.86l11.9,6.87c4.2,2.42,10.99,2.44,15.16,0.03l11.82-6.83
                        c4.17-2.41,4.15-6.33-0.05-8.75l-11.9-6.87c-4.2-2.42-10.99-2.44-15.16-0.03l-11.82,6.83
                        C1538.33,1150.51,1538.35,1154.43,1542.55,1156.86z M1588.85,1183.59c4.2,2.42,10.99,2.44,15.16,0.03l11.82-6.83
                        c4.17-2.41,4.15-6.33-0.05-8.75l-11.9-6.87c-4.2-2.42-10.99-2.44-15.16-0.03l-11.82,6.83c-4.17,2.41-4.15,6.33,0.05,8.75
                        L1588.85,1183.59z M1623.26,1203.46c4.2,2.42,10.99,2.44,15.16,0.03l11.82-6.83c4.17-2.41,4.15-6.33-0.05-8.75l-11.9-6.87
                        c-4.2-2.42-10.99-2.44-15.16-0.03l-11.82,6.83c-4.17,2.41-4.15,6.33,0.05,8.75L1623.26,1203.46z M1657.55,1200.87l-11.82,6.83
                        c-4.17,2.41-4.15,6.33,0.05,8.75l11.9,6.87c4.2,2.42,10.99,2.44,15.16,0.03l11.82-6.83c4.17-2.41,4.15-6.33-0.05-8.75l-11.9-6.87
                        C1668.51,1198.48,1661.72,1198.46,1657.55,1200.87z M1692.68,1220.32l-13.28,7.67c-3.77,2.18-3.75,5.72,0.04,7.91l13.36,7.71
                        c3.8,2.19,9.93,2.2,13.7,0.02l13.28-7.67c3.77-2.18,3.75-5.72-0.04-7.91l-13.36-7.71
                        C1702.59,1218.15,1696.46,1218.14,1692.68,1220.32z M1727.09,1240.18l-13.28,7.67c-3.77,2.18-3.75,5.72,0.04,7.91l13.36,7.71
                        c3.8,2.19,9.93,2.2,13.7,0.02l13.28-7.67c3.77-2.18,3.75-5.72-0.04-7.91l-13.36-7.71
                        C1737,1238.02,1730.86,1238.01,1727.09,1240.18z M1761.5,1260.05l-13.28,7.67c-3.77,2.18-3.75,5.72,0.04,7.91l13.36,7.71
                        c3.8,2.19,9.93,2.2,13.7,0.02l13.28-7.67c3.77-2.18,3.75-5.72-0.04-7.91l-13.36-7.71
                        C1771.41,1257.88,1765.27,1257.87,1761.5,1260.05z M1795.91,1279.92l-13.28,7.67c-3.77,2.18-3.75,5.72,0.04,7.91l13.36,7.71
                        c3.8,2.19,9.93,2.2,13.7,0.02l13.28-7.67c3.77-2.18,3.75-5.72-0.04-7.91l-13.36-7.71
                        C1805.81,1277.75,1799.68,1277.74,1795.91,1279.92z M1830.32,1299.78l-13.28,7.67c-3.77,2.18-3.75,5.72,0.04,7.91l13.36,7.71
                        c3.8,2.19,9.93,2.2,13.7,0.02l13.28-7.67c3.77-2.18,3.75-5.72-0.04-7.91l-13.36-7.71
                        C1840.22,1297.61,1834.09,1297.6,1830.32,1299.78z M1878.55,1342.96l13.28-7.67c3.77-2.18,3.75-5.72-0.04-7.91l-13.36-7.71
                        c-3.8-2.19-9.93-2.2-13.7-0.03l-13.28,7.67c-3.77,2.18-3.75,5.72,0.04,7.91l13.36,7.71
                        C1868.65,1345.13,1874.78,1345.14,1878.55,1342.96z M1912.96,1362.83l13.28-7.67c3.77-2.18,3.75-5.72-0.04-7.91l-13.36-7.71
                        c-3.8-2.19-9.93-2.2-13.7-0.02l-13.28,7.67c-3.77,2.18-3.75,5.72,0.04,7.91l13.36,7.71
                        C1903.06,1365,1909.19,1365.01,1912.96,1362.83z M1961.32,1367.53l-13.36-7.71c-3.8-2.19-9.93-2.2-13.7-0.02l-13.28,7.67
                        c-3.77,2.18-3.75,5.72,0.04,7.91l13.36,7.71c3.8,2.19,9.93,2.2,13.7,0.02l13.28-7.67
                        C1965.14,1373.26,1965.12,1369.72,1961.32,1367.53z M1460.27,1146.15l43.14,24.91c3.8,2.19,9.93,2.2,13.7,0.02l13.28-7.67
                        c3.77-2.18,3.75-5.72-0.04-7.91l-43.14-24.91c-3.8-2.19-9.93-2.2-13.7-0.03l-13.28,7.67
                        C1456.45,1140.42,1456.47,1143.96,1460.27,1146.15z M1524.45,1183.21l13.36,7.71c3.8,2.19,9.93,2.2,13.7,0.02l13.28-7.67
                        c3.77-2.18,3.75-5.72-0.04-7.91l-13.36-7.71c-3.8-2.19-9.93-2.2-13.7-0.03l-13.28,7.67
                        C1520.64,1177.48,1520.66,1181.02,1524.45,1183.21z M1558.86,1203.08l13.36,7.71c3.8,2.19,9.93,2.2,13.7,0.03l13.28-7.67
                        c3.77-2.18,3.75-5.72-0.04-7.91l-13.36-7.71c-3.8-2.19-9.93-2.2-13.7-0.02l-13.28,7.67
                        C1555.05,1197.34,1555.07,1200.88,1558.86,1203.08z M1593.27,1222.94l13.36,7.71c3.8,2.19,9.93,2.2,13.7,0.02l13.28-7.67
                        c3.77-2.18,3.75-5.72-0.04-7.91l-13.36-7.71c-3.8-2.19-9.93-2.2-13.7-0.03l-13.28,7.67
                        C1589.45,1217.21,1589.47,1220.75,1593.27,1222.94z M1654.74,1250.55l13.28-7.67c3.77-2.18,3.75-5.72-0.04-7.91l-13.36-7.71
                        c-3.8-2.19-9.93-2.2-13.7-0.02l-13.28,7.67c-3.77,2.18-3.75,5.72,0.04,7.91l13.36,7.71
                        C1644.84,1252.71,1650.97,1252.72,1654.74,1250.55z M1689.15,1270.41l13.28-7.67c3.77-2.18,3.75-5.72-0.04-7.91l-13.36-7.71
                        c-3.8-2.19-9.93-2.2-13.7-0.02l-13.28,7.67c-3.77,2.18-3.75,5.72,0.04,7.91l13.36,7.71
                        C1679.24,1272.58,1685.38,1272.59,1689.15,1270.41z M1723.56,1290.28l13.28-7.67c3.77-2.18,3.75-5.72-0.04-7.91l-13.36-7.71
                        c-3.8-2.19-9.93-2.2-13.7-0.02l-13.28,7.67c-3.77,2.18-3.75,5.72,0.04,7.91l13.36,7.71
                        C1713.65,1292.44,1719.79,1292.45,1723.56,1290.28z M1757.97,1310.14l13.28-7.67c3.77-2.18,3.75-5.72-0.04-7.91l-13.36-7.71
                        c-3.8-2.19-9.93-2.2-13.7-0.02l-13.28,7.67c-3.77,2.18-3.75,5.72,0.04,7.91l13.36,7.71
                        C1748.06,1312.31,1754.19,1312.32,1757.97,1310.14z M1792.38,1330.01l13.28-7.67c3.77-2.18,3.75-5.72-0.04-7.91l-13.36-7.71
                        c-3.8-2.19-9.93-2.2-13.7-0.02l-13.28,7.67c-3.77,2.18-3.75,5.72,0.04,7.91l13.36,7.71
                        C1782.47,1332.17,1788.6,1332.19,1792.38,1330.01z M1826.78,1349.87l13.28-7.67c3.77-2.18,3.75-5.72-0.04-7.91l-13.36-7.71
                        c-3.8-2.19-9.93-2.2-13.7-0.02l-13.28,7.67c-3.77,2.18-3.75,5.72,0.04,7.91l13.36,7.71
                        C1816.88,1352.04,1823.01,1352.05,1826.78,1349.87z M1834.13,1362l13.36,7.71c3.8,2.19,9.93,2.2,13.7,0.02l13.28-7.67
                        c3.77-2.18,3.75-5.72-0.04-7.91l-13.36-7.71c-3.8-2.19-9.93-2.2-13.7-0.03l-13.28,7.67
                        C1830.31,1356.27,1830.33,1359.81,1834.13,1362z M1929.45,1385.93l-33.98-19.62c-3.8-2.19-9.93-2.2-13.7-0.02l-13.28,7.67
                        c-3.77,2.18-3.75,5.72,0.04,7.91l33.98,19.62c3.8,2.19,9.93,2.2,13.7,0.02l13.28-7.67
                        C1933.27,1391.66,1933.25,1388.12,1929.45,1385.93z M1428.53,1165.5l61.21,35.34c4.29,2.47,11.22,2.49,15.48,0.03l11.51-6.64
                        c4.26-2.46,4.24-6.46-0.05-8.94l-61.21-35.34c-4.29-2.48-11.22-2.49-15.48-0.03l-11.51,6.64
                        C1424.22,1159.03,1424.24,1163.03,1428.53,1165.5z M1512.57,1214.02l11.58,6.69c4.29,2.48,11.22,2.49,15.48,0.03l11.51-6.64
                        c4.26-2.46,4.24-6.46-0.05-8.94l-11.58-6.69c-4.29-2.48-11.22-2.49-15.48-0.03l-11.51,6.64
                        C1508.26,1207.55,1508.28,1211.55,1512.57,1214.02z M1546.98,1233.89l11.58,6.69c4.29,2.47,11.22,2.49,15.48,0.03l11.51-6.64
                        c4.26-2.46,4.24-6.46-0.05-8.94l-11.58-6.69c-4.29-2.48-11.22-2.49-15.48-0.03l-11.51,6.64
                        C1542.67,1227.41,1542.69,1231.41,1546.98,1233.89z M1581.38,1253.75l11.58,6.69c4.29,2.48,11.22,2.49,15.48,0.03l11.51-6.64
                        c4.26-2.46,4.24-6.46-0.05-8.94l-11.58-6.69c-4.29-2.48-11.22-2.49-15.48-0.03l-11.51,6.64
                        C1577.08,1247.28,1577.1,1251.28,1581.38,1253.75z M1615.79,1273.62l11.58,6.69c4.29,2.48,11.22,2.49,15.48,0.03l11.51-6.64
                        c4.26-2.46,4.24-6.46-0.05-8.94l-11.58-6.69c-4.29-2.48-11.22-2.49-15.48-0.03l-11.51,6.64
                        C1611.48,1267.14,1611.51,1271.15,1615.79,1273.62z M1650.2,1293.49l11.58,6.69c4.29,2.48,11.22,2.49,15.48,0.03l11.51-6.64
                        c4.26-2.46,4.24-6.46-0.05-8.94l-11.58-6.69c-4.29-2.48-11.22-2.49-15.48-0.03l-11.51,6.64
                        C1645.89,1287.01,1645.91,1291.01,1650.2,1293.49z M1684.61,1313.35l11.58,6.69c4.29,2.48,11.22,2.49,15.48,0.03l11.51-6.64
                        c4.26-2.46,4.24-6.46-0.05-8.94l-11.58-6.69c-4.29-2.48-11.22-2.49-15.48-0.03l-11.51,6.64
                        C1680.3,1306.88,1680.32,1310.88,1684.61,1313.35z M1719.02,1333.22l11.58,6.69c4.29,2.48,11.22,2.49,15.48,0.03l11.51-6.64
                        c4.26-2.46,4.24-6.46-0.05-8.94l-11.58-6.69c-4.29-2.48-11.22-2.49-15.48-0.03l-11.51,6.64
                        C1714.71,1326.74,1714.73,1330.74,1719.02,1333.22z M1753.43,1353.08l11.58,6.69c4.29,2.48,11.22,2.49,15.48,0.03l11.51-6.64
                        c4.26-2.46,4.24-6.46-0.05-8.94l-11.58-6.69c-4.29-2.48-11.22-2.49-15.48-0.03l-11.51,6.64
                        C1749.12,1346.61,1749.14,1350.61,1753.43,1353.08z M1787.83,1372.95l11.58,6.69c4.29,2.48,11.22,2.49,15.48,0.03l11.51-6.64
                        c4.26-2.46,4.24-6.46-0.05-8.94l-11.58-6.69c-4.29-2.48-11.22-2.49-15.48-0.03l-11.51,6.64
                        C1783.53,1366.47,1783.55,1370.47,1787.83,1372.95z M1822.24,1392.81l11.58,6.69c4.29,2.48,11.22,2.49,15.48,0.03l11.51-6.64
                        c4.26-2.46,4.24-6.46-0.05-8.94l-11.58-6.69c-4.29-2.48-11.22-2.49-15.48-0.03l-11.51,6.64
                        C1817.93,1386.34,1817.96,1390.34,1822.24,1392.81z M1895.93,1404.25l-11.58-6.69c-4.29-2.48-11.22-2.49-15.48-0.03l-11.51,6.64
                        c-4.26,2.46-4.24,6.46,0.05,8.94l11.58,6.69c4.29,2.48,11.22,2.49,15.48,0.03l11.51-6.64
                        C1900.24,1410.73,1900.22,1406.73,1895.93,1404.25z M1450.45,1183.85l-26.85-15.5c-4.29-2.48-11.22-2.49-15.48-0.03l-11.51,6.64
                        c-4.26,2.46-4.24,6.46,0.05,8.94l26.85,15.5c4.29,2.48,11.22,2.49,15.48,0.03l11.51-6.64
                        C1454.76,1190.33,1454.74,1186.33,1450.45,1183.85z M1484.86,1203.72l-11.58-6.69c-4.29-2.48-11.22-2.49-15.48-0.03l-11.51,6.64
                        c-4.26,2.46-4.24,6.46,0.05,8.94l11.58,6.69c4.29,2.47,11.22,2.49,15.48,0.03l11.51-6.64
                        C1489.17,1210.2,1489.14,1206.19,1484.86,1203.72z M1519.26,1223.58l-11.58-6.69c-4.29-2.48-11.22-2.49-15.48-0.03l-11.51,6.64
                        c-4.26,2.46-4.24,6.46,0.05,8.94l11.58,6.69c4.29,2.48,11.22,2.49,15.48,0.03l11.51-6.64
                        C1523.57,1230.06,1523.55,1226.06,1519.26,1223.58z M1760.12,1362.64l-218.03-125.88c-4.29-2.48-11.22-2.49-15.48-0.03
                        l-11.51,6.64c-4.26,2.46-4.24,6.46,0.05,8.94l218.03,125.88c4.29,2.48,11.22,2.49,15.48,0.03l11.51-6.64
                        C1764.43,1369.12,1764.41,1365.12,1760.12,1362.64z M1794.53,1382.51l-11.58-6.69c-4.29-2.48-11.22-2.49-15.48-0.03l-11.51,6.64
                        c-4.26,2.46-4.24,6.46,0.05,8.94l11.58,6.69c4.29,2.48,11.22,2.49,15.48,0.03l11.51-6.64
                        C1798.84,1388.99,1798.82,1384.99,1794.53,1382.51z M1828.94,1402.38l-11.58-6.69c-4.29-2.48-11.22-2.49-15.48-0.03l-11.51,6.64
                        c-4.26,2.46-4.24,6.46,0.05,8.94l11.58,6.69c4.29,2.48,11.22,2.49,15.48,0.03l11.51-6.64
                        C1833.25,1408.85,1833.23,1404.85,1828.94,1402.38z M1864.06,1422.65l-11.58-6.69c-4.29-2.48-11.22-2.49-15.48-0.03l-11.51,6.64
                        c-4.26,2.46-4.24,6.46,0.05,8.94l11.58,6.69c4.29,2.48,11.22,2.49,15.48,0.03l11.51-6.64
                        C1868.37,1429.13,1868.35,1425.13,1864.06,1422.65z"/>
                      <g id="screen">         
                        <linearGradient id="monitor-border-1" gradientUnits="userSpaceOnUse" x1="1550.7139" y1="914.0552" x2="2163.2842" y2="914.0552">
                          <stop offset="0" style={{stopColor:'#2F054A'}} />
                          <stop offset="1" style={{stopColor:'#5824B0'}} />
                        </linearGradient>
                        <path id="monitor-border" className="st22" d="M2125.62,1330.18l-537.25-310.36c-20.8-12.01-37.66-44.23-37.66-71.96
                          V526.4c0-27.73,16.86-40.48,37.66-28.47l537.25,310.36c20.8,12.01,37.66,44.23,37.66,71.96v421.46
                          C2163.28,1329.44,2146.42,1342.19,2125.62,1330.18z"/>
                        <linearGradient id="screen-background-1" gradientUnits="userSpaceOnUse" x1="3087.9729" y1="1293.5938" x2="2593.6387" y2="527.2307" gradientTransform="matrix(1 0 0 1 -985.5782 0)">
                          <stop offset="0.0303" style={{stopColor:'#C4AFE0'}} />
                          <stop offset="0.5565" style={{stopColor:'#D38BF0', stopOpacity:0.7829}} />
                          <stop offset="1" style={{stopColor:'#E16AFF', stopOpacity:0.6}} />
                        </linearGradient>
                        <path id="screen-background" className="st23" d="M2130.47,1308.52l-531.52-307.02
                          c-16.74-10.64-27.09-31.89-27.09-53.64v-423.5c0-5.15,5.61-8.35,10.05-5.75c0.52,0.3,0.93,0.54,1.18,0.69l531.94,307.31
                          c19.93,11.49,27.09,31.9,27.09,53.65v422.52c0,5.53-5.98,8.99-10.78,6.25C2130.97,1308.81,2130.67,1308.63,2130.47,1308.52z"/>
                        <path className="st24" d="M2115.04,826.6L1583.1,519.29c-0.25-0.15-0.66-0.38-1.18-0.69c-4.45-2.6-10.05,0.6-10.05,5.75v423.5
                          c0,21.75,10.35,43.01,27.09,53.64l531.52,307.02c0.2,0.11,0.5,0.29,0.88,0.5c4.8,2.74,10.78-0.73,10.78-6.25V880.25
                          C2142.13,858.5,2134.97,838.09,2115.04,826.6z M2137.9,1302.77c0,1.84-1.55,2.98-2.99,2.98c-0.5,0-1-0.13-1.46-0.4
                          l-532.37-307.51c-15.11-9.61-24.98-29.26-24.98-49.98v-423.5c0-1.51,1.28-2.45,2.46-2.45c0.42,0,0.83,0.12,1.23,0.35
                          l533.14,308.01c20.65,11.9,24.98,33.75,24.98,49.98V1302.77z"/>
                        <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="3281.1675" y1="1353.4662" x2="2771.9055" y2="539.7347" gradientTransform="matrix(1 0 0 1 -985.5782 0)">
                          <stop offset="0.0556" style={{stopColor:'#9F79B8'}} />
                          <stop offset="0.2575" style={{stopColor:'#A17BBA'}} />
                          <stop offset="0.3569" style={{stopColor:'#A783BF'}} />
                          <stop offset="0.4338" style={{stopColor:'#B290C9'}} />
                          <stop offset="0.4949" style={{stopColor:'#C1A1D5'}} />
                          <stop offset="0.5067" style={{stopColor:'#C4A5D7'}} />
                          <stop offset="0.5196" style={{stopColor:'#CDB3DD'}} />
                          <stop offset="0.5329" style={{stopColor:'#DBC8E7'}} />
                          <stop offset="0.5466" style={{stopColor:'#EFE7F4'}} />
                          <stop offset="0.5556" style={{stopColor:'#FFFFFF'}} />
                          <stop offset="0.5678" style={{stopColor:'#ECE0F3'}} />
                          <stop offset="0.5801" style={{stopColor:'#DDC8EA'}} />
                          <stop offset="0.5915" style={{stopColor:'#D4B9E5'}} />
                          <stop offset="0.601" style={{stopColor:'#D1B4E3'}} />
                          <stop offset="0.6124" style={{stopColor:'#CFB2E2'}} />
                          <stop offset="0.7562" style={{stopColor:'#BE9AD6'}} />
                          <stop offset="0.8892" style={{stopColor:'#B48BCF'}} />
                          <stop offset="1" style={{stopColor:'#B086CC'}} />
                        </linearGradient>
                        <path className="st25" d="M2136.3,802.62l-537.25-310.36c-8.23-4.75-15.84-5.63-22.04-3.23c-0.79,0.3-11.29,5.88-12.03,6.29
                          c-0.02,0.01-0.04,0.02-0.04,0.02c6.42-3.12,14.56-2.53,23.43,2.59l537.25,310.36c20.8,12.01,37.66,44.23,37.66,71.96v421.46
                          c0,17.31-6.57,28.77-16.56,32.03c0.81,0.04,1.83-0.27,3.3-1.12c0.48-0.27,11.87-6.7,12.33-7.02c7.15-5.01,11.61-15.3,11.61-29.56
                          V874.58C2173.96,846.85,2157.1,814.63,2136.3,802.62z"/>
                        <radialGradient id="SVGID_7_" cx="3135.8569" cy="853.532" r="86.5484" gradientTransform="matrix(1 0 0 1 -985.5782 0)" gradientUnits="userSpaceOnUse">
                          <stop offset="0" style={{stopColor:'#FFFFFF'}} />
                          <stop offset="1" style={{stopColor:'#000000'}} />
                        </radialGradient>
                        <path className="st26" d="M2127.43,807.39l-537.25-310.36c-8.53-4.93-16.37-5.63-22.68-2.9c5.39-0.85,11.58,0.45,18.17,4.25
                          l537.25,310.36c20.8,12.01,37.66,44.23,37.66,71.96v421.46c0,16.36-5.89,27.45-14.98,31.37c11.62-1.84,19.5-13.78,19.5-32.73
                          V879.35C2165.09,851.61,2148.23,819.4,2127.43,807.39z"/>
                      </g>
                    </g>
                    <g id="multiple-choice">
                      <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="1857.0095" y1="1604.0295" x2="1847.088" y2="645.6074">
                        <stop offset="0" style={{stopColor:'#9F79C8'}} />
                        <stop offset="0.1367" style={{stopColor:'#A580CB'}} />
                        <stop offset="0.3288" style={{stopColor:'#B492D3'}} />
                        <stop offset="0.5529" style={{stopColor:'#CDB0E1'}} />
                        <stop offset="0.5808" style={{stopColor:'#D1B4E3'}} />
                        <stop offset="0.7644" style={{stopColor:'#DBC4E9'}} />
                        <stop offset="1" style={{stopColor:'#EBDEF3'}} />
                      </linearGradient>
                      <path className="st27" d="M1610.13,973.46V600.47c0-15.79,9.6-23.04,21.44-16.21l436.83,252.75c11.84,6.84,21.44,25.18,21.44,40.96
                        v372.99L1610.13,973.46z"/>
                      <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="3186.4778" y1="1271.0955" x2="2778.8184" y2="619.7111" gradientTransform="matrix(1 0 0 1 -985.5782 0)">
                        <stop offset="0.0556" style={{stopColor:'#9F79B8'}} />
                        <stop offset="0.2575" style={{stopColor:'#A17BBA'}} />
                        <stop offset="0.3569" style={{stopColor:'#A783BF'}} />
                        <stop offset="0.4338" style={{stopColor:'#B290C9'}} />
                        <stop offset="0.4949" style={{stopColor:'#C1A1D5'}} />
                        <stop offset="0.5067" style={{stopColor:'#C4A5D7'}} />
                        <stop offset="0.5196" style={{stopColor:'#CDB3DD'}} />
                        <stop offset="0.5329" style={{stopColor:'#DBC8E7'}} />
                        <stop offset="0.5466" style={{stopColor:'#EFE7F4'}} />
                        <stop offset="0.5556" style={{stopColor:'#FFFFFF'}} />
                        <stop offset="0.5678" style={{stopColor:'#ECE0F3'}} />
                        <stop offset="0.5801" style={{stopColor:'#DDC8EA'}} />
                        <stop offset="0.5915" style={{stopColor:'#D4B9E5'}} />
                        <stop offset="0.601" style={{stopColor:'#D1B4E3'}} />
                        <stop offset="0.6124" style={{stopColor:'#CFB2E2'}} />
                        <stop offset="0.7562" style={{stopColor:'#BE9AD6'}} />
                        <stop offset="0.8892" style={{stopColor:'#B48BCF'}} />
                        <stop offset="1" style={{stopColor:'#B086CC'}} />
                      </linearGradient>
                      <path className="st28" d="M2095.92,1247.74V874.75c0-15.79-9.6-34.13-21.44-40.96l-436.83-252.75c-4.68-2.7-9.02-3.2-12.55-1.84
                        c-0.45,0.17-6.43,3.35-6.85,3.58c-0.01,0.01-0.02,0.01-0.02,0.01c3.65-1.77,8.29-1.44,13.34,1.47l436.83,252.75
                        c11.84,6.84,21.44,25.18,21.44,40.96v372.99L2095.92,1247.74z"/>
                      <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="1832.599" y1="782.7955" x2="1871.8176" y2="716.8864">
                        <stop offset="0.096" style={{stopColor:'#45108A'}} />
                        <stop offset="1" style={{stopColor:'#7C1DC9'}} />
                      </linearGradient>
                      <path className="st29" d="M2095.92,900.53v-25.78c0-15.79-9.6-34.13-21.44-40.96l-436.83-252.75c-4.68-2.7-9.02-3.2-12.55-1.84
                        c-0.45,0.17-6.43,3.35-6.85,3.58c-0.01,0.01-0.02,0.01-0.02,0.01c3.65-1.77,8.29-1.44,13.34,1.47l436.83,252.75
                        c11.84,6.84,21.44,25.18,21.44,40.96v25.78L2095.92,900.53z"/>
                      <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="1728.3759" y1="893.4556" x2="1963.8091" y2="610.1376">
                        <stop offset="0.096" style={{stopColor:'#45108A'}} />
                        <stop offset="1" style={{stopColor:'#7C1DC9'}} />
                      </linearGradient>
                      <path className="st30" d="M2068.4,837.02l-436.83-252.75c-11.84-6.84-21.44,0.42-21.44,16.21v25.29l479.71,277.5v-25.29
                        C2089.84,862.19,2080.24,843.85,2068.4,837.02z"/>
                      <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="2014.7611" y1="1066.854" x2="2321.157" y2="1080.6338">
                        <stop offset="0" style={{stopColor:'#9F79C8'}} />
                        <stop offset="0.1367" style={{stopColor:'#A580CB'}} />
                        <stop offset="0.3288" style={{stopColor:'#B492D3'}} />
                        <stop offset="0.5529" style={{stopColor:'#CDB0E1'}} />
                        <stop offset="0.5808" style={{stopColor:'#D1B4E3'}} />
                        <stop offset="0.7644" style={{stopColor:'#DBC4E9'}} />
                        <stop offset="1" style={{stopColor:'#EBDEF3'}} />
                      </linearGradient>
                      <polygon className="st31" points="2069.83,895.38 2069.83,1234.61 2085.4,1243.98 2085.4,903.48"/>
                      <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="2128.9714" y1="1159.5863" x2="2068.9849" y2="1153.5876">
                        <stop offset="0" style={{stopColor:'#9F79C8'}} />
                        <stop offset="0.1367" style={{stopColor:'#A580CB'}} />
                        <stop offset="0.3288" style={{stopColor:'#B492D3'}} />
                        <stop offset="0.5529" style={{stopColor:'#CDB0E1'}} />
                        <stop offset="0.5808" style={{stopColor:'#D1B4E3'}} />
                        <stop offset="0.7644" style={{stopColor:'#DBC4E9'}} />
                        <stop offset="1" style={{stopColor:'#EBDEF3'}} />
                      </linearGradient>
                      <polygon className="st32" points="2072.12,1086.91 2072.12,1215.54 2083.11,1221.99 2083.11,1092.6"/>
                      <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="1695.0251" y1="661.9077" x2="1705.3568" y2="644.2478">
                        <stop offset="0.0354" style={{stopColor:'#9F19AB'}} />
                        <stop offset="0.2438" style={{stopColor:'#AE25B1'}} />
                        <stop offset="0.6455" style={{stopColor:'#D744BF'}} />
                        <stop offset="1" style={{stopColor:'#FF63CE'}} />
                      </linearGradient>
                      <path className="st33" d="M1710.01,657.36c0,5.84-4.13,8.19-9.21,5.25c-5.09-2.94-9.21-10.05-9.21-15.89c0-5.84,4.13-8.19,9.21-5.25
                        C1705.89,644.41,1710.01,651.52,1710.01,657.36z"/>
                      <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="1665.9622" y1="645.7086" x2="1676.2937" y2="628.0487">
                        <stop offset="0.0354" style={{stopColor:'#FFA98C'}} />
                        <stop offset="0.3169" style={{stopColor:'#FFB892'}} />
                        <stop offset="0.8601" style={{stopColor:'#FEE1A3'}} />
                        <stop offset="1" style={{stopColor:'#FEECA8'}} />
                      </linearGradient>
                      <path className="st34" d="M1680.95,641.16c0,5.84-4.13,8.19-9.21,5.25c-5.09-2.94-9.21-10.05-9.21-15.89c0-5.84,4.13-8.19,9.21-5.25
                        C1676.82,628.21,1680.95,635.32,1680.95,641.16z"/>
                      <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="1636.6609" y1="629.1124" x2="1646.9926" y2="611.4525">
                        <stop offset="0" style={{stopColor:'#9F79C8'}} />
                        <stop offset="0.1367" style={{stopColor:'#A580CB'}} />
                        <stop offset="0.3288" style={{stopColor:'#B492D3'}} />
                        <stop offset="0.5529" style={{stopColor:'#CDB0E1'}} />
                        <stop offset="0.5808" style={{stopColor:'#D1B4E3'}} />
                        <stop offset="0.7644" style={{stopColor:'#DBC4E9'}} />
                        <stop offset="1" style={{stopColor:'#EBDEF3'}} />
                      </linearGradient>
                      <path className="st35" d="M1651.65,624.57c0,5.84-4.13,8.19-9.21,5.25c-5.09-2.94-9.21-10.05-9.21-15.89c0-5.84,4.13-8.19,9.21-5.25
                        C1647.52,611.61,1651.65,618.73,1651.65,624.57z"/>
                      <linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="1658.3881" y1="786.3295" x2="1932.5718" y2="786.3295">
                        <stop offset="0.0101" style={{stopColor:'#9F19AB'}} />
                        <stop offset="0.4293" style={{stopColor:'#5F27BD'}} />
                        <stop offset="0.904" style={{stopColor:'#3D065F'}} />
                      </linearGradient>
                      <path className="st37" d="M1892.31,853.69l-45.46-26.11v9.21l45.46,26.11V853.69z M1898.9,866.71l33.67,19.36v-9.21l-33.67-19.36
                        V866.71z M1840.26,823.77l-22.65-13.08v9.21l22.65,13.08V823.77z M1811.03,806.9l-52.37-30.13v9.21l52.37,30.13V806.9z
                        M1806.84,773.56l-148.45-86.97v13.98l148.45,86.97V773.56z M1752.07,772.96l-54.52-31.46v9.21l54.52,31.46V772.96z
                        M1690.96,737.7l-32.57-19.26v9.21l32.57,19.26V737.7z"/>
                      <linearGradient id="SVGID_19_" gradientUnits="userSpaceOnUse" x1="1658.3881" y1="949.3427" x2="1951.9524" y2="949.3427">
                        <stop offset="0.0101" style={{stopColor:'#9F19AB'}} />
                        <stop offset="0.4293" style={{stopColor:'#5F27BD'}} />
                        <stop offset="0.904" style={{stopColor:'#3D065F'}} />
                      </linearGradient>
                      <path className="st38" d="M1941.06,1048.58l10.89,6.29v-9.21l-10.89-6.29V1048.58z M1908.37,1029.79l26.1,14.99v-9.21l-26.1-14.99
                        V1029.79z M1822.97,980.61l28,16.17v-9.21l-28-16.17V980.61z M1788.71,960.94l27.67,15.87v-9.21l-27.67-15.87V960.94z
                        M1857.56,1000.58l44.22,25.4v-9.21l-44.22-25.4V1000.58z M1704.55,912.37l77.58,44.77v-9.21l-77.58-44.77V912.37z
                        M1697.96,899.35l-39.57-23.3v9.21l39.57,23.31V899.35z M1806.84,930.79l-148.45-86.97v13.98l148.45,86.97V930.79z"/>
                      <g>
                        <path className="st39" d="M1717.7,985.06c-0.33,0-0.66-0.09-0.95-0.26l-32.31-18.65c-0.59-0.34-0.95-0.97-0.95-1.65v-37.07
                          c0-0.68,0.36-1.31,0.95-1.65c0.59-0.34,1.32-0.34,1.91,0l32.31,18.65c0.59,0.34,0.95,0.97,0.95,1.65v37.07
                          c0,0.68-0.36,1.31-0.95,1.65C1718.36,984.97,1718.03,985.06,1717.7,985.06z M1687.3,963.4l28.5,16.45v-32.67l-28.5-16.45V963.4z"
                          />
                      </g>
                      <g>
                        <linearGradient id="SVGID_20_" gradientUnits="userSpaceOnUse" x1="1635.5126" y1="915.1365" x2="2021.4287" y2="1177.1785">
                          <stop offset="0" style={{stopColor:'#25054D'}} />
                          <stop offset="1" style={{stopColor:'#45108A'}} />
                        </linearGradient>
                        <path className="st40" d="M1652.03,939.57c-0.13-0.22-0.19-0.44-0.19-0.66l0.06-0.29l7.65-19.16c0.21-0.49,0.59-0.57,1.15-0.25
                          l3.51,2.02c0.55,0.32,0.93,0.84,1.15,1.57l7.64,27.99l0.07,0.37c0,0.22-0.07,0.36-0.21,0.42c-0.14,0.07-0.29,0.05-0.46-0.04
                          l-2.84-1.64c-0.44-0.26-0.75-0.67-0.92-1.23l-1.46-5.27l-9.43-5.44l-1.47,3.58c-0.17,0.36-0.48,0.42-0.92,0.16l-2.83-1.64
                          C1652.31,939.95,1652.15,939.79,1652.03,939.57z M1658.84,934.37l7.2,4.15l-3.6-13.34L1658.84,934.37z"/>
                      </g>
                      <g>
                        <path className="st39" d="M1816.32,1042c-0.33,0-0.66-0.09-0.95-0.26l-32.31-18.65c-0.59-0.34-0.95-0.97-0.95-1.65v-37.07
                          c0-0.68,0.36-1.31,0.95-1.65c0.59-0.34,1.32-0.34,1.91,0l32.31,18.65c0.59,0.34,0.95,0.97,0.95,1.65v37.07
                          c0,0.68-0.36,1.31-0.95,1.65C1816.98,1041.91,1816.65,1042,1816.32,1042z M1785.92,1020.34l28.5,16.45v-32.67l-28.5-16.45
                          V1020.34z"/>
                      </g>
                      <g>
                        <linearGradient id="SVGID_21_" gradientUnits="userSpaceOnUse" x1="1640.2294" y1="908.1904" x2="2026.1476" y2="1170.2338">
                          <stop offset="0" style={{stopColor:'#25054D'}} />
                          <stop offset="1" style={{stopColor:'#45108A'}} />
                        </linearGradient>
                        <path className="st41" d="M1752.72,997.67c-0.15-0.26-0.22-0.51-0.22-0.75v-23.8c0-0.27,0.07-0.45,0.2-0.54
                          c0.14-0.09,0.32-0.07,0.56,0.07l9.24,5.33c2.49,1.43,4.34,3.13,5.58,5.1c1.23,1.97,1.85,4.04,1.85,6.21
                          c0,1.27-0.27,2.19-0.81,2.77c-0.54,0.58-1.18,0.85-1.89,0.83c0.89,0.97,1.65,2.17,2.26,3.57c0.62,1.41,0.93,2.78,0.93,4.12
                          c0,2.27-0.67,3.71-2.01,4.32c-1.34,0.62-3.2,0.23-5.61-1.15l-9.53-5.5C1753.04,998.11,1752.86,997.92,1752.72,997.67z
                          M1761.92,988.14c1.08,0.63,1.92,0.8,2.5,0.53c0.58-0.27,0.88-0.92,0.88-1.94c0-1.02-0.29-1.98-0.86-2.87
                          c-0.57-0.89-1.41-1.66-2.52-2.29l-4.94-2.85v6.58L1761.92,988.14z M1762.24,999.51c1.1,0.64,1.96,0.79,2.58,0.45
                          c0.61-0.34,0.92-1.05,0.92-2.12c0-1.07-0.31-2.14-0.94-3.19c-0.63-1.06-1.48-1.89-2.56-2.52l-5.26-3.04v7.38L1762.24,999.51z"/>
                      </g>
                      <g>
                        <path className="st39" d="M1912.39,1097.46c-0.33,0-0.66-0.09-0.95-0.26l-32.31-18.65c-0.59-0.34-0.95-0.97-0.95-1.65v-37.07
                          c0-0.68,0.36-1.31,0.95-1.65c0.59-0.34,1.32-0.34,1.91,0l32.31,18.65c0.59,0.34,0.95,0.97,0.95,1.65v37.07
                          c0,0.68-0.36,1.31-0.95,1.65C1913.05,1097.38,1912.71,1097.46,1912.39,1097.46z M1881.99,1075.8l28.49,16.45v-32.67l-28.49-16.45
                          V1075.8z"/>
                      </g>
                      <g>
                        <linearGradient id="SVGID_22_" gradientUnits="userSpaceOnUse" x1="1644.9041" y1="901.3051" x2="2030.8224" y2="1163.3485">
                          <stop offset="0" style={{stopColor:'#25054D'}} />
                          <stop offset="1" style={{stopColor:'#45108A'}} />
                        </linearGradient>
                        <path className="st42" d="M1850.36,1052.08c-1.6-2.64-2.46-5.58-2.56-8.81c-0.02-0.67-0.03-1.75-0.03-3.24c0-1.51,0.01-2.6,0.03-3.27
                          c0.1-3.06,0.96-5,2.58-5.81c1.62-0.81,3.87-0.37,6.79,1.31c1.89,1.09,3.54,2.41,4.94,3.96c1.4,1.56,2.49,3.2,3.27,4.94
                          c0.77,1.74,1.18,3.42,1.22,5.06v0.07c0,0.2-0.07,0.32-0.21,0.38c-0.14,0.05-0.29,0.03-0.46-0.07l-3.09-1.79
                          c-0.25-0.14-0.45-0.32-0.57-0.53c-0.13-0.21-0.23-0.51-0.32-0.9c-0.32-1.69-0.87-3.07-1.65-4.15c-0.79-1.08-1.83-1.99-3.12-2.74
                          c-3.04-1.75-4.62-0.71-4.75,3.11c-0.02,0.64-0.03,1.65-0.03,3.01c0,1.36,0.01,2.4,0.03,3.12c0.13,3.97,1.71,6.84,4.75,8.59
                          c1.27,0.74,2.31,1.03,3.11,0.86c0.79-0.16,1.35-0.9,1.67-2.23c0.09-0.29,0.19-0.47,0.32-0.53c0.13-0.06,0.32-0.02,0.57,0.13
                          l3.09,1.79c0.19,0.11,0.35,0.28,0.49,0.51c0.14,0.22,0.2,0.44,0.17,0.65c-0.04,1.59-0.45,2.8-1.22,3.64
                          c-0.78,0.84-1.86,1.24-3.27,1.17c-1.4-0.07-3.05-0.65-4.94-1.73C1854.23,1056.9,1851.97,1054.73,1850.36,1052.08z"/>
                      </g>
                      <g>
                        <path className="st39" d="M1717.7,828.79c-0.33,0-0.66-0.09-0.95-0.26l-32.31-18.65c-0.59-0.34-0.95-0.97-0.95-1.65v-37.07
                          c0-0.68,0.36-1.31,0.95-1.65c0.59-0.34,1.32-0.34,1.91,0l32.31,18.65c0.59,0.34,0.95,0.97,0.95,1.65v37.07
                          c0,0.68-0.36,1.31-0.95,1.65C1718.36,828.7,1718.03,828.79,1717.7,828.79z M1687.3,807.13l28.5,16.45v-32.67l-28.5-16.45V807.13z
                          "/>
                      </g>
                      <g>
                        <linearGradient id="SVGID_23_" gradientUnits="userSpaceOnUse" x1="1635.5126" y1="758.8633" x2="2021.4287" y2="1020.9052">
                          <stop offset="0" style={{stopColor:'#25054D'}} />
                          <stop offset="1" style={{stopColor:'#45108A'}} />
                        </linearGradient>
                        <path className="st43" d="M1652.03,783.3c-0.13-0.22-0.19-0.44-0.19-0.66l0.06-0.29l7.65-19.16c0.21-0.49,0.59-0.57,1.15-0.25
                          l3.51,2.02c0.55,0.32,0.93,0.84,1.15,1.57l7.64,27.99l0.07,0.37c0,0.22-0.07,0.36-0.21,0.42c-0.14,0.07-0.29,0.05-0.46-0.04
                          l-2.84-1.64c-0.44-0.26-0.75-0.67-0.92-1.23l-1.46-5.27l-9.43-5.44l-1.47,3.58c-0.17,0.36-0.48,0.42-0.92,0.16l-2.83-1.64
                          C1652.31,783.68,1652.15,783.51,1652.03,783.3z M1658.84,778.09l7.2,4.15l-3.6-13.34L1658.84,778.09z"/>
                      </g>
                      <g>
                        <path className="st39" d="M1816.32,885.72c-0.33,0-0.66-0.09-0.95-0.26l-32.31-18.65c-0.59-0.34-0.95-0.97-0.95-1.65V828.1
                          c0-0.68,0.36-1.31,0.95-1.65c0.59-0.34,1.32-0.34,1.91,0l32.31,18.65c0.59,0.34,0.95,0.97,0.95,1.65v37.07
                          c0,0.68-0.36,1.31-0.95,1.65C1816.98,885.64,1816.65,885.72,1816.32,885.72z M1785.92,864.06l28.5,16.45v-32.67l-28.5-16.45
                          V864.06z"/>
                      </g>
                      <g>
                        <linearGradient id="SVGID_24_" gradientUnits="userSpaceOnUse" x1="1640.2294" y1="751.9172" x2="2026.1476" y2="1013.9605">
                          <stop offset="0" style={{stopColor:'#25054D'}} />
                          <stop offset="1" style={{stopColor:'#45108A'}} />
                        </linearGradient>
                        <path className="st44" d="M1752.72,841.39c-0.15-0.26-0.22-0.51-0.22-0.75v-23.8c0-0.27,0.07-0.45,0.2-0.54
                          c0.14-0.09,0.32-0.07,0.56,0.07l9.24,5.33c2.49,1.43,4.34,3.13,5.58,5.1c1.23,1.97,1.85,4.04,1.85,6.21
                          c0,1.27-0.27,2.19-0.81,2.77c-0.54,0.58-1.18,0.85-1.89,0.83c0.89,0.97,1.65,2.17,2.26,3.57c0.62,1.41,0.93,2.78,0.93,4.12
                          c0,2.27-0.67,3.71-2.01,4.32c-1.34,0.62-3.2,0.23-5.61-1.15l-9.53-5.5C1753.04,841.84,1752.86,841.65,1752.72,841.39z
                          M1761.92,831.86c1.08,0.63,1.92,0.8,2.5,0.53c0.58-0.27,0.88-0.92,0.88-1.94c0-1.02-0.29-1.98-0.86-2.87
                          c-0.57-0.89-1.41-1.66-2.52-2.29l-4.94-2.85v6.58L1761.92,831.86z M1762.24,843.24c1.1,0.64,1.96,0.79,2.58,0.45
                          c0.61-0.34,0.92-1.05,0.92-2.12c0-1.07-0.31-2.14-0.94-3.19c-0.63-1.06-1.48-1.89-2.56-2.52l-5.26-3.04v7.38L1762.24,843.24z"/>
                      </g>
                      <g>
                        <path className="st39" d="M1912.39,941.19c-0.33,0-0.66-0.09-0.95-0.26l-32.31-18.65c-0.59-0.34-0.95-0.97-0.95-1.65v-37.07
                          c0-0.68,0.36-1.31,0.95-1.65c0.59-0.34,1.32-0.34,1.91,0l32.31,18.65c0.59,0.34,0.95,0.97,0.95,1.65v37.07
                          c0,0.68-0.36,1.31-0.95,1.65C1913.05,941.1,1912.71,941.19,1912.39,941.19z M1881.99,919.53l28.49,16.45v-32.67l-28.49-16.45
                          V919.53z"/>
                      </g>
                      <g>
                        <linearGradient id="SVGID_25_" gradientUnits="userSpaceOnUse" x1="1644.9041" y1="745.0318" x2="2030.8224" y2="1007.0753">
                          <stop offset="0" style={{stopColor:'#25054D'}} />
                          <stop offset="1" style={{stopColor:'#45108A'}} />
                        </linearGradient>
                        <path className="st45" d="M1850.36,895.81c-1.6-2.64-2.46-5.58-2.56-8.81c-0.02-0.67-0.03-1.75-0.03-3.24c0-1.51,0.01-2.6,0.03-3.27
                          c0.1-3.06,0.96-5,2.58-5.81c1.62-0.81,3.87-0.37,6.79,1.31c1.89,1.09,3.54,2.41,4.94,3.96c1.4,1.56,2.49,3.2,3.27,4.94
                          c0.77,1.74,1.18,3.42,1.22,5.06v0.07c0,0.2-0.07,0.32-0.21,0.38c-0.14,0.05-0.29,0.03-0.46-0.07l-3.09-1.79
                          c-0.25-0.14-0.45-0.32-0.57-0.53c-0.13-0.21-0.23-0.51-0.32-0.9c-0.32-1.69-0.87-3.07-1.65-4.15c-0.79-1.08-1.83-1.99-3.12-2.74
                          c-3.04-1.75-4.62-0.71-4.75,3.11c-0.02,0.64-0.03,1.65-0.03,3.01c0,1.36,0.01,2.4,0.03,3.12c0.13,3.97,1.71,6.84,4.75,8.59
                          c1.27,0.74,2.31,1.03,3.11,0.86c0.79-0.16,1.35-0.9,1.67-2.23c0.09-0.29,0.19-0.47,0.32-0.53c0.13-0.06,0.32-0.02,0.57,0.13
                          l3.09,1.79c0.19,0.11,0.35,0.28,0.49,0.51c0.14,0.22,0.2,0.44,0.17,0.65c-0.04,1.59-0.45,2.8-1.22,3.64
                          c-0.78,0.85-1.86,1.24-3.27,1.17c-1.4-0.07-3.05-0.65-4.94-1.73C1854.23,900.62,1851.97,898.46,1850.36,895.81z"/>
                      </g>
                      <g>
                        <path className="st39" d="M2013.76,1155.99c-0.33,0-0.66-0.09-0.95-0.26l-32.31-18.65c-0.59-0.34-0.95-0.97-0.95-1.65v-37.07
                          c0-0.68,0.36-1.31,0.95-1.65c0.59-0.34,1.32-0.34,1.91,0l32.31,18.65c0.59,0.34,0.95,0.97,0.95,1.65v37.07
                          c0,0.68-0.36,1.31-0.95,1.65C2014.42,1155.9,2014.09,1155.99,2013.76,1155.99z M1983.36,1134.33l28.49,16.45v-32.67l-28.49-16.45
                          V1134.33z"/>
                      </g>
                      <g>
                        <linearGradient id="SVGID_26_" gradientUnits="userSpaceOnUse" x1="1649.5242" y1="894.4968" x2="2035.4441" y2="1156.5414">
                          <stop offset="0" style={{stopColor:'#25054D'}} />
                          <stop offset="1" style={{stopColor:'#45108A'}} />
                        </linearGradient>
                        <path className="st46" d="M1950.16,1111.66c-0.15-0.26-0.22-0.51-0.22-0.75v-23.8c0-0.27,0.07-0.45,0.2-0.54
                          c0.14-0.09,0.32-0.07,0.56,0.07l7.77,4.49c6.27,3.62,9.46,8.78,9.59,15.48c0.04,1.44,0.06,2.4,0.06,2.89
                          c0,0.46-0.02,1.39-0.06,2.78c-0.09,3.37-0.89,5.42-2.41,6.16c-1.52,0.74-3.86,0.2-7.02-1.63l-7.93-4.58
                          C1950.49,1112.11,1950.31,1111.92,1950.16,1111.66z M1958.47,1112.47c1.76,1.02,3.04,1.3,3.82,0.86c0.79-0.45,1.2-1.71,1.24-3.78
                          c0.04-1.44,0.06-2.34,0.06-2.71c0-0.39-0.02-1.3-0.06-2.74c-0.04-2.03-0.48-3.74-1.31-5.16c-0.83-1.41-2.13-2.64-3.92-3.67
                          l-3.89-2.25v17.11L1958.47,1112.47z"/>
                      </g>
                      <linearGradient id="SVGID_27_" gradientUnits="userSpaceOnUse" x1="1786.8257" y1="1011.793" x2="1827.4822" y2="1011.793">
                        <stop offset="0.0354" style={{stopColor:'#9F19AB'}} />
                        <stop offset="0.2438" style={{stopColor:'#AE25B1'}} />
                        <stop offset="0.6455" style={{stopColor:'#D744BF'}} />
                        <stop offset="1" style={{stopColor:'#FF63CE'}} />
                      </linearGradient>
                      <polygon className="st47" points="1786.83,1005.12 1798.26,1024.82 1827.48,1008.3 1820.49,998.77 1799.85,1019.1 1796.04,1003.85"/>
                    </g>
                  </g>
                </svg>
              </div>

              <div className="clipboard-container">
                <svg 
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" y="0px"
                  viewBox="0 0 2550 1720"
                >
                  <g id="clipboard">
                    <path className="st48" d="M737.55,174.31l638.31-5.75c35.22-0.32,64.5,27.02,66.61,62.17l55.46,925.01
                      c1.65,27.51-19.81,50.92-47.35,51.67l-731.47,19.89c-25.89,0.7-47.31-19.96-47.55-45.86l-8.39-931.39
                      C662.79,208.59,696.09,174.68,737.55,174.31z"/>
                    <path className="st19" d="M825.87,1209.85C825.87,1209.85,825.86,1209.85,825.87,1209.85l-81.87-0.32
                      c-26.97-0.11-49.11-22.13-49.35-49.1l-1.11-123.59c0-0.55,0.44-1,0.99-1.01c0.55-0.02,1,0.44,1.01,0.99l1.11,123.59
                      c0.23,25.88,21.48,47.02,47.36,47.12l81.87,0.32c0.55,0,1,0.45,1,1C826.86,1209.4,826.42,1209.85,825.87,1209.85z"/>
                    <path className="st19" d="M694.18,999.54c-0.55,0-1-0.44-1-0.99L693,978.54c0-0.55,0.44-1,0.99-1.01c0,0,0.01,0,0.01,0
                      c0.55,0,1,0.44,1,0.99l0.18,20.01C695.19,999.09,694.75,999.54,694.18,999.54C694.19,999.54,694.19,999.54,694.18,999.54z"/>
                    
                    <g id="board-multiple-choice">
                      <path className="st73" d="M739.52,162.94c89.2,11.81,395.95,48.31,649.51,28.86c11.56-0.89,21.61,7.73,22.24,19.13l51.61,936.52
                        c0.71,12.84-9.24,23.83-22.28,24.61c-106.64,6.33-545.84,30.84-702.52,14.85c-9.54-0.97-16.78-8.87-16.85-18.31l-6.99-983.74
                        C714.15,171.51,726.07,161.16,739.52,162.94z"/>
                      
                      <linearGradient id="SVGID_42_" gradientUnits="userSpaceOnUse" x1="88.1814" y1="4387.3784" x2="827.0141" y2="3297.3406" gradientTransform="matrix(0.9815 -1.131597e-03 1.117704e-03 0.9687 509.9971 -2859.0002)">
                        <stop offset="0" style={{stopColor:'#FE7062'}}/>
                        <stop offset="1" style={{stopColor:'#FF928E'}}/>
                      </linearGradient>
                      <path className="st74" d="M755.15,198.53c85.55,11.23,379.72,45.93,622.88,27.44c11.09-0.84,20.72,7.35,21.33,18.2l49.48,890.59
                        c0.68,12.21-8.86,22.66-21.36,23.4c-102.27,6.02-523.46,29.34-673.72,14.14c-9.14-0.92-16.09-8.43-16.16-17.41l-6.69-935.48
                        C730.82,206.69,742.25,196.84,755.15,198.53z"/>
                      <path className="st75" d="M755.91,192.91c85.26,11.24,378.43,45.99,620.76,27.47c11.05-0.84,20.65,7.36,21.25,18.22l49.32,891.58
                        c0.68,12.22-8.83,22.69-21.29,23.43c-101.92,6.03-521.68,29.36-671.43,14.15c-9.11-0.93-16.04-8.44-16.1-17.43l-6.68-936.52
                        C731.66,201.07,743.05,191.21,755.91,192.91z"/>
                      <g>
                        <g>
                          <path className="st55" d="M891.52,1079.33c-0.29,0.3-0.68,0.47-1.13,0.48l-45.61,1.53c-0.89,0.03-1.88-0.57-2.61-1.58
                            c-0.73-1.01-1.07-2.27-0.91-3.31l8.31-52.9c0.16-1.04,0.81-1.7,1.7-1.73l45.61-1.53c0.89-0.03,1.88,0.57,2.61,1.58
                            c0.73,1.01,1.07,2.27,0.91,3.31l-8.31,52.9C892.02,1078.6,891.81,1079.03,891.52,1079.33z M847.28,1074.58l39.86-1.33
                            l7.26-46.19l-39.86,1.34L847.28,1074.58z"/>
                        </g>
                        <g>
                          
                          <linearGradient id="SVGID_43_" gradientUnits="userSpaceOnUse" x1="926.8043" y1="1234.8318" x2="608.9471" y2="486.4392" gradientTransform="matrix(0.7601 -0.6498 0.6498 0.7601 -22.5949 1052.6277)">
                            <stop offset="0" style={{stopColor:'#25054D'}}/>
                            <stop offset="1" style={{stopColor:'#45108A'}}/>
                          </linearGradient>
                          <path className="st76" d="M798.02,1071.53c-0.14-0.2-0.19-0.46-0.14-0.78l0.16-0.47l16.08-34c0.44-0.88,1.05-1.34,1.83-1.37
                            l4.96-0.17c0.78-0.02,1.24,0.39,1.41,1.25l5.51,33.29l0.02,0.46c-0.05,0.31-0.19,0.58-0.41,0.79c-0.23,0.21-0.46,0.33-0.7,0.33
                            l-4,0.14c-0.63,0.01-1.01-0.3-1.15-0.95l-1.08-6.24l-13.3,0.44l-3.07,6.39c-0.35,0.67-0.83,1.02-1.46,1.03l-4,0.14
                            C798.39,1071.83,798.18,1071.73,798.02,1071.53z M809.7,1058.17l10.17-0.34l-2.57-15.9L809.7,1058.17z"/>
                        </g>
                        <g>
                          <path className="st55" d="M1030.76,1074.66c-0.29,0.3-0.68,0.47-1.13,0.48l-45.62,1.53c-0.89,0.03-1.88-0.57-2.61-1.58
                            c-0.73-1.01-1.07-2.27-0.91-3.31l8.31-52.9c0.16-1.04,0.81-1.7,1.7-1.73l45.62-1.53c0.89-0.03,1.88,0.57,2.61,1.58
                            c0.73,1.01,1.07,2.27,0.91,3.31l-8.31,52.9C1031.26,1073.93,1031.06,1074.36,1030.76,1074.66z M986.52,1069.91l39.86-1.33
                            l7.26-46.18l-39.86,1.33L986.52,1069.91z"/>
                        </g>
                        <g>
                          
                          <linearGradient id="SVGID_44_" gradientUnits="userSpaceOnUse" x1="1027.6326" y1="1299.4679" x2="655.1045" y2="422.3533" gradientTransform="matrix(0.7601 -0.6498 0.6498 0.7601 -22.5949 1052.6277)">
                            <stop offset="0" style={{stopColor:'#25054D'}}/>
                            <stop offset="1" style={{stopColor:'#45108A'}}/>
                          </linearGradient>
                          <path className="st77" d="M940.2,1066.7c-0.18-0.23-0.24-0.52-0.19-0.87l5.34-33.95c0.06-0.38,0.21-0.7,0.44-0.95
                            c0.23-0.24,0.52-0.38,0.85-0.39l13.05-0.44c3.51-0.11,5.98,0.7,7.45,2.43c1.46,1.74,1.94,4.15,1.46,7.25
                            c-0.29,1.81-0.9,3.35-1.87,4.65c-0.96,1.29-1.99,2.23-3.11,2.83c1.17,0.61,2.06,1.66,2.69,3.13c0.64,1.46,0.8,3.15,0.5,5.07
                            c-0.51,3.24-1.86,5.87-4.06,7.92c-2.2,2.04-4.99,3.13-8.38,3.24l-13.45,0.45C940.61,1067.06,940.37,1066.94,940.2,1066.7z
                            M954.46,1061.04c1.56-0.05,2.85-0.59,3.88-1.61c1.02-1.02,1.66-2.29,1.9-3.82c0.24-1.52,0-2.78-0.74-3.74
                            c-0.72-0.96-1.85-1.41-3.38-1.37l-7.42,0.25l-1.66,10.54L954.46,1061.04z M956.52,1045.09c1.53-0.05,2.78-0.53,3.74-1.43
                            c0.97-0.9,1.56-2.07,1.79-3.53c0.23-1.46,0-2.58-0.68-3.36c-0.68-0.77-1.81-1.12-3.36-1.07l-6.97,0.23l-1.48,9.39
                            L956.52,1045.09z"/>
                        </g>
                        <g>
                          <path className="st55" d="M1166.4,1070.1c-0.29,0.3-0.68,0.47-1.13,0.48l-45.61,1.53c-0.89,0.03-1.88-0.57-2.61-1.58
                            c-0.73-1.01-1.07-2.27-0.91-3.31l8.31-52.9c0.16-1.04,0.81-1.7,1.7-1.73l45.61-1.53c0.89-0.03,1.88,0.57,2.61,1.58
                            c0.73,1.01,1.07,2.27,0.91,3.31l-8.31,52.9C1166.9,1069.38,1166.69,1069.81,1166.4,1070.1z M1122.16,1065.36l39.86-1.34
                            l7.26-46.18l-39.86,1.34L1122.16,1065.36z"/>
                        </g>
                        <g>
                          
                          <linearGradient id="SVGID_45_" gradientUnits="userSpaceOnUse" x1="1065.406" y1="1219.7823" x2="714.7126" y2="394.0775" gradientTransform="matrix(0.7601 -0.6498 0.6498 0.7601 -22.5949 1052.6277)">
                            <stop offset="0" style={{stopColor:'#25054D'}}/>
                            <stop offset="1" style={{stopColor:'#45108A'}}/>
                          </linearGradient>
                          <path className="st78" d="M1078.51,1059.29c-1.88-2.38-2.54-5.83-1.97-10.34c0.1-0.93,0.33-2.46,0.67-4.58
                            c0.34-2.15,0.59-3.72,0.79-4.71c0.85-4.45,2.6-7.96,5.27-10.53c2.67-2.56,6.05-3.91,10.16-4.05c2.67-0.09,4.91,0.37,6.72,1.36
                            c1.82,1,3.11,2.4,3.93,4.21c0.8,1.81,1.06,3.85,0.76,6.14l-0.02,0.1c-0.04,0.28-0.18,0.52-0.4,0.72s-0.47,0.31-0.69,0.31
                            l-4.36,0.15c-0.37,0.01-0.62-0.08-0.78-0.26c-0.14-0.18-0.24-0.52-0.28-1c-0.11-2.14-0.65-3.64-1.62-4.48
                            c-0.97-0.85-2.37-1.25-4.2-1.18c-4.28,0.14-6.96,3-8.01,8.57c-0.2,0.94-0.43,2.38-0.73,4.33c-0.31,1.95-0.54,3.43-0.65,4.43
                            c-0.7,5.56,1.1,8.27,5.38,8.13c1.8-0.06,3.33-0.55,4.6-1.48c1.26-0.93,2.28-2.47,3.07-4.65c0.19-0.49,0.4-0.84,0.6-1.03
                            c0.21-0.2,0.5-0.3,0.87-0.32l4.36-0.15c0.27-0.01,0.48,0.09,0.64,0.29c0.16,0.2,0.21,0.46,0.13,0.76
                            c-0.42,2.3-1.33,4.4-2.72,6.28c-1.39,1.88-3.14,3.38-5.29,4.5c-2.14,1.13-4.56,1.74-7.22,1.82
                            C1083.4,1062.79,1080.38,1061.66,1078.51,1059.29z"/>
                        </g>
                        <g>
                          <path className="st55" d="M1309.53,1065.31c-0.29,0.3-0.68,0.47-1.13,0.48l-45.61,1.53c-0.89,0.03-1.88-0.57-2.61-1.58
                            c-0.73-1.01-1.07-2.27-0.91-3.31l8.31-52.9c0.16-1.04,0.81-1.7,1.7-1.73l45.61-1.53c0.89-0.03,1.88,0.57,2.61,1.58
                            c0.73,1.01,1.07,2.27,0.91,3.31l-8.31,52.9C1310.02,1064.58,1309.82,1065.01,1309.53,1065.31z M1265.29,1060.56l39.86-1.33
                            l7.26-46.18l-39.86,1.34L1265.29,1060.56z"/>
                        </g>
                        <g>
                          
                          <linearGradient id="SVGID_46_" gradientUnits="userSpaceOnUse" x1="1127.583" y1="1196.425" x2="758.3994" y2="327.1846" gradientTransform="matrix(0.7601 -0.6498 0.6498 0.7601 -22.5949 1052.6277)">
                            <stop offset="0" style={{stopColor:'#25054D'}}/>
                            <stop offset="1" style={{stopColor:'#45108A'}}/>
                          </linearGradient>
                          <path className="st79" d="M1218.96,1057.35c-0.18-0.23-0.24-0.52-0.19-0.87l5.34-33.96c0.06-0.38,0.21-0.7,0.44-0.95
                            c0.23-0.24,0.52-0.38,0.85-0.39l10.98-0.37c8.85-0.3,12.61,4.29,11.31,13.73c-0.26,2.02-0.45,3.37-0.56,4.07
                            c-0.11,0.66-0.34,2-0.71,4.02c-0.9,4.88-2.59,8.5-5.1,10.89c-2.5,2.38-5.99,3.65-10.46,3.79l-11.2,0.38
                            C1219.37,1057.71,1219.13,1057.59,1218.96,1057.35z M1231.59,1051.28c2.49-0.09,4.38-0.79,5.7-2.11
                            c1.31-1.32,2.23-3.47,2.77-6.48c0.39-2.08,0.62-3.39,0.7-3.91c0.08-0.55,0.26-1.85,0.52-3.86c0.37-2.84,0.09-4.92-0.86-6.22
                            c-0.96-1.3-2.7-1.91-5.22-1.82l-5.49,0.18l-3.83,24.41L1231.59,1051.28z"/>
                        </g>
                      </g>
                      <path className="st80" d="M1073.23,972.23l3.14-20l-34.5,1.16l-3.14,20L1073.23,972.23z M1173.04,906.34l-193.42,6.49l-3.14,20
                        l193.42-6.49L1173.04,906.34z M885.36,978.53l138.6-4.65l3.14-20l-138.6,4.65L885.36,978.53z M1307.94,901.82l-120.12,4.03
                        l-3.14,20l120.12-4.03L1307.94,901.82z M1061.62,852.6l-233.59,7.84l-5.29,26.46l233.59-7.84L1061.62,852.6z M1244.54,946.58
                        l-153.38,5.15l-3.14,20l153.38-5.15L1244.54,946.58z M1319.58,921.32l35.93-1.21l3.14-20l-35.93,1.21L1319.58,921.32z
                        M873.72,959.02l-61.39,2.06l-3.14,20l61.39-2.06L873.72,959.02z M964.84,913.33l-145.78,4.89l-3.14,20l145.78-4.89L964.84,913.33
                        z"/>
                      <g>
                        <g>
                          <path className="st55" d="M890.49,787.36c-0.29,0.3-0.68,0.47-1.13,0.48l-45.61,1.53c-0.89,0.03-1.88-0.57-2.61-1.58
                            c-0.73-1.01-1.07-2.27-0.91-3.31l8.31-52.9c0.16-1.04,0.81-1.7,1.7-1.73l45.61-1.53c0.89-0.03,1.88,0.57,2.61,1.58
                            c0.73,1.01,1.07,2.27,0.91,3.31l-8.31,52.9C890.99,786.64,890.78,787.07,890.49,787.36z M846.25,782.61l39.86-1.33l7.26-46.19
                            l-39.86,1.34L846.25,782.61z"/>
                        </g>
                        <g>
                          
                          <linearGradient id="SVGID_47_" gradientUnits="userSpaceOnUse" x1="1125.3596" y1="1034.8766" x2="807.5024" y2="286.4841" gradientTransform="matrix(0.7601 -0.6498 0.6498 0.7601 -22.5949 1052.6277)">
                            <stop offset="0" style={{stopColor:'#25054D'}}/>
                            <stop offset="1" style={{stopColor:'#45108A'}}/>
                          </linearGradient>
                          <path className="st81" d="M796.99,779.57c-0.14-0.2-0.19-0.46-0.14-0.78l0.16-0.47l16.08-34c0.44-0.88,1.05-1.34,1.83-1.37
                            l4.96-0.17c0.78-0.02,1.24,0.39,1.41,1.25l5.51,33.29l0.02,0.46c-0.05,0.31-0.19,0.58-0.41,0.79c-0.23,0.21-0.46,0.33-0.7,0.33
                            l-4,0.14c-0.63,0.01-1.01-0.3-1.15-0.95l-1.08-6.24l-13.3,0.44l-3.07,6.39c-0.35,0.67-0.83,1.02-1.46,1.03l-4,0.14
                            C797.36,779.87,797.15,779.76,796.99,779.57z M808.67,766.2l10.17-0.34l-2.57-15.9L808.67,766.2z"/>
                        </g>
                        <g>
                          <path className="st55" d="M1029.73,782.69c-0.29,0.3-0.68,0.47-1.13,0.48l-45.62,1.53c-0.89,0.03-1.88-0.57-2.61-1.58
                            c-0.73-1.01-1.07-2.27-0.91-3.31l8.31-52.9c0.16-1.04,0.81-1.7,1.7-1.73l45.62-1.53c0.89-0.03,1.88,0.57,2.61,1.58
                            c0.73,1.01,1.07,2.27,0.91,3.31l-8.31,52.9C1030.23,781.97,1030.03,782.4,1029.73,782.69z M985.49,777.94l39.86-1.33l7.26-46.18
                            l-39.86,1.33L985.49,777.94z"/>
                        </g>
                        <g>
                          
                          <linearGradient id="SVGID_48_" gradientUnits="userSpaceOnUse" x1="1227.842" y1="1103.4073" x2="855.314" y2="226.2928" gradientTransform="matrix(0.7601 -0.6498 0.6498 0.7601 -22.5949 1052.6277)">
                            <stop offset="0" style={{stopColor:'#25054D'}}/>
                            <stop offset="1" style={{stopColor:'#45108A'}}/>
                          </linearGradient>
                          <path className="st82" d="M939.17,774.74c-0.18-0.23-0.24-0.52-0.19-0.87l5.34-33.95c0.06-0.38,0.21-0.7,0.44-0.95
                            c0.23-0.24,0.52-0.38,0.85-0.39l13.05-0.44c3.51-0.11,5.98,0.7,7.45,2.43c1.46,1.74,1.94,4.15,1.46,7.25
                            c-0.29,1.81-0.9,3.35-1.87,4.65c-0.96,1.29-1.99,2.23-3.11,2.83c1.17,0.61,2.06,1.66,2.69,3.12c0.64,1.46,0.8,3.15,0.5,5.07
                            c-0.51,3.24-1.86,5.87-4.06,7.92c-2.2,2.04-4.99,3.13-8.38,3.24l-13.45,0.45C939.58,775.1,939.33,774.98,939.17,774.74z
                            M953.43,769.08c1.56-0.05,2.85-0.59,3.88-1.61c1.02-1.02,1.66-2.29,1.9-3.82c0.24-1.52,0-2.78-0.74-3.74
                            c-0.72-0.96-1.85-1.41-3.38-1.37l-7.42,0.25l-1.66,10.54L953.43,769.08z M955.49,753.13c1.53-0.05,2.78-0.53,3.74-1.42
                            c0.97-0.9,1.56-2.07,1.79-3.53c0.23-1.46,0-2.58-0.68-3.36c-0.68-0.77-1.81-1.12-3.36-1.07l-6.97,0.23l-1.48,9.39L955.49,753.13
                            z"/>
                        </g>
                        <g>
                          <path className="st55" d="M1165.37,778.14c-0.29,0.3-0.68,0.47-1.13,0.48l-45.61,1.53c-0.89,0.03-1.88-0.57-2.61-1.58
                            c-0.73-1.01-1.07-2.27-0.91-3.31l8.31-52.9c0.16-1.04,0.81-1.7,1.7-1.73l45.61-1.53c0.89-0.03,1.88,0.57,2.61,1.58
                            c0.73,1.01,1.07,2.27,0.91,3.31l-8.31,52.9C1165.87,777.42,1165.66,777.84,1165.37,778.14z M1121.13,773.39l39.86-1.34
                            l7.26-46.18l-39.86,1.34L1121.13,773.39z"/>
                        </g>
                        <g>
                          
                          <linearGradient id="SVGID_49_" gradientUnits="userSpaceOnUse" x1="1264.9546" y1="1022.1661" x2="914.2614" y2="196.4612" gradientTransform="matrix(0.7601 -0.6498 0.6498 0.7601 -22.5949 1052.6277)">
                            <stop offset="0" style={{stopColor:'#25054D'}}/>
                            <stop offset="1" style={{stopColor:'#45108A'}}/>
                          </linearGradient>
                          <path className="st83" d="M1077.48,767.33c-1.88-2.38-2.54-5.83-1.97-10.34c0.1-0.93,0.33-2.46,0.67-4.58
                            c0.34-2.15,0.59-3.72,0.79-4.71c0.85-4.45,2.6-7.96,5.27-10.53c2.67-2.56,6.05-3.91,10.16-4.05c2.67-0.09,4.91,0.37,6.72,1.36
                            c1.82,1,3.11,2.4,3.93,4.21c0.8,1.81,1.06,3.85,0.76,6.14l-0.02,0.1c-0.04,0.28-0.18,0.52-0.4,0.72s-0.47,0.31-0.7,0.31
                            l-4.36,0.15c-0.37,0.01-0.62-0.08-0.78-0.26c-0.14-0.18-0.24-0.52-0.28-1c-0.11-2.14-0.65-3.64-1.62-4.48
                            c-0.97-0.85-2.37-1.25-4.2-1.18c-4.28,0.14-6.96,3-8.01,8.57c-0.2,0.94-0.43,2.38-0.73,4.33c-0.31,1.95-0.54,3.43-0.65,4.43
                            c-0.7,5.56,1.1,8.27,5.38,8.13c1.8-0.06,3.33-0.55,4.6-1.48c1.26-0.93,2.28-2.47,3.07-4.65c0.19-0.49,0.4-0.84,0.6-1.03
                            c0.21-0.2,0.5-0.3,0.87-0.32l4.36-0.15c0.27-0.01,0.48,0.09,0.64,0.29c0.16,0.2,0.21,0.46,0.13,0.76
                            c-0.42,2.3-1.33,4.4-2.72,6.28c-1.39,1.88-3.14,3.38-5.29,4.5c-2.14,1.13-4.56,1.74-7.22,1.82
                            C1082.36,770.82,1079.35,769.7,1077.48,767.33z"/>
                        </g>
                      </g>
                      <path className="st80" d="M1092.95,680.6l3.14-20l-61.6,2.07l-3.14,20L1092.95,680.6z M1143.49,616.37l-138.6,4.65l-3.14,20
                        l138.6-4.65L1143.49,616.37z M877.98,687.81l138.6-4.65l3.14-20l-138.6,4.65L877.98,687.81z M1078.72,563.98l-233.59,7.84
                        l-5.29,26.46l233.59-7.84L1078.72,563.98z M1158.27,615.87l-3.14,20l34.49-1.16l3.14-20L1158.27,615.87z M1107.74,680.1
                        l117.04-3.93l3.14-20l-117.04,3.93L1107.74,680.1z M1239.56,675.68l125.25-4.2l3.14-20l-125.25,4.2L1239.56,675.68z
                        M1360.93,609.07l-153.38,5.15l-3.14,20l153.38-5.15L1360.93,609.07z M866.34,668.3l-37.99,1.27l-3.14,20l37.99-1.27L866.34,668.3
                        z M990.11,621.51l-155.02,5.2l-3.14,20l155.02-5.2L990.11,621.51z"/>
                      <g>
                        <g>
                          <path className="st55" d="M890.07,513.34c-0.29,0.3-0.68,0.47-1.13,0.48l-45.61,1.53c-0.89,0.03-1.88-0.57-2.61-1.58
                            c-0.73-1.01-1.07-2.27-0.91-3.31l8.31-52.9c0.16-1.04,0.81-1.7,1.7-1.73l45.61-1.53c0.89-0.03,1.88,0.57,2.61,1.58
                            c0.73,1.01,1.07,2.27,0.91,3.31l-8.31,52.9C890.57,512.62,890.37,513.05,890.07,513.34z M845.84,508.59l39.86-1.33l7.26-46.19
                            l-39.86,1.34L845.84,508.59z"/>
                        </g>
                        <g>
                          
                          <linearGradient id="SVGID_50_" gradientUnits="userSpaceOnUse" x1="1312.6259" y1="848.728" x2="994.7662" y2="100.3296" gradientTransform="matrix(0.7601 -0.6498 0.6498 0.7601 -22.5949 1052.6277)">
                            <stop offset="0" style={{stopColor:'#25054D'}}/>
                            <stop offset="1" style={{stopColor:'#45108A'}}/>
                          </linearGradient>
                          <path className="st84" d="M796.58,505.55c-0.14-0.21-0.19-0.46-0.14-0.78l0.16-0.47l16.08-34c0.44-0.88,1.05-1.34,1.83-1.36
                            l4.96-0.17c0.78-0.02,1.24,0.39,1.41,1.25l5.51,33.28l0.02,0.46c-0.05,0.31-0.19,0.58-0.41,0.79c-0.23,0.21-0.46,0.33-0.7,0.33
                            l-4,0.14c-0.63,0.01-1.01-0.3-1.15-0.95l-1.08-6.24l-13.3,0.43l-3.07,6.39c-0.35,0.67-0.83,1.02-1.46,1.03l-4,0.14
                            C796.94,505.84,796.73,505.74,796.58,505.55z M808.25,492.18l10.17-0.34l-2.57-15.9L808.25,492.18z"/>
                        </g>
                        <g>
                          <path className="st55" d="M1029.32,508.67c-0.29,0.3-0.68,0.47-1.13,0.48l-45.62,1.53c-0.89,0.03-1.88-0.57-2.61-1.58
                            c-0.73-1.01-1.07-2.27-0.91-3.31l8.31-52.9c0.16-1.04,0.81-1.7,1.7-1.73l45.62-1.53c0.89-0.03,1.88,0.57,2.61,1.58
                            c0.73,1.01,1.07,2.27,0.91,3.31l-8.31,52.9C1029.81,507.95,1029.61,508.38,1029.32,508.67z M985.07,503.92l39.86-1.33
                            l7.26-46.18l-39.86,1.33L985.07,503.92z"/>
                        </g>
                        <g>
                          
                          <linearGradient id="SVGID_51_" gradientUnits="userSpaceOnUse" x1="1416.7417" y1="921.1042" x2="1044.2142" y2="43.991" gradientTransform="matrix(0.7601 -0.6498 0.6498 0.7601 -22.5949 1052.6277)">
                            <stop offset="0" style={{stopColor:'#25054D'}}/>
                            <stop offset="1" style={{stopColor:'#45108A'}}/>
                          </linearGradient>
                          <path className="st85" d="M938.75,500.72c-0.18-0.23-0.24-0.52-0.19-0.87l5.34-33.95c0.06-0.38,0.21-0.7,0.44-0.95
                            c0.23-0.24,0.52-0.38,0.85-0.39l13.05-0.44c3.51-0.11,5.98,0.7,7.44,2.42c1.46,1.74,1.94,4.15,1.46,7.25
                            c-0.29,1.81-0.9,3.36-1.87,4.65c-0.96,1.29-1.99,2.23-3.11,2.83c1.17,0.61,2.06,1.66,2.69,3.12c0.64,1.46,0.8,3.15,0.5,5.07
                            c-0.51,3.23-1.86,5.87-4.06,7.92c-2.2,2.04-4.99,3.13-8.38,3.24l-13.45,0.45C939.16,501.07,938.92,500.96,938.75,500.72z
                            M953.01,495.05c1.56-0.05,2.85-0.59,3.88-1.61c1.02-1.01,1.66-2.29,1.9-3.82c0.24-1.53,0-2.78-0.74-3.74
                            c-0.72-0.96-1.85-1.41-3.38-1.36l-7.42,0.25l-1.66,10.54L953.01,495.05z M955.07,479.11c1.53-0.05,2.78-0.53,3.74-1.42
                            c0.97-0.9,1.56-2.07,1.78-3.53c0.23-1.46,0-2.58-0.68-3.36c-0.68-0.77-1.81-1.12-3.36-1.08l-6.97,0.23l-1.48,9.39L955.07,479.11
                            z"/>
                        </g>
                        <g>
                          <path className="st55" d="M1164.95,504.12c-0.29,0.3-0.68,0.47-1.13,0.48l-45.61,1.53c-0.89,0.03-1.88-0.57-2.61-1.58
                            c-0.73-1.01-1.07-2.27-0.91-3.31l8.31-52.9c0.16-1.04,0.81-1.7,1.7-1.73l45.61-1.53c0.89-0.03,1.88,0.57,2.61,1.58
                            c0.73,1.01,1.07,2.27,0.91,3.31l-8.31,52.9C1165.45,503.4,1165.25,503.82,1164.95,504.12z M1120.71,499.37l39.86-1.34
                            l7.25-46.18l-39.86,1.34L1120.71,499.37z"/>
                        </g>
                        <g>
                          
                          <linearGradient id="SVGID_52_" gradientUnits="userSpaceOnUse" x1="1453.2017" y1="838.326" x2="1102.5083" y2="12.6213" gradientTransform="matrix(0.7601 -0.6498 0.6498 0.7601 -22.5949 1052.6277)">
                            <stop offset="0" style={{stopColor:'#25054D'}}/>
                            <stop offset="1" style={{stopColor:'#45108A'}}/>
                          </linearGradient>
                          <path className="st86" d="M1077.06,493.31c-1.88-2.38-2.54-5.83-1.97-10.34c0.1-0.94,0.33-2.46,0.67-4.58
                            c0.34-2.15,0.59-3.72,0.79-4.71c0.85-4.45,2.6-7.96,5.27-10.53c2.67-2.56,6.05-3.91,10.16-4.05c2.66-0.09,4.91,0.37,6.72,1.36
                            c1.82,1,3.11,2.4,3.93,4.21c0.8,1.8,1.06,3.85,0.76,6.14l-0.02,0.1c-0.04,0.28-0.18,0.52-0.41,0.72
                            c-0.22,0.2-0.47,0.31-0.69,0.31l-4.36,0.15c-0.37,0.01-0.62-0.08-0.78-0.26c-0.14-0.18-0.24-0.52-0.28-1
                            c-0.11-2.14-0.65-3.64-1.62-4.48c-0.97-0.85-2.37-1.25-4.2-1.18c-4.28,0.14-6.96,3-8.01,8.57c-0.2,0.94-0.43,2.38-0.73,4.33
                            c-0.31,1.95-0.54,3.43-0.65,4.43c-0.7,5.56,1.1,8.27,5.38,8.13c1.8-0.06,3.33-0.55,4.6-1.48c1.26-0.93,2.28-2.47,3.08-4.65
                            c0.19-0.49,0.4-0.84,0.6-1.03c0.21-0.2,0.5-0.3,0.87-0.32l4.36-0.15c0.27-0.01,0.48,0.09,0.64,0.29
                            c0.16,0.2,0.21,0.46,0.13,0.77c-0.42,2.3-1.33,4.4-2.72,6.28c-1.39,1.88-3.14,3.38-5.29,4.5c-2.14,1.13-4.55,1.74-7.22,1.83
                            C1081.95,496.8,1078.94,495.68,1077.06,493.31z"/>
                        </g>
                        <g>
                          <path className="st55" d="M1308.08,499.32c-0.29,0.3-0.68,0.47-1.13,0.48l-45.61,1.53c-0.89,0.03-1.88-0.57-2.61-1.58
                            c-0.73-1.01-1.07-2.27-0.91-3.31l8.31-52.9c0.16-1.04,0.81-1.7,1.7-1.73l45.61-1.53c0.89-0.03,1.88,0.57,2.61,1.58
                            c0.73,1.01,1.07,2.27,0.91,3.31l-8.31,52.9C1308.58,498.6,1308.37,499.02,1308.08,499.32z M1263.84,494.57l39.86-1.33
                            l7.26-46.18l-39.86,1.33L1263.84,494.57z"/>
                        </g>
                        <g>
                          
                          <linearGradient id="SVGID_53_" gradientUnits="userSpaceOnUse" x1="1516.4911" y1="817.5885" x2="1147.3075" y2="-51.6516" gradientTransform="matrix(0.7601 -0.6498 0.6498 0.7601 -22.5949 1052.6277)">
                            <stop offset="0" style={{stopColor:'#25054D'}}/>
                            <stop offset="1" style={{stopColor:'#45108A'}}/>
                          </linearGradient>
                          <path className="st87" d="M1217.52,491.36c-0.18-0.23-0.24-0.52-0.19-0.87l5.34-33.96c0.06-0.38,0.21-0.7,0.44-0.95
                            c0.23-0.24,0.52-0.38,0.85-0.39l10.98-0.37c8.85-0.3,12.61,4.29,11.31,13.73c-0.26,2.02-0.45,3.37-0.56,4.07
                            c-0.11,0.66-0.34,2-0.71,4.02c-0.9,4.88-2.59,8.5-5.1,10.89c-2.5,2.38-5.99,3.65-10.46,3.79l-11.2,0.38
                            C1217.93,491.72,1217.68,491.61,1217.52,491.36z M1230.15,485.29c2.49-0.09,4.38-0.79,5.7-2.11c1.31-1.32,2.23-3.47,2.77-6.48
                            c0.39-2.08,0.62-3.39,0.7-3.91c0.08-0.55,0.26-1.85,0.52-3.86c0.37-2.84,0.09-4.92-0.86-6.22c-0.96-1.3-2.7-1.9-5.22-1.82
                            l-5.49,0.18l-3.84,24.41L1230.15,485.29z"/>
                        </g>
                      </g>
                      <g>
                        <path className="st80" d="M1109.42,350.47c0.1-11.56-0.01-22.79-0.32-33.73l-307.84,2.33c1.05,10.6,1.88,21.43,2.58,32.4
                          L1109.42,350.47z"/>
                        <g>
                          <path className="st80" d="M1165.63,375.24c0.16,7.13,0.23,14.39,0.22,21.78l103.49-1c-0.48-7.19-1.04-14.3-1.67-21.33
                            L1165.63,375.24z"/>
                          <path className="st80" d="M978.19,376.26c0.16,7.39,0.23,14.91,0.2,22.58l117.13-1.13c-0.49-7.44-1.07-14.8-1.72-22.07
                            L978.19,376.26z"/>
                          <path className="st80" d="M964.33,376.33l-159.18,0.86c0.29,7.7,0.55,15.42,0.68,23.31l160.27-1.55
                            C965.59,391.32,965,383.79,964.33,376.33z"/>
                          <path className="st80" d="M1281.49,374.61c0.15,6.97,0.22,14.06,0.2,21.28l70.45-0.68c0.85-8.18,1.46-15.23,1.78-20.99
                            L1281.49,374.61z"/>
                          <path className="st80" d="M1151.73,375.32l-44.11,0.24c0.15,7.21,0.22,14.55,0.2,22.03l45.63-0.44
                            C1152.96,389.78,1152.39,382.51,1151.73,375.32z"/>
                        </g>
                      </g>
                      
                      <radialGradient id="SVGID_54_" cx="1048.5354" cy="787.6277" r="13.1251" gradientTransform="matrix(4.0359 -4.0945 4.824 6.6956 -6984.5884 -489.6564)" gradientUnits="userSpaceOnUse">
                        <stop offset="0.096" style={{stopColor:'#8493BA'}}/>
                        <stop offset="0.2117" style={{stopColor:'#606C90'}}/>
                        <stop offset="0.3258" style={{stopColor:'#444D6E'}}/>
                        <stop offset="0.4337" style={{stopColor:'#303756'}}/>
                        <stop offset="0.5332" style={{stopColor:'#232A47'}}/>
                        <stop offset="0.6162" style={{stopColor:'#1F2542'}}/>
                        <stop offset="0.7061" style={{stopColor:'#242A49'}}/>
                        <stop offset="0.8335" style={{stopColor:'#34395B'}}/>
                        <stop offset="0.9827" style={{stopColor:'#4D5179'}}/>
                        <stop offset="0.9848" style={{stopColor:'#4D517A'}}/>
                      </radialGradient>
                      <polygon className="st89" points="1006.74,495.36 980.37,451.65 986.85,447.04 1004.87,476.92 1028.73,435.42 1036.5,443.6"/>
                    </g>
                    
                    {/*
                    <g id="board">
                      <path className="st49" d="M739.52,162.94c89.2,11.81,395.95,48.31,649.51,28.86c11.56-0.89,21.61,7.73,22.24,19.13l51.61,936.52
                        c0.71,12.84-9.24,23.83-22.28,24.61c-106.64,6.33-545.84,30.84-702.52,14.85c-9.54-0.97-16.78-8.87-16.85-18.31l-6.99-983.74
                        C714.15,171.51,726.07,161.16,739.52,162.94z"/>
                      <linearGradient id="SVGID_28_" gradientUnits="userSpaceOnUse" x1="88.1814" y1="4387.3784" x2="827.0141" y2="3297.3406" gradientTransform="matrix(0.9815 -1.131597e-03 1.117704e-03 0.9687 509.9971 -2859.0002)">
                        <stop offset="0" style={{stopColor:'#FE7062'}} />
                        <stop offset="1" style={{stopColor:'#FF928E'}} />
                      </linearGradient>
                      <path className="st50" d="M755.15,198.53c85.55,11.23,379.72,45.93,622.88,27.44c11.09-0.84,20.72,7.35,21.33,18.2l49.48,890.59
                        c0.68,12.21-8.86,22.66-21.36,23.4c-102.27,6.02-523.46,29.34-673.72,14.14c-9.14-0.92-16.09-8.43-16.16-17.41l-6.69-935.48
                        C730.82,206.69,742.25,196.84,755.15,198.53z"/>
                      <path className="st51" d="M755.91,192.91c85.26,11.24,378.43,45.99,620.76,27.47c11.05-0.84,20.65,7.36,21.25,18.22l49.32,891.58
                        c0.68,12.22-8.83,22.69-21.29,23.43c-101.92,6.03-521.68,29.36-671.43,14.15c-9.11-0.93-16.04-8.44-16.1-17.43l-6.68-936.52
                        C731.66,201.07,743.05,191.21,755.91,192.91z"/>
                      <path className="st19" d="M1370.08,294.19c1.96,0,3.46-1.58,3.33-3.51l-0.41-5.95c-0.13-1.93-1.84-3.52-3.81-3.53l-560.03-3.73
                        c-1.96-0.01-2.57,1.21-1.34,2.72l9.61,11.86c1.22,1.51,3.83,2.74,5.79,2.74L1370.08,294.19z"/>
                      <path className="st19" d="M809.21,313.49c-1.96-0.01-2.57,1.21-1.34,2.72l9.61,11.86c1.22,1.51,3.83,2.74,5.79,2.74l546.85-0.6
                        c1.96,0,3.46-1.58,3.33-3.51l-0.41-5.95c-0.13-1.93-1.84-3.52-3.81-3.53L809.21,313.49z"/>
                      <path className="st19" d="M907.98,640.79c-0.07,0-0.13,0-0.2,0.01l-73.49,1.73c-10.59,0.25-19.57-6.64-20.89-16.02l-8.53-60.48
                        c-0.51-3.6,0.51-7.2,2.87-10.16c2.63-3.3,6.7-5.45,11.17-5.9l0,0c6.18-0.62,14.69-1.62,24.06-2.72c20.39-2.39,43.5-5.1,57.11-5.72
                        c0.1,0,0.2-0.01,0.3-0.01c9.74-0.32,18.32,5.25,20.93,13.63c4.31,13.83,4.87,40.23,5.1,68.36
                        C926.48,632.74,918.24,640.45,907.98,640.79z M819.53,555.84c-2.86,0.29-5.44,1.62-7.07,3.67c-1.34,1.68-1.92,3.7-1.64,5.69
                        l8.53,60.48c0.9,6.41,7.27,11.11,14.81,10.93l73.49-1.73c0.05,0,0.1,0,0.14,0c7.02-0.23,12.67-5.29,12.63-11.33
                        c-0.22-27.68-0.76-53.6-4.84-66.68c-1.81-5.79-8.34-9.92-15.22-9.44c-13.39,0.61-37.39,3.42-56.68,5.68
                        C834.28,554.21,825.74,555.22,819.53,555.84L819.53,555.84z"/>
                      <path className="st51" d="M827.62,561.67c15.4-1.55,48.83-6.01,64.73-6.73c6.74-0.31,12.79,3.51,14.56,9.21
                        c3.45,11.08,3.8,33.37,3.97,53.96c0.05,6.16-5.53,11.27-12.54,11.44l-58.73,1.38c-7.22,0.17-13.38-4.48-14.27-10.77l-6.81-48.33
                        C817.83,566.83,821.92,562.24,827.62,561.67z"/>
                      <path className="st19" d="M967.86,575.7c-1.96,0.02-3.11,1.55-2.54,3.4l8.86,29.1c0.56,1.85,2.63,3.31,4.59,3.25l374.83-12.37
                        c1.96-0.06,3.48-1.7,3.37-3.62l-1.12-19.95c-0.11-1.93-1.8-3.49-3.76-3.47L967.86,575.7z"/>
                      <path className="st52" d="M831.08,571.09L831.08,571.09c5.35-3.33,12.43-1.68,15.7,3.65l15.36,25.1c1.54,2.51,5.33,2.23,6.46-0.49
                        l33.26-79.97c1.66-3.99,7.65-2.86,7.73,1.45c0.45,24.11-4.48,70.14-42.28,109.77c0,0-21.83-7.54-40.46-44.49
                        C824.14,580.77,825.96,574.27,831.08,571.09z"/>
                      <path className="st19" d="M909.29,478.23c-0.07,0-0.13,0.01-0.2,0.01l-73.49,1.73c-10.59,0.25-19.57-6.64-20.89-16.02l-8.53-60.48
                        c-0.51-3.6,0.51-7.2,2.86-10.16c2.63-3.3,6.7-5.45,11.17-5.9l0,0c6.18-0.62,14.69-1.62,24.06-2.72c20.39-2.39,43.5-5.1,57.11-5.72
                        c0.1,0,0.2-0.01,0.3-0.01c9.74-0.32,18.32,5.25,20.93,13.63c4.31,13.83,4.87,40.23,5.1,68.36
                        C927.78,470.18,919.54,477.89,909.29,478.23z M820.83,393.28c-2.86,0.29-5.44,1.62-7.07,3.67c-1.34,1.68-1.92,3.7-1.64,5.69
                        l8.53,60.48c0.9,6.41,7.27,11.11,14.81,10.93l73.49-1.73c0.05,0,0.1,0,0.14,0c7.02-0.23,12.67-5.29,12.63-11.33
                        c-0.22-27.68-0.76-53.6-4.84-66.68c-1.81-5.79-8.34-9.92-15.22-9.44c-13.39,0.61-37.39,3.42-56.68,5.68
                        C835.58,391.66,827.04,392.66,820.83,393.28L820.83,393.28z"/>
                      <path className="st51" d="M828.93,399.11c15.4-1.55,48.83-6.01,64.73-6.73c6.74-0.31,12.79,3.51,14.56,9.21
                        c3.45,11.08,3.8,33.37,3.97,53.96c0.05,6.16-5.53,11.27-12.54,11.44l-58.73,1.38c-7.22,0.17-13.38-4.48-14.27-10.77l-6.81-48.33
                        C819.13,404.27,823.22,399.69,828.93,399.11z"/>
                      <path className="st19" d="M969.17,413.14c-1.96,0.02-3.11,1.55-2.54,3.4l8.86,29.1c0.56,1.85,2.63,3.31,4.59,3.25l374.83-12.37
                        c1.96-0.06,3.48-1.7,3.37-3.63l-1.12-19.95c-0.11-1.93-1.8-3.49-3.76-3.47L969.17,413.14z"/>
                      <path className="st53" d="M832.38,408.53L832.38,408.53c5.35-3.33,12.43-1.68,15.69,3.65l15.36,25.1c1.54,2.51,5.33,2.23,6.46-0.49
                        l33.26-79.97c1.66-3.99,7.65-2.86,7.73,1.45c0.45,24.11-4.48,70.14-42.28,109.77c0,0-21.83-7.54-40.46-44.49
                        C825.44,418.21,827.26,411.72,832.38,408.53z"/>
                      <path className="st19" d="M913.26,795.83c-0.07,0-0.13,0-0.2,0.01l-73.49,1.73c-10.59,0.25-19.57-6.64-20.89-16.02l-8.52-60.48
                        c-0.51-3.6,0.51-7.21,2.86-10.16c2.63-3.3,6.7-5.45,11.17-5.9l0,0c6.18-0.62,14.69-1.62,24.06-2.72
                        c20.39-2.39,43.5-5.1,57.11-5.72c0.1,0,0.2-0.01,0.3-0.01c9.74-0.32,18.32,5.25,20.93,13.63c4.31,13.83,4.87,40.23,5.1,68.36
                        C931.76,787.78,923.51,795.49,913.26,795.83z M824.8,710.88c-2.86,0.29-5.44,1.62-7.07,3.67c-1.34,1.68-1.92,3.7-1.64,5.7
                        l8.53,60.48c0.9,6.41,7.27,11.11,14.81,10.93l73.49-1.73c0.05,0,0.1,0,0.14,0c7.02-0.23,12.67-5.29,12.63-11.33
                        c-0.22-27.68-0.76-53.6-4.83-66.68c-1.81-5.79-8.34-9.84-15.22-9.45c-13.39,0.61-37.39,3.42-56.68,5.68
                        C839.55,709.25,831.01,710.25,824.8,710.88L824.8,710.88z"/>
                      <path className="st51" d="M832.9,716.71c15.4-1.55,48.83-6.01,64.73-6.73c6.74-0.3,12.79,3.51,14.56,9.21
                        c3.45,11.08,3.8,33.37,3.97,53.96c0.05,6.16-5.53,11.27-12.54,11.44l-58.73,1.38c-7.22,0.17-13.38-4.48-14.27-10.77l-6.81-48.33
                        C823.11,721.87,827.19,717.28,832.9,716.71z"/>
                      <path className="st19" d="M973.14,730.74c-1.96,0.02-3.11,1.55-2.54,3.4l8.86,29.1c0.56,1.85,2.63,3.31,4.59,3.25l374.83-12.37
                        c1.96-0.06,3.48-1.7,3.37-3.63l-1.12-19.95c-0.11-1.93-1.8-3.49-3.76-3.47L973.14,730.74z"/>
                      <path className="st54" d="M836.35,726.13L836.35,726.13c5.35-3.33,12.43-1.68,15.69,3.65l15.36,25.1c1.54,2.51,5.33,2.23,6.46-0.49
                        l33.26-79.97c1.66-3.99,7.65-2.86,7.73,1.45c0.45,24.11-4.48,70.14-42.28,109.77c0,0-21.83-7.54-40.46-44.49
                        C829.41,735.81,831.24,729.31,836.35,726.13z"/>
                      <path className="st19" d="M911.45,944.93c-0.06,0-0.13,0-0.19,0.01l-73.49,1.73c-10.59,0.25-19.57-6.64-20.89-16.02l-8.52-60.48
                        c-0.51-3.6,0.51-7.21,2.86-10.16c2.63-3.3,6.7-5.45,11.18-5.9l0,0c6.18-0.62,14.68-1.62,24.03-2.71
                        c19.39-2.27,43.52-5.1,57.13-5.72c9.66-0.37,18.59,5.15,21.23,13.61c4.31,13.83,4.87,40.23,5.1,68.36
                        C929.95,936.88,921.7,944.59,911.45,944.93z M822.99,859.98c-2.86,0.29-5.44,1.62-7.07,3.67c-1.34,1.68-1.92,3.7-1.64,5.69
                        l8.52,60.48c0.9,6.41,7.27,11.11,14.81,10.93l73.49-1.73c6.92-0.35,12.82-5.26,12.77-11.34c-0.22-27.68-0.76-53.6-4.84-66.68
                        c-1.81-5.79-8.33-9.77-15.22-9.44c-13.4,0.6-37.41,3.42-56.7,5.68C837.73,858.36,829.2,859.36,822.99,859.98L822.99,859.98z"/>
                      <path className="st51" d="M831.09,865.81c15.4-1.55,48.83-6.01,64.73-6.73c6.74-0.31,12.79,3.51,14.56,9.21
                        c3.45,11.08,3.8,33.37,3.97,53.96c0.05,6.16-5.53,11.27-12.54,11.43l-58.73,1.38c-7.22,0.17-13.38-4.48-14.27-10.77L822,875.97
                        C821.3,870.97,825.38,866.39,831.09,865.81z"/>
                      <path className="st19" d="M971.33,879.84c-1.96,0.02-3.11,1.55-2.54,3.4l8.86,29.1c0.56,1.85,2.63,3.31,4.59,3.25l374.83-12.36
                        c1.96-0.06,3.48-1.7,3.37-3.63l-1.12-19.95c-0.11-1.93-1.8-3.49-3.76-3.47L971.33,879.84z"/>
                      <path className="st52" d="M834.54,875.23L834.54,875.23c5.35-3.33,12.43-1.68,15.7,3.65l15.36,25.1c1.54,2.51,5.33,2.23,6.45-0.49
                        l33.26-79.97c1.66-3.99,7.65-2.86,7.73,1.45c0.45,24.11-4.48,70.13-42.28,109.77c0,0-21.83-7.54-40.46-44.49
                        C827.61,884.91,829.43,878.41,834.54,875.23z"/>
                      <path className="st19" d="M908.33,1098.73c-0.07,0-0.13,0-0.2,0.01l-73.49,1.73c-10.59,0.25-19.57-6.64-20.89-16.02l-8.52-60.48
                        c-0.51-3.6,0.51-7.2,2.86-10.16c2.63-3.3,6.7-5.45,11.17-5.9l0,0c6.18-0.62,14.69-1.62,24.06-2.72c20.39-2.39,43.5-5.1,57.11-5.72
                        c9.98-0.2,18.59,5.15,21.23,13.61c4.31,13.83,4.87,40.23,5.1,68.36C926.83,1090.68,918.58,1098.4,908.33,1098.73z M819.87,1013.79
                        c-2.86,0.29-5.44,1.62-7.07,3.67c-1.34,1.68-1.92,3.7-1.64,5.7l8.53,60.48c0.9,6.41,7.27,11.11,14.81,10.93l73.49-1.73
                        c0.05,0,0.1,0,0.14-0.01c7.02-0.23,12.67-5.29,12.63-11.33c-0.22-27.68-0.76-53.6-4.84-66.68c-1.81-5.79-8.33-9.69-15.22-9.44
                        c-13.39,0.61-37.39,3.42-56.68,5.68C834.63,1012.16,826.08,1013.16,819.87,1013.79L819.87,1013.79z"/>
                      <path className="st51" d="M827.97,1019.62c15.4-1.55,48.83-6.01,64.73-6.73c6.74-0.31,12.79,3.51,14.56,9.21
                        c3.45,11.08,3.8,33.37,3.97,53.96c0.05,6.16-5.53,11.27-12.54,11.44l-58.73,1.38c-7.22,0.17-13.38-4.48-14.27-10.77l-6.81-48.33
                        C818.18,1024.77,822.26,1020.19,827.97,1019.62z"/>
                      <path className="st19" d="M968.21,1033.65c-1.96,0.02-3.11,1.55-2.54,3.4l8.86,29.1c0.56,1.85,2.63,3.31,4.59,3.25l374.83-12.37
                        c1.96-0.06,3.48-1.7,3.37-3.63l-1.12-19.95c-0.11-1.93-1.8-3.49-3.76-3.47L968.21,1033.65z"/>
                      <path className="st53" d="M831.42,1029.03L831.42,1029.03c5.35-3.33,12.43-1.68,15.7,3.65l15.36,25.1c1.54,2.51,5.33,2.23,6.45-0.49
                        l33.26-79.97c1.66-3.99,7.65-2.86,7.73,1.45c0.45,24.11-4.48,70.14-42.28,109.77c0,0-21.83-7.54-40.46-44.49
                        C824.48,1038.72,826.31,1032.22,831.42,1029.03z"/>
                    </g>
                    */}
                    <g id="clip">
                      <path className="st55" d="M1095.15,44.9c-23.2-15.83-43.25-15.33-57.32-11.04c-12.84,3.91-21.07,16.42-19.73,29.78l6.68,66.38
                        l72.48,5.77C1097.27,135.78,1140.65,75.95,1095.15,44.9z M1063.79,98.78c-9.97,0.09-18.13-7.92-18.22-17.9
                        c-0.09-9.97,7.92-18.13,17.9-18.22c9.97-0.09,18.13,7.92,18.22,17.9C1081.77,90.53,1073.76,98.69,1063.79,98.78z"/>
                      <path className="st56" d="M1030.55,103.63c-1.26-0.45-2.22-1.6-2.37-3.02l-1.06-10.57c-0.2-1.97,1.24-3.73,3.21-3.93
                        c1.97-0.2,3.73,1.23,3.93,3.21l1.06,10.57c0.2,1.97-1.24,3.73-3.21,3.93C1031.57,103.87,1031.04,103.8,1030.55,103.63z
                        M1028.47,82.91c-1.26-0.45-2.22-1.59-2.37-3.02l-0.81-8.03c-1.37-13.6,7.08-26.34,20.09-30.31c8.91-2.72,18.11-3.18,27.35-1.37
                        c1.91,0.41,3.21,2.26,2.83,4.21c-0.38,1.94-2.26,3.21-4.21,2.83c-8.07-1.58-16.11-1.18-23.88,1.19
                        c-9.74,2.97-16.07,12.53-15.05,22.73l0.81,8.03c0.2,1.97-1.24,3.73-3.21,3.93C1029.49,83.16,1028.95,83.09,1028.47,82.91z"/>
                      <path className="st55" d="M926.33,173.83l-0.3-33.4c-0.1-11.53,9.37-20.85,20.89-20.56l230.35,5.81c10.22,0.26,18.57,8.25,19.28,18.45
                        l2.21,32.07L926.33,173.83z"/>
                      <path className="st56" d="M1001.9,130.21l9.9-0.09L1001.9,130.21z"/>
                      <path className="st56" d="M937.48,164.86c-1.38-0.49-2.37-1.8-2.38-3.35L934.98,148c-0.05-5.55,2.06-10.78,5.95-14.74
                        c3.89-3.96,9.09-6.17,14.63-6.22l36.41-0.33c1.97-0.01,3.6,1.57,3.62,3.56c0.02,1.98-1.57,3.6-3.56,3.62l-36.41,0.33
                        c-7.5,0.07-13.54,6.22-13.47,13.72l0.12,13.51c0.02,1.98-1.57,3.6-3.56,3.62C938.28,165.07,937.87,165,937.48,164.86z"/>
                      <path className="st55" d="M854.07,162.26l402.68-3.64c14.48-0.13,26.95,10.18,29.55,24.42l0.36,1.97c2.85,15.6-9.06,30-24.92,30.15
                        l-413.13,3.72c-9.49,0.09-17.13-7.78-16.76-17.27l0.72-18.5C833.03,171.54,842.49,162.36,854.07,162.26z"/>
                    </g>
                  </g>
                </svg>
              </div>

              <div className="pencil-container">
                <svg 
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" y="0px"
                  viewBox="0 0 2550 1720"
                >
                  <g id="pencil">
                    <path className="st57" d="M1065.14,996.38l250.62-579.71l85.46,36.55l-246.15,583.59c-5.23,12.4-18.59,19.22-31.71,16.26
                      c-13.48-3.05-31.66-10.9-52.39-29.65C1063.44,1016.6,1061.11,1005.71,1065.14,996.38z"/>
                    <path className="st58" d="M1424.67,266.54l-48.63,67.03c8.34,7.65,21.58,14.91,42.41,17.17l13.64-81.1
                      C1432.81,265.34,1427.23,263,1424.67,266.54z"/>
                    <linearGradient id="SVGID_29_" gradientUnits="userSpaceOnUse" x1="1153.3971" y1="393.4354" x2="1257.865" y2="393.4354">
                      <stop offset="0" style={{stopColor:'#FF928E'}} />
                      <stop offset="0.0363" style={{stopColor:'#FF938F'}} />
                      <stop offset="1" style={{stopColor:'#FEB3B1'}} />
                    </linearGradient>
                    <path className="st59" d="M1315.76,416.67c37.54,40.1,85.46,36.55,85.46,36.55l17.23-102.48c-20.83-2.27-34.07-9.53-42.41-17.17
                      L1315.76,416.67z"/>
                    <path className="st60" d="M1089.8,1009.25c-0.49,0.53-1.28,0.72-1.98,0.41c-0.89-0.38-1.29-1.41-0.91-2.3l240.71-553.76
                      c0.39-0.89,1.42-1.29,2.3-0.91c0.89,0.38,1.29,1.42,0.91,2.3l-240.71,553.76C1090.04,1008.94,1089.93,1009.11,1089.8,1009.25z"/>
                    <path className="st60" d="M1121.6,1025.44c-0.49,0.53-1.28,0.72-1.98,0.41c-0.89-0.38-1.29-1.42-0.91-2.3l240.71-553.76
                      c0.39-0.89,1.41-1.29,2.3-0.91c0.89,0.38,1.29,1.41,0.91,2.3l-240.71,553.76C1121.84,1025.14,1121.73,1025.3,1121.6,1025.44z"/>
                  </g>
                </svg>
              </div>

              <div className="coffee-mug-container">
                <svg 
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" y="0px"
                  viewBox="0 0 2550 1720"
                >
                  <g id="coffee-mug">
                    <path className="st61" d="M2425.92,1370.4c23.88-10.3,52.98-3.54,67.7,20.9c12.63,20.96,15.27,43.62,11.08,67.23
                      c-9.22,51.99-41.27,85.98-95.56,84.92c-11.98-0.23-11.59-0.23-15.57,11.82c-7.89,23.9-15.35,48-24.63,71.33
                      c-11.12,27.95-30.09,47.73-59.99,54.38c-25.08,5.58-50.25,5.22-75.4,1.67c-20.55-2.9-38.84-11.33-52.94-27.64
                      c-13.04-15.07-21.32-33.04-25.7-52.39c-5.83-25.77-11.12-51.8-14.46-78.02c-4.35-34.12-6.7-68.52-9.63-102.83
                      c-1.02-11.92-0.91-23.94-1.8-35.88c-2.61-35.24-3.53-75.08,1.03-110.02c43.28,21.44,89.74,28.51,136.68,29.22
                      c47.4,0.72,94.66-5.77,140.19-26.68c0,32.13-0.15,68.32-0.15,101.16C2413.72,1376.24,2419.72,1373.08,2425.92,1370.4z
                      M2471.26,1452.09c0.2-1.07,0.25-2.17,0.36-3.26c1.66-16.82-1.15-31.89-16.67-41.09c-13.33-7.9-32.58-4.88-45.76,7.12
                      c-2.47,2.25-4.45,6.19-4.85,9.58c-2.87,24.39-5.19,48.85-7.89,73.26c-0.49,4.47,0.81,6.77,5.1,7.3c4.98,0.61,9.96,1.27,14.94,1.9
                      C2447.75,1506.56,2464.8,1487.5,2471.26,1452.09z"/>
                    <path className="st62" d="M2275.66,1305.54c-32.41,1.12-65.43-3.8-97.71-8.76c-14.57-2.24-28.77-8.46-42.55-14.33
                      c-7.88-3.35-8.24-11.78-1.73-18.07c2.94-2.84,6.82-5.34,10.67-6.32c14.8-3.74,29.64-7.62,44.68-9.94
                      c29.33-4.53,58.77-5.33,88.54-3.9c26.46,1.27,52.76,2.72,78.9,7.07c15.92,2.65,31.56,6.23,45.49,15.09
                      c7.53,4.79,7.25,14.62-0.78,18.35c-8.93,4.15-18.07,8.39-27.55,10.61c-32.24,7.53-64.99,10.83-98.07,10.14
                      C2275.48,1306.28,2275.74,1304.75,2275.66,1305.54z M2372.6,1266.55c-23.17-3.69-46.33-7.74-69.65-9.97
                      c-18.85-1.81-37.95-2.24-56.88-1.46c-24.2,1-48.34,3.62-72.46,6.05c-5.11,0.51-10.16,2.9-14.96,5.06c-2.1,0.94-3.53,3.49-5.26,5.31
                      c1.68,1.57,3.12,3.99,5.07,4.56c11.9,3.45,23.77,8.35,35.91,9.3c34.17,2.66,68.47,5,102.71,4.79c22.77-0.14,45.56-4.47,68.22-7.79
                      c6.06-0.89,11.68-4.93,17.5-7.53c-0.06-1.01-0.11-2.02-0.17-3.02C2379.29,1270.01,2376.12,1267.11,2372.6,1266.55z"/>
                    <path className="st63" d="M2382.23,1269.2c-1.76-0.93-2.31-1.24-7.38-2.85c-22.19-7.02-45.86-8.06-68.96-9.76
                      c-40.03-2.95-80.16-2.54-120.03,3.04c-8.27,1.16-24.59,1.61-32.29,6.81c-0.61,0.41-1.25,0.78-1.84,1.36
                      c-1.8,1.77-1.83,4.9-0.06,6.7c0.59,0.6,1.19,1.11,1.85,1.42c12.12,5.84,32.3,8.11,44.66,9.45c34.59,3.76,67.98,4.93,101.97,4.74
                      c22.52-0.13,45.08-4.36,67.5-7.64c6.2-0.91,13.78-3.17,17.97-7.41C2387.2,1271.74,2383.5,1270.05,2382.23,1269.2z"/>
                  </g>
                  <g id="hot-fumes">
                    <path className="st60" d="M2282.25,991.25c-2.35,4.4-4.76,8.76-7.04,13.2c-8.52,16.61-8.45,33.36,1.23,49.14
                      c8.03,13.09,17.36,25.34,26.29,37.82c11.57,16.15,20.9,33.17,23.87,53.54c3.37,23.13-4.02,42.12-19.72,57.94
                      c-6.12,6.17-13.47,11.05-20.26,16.51c-0.36-0.4-0.71-0.8-1.07-1.2c2.79-7.3,5.69-14.55,8.33-21.91c7.3-20.36,5.69-40.18-4.95-58.74
                      c-7.45-12.98-15.88-25.37-24.13-37.85c-9.1-13.76-17.03-27.98-20.18-44.64c-4.09-21.66,2.23-39.38,18.71-53.09
                      c5.56-4.63,11.96-8.18,17.98-12.23C2281.63,990.24,2281.94,990.75,2282.25,991.25z"/>
                    <path className="st60" d="M2343.82,1172.43c2.41-8.81,5.58-17.45,7.01-26.38c2.04-12.77-2.23-24.35-9.14-34.88
                      c-6.35-9.68-13.23-19.01-19.16-28.95c-7.32-12.26-12.93-25.27-9.42-40.31c3.27-13.96,12.77-21.99,24.45-27.95
                      c-2.58,7.99-6.13,15.4-7.24,23.18c-1.81,12.76,5.69,22.48,12.3,32.33c8.5,12.66,18.42,24.74,24.54,38.58
                      C2378.61,1133.95,2367.57,1161.38,2343.82,1172.43z"/>
                    <path className="st60" d="M2230.41,1054.65c-2.27,7.81-5.63,15.14-6.33,22.73c-1.28,14.02,7.21,24.31,15.33,34.21
                      c10.91,13.29,21.88,26.31,27.04,43.54c6.61,22.06-4,47.57-24.52,57.37c2.13-7.86,4.88-15.68,6.31-23.75
                      c2.89-16.29-3.96-29.73-13.07-42.23c-9.23-12.66-19.67-24.44-25.49-39.49C2201.3,1085.31,2209.71,1063.81,2230.41,1054.65z"/>
                  </g>
                </svg>
              </div>

              <div className="book-container">
                <svg 
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" y="0px"
                  viewBox="0 0 2550 1720"
                >
                  <g id="book">
                    <path className="st19" d="M729.61,1090.38c0.02-0.17,1.67-17.72-37.75-40.55c-9.63-5.58-22.43-14.21-37.26-24.21
                      c-49.95-33.68-125.44-84.59-171.18-78.29l-0.46-7.03c48.01-6.61,124.62,45.06,175.32,79.24c14.73,9.94,27.46,18.51,36.91,23.99
                      c43.39,25.12,41.25,45.44,40.86,47.67l-6.43-0.92l3.21,0.46L729.61,1090.38z"/>
                    <path className="st64" d="M389,955.34l-101.05,459.42c-3.96,17.98,4.35,35.75,19.76,42.26l292.79,123.7c2.98,1.26,6.13,2.05,9.35,2.35
                      l63.95,5.94c6.76,0.63,13.65-0.91,19.82-4.43L880.65,1478c9.5-5.41,16.49-15.03,19.04-26.21l81.61-357.07
                      c6.62-28.95-18.09-52.9-44.12-42.77l-151.64,59.03c-4.14,1.61-8.48,2.39-12.79,2.3l-66.08-1.36c-6.41-0.13-12.57-2.18-17.8-5.93
                      L443.45,930.16C423.26,915.7,394.83,928.85,389,955.34z"/>
                    <path className="st65" d="M696.86,1111.72l82.41,1.7l-95.12,476.54c0,0-28.52,26-79.17-7.35L696.86,1111.72z"/>
                    <path className="st19" d="M461.45,943.06c0,0,29.09-10.66,79.44,18.24c50.35,28.89,122.43,80.51,153.55,94.95
                      c31.12,14.44,38.4,34.51,38.4,34.51s-0.99-30.54,42.07-33.28c43.06-2.74,127.25-24.88,145.01,1.19l-140.65,54.75l-82.41-1.7
                      L461.45,943.06z"/>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

    {showResourcesEmbedModal && 
      <ModalPortal showModal={showResourcesEmbedModal}>
        <ResourcesEmbedModal 
          setShowResourcesEmbedModal={setShowResourcesEmbedModal} 
          modalName={modalName}  
        />
      </ModalPortal>
    }

    {showResourcesModal && 
      <ModalPortal showModal={showResourcesModal}>
        <ResourcesModal 
          setShowResourcesModal={setShowResourcesModal}
          modalTitle={modalTitle}   
          modalCopy={modalCopy} 
        />
      </ModalPortal>
    }

    </div>
  )
}

export default EmployeeResources;
