import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchArticle, fetchArticles, deleteArticle, updateArticle } from './articlesAPI';
// import getMapCoordinates from '../../utilities/getMapCoordinates';

const initialState = {
  article: {},
  articles: [],
  articlesLoaded: false,
  status: 'idle'
};

export const getArticleAsync = createAsyncThunk(
  'articles/fetchArticle',
  async (articleId) => {
    const response = await fetchArticle(articleId);
    return response;
  }
);

export const getArticlesAsync = createAsyncThunk(
  'articles/fetchArticles',
  async () => {
    const response = await fetchArticles();
    return response;
  }
);

export const deleteArticleAsync = createAsyncThunk(
  'articles/deleteArticleAsync',
  async (payload) => {
      const response = await deleteArticle(payload);
      // The value we return becomes the `fulfilled` action payload
      return response;
  }  
);

export const updateArticleAsync = createAsyncThunk(
  'articles/updateArticle',
  async (payload) => {
      const response = await updateArticle(payload);
      return response;
  }  
);

export const articlesSlice = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    /*
    setCoordinates: (state, action) => {
      action.payload.forEach((coordinates, index) => {
        state.offices[index].coordinates = coordinates;
      })
    },
    */
  },

  extraReducers: (builder) => {
    builder
      .addCase(getArticlesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getArticlesAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.articles = action.payload?.articles;
        state.articlesLoaded = true;
      })
      .addCase(getArticleAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getArticleAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.article = action.payload?.article;
      })
      .addCase(deleteArticleAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteArticleAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.articles = state.articles.filter(article => article.articleId !== action.payload.articleId);
      })
      .addCase(updateArticleAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateArticleAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        if (action.payload?.article) {
          state.articles = (state.articles.find(article => article.articleId === action.payload.articleId))
            ? state.articles.map(article => article.articleId===action.payload.articleId ? action.payload.article : article) // updated
            : [...state.articles, action.payload.article]; // added
        }
      })
  },
});

// export const { setCoordinates } = articlesSlice.actions;

export const selectArticle = (state) => state.articles.article;
export const selectArticles = (state) => state.articles.articles;
export const selectArticlesStatus = (state) => state.articles.status;

export default articlesSlice.reducer;
