import React from 'react';
import './Clouds2.scss';

function Clouds() {

  return (
    <div className="clouds-wrapper"> 

      <div className="x1">
          <div className="cloud"></div>
      </div>

      <div className="x2">
          <div className="cloud"></div>
      </div>

      <div className="x3">
          <div className="cloud"></div>
      </div>
{/* 
      <div className="x4">
          <div className="cloud"></div>
      </div>

      <div className="x5">
          <div className="cloud"></div>
      </div>
*/}
    </div>
  );
}

export default Clouds;