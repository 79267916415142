import React, { useState, useEffect } from 'react';
// import { setCoordinates } from '../../features/booking/bookingSlice';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api'; // LoadScript // InfoBox
// import getMapCoordinates from '../../utilities/getMapCoordinates';
import Loader from '../Loader/Loader';
import MapIcon from '../../images/map-marker.png';
import './Map.scss';

const mapDirectionsZoom = 15;
/*
JSON CODE TO IMPORT TO GOOGLE CONSOLE:

[
    {
        "featureType": "all",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "hue": "#7f1417"
            }
        ]
    }
]

*/

function Map({ mapLocations, mapZoom, directionLinks, setDirectionLinks }) {

  const mapId = process.env.REACT_APP_GOOGLE_MAP_ID; // To associate with particular map style in Google Console

  const [mapCenter, setMapCenter] = useState();

  const getCenterCoordinates = (mapLocations) => {   
    const lat = mapLocations.map(location => location.coordinates.lat).reduce((previousValue, currentValue) => previousValue+currentValue)/mapLocations.length;
    const lng = mapLocations.map(location => location.coordinates.lng).reduce((previousValue, currentValue) => previousValue+currentValue)/mapLocations.length;
    return {lat, lng};
  };

  const { isLoaded, loadError } = useLoadScript({
    mapIds: [mapId], 
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
  });


  useEffect(()=> {
    if (loadError) console.log('Error loading Maps');
  }, [loadError]);

 /*
  const [coordinates, setCoordinates] = useState();

  useEffect(()=> {
    if (!addresses || addresses.length===0) return;
    try {
      Promise.all(addresses.map(address => getMapCoordinates(address))).then(coordinates => {
        setCoordinates(coordinates);
        const mapCenter = getCenterCoordinates(coordinates);
        setMapCenter(mapCenter);
      });
    } catch (error) {
      console.log("Get map coordinates error: "+error);
    }
  },[addresses, dispatch])
  */


  useEffect(()=> {
    if (mapCenter || !mapLocations || mapLocations.length===0) return;
    const newMapCenter = getCenterCoordinates(mapLocations);
    setMapCenter(newMapCenter);
  }, [mapLocations, mapCenter])


  useEffect(()=> {
    if (directionLinks.length>0 || !mapLocations || mapLocations.length===0) return;
    const newDirectionLinks = mapLocations.map((position, index) => (
      'https://maps.google.com/maps?ll='+position.lat+','+position.lng+
          '&z='+mapDirectionsZoom+'&t=m&hl=en-US&gl=US&mapclient=embed&daddr='+
          encodeURIComponent(mapLocations[index].address) // +'@'+mapCenter.lat+','+mapCenter.lng
    ));
    setDirectionLinks(newDirectionLinks);
  }, [mapLocations, directionLinks, setDirectionLinks]);

  /*
  const [map, setMap] = useState(null);

  const onLoadMap = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
   // map.fitBounds(bounds);
    setMap(map)
  }, [])
 
  const onUnmountMap = useCallback(function callback(map) {
    setMap(null)
  }, [])
  */
 
  return !isLoaded || !mapLocations || mapLocations.length===0 || !mapCenter || !mapCenter.lat || !mapCenter.lng 
    ? <div className="loader" >
        {/* <div className="dot-flashing"></div> */}
        <Loader />
      </div>
    : <GoogleMap
        mapContainerStyle={{
          width: '100%', 
          height: '100%', 
          borderRadius: 10
        }}
        options={{
          mapId: mapId,
          mapTypeControl: false,
          streetViewControl: false,
        }}
        address={mapLocations.length === 1 ? mapLocations[0].address : null}
        center={mapCenter}
        zoom={mapZoom}
        clickableIcons={true}

        // onLoad={onLoadMap}
        // onUnmount={onUnmountMap}
      >
        { 
          mapLocations && 
          mapLocations.length>0 && 
          mapLocations.map((location, index) => (
            <Marker 
              key={'marker-'+index}
              position={location.coordinates} 
              title={location.address} 
              icon={MapIcon} 
            />
          ))
        }

{/*
          <div className="placeDiv">
            <div className="placecard__container">

              <div className="placecard__left">
                <p className="placecard__business-name">{mapHeading || addresses[0]}</p>
                
                  {mapHeading &&
                    <p className="placecard__info">
                      {addresses[0]}
                    </p>
                  }
                
                <a className="placecard__view-large"
                  target="_blank" 
                  rel="noopener noreferrer"
                  href={'https://maps.google.com/maps?ll='+mapCenter.lat+','+mapCenter.lng+'&z='+mapZoom+'&t=m&hl=en-US&gl=US&mapclient=embed' // &q='+encodeURI(addresses[0])
                } 
                >
                    View larger map
                </a>
              </div> 

              <div className="placecard__right">
                  <a 
                    className="placecard__direction-link" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    href={
                      'https://maps.google.com/maps?ll='+mapCenter.lat+','+mapCenter.lng+
                      '&z='+mapZoom+'&t=m&hl=en-US&gl=US&mapclient=embed&daddr='+
                      encodeURIComponent(addresses[0])} // +'@'+mapCenter.lat+','+mapCenter.lng
                  >
                    {/* 
                      href={'https://www.google.com/maps/dir//'+encodeURI(mapAddress)+'/@'+mapCenter.lat+','+mapCenter.lng+','+mapZoom+'z/'} 
                    */}
{/*
                      <div className="placecard__direction-icon"></div>
                      Directions
                  </a>
              </div>

            </div>
          </div> 
*/}
      </GoogleMap>

}

export default Map;