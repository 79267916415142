
import React, { useState } from 'react';
import './ToggleSwitchLabeled.scss';


const ToggleSwitchLabeled = ({ labels, isLeft, setIsLeft, transitionDuration }) => {

  const handleChange = () => setIsLeft(!isLeft);
  const [hover, setHover] = useState('');

  return (
    <div
      className={`toggle-switch-labeled-container ${
        (hover==='left' && isLeft===false) || 
        (hover==='right' && isLeft===true) 
          ? 'hover' 
          : ''
        }`
      }
      transitionDuration={transitionDuration}
    > 
      <input 
        id="input-left" 
        className="toggle-left" 
        name="toggle" 
        value={true} 
        type="radio" 
        checked={isLeft===true} 
        onChange={handleChange}
      />
      <label 
        htmlFor="input-left" 
        onMouseOver={() => setHover('left')} 
        onMouseOut={() => setHover('')}
      >
        {labels.left}
      </label>
      <input 
        id="input-right" 
        className="toggle-right" 
        name="toggle" 
        value={false} 
        type="radio" 
        checked={isLeft===false}
        onChange={handleChange}
      />
      <label 
        htmlFor="input-right" 
        onMouseOver={() => setHover('right')} 
        onMouseOut={() => setHover('')}
      >
        {labels.right}
      </label>
    </div>
  )
}

export default ToggleSwitchLabeled;

