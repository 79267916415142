import React, { useState, useEffect, useRef, useCallback } from 'react';
// import { Link } from 'react-router-dom';  
import ReactHtmlParser from 'react-html-parser';
import './OurTeam.scss';
import './Gallery.scss';
// import useIsIntersecting from '../../hooks/useIsIntersecting';
// import useIsMobile from '../../hooks/useIsMobile';
import useScreenWidth from '../../hooks/useScreenWidth';
// import anime from 'animejs';
// import jQuery from 'jquery';
// window.jQuery = jQuery;
import SlideNavigation from './SlideNavigation';
import ModalPortal from '../ModalPortal/ModalPortal';
import DirectoryModal from './DirectoryModal';

// import data from '../../translations/es.json';

function OurTeam({ ourTeam, general, language }) {

  const { profiles } = ourTeam;

  //const {  } = props;
  
  const [profileIndex, setProfileIndex] = useState(0);
  const [nextProfileIndex, setNextProfileIndex] = useState(-1);
  const [notepadPage, setNotepadPage] = useState(1);
  const [exitProfileIndex, setExitProfileIndex] = useState(-1);

  const photoRefs = useRef([]);

  useEffect(() => {
    if (profiles[profileIndex]) photoRefs.current = photoRefs.current.slice(0, profiles[profileIndex].images.length); 
  }, [profileIndex, profiles]); // photos

  /*
  useEffect(() => {
    var textWrapper = document.querySelector('.domino-text-wrapper .letters');
    textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

    anime.timeline({loop: true})
      .add({
        targets: '.domino-text-wrapper .letter',
        rotateY: [-90, 0],
        duration: 1300,
        delay: (el, i) => 45 * i
      }).add({
        targets: '.notepad-content',
        opacity: 0,
        duration: 1000,
        easing: "easeOutExpo",
        delay: 1000
      });
  }, []);
*/

  

  const select = (selector) => {
    var method = selector.substr(0,1) === '.' ? 'getElementsByClassName' : 'getElementById';
    return document[method](selector.substr(1));
  }

  const range = useCallback(() => {
    var range = {left : {x : [], y : []}, right : {x : [], y : []}};
    var wrap = {
      w : select("#wrap").clientWidth,
      h : select("#wrap").clientHeight
    }
    var photo = {
      w : select(".photo")[0].clientWidth,
      h : select(".photo")[0].clientHeight
    }
    range.wrap = wrap;
    range.photo = photo;

    range.left.x = [0, wrap.w/2 - photo.w/2];
    range.left.y = [0, wrap.h - photo.w/2];
    range.right.x = [wrap.w/2 + photo.w/2, wrap.w];
    range.right.y = [0, wrap.h - photo.w/2];

    return range;
  },[]);

  const sort = useCallback((n) => {
    var _photo = select('.photo');
    var photos = [];
    for(var i=0; i<_photo.length; i++) {
      _photo[i].className = _photo[i].className.replace(/\s*photo_center\s*/, ' ');
      _photo[i].className = _photo[i].className.replace(/\s*photo_front\s*/, ' ');
      _photo[i].className = _photo[i].className.replace(/\s*photo_back\s*/, ' ');
      _photo[i].className += ' photo_front';
      _photo[i].style.left = '';
      _photo[i].style.top = '';
      _photo[i].style['transform'] = 'rotate(360deg) scale(1.3)';
      _photo[i].style['-webkit-transform'] = 'rotate(360deg) scale(1.3)';
      photos.push(_photo[i]);
    }
    var photo_center = select('#photo_' + n);
    photo_center.className += ' photo_center';

    photo_center = photos.splice(n, 1)[0];

    var photos_left = photos.splice(0, Math.ceil(photos.length/2));
    var photos_right = photos;

    var ranges = range();
    for(i=0; i<photos_left.length; i++){
      photos_left[i].style.left = random(ranges.left.x) + "px";
      photos_left[i].style.top = random(ranges.left.y) + "px";
      photos_left[i].style['transform'] = 'rotate('+random([-150,150])+'deg) scale(1)';
      photos_left[i].style['-webkit-transform'] = 'rotate('+random([-150,150])+'deg) scale(1)';
    }
    for(i = 0; i<photos_right.length; i++){
      photos_right[i].style.left = random(ranges.right.x) + "px";
      photos_right[i].style.top = random(ranges.right.y) + "px";
      photos_right[i].style['transform'] = 'rotate('+random([-150,150])+'deg) scale(1)';
      photos_right[i].style['-webkit-transform'] = 'rotate('+random([-150,150])+'deg) scale(1)';
    }
    var navs = select('.i');
    for(i=0; i<navs.length; i++) {
      navs[i].className = navs[i].className.replace(/\s*i_current\s*/, ' ');
      navs[i].className = navs[i].className.replace(/\s*i_back\s*/, ' ');
    }
    select('#nav_' + n).className += ' i_current '; 
  },[range]);


  const random = (range) => {
    var max = Math.max(range[0], range[1]);
    var min = Math.min(range[0], range[1]);
    var diff = max - min;
    var number = Math.floor(Math.random() * diff + min);
    return number;
  }

/*
  const addPhotos = () => {
    var template = select('#wrap').innerHTML;
    var html = [];
    var nav = [];
    for (i=0; i<data.length; i++) {
      var _html = template.replace('{{index}}', i)
      .replace('{{img}}', data[i].img)
      .replace('{{caption}}', data[i].caption)
      .replace('{{description}}', data[i].description);
      html.push(_html);
      nav.push('<span id="nav_'+i+'" class="i" onclick ="turn(select(\'#photo_'+i+'\'))">&nbsp;</span>');
    }
    html.push('<div class="nav">'+nav.join('')+'</div>');
    select('#wrap').innerHTML = html.join('');
    sort(random([0, data.length]));
  }
*/

  const turn = (e, refIndex) => {
    // console.log('turn '+refIndex);
    const elem = photoRefs.current[refIndex];
    var cls = elem.className;
    var n = elem.id.split("_")[1];

    if(! /photo_center/.test(cls)) {
      return sort(n);
    }

// Flips Polaroid to backside
//    if(/photo_front/.test(cls)) {
//      cls = cls.replace(/photo_front/, 'photo_back'); 
//      select('#nav_' + n).className += ' i_back ';
//    } else {
//      cls = cls.replace(/photo_back/, 'photo_front');
//      select('#nav_' + n).className = select('#nav_' + n).className.replace(/\s*i_back\s*/, ' ');
//    }

    return elem.className = cls;
  }


  useEffect(()=> {
    sort(0);
    // sort(random([0, profiles.length]));
  }, [sort]);


  const [slideDots, setSlideDots] = useState(0);

  const goToProfile = (index) => {
    if (exitProfileIndex>-1) return;
    if (profileIndex!==index) {
      setNextProfileIndex(index);
      setExitProfileIndex(profileIndex);
    }
  }

  const [dotsScroll, setDotsScroll] = useState(0);
  const [dotsTotal, setDotsTotal] = useState(7);  
  
  const [increment, setIncrement] = useState(3);

  const screenWidth = useScreenWidth();

 /*
  useEffect(()=> {
    const newDotsTotal = screenWidth>600 ? 7 : 6; // Must be at least 5
    if (dotsTotal!==newDotsTotal) setDotsTotal(newDotsTotal);
    const newIncrement = newDotsTotal - 4;
    if (increment!==newIncrement) setIncrement(newIncrement);
  },[screenWidth, dotsTotal, increment]);
 */


  const [playEnter, setPlayEnter] = useState(0);

  useEffect(()=> {
    setTimeout(()=> {
      setPlayEnter(1);
    }, 100);
  }, []);

  const playAnimation = (e, id) => {
    // console.log('playAnimation '+id,e.animationName)
    if (e.animationName==='slide-in-back-center' && id==='wrap-container' && playEnter<1) {
      // sort(0);
    } else if (e.animationName==='enter-airpods' && id==='airpods') {
      // setPlayEnter(1);
    } else if (e.animationName==='exit-notepad-page' && id==='notepad-page-1') {
      setNotepadPage(2);
      setExitProfileIndex(-1);
      setNextProfileIndex(-1);
    } else if (e.animationName==='exit-notepad-page' && id==='notepad-page-2') {
      setNotepadPage(1);
      setExitProfileIndex(-1);
      setNextProfileIndex(-1);
      // console.log('exit-notepad-page-2 done setNotepadPage 1', nextProfileIndex);
    } else if (e.animationName.indexOf('exit-photo')>-1 && id==='photo-wrap' && nextProfileIndex>-1) {
      setProfileIndex(nextProfileIndex);
      sort(0);
    }
  }


/*
  const playAnimation = (e, id) => {
    if (e.animationName==='enter-townhouses' && 
        id==='townhouses' && 
        playStreet<2) {
        setPlayStreet(2);
    }
  }

  const animateRefs = useRef([]);
  const [isIntersecting, setIsIntersecting] = useState([]);
  // const screenWidth=useScreenWidth();
  const [rootMargin, setRootMargin] = useState('0px 0px -300px 0px');
  const isMobile = useIsMobile();
  
  useEffect(() => {
    animateRefs.current = animateRefs.current.slice(0, document.querySelectorAll('.intersection').length); 
  }, [playStreet]);
  
  useIsIntersecting(animateRefs, isIntersecting, setIsIntersecting, rootMargin, playStreet);
  
  useEffect(() => {
    // const margin = isMobile ? Math.floor(screenWidth * -.1) :  Math.floor(screenWidth * -.2);
    const margin = isMobile ? -100 : -300;
    const newRootMargin = '0px 0px '+margin.toString()+'px 0px';
    if (rootMargin!==newRootMargin) setRootMargin(newRootMargin);
  }, [isMobile, rootMargin]);


  const [currentSlide, setCurrentSlide] = useState(0);
  const [jumpSlide, setJumpSlide] = useState(-1);
  const [slideClass, setSlideClass] = useState('');

  const sliderTimer = useRef();

  useEffect(() => {

    if (isIntersecting[1] && jumpSlide!==-2) {

      sliderTimer.current = setInterval(() => {
        setSlideClass('start-flip');
      }, 4500);
      
      if (jumpSlide>-1) setSlideClass('start-flip');

      return () => {
        if (jumpSlide<0) clearInterval(sliderTimer.current);
      }
    }

  },[isIntersecting, jumpSlide]);


  const finishFlipSlide = (e) => {
    if (e.animationName==='begin-flip' || e.animationName==='begin-flip-mobile') {
      let newCurrentSlide= jumpSlide>-1 ? jumpSlide : currentSlide+1;
      if (newCurrentSlide>slides.length-1) newCurrentSlide=0;
      setCurrentSlide(newCurrentSlide);
      setSlideClass('end-flip');
      if (jumpSlide>-1) setJumpSlide(-2);
    } 
  }

  const goToSlide = (index) => {
    if (currentSlide===index) return;
    if (sliderTimer.current) clearInterval(sliderTimer.current);
    setJumpSlide(index);
  }
  
*/


  const [showDirectoryModal, setShowDirectoryModal] = useState(false);

  const openDirectoryModal = () => {
    setShowDirectoryModal(true);
  }
  
/*
  useEffect(() => {
    console.log('profiles', profiles);
  }, [profiles]);
*/

  return (
    <>
      <div id="our-team-container">
          <div className="background">
            <div className="left"></div>
            <div className="right"></div>  
          </div>
          <div className="pink-background"></div>

          <div id="desktop-container" className="desktop enter">
            <svg 
              version="1.1" 
              xmlns="http://www.w3.org/2000/svg" 
              xmlnsXlink="http://www.w3.org/1999/xlink" 
              x="0px" y="0px"
              viewBox="0 0 1920 5580" // 1920 1280
            >
              <g id="keyboard">
                <path className="st0" d="M1235.68,129.66L498.92,246.24c-20.07,3.17-38.91-10.52-42.08-30.58L414.06-54.72
                  c-3.17-20.07,10.52-38.91,30.58-42.08l736.77-116.58c20.07-3.17,38.91,10.52,42.08,30.58l42.78,270.37
                  C1269.44,107.64,1255.75,126.48,1235.68,129.66z"/>
                <path className="st1" d="M846.1,10.2"/>
                <path className="st2" d="M1220.46,131.97L483.7,248.55c-20.07,3.17-38.91-10.52-42.08-30.58L398.83-52.4
                  c-3.17-20.07,10.52-38.91,30.58-42.08l736.77-116.58c20.07-3.17,38.91,10.52,42.08,30.58l42.78,270.37
                  C1254.22,109.96,1240.53,128.8,1220.46,131.97z"/>
                <g>
                  <g>
                      <rect x="446.49" y="-33.81" transform="matrix(0.9877 -0.1563 0.1563 0.9877 8.9724 73.4407)" className="st3" width="50.04" height="26.95"/>
                    
                      <rect x="509.39" y="-43.76" transform="matrix(0.9877 -0.1563 0.1563 0.9877 11.3011 83.15)" className="st3" width="50.04" height="26.95"/>
                    
                      <rect x="572.3" y="-53.72" transform="matrix(0.9877 -0.1563 0.1563 0.9877 13.6298 92.8595)" className="st3" width="50.04" height="26.95"/>
                    
                      <rect x="635.21" y="-63.67" transform="matrix(0.9877 -0.1563 0.1563 0.9877 15.9585 102.5688)" className="st3" width="50.04" height="26.95"/>
                    
                      <rect x="698.12" y="-73.63" transform="matrix(0.9877 -0.1563 0.1563 0.9877 18.2873 112.2782)" className="st3" width="50.04" height="26.95"/>
                    
                      <rect x="761.03" y="-83.58" transform="matrix(0.9877 -0.1563 0.1563 0.9877 20.616 121.9875)" className="st3" width="50.04" height="26.95"/>
                    
                      <rect x="823.93" y="-93.53" transform="matrix(0.9877 -0.1563 0.1563 0.9877 22.9447 131.697)" className="st3" width="50.04" height="26.95"/>
                    
                      <rect x="886.84" y="-103.49" transform="matrix(0.9877 -0.1563 0.1563 0.9877 25.2734 141.4063)" className="st3" width="50.04" height="26.95"/>
                    
                      <rect x="949.75" y="-113.44" transform="matrix(0.9877 -0.1563 0.1563 0.9877 27.6021 151.1157)" className="st3" width="50.04" height="26.95"/>
                    
                      <rect x="1012.66" y="-123.4" transform="matrix(0.9877 -0.1563 0.1563 0.9877 29.9308 160.825)" className="st3" width="50.04" height="26.95"/>
                    
                      <rect x="1075.57" y="-133.35" transform="matrix(0.9877 -0.1563 0.1563 0.9877 32.2595 170.5345)" className="st3" width="50.04" height="26.95"/>
                    
                      <rect x="1138.47" y="-143.3" transform="matrix(0.9877 -0.1563 0.1563 0.9877 34.5882 180.2438)" className="st3" width="50.04" height="26.95"/>
                  </g>
                  <g>		
                      <rect x="454.27" y="7.07" transform="matrix(0.9877 -0.1563 0.1563 0.9877 1.3762 75.2626)" className="st3" width="50.04" height="43.63"/>
                    
                      <rect x="517.27" y="-2.9" transform="matrix(0.9877 -0.1563 0.1563 0.9877 3.7081 84.9852)" className="st3" width="50.04" height="43.63"/>
                    
                      <rect x="580.26" y="-12.87" transform="matrix(0.9877 -0.1563 0.1563 0.9877 6.04 94.7077)" className="st3" width="50.04" height="43.63"/>
                    
                      <rect x="643.25" y="-22.84" transform="matrix(0.9877 -0.1563 0.1563 0.9877 8.3718 104.4303)" className="st3" width="50.04" height="43.63"/>
                    
                      <rect x="706.25" y="-32.8" transform="matrix(0.9877 -0.1563 0.1563 0.9877 10.7037 114.1527)" className="st3" width="50.04" height="43.63"/>
                    
                      <rect x="769.24" y="-42.77" transform="matrix(0.9877 -0.1563 0.1563 0.9877 13.0356 123.8753)" className="st3" width="50.04" height="43.63"/>
                    
                      <rect x="832.23" y="-52.74" transform="matrix(0.9877 -0.1563 0.1563 0.9877 15.3675 133.5979)" className="st3" width="50.04" height="43.63"/>
                    
                      <rect x="895.23" y="-62.71" transform="matrix(0.9877 -0.1563 0.1563 0.9877 17.6993 143.3204)" className="st3" width="50.04" height="43.63"/>
                    
                      <rect x="958.22" y="-72.67" transform="matrix(0.9877 -0.1563 0.1563 0.9877 20.0312 153.043)" className="st3" width="50.04" height="43.63"/>
                    
                      <rect x="1021.21" y="-82.64" transform="matrix(0.9877 -0.1563 0.1563 0.9877 22.3619 162.7601)" className="st3" width="50.04" height="43.63"/>
                  </g>
                  <g>	
                      <rect x="1081.65" y="-97.35" transform="matrix(0.9877 -0.1563 0.1563 0.9877 25.803 177.108)" className="st3" width="115.05" height="43.63"/>
                  </g>
                  <g>		
                      <rect x="1155.35" y="-44.97" transform="matrix(0.9877 -0.1563 0.1563 0.9877 18.1244 184.1926)" className="st3" width="50.04" height="43.63"/>
                    
                      <rect x="1092.36" y="-35.01" transform="matrix(0.9877 -0.1563 0.1563 0.9877 15.7925 174.4698)" className="st3" width="50.04" height="43.63"/>
                    
                      <rect x="1029.37" y="-25.04" transform="matrix(0.9877 -0.1563 0.1563 0.9877 13.4606 164.7475)" className="st3" width="50.04" height="43.63"/>
                    
                      <rect x="966.37" y="-15.07" transform="matrix(0.9877 -0.1563 0.1563 0.9877 11.128 155.0199)" className="st3" width="50.04" height="43.63"/>
                    
                      <rect x="903.38" y="-5.1" transform="matrix(0.9877 -0.1563 0.1563 0.9877 8.7969 145.3024)" className="st3" width="50.04" height="43.63"/>
                    
                      <rect x="840.39" y="4.86" transform="matrix(0.9877 -0.1563 0.1563 0.9877 6.465 135.5798)" className="st3" width="50.04" height="43.63"/>
                    
                      <rect x="777.39" y="14.83" transform="matrix(0.9877 -0.1563 0.1563 0.9877 4.1332 125.8572)" className="st3" width="50.04" height="43.63"/>
                    
                      <rect x="714.4" y="24.8" transform="matrix(0.9877 -0.1563 0.1563 0.9877 1.8013 116.1348)" className="st3" width="50.04" height="43.63"/>
                    
                      <rect x="651.41" y="34.77" transform="matrix(0.9877 -0.1563 0.1563 0.9877 -0.5306 106.4122)" className="st3" width="50.04" height="43.63"/>
                    
                      <rect x="588.41" y="44.73" transform="matrix(0.9877 -0.1563 0.1563 0.9877 -2.8625 96.6896)" className="st3" width="50.04" height="43.63"/>
                  </g>
                  <g>
                      <rect x="462.97" y="59.44" transform="matrix(-0.9877 0.1563 -0.1563 -0.9877 1047.2898 80.1626)" className="st3" width="115.06" height="43.63"/>
                  </g>
                  <g>
                      <rect x="1164.44" y="12.48" transform="matrix(0.9877 -0.1563 0.1563 0.9877 9.2567 186.3192)" className="st3" width="50.04" height="43.63"/>
                    
                      <rect x="1101.45" y="22.45" transform="matrix(0.9877 -0.1563 0.1563 0.9877 6.9249 176.5969)" className="st3" width="50.04" height="43.63"/>
                    
                      <rect x="1038.46" y="32.42" transform="matrix(0.9877 -0.1563 0.1563 0.9877 4.593 166.8743)" className="st3" width="50.04" height="43.63"/>
                    
                      <rect x="975.46" y="42.38" transform="matrix(0.9877 -0.1563 0.1563 0.9877 2.2611 157.1518)" className="st3" width="50.04" height="43.63"/>
                    
                      <rect x="912.47" y="52.35" transform="matrix(0.9877 -0.1563 0.1563 0.9877 -0.0708 147.4292)" className="st3" width="50.04" height="43.63"/>
                    
                      <rect x="849.48" y="62.32" transform="matrix(0.9877 -0.1563 0.1563 0.9877 -2.4029 137.702)" className="st3" width="50.04" height="43.63"/>
                    
                      <rect x="786.48" y="72.29" transform="matrix(0.9877 -0.1563 0.1563 0.9877 -4.7345 127.9841)" className="st3" width="50.04" height="43.63"/>
                    
                      <rect x="723.49" y="82.25" transform="matrix(0.9877 -0.1563 0.1563 0.9877 -7.0664 118.2616)" className="st3" width="50.04" height="43.63"/>
                    
                      <rect x="660.5" y="92.22" transform="matrix(0.9877 -0.1563 0.1563 0.9877 -9.3982 108.539)" className="st3" width="50.04" height="43.63"/>
                    
                      <rect x="597.5" y="102.19" transform="matrix(0.9877 -0.1563 0.1563 0.9877 -11.7301 98.8165)" className="st3" width="50.04" height="43.63"/>
                  </g>
                  <g>		
                      <rect x="472.06" y="116.89" transform="matrix(-0.9877 0.1563 -0.1563 -0.9877 1074.3396 192.9443)" className="st3" width="115.06" height="43.63"/>
                  </g>
                  <g>	
                      <rect x="730.99" y="124.71" transform="matrix(-0.9877 0.1563 -0.1563 -0.9877 1717.1724 158.0364)" className="st3" width="242.77" height="43.63"/>
                  </g>
                  <g>		
                      <rect x="669.12" y="149.75" transform="matrix(-0.9877 0.1563 -0.1563 -0.9877 1406.5636 232.5328)" className="st3" width="50.04" height="43.63"/>
                  </g>
                  <g>			
                      <rect x="606.6" y="159.64" transform="matrix(-0.9877 0.1563 -0.1563 -0.9877 1283.8304 261.9693)" className="st3" width="50.04" height="43.63"/>
                  </g>
                  <g>			
                      <rect x="543.23" y="169.67" transform="matrix(-0.9877 0.1563 -0.1563 -0.9877 1159.439 291.8035)" className="st3" width="50.04" height="43.63"/>
                  </g>
                  <g>			
                      <rect x="481.55" y="179.43" transform="matrix(-0.9877 0.1563 -0.1563 -0.9877 1038.3644 320.8423)" className="st3" width="50.04" height="43.63"/>
                  </g>
                  <g>			
                      <rect x="1173.53" y="69.94" transform="matrix(-0.9877 0.1563 -0.1563 -0.9877 2396.7219 -4.9485)" className="st3" width="50.04" height="43.63"/>
                  </g>
                  <g>	
                      <rect x="1111.01" y="79.83" transform="matrix(-0.9877 0.1563 -0.1563 -0.9877 2273.9888 24.488)" className="st3" width="50.04" height="43.63"/>
                  </g>
                  <g>		
                      <rect x="1047.64" y="89.86" transform="matrix(-0.9877 0.1563 -0.1563 -0.9877 2149.5969 54.3226)" className="st3" width="50.04" height="43.63"/>
                  </g>
                  <g>			
                      <rect x="985.96" y="99.62" transform="matrix(-0.9877 0.1563 -0.1563 -0.9877 2028.5222 83.361)" className="st3" width="50.04" height="43.63"/>
                  </g>
                </g>
                <g>
                  <g>		
                      <rect x="438.88" y="-32.65" transform="matrix(0.9877 -0.1563 0.1563 0.9877 8.6979 72.2654)" className="st4" width="50.04" height="26.95"/>
                    
                      <rect x="501.78" y="-42.61" transform="matrix(0.9877 -0.1563 0.1563 0.9877 11.0266 81.9747)" className="st4" width="50.04" height="26.95"/>
                    
                      <rect x="564.69" y="-52.56" transform="matrix(0.9877 -0.1563 0.1563 0.9877 13.3553 91.6842)" className="st4" width="50.04" height="26.95"/>
                    
                      <rect x="627.6" y="-62.51" transform="matrix(0.9877 -0.1563 0.1563 0.9877 15.6832 101.3903)" className="st4" width="50.04" height="26.95"/>
                    
                      <rect x="690.51" y="-72.47" transform="matrix(0.9877 -0.1563 0.1563 0.9877 18.0127 111.1029)" className="st4" width="50.04" height="26.95"/>
                    
                      <rect x="753.41" y="-82.42" transform="matrix(0.9877 -0.1563 0.1563 0.9877 20.3414 120.8122)" className="st4" width="50.04" height="26.95"/>
                    
                      <rect x="816.32" y="-92.38" transform="matrix(0.9877 -0.1563 0.1563 0.9877 22.6701 130.5217)" className="st4" width="50.04" height="26.95"/>
                    
                      <rect x="879.23" y="-102.33" transform="matrix(0.9877 -0.1563 0.1563 0.9877 24.9988 140.231)" className="st4" width="50.04" height="26.95"/>
                    
                      <rect x="942.14" y="-112.28" transform="matrix(0.9877 -0.1563 0.1563 0.9877 27.3276 149.9404)" className="st4" width="50.04" height="26.95"/>
                    
                      <rect x="1005.05" y="-122.24" transform="matrix(0.9877 -0.1563 0.1563 0.9877 29.6563 159.6498)" className="st4" width="50.04" height="26.95"/>
                    
                      <rect x="1067.95" y="-132.19" transform="matrix(0.9877 -0.1563 0.1563 0.9877 31.985 169.3592)" className="st4" width="50.04" height="26.95"/>
                    
                      <rect x="1130.86" y="-142.15" transform="matrix(0.9877 -0.1563 0.1563 0.9877 34.3137 179.0685)" className="st4" width="50.04" height="26.95"/>
                  </g>
                  <g>	
                      <rect x="446.66" y="8.22" transform="matrix(0.9877 -0.1563 0.1563 0.9877 1.1014 74.0848)" className="st4" width="50.04" height="43.63"/>
                    
                      <rect x="509.66" y="-1.74" transform="matrix(0.9877 -0.1563 0.1563 0.9877 3.4335 83.8098)" className="st4" width="50.04" height="43.63"/>
                    
                      <rect x="572.65" y="-11.71" transform="matrix(0.9877 -0.1563 0.1563 0.9877 5.7654 93.5324)" className="st4" width="50.04" height="43.63"/>
                    
                      <rect x="635.64" y="-21.68" transform="matrix(0.9877 -0.1563 0.1563 0.9877 8.0973 103.255)" className="st4" width="50.04" height="43.63"/>
                    
                      <rect x="698.64" y="-31.65" transform="matrix(0.9877 -0.1563 0.1563 0.9877 10.4292 112.9775)" className="st4" width="50.04" height="43.63"/>
                    
                      <rect x="761.63" y="-41.61" transform="matrix(0.9877 -0.1563 0.1563 0.9877 12.761 122.6999)" className="st4" width="50.04" height="43.63"/>
                    
                      <rect x="824.62" y="-51.58" transform="matrix(0.9877 -0.1563 0.1563 0.9877 15.0929 132.4226)" className="st4" width="50.04" height="43.63"/>
                    
                      <rect x="887.62" y="-61.55" transform="matrix(0.9877 -0.1563 0.1563 0.9877 17.4248 142.1451)" className="st4" width="50.04" height="43.63"/>
                    
                      <rect x="950.61" y="-71.52" transform="matrix(0.9877 -0.1563 0.1563 0.9877 19.7567 151.8677)" className="st4" width="50.04" height="43.63"/>
                    
                      <rect x="1013.6" y="-81.48" transform="matrix(0.9877 -0.1563 0.1563 0.9877 22.0885 161.5902)" className="st4" width="50.04" height="43.63"/>
                  </g>
                  <g>		
                      <rect x="1074.04" y="-96.19" transform="matrix(0.9877 -0.1563 0.1563 0.9877 25.5285 175.9327)" className="st4" width="115.05" height="43.63"/>
                  </g>
                  <g>		
                      <rect x="1147.74" y="-43.81" transform="matrix(0.9877 -0.1563 0.1563 0.9877 17.8498 183.0171)" className="st4" width="50.04" height="43.63"/>
                    
                      <rect x="1084.75" y="-33.85" transform="matrix(0.9877 -0.1563 0.1563 0.9877 15.518 173.2947)" className="st4" width="50.04" height="43.63"/>
                    
                      <rect x="1021.75" y="-23.88" transform="matrix(0.9877 -0.1563 0.1563 0.9877 13.1861 163.5722)" className="st4" width="50.04" height="43.63"/>
                    
                      <rect x="958.76" y="-13.91" transform="matrix(0.9877 -0.1563 0.1563 0.9877 10.8542 153.8496)" className="st4" width="50.04" height="43.63"/>
                    
                      <rect x="895.77" y="-3.95" transform="matrix(0.9877 -0.1563 0.1563 0.9877 8.5224 144.1271)" className="st4" width="50.04" height="43.63"/>
                    
                      <rect x="832.77" y="6.02" transform="matrix(0.9877 -0.1563 0.1563 0.9877 6.1905 134.4044)" className="st4" width="50.04" height="43.63"/>
                    
                      <rect x="769.78" y="15.99" transform="matrix(0.9877 -0.1563 0.1563 0.9877 3.8586 124.682)" className="st4" width="50.04" height="43.63"/>
                    
                      <rect x="706.79" y="25.96" transform="matrix(0.9877 -0.1563 0.1563 0.9877 1.5268 114.9594)" className="st4" width="50.04" height="43.63"/>
                    
                      <rect x="643.8" y="35.92" transform="matrix(0.9877 -0.1563 0.1563 0.9877 -0.8051 105.2369)" className="st4" width="50.04" height="43.63"/>
                    
                      <rect x="580.8" y="45.89" transform="matrix(0.9877 -0.1563 0.1563 0.9877 -3.137 95.5143)" className="st4" width="50.04" height="43.63"/>
                  </g>
                  <g>			
                      <rect x="455.35" y="60.6" transform="matrix(-0.9877 0.1563 -0.1563 -0.9877 1032.3418 83.6544)" className="st4" width="115.06" height="43.63"/>
                  </g>
                  <g>			
                      <rect x="1156.83" y="13.64" transform="matrix(0.9877 -0.1563 0.1563 0.9877 8.9822 185.1441)" className="st4" width="50.04" height="43.63"/>
                    
                      <rect x="1093.84" y="23.61" transform="matrix(0.9877 -0.1563 0.1563 0.9877 6.6503 175.4216)" className="st4" width="50.04" height="43.63"/>
                    
                      <rect x="1030.85" y="33.57" transform="matrix(0.9877 -0.1563 0.1563 0.9877 4.3184 165.699)" className="st4" width="50.04" height="43.63"/>
                    
                      <rect x="967.85" y="43.54" transform="matrix(0.9877 -0.1563 0.1563 0.9877 1.9861 155.9714)" className="st4" width="50.04" height="43.63"/>
                    
                      <rect x="904.86" y="53.51" transform="matrix(0.9877 -0.1563 0.1563 0.9877 -0.3453 146.2539)" className="st4" width="50.04" height="43.63"/>
                    
                      <rect x="841.87" y="63.48" transform="matrix(0.9877 -0.1563 0.1563 0.9877 -2.6772 136.5312)" className="st4" width="50.04" height="43.63"/>
                    
                      <rect x="778.87" y="73.44" transform="matrix(0.9877 -0.1563 0.1563 0.9877 -5.009 126.8088)" className="st4" width="50.04" height="43.63"/>
                    
                      <rect x="715.88" y="83.41" transform="matrix(0.9877 -0.1563 0.1563 0.9877 -7.3409 117.0863)" className="st4" width="50.04" height="43.63"/>
                    
                      <rect x="652.89" y="93.38" transform="matrix(0.9877 -0.1563 0.1563 0.9877 -9.6728 107.3637)" className="st4" width="50.04" height="43.63"/>
                    
                      <rect x="589.89" y="103.35" transform="matrix(0.9877 -0.1563 0.1563 0.9877 -12.0047 97.6412)" className="st4" width="50.04" height="43.63"/>
                  </g>
                  <g>		
                      <rect x="464.45" y="118.05" transform="matrix(-0.9877 0.1563 -0.1563 -0.9877 1059.3917 196.4361)" className="st4" width="115.05" height="43.63"/>
                  </g>
                  <g>			
                      <rect x="723.38" y="125.87" transform="matrix(-0.9877 0.1563 -0.1563 -0.9877 1702.2244 161.5282)" className="st4" width="242.77" height="43.63"/>
                  </g>
                  <g>
                      <rect x="661.51" y="150.91" transform="matrix(-0.9877 0.1563 -0.1563 -0.9877 1391.6157 236.0246)" className="st4" width="50.04" height="43.63"/>
                  </g>
                  <g>	
                      <rect x="598.98" y="160.8" transform="matrix(-0.9877 0.1563 -0.1563 -0.9877 1268.8826 265.461)" className="st4" width="50.04" height="43.63"/>
                  </g>
                  <g>			
                      <rect x="535.62" y="170.83" transform="matrix(-0.9877 0.1563 -0.1563 -0.9877 1144.491 295.2952)" className="st4" width="50.04" height="43.63"/>
                  </g>
                  <g>	
                      <rect x="473.94" y="180.59" transform="matrix(-0.9877 0.1563 -0.1563 -0.9877 1023.4164 324.334)" className="st4" width="50.04" height="43.63"/>
                  </g>
                  <g>			
                      <rect x="1165.92" y="71.09" transform="matrix(-0.9877 0.1563 -0.1563 -0.9877 2381.7742 -1.4567)" className="st4" width="50.04" height="43.63"/>
                  </g>
                  <g>			
                      <rect x="1103.4" y="80.99" transform="matrix(-0.9877 0.1563 -0.1563 -0.9877 2259.0408 27.9796)" className="st4" width="50.04" height="43.63"/>
                  </g>
                  <g>		
                      <rect x="1040.03" y="91.01" transform="matrix(-0.9877 0.1563 -0.1563 -0.9877 2134.6489 57.8142)" className="st4" width="50.04" height="43.63"/>
                  </g>
                  <g>	
                      <rect x="978.35" y="100.77" transform="matrix(-0.9877 0.1563 -0.1563 -0.9877 2013.5743 86.8528)" className="st4" width="50.04" height="43.63"/>
                  </g>
                </g>
                <path className="st5" d="M1201.4-196.73c-7.91-10.58-21.26-16.54-35.21-14.33L429.42-94.48c-11.95,1.89-21.6,9.36-26.81,19.31
                  L1201.4-196.73z"/>
  {/*
                <g id="our-team">
                  <text id="O1" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 605.302 142.8174)">O</text>
                  <text id="U1" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 669.6179 133.2049)">U</text>
                  <text id="R1" className="st54 st55 st56" transform="matrix(0.7322 -0.1202 0.162 0.9868 730.9887 123.2955)">R</text>
                  <text id="T1" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 860.246 103.7905)">T</text>
                  <text id="E1" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 922.7948 92.9114)">E</text>
                  <text id="A1" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 983.9589 83.8858)">A</text>
                  <text id="M1" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 1043.9589 74.8858)">M</text>                                         
                </g> 
  */}
                <g id="our-team">
                  <text id="O1" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 541.5974 152.9072)">O</text>
                  <text id="U1" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 605.9133 143.2946)">U</text>
                  <text id="R1" className="st54 st55 st56" transform="matrix(0.7322 -0.1202 0.162 0.9868 667.2841 133.3852)">R</text>
                  <text id="T1" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 796.5414 113.8803)">T</text>
                  <text id="E1" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 859.0903 103.0012)">E</text>
                  <text id="A1" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 920.2543 93.9756)">A</text>
                  <text id="M1" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 980.2543 84.9756)">M</text>
                </g>
                
                <g id="our-team-2"> 
                  <text id="O2" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 541.9335 152.0874)">O</text>
                  <text id="U2" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 605.2495 143.4749)">U</text>
                  <text id="R2" className="st54 st55 st56" transform="matrix(0.7322 -0.1202 0.162 0.9868 670.6202 132.5655)">R</text>
                  <text id="T2" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 488.6442 219.9477)">T</text>
                  <text id="E2" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 552.9601 210.3351)">E</text>
                  <text id="A2" className="st54 st55 st56" transform="matrix(0.7322 -0.1202 0.162 0.9868 614.3309 200.4257)">A</text>
                  <text id="M2" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 674.2302 190.8924)">M</text>                                             
                </g>
                {/* 
                <g id="our-team-3">
                  <text id="O3" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 596.5974 85.9072)">O</text>
                  <text id="U3" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 660.9133 76.2946)">U</text>
                  <text id="R3" className="st54 st55 st56" transform="matrix(0.7322 -0.1202 0.162 0.9868 722.2841 66.3852)">R</text>
                  <text id="T3" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 608.5414 143.8802)">T</text>
                  <text id="E3" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 671.0903 133.0011)">E</text>
                  <text id="A3" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 732.2543 123.9756)">A</text>
                  <text id="M3" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 792.2543 114.9756)">M</text>
                </g>
                */}
                <g id="our-team-3">
                  <text id="O3" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 534.5974 93.9072)">O</text>
                  <text id="U3" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 596.9133 85.2946)">U</text>
                  <text id="R3" className="st54 st55 st56" transform="matrix(0.7322 -0.1202 0.162 0.9868 659.2841 75.3852)">R</text>
                  <text id="T3" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 546.5414 151.8802)">T</text>
                  <text id="E3" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 608.0903 142.0011)">E</text>
                  <text id="A3" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 669.2543 132.9756)">A</text>
                  <text id="M3" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 729.2543 122.9756)">M</text>
                </g>
                <g id="our-team-mobile">
                  <text id="O4" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 1036.8044 16.1673)">O</text>
                  <text id="U4" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 1099.8044 6.1673)">U</text>
                  <text id="R4" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 1163.6702 -4.0277)">R</text>
                  <text id="T4" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 983.9861 83.3597)">T</text>
                  <text id="E4" className="st54 st55 st56" transform="matrix(0.7322 -0.1202 0.162 0.9868 1048.3568 73.4503)">E</text>
                  <text id="A4" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 1109.2561 63.917)">A</text>
                  <text id="M4" className="st54 st55 st56" transform="matrix(0.7333 -0.1135 0.153 0.9882 1170.6141 54.9454)">M</text>
                </g>

                {/* 
                <g id="about">
                  <text id="a1" transform="matrix(0.7333 -0.1135 0.153 0.9882 605.2557 142.8279)" className="st54 st55 st56">A</text>
                  <text id="b1" transform="matrix(0.7333 -0.1135 0.153 0.9882 669.5716 133.2154)" className="st54 st55 st56">B</text>
                  <text id="o1" transform="matrix(0.7322 -0.1202 0.162 0.9868 731.0104 123.2964)" className="st54 st55 st56">O</text>
                  <text id="u1" transform="matrix(0.7333 -0.1135 0.153 0.9882 794.8416 112.7726)" className="st54 st55 st56">U</text>
                  <text id="t1" transform="matrix(0.7333 -0.1135 0.153 0.9882 860.1996 103.801)" className="st54 st55 st56">T</text>
                </g>     

                <g id="about-2">
                  <text id="a2" transform="matrix(0.7333 -0.1135 0.153 0.9882 488.5978 219.9582)" className="st54 st55 st56">A</text>
                  <text id="b2" transform="matrix(0.7333 -0.1135 0.153 0.9882 552.9138 210.3456)" className="st54 st55 st56">B</text>
                  <text id="o2" transform="matrix(0.7322 -0.1202 0.162 0.9868 614.3527 200.4266)" className="st54 st55 st56">O</text>
                  <text id="u2" transform="matrix(0.7333 -0.1135 0.153 0.9882 677.1838 190.9029)" className="st54 st55 st56">U</text>
                  <text id="t2" transform="matrix(0.7333 -0.1135 0.153 0.9882 740.5417 180.9312)" className="st54 st55 st56">T</text>
                </g> 

                <g id="about-mobile">
                  <text id="a3" transform="matrix(0.7333 -0.1135 0.153 0.9882 919.6702 92.9723)" className="st54 st55 st56">A</text>
                  <text id="b3" transform="matrix(0.7333 -0.1135 0.153 0.9882 983.9861 83.3597)" className="st54 st55 st56">B</text>
                  <text id="o3" transform="matrix(0.7322 -0.1202 0.162 0.9868 1045.3568 73.4503)" className="st54 st55 st56">O</text>
                  <text id="u3" transform="matrix(0.7333 -0.1135 0.153 0.9882 1109.2561 62.917)" className="st54 st55 st56">U</text>
                  <text id="t3" transform="matrix(0.7333 -0.1135 0.153 0.9882 1174.6141 53.9454)" className="st54 st55 st56">T</text>
                </g>
              */} 

              </g>
            

              {/*
              <text id="U_2" transform="matrix(0.7329 -0.1161 0.1564 0.9877 983.3695 83.8922)" className="st54 st55 st56">U</text>
              <text id="S" transform="matrix(0.7329 -0.1161 0.1564 0.9877 1047.3728 72.9111)" className="st54 st55 st56">S</text>
              */}
              <g id="airpods" onAnimationEnd={(e) => playAnimation(e, 'airpods')}>
                <g id="airpods-bottom">
                  <path className="st14" d="M1927.3,396.75c0,121.64,0,243.27,0,364.91c-133.48,0-266.95,0-400.43,0c0-143.81,0-287.62,0-431.43
                    c133.48,0,266.95,0,400.43,0c0,16.58,0,33.15,0,49.73c-0.89,1.34-1.88,2.62-2.66,4.02c-6.81,12.1-13.48,24.28-20.38,36.32
                    c-10.54,18.37-21.56,36.47-30.26,55.85c-8.02,17.85-16.16,35.65-24.34,53.43c-3.09,6.73-5.61,13.92-9.81,19.9
                    c-10.37,14.78-21.56,29-32.44,43.42c-0.84,1.11-1.75,2.82-2.81,2.97c-3.31,0.49-4.4,3.2-6.15,5.25c-2.23,2.62-4.28,5.39-6.54,7.99
                    c-0.5,0.58-1.92,1.19-2.29,0.93c-2.18-1.59-3.14,0.01-4.17,1.38c-4.2,5.59-8.33,11.23-12.55,16.8
                    c-10.78,14.23-21.53,28.48-32.44,42.6c-3.39,4.39-7.26,8.41-11.18,12.91c-1.22-1.81-1.99-3.4-3.16-4.58
                    c-2.93-2.94-5.58-6.77-9.17-8.32c-9.73-4.2-20.06-6.54-30.81-5.31c-2.34,0.27-5.46,0.75-6.72,2.33
                    c-5.76,7.21-11.04,14.82-16.35,22.38c-0.79,1.12-1.22,2.81-1.12,4.18c0.48,6.92,2.05,13.7,5.17,19.84
                    c2.46,4.83,5.32,9.84,9.21,13.48c9.72,9.08,21.7,11.15,34.57,8.83c1.04-0.19,2.22-0.42,3-1.05c6.46-5.21,12.99-10.35,15.64-18.78
                    c0.43-1.36,1.53-2.56,2.51-3.67c9.44-10.73,19.68-20.77,27.63-32.77c3.5-5.29,7.28-10.39,11.08-15.47
                    c10.55-14.13,21.16-28.22,31.78-42.3c1.11-1.47,1.7-2.77-0.1-4.09c-1.48-1.08-1.31-2.18-0.21-3.51c2.6-3.16,5.13-6.36,7.6-9.62
                    c0.67-0.89,1.07-2.01,1.44-3.08c0.45-1.3,0.38-2.91,1.15-3.94c5.26-7.08,10.55-14.14,16.07-21.01
                    c14.26-17.73,25.57-37.07,33.71-58.4c5.05-13.23,10.39-26.47,17.01-38.96c13.63-25.75,28.15-51.03,42.44-76.43
                    C1923.05,400.97,1925.39,398.98,1927.3,396.75z M1699.67,516.52c0,0.13,0,0.27,0,0.4c6.02,0,12.05-0.1,18.07,0.05
                    c2.18,0.06,3.34-0.6,4.08-2.67c0.36-1,1.52-2.38,2.38-2.42c7.17-0.33,14.22-1.25,21.15-3.17c2.56-0.71,5.33-0.71,7.9-1.4
                    c13.15-3.54,24.64-10.68,36.37-17.19c18.85-10.46,35.2-24.26,50.47-39.35c8.26-8.17,16.31-16.55,24.24-25.05
                    c6.53-6.99,13.58-13.49,19.33-21.25c4.85-6.53,10.45-12.5,15.86-18.6c7.68-8.68,15.49-17.24,23.22-25.88
                    c3.14-3.51,3.41-6.06,1.27-9.38c-1.36-2.11-2.66-2.17-4.28-0.29c-2.25,2.6-4.64,5.08-6.87,7.69
                    c-9.42,11.04-18.77,22.13-28.22,33.15c-8.96,10.45-17.6,21.2-27.1,31.13c-11.89,12.43-24.42,24.25-36.87,36.13
                    c-4.89,4.66-9.98,9.26-15.57,13c-11.17,7.46-22.75,14.3-34.17,21.39c-8.05,5-16.95,7.83-26.01,10.26
                    c-3.08,0.82-5.74-0.69-8.61-1.28c-4.08-0.84-8.35-0.69-12.52-1.12c-1.06-0.11-2.66-0.59-2.97-1.33c-1.46-3.47-4.37-3.35-7.28-3.44
                    c-24.3-0.75-48.61-1.43-72.91-2.32c-4.48-0.16-8.98-0.93-13.36-1.89c-5.35-1.18-6.17-3.69-3.19-8.25c0.29-0.45,0.77-0.81,0.96-1.29
                    c1.11-2.76,2.97-5.51,3.09-8.33c0.57-13.04-4.29-24.12-13.37-33.37c-0.77-0.79-1.98-1.49-3.05-1.58
                    c-9.11-0.78-18.22-1.43-27.34-2.07c-2.61-0.18-4.84,0.65-6.53,2.8c-3.85,4.89-8.06,9.53-11.56,14.66
                    c-5.7,8.35-8.95,17.6-8.44,27.92c0.45,9.22,4.98,16.34,12.9,20.2c17.48,8.54,36.43,11.42,55.7,12.07
                    C1650.84,515.27,1675.26,515.84,1699.67,516.52z"/>
                  <path className="st15" d="M1927.3,396.75c-1.91,2.23-4.25,4.21-5.66,6.72c-14.29,25.4-28.81,50.68-42.44,76.43
                    c-6.61,12.49-11.96,25.73-17.01,38.96c-8.14,21.32-19.45,40.66-33.71,58.4c-5.52,6.87-10.81,13.93-16.07,21.01
                    c-0.76,1.03-0.7,2.64-1.15,3.94c-0.37,1.07-0.77,2.19-1.44,3.08c-2.46,3.26-5,6.47-7.6,9.62c-1.1,1.33-1.26,2.43,0.21,3.51
                    c1.81,1.32,1.21,2.62,0.1,4.09c-10.62,14.08-21.23,28.16-31.78,42.3c-3.79,5.08-7.58,10.19-11.08,15.47
                    c-7.95,12-18.19,22.04-27.63,32.77c-0.98,1.11-2.08,2.32-2.51,3.67c-2.65,8.43-9.18,13.58-15.64,18.78c-0.78,0.63-1.96,0.86-3,1.05
                    c-12.86,2.32-24.85,0.25-34.57-8.83c-3.89-3.64-6.75-8.65-9.21-13.48c-3.13-6.14-4.69-12.92-5.17-19.84
                    c-0.1-1.38,0.33-3.06,1.12-4.18c5.31-7.56,10.59-15.17,16.35-22.38c1.26-1.58,4.38-2.06,6.72-2.33
                    c10.75-1.23,21.08,1.11,30.81,5.31c3.59,1.55,6.24,5.38,9.17,8.32c1.17,1.18,1.94,2.77,3.16,4.58c3.92-4.5,7.79-8.52,11.18-12.91
                    c10.91-14.13,21.66-28.38,32.44-42.6c4.22-5.57,8.35-11.22,12.55-16.8c1.03-1.36,1.99-2.96,4.17-1.38
                    c0.37,0.27,1.79-0.35,2.29-0.93c2.26-2.59,4.3-5.37,6.54-7.99c1.75-2.05,2.84-4.76,6.15-5.25c1.06-0.16,1.97-1.87,2.81-2.97
                    c10.88-14.42,22.07-28.64,32.44-43.42c4.2-5.98,6.72-13.17,9.81-19.9c8.17-17.78,16.32-35.58,24.34-53.43
                    c8.7-19.37,19.72-37.47,30.26-55.85c6.91-12.04,13.58-24.22,20.38-36.32c0.79-1.4,1.77-2.68,2.66-4.02
                    C1927.3,385.56,1927.3,391.16,1927.3,396.75z"/>
                  <path className="st16" d="M1699.67,516.52c-24.41-0.68-48.82-1.25-73.23-2.07c-19.28-0.65-38.22-3.53-55.7-12.07
                    c-7.92-3.87-12.45-10.99-12.9-20.2c-0.51-10.32,2.74-19.57,8.44-27.92c3.5-5.13,7.71-9.76,11.56-14.66
                    c1.69-2.15,3.92-2.98,6.53-2.8c9.12,0.64,18.23,1.29,27.34,2.07c1.06,0.09,2.28,0.79,3.05,1.58c9.09,9.25,13.94,20.33,13.37,33.37
                    c-0.12,2.82-1.98,5.57-3.09,8.33c-0.19,0.48-0.67,0.84-0.96,1.29c-2.98,4.56-2.16,7.07,3.19,8.25c4.38,0.97,8.89,1.73,13.36,1.89
                    c24.3,0.89,48.6,1.57,72.91,2.32c2.9,0.09,5.82-0.03,7.28,3.44c0.31,0.74,1.91,1.22,2.97,1.33c4.17,0.44,8.44,0.28,12.52,1.12
                    c2.87,0.59,5.54,2.1,8.61,1.28c9.06-2.43,17.96-5.26,26.01-10.26c11.42-7.09,23-13.93,34.17-21.39c5.59-3.73,10.68-8.34,15.57-13
                    c12.45-11.88,24.98-23.7,36.87-36.13c9.5-9.93,18.14-20.68,27.1-31.13c9.45-11.02,18.8-22.11,28.22-33.15
                    c2.23-2.61,4.63-5.09,6.87-7.69c1.62-1.88,2.92-1.82,4.28,0.29c2.14,3.32,1.87,5.87-1.27,9.38c-7.73,8.63-15.54,17.2-23.22,25.88
                    c-5.4,6.1-11.01,12.07-15.86,18.6c-5.76,7.76-12.8,14.25-19.33,21.25c-7.93,8.49-15.98,16.88-24.24,25.05
                    c-15.27,15.09-31.62,28.89-50.47,39.35c-11.73,6.51-23.22,13.66-36.37,17.19c-2.57,0.69-5.33,0.69-7.9,1.4
                    c-6.93,1.93-13.98,2.84-21.15,3.17c-0.85,0.04-2.02,1.42-2.38,2.42c-0.75,2.08-1.9,2.73-4.08,2.67
                    c-6.02-0.16-12.05-0.05-18.07-0.05C1699.67,516.79,1699.67,516.66,1699.67,516.52z"/>
                </g>
                <g id="airpods-top">
                  <path className="st14" d="M1925,390.52c0,121.64,0,243.27,0,364.91c-133.48,0-266.95,0-400.43,0c0-143.81,0-287.62,0-431.43
                    c133.48,0,266.95,0,400.43,0c0,16.58,0,33.15,0,49.73c-0.89,1.34-1.88,2.62-2.66,4.02c-6.81,12.1-13.48,24.28-20.38,36.32
                    c-10.54,18.37-21.56,36.47-30.26,55.85c-8.02,17.85-16.16,35.65-24.34,53.43c-3.09,6.73-5.61,13.92-9.81,19.9
                    c-10.37,14.78-21.56,29-32.44,43.42c-0.84,1.11-1.75,2.82-2.81,2.97c-3.31,0.49-4.4,3.2-6.15,5.25c-2.23,2.62-4.28,5.39-6.54,7.99
                    c-0.5,0.58-1.92,1.19-2.29,0.93c-2.18-1.59-3.14,0.01-4.17,1.38c-4.2,5.59-8.33,11.23-12.55,16.8
                    c-10.78,14.23-21.53,28.48-32.44,42.6c-3.39,4.39-7.26,8.41-11.18,12.91c-1.22-1.81-1.99-3.4-3.16-4.58
                    c-2.93-2.94-5.58-6.77-9.17-8.32c-9.73-4.2-20.06-6.54-30.81-5.31c-2.34,0.27-5.46,0.75-6.72,2.33
                    c-5.76,7.21-11.04,14.82-16.35,22.38c-0.79,1.12-1.22,2.81-1.12,4.18c0.48,6.92,2.05,13.7,5.17,19.84
                    c2.46,4.83,5.32,9.84,9.21,13.48c9.72,9.08,21.7,11.15,34.57,8.83c1.04-0.19,2.22-0.42,3-1.05c6.46-5.21,12.99-10.35,15.64-18.78
                    c0.43-1.36,1.53-2.56,2.51-3.67c9.44-10.73,19.68-20.77,27.63-32.77c3.5-5.28,7.28-10.39,11.08-15.47
                    c10.55-14.13,21.16-28.22,31.78-42.3c1.11-1.47,1.7-2.77-0.1-4.09c-1.48-1.08-1.31-2.18-0.21-3.51c2.6-3.16,5.13-6.36,7.6-9.62
                    c0.67-0.89,1.07-2.01,1.44-3.08c0.45-1.3,0.38-2.91,1.15-3.94c5.26-7.08,10.55-14.14,16.07-21.01
                    c14.26-17.73,25.57-37.07,33.71-58.4c5.05-13.23,10.39-26.47,17.01-38.96c13.63-25.75,28.15-51.03,42.44-76.43
                    C1920.75,394.74,1923.09,392.75,1925,390.52z M1697.37,510.29c0,0.13,0,0.27,0,0.4c6.02,0,12.05-0.1,18.07,0.05
                    c2.18,0.06,3.34-0.6,4.08-2.67c0.36-1,1.53-2.38,2.38-2.42c7.17-0.33,14.22-1.25,21.15-3.17c2.56-0.71,5.33-0.71,7.9-1.4
                    c13.15-3.54,24.64-10.68,36.37-17.19c18.85-10.46,35.2-24.26,50.47-39.35c8.26-8.17,16.31-16.55,24.24-25.05
                    c6.53-6.99,13.58-13.49,19.33-21.25c4.85-6.53,10.45-12.5,15.86-18.6c7.68-8.68,15.49-17.24,23.22-25.88
                    c3.14-3.51,3.41-6.06,1.27-9.38c-1.36-2.11-2.66-2.17-4.28-0.29c-2.25,2.6-4.64,5.08-6.87,7.69
                    c-9.42,11.04-18.77,22.13-28.22,33.15c-8.96,10.45-17.6,21.2-27.1,31.13c-11.89,12.43-24.42,24.25-36.87,36.13
                    c-4.89,4.66-9.98,9.26-15.57,13c-11.17,7.46-22.75,14.3-34.17,21.39c-8.05,5-16.95,7.83-26.01,10.26
                    c-3.08,0.82-5.74-0.69-8.61-1.28c-4.08-0.84-8.35-0.69-12.52-1.12c-1.06-0.11-2.66-0.59-2.97-1.33c-1.46-3.47-4.37-3.35-7.28-3.44
                    c-24.3-0.75-48.61-1.43-72.91-2.32c-4.48-0.16-8.98-0.93-13.36-1.89c-5.35-1.18-6.17-3.69-3.19-8.25c0.29-0.45,0.77-0.81,0.96-1.29
                    c1.11-2.76,2.97-5.51,3.09-8.33c0.57-13.04-4.29-24.12-13.37-33.37c-0.77-0.79-1.98-1.49-3.05-1.58
                    c-9.11-0.78-18.22-1.43-27.34-2.07c-2.61-0.18-4.84,0.65-6.53,2.8c-3.85,4.89-8.06,9.53-11.56,14.66
                    c-5.7,8.35-8.95,17.6-8.44,27.92c0.45,9.22,4.98,16.34,12.9,20.2c17.48,8.54,36.43,11.42,55.7,12.07
                    C1648.54,509.04,1672.96,509.61,1697.37,510.29z"/>
                  <path className="st17" d="M1925,390.52c-1.91,2.23-4.25,4.21-5.66,6.72c-14.29,25.4-28.81,50.68-42.44,76.43
                    c-6.61,12.49-11.96,25.73-17.01,38.96c-8.14,21.32-19.45,40.66-33.71,58.4c-5.52,6.87-10.81,13.93-16.07,21.01
                    c-0.76,1.03-0.7,2.64-1.15,3.94c-0.37,1.07-0.77,2.19-1.44,3.08c-2.46,3.26-5,6.47-7.6,9.62c-1.1,1.33-1.26,2.43,0.21,3.51
                    c1.81,1.32,1.21,2.62,0.1,4.09c-10.62,14.08-21.23,28.16-31.78,42.3c-3.79,5.08-7.58,10.19-11.08,15.47
                    c-7.95,12-18.19,22.04-27.63,32.77c-0.98,1.11-2.08,2.32-2.51,3.67c-2.65,8.43-9.18,13.58-15.64,18.78c-0.78,0.63-1.96,0.86-3,1.05
                    c-12.86,2.32-24.85,0.25-34.57-8.83c-3.89-3.64-6.75-8.65-9.21-13.48c-3.13-6.14-4.69-12.92-5.17-19.84
                    c-0.1-1.38,0.33-3.06,1.12-4.18c5.31-7.56,10.59-15.17,16.35-22.38c1.26-1.58,4.38-2.06,6.72-2.33
                    c10.75-1.23,21.08,1.11,30.81,5.31c3.59,1.55,6.24,5.38,9.17,8.32c1.17,1.18,1.94,2.77,3.16,4.58c3.92-4.5,7.79-8.52,11.18-12.91
                    c10.91-14.13,21.66-28.38,32.44-42.6c4.22-5.57,8.35-11.22,12.55-16.8c1.03-1.36,1.99-2.96,4.17-1.38
                    c0.37,0.27,1.79-0.35,2.29-0.93c2.26-2.59,4.3-5.37,6.54-7.99c1.75-2.05,2.84-4.76,6.15-5.25c1.06-0.16,1.97-1.87,2.81-2.97
                    c10.88-14.42,22.07-28.64,32.44-43.42c4.2-5.98,6.72-13.17,9.81-19.9c8.17-17.78,16.32-35.58,24.34-53.43
                    c8.7-19.37,19.72-37.47,30.26-55.85c6.91-12.04,13.58-24.22,20.38-36.32c0.79-1.4,1.77-2.68,2.66-4.02
                    C1925,379.33,1925,384.93,1925,390.52z"/>
                  <path className="st17" d="M1697.37,510.29c-24.41-0.68-48.82-1.25-73.23-2.07c-19.28-0.65-38.22-3.53-55.7-12.07
                    c-7.92-3.87-12.45-10.99-12.9-20.2c-0.51-10.32,2.74-19.57,8.44-27.92c3.5-5.13,7.71-9.76,11.56-14.66
                    c1.69-2.15,3.92-2.98,6.53-2.8c9.12,0.64,18.23,1.29,27.34,2.07c1.06,0.09,2.28,0.79,3.05,1.58c9.09,9.25,13.94,20.33,13.37,33.37
                    c-0.12,2.82-1.98,5.57-3.09,8.33c-0.19,0.48-0.67,0.84-0.96,1.29c-2.98,4.56-2.16,7.07,3.19,8.25c4.38,0.97,8.89,1.73,13.36,1.89
                    c24.3,0.89,48.6,1.57,72.91,2.32c2.9,0.09,5.82-0.03,7.28,3.44c0.31,0.74,1.91,1.22,2.97,1.33c4.17,0.44,8.44,0.28,12.52,1.12
                    c2.87,0.59,5.54,2.1,8.61,1.28c9.06-2.43,17.96-5.26,26.01-10.26c11.42-7.09,23-13.93,34.17-21.39c5.59-3.73,10.68-8.34,15.57-13
                    c12.45-11.88,24.98-23.7,36.87-36.13c9.5-9.93,18.14-20.68,27.1-31.13c9.45-11.02,18.8-22.11,28.22-33.15
                    c2.23-2.61,4.63-5.09,6.87-7.69c1.62-1.88,2.92-1.82,4.28,0.29c2.14,3.32,1.87,5.87-1.27,9.38c-7.73,8.63-15.54,17.2-23.22,25.88
                    c-5.4,6.1-11.01,12.07-15.86,18.6c-5.76,7.76-12.8,14.25-19.33,21.25c-7.93,8.49-15.98,16.88-24.24,25.05
                    c-15.27,15.09-31.62,28.89-50.47,39.35c-11.73,6.51-23.22,13.66-36.37,17.19c-2.57,0.69-5.33,0.69-7.9,1.4
                    c-6.93,1.93-13.98,2.84-21.15,3.17c-0.85,0.04-2.02,1.42-2.38,2.42c-0.75,2.08-1.9,2.73-4.08,2.67
                    c-6.02-0.16-12.05-0.05-18.07-0.05C1697.37,510.56,1697.37,510.43,1697.37,510.29z"/>
                </g>
              </g>

              <g id="mousepad">
                <path className="st0" d="M1356.54-134.08l-59.92,299.16c-11.33,56.56,25.34,111.6,81.9,122.93l152.15,30.48
                  c56.56,11.33,111.6-25.34,122.93-81.9l59.92-299.16c11.33-56.56-25.34-111.6-81.9-122.93l-152.15-30.48
                  C1422.9-227.31,1367.87-190.64,1356.54-134.08z"/>
                <path className="st27" d="M1350.49-135.62l-59.92,299.16c-11.33,56.56,25.34,111.6,81.9,122.93l152.15,30.48
                  c56.56,11.33,111.6-25.34,122.93-81.9l59.92-299.16c11.33-56.56-25.34-111.6-81.9-122.93l-152.15-30.48
                  C1416.86-228.85,1361.82-192.18,1350.49-135.62z"/>
              </g>

              <g id="mouse">
                <path className="st0" d="M1460.22,90.83c11.6-39.21,26.25-62.58,39.19-83.19c11.41-18.18,21.26-33.89,26.21-56.39
                  c2.08-9.46-2.7-15.99-7.77-22.89c-7.96-10.86-16.99-23.17,5.42-44.38c18.55-17.56,41.93-8.46,62.56-0.43
                  c14.9,5.8,28.97,11.27,39.48,6.72c15.21-6.59,23.81-15.44,26.28-27.05c7.99-37.6-49.08-99.68-104.27-159.72
                  c-67.27-73.18-136.83-148.86-97.41-190.38l5.8,5.51c-34.28,36.1,32.71,108.98,97.49,179.46c59.16,64.37,115.05,125.16,106.2,166.79
                  c-3,14.13-13.4,25.13-30.92,32.72c-13.52,5.86-29.81-0.48-45.56-6.61c-20.2-7.86-39.29-15.29-54.16-1.21
                  c-17.29,16.36-12.19,23.32-4.47,33.84c5.62,7.66,11.99,16.35,9.13,29.34c-5.53,25.16-15.25,39.81-27.24,58.92
                  c-12.66,20.18-27.01,43.05-38.29,81.21L1460.22,90.83z"/>
                <g>
                  <path className="st0" d="M1421.29,250.06L1421.29,250.06c-31.71-9.56-49.67-43.02-40.11-74.73l15-49.77
                    c9.56-31.71,43.02-49.67,74.73-40.11l0,0c31.71,9.56,49.67,43.02,40.11,74.73l-15,49.77
                    C1486.46,241.66,1453.01,259.62,1421.29,250.06z"/>
                  <path className="st28" d="M1413.63,243.64L1413.63,243.64c-31.71-9.56-49.67-43.02-40.11-74.73l15-49.77
                    c9.56-31.71,43.02-49.67,74.73-40.11l0,0c31.71,9.56,49.67,43.02,40.11,74.73l-15,49.77
                    C1478.8,235.24,1445.35,253.2,1413.63,243.64z"/>
                  <path className="st29" d="M1446.64,134.4L1446.64,134.4c-3.77-1.14-5.9-5.11-4.76-8.87l3.75-12.44c1.14-3.77,5.11-5.9,8.87-4.76l0,0
                    c3.77,1.14,5.9,5.11,4.76,8.87l-3.75,12.44C1454.38,133.4,1450.41,135.53,1446.64,134.4z"/>
                  <path className="st2" d="M1455.52,129.63l3.75-12.44c1.11-3.67-0.91-7.52-4.5-8.77l-7.99,26
                    C1450.5,135.47,1454.4,133.36,1455.52,129.63z"/>
                </g>
                <path className="st30" d="M1454.1,85.7c11.6-39.21,26.25-62.58,39.19-83.19c11.41-18.18,21.26-33.89,26.21-56.39
                  c2.08-9.46-2.7-15.99-7.77-22.89c-7.96-10.86-16.99-23.17,5.42-44.38c18.55-17.56,41.93-8.46,62.56-0.43
                  c14.9,5.8,28.97,11.27,39.48,6.72c15.21-6.59,23.81-15.44,26.28-27.05c7.99-37.6-49.08-99.68-104.26-159.72
                  c-67.27-73.18-136.83-148.86-97.41-190.38l5.8,5.51c-34.28,36.1,32.71,108.98,97.49,179.46c59.16,64.37,115.05,125.16,106.2,166.79
                  c-3,14.13-13.4,25.13-30.92,32.72c-13.52,5.86-29.81-0.48-45.56-6.61c-20.2-7.86-39.29-15.29-54.16-1.21
                  c-17.29,16.36-12.19,23.32-4.47,33.84c5.62,7.66,11.99,16.35,9.13,29.34c-5.25,23.86-15.44,40.11-27.24,58.92
                  c-12.66,20.18-27.01,43.05-38.29,81.21L1454.1,85.7z"/>
              </g>

              <g id="ipad">
                <path className="st31" d="M-325.35,342.54c-17.56-24.56-11.83-59.02,12.73-76.58L-90.6,107.21c24.56-17.56,59.02-11.83,76.58,12.73
                  L243.8,480.52c17.56,24.56,11.83,59.02-12.73,76.58L9.04,715.85c-24.56,17.56-59.02,11.83-76.58-12.73L-325.35,342.54z"/>
                <rect x="-213.63" y="194.4" transform="matrix(0.8135 -0.5816 0.5816 0.8135 -246.9615 53.0502)" className="st32" width="345.7" height="434.26"/>
                <g>
                  <circle className="st33" cx="95.86" cy="614.94" r="19.21"/>
                  <path className="st33" d="M90.1,606.88c7.32-5.23,17.03-4.54,23.58,1.04c-0.56-1.43-1.25-2.84-2.19-4.15
                    c-6.17-8.63-18.17-10.63-26.8-4.45c-8.63,6.17-10.63,18.17-4.45,26.8c0.94,1.31,2.04,2.42,3.22,3.41
                    C80.29,621.52,82.78,612.11,90.1,606.88z"/>
                  <path className="st33" d="M78.74,627.18c-6.75-9.44-4.56-22.61,4.88-29.36c9.44-6.75,22.61-4.56,29.36,4.88
                    c6.75,9.44,4.56,22.61-4.88,29.36C98.66,638.81,85.49,636.62,78.74,627.18z M110,604.83c-5.57-7.8-16.45-9.6-24.25-4.03
                    c-7.8,5.57-9.6,16.45-4.03,24.25c5.57,7.8,16.45,9.6,24.25,4.03C113.77,623.51,115.58,612.63,110,604.83z"/>
                </g>
                <g>
                  <circle className="st34" cx="-195.26" cy="219.15" r="12.81"/>
                  <path className="st35" d="M-174.84,193.62c3.99-2.86,9.55-1.93,12.41,2.06c2.86,4,1.93,9.55-2.06,12.41
                    c-3.99,2.86-9.55,1.93-12.41-2.06C-179.76,202.03-178.84,196.47-174.84,193.62z"/>
                </g>
                <polygon className="st36" points="136.26,361.95 -254.03,297.09 -307.67,335.44 -267.97,390.96 214.31,471.11 	"/>
                <polygon className="st37" points="169.45,528.14 -215.65,464.14 -180.06,513.92 119.68,563.73 	"/>
              </g>

              <g id="coffee-mug">
                <path className="st0" d="M392.03,718.81c-4.37-6.15-12.9-7.59-19.05-3.21l-34.79,24.76C316.9,712.2,283.14,694,245.12,694
                  c-64.41,0-116.62,52.21-116.62,116.62s52.21,116.62,116.62,116.62c64.41,0,116.62-52.21,116.62-116.62
                  c0-16.63-3.5-32.43-9.77-46.74c0.59-0.3,1.16-0.63,1.72-1.03l35.14-25C394.97,733.48,396.41,724.95,392.03,718.81z"/>
                <g>
                  <path className="st38" d="M374.65,718.81c-4.37-6.15-12.9-7.59-19.05-3.21l-34.79,24.76C299.52,712.2,265.76,694,227.74,694
                    c-64.41,0-116.62,52.21-116.62,116.62c0,64.41,52.21,116.62,116.62,116.62s116.62-52.21,116.62-116.62
                    c0-16.63-3.5-32.43-9.77-46.74c0.59-0.3,1.16-0.63,1.72-1.03l35.14-25C377.59,733.48,379.02,724.95,374.65,718.81z"/>
                  <ellipse transform="matrix(0.1296 -0.9916 0.9916 0.1296 -605.5675 931.3627)" className="st39" cx="227.74" cy="810.62" rx="91.79" ry="91.79"/>
                  <path className="st40" d="M186.5,804.83c0-38.1,23.34-70.84,56.46-84.71c-4.96-0.83-10.04-1.29-15.23-1.29
                    c-50.61,0-91.79,41.18-91.79,91.79c0,50.62,41.18,91.79,91.79,91.79c12.51,0,24.45-2.53,35.33-7.08
                    C219.68,888.06,186.5,850.26,186.5,804.83z"/>
                  <ellipse className="st41" cx="261.92" cy="784.55" rx="19.57" ry="12"/>
                </g>
              </g>
            
                        
              <g id="notepad">
                <path className="st13" d="M1418.81,397.53l0.07,0.65l-11.38,1.15c-4.15,0.42-7.18,4.13-6.76,8.28l0.26,2.57l0.26,2.57
                  c0.42,4.15,4.13,7.18,8.28,6.76l11.38-1.15l2.43,24.1l-11.38,1.15c-4.16,0.42-7.18,4.13-6.76,8.28l0.26,2.57l0.26,2.57
                  c0.42,4.15,4.13,7.18,8.28,6.76l11.38-1.15l2.43,24.1l-11.38,1.15c-2.08,0.21-3.87,1.24-5.1,2.74c-1.22,1.5-1.88,3.46-1.67,5.54
                  l0,0l0.26,2.57l0.26,2.57c0.42,4.15,4.13,7.18,8.28,6.76l11.38-1.15l2.43,24.1l-11.38,1.15c-4.16,0.42-7.18,4.13-6.76,8.28
                  l0.26,2.57l0.26,2.57c0.42,4.15,4.13,7.18,8.28,6.76l11.38-1.15l2.43,24.1l-11.38,1.15c-2.08,0.21-3.87,1.24-5.1,2.74
                  s-1.88,3.46-1.67,5.54l0,0l0.26,2.57l0.26,2.57c0.42,4.16,4.13,7.18,8.28,6.76l11.38-1.15l2.43,24.1l-11.38,1.15
                  c-4.15,0.42-7.18,4.13-6.76,8.28l0.26,2.57l0.26,2.57c0.42,4.15,4.13,7.18,8.28,6.76l11.38-1.15l2.43,24.1l-11.38,1.15
                  c-2.08,0.21-3.87,1.24-5.1,2.74c-1.22,1.5-1.88,3.46-1.67,5.54l0,0l0.26,2.57l0.26,2.57c0.42,4.15,4.13,7.18,8.28,6.76l11.38-1.15
                  l2.43,24.1l-11.38,1.15c-4.15,0.42-7.18,4.13-6.76,8.28l0.26,2.57l0.26,2.57c0.42,4.16,4.13,7.18,8.28,6.76l11.38-1.15l0.02,0.17
                  c1.64,16.19,16.08,27.98,32.27,26.35l421.32-42.56c16.19-1.64,27.98-16.08,26.35-32.27l-33.44-330.98
                  c-1.64-16.19-16.08-27.98-32.27-26.35l-421.32,42.56C1428.97,366.89,1417.18,381.34,1418.81,397.53z"/>
                <path className="st19" d="M1898.56,342.62l37.83,374.43c0.42,4.19-2.63,7.93-6.82,8.35l-464.77,46.95c-4.19,0.42-7.93-2.63-8.35-6.82
                  l-37.83-374.43c-0.42-4.19,2.63-7.93,6.82-8.35l464.77-46.95C1894.4,335.38,1898.14,338.43,1898.56,342.62z"/>
                
                <g id="notepad-spiral">
                  <g>
                    <path className="st21" d="M1485.32,727.72l0.52,5.14c0.42,4.15-2.61,7.86-6.76,8.28l-36.22,3.66c-4.15,0.42-7.86-2.61-8.28-6.76
                      l-0.52-5.14c-0.42-4.15,2.61-7.86,6.76-8.28l36.22-3.66C1481.19,720.53,1484.9,723.56,1485.32,727.72z"/>
                    <path className="st22" d="M1434.06,732.89l0.26,2.57l51.26-5.18l-0.26-2.57c-0.42-4.15-4.13-7.18-8.28-6.76l-36.22,3.66
                      C1436.66,725.03,1433.64,728.74,1434.06,732.89z"/>
                  </g>
                  <g>
                    <path className="st21" d="M1480.85,683.43l0.52,5.14c0.42,4.15-2.61,7.86-6.76,8.28l-36.22,3.66c-4.15,0.42-7.86-2.61-8.28-6.76
                      l-0.52-5.14c-0.42-4.15,2.61-7.86,6.76-8.28l36.22-3.66C1476.72,676.25,1480.43,679.28,1480.85,683.43z"/>
                    <path className="st22" d="M1429.58,688.61l0.26,2.57l51.26-5.18l-0.26-2.57c-0.42-4.15-4.13-7.18-8.28-6.76l-36.22,3.66
                      C1432.19,680.75,1429.16,684.46,1429.58,688.61z"/>
                  </g>
                  <g>
                    <path className="st21" d="M1476.37,639.15l0.52,5.14c0.42,4.15-2.61,7.86-6.76,8.28l-36.22,3.66c-4.16,0.42-7.86-2.61-8.28-6.76
                      l-0.52-5.14c-0.42-4.15,2.61-7.86,6.76-8.28l36.22-3.66C1472.24,631.97,1475.95,634.99,1476.37,639.15z"/>
                    <path className="st22" d="M1425.11,644.33l0.26,2.57l51.26-5.18l-0.26-2.57c-0.42-4.15-4.13-7.18-8.28-6.76l-36.22,3.66
                      C1427.72,636.46,1424.69,640.17,1425.11,644.33z"/>
                  </g>
                  <g>
                    <path className="st21" d="M1471.9,594.87l0.52,5.14c0.42,4.15-2.61,7.86-6.76,8.28l-36.22,3.66c-4.16,0.42-7.86-2.61-8.28-6.76
                      l-0.52-5.14c-0.42-4.15,2.61-7.86,6.76-8.28l36.22-3.66C1467.77,587.68,1471.48,590.71,1471.9,594.87z"/>
                    <path className="st22" d="M1420.63,600.05l0.26,2.57l51.26-5.18l-0.26-2.57c-0.42-4.15-4.13-7.18-8.28-6.76l-36.22,3.66
                      C1423.24,592.18,1420.21,595.89,1420.63,600.05z"/>
                  </g>
                  <g>
                    <path className="st21" d="M1467.42,550.58l0.52,5.14c0.42,4.15-2.61,7.86-6.76,8.28l-36.22,3.66c-4.15,0.42-7.86-2.61-8.28-6.76
                      l-0.52-5.14c-0.42-4.16,2.61-7.86,6.76-8.28l36.22-3.66C1463.3,543.4,1467,546.43,1467.42,550.58z"/>
                    <path className="st22" d="M1416.16,555.76l0.26,2.57l51.26-5.18l-0.26-2.57c-0.42-4.15-4.13-7.18-8.28-6.76l-36.22,3.66
                      C1418.77,547.9,1415.74,551.61,1416.16,555.76z"/>
                  </g>
                  <g>
                    <path className="st21" d="M1462.95,506.3l0.52,5.14c0.42,4.15-2.61,7.86-6.76,8.28l-36.22,3.66c-4.16,0.42-7.86-2.61-8.28-6.76
                      l-0.52-5.14c-0.42-4.15,2.61-7.86,6.76-8.28l36.22-3.66C1458.82,499.12,1462.53,502.15,1462.95,506.3z"/>
                    <path className="st22" d="M1411.69,511.48l0.26,2.57l51.26-5.18l-0.26-2.57c-0.42-4.15-4.13-7.18-8.28-6.76l-36.22,3.66
                      C1414.3,503.62,1411.27,507.32,1411.69,511.48z"/>
                  </g>
                  <g>
                    <path className="st21" d="M1458.48,462.02l0.52,5.14c0.42,4.15-2.61,7.86-6.76,8.28l-36.22,3.66c-4.15,0.42-7.86-2.61-8.28-6.76
                      l-0.52-5.14c-0.42-4.15,2.61-7.86,6.76-8.28l36.22-3.66C1454.35,454.83,1458.06,457.86,1458.48,462.02z"/>
                    <path className="st22" d="M1407.21,467.2l0.26,2.57l51.26-5.18l-0.26-2.57c-0.42-4.15-4.13-7.18-8.28-6.76l-36.22,3.66
                      C1409.82,459.33,1406.79,463.04,1407.21,467.2z"/>
                  </g>
                  <g>
                    <path className="st21" d="M1454,417.73l0.52,5.14c0.42,4.15-2.61,7.86-6.76,8.28l-36.22,3.66c-4.15,0.42-7.86-2.61-8.28-6.76
                      l-0.52-5.14c-0.42-4.15,2.61-7.86,6.76-8.28l36.22-3.66C1449.88,410.55,1453.58,413.58,1454,417.73z"/>
                    <path className="st22" d="M1402.74,422.91l0.26,2.57l51.26-5.18l-0.26-2.57c-0.42-4.15-4.13-7.18-8.28-6.76l-36.22,3.66
                      C1405.35,415.05,1402.32,418.76,1402.74,422.91z"/>
                  </g>
                </g>

              </g>

              <g id="pencil">
                <g>
                  <path className="st18" d="M2024.48-70.98l-11.92-6.02c-3.29-1.66-7.3-0.35-8.97,2.94l-9.37,18.1c-1.67,3.29-0.36,7.32,2.94,8.99
                    l11.92,6.02c3.29,1.66,7.3,0.35,8.97-2.94l9.38-18.1C2029.09-65.29,2027.78-69.32,2024.48-70.98z"/>
                </g>
                <path className="st18" d="M1789.41,370.85l8.34-46.68l30.86,15.59l-32.63,34.41C1793.34,376.79,1788.88,374.53,1789.41,370.85z"/>
                <rect x="1891.15" y="-67.75" transform="matrix(0.8925 0.451 -0.451 0.8925 269.7931 -845.2583)" className="st19" width="34.58" height="422.41"/>
                <g>
                    <rect x="1996.71" y="-62.35" transform="matrix(0.451 -0.8925 0.8925 0.451 1140.2827 1763.6185)" className="st20" width="13.96" height="34.58"/>
                </g>
                <path className="st21" d="M1803.12,366.67c-1.05-2.08-2.77-3.83-4.97-4.95c-2.2-1.11-4.63-1.46-6.91-1.11l-1.85,10.24
                  c-0.52,3.66,3.97,5.94,6.61,3.34L1803.12,366.67z"/>
              </g>

              <g id="pen">
                <path className="st0" d="M1850.5,156l-2.04,1.32l-2.16,1.39l-2.96-4.58l-117.48,75.84c0.18,0.62,0.37,1.26,0.58,1.92
                  c0.97,3.06,2.28,6.58,4.09,10.39c-1.43,1.57-2.17,3.61-2.13,5.68c-1.22,2.49-1.17,5.53,0.45,8.03c2.45,3.8,7.52,4.89,11.32,2.44
                  l0.39-0.25c3.73,4.62,7.43,8.18,10.46,10.76l117.48-75.84l-2.96-4.58l2.1-1.35l164.1-105.93l16.35-25.54
                  c0.46-0.72,0.67-1.47,0.79-2.22c3.02-2.64,4.05-5.35,3.07-6.87c-1-1.54-3.73-1.34-7.39,0.54c-0.65-0.14-1.33-0.21-2.06-0.11
                  l-7.44,1.09l0,0l-22.56,3.3L1850.5,156L1850.5,156z"/>
                <g>
                  <path className="st22" d="M2056.36,53.5c-1.2-1.86-4.91-1.23-9.74,1.89c-4.83,3.12-7.77,7.15-6.56,9.01c1.2,1.86,6.09,0.84,10.91-2.27
                    C2055.8,59.02,2057.56,55.36,2056.36,53.5z"/>
                  <path className="st23" d="M2036.14,88.13l16.35-25.54c2.62-4.09-0.78-9.36-5.59-8.66l-30,4.39l-184.4,119.03l19.24,29.8L2036.14,88.13
                    z"/>
                  <polygon className="st24" points="2039.47,55.03 2016.91,58.33 1832.51,177.36 1837.05,184.39 		"/>
                  <path className="st23" d="M1730.27,236.87l117.48-75.84l25.16,38.97l-117.48,75.84c-4.52-3.85-10.53-9.82-15.9-18.34
                    C1734.58,249.66,1731.84,242.31,1730.27,236.87z"/>
                  <path className="st24" d="M1736.53,247.79l85.96-55.49c3.8-2.45,8.86-1.36,11.32,2.44c2.45,3.8,1.36,8.86-2.44,11.32l-85.96,55.49
                    c-3.8,2.45-8.86,1.36-11.32-2.44C1731.64,255.31,1732.74,250.24,1736.53,247.79z"/>
                  <path className="st25" d="M1735.7,251.57l85.96-55.49c3.8-2.45,8.86-1.36,11.32,2.44c2.45,3.8,1.36,8.86-2.44,11.32l-85.96,55.49
                    c-3.8,2.45-8.86,1.36-11.32-2.44S1731.9,254.03,1735.7,251.57z"/>
                  <rect x="1859.93" y="161.42" transform="matrix(-0.8402 0.5423 -0.5423 -0.8402 3524.3315 -680.3934)" className="st24" width="5" height="35.47"/>
                  <line className="st26" x1="1822.62" y1="208.46" x2="1763.02" y2="246.93"/>
                  <path className="st24" d="M1852.72,168.73l-4.97-7.7l-117.48,75.84c0.7,2.42,1.65,5.23,2.9,8.27L1852.72,168.73z"/>
                </g>
              </g>

            </svg>
  {/* 
            <div id="notepad-text">
              <div className="notepad-header st10 st11 st12">
                Why Red Lion?
              </div>
              <div className="notepad-content st11" >
                <span className="text-wrapper">
                  <span className="letters">
                      “Red Lion is amazing because of the people that make up our agency. We are one big family,
                      dedicated to providing the best care for our clients. We strive to be the best agency around!”
                  </span>
                </span>
              </div>
            </div>
  */}
          </div>

          <div id="notepad-page-1-container" className={`desktop enter ${notepadPage===1 ? 'ontop' : 'onbottom'}`}>
            <svg  
              version="1.1" 
              xmlns="http://www.w3.org/2000/svg" 
              xmlnsXlink="http://www.w3.org/1999/xlink" 
              x="0px" y="0px"
              viewBox="0 0 1920 5580" // 1920 1280
            >    
              <g id="notepad-page-1-enter-animation">      
                <g id="notepad-page-1"
                  className={exitProfileIndex>-1 && notepadPage===1 ? 'exit' : ''}
                  onAnimationEnd={(e) => playAnimation(e, 'notepad-page-1')}
                >           
                  <path className="st20" d="M1916.8,711.48l-442.27,44.68c-2.63,0.27-4.98-1.65-5.25-4.28l-0.98-9.65
                    l10.77-1.09c4.16-0.42,7.19-4.13,6.77-8.29l-0.26-2.56l-0.26-2.57c-0.42-4.16-4.13-7.19-8.28-6.77l-10.77,1.09l-2.44-24.1
                    l10.77-1.09c4.16-0.42,7.18-4.12,6.76-8.28l-0.26-2.57l-0.25-2.57c-0.42-4.15-4.13-7.18-8.29-6.76l-10.77,1.09l-2.43-24.1
                    l10.77-1.09c4.15-0.42,7.18-4.13,6.76-8.28l-0.52-5.14c-0.42-4.16-4.13-7.18-8.28-6.76l-10.77,1.09l-2.44-24.1l10.77-1.09
                    c4.16-0.42,7.19-4.13,6.77-8.28l-0.26-2.57l-0.26-2.57c-0.42-4.16-4.13-7.19-8.29-6.77l-10.77,1.09l-2.43-24.1l10.77-1.09
                    c4.16-0.41,7.18-4.12,6.76-8.28l-0.52-5.14c-0.42-4.15-4.12-7.18-8.28-6.76l-10.77,1.09l-2.43-24.1l10.77-1.09
                    c4.15-0.42,7.18-4.13,6.76-8.28l-0.26-2.57l-0.26-2.57c-0.42-4.15-4.13-7.18-8.28-6.76l-10.77,1.09l-2.44-24.1l10.77-1.09
                    c4.16-0.42,7.19-4.13,6.77-8.28l-0.52-5.14c-0.42-4.16-4.13-7.19-8.29-6.77l-10.77,1.09l-2.43-24.09l10.77-1.09
                    c4.15-0.42,7.18-4.13,6.76-8.29l-0.52-5.14c-0.42-4.15-4.12-7.18-8.28-6.76l-10.77,1.09l-0.83-8.21
                    c-0.26-2.63,1.65-4.98,4.29-5.25l442.27-44.68c2.63-0.26,4.98,1.66,5.25,4.29l35.15,348.03
                    C1921.35,708.87,1919.43,711.22,1916.8,711.48z"/>

                  <text className="notepad-copy" transform="matrix(0.9946 -0.1042 0.1042 0.9946 1483.6826 461.8905)">
                    <tspan className={`notepad-header st10 st11 st12 ${language}`} x="0" y="0">
                      {ourTeam[language].quoteHeading}
                    </tspan>
    {/*
                    <tspan className="notepad-content st11" x="0" y="62">
                      “Red Lion is amazing because 
                    </tspan>
                    <tspan className="notepad-content st11" x="0" y="98">
                      of the people that make up our 
                    </tspan>
                    <tspan className="notepad-content st11" x="0" y="134">
                      agency. We are one big family, 
                    </tspan>
                    <tspan className="notepad-content st11" x="0" y="170">
                      dedicated to providing the best 
                    </tspan>
                    <tspan className="notepad-content st11" x="0" y="206">
                      care for our clients. We strive 
                    </tspan>
                    <tspan className="notepad-content st11" x="0" y="242">
                      to be the best agency around!”
                    </tspan>
    */}
                  </text>
            
                  <foreignObject x="0" y="36" width="380" height="300" transform="matrix(0.9946 -0.1042 0.1042 0.9946 1483.6826 461.8905)">
                      <div className="notepad-content" xmlns="http://www.w3.org/1999/xhtml">
                        {notepadPage===1 && exitProfileIndex>-1 ? profiles[exitProfileIndex].copy[language].quote : notepadPage===1 && profiles[profileIndex].copy[language].quote}
                        {notepadPage===2 && nextProfileIndex>-1 && profiles[nextProfileIndex] && profiles[nextProfileIndex].copy[language].quote}
                      </div>
                  </foreignObject>
                </g>
              </g>    
            </svg>

          </div>

          <div id="notepad-page-2-container"  className={`desktop enter ${notepadPage===2 ? 'ontop' : 'onbottom'}`}>
            <svg 
              version="1.1" 
              xmlns="http://www.w3.org/2000/svg" 
              xmlnsXlink="http://www.w3.org/1999/xlink" 
              x="0px" y="0px"
              viewBox="0 0 1920 5580" // 1920 1280
            >       
              <g id="notepad-page-2-enter-animation">
                <g id="notepad-page-2"
                  className={exitProfileIndex>-1 && notepadPage===2 ? 'exit' : ''}
                  onAnimationEnd={(e) => playAnimation(e, 'notepad-page-2')}
                >
                  <path className="st20" d="M1916.8,711.48l-442.27,44.68c-2.63,0.27-4.98-1.65-5.25-4.28l-0.98-9.65
                    l10.77-1.09c4.16-0.42,7.19-4.13,6.77-8.29l-0.26-2.56l-0.26-2.57c-0.42-4.16-4.13-7.19-8.28-6.77l-10.77,1.09l-2.44-24.1
                    l10.77-1.09c4.16-0.42,7.18-4.12,6.76-8.28l-0.26-2.57l-0.25-2.57c-0.42-4.15-4.13-7.18-8.29-6.76l-10.77,1.09l-2.43-24.1
                    l10.77-1.09c4.15-0.42,7.18-4.13,6.76-8.28l-0.52-5.14c-0.42-4.16-4.13-7.18-8.28-6.76l-10.77,1.09l-2.44-24.1l10.77-1.09
                    c4.16-0.42,7.19-4.13,6.77-8.28l-0.26-2.57l-0.26-2.57c-0.42-4.16-4.13-7.19-8.29-6.77l-10.77,1.09l-2.43-24.1l10.77-1.09
                    c4.16-0.41,7.18-4.12,6.76-8.28l-0.52-5.14c-0.42-4.15-4.12-7.18-8.28-6.76l-10.77,1.09l-2.43-24.1l10.77-1.09
                    c4.15-0.42,7.18-4.13,6.76-8.28l-0.26-2.57l-0.26-2.57c-0.42-4.15-4.13-7.18-8.28-6.76l-10.77,1.09l-2.44-24.1l10.77-1.09
                    c4.16-0.42,7.19-4.13,6.77-8.28l-0.52-5.14c-0.42-4.16-4.13-7.19-8.29-6.77l-10.77,1.09l-2.43-24.09l10.77-1.09
                    c4.15-0.42,7.18-4.13,6.76-8.29l-0.52-5.14c-0.42-4.15-4.12-7.18-8.28-6.76l-10.77,1.09l-0.83-8.21
                    c-0.26-2.63,1.65-4.98,4.29-5.25l442.27-44.68c2.63-0.26,4.98,1.66,5.25,4.29l35.15,348.03
                    C1921.35,708.87,1919.43,711.22,1916.8,711.48z"/>

                  <text className="notepad-copy" transform="matrix(0.9946 -0.1042 0.1042 0.9946 1483.6826 461.8905)">
                    <tspan className={`notepad-header st10 st11 st12 ${language}`} x="0" y="0">
                      {ourTeam[language].quoteHeading}
                    </tspan>
                  </text>

                  <foreignObject x="0" y="36" width="380" height="300" transform="matrix(0.9946 -0.1042 0.1042 0.9946 1483.6826 461.8905)">
                    <div className="notepad-content" xmlns="http://www.w3.org/1999/xhtml">
                      {notepadPage===2 && exitProfileIndex>-1 ? profiles[exitProfileIndex].copy[language].quote : notepadPage===2 && profiles[profileIndex].copy[language].quote}
                      {notepadPage===1 && profiles[nextProfileIndex] && profiles[nextProfileIndex].copy[language].quote}
                    </div>
                  </foreignObject>
                </g>
              </g>
            </svg>

          </div>

          <div id="wrap-container"
            className={exitProfileIndex>-1 ? 'exit' : playEnter>0 ? 'enter' : ''}
            onAnimationEnd={(e) => playAnimation(e, 'wrap-container')}
          >
            <div>
              <div className="wrap" id="wrap">
              { profiles && 
                profiles[profileIndex] && 
                profiles[profileIndex].images.length>0 &&
                profiles[profileIndex].images.map((photo, index) => {
                return (
                  <div 
                    key={'photo_'+index}
                    ref={el => photoRefs.current[index] = el}  
                    id={'photo_'+index} 
                    className={index === 0 ? 'photo photo_front photo_profile' : 'photo photo_front'} 
                    onClick={(e) => turn(e, index)}
                  >
                    <div 
                      className="photo-wrap"
                      onAnimationEnd={(e) => playAnimation(e, 'photo-wrap')}
                    >
                      <div className="side side-front">
                        <div>
                          <img src={photo.imageUrl} alt={photo.copy[language].caption} />
                          {/* src={process.env.PUBLIC_URL+'/images/'+photo.src} */}
                        </div>
                        <div className="caption">
                          {ReactHtmlParser(photo.copy[language].caption.replace(/\\n/g,'<br />'))}
                        </div>
                      </div>
                      <div className="side side-back">
                        <p className="description">{photo.description}</p>
                      </div>
                    </div>
                  </div>
                )})
              }
            </div>
            </div>
          </div>
          
          <div className="nav">
            { profiles && 
              profiles[profileIndex] && 
              profiles[profileIndex].images.length>0 &&
              profiles[profileIndex].images.map((photo, index) => 
                <span 
                  key={'nav_'+index} 
                  id={'nav_'+index} 
                  className="i" 
                  onClick ={(e) => turn(e, index)}
                >&nbsp;</span>
            )}
          </div>

          <div 
            className="profile-nav"
            style={{minWidth: `${screenWidth < 600 ? 250 : (dotsTotal*50)+(2*42)}px`}}
          >
       
              {screenWidth > 600
                ? <>
                    <div 
                      className={`arrow-left ${dotsScroll===0 ? 'disabled' : ''}`}
                      onClick={() => setSlideDots(-increment)}
                    >
                    </div>
                    <div className="profile-nav-slider"
                      style={{width: `${dotsTotal*50}px`}}
                    >
                      <SlideNavigation
                        dotsTotal={dotsTotal}
                        slides={profiles}
                        profileIndex={profileIndex}
                        nextProfileIndex={nextProfileIndex}
                        goToProfile={goToProfile}
                        dotsScroll={dotsScroll}
                        setDotsScroll={setDotsScroll}
                        slideDots={slideDots}
                        setSlideDots={setSlideDots}
                      />
                      {/* 
                        <div>
                          { profiles && 
                            profiles.map((profile, index) => {
                              if (profile.images && profile.images[0] && profile.images[0].src) {
                                return (
                                  <div 
                                    key={'profile_nav_'+index} 
                                    id={'profile_nav_'+index}  
                                    className={profileIndex===index ? 'profile-nav-dot selected' : 'profile-nav-dot'}
                                    onClick={()=>goToProfile(index)}
                                  >
                                    <img src={process.env.PUBLIC_URL+'/images/'+profile.images[0].src} alt={profile.name} title={profile.name} />  
                                  </div>
                                )
                              } else {
                                return <></>
                              }
                            })
                          }
                        </div>
                      */}
                    </div>
                    <div 
                      className={`arrow-right ${dotsScroll>=((profiles.length - dotsTotal) * 50) ? 'disabled' : ''}`} 
                      onClick={() => setSlideDots(increment)}
                    >
                    </div>
                  </>
                : <>
                    <div className="profile-nav-slider"
                      style={{width: `${dotsTotal*50}px`}}
                    >
                      <SlideNavigation
                        dotsTotal={dotsTotal}
                        slides={profiles}
                        profileIndex={profileIndex}
                        nextProfileIndex={nextProfileIndex}
                        goToProfile={goToProfile}
                        dotsScroll={dotsScroll}
                        setDotsScroll={setDotsScroll}
                        slideDots={slideDots}
                        setSlideDots={setSlideDots}
                      />
                    </div>
                    <div id="arrows-container">
                      <div 
                        className={`arrow-left ${dotsScroll===0 ? 'disabled' : ''}`}
                        onClick={() => setSlideDots(-increment)}
                      >
                      </div>
                      <div 
                        className={`arrow-right ${dotsScroll>=((profiles.length - dotsTotal) * 50) ? 'disabled' : ''}`} 
                        onClick={() => setSlideDots(increment)}
                      >
                      </div>
                    </div>
                  </>
              }

           
              <div className="team-button" onClick={openDirectoryModal} >
                <div className="team-button-background">
                  {language==='es' ? 'Directorio del equipo' : 'Team Directory'}
                  {/*ReactHtmlParser(teamDirectoryButton)*/}
                </div>
              </div>
        
          </div>



  {/* 
          <div id="section-1" className="foreground">
            <div className="left">
              <div className="heading">WHY RED LION?</div>
              <div>
                We focus on patient’s health care choices by advocating for their independence.<br/ ><br />
                Our caregivers are valued so much, that they own the company.
              </div>
            </div>

          </div>
  */}

      </div> 
      
      {showDirectoryModal && 
        <ModalPortal showModal={openDirectoryModal}>
          <DirectoryModal 
            setShowDirectoryModal={setShowDirectoryModal}
            language={language} 
            profiles={profiles} 
            goToProfile={goToProfile}
          />
        </ModalPortal>
      }
    </>
  );

}

export default OurTeam;
