import { useEffect } from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'; // enableBodyScroll

/* Fix for iOs Safari */
// const isSafari = navigator.userAgent.indexOf("Safari") !== -1;
// const isIphone = navigator.userAgent.indexOf("iPhone") !== -1; 
// const isIpad = navigator.userAgent.indexOf("iPad") !== -1; // iOs can spoof Mac userAgent if Request Desktop Site is turn on in settings
const isTouchDevice = window.matchMedia('(hover: none)').matches===true; 
const isMobileIosSafari =  isTouchDevice // isSafari && isTouchDevice;

function useDisableBodyScroll (disableRef, excludedRef = null) {

  const resizeWindow = () => {
    document.body.style.height=window.innerHeight+'px'; // '100vh' doesn't account for iOs address bar issues
    document.getElementById('root').style.height=window.innerHeight+'px'; /* Yes, this is needed ... otherwise messing up on booking info form step */
  }

  useEffect(() => {
    if (!disableRef.current) return;
    const element = disableRef.current;
    
    const scrollToTop = () => {
      var ua = window.navigator.userAgent;
      if (ua.indexOf('MSIE ') > 0 ||
      ua.indexOf('Trident/') > 0 ||
      ua.indexOf('Edge/') > 0) {
        element.scrollTo(0,0);
      } else {
        element.scroll({ 
          top: 0, 
          left: 0, 
          behavior: 'smooth'
        });
      }
    }

    
  
    // Fix for iOs Safari
    // disableBodyLock is not working for iOs Safari... 
    // Scrolling sometimes freezes at bottom or top of modal
    // The solution is to prevent touch events from working on anything except menu element and elements inside of it */
    const handleTouchMove = (e) => {
      if (!element) return;

      const insideElement = [];
   
      element.querySelectorAll("*").forEach((node) => insideElement.push(node));
  
      const targetIsElement = e.target.id === element.id;
     // console.log('e.target ', e.target, e.target.id);
      const targetIsExcluded = excludedRef && excludedRef.current ? e.target.id === excludedRef.current.id : false;
      const modalContainsTarget = insideElement.includes(e.target);
      const shouldPreventDefault = !(targetIsElement || modalContainsTarget) || targetIsExcluded; 
     // console.log('shouldPreventDefault',shouldPreventDefault); 
      if (shouldPreventDefault) e.preventDefault();
    };
  
    if (isMobileIosSafari) {  
      window.addEventListener("touchmove", handleTouchMove, {passive: false});
      window.addEventListener('resize', resizeWindow);
      document.body.style.overflow='hidden';
      document.getElementById('root').style.overflow='hidden'; /* Yes, this is needed ... otherwise messing up on booking info form step */
      resizeWindow();
    } else {
      const options = {reserveScrollBarGap: true};
      clearAllBodyScrollLocks();
      disableBodyScroll(element, options);
    } 
   
    scrollToTop();
    
    return () => {
      if (isMobileIosSafari) {
        window.removeEventListener("touchmove", handleTouchMove, {passive: false}); 
        window.removeEventListener('resize', resizeWindow);
        document.body.style.height=window.innerHeight+'px';
        document.body.style.overflow='initial';
        document.getElementById('root').style.height=window.innerHeight+'px';
        document.getElementById('root').style.overflow='initial';
      }
    }
  }, [disableRef, excludedRef]);

  return;
}

export default useDisableBodyScroll;