import React from 'react';
import './Loader.scss';

const Loader = ({size, stroke}) => {
  return (
    <div className="loader-container" size={size}>
      <div className="spinner"> 
        <svg viewBox="0 0 50 50">
          <circle cx="25" cy="25" r="20" fill="none" strokeWidth="5" stroke={stroke ? stroke : '#EC6A83'}></circle>
        </svg>
      </div>
    </div>
  )
};

export default Loader;
