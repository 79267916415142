import React, { useState, useRef, useEffect} from 'react';
import { enableBodyScroll } from 'body-scroll-lock'  // from 'body-scroll-lock'; // disableBodyScroll, clearAllBodyScrollLocks
import useDisableBodyScroll from '../../hooks/useDisableBodyScroll';
import ReactHtmlParser from 'react-html-parser';

import './FormModal.scss';
// import english from '../../translations/en.json'; /* For use in sending qualifying options in English to API when page is in Spanish */

const submitUrl = process.env.REACT_APP_API_BASE_URL+'/submit-form.php';
/*
const checkboxLabels = [
  'I am over 21 years old or live in a nursing home.', //   'I am over 21 years old.',
  // 'I live in a nursing facility.',
  'I receive Medicare and/or Medicaid (dual eligible).',
  //'I am dually eligible for Medicare and Medicaid.',
  'I have a physical disability or a traumatic brain injury.'
];
*/

const FormModal = ({formName, setShowFormModal, translation, englishFormData}) => {

  const modalRef = useRef();
  const closeIconRef = useRef();

  const scrollToTop = () => {
    var ua = window.navigator.userAgent;
    if (ua.indexOf('MSIE ') > 0 ||
    ua.indexOf('Trident/') > 0 ||
    ua.indexOf('Edge/') > 0) {
      modalRef.current.scrollTo(0,0);
    } else {
      modalRef.current.scroll({ 
        top: 0, 
        left: 0, 
        behavior: 'smooth'
      });
    }
  }

  useDisableBodyScroll(modalRef);

  /*
  useEffect(() => {
    const options = {reserveScrollBarGap: true};
    disableBodyScroll(modalRef.current, options);
    scrollToTop();
  }, [modalRef]);
  */

  const [modalClass, setModalClass] = useState('show');

  const closeModal = (e) => {
      if (
        !e ||
        (modalRef.current && !modalRef.current.contains(e.target)) ||
        (closeIconRef && closeIconRef.current.contains(e.target))
      ) {
        setModalClass('hide');
      }
  }

  const handleModalOnEndAnimation = (e) => {
    if (e.animationName==='exit-form-modal') {
      enableBodyScroll(modalRef.current); /* Must enable before modal disappears */
      setShowFormModal(false);
    }
  }

  const [formData, setFormData]= useState({
    formName: formName,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    zipCode: '',
    message: '',
    checkboxes: [],
  })

  const formRef = useRef();
  const submitButtonRef = useRef();

  /* Prevent hitting return from submitting contact form. */
  const preventSubmitWithReturn = (e) => {
      var key = e.charCode || e.keyCode || 0;     
      if (key === 13 && e.target!==document.getElementById('message')) {
          e.preventDefault();
      }
  }

  const onChangeInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })

    if (e.target.value!=='') {
      e.target.setAttribute('data-filled', 'Y');
    } else {
      e.target.setAttribute('data-filled', 'N');
    }    
  }

  const validateEmail = (e) => {
    if (e.target.value!=='') {
      // ^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$
      if (!e.target.value.match(/^([A-Za-z0-9+_-]+)(\.[A-Za-z0-9+_-]+)*@([A-Za-z0-9-]+\.)+[A-Za-z]{2,6}$/g)) {
        e.target.setCustomValidity("Invalid format."); 
        return;
      };
    }
    e.target.setCustomValidity('');
  }

  const validatePhone = (e) => {
    if (e.target.value!=='') {
        var phoneDigits = e.target.value.replace(/[^0-9]/g, '');
        if (phoneDigits.length!==10) {
          e.target.setCustomValidity('Must be 10 digits long.'); 
          return;
        }
        e.target.value = '('+phoneDigits.substr(0,3)+') '+phoneDigits.substr(3,3)+'-'+phoneDigits.substr(6,4);
        }
    e.target.setCustomValidity(''); 
  }

  const validateZipCode = (e) => {
    if (e.target.value!=='') {
        var zipDigits = e.target.value.replace(/[^0-9]/g, '');
        if (zipDigits.length!==5 && zipDigits.length!==9) {
          e.target.removeAttribute('title'); 
          e.target.setCustomValidity('Must be 5 or 9 digits long.'); 
          return;
        }
        e.target.value = (zipDigits.length===9)
          ? zipDigits.substr(0,5)+'-'+zipDigits.substr(5,4)
          : zipDigits;
        }
    e.target.setCustomValidity('');
  }


  const [submitStatus, setSubmitStatus] = useState();
  
  const submitForm = async (e) => {
    if (!formRef.current || !submitButtonRef.current) return;

    // Note: onSubmit attribute in form tag prevents the form from being submitted but still click submit button with next line to use native validate functionality
    submitButtonRef.current.click();

    if (formRef.current && formRef.current.checkValidity()) {
      setSubmitStatus('submitted');
      await fetch(submitUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(formData) 
      })
        .then(response => response.json())
        .then(data => {
          // console.log(data); 
          if (data.success===true) {
            setSubmitStatus('success');
          } else {
            console.log('API Error: ', data.error);
            setSubmitStatus('error');
          }
        })
        .catch (err => {
          console.log('API Error: ', err);
          setSubmitStatus('error');
        });
    }
  }

  const scrollToInvalid = () => {
    (function () {
      // If not done as a closure, it will fire for every invalid field (not just the first one) on form submit
      var inView = false;
      return (e) => {
        if (!inView) {
          e.target.scrollIntoView({
            behavior: 'smooth', 
            block: 'start', 
            inline: 'nearest'
          })
        }; 
        inView = true;
      }
    })();
  }

  const checkboxRefs = useRef([]);
  
  const handleChangeCheckbox = (e) => {
    let newCheckboxes = [];
    checkboxRefs.current.forEach((el, index) => {
      if (el && el.checked) newCheckboxes.push(englishFormData.checkboxes.labels[index]); // el.value
    });
    setFormData({
      ...formData,
      checkboxes: newCheckboxes
    });
  }

  return (
    <div 
      id="form-modal-wrapper" 
      onClick={(e) => closeModal(e)}
      className={modalClass}
      onAnimationEnd={(e) => handleModalOnEndAnimation(e)}
    >
      <div id="form-modal-container" onKeyPress={(e) => preventSubmitWithReturn(e)}>
        
        <div id="form-modal-content" ref={modalRef}>

          <div ref={closeIconRef} className="icon-close-modal" onClick={(e) => closeModal(e)}></div>

          {submitStatus==='success'  
            ? <div id="success-message">
                <div>{ReactHtmlParser(translation.successMessage.header)}</div> 
                <div>{ReactHtmlParser(translation.successMessage.copy)}</div>
              </div> 
            : <>

                <div id="form-modal-header">{translation.header}</div>
                
                {/*<div id="speech-bubble"></div>*/}

                <div id="form-container">
                  <form id="form" ref={formRef} autoComplete="on" onSubmit={(e) => e.preventDefault()} method="post" acceptCharset="utf-8">
                    <div className="form-row">
                        <div className="form-field">
                            <label className="form-input-label-tag">
                                <input className="form-input-field" name="firstName" id="first-name" type="text" placeholder="&nbsp;" 
                                    data-filled={formData.firstName!=='' ? 'Y' : 'N'} 
                                    value={formData.firstName}
                                    onChange={(e) => onChangeInput(e)}
                                    onInvalid={(e) => scrollToInvalid(e)} 
                                    required
                                /> 
                                <span className="form-input-desc">{translation.firstName}</span>
                                <span className="form-input-border"></span>
                            </label>
                        </div>
                        <div className="form-field">
                            <label className="form-input-label-tag">
                                <input className="form-input-field" name="lastName" id="last-name" type="text" placeholder="&nbsp;" 
                                    data-filled={formData.lastName!=='' ? 'Y' : 'N'}
                                    value={formData.lastName}  
                                    onChange={(e) => onChangeInput(e)} 
                                    onInvalid={(e) => scrollToInvalid(e)}
                                    required 
                                />
                                <span className="form-input-desc">{translation.lastName}</span>
                                <span className="form-input-border"></span>
                            </label>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-field">
                            <label className="form-input-label-tag">
                                <input className="form-input-field" name="phone" id="phone" type="tel" placeholder="&nbsp;"
                                    data-filled={formData.phone!=='' ? 'Y' : 'N'} 
                                    value={formData.phone}  
                                    onChange={(e) => onChangeInput(e)} 
                                    onBlur={(e) => validatePhone(e)} 
                                    onInvalid={(e) => scrollToInvalid(e)}
                                />
                                <span className="form-input-desc">{translation.phone}</span>
                                <span className="form-input-border"></span>
                            </label>
                        </div>
                        <div className="form-field">
                          <label className="form-input-label-tag">
                              <input className="form-input-field" name="zipCode" id="zipCode" placeholder="&nbsp;"
                                  data-filled={formData.zipCode!=='' ? 'Y' : 'N'} 
                                  value={formData.zipCode}  
                                  onChange={(e) => onChangeInput(e)} 
                                  onBlur={(e) => validateZipCode(e)} 
                                  onInvalid={(e) => scrollToInvalid(e)}
                                  maxLength={10}
                              />
                              <span className="form-input-desc">{translation.zipCode}</span>
                              <span className="form-input-border"></span>
                          </label>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-field">
                            <label className="form-input-label-tag">
                                <input className="form-input-field" name="email" id="email" placeholder="&nbsp;" 
                                    data-filled={formData.email!=='' ? 'Y' : 'N'} 
                                    value={formData.email}
                                    onChange={(e) => onChangeInput(e)} 
                                    onBlur={(e) => validateEmail(e)} 
                                    onInvalid={(e) => scrollToInvalid(e)}
                                    required 
                                />
                                <span className="form-input-desc">{translation.email}</span>
                                <span className="form-input-border"></span>
                            </label>
                        </div>
                    </div>

                    <div className="form-row">
                      <div className="form-field">
                        <label className="form-input-label-tag">
                          <textarea className="form-input-field" name="message" id="message" placeholder="&nbsp;" 
                            data-filled={formData.message!=='' ? 'Y' : 'N'} 
                            onChange={(e) => onChangeInput(e)} 
                            value={formData.message}
                            onInvalid={(e) => scrollToInvalid(e)} 
                          /> 
                          <span id="message-desc" className="form-input-desc">{translation.message}</span>
                          <span className="form-input-border"></span>
                        </label>
                      </div>
                    </div>

                    <div className="form-row checklist-header">
                      {translation.checkboxes.header}
                    </div>

                    {translation.checkboxes.labels.map((label, index) => (
                      <div key={'checklist-row-'+index} className="form-row checklist">
                        <div className="cbx">
                          <input 
                            id={'cbx'+index} 
                            type="checkbox" 
                            name="checkboxes[]" 
                            ref={el => checkboxRefs.current[index] = el}
                            value={label}
                            onClick={(e) => handleChangeCheckbox(e)} 
                          />
                          <label htmlFor={'cbx'+index}></label>
                          <svg width="15" height="14" viewBox="0 0 15 14" fill="none">
                            <path d="M2 8.36364L6.23077 12L13 2"></path>
                          </svg>
                        </div>
                        <div>{label}</div>
                      </div>
                    ))}

                    <svg id="goo-svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1">
                      <defs>
                        <filter id="goo">
                          <feGaussianBlur in="SourceGraphic" stdDeviation="4" result="blur"></feGaussianBlur>
                          <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7" result="goo"></feColorMatrix>
                          <feBlend in="SourceGraphic" in2="goo"></feBlend>
                        </filter>
                      </defs>
                    </svg>

      
                    <button ref={submitButtonRef} type="submit" style={{display: 'none'}} />

                    <div id="form-submit-button" onClick={(e)=>submitForm(e)} >
                        <div id="form-submit-button-background" className={submitStatus}>
                          {submitStatus==='submitted' 
                            ? <svg id="submit-spinner" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="0px" height="0px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                <circle cx="40" cy="40" fill="none" stroke="#e15b64" strokeWidth="5" r="10" strokeDasharray="47.12388980384689 17.707963267948966">
                                  <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 40 40;360 40 40" keyTimes="0;1"></animateTransform>
                                </circle>
                              </svg>
                            : <>{translation.submitButton}</>
                          }
                        </div>
                    </div>
          
                    {submitStatus==='error' && 
                      <div id="form-submit-error">
                        <div id="icon-error" />
                        {translation.errorMessage}
                      </div>
                    }
         
                  </form>
                </div>

              </>
          }
        </div>  
      </div>
    </div>
  )
}

export default FormModal;