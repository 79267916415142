import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";  
import { getSafetyCommitteeMeetingsAsync, selectSafetyCommitteeMeetings, selectSafetyCommitteeMeetingsStatus } from '../../features/safetyCommittee/safetyCommitteeSlice';

import ModalPortal from '../ModalPortal/ModalPortal';
import SafetyCommitteeModal from './SafetyCommitteeModal';

// import Error404 from '../Error/Error404';   
// import scrollToTop from '../utilities/scrollToTop';

//const env = dotenv.config().parsed;
// var hostServer = REACT_APP_HOST_SERVER; /* Requires npm install --save-dev dotenv to be installed */
import './SafetyCommittee.scss';
// import esopLogo from '../../images/esop-logo.png';


// import data from '../../translations/es.json';

const enMonths=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const esMonths= ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Set', 'Oct', 'Nov', 'Dic'];


function SafetyCommittee({showPicker, closePicker, general, language}) {

  const [year, setYear] = useState(2022);
  const [months, setMonths] = useState([]);

  useEffect(()=> {
    if (language==='en') setMonths(enMonths);
    if (language==='es') setMonths(esMonths);
  }, [language]);

  
  const dispatch = useDispatch();
  const meetings = useSelector(selectSafetyCommitteeMeetings); // mockSlots
  const meetingsStatus = useSelector(selectSafetyCommitteeMeetingsStatus);

  /*
  const [dispatchNow, setDispatchNow] = useState(false);

  useEffect(() => {
    if (articlesStatus==='idle') setDispatchNow(true);
  }, [articlesStatus]);
*/

  useEffect(() => {
    if (meetingsStatus==='idle') dispatch(getSafetyCommitteeMeetingsAsync());
  }, [meetingsStatus, dispatch]);


  const [showSafetyCommitteeModal, setShowSafetyCommitteeModal] = useState(false);
  const [selectedMeetingId, setSelectedMeetingId] = useState();

  const selectMeeting = (month) => {
    const meetingId = meetings.find(meeting => meeting.meetingYear===year && meeting.meetingMonth===month).meetingId
    setSelectedMeetingId(meetingId);
    setShowSafetyCommitteeModal(true);
  }

  const [meeting, setMeeting] = useState();

  useEffect(()=> {
    setMeeting(meetings.find(meeting => meeting.meetingId === selectedMeetingId));
  }, [meetings, selectedMeetingId]);

  const goToYear = (year) => {
    setYear(year);
  }

  return (
    <>
      <div id="month-picker" className={showPicker ? 'show' : 'hide'}>
        <div>
          <div>
            <svg id="months" viewBox="0 0 100 100">
              <defs>
                <path id="circle"
                  d="
                    M 50, 50
                    m -37, 0
                    a 37,37 0 1,1 74,0
                    a 37,37 0 1,1 -74,0"/>
              </defs>
              <circle id="ring" cx="50" cy="50" r="39"/>
              {months.map((month, index) => (
                <text 
                  key={'month-'+index} 
                  className={
                    meetings && meetings.find(meeting => meeting.meetingYear === year && meeting.meetingMonth===index+1)
                      ? 'active' 
                      : 'disabled'
                  }
                  onClick={() => selectMeeting(index+1)}
                >
                  <textPath xlinkHref="#circle" startOffset={15*index}>
                    {month}
                  </textPath>
                </text>
              ))}
            </svg>
            <svg id="picker-center" viewBox="0 0 100 100">
              <text id="safety-committee" dominantBaseline="middle" textAnchor="middle" x="50%" y="35%">{general && general[language]?.footer?.safetyCommittee && general[language]?.footer?.safetyCommittee.toLowerCase()}</text>
              <text id="year" dominantBaseline="middle" textAnchor="middle" x="50%" y="50%">{year}</text>
              <text id="meeting-minutes" dominantBaseline="middle" textAnchor="middle" x="50%" y="62%">{general && general[language]?.footer?.meetingMinutes && general[language]?.footer?.meetingMinutes.toLowerCase()}</text>
            </svg>
            {meetings.find(meeting => meeting.meetingYear < year) 
              && <div className="picker-arrow-left" onClick={() => goToYear(year-1)}></div>
            }
            {meetings.find(meeting => meeting.meetingYear > year) 
              && <div className="picker-arrow-right" onClick={() => goToYear(year+1)}></div>
            }
            <div className="icon-close" onClick={closePicker}></div>
          </div>
        </div>
      </div>
      <ModalPortal showModal={showSafetyCommitteeModal}>
        <SafetyCommitteeModal 
          setShowSafetyCommitteeModal={setShowSafetyCommitteeModal} 
          meeting={meeting}
          general={general}
          language={language}
        />
      </ModalPortal>
    </>
  );
}

export default SafetyCommittee;
/*
   <div className="month-dial">
      <div className="ring-back"></div>
      <div className="ring" id="r2">
        <h2 className="month-text">JAN FEB MAR APR MAY JUN JUL AUG SEP OCT NOV DEC</h2>
      </div>
    </div>    
*/
