import Geocode from "react-geocode";

async function getMapCoordinates (mapAddress) {

  return new Promise(resolve => {

    if (!mapAddress) return;

    // Set Google Maps Geocoding API for purposes of quota management. It's optional but recommended.
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
    
    // Set response language. Defaults to english.
    Geocode.setLanguage("en");
    
    // Set response region. It's optional.
    // A Geocoding request with region=es (Spain) will return the Spanish city.
    Geocode.setRegion("us");
    
    // Enable or disable logs. It's optional.
    Geocode.enableDebug(true);
    
    /*
    // Get address from latitude & longitude.
    Geocode.fromLatLng("48.8583701", "2.2922926").then(
      response => {
        const address = response.results[0].formatted_address;
        console.log(address);
      },
      error => {
        console.error(error);
      }
    );
    */

    // Get latitude & longitude from address.
    Geocode.fromAddress(mapAddress).then(
      response => {
        const { lat, lng } = response.results[0].geometry.location;
        resolve({lat, lng});
      },
      error => {
        console.error(error);
      }
    );
  });
}

export default getMapCoordinates;