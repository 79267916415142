import { useState, useEffect, useRef, useCallback } from 'react';

function useResizeObserver (elRefs) {

  const [bounds, setBounds] = useState();
  
  const observer = useRef(null);

  const handleResize = useCallback((entries) => {
    let newBounds = {...bounds}; // Sometimes not reporting all entries, so selectively update
    // console.log('handleResize entries', entries);
    entries.forEach((entry)=> {
      // entry.contentRect
      newBounds[entry.target.id]=entry.target.getBoundingClientRect();
    })
    setBounds(newBounds);
  }, [bounds]);

  useEffect(() => {
    const initiate = () => {
      if (!elRefs.current || elRefs.current.length===0) {
        setTimeout(() => {
          initiate(); 
        }, 100);
      } else {
        observer.current = new ResizeObserver((entries) => {
          handleResize(entries);
        });
        elRefs.current.forEach(el => {
          observer.current.observe(el);
        });
      };
    }
 
    initiate();

    return () => {
      if (observer.current) {
        observer.current.disconnect();
        observer.current = null;
      }
    };
  }, [elRefs, handleResize]);


  return bounds;
}

export default useResizeObserver;
