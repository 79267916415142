import React, { useState, useEffect, useCallback, useRef } from 'react';
// import { Link } from 'react-router-dom'; 
// import easyScroll from 'easy-scroll'; // https://www.npmjs.com/package/easy-scroll // https://github.com/tarun-dugar/easy-scroll#readme
import './iphone.scss';
import './QualifyingForCare.scss';
import '../FormModal/FormModal.scss'; // For open form modal button
import { Scrollbars } from 'react-custom-scrollbars';
import Bowser from 'bowser'; 
import ModalPortal from '../ModalPortal/ModalPortal';
import FormModal from '../FormModal/FormModal';

//import anime from 'animejs/lib/anime.es.js';

/*
function sendSMS()
{
	var text = $('#imessage').val();
	if(text != '')
	{
		sendText(text);
		receiveText(text);
	}
}

function scrollDown(div)
{
	  div.animate({ scrollTop: 10000 }, "slow");
}

function sendText(text)
{
	var conversation = $('#conversation');
	var button = $('#send_btn');

	var date = new Date();
	var newTime = $('<div class="time"><p>'+ getDate() + '</p></div>');
	newTime.hide();	
	conversation.append(newTime);

	var newText = $('<div class="text sent"><div class="reflect"></div><p>' + text + '</p></div>');
	newText.hide();
	conversation.append(newText);

	newText.show('normal');
	newTime.show('fast');
	scrollDown(conversation);
	disable(button);
	$('#imessage').val('');
}

function receiveText(smsText)
{
	var button = $('#send_btn');
	var newText = $('<div class="text receive"><div class="reflect"></div><p></p></div>');
	var newImg = $('<div class="text receive"><div class="reflect"></div><p></p></div>');
	newImg.hide();
	newText.hide();
	var conversation = $('#conversation');
	var sender = $('#phone').val();
}

function getDate()
{
	var a_p = "";
	var d = new Date();
	var curr_hour = d.getHours();
	if (curr_hour < 12)
	   {
	   a_p = "AM";
	   }
	else
	   {
	   a_p = "PM";
	   }
	if (curr_hour === 0)
	   {
	   curr_hour = 12;
	   }
	if (curr_hour > 12)
	   {
	   curr_hour = curr_hour - 12;
	   }

	var curr_min = d.getMinutes();

	curr_min = curr_min + "";

	if (curr_min.length === 1)
	   {
	   curr_min = "0" + curr_min;
	   }

   var m_names = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

   var curr_date = d.getDate();
   var curr_month = d.getMonth();
   var curr_year = d.getFullYear();

	return m_names[curr_month] + " " + curr_date + ", " + curr_year + ' '+ curr_hour + ":" + curr_min + " " + a_p;
}
*/


const charsets = [
  [
    'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p',
    'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', '',
    '', 'z', 'x', 'c', 'v', 'b', 'n', 'm',
    '123', ' '
  ],
  [
    'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P',
    'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', '',
    '', 'Z', 'X', 'C', 'V', 'B', 'N', 'M',
    '123', ' '
  ],
  [ 
    '1', '2', '3', '4', '5', '6', '7', '8', '9', '0',
    '-', '/', ':', ';', '(', ')', '$', '&', '@', '"',
    '#+=', '.', ',', '?', '!', "'", '', '',
    'ABC', ' '
  ],
  [ 
    '[', ']', '{', '}', '#', '%', '^', '*', '+', '=',
    '_', '\\', '|', '~', '<', '>', '€', '£', '¥', '•',
    '123', '.', ',', '?', '!', "'", '', '',
    'ABC', ' '
  ]
];
/*
const messages = [
  {
    type: "receive",
    text: "Diego here from Red Lion Home Care! Are you a resident of Pennsylvania?"
  },
  {
    type: "send",
    text: "Yes, I am! I live right outside Philly."
  },
  {
    type: "receive",
    text: "Did you know you may be one of 500,000 people who qualify for Community Health Choices?"
  },
  {
    type: "send",
    text: "I didn't. May I please get some more info?"
  },
  {
    type: "receive",
    text: "Definitely! Let's find out if you qualify."
  },
  {
    type: "receive",
    text: "Are you over 21 years old or in a nursing home?"
  },
  {
    type: "send",
    text: "yes, I'm 79"
  },
  {
    type: "receive",
    text: "Do you receive Medicare and/or Medicaid? (dual eligible)"
  },
  {
    type: "send",
    text: "yes, I'm on Medicare"
  },
  {
    type: "receive",
    text: "Do you have a physical disability or a traumatic brain injury?"
  },
  {
    type: "send",
    text: "no"
  },
  {
    type: "receive",
    text: "Great! Answering yes to any one of those questions means that you qualify."
  },
  {
    type: "send",
    text: "Perfect. How do I get started?"
  },
  {
    type: "receive",
    text: "Please click the button below to fill out a form."
  },
  {
    type: "receive",
    text: "Once we receive it, a case manager will set up an appointment within 24 hours to answer any questions you may have.",
  },
  {
    type: "receive",
    text: "Or you may call us at (267) 571-5570."
  },
  {}
];
*/

function QualifyingForCare({data, englishFormData}) {

  const { title, messages, form } = data; // Need to append an empty last object to render last message... done in pagesSlice

  const { openButton } = form;

  const [msgs, setMsgs] = useState(messages); 

  useEffect(()=> {
    setMsgs([...messages, {type:'receive', text: ''}]); // Need the empty last object to render last message
  }, [messages]);

  useEffect(()=> {
    console.log('msgs', msgs);
  }, [msgs]);

  /*
  useEffect(()=> {
    var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'mac';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'ios';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'windows';
    } else if (/Android/.test(userAgent)) {
      os = 'android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'linux';
    }

    setOS(os);
  }, []);
  */

  const [charsetsIndex, setCharsetsIndex] = useState(0);
  const [charIndex, setCharIndex] = useState();
  const [messagesIndex, setMessagesIndex] = useState(0);
  const [messageIndex, setMessageIndex] = useState(-1);
  const [isTyping, setIsTyping] = useState(false);
  const [isReceiving, setIsReceiving] = useState(false);
  const [isSending, setIsSending] = useState(false); /* Required for proper execution of sending animation */
  const [texter, setTexter] = useState('');
  const [keyboardClass, setKeyboardClass] = useState('init');
  const [lionEntered, setLionEntered] = useState(false);
  const [manEntered, setManEntered] = useState(false);
  
  const messagesRef = useRef();

  const [scrollbarBackgroundColor, setScrollbarBackgroundColor] = useState('transparent');
  
  const setNextMessage = useCallback((nextMessagesIndex) => {
    setIsTyping(false);
    console.log('nextMessagesIndex', nextMessagesIndex, 'msgs', msgs);

    if (!msgs[nextMessagesIndex] || msgs[nextMessagesIndex].type==='receive' || msgs[nextMessagesIndex].type==='') setIsReceiving(true);

    setTimeout(()=> {
      setMessageIndex(-1);
      let scrollCount = 0;

      const scrollInterval = setInterval(() => {
        scrollMessages(true);
        scrollCount+=1;
        if (scrollCount>=(400/50)) clearInterval(scrollInterval);
      }, 50);

      if (msgs[nextMessagesIndex-1].type==='send') {
        setIsSending(true);
      } 

      if (msgs[nextMessagesIndex].type==='send') {
        setTexter('man');
      } else {
        setTexter('lion');
      }
      
      setMessagesIndex(nextMessagesIndex);
      
    }, 1000);

  }, [msgs]);

  const type = useCallback((messagesIndex, messageIndex) => {

    if (msgs[messagesIndex].type==='send' && 
      messageIndex < msgs[messagesIndex].text.length) {

      const typeChar = msgs[messagesIndex].text.charAt(messageIndex);

      // Find the corresponding charsets and char indices
      charsets.find((charset, charsetIndex) => {
        let charIndex = charset.findIndex(char => typeChar===char);
        if (charIndex>-1) {
          setCharsetsIndex(charsetIndex);
          setCharIndex(charIndex);
          return true;
        }
        return false;
      });

      const nextMessageIndex = messageIndex+1;

      setTimeout(() => {
        setMessageIndex(nextMessageIndex);
        scrollMessages(false); 
        
        setTimeout(() => type(messagesIndex, nextMessageIndex), 50);
      }, 50);

      return;
    } 
    
    setCharIndex(-1); /* Clear key press */
    
    scrollMessages(false);

    if (messagesIndex < msgs.length-1) {
      setNextMessage(messagesIndex+1);
    }

  }, [msgs, setNextMessage]);


  const browserParser = Bowser.getParser(window.navigator.userAgent);
  const [browser, setBrowser] = useState();
  const [OS, setOS] = useState();

  useEffect(()=> {
    if (!browser && !OS) { /* Only run once... (type function changes) */
      const newBrowser = browserParser.getBrowserName(true).replace(' ','');
      const newOS = browserParser.getOSName(true).replace(' ',''); // macos, windows, linux
      setBrowser(newBrowser);
      setOS(newOS); 
      if (newOS==='macos' && (newBrowser==='chrome' || newBrowser==='microsoftedge')) {
        type(0, 0);
      }
    }
  }, [browser, OS, browserParser, type]);


  const handleOnAnimationEndKeyboardSlider = () => {
    if (keyboardClass==='show' || keyboardClass==='hide') {
      setTimeout(()=> {type(messagesIndex, 0);}, 300);
    }
  }

  const scrollMessages = () => {
    setTimeout(() => { // Necessary to allow full render before measuring
      if (messagesRef.current) messagesRef.current.scrollToBottom();

      /*
      if (messagesRef.current) {
        if (!isSmooth) messagesRef.current.scrollTop = messagesRef.current.scrollHeight - messagesRef.current.clientHeight;
        if (isSmooth) easyScroll({
          'scrollableDomEle': messagesRef.current,
          'direction': 'bottom',
          'duration': 200,
          'easingPreset': 'linear',
        // onAnimationCompleteCallback: scrollComplete
        // 'scrollAmount': 1000
        });
      }
    */
    }, 0); 
  }

  const typeNextMessage = () => {

    setIsSending(false);
    setIsReceiving(false);

    // If there are no more messages to send, hide the keyboard
    if(keyboardClass!=='hide' && !msgs.find((message, index) => message.type==='send' && index >= messagesIndex)) {
      setTimeout(()=>{setKeyboardClass('hide')}, 700);
      return;
    };

    if (keyboardClass!=='show' && msgs[messagesIndex].type==='send') {
      setTimeout(()=>{setKeyboardClass('show')}, 1000);
      return;
    }

    type(messagesIndex, 0);
  };

  const receiveNextMessage = () => {

    setIsSending(false);
    setIsReceiving(false);

    // If there are no more messages to send, hide the keyboard
    if(keyboardClass!=='hide' && !msgs.find((message, index) => message.type==='send' && index >= messagesIndex)) {
      setTimeout(()=>{setKeyboardClass('hide')}, 700);
      return;
    };

    scrollMessages(false);

    setTimeout(() => {
      setIsTyping(true);
      scrollMessages(false);

      setTimeout(() => {
        scrollMessages(false);
        type(messagesIndex, 0);
      }, 3000); 

    }, 2000); 
  };

  /*
    useEffect(()=> {
      type(0, 0);
    }, [type]);
  */
 
  const startSendingMessages = (e) => {
    if (e.animationName==='enter-phone') type(0, 0);
  };

  const handleOnAnimationEndLion = (e) => {
    if (e.animationName==='enter-lion') setLionEntered(true); 
  } 

  const handleOnAnimationEndMan = (e) => {
    if (e.animationName==='enter-man') setManEntered(true); 
  } 

  const [showFormModal, setShowFormModal] = useState(false);

  const openFormModal = () => {
    setShowFormModal(true);
  }

  return (
    <div id="qualifying-for-care-container" className={`${OS} ${browser}`}>
      <div className="background">
        <div className="left"></div>
        <div className="right"></div>  
      </div>
      <div className="pink-background"></div>
      
      <div className={`phone-container enter`}>
        <div className={`phone${texter==='man' ? ' tilt-right' : ''}${texter==='lion' && messagesIndex > 1 ? ' tilt-left' : ''}`} onAnimationEnd={(e) => startSendingMessages(e)}>
          <div className="front"></div>
          <div 
            className="screen"
            onMouseOver={()=>setScrollbarBackgroundColor('#777')} /* Otherwise shows when programmatically scrolling */
            onMouseOut={()=>setScrollbarBackgroundColor('transparent')}
          >
            <div className="header">
              <div className="contact-picture"><div></div></div>
              <div className="contact-name">Diego &gt;</div>
            </div>
            <Scrollbars 
              ref={messagesRef}
              style={{ width: '100%', height: keyboardClass === 'show' ? 294 : 455 }} /* Height must match .messages in scss (note that there is 50px padding at bottom of .screen)  */
              renderThumbVertical={({ style, ...props }) => 
                <div 
                  {...props} 
                  style={{...style, 
                    pointerEvents: 'auto', 
                    cursor: 'pointer', 
                    width: 6, 
                    zIndex: 99, 
                    backgroundColor: scrollbarBackgroundColor, 
                    borderRadius: 3,
                    right: 11
                  }} 
                  className="thumb-vertical"
                />
              }
              
              autoHide={false}
            >
            <div id="messages" className={`messages ${keyboardClass === 'show' ? 'short' : 'tall'}`}>
              { messagesIndex > 0 && 
                msgs && 
                msgs.length > 0 &&
                msgs.slice(0, messagesIndex).concat([{type: 'pre-send', text: ''}]).map((message, index) => {
                  
                  if (message.type==='pre-send' && ((messageIndex < 0 && isSending) || keyboardClass!=='show')) return <React.Fragment key={'message-'+index}></React.Fragment>
               
                  return  <React.Fragment key={'message-'+index}>
                            <div id={'message-'+index}  
                              className={message.type} 
                              onAnimationEnd={message.type==='receive' ? typeNextMessage : receiveNextMessage}
                              style={{flex: index===messagesIndex-1 && isTyping===false ? '1 0 20px': '0 0 20px', paddingBottom: index===messagesIndex-1 && isTyping===false ? 4 : 0}} 
                              // style={{marginTop: 0}} // If pre-send, must set dynamically to overlay icons-input-field duplicate text
                            >
                              <div>
                                <div>
                                  {message.type!=='pre-send'
                                    ? message.text 
                                    : (
                                        messageIndex > -1 && messagesIndex > -1 && msgs[messagesIndex].type==='send'
                                          ? msgs[messagesIndex].text.slice(0, messageIndex)
                                          : <span style={{opacity: .5}}>Text Message</span>
                                      )
                                  }
                                </div>
                              </div>
                            </div> 
                            {index===messagesIndex-1 && !isSending && 
                              <div className={isTyping ? 'typing' : 'not-typing'} style={{flex: '1 0 20px'}}>
                                <div>
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </div>
                              </div>
                            }
                          </React.Fragment>            
                  }
              )} 
            </div>
            </Scrollbars>
            <div className={`keyboard-slider ${keyboardClass}`} onAnimationEnd={handleOnAnimationEndKeyboardSlider}>
              <div className={`icons-input-field${isSending ? ' sending' : ''}${keyboardClass!=='show' ? ' keyboard-hidden' : ''}`}>
                <div className="icons"></div>
                <div className="input-field">
                  <div className="input">
                    {messagesIndex > -1 && msgs[messagesIndex].type==='send' && messageIndex > -1 
                      ? msgs[messagesIndex].text.slice(0, messageIndex)
                      : <span style={{opacity: .5}}>Text Message</span>
                    }
                  </div>
                  <div className={`send-button ${messagesIndex > -1 && msgs[messagesIndex].type==='send' && messageIndex > -1 ? 'active' : 'inactive'}`}></div>
                </div>
              </div>
              <div className="keyboard">
                <div>
                  <div className="row">
                    <div><div><div className={charIndex===0 ? 'pressed' : ''}>{charsets[charsetsIndex][0]}</div></div></div>
                    <div><div><div className={charIndex===1 ? 'pressed' : ''}>{charsets[charsetsIndex][1]}</div></div></div>
                    <div><div><div className={charIndex===2 ? 'pressed' : ''}>{charsets[charsetsIndex][2]}</div></div></div>
                    <div><div><div className={charIndex===3 ? 'pressed' : ''}>{charsets[charsetsIndex][3]}</div></div></div>
                    <div><div><div className={charIndex===4 ? 'pressed' : ''}>{charsets[charsetsIndex][4]}</div></div></div>
                    <div><div><div className={charIndex===5 ? 'pressed' : ''}>{charsets[charsetsIndex][5]}</div></div></div>
                    <div><div><div className={charIndex===6 ? 'pressed' : ''}>{charsets[charsetsIndex][6]}</div></div></div>
                    <div><div><div className={charIndex===7 ? 'pressed' : ''}>{charsets[charsetsIndex][7]}</div></div></div>
                    <div><div><div className={charIndex===8 ? 'pressed' : ''}>{charsets[charsetsIndex][8]}</div></div></div>
                    <div><div><div className={charIndex===9 ? 'pressed' : ''}>{charsets[charsetsIndex][9]}</div></div></div>
                  </div>
                  <div className={`row${charsetsIndex<2 ? ' row-2 shorten' : ''}`}>
                    <div><div><div className={charIndex===10 ? 'pressed' : ''}>{charsets[charsetsIndex][10]}</div></div></div>
                    <div><div><div className={charIndex===11 ? 'pressed' : ''}>{charsets[charsetsIndex][11]}</div></div></div>
                    <div><div><div className={charIndex===12 ? 'pressed' : ''}>{charsets[charsetsIndex][12]}</div></div></div>
                    <div><div><div className={charIndex===13 ? 'pressed' : ''}>{charsets[charsetsIndex][13]}</div></div></div>
                    <div><div><div className={charIndex===14 ? 'pressed' : ''}>{charsets[charsetsIndex][14]}</div></div></div>
                    <div><div><div className={charIndex===15 ? 'pressed' : ''}>{charsets[charsetsIndex][15]}</div></div></div>
                    <div><div><div className={charIndex===16 ? 'pressed' : ''}>{charsets[charsetsIndex][16]}</div></div></div>
                    <div><div><div className={charIndex===17 ? 'pressed' : ''}>{charsets[charsetsIndex][17]}</div></div></div>
                    <div><div><div className={charIndex===18 ? 'pressed' : ''}>{charsets[charsetsIndex][18]}</div></div></div>
                    <div><div><div className={charIndex===19 ? 'pressed' : ''}>{charsets[charsetsIndex][19]}</div></div></div>
                  </div>
                  <div className={`row${charsetsIndex>1 ? ' row-3 shorten' : ''}`}>
                    <div className={charsetsIndex>1 ? 'special dark' : (charsetsIndex===0 ? 'shift dark' : 'shift')}><div>{charsets[charsetsIndex][20]}</div></div>
                    <div><div><div className={`${charIndex===21 ? 'pressed' : ''}${charsetsIndex>1 ? ' wide' : ''}`}>{charsets[charsetsIndex][21]}</div></div></div>
                    <div><div><div className={`${charIndex===22 ? 'pressed' : ''}${charsetsIndex>1 ? ' wide' : ''}`}>{charsets[charsetsIndex][22]}</div></div></div>
                    <div><div><div className={`${charIndex===23 ? 'pressed' : ''}${charsetsIndex>1 ? ' wide' : ''}`}>{charsets[charsetsIndex][23]}</div></div></div>
                    <div><div><div className={`${charIndex===24 ? 'pressed' : ''}${charsetsIndex>1 ? ' wide' : ''}`}>{charsets[charsetsIndex][24]}</div></div></div>
                    <div><div><div className={`${charIndex===25 ? 'pressed' : ''}${charsetsIndex>1 ? ' wide' : ''}`}>{charsets[charsetsIndex][25]}</div></div></div>
                    <div><div><div className={charIndex===26 ? 'pressed' : ''}>{charsets[charsetsIndex][26]}</div></div></div>
                    <div><div><div className={charIndex===27 ? 'pressed' : ''}>{charsets[charsetsIndex][27]}</div></div></div>
                    <div className="delete dark"><div></div></div>
                  </div>
                  <div className="row">
                    <div className="switch dark"><div>{charsets[charsetsIndex][28]}</div></div>
                    <div className={charIndex===29 ? 'space dark' : 'space'}><div>space</div></div>
                    <div className="return dark"><div>return</div></div>
                  </div>
                  <div className="bottom"></div>
                </div>
              </div>
            </div>
            
            <div className="home-bar"></div>
            {/* <p class="receive">Do you receive Medicare and/or Medcaid (dual eligible)?</p> */}
          </div>
          <div className="sides">
            <div></div><div></div><div></div><div></div><div></div>
            <div></div><div></div><div></div><div></div><div></div>
            <div></div><div></div><div></div><div></div><div></div>
            <div></div><div></div><div></div><div></div><div></div>
            <div></div><div></div><div></div><div></div><div></div>
            <div></div><div></div><div></div><div></div><div></div>
            <div></div><div></div><div></div><div></div><div></div>
            <div></div><div></div><div></div><div></div><div></div>
            <div></div><div></div><div></div><div></div>
            <div></div><div></div><div></div><div></div>
          </div>
          <div className="back"></div>
          <div className="left"></div>
          <div className="left-top"></div>
          <div className="left-bottom"></div>
          <div className="right"></div>
          <div className="right-top"></div>
          <div className="right-bottom"></div>
          <div className="shadow"></div>
        </div>
      </div>


      <div className="avatar-lion-container" onAnimationEnd={handleOnAnimationEndLion}>
        <div className={lionEntered===true ? 'bounce' : ''}>
          <div className={`avatar-lion-background${texter==='lion' && lionEntered===true ? ' tilt' : ''}${texter==='man' && lionEntered===true ? ' untilt' : ''}`}>
            <div className="avatar-lion"></div>
            <div className="shadow"></div>
            <div className="sides">
              <div></div><div></div><div></div><div></div><div></div>
              <div></div><div></div><div></div><div></div><div></div>
              <div></div><div></div><div></div><div></div><div></div>
              <div></div><div></div><div></div><div></div><div></div>
              <div></div><div></div><div></div><div></div><div></div>
              <div></div><div></div><div></div><div></div><div></div>
              <div></div><div></div><div></div><div></div><div></div>
              <div></div><div></div><div></div><div></div><div></div>
              <div></div><div></div><div></div><div></div>
              <div></div><div></div><div></div><div></div>
            </div>
          </div>
        </div>
      </div>

      <div className="avatar-man-container" onAnimationEnd={handleOnAnimationEndMan}>
        <div className={manEntered===true ? 'bounce' : ''}>
          <div className={`avatar-man-background${texter==='man' && manEntered===true ? ' tilt' : ''}${texter==='lion' && manEntered===true ? ' untilt' : ''}`}>
          <div className="avatar-man"></div>
          <div className="shadow"></div>
          <div className="sides">
            <div></div><div></div><div></div><div></div><div></div>
            <div></div><div></div><div></div><div></div><div></div>
            <div></div><div></div><div></div><div></div><div></div>
            <div></div><div></div><div></div><div></div><div></div>
            <div></div><div></div><div></div><div></div><div></div>
            <div></div><div></div><div></div><div></div><div></div>
            <div></div><div></div><div></div><div></div><div></div>
            <div></div><div></div><div></div><div></div><div></div>
            <div></div><div></div><div></div><div></div>
            <div></div><div></div><div></div><div></div>
          </div>
        </div>
        </div>
      </div>

      <div className="page-header">
        {title}
      </div>

      <div className="open-form-modal-button-container">
        <div className="open-form-modal-button" onClick={openFormModal}>
        <div className="button-copy">{openButton}</div>
          <div className="sides">
            <div></div><div></div><div></div><div></div><div></div>
            <div></div><div></div><div></div><div></div><div></div>
            <div></div><div></div><div></div><div></div><div></div>
            <div></div><div></div><div></div><div></div><div></div>
            <div></div><div></div><div></div><div></div>
          </div>
        </div>
        <div className="shadow"></div>    
      </div>

{/* 
      <div id="iPhoneBro" className="iPhone gen">
        <div className="title">
          <input type="submit" className="back button" value="Messages" />
          <span className="contact_name button">(614) MEME-BRO</span>
          <input type="submit" className="edit button" value="Edit" />
        </div>
        <div id="conversation" className="conversation">
          <div className="time">
            <div className="time">
              <p>Aug 9, 2012 3:43 AM</p>
            </div>
          </div>
          <div className="text sent" id="query">
            <div className="reflect"></div>
            <p>Check Out <a href="http://www.memebro.com/?r=codepen">memebro.com</a></p>
          </div>
          <div className="text receive">
            <div className="reflect"></div>
            <p>Try "+popular", "+trending", or "+new" for meme lists</p>
          </div>
          <div className="text sent" id="query">
            <div className="reflect"></div>
            <p>+popular</p>
          </div>
          <div className="text receive" id="popular">
            <div className="reflect"></div>
            <p>#Bad-Luck-Brian-Meme, #One-Does-Not-Simply-A, #First-World-Problems-Ii, #Stoner-Stanley, #Scumbag-Steve, #Conspiracy-Keanu, #I-Dont-Always, #All-The-Things, ...</p>
          </div>
          <div className="text sent">
            <div className="reflect"></div>
            <p>Invited to play a game, SAW 7 #badluckbrian</p>
          </div>
          <div className="text receive">
            <div className="reflect"></div>
              <p>Processing MMS... <a href="http://memebro.com/pf6t0">http://memebro.com/pf6t0</a></p>
            </div>
          <div className="text receive">
            <div className="reflect"></div>
            <p>
              <a href="http://memebro.com/pf6t0">
                <img className="addthis_shareable" alt="" src="http://cdn.memegenerator.net/instances/400x/24506893.jpg" />
              </a>
            </p>
          </div>
          <div className="clear"></div>
        <div className="time">
          <p>Aug 17, 2012 11:59 AM</p>
        </div>
        <div className="text sent">
          <div className="reflect"></div>
          <p>Not sure if trolling, or just stupid #fry</p>
        </div>
        <div className="text receive">
          <div className="reflect"></div>
          <p>Processing MMS... <a href="http://memebro.com/zmxo0">http://memebro.com/zmxo0</a></p>
        </div>
        <div className="text receive">
          <div className="reflect"></div>
            <p>
              <a className="addthis_button_compact" href="http://memebro.com/zmxo0">
                <img className="addthis_shareable" alt="" src="http://cdn.memegenerator.net/instances/400x/25061324.jpg" />
              </a>
            </p>
          </div>
        </div>
        <div className="message">
          <input id="imessage" type="text" name="imessage" value="" placeholder="Text Message" />
          <input id="send_btn" type="submit" value="Send" onclick="sendSMS();" className="disabled" />
          <input id="phone" type="hidden" name="number" value="" />
        </div>
      </div>
*/}

      <ModalPortal showModal={showFormModal}>
        <FormModal 
          formName="Get More Info" 
          setShowFormModal={setShowFormModal} 
          translation={form} 
          englishFormData={englishFormData}  
        />
      </ModalPortal>

    </div>
  );

}

export default QualifyingForCare;
