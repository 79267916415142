import React, { useState, useEffect, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import './Caregivers.scss';
import useIsIntersecting from '../../hooks/useIsIntersecting2';
// import { forEach } from 'lodash';

const Caregivers = ({ data, language }) => {

  const { title, caringForPatients, caringForCaregivers, reviews } = data;
  
  const animateRefs = useRef([]);
  const isIntersecting = useIsIntersecting(animateRefs, '0px 0px 0px 0px', .3);
  const [animationClassNames, setAnimationClassNames] = useState([]);

  useEffect(() => {
    if (!isIntersecting || !animateRefs || animateRefs.current.length===0) return;
    animateRefs.current.forEach((ref, index) => {
      if (isIntersecting[index]===true && !animationClassNames[index]) {
        let classes = [...animationClassNames];
        classes[index]='enter';
        setAnimationClassNames(classes);
      }
    });
  }, [isIntersecting, animationClassNames]);

  /*
    useEffect(() => {
      console.log('isIntersecting', isIntersecting);
    }, [isIntersecting]);
  */

  // const reviewsSectionRef=useRef();

  const [reviewerEntering, setReviewerEntering] = useState(-1);
  const [reviewerToBeEntering, setReviewerToBeEntering] = useState(-1);
  const [reviewerExiting, setReviewerExiting] = useState(-1);
  const [isSwitching, setIsSwitching] = useState(false);

  const selectReviewer = (value) => {
    if (speakButtonClass==='enter') setSpeakButtonClass('exit');
    if (isSwitching===true) return;
    setIsSwitching(true);
    setReviewerExiting(reviewerEntering);
    if (reviewerEntering===-1) {
      setReviewerEntering(value);
    } else if (value!==reviewerEntering) {
      setReviewerToBeEntering(value);
    }
  }

  const incrementReviewer = (increment) => {
    let next = reviewerEntering + increment;
    if (next<1) next=8;
    if (next>8) next=1;
    selectReviewer(next);
  }

  const handleAnimationEnd = (e) => {
    if (e.animationName.indexOf('speak')>-1 && e.animationName.indexOf('sidestep')===-1) setIsSwitching(false);
    if (e.animationName.indexOf('exit')>-1 && e.animationName.indexOf('sidestep')===-1) {
      setReviewerExiting(-1);
      if (reviewerToBeEntering>-1) {
        setReviewerEntering(reviewerToBeEntering);
        setReviewerToBeEntering(-1);
      } else {
        setReviewerEntering(-1);
        setIsSwitching(false);
      }
    }
  }
  
  const [speakButtonClass, setSpeakButtonClass] = useState('');

  useEffect(()=> {
    if (animationClassNames[4]==='enter' && speakButtonClass==='') setSpeakButtonClass('enter');
  }, [animationClassNames, speakButtonClass]);

  /*
  // Used to bind animations to scrolling
  // Each animation takes place over duration of scroll within start and end offsets
  const setReviewSectionScrolls = () => {
    
    if (!reviewsSectionRef.current) return;

    const scrollOffsets = [
      { 
        start: 0,
        end: 1000
      },
      { 
        start: 1000,
        end: 1500
      },
      // Reviewer 1, Caregiver 1
      { 
        start: 1500,
        end: 3500
      },
      // Reviewer 8, Patient 4
      { 
        start: 3000,
        end: 5000
      },
       // Reviewer 2, Patient 1
      { 
        start: 4500,
        end: 6500
      },
       // Reviewer 7, Caregiver 4
      { 
        start: 6000,
        end: 10000
      },
      // Reviewer 3, Caregiver 2
      { 
        start: 9500,
        end: 13500
      },
      // Reviewer 6, Patient 3
      { 
        start: 13000,
        end: 17000
      },
      // Reviewer 4, Patient 2
      { 
        start: 16500,
        end: 20500
      },
      // Reviewer 5, Caregiver 3
      { 
        start: 20000,
        end: 24000
      },
      // Lion
      { 
        start: 23500,
        end: 25500
      },
      { 
        start: 23600,
        end: 25600
      },
      { 
        start: 25600,
        end: 30100
      }
    ];
    
    scrollOffsets.forEach((offset, index) => {
      const a = window.pageYOffset - reviewsSectionRef.current.offsetTop - offset.start;
      let b =  offset.end - offset.start;

      let ratio = (a<0) ? 0 : a/b;
      if (ratio>1) {ratio = 1;};
      if (index===0) {
        // console.log('aaaaaaaaaaaa', a, 'bbbbbbbbbbbbbb', b);
        // console.log('22222222222222222 scrollOffsets '+index, ratio);
      }
      document.documentElement.style.setProperty('--scroll-'+(index+1), ratio);
    });
  }
*/

/*
  useEffect(()=> {
    setReviewSectionScrolls();
    window.addEventListener('scroll', setReviewSectionScrolls, false);

    return () => {
      window.removeEventListener('scroll', setReviewSectionScrolls, false);
    }
  }, []);
*/

  return (
    <div className="caregivers-container">

      <div className="background">
        <div className="left"></div>
        <div className="right"></div>  
      </div>
      <div className="pink-background"></div>

      <div className="page-heading">
        {title}
      </div>

      <div className="caregivers-content">

 {/* 
        <div className="caregivers-copy">
          {
          //ReactHtmlParser(copy)
        }

       
          <div className="caregiver-triangle">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 807.32 539.21">
              <polygon points="1.58 269.6 806.83 0.69 806.82 538.51 1.58 269.6"/>
            </svg>
          </div>
  
        </div>
*/}

        <div 
          ref={el => animateRefs.current[0] = el} 
          className={`section patients-copy ${animationClassNames[0] || ''}`}
        >
          <div>
            <div className="section-heading">
              {ReactHtmlParser(caringForPatients.heading)}
            </div>
            <div className="section-copy">
              {ReactHtmlParser(caringForPatients.copy)}<br /><br />
            </div>
            <div className="bullet-points">
              <div>{ReactHtmlParser(caringForPatients.bulletPoints.heading)}</div>
              <ul>
                {caringForPatients.bulletPoints.items.map((item, index) => {
                  return (
                    <li key={'patients-'+index}>
                      <span>{ReactHtmlParser(item)}</span>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>

        <div 
          ref={el => animateRefs.current[1] = el} 
          className={`patients-svg-container ${animationClassNames[1] || ''}`}
        >
          <div>
            <div className="floor-container">
              <svg 
                version="1.1" 
                xmlns="http://www.w3.org/2000/svg" 
                xmlnsXlink="http://www.w3.org/1999/xlink" 
                x="0px" y="0px"
                viewBox="0 0 770 250" 
              >
                <path className="st15" d="M23.2,25.5c95.6-11.1,677.5-75.7,738,81.2c9.3,24.2,8.4,59-24.7,110c-388.2,59.4-677.9,30.9-725.6-83.3
                  C1,110.1-1.6,75.9,23.2,25.5z"/>
              </svg>
            </div>
            <div className="background-container">
              <svg 
                version="1.1" 
                xmlns="http://www.w3.org/2000/svg" 
                xmlnsXlink="http://www.w3.org/1999/xlink" 
                x="0px" y="0px"
                viewBox="0 0 600 670" 
              >
                <path className="background st0" d="M60.7,125.6c0,0,155.5-127.8,241.9-120.6c131.7,11,138.7,96.9,246.9,200.3
                  c89.6,85.6,34.1,243.9-71.3,312.8c-71.7,46.8-326.2,46.5-384.1-30.2S-53.4,244.8,60.7,125.6z"/>
                <g id="background-clip-group">
                  <defs>
                    <path id="background-clip-def" d="M60.7,125.6c0,0,155.5-127.8,241.9-120.6
                      c131.7,11,138.7,96.9,246.9,200.3c89.6,85.6,34.1,243.9-71.3,312.8c-71.7,46.8-326.2,46.5-384.1-30.2S-53.4,244.8,60.7,125.6z"/>
                  </defs>
                  <clipPath id="background-clip-path">
                    <use xlinkHref="#background-clip-def" style={{overflow: 'visible'}}/>
                  </clipPath>
                  <g className="st1">
                    <g id="mirror">
                      <ellipse className="st2" cx="258.3" cy="189.5" rx="106.2" ry="152.4"/>
                      <ellipse className="st3" cx="261.1" cy="185.2" rx="98.7" ry="141.6"/>
                      <line className="st4" x1="250.6" y1="74.8" x2="211.1" y2="114.3"/>
                      <line className="st4" x1="247.6" y1="93.5" x2="208.2" y2="133"/>
                    </g>
                    <g id="reflection-clip-group">
                      <defs>
                        <ellipse id="mirror-clip-def" cx="261" cy="185.5" rx="98.7" ry="141.6"/>
                      </defs>
                      <clipPath id="mirror-clip-path">
                        <use xlinkHref="#mirror-clip-def" style={{overflow: 'visible'}}/>
                      </clipPath>
                      <g className="st5">
                        <g id="reflection">
                          <path className="st6" d="M286.2,136.3c0,0-34.9-2.8-34.6,24.2c0.3,22.6,2,91.1,2,91.1s29,22.7,71.7-1c1.1-40.1-1.7-82-2.1-85.7
                            C318.7,124.1,286.2,136.3,286.2,136.3z"/>
                          <path className="st7" d="M293.3,273.3c0,0-14.9,7-17.4-44.4c-2.6-54.4,32.8-33.3,33.8,12.2c0.3,8.7-1.1,15.6-2.6,20.5
                            c-1.5,4.8-4.4,9.4-9,11.1L293.3,273.3z"/>
                          <path className="st8" d="M230.9,298.9l22.9,6l13.9-11.5c0,0-3.9,36.1-5.8,46.7s-6.8,22.2-6.8,22.2l84.8,0.5l-10.1-19.7
                            c0,0-2-14.9-2-21.7s-3.5-36.4-3.5-36.4l6.1,8.3l23.7-1c0,0-11.6-49.6-16.4-57.3s-30.6-13.6-30.6-13.6s0.8,18.9-15.7,18.9
                            S276,227,276,227s-33.3,26.2-36.1,37.9S230.9,298.9,230.9,298.9z"/>
                          <path className="st7" d="M332.7,291.5c0,0,4.1,19.8,7.6,34.6L324.5,314l-8.5,19.3c0,0,18.7,8.3,25,11
                            s22.6,9.2,22.6,2.4s-12.7-55.2-12.7-55.2H332.7z"/>
                          <ellipse className="st7" cx="289.8" cy="177.6" rx="25.5" ry="34.6"/>
                          <path className="st9" d="M292.5,184.3l-21.5,2.5c0,0,7.9,13.8,19.2,9.1"/>
                          <path className="st9" d="M287.7,184.8l15.2-2c0,0-0.5,11.3-15.5,13.9"/>
                          <path className="st10" d="M222.6,214.8c0,0-28.6,4.7-31.1,24.1c-1.9,15.4-8.2,68.1-8.2,68.1s42.4,29.7,83.7,13.4
                            c1.1-27.6-6-80.9-8.3-88.5C251.5,208.5,222.6,214.8,222.6,214.8z"/>
                          <path className="st11" d="M287.5,170.2c0,0-1.8,2.9-2.5,4.8c-0.4,1.1,0.1,1.8,1.3,1.7l3.1,0"/>
                          <circle className="st6" cx="298.2" cy="165.9" r="2"/>
                          <path className="st6" d="M296.7,158.4c-0.7,0-1.2,0.6-1.3,1.3c0,0.7,0.6,1.2,1.3,1.3h0.3c0.2,0-0.2,0,0,0l0.5,0.1
                            c0.3,0.1,0.7,0.2,1,0.3l0.5,0.2l0.2,0.1l0.1,0.1c0.3,0.1,0.6,0.3,0.9,0.5c1.2,0.7,2.3,1.4,3.3,2.3c0.5,0.5,1.3,0.5,1.8,0
                            c0.5-0.5,0.5-1.3,0-1.8C302.7,160.5,300,158.6,296.7,158.4L296.7,158.4z"/>
                          <path className="st6" d="M279.2,159.6c-3.3,0.3-6,2.1-8.5,4.2c-0.5,0.5-0.5,1.3,0,1.8c0.5,0.5,1.3,0.5,1.8,0c1-0.9,2.1-1.7,3.3-2.3
                            c0.3-0.2,0.6-0.3,0.9-0.5l0.1-0.1l0.2-0.1l0.5-0.2c0.3-0.1,0.7-0.2,1-0.3l0.5-0.1c0.2,0-0.2,0,0,0h0.3c0.7,0,1.2-0.6,1.3-1.3
                            C280.5,160.2,279.9,159.6,279.2,159.6L279.2,159.6z"/>
                          <circle className="st6" cx="276.3" cy="167.7" r="2"/>
                          <path className="st7" d="M232.9,351c-5-0.6-8.8-4.5-11.4-8.8c-2.6-4.4-5.6-10.8-7.2-19.4c-9.5-44.4,20-73.1,30-19.6
                            c9.4,50.5-6.7,47.2-6.7,47.2L232.9,351z"/>
                          <path className="st12" d="M156.1,371.5l24.3,5.8c0,0,1.9,24.3,0,35s-0.8,25-0.8,25l82.4,0.2l-9.7-21.4c0,0-8.1-21.7-8.1-28.4
                            s2.9-23.3,2.9-23.3l4.9,25.2l26.2-13.6c0,0-10.9-55.9-15.8-63.6s-18.9-13.7-18.9-13.7s-0.2,8.7-16.7,8.7s-13.9-7.6-13.9-7.6
                            s-47.1,10.6-49.9,22.2C160.1,333.6,156.1,371.5,156.1,371.5z"/>
                          <ellipse className="st7" cx="227.4" cy="257.2" rx="25.5" ry="34.6"/>
                          <path className="st9" d="M224.8,267.8l21.4-3.2c0,0-4,15.4-16.2,13.8"/>
                          <path className="st9" d="M230.9,267l-19.9,1.6c-0.1-0.2,7.9,9,18.9,9.8"/>
                          <path className="st6" d="M245,241.4c-2.6-1.8-5.9-2-9-1.9c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c0.7,0,1.4,0,2.1,0s1.4,0.1,2,0.2
                            c0.7,0.1,1.3,0.3,2,0.5l0.5,0.2l0.3,0.1c0.3,0.2,0.6,0.4,0.9,0.6c0.6,0.3,1.4,0.1,1.7-0.4C245.8,242.6,245.6,241.8,245,241.4
                            L245,241.4z"/>
                          <path className="st11" d="M228.9,250.5c0,0,2.1,3.4,3.1,5.2c0.5,1,0.1,1.8-1.1,1.8l-4.2,0.5"/>
                          <path className="st6" d="M211.6,241.2c-0.6,0.3-0.8,1.1-0.5,1.7c0.3,0.6,1.1,0.8,1.7,0.4c0.3-0.2,0.6-0.4,0.9-0.6l0.3-0.1l0.5-0.2
                            c0.7-0.2,1.3-0.4,2-0.5c0.6-0.1,1.3-0.2,2-0.2s1.4,0,2.1,0c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3
                            C217.5,239.2,214.3,239.5,211.6,241.2L211.6,241.2z"/>
                          <circle className="st6" cx="239.7" cy="247.2" r="2"/>
                          <circle className="st6" cx="218" cy="248.1" r="2"/>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>

              </svg>
            </div>
            <div className="table-container">
              <svg 
                version="1.1" 
                xmlns="http://www.w3.org/2000/svg" 
                xmlnsXlink="http://www.w3.org/1999/xlink" 
                x="0px" y="0px"
                viewBox="0 0 600 670" 
              >
                <g id="table">
                  <rect x="384" y="368.6" className="st13" width="18" height="182.3"/>
                  <rect x="120.4" y="368.6" className="st13" width="18" height="182.3"/>
                  <rect x="112.6" y="349.8" className="st13" width="300.9" height="32.4"/>
                </g>
              </svg>
            </div>
            <div className="patient-container">
              <svg 
                version="1.1" 
                xmlns="http://www.w3.org/2000/svg" 
                xmlnsXlink="http://www.w3.org/1999/xlink" 
                x="0px" y="0px"
                viewBox="0 0 600 670" 
              >
                <g id="patient">
                  <path className="st6" d="M133.2,576.8c0,0,4.2-9.2,13.4-13.2s28.1-7.9,28.1-7.9l15.8,1.8l0.4,21.2L133.2,576.8z"/>
                  <path className="st6" d="M265.9,569.6c0,0-4.9-8.9-14.3-12.3c-9.4-3.4-28.6-5.9-28.6-5.9l-15.7,2.8l1.1,21.2L265.9,569.6z"/>
                  <polygon className="st2" points="192.4,505 192.4,558.2 164.1,558.2 165.2,474.3 232.6,469.6 238.2,551 206.6,555.5 209.7,464.7 
                    193.7,468.9"/>
                  <path className="st7" d="M244,394.9c0,0,1.6,8.9,5.2,23.7c-4.9,1-15.8-12.1-15.8-12.1l-8.5,19.3c0,0,18.7,8.3,25,11
                    c6.3,2.7,22.6,9.2,22.6,2.4S259.7,384,259.7,384L244,394.9z"/>
                  <path className="st7" d="M158.3,389.3c0,0-1.6,8.9-5.1,23.7c4.9,1,15.8-12.1,15.8-12.1l8.5,19.3c0,0-18.7,8.3-25,11s-22.6,9.2-22.6,2.4
                    s12.7-55.2,12.7-55.2L158.3,389.3z"/>
                  <path className="st12" d="M134.4,386.8l24.3,5.8c0,0,1.9,24.3,0,35s-0.8,25-0.8,25l82.4,0.2l-9.7-21.4c0,0,3.9-15.5,3.9-22.3
                    s2.9-23.3,2.9-23.3l4.9,25.2l26.2-13.6c0,0-11.7-54.4-16.5-62.1c-4.9-7.8-30.1-21.4-30.1-21.4s-8.7,4.9-25.2,4.9
                    s-23.3-2.9-23.3-2.9s-29.1,9.7-32,21.4C138.4,348.9,134.4,386.8,134.4,386.8z"/>
                  <path className="st10" d="M193.5,233.8c0,0-29.6,4.6-32.1,23.7c-2,15.2-8.5,67.1-8.5,67.1s56.2,19.2,98.9,3.2
                    c1.1-27.2-18.5-69.7-20.9-77.2C223.4,227.6,193.5,233.8,193.5,233.8z"/>
                  <g id="chair">
                    <rect x="152.5" y="468.8" className="st14" width="16" height="121.4"/>
                    <rect x="231.6" y="468.3" className="st14" width="16" height="121.4"/>
                    <path className="st14" d="M247.8,477.7h-95.4V361.2c0,0,1.1-9.7,9.3-9.7h77.9c7,0,8.2,11.1,8.2,11.1L247.8,477.7z"/>
                  </g>
                </g>
              </svg>
            </div>
            <div className="caregiver-container">
              <svg 
                version="1.1" 
                xmlns="http://www.w3.org/2000/svg" 
                xmlnsXlink="http://www.w3.org/1999/xlink" 
                x="0px" y="0px"
                viewBox="0 0 600 670" 
              >
                <g id="caregiver">
                <path className="st6" d="M244.3,659.3c0,0,4.8-8.8,14.2-12.2c9.4-3.4,28.3-5.9,28.3-5.9l15.5,2.8l-1,21L244.3,659.3z"/>
                  <path className="st6" d="M300,659.6c0,0,4.8-8.8,14.2-12.2s28.4-5.9,28.4-5.9l15.5,2.8l-1.1,21L300,659.6z"/>
                  <path className="st7" d="M364.7,282.4c0,0,4.1,19.8,7.6,34.6l-15.8-12.1l-8.5,19.3c0,0,18.7,8.3,25,11s22.6,9.2,22.6,2.4
                    S383,282.4,383,282.4H364.7z"/>
                  <path className="st8" d="M262.3,443.8c1,49.5,8.7,198.9,8.7,198.9l34,4.9c0,0,5.5-160.4,5.5-176.9s9.1-64.2,9.1-64.2s6.8,36.4,6.8,46.1
                    s-1,191.1-1,191.1l38.8,1.9c0,0,9.7-194,9.7-219.3s-2.9-63.1-11.7-77.7s-90.3,0-90.3,0S261.4,394.2,262.3,443.8z"/>
                  <path className="st7" d="M276.6,285.2l-15.8-11c0,0-9.9,43-9.9,49.8l-16.2,5.3c-4.1,0-7.9-1.8-10.6-4.8c-0.6-0.7-2.6-6.3-4.1-3.7
                    c-1,2.1-1.1,4.5-0.4,6.6c0.5,1.4-2.9-2.3-5-5.1c-1.1-1.5-3.7-4-5.3-2.3c-6.3,6.8-1.1,20.5,11.7,21.2c36.3,2.1,40.5,2.8,41.9-1.2
                    C274.5,306.6,275.5,300.9,276.6,285.2z"/>
                  <path className="st8" d="M256.5,282.6l22.9,6l2.4-3.8c0,0,1,27.9-1,38.5s-11.7,25.7-11.7,25.7l96.3-2.5l-10.1-19.7c0,0,1.2-15.2,1.2-22
                    s2.9-23.3,2.9-23.3l0.7,9.3l28-8c0,0-9.3-44-14.2-51.7s-30.1-21.4-30.1-21.4s-8.7,4.9-25.2,4.9s-23.3-2.9-23.3-2.9
                    s-29.1,9.7-32,21.4S256.5,282.6,256.5,282.6z"/>
                  <path className="st6" d="M313.8,123c0,0-34.9-2.8-34.6,24.2c0.3,22.6,2,91.1,2,91.1s29,22.6,71.7-1.1c1.1-40.1-1.7-82-2.1-85.7
                    C346.3,110.9,313.8,123,313.8,123z"/>
                  <path id="hairbrush" className="st6" d="M183.1,288c0,0-2-4.5,1.8-6.8s15.3-8.6,15.3-8.6s3.5-2,6.3,2.9
                    s16.4,28.2,16.4,28.2s1.3,2.3,0,3.9s-5.1,6.2-3.8,9c1.5,5.9,10,14,11.6,16.4s7.5,13.5,3.1,16.8s-11.1-6.4-13.3-11.7
                    s-3.3-11.7-5.2-14.9c-1.1-1.9-2.9-3.3-5-3.9c-2.6-0.4-5.2-0.6-7.8-0.7c-1.7,0-3.2-1.1-3.7-2.8L183.1,288z"/>
                </g>
              </svg>
            </div>
          </div>
        </div>

        <div 
          ref={el => animateRefs.current[2] = el}
          className={`caregivers-svg-container ${animationClassNames[2] || ''}`}
        >
          <div>
            <div id="caregivers-background">
              <svg 
                version="1.1" 
                xmlns="http://www.w3.org/2000/svg" 
                xmlnsXlink="http://www.w3.org/1999/xlink" 
                x="0px" y="0px"
                viewBox="0 0 1110.06 1065.87"
              >
                <path className="st0" d="M123.64,775.42c-56.12-62.31-87.54-119.46-86.15-182.9
                  c1-45.53,18.63-82.17,38.07-113.11c105.39-167.76,309.27-240.23,319.9-243.87c187.68-64.24,432.15-147.91,540.96,12.84
                  c111.18,164.25-0.25,454.59-9.35,477.3c-23.36,58.28-110.04,249.87-289.85,306.44C417.16,1101.34,244.44,909.56,123.64,775.42z"/>
              </svg>
            </div>

            <div id="surgical-mask">
              <svg 
                version="1.1" 
                xmlns="http://www.w3.org/2000/svg" 
                xmlnsXlink="http://www.w3.org/1999/xlink" 
                x="0px" y="0px"
                viewBox="0 0 1110.06 1065.87"
              >
                <ellipse className="st1" cx="931.64" cy="547.89" rx="139.29" ry="142.43"/>
                <ellipse className="st2" cx="941.42" cy="542.89" rx="139.29" ry="142.43"/>
                <g>
                  <path className="st3" d="M983.49,531.08c0,0,26.94-4.3,48.82-11.19c21.88-6.89,35.35,11.48,25.25,33.85s-46.37,54.65-64.6,62.11"/>
                  <path className="st3" d="M826.7,522.67c0,0,23.95-23.01,41.14-45.1c17.19-22.1,42.89-12.99,47.81,16.5
                    c4.91,29.49-9.61,87.07-22.78,107.21"/>
                  <path className="st4" d="M990.65,528.07c0,0-20.62,51.64,7.15,86.5c0,0-79.12,96.64-136.36,58.65
                    c-57.24-37.99-57.24-93.94-42.93-145.14c0,0,20.2-37.87,91.75-21.52C981.81,522.9,990.65,528.07,990.65,528.07z"/>
                  <path className="st5" d="M825.67,553.45c1.87-2.22,4.19-4.06,6.62-5.7c2.43-1.66,5.11-2.94,7.85-4.06
                    c5.54-2.11,11.55-3.14,17.54-2.99c5.98,0.02,11.99,1.56,17.42,2.77c5.59,1.18,11.21,2.24,16.87,3.19
                    c11.3,1.92,22.68,3.55,34.07,4.98c5.7,0.68,11.41,1.33,17.13,1.78c2.86,0.26,5.72,0.41,8.59,0.51c2.86,0.06,5.74,0.11,8.58-0.48
                    c-2.69,1.14-5.64,1.52-8.52,1.87c-2.9,0.32-5.81,0.46-8.71,0.56c-5.81,0.13-11.62,0-17.41-0.35c-11.59-0.65-23.12-2-34.57-3.94
                    c-5.72-0.98-11.43-2.12-17.09-3.51c-2.82-0.69-5.7-1.54-8.34-2.15l-2.03-0.5l-2.05-0.32c-1.37-0.22-2.74-0.49-4.13-0.54
                    c-5.53-0.47-11.15-0.04-16.58,1.42c-2.71,0.73-5.39,1.69-7.94,2.94c-1.28,0.61-2.54,1.29-3.76,2.04
                    C827.99,551.73,826.79,552.54,825.67,553.45z"/>
                  <path className="st5" d="M830.25,587.96c0.75-1.13,1.86-1.98,2.99-2.73c0.57-0.38,1.18-0.69,1.77-1.03c0.62-0.29,1.23-0.6,1.86-0.87
                    c2.52-1.09,5.19-1.72,7.85-2.31c5.36-1.01,10.82-1.33,16.24-1.26c5.44,0.1,10.75,0.52,16.08,0.93l15.92,1.28
                    c10.59,0.85,21.15,1.51,31.69,1.41c1.32,0,2.63-0.09,3.94-0.13c1.32-0.04,2.63-0.11,3.94-0.22c1.31-0.1,2.63-0.18,3.94-0.31
                    l3.91-0.52c5.21-0.76,10.35-1.99,15.38-3.89c-4.53,2.9-9.66,4.83-14.88,6.29c-5.25,1.43-10.64,2.16-16.03,2.67
                    c-10.79,0.91-21.6,0.53-32.28-0.33c-5.35-0.42-10.67-0.97-15.97-1.55c-5.3-0.59-10.61-1.23-15.82-1.75
                    c-5.24-0.39-10.5-0.61-15.72-0.2c-2.6,0.22-5.21,0.54-7.74,1.2c-0.63,0.16-1.25,0.36-1.89,0.55c-0.61,0.23-1.23,0.44-1.83,0.71
                    C832.4,586.4,831.22,587.04,830.25,587.96z"/>
                  <path className="st5" d="M834.09,610.6c11.05,0.95,22.04,1.47,33.03,1.66c10.98,0.12,21.95-0.07,32.86-0.86
                    c5.46-0.38,10.91-0.88,16.33-1.56c2.72-0.29,5.42-0.74,8.13-1.1c2.69-0.46,5.41-0.84,8.1-1.35c10.79-1.93,21.41-4.68,32.01-7.98
                    c-9.8,5.22-20.35,9.1-31.18,11.79c-5.4,1.42-10.89,2.48-16.41,3.33c-2.76,0.45-5.53,0.77-8.3,1.11
                    c-2.78,0.27-5.55,0.57-8.34,0.74c-11.13,0.79-22.31,0.68-33.4-0.24c-5.55-0.44-11.07-1.13-16.56-2.01
                    C844.89,613.21,839.42,612.16,834.09,610.6z"/>
                </g>
              </svg>
            </div>

            <div id="hand-on-left">
              <svg 
                version="1.1" 
                xmlns="http://www.w3.org/2000/svg" 
                xmlnsXlink="http://www.w3.org/1999/xlink" 
                x="0px" y="0px"
                viewBox="0 0 1110.06 1065.87"
              >
                <g>
                  <path className="st6" d="M292.4,789.01c-3.11-8.8-7.44-17.29-13.75-24.54c-3.08-3.54-7.05-6.31-10.6-9.44
                    c-9.2-8.09-18.38-16.22-27.63-24.26c-1.24-1.08-0.81-1.77-0.05-2.77c7.85-10.37,15.66-20.76,23.5-31.14
                    c14.46-19.14,28.93-38.27,43.39-57.4c5.06-6.73,17.09-18.79,24.09-24.79c1.14-1.55,4.36,1.19,6,2c6.7,3.32,7.66,4.66,13,10
                    c3.61,3.61,5.13,6.85,10,7c8.81,0.28,15.14,2.18,23.96,2.19c1.02,0,2.03,0,3.84,0c-2.02,6.09-3.69,11.76-5.77,17.27
                    c-5.99,15.83-16.09,29.14-26.3,42.37c-1.75,2.27-3.2,4.77-4.71,7.22c-1.46,2.36-0.79,4.39,0.69,6.64
                    c5.04,7.64,12.23,12.66,20.07,16.91c8.91,4.83,18.37,5.46,28.26,3.92c7.07-1.1,13.86-2.94,20.47-5.59
                    c11.55-4.64,21.57-11.23,29.03-21.48c3.63-4.99,12.66-12.53,14.47-18.45c0.51-1.65,4.72-1.17,6-1c6.96,0.93,4.97,3.39,11.83,4.79
                    c6.09,1.25,12.27,2.77,17.9,5.31c6.36,2.87,12.27,6.83,18.15,10.66c9.86,6.42,19.59,13.04,29.29,19.7
                    c8.55,5.88,17,11.91,25.46,17.92c13.91,9.89,27.86,19.74,41.68,29.77c15.02,10.9,30.69,20.96,44.07,33.99
                    c5.96,5.81,11.45,12,15.19,19.49c5.72,11.46,4.44,21.81-5.4,30.01c-3.21,2.67-7.06,4-11.23,4.27c-9.66,0.61-18.52-2.41-27.3-5.96
                    c-12.77-5.16-24.62-12.05-36.53-18.85c-2.21-1.26-4.52-2.36-6.78-3.53c-0.14,0.23-0.28,0.45-0.43,0.68
                    c2.82,1.97,5.58,4.03,8.47,5.9c7.74,5.02,15.54,9.93,23.29,14.92c11.66,7.51,12.43,20.13,3.67,30.41
                    c-7.44,8.72-17.2,12.98-28.76,11.9c-5.66-0.53-10.98-2.81-15.88-5.7c-11.51-6.79-22.92-13.76-34.42-20.57
                    c-3.44-2.04-7.03-3.84-10.58-5.69c-0.78-0.4-1.69-0.55-2.54-0.81c-0.12,0.21-0.24,0.41-0.35,0.62c0.66,0.58,1.26,1.23,1.98,1.71
                    c13.44,8.91,26.88,17.86,38.58,29.04c2.37,2.27,3.83,5.59,5.34,8.61c0.51,1.02,0.25,2.79-0.29,3.91
                    c-3.25,6.71-7,13.29-14.81,15.31c-3.65,0.95-7.88,1.01-11.57,0.21c-9.22-2-16.91-7.51-25.02-12.06
                    c-12.64-7.09-25.44-13.92-38.24-20.73c-2.32-1.24-4.96-1.88-7.45-2.79c-0.13,0.25-0.26,0.5-0.39,0.75
                    c3.56,2.26,7.09,4.57,10.68,6.78c10.46,6.44,21.54,11.99,30.73,20.29c2.89,2.61,5.49,5.95,7.11,9.47
                    c2.08,4.54-0.12,9.18-4.42,12.54c-5.35,4.18-11.11,3.62-17.09,1.95c-8.69-2.42-16.44-6.83-24.26-11.14
                    c-4.24-2.34-8.44-4.77-12.82-6.82c-2.03-0.95-2.34-2.1-2.25-4.11c0.29-6.38,0.73-12.79,0.41-19.15
                    c-0.39-7.87-3.06-15.09-10.48-18.95c-4.3-2.23-9.22-3.48-14-4.49c-4.12-0.86-4.12-0.44-4.69-4.73
                    c-1.06-7.95-3.06-15.64-7.99-22.16c-8.11-10.74-18.19-12.66-30.74-10.2c-3.54,0.69-6.89,2.62-10.15,4.32
                    c-1.49,0.77-2.05,0.27-2.58-0.79c-2.13-4.24-4.18-8.52-6.28-12.77c-4.58-9.26-11.45-15.36-22.15-16.59
                    c-3.77-0.43-7.42-1.76-11.17-2.45c-3.72-0.69-5.32-3.47-6.87-6.37c-2.03-3.77-3.62-7.86-6.13-11.28
                    c-4.61-6.3-11.44-7.91-18.71-6.55C306.41,784.98,299.58,787.15,292.4,789.01z"/>
                  <path className="st6" d="M162.04,713.43c4.68-6.39,9.27-12.68,13.88-18.95c7.7-10.47,15.39-20.95,23.12-31.41
                    c11.28-15.26,22.6-30.5,33.89-45.76c11.01-14.88,30.42-35.76,41.42-50.65c5.68-7.69,10.43-12.9,19-20c2.06-1.7,4.13-0.33,6,1
                    c5.53,3.95,4.71,4.75,10,9c7.82,6.29,16.38,11.47,24,18c1.19,1.02,1.8,2.71,3,4c-4.76,5.9-15.08,16.67-19.7,22.38
                    c-10.53,13.03-21.06,26.06-31.58,39.1c-7.07,8.76-11.68,19.74-18.72,28.52c-16,19.94-32.01,42.05-48,62
                    c-4.18,5.22-5.87,7.75-10,13c-0.86,1.1-4.83,1.88-6,1c-11.57-8.67-24.87-19.47-36.44-28.16
                    C164.65,715.57,163.44,714.55,162.04,713.43z"/>
                </g>
                <g>
                  <path className="st7" d="M301.23,786.01c-3.1-8.8-7.41-17.29-13.7-24.54c-3.07-3.54-7.02-6.31-10.56-9.44
                    c-9.17-8.09-18.31-16.22-27.53-24.26c-1.24-1.08-0.8-1.77-0.05-2.77c7.82-10.37,15.6-20.76,23.41-31.14
                    c14.4-19.14,28.82-38.27,43.23-57.4c5.07-6.73,10.16-13.45,15.14-20.25c1.14-1.55,2.13-1.71,3.76-0.9
                    c6.68,3.32,12.87,7.26,18.19,12.61c3.6,3.61,8.48,4.68,13.33,4.83c8.78,0.28,17.57,0.1,26.36,0.11c1.01,0,2.03,0,3.82,0
                    c-2.01,6.09-3.67,11.76-5.75,17.27c-5.97,15.83-16.03,29.14-26.21,42.37c-1.74,2.27-3.19,4.77-4.7,7.22
                    c-1.46,2.36-0.79,4.39,0.69,6.64c5.03,7.64,12.19,12.66,20,16.91c8.87,4.83,18.3,5.46,28.16,3.92c7.04-1.1,13.81-2.94,20.4-5.59
                    c11.51-4.64,21.49-11.23,28.92-21.48c3.62-4.99,6.87-10.1,8.67-16.01c0.5-1.65,1.47-1.75,2.74-1.58
                    c6.93,0.93,13.93,1.53,20.76,2.94c6.06,1.25,12.23,2.77,17.83,5.31c6.34,2.87,12.22,6.83,18.08,10.66
                    c9.83,6.42,19.52,13.04,29.18,19.7c8.52,5.88,16.94,11.91,25.36,17.92c13.86,9.89,27.76,19.74,41.53,29.77
                    c14.97,10.9,30.58,20.96,43.91,33.99c5.94,5.81,11.41,12,15.14,19.49c5.7,11.46,4.42,21.81-5.38,30.01
                    c-3.2,2.67-7.04,4-11.19,4.27c-9.63,0.61-18.45-2.41-27.2-5.96c-12.72-5.16-24.53-12.05-36.4-18.85c-2.2-1.26-4.5-2.36-6.75-3.53
                    c-0.14,0.23-0.28,0.45-0.42,0.68c2.81,1.97,5.56,4.03,8.44,5.9c7.71,5.02,15.48,9.93,23.21,14.92
                    c11.62,7.51,12.39,20.13,3.65,30.41c-7.41,8.72-17.14,12.98-28.65,11.9c-5.64-0.53-10.94-2.81-15.83-5.7
                    c-11.47-6.79-22.84-13.76-34.29-20.57c-3.43-2.04-7-3.84-10.54-5.69c-0.77-0.4-1.68-0.55-2.53-0.81
                    c-0.12,0.21-0.23,0.41-0.35,0.62c0.66,0.58,1.25,1.23,1.97,1.71c13.39,8.91,26.78,17.86,38.44,29.04
                    c2.36,2.27,3.82,5.59,5.32,8.61c0.51,1.02,0.25,2.79-0.29,3.91c-3.24,6.71-6.98,13.29-14.76,15.31
                    c-3.64,0.95-7.85,1.01-11.53,0.21c-9.19-2-16.85-7.51-24.93-12.06c-12.6-7.09-25.35-13.92-38.1-20.73
                    c-2.31-1.24-4.94-1.88-7.42-2.79c-0.13,0.25-0.26,0.5-0.39,0.75c3.54,2.26,7.06,4.57,10.64,6.78
                    c10.43,6.44,21.46,11.99,30.62,20.29c2.88,2.61,5.47,5.95,7.08,9.47c2.07,4.54-0.12,9.18-4.41,12.54
                    c-5.33,4.18-11.07,3.62-17.03,1.95c-8.66-2.42-16.38-6.83-24.17-11.14c-4.23-2.34-8.41-4.77-12.77-6.82
                    c-2.02-0.95-2.33-2.1-2.24-4.11c0.29-6.38,0.73-12.79,0.41-19.15c-0.39-7.87-3.05-15.09-10.45-18.95
                    c-4.28-2.23-9.19-3.48-13.95-4.49c-4.11-0.86-4.11-0.44-4.68-4.73c-1.06-7.95-3.05-15.64-7.96-22.16
                    c-8.08-10.74-18.13-12.66-30.62-10.2c-3.53,0.69-6.87,2.62-10.12,4.32c-1.48,0.77-2.04,0.27-2.57-0.79
                    c-2.12-4.24-4.16-8.52-6.26-12.77c-4.56-9.26-11.41-15.36-22.07-16.59c-3.75-0.43-7.39-1.76-11.12-2.45
                    c-3.71-0.69-5.3-3.47-6.85-6.37c-2.02-3.77-3.61-7.86-6.1-11.28c-4.59-6.3-11.4-7.91-18.65-6.55
                    C315.19,781.98,308.38,784.15,301.23,786.01z"/>
                  <path className="st9" d="M171.35,710.43c4.66-6.39,9.23-12.68,13.83-18.95c7.67-10.47,15.34-20.95,23.03-31.41
                    c11.24-15.26,22.51-30.5,33.76-45.76c10.97-14.88,21.93-29.76,32.89-44.64c5.66-7.69,11.4-15.32,16.93-23.1
                    c1.54-2.17,2.97-2.17,4.83-0.84c5.51,3.95,11.18,7.7,16.45,11.95c7.79,6.29,15.31,12.93,22.91,19.46
                    c1.18,1.02,2.16,2.28,3.35,3.57c-4.74,5.9-9.34,11.62-13.93,17.34c-10.49,13.03-20.98,26.06-31.47,39.1
                    c-7.04,8.76-14.09,17.51-21.1,26.29c-15.94,19.94-31.85,39.9-47.79,59.85c-4.17,5.22-8.4,10.38-12.52,15.63
                    c-0.86,1.1-1.57,1.5-2.74,0.62c-11.53-8.67-23.06-17.34-34.58-26.02C173.95,712.57,172.74,711.55,171.35,710.43z"/>
                </g>
              </svg>
            </div>

            <div id="hand-on-right">
              <svg 
                version="1.1" 
                xmlns="http://www.w3.org/2000/svg" 
                xmlnsXlink="http://www.w3.org/1999/xlink" 
                x="0px" y="0px"
                viewBox="0 0 1110.06 1065.87"
              >
                <g>
                  <path className="st6" d="M359.91,703.04c8.27-8.89,15.47-18.41,21.26-29.01c5.51-10.07,9.48-20.76,13.26-31.53
                    c2.39-6.8,5.13-13.28,11.15-17.73c5.26-3.89,11.22-6.31,17.59-7.48c6.43-1.18,12.93-2.09,19.44-2.69
                    c6.07-0.56,12.2-0.41,18.29-0.85c10.82-0.79,21.67-1.14,32.32-3.78c7.64-1.89,15.29-0.46,22.74,1.87
                    c8.28,2.59,16.65,4.94,24.85,7.77c8.24,2.84,16.25,6.33,24.48,9.19c6.55,2.28,13.21,4.32,19.95,5.95
                    c6.77,1.64,12.97-0.83,18.5-4.48c6.54-4.32,12.76-9.12,19.11-13.74c1.43-1.04,2.16-0.25,2.93,0.88c3.77,5.55,7.43,11.19,11.4,16.6
                    c8.85,12.05,17.76,24.06,26.85,35.93c9.83,12.84,19.76,25.6,29.89,38.2c5.51,6.85,11.63,13.22,17.29,19.96
                    c6.08,7.25,11.87,14.74,15.31,23.7c2.94,7.66,0.73,14.05-6.42,18.16c-5.6,3.21-11.7,5.69-16.86,9.47
                    c-6.93,5.07-13.17,11.07-19.7,16.68c-2.53,2.17-4.98,4.45-7.58,6.54c-2.85,2.29-5.63,2.35-8.39-0.01
                    c-5.99-5.11-11.59-10.74-17.84-15.5c-9.42-7.17-19.3-13.72-28.92-20.63c-12.8-9.19-25.57-18.42-38.28-27.73
                    c-10.42-7.63-20.7-15.47-31.12-23.11c-11.35-8.32-22.73-16.6-34.22-24.73c-8.46-5.99-17.49-10.8-27.83-12.92
                    c-4.97-1.02-9.7-3.41-14.68-4.04c-7.36-0.93-14.88-2.11-22.22,0.79c-2.66,1.05-4.76,2.65-5.98,5.11
                    c-5.34,10.75-13.62,18.81-23.11,25.84c-6.34,4.69-13.22,8.41-20.59,11.09c-8.08,2.94-16.58,3.99-24.91,1.83
                    C369.68,716.52,363.7,711.09,359.91,703.04z"/>
                  <path className="st6" d="M792.35,725.01c-13.33,12.38-27.86,22.32-43.41,30.92c-3.97-6.54-7.49-13-11.64-19.03
                    c-7.17-10.42-14.58-20.69-22.18-30.8c-8.28-11-17.19-21.54-25.3-32.66c-12.11-16.6-23.79-33.51-35.61-50.33
                    c-5.24-7.46-10.31-15.04-15.59-22.48c-2.93-4.13-6.11-8.08-9.11-12.01c14.64-10.83,28.73-21.25,43.09-31.88
                    C712.68,613.06,752.41,668.89,792.35,725.01z"/>
                  <path className="st6" d="M404.31,841.33c6.09-0.02,10.53,2.31,14.12,6.23c6.7,7.31,10.26,15.8,8.27,25.82
                    c-1.44,7.23-5.91,12.95-10.64,18.37c-4.7,5.39-9.68,10.53-14.4,15.9c-1.49,1.7-3.07,1.16-4.62,0.71
                    c-6.79-1.94-13.29-4.65-18.74-9.17c-3.85-3.19-7.54-6.72-10.66-10.62c-3.95-4.94-3.58-9.96,0.34-14.9
                    c3.41-4.3,7.21-8.32,10.36-12.8c4.11-5.86,8.53-11.39,14.42-15.49C396.35,842.88,400.32,841.02,404.31,841.33z"/>
                  <path className="st6" d="M350.73,819.31c9.86,0.11,16.66,5.17,20.53,13.84c4.85,10.86,4.65,21.58-2.78,31.53
                    c-4.74,6.34-14.26,10.07-24.5,7.01c-9.05-2.71-14.65-8.7-18.32-17.01c-1.81-4.1-1.81-8.33-0.21-12.34
                    c3.31-8.27,8.01-15.69,15.74-20.5c2.7-1.68,6.13-2.19,9.23-3.23C350.52,818.84,350.62,819.08,350.73,819.31z"/>
                  <path className="st6" d="M456.12,902.12c-0.46,10.93-3.55,20.5-12.47,27.48c-12.63,9.87-28.72,6.47-38.08-6.08
                    c-2.57-3.45-2.77-6.03-0.01-9.65c6.59-8.66,12.49-17.84,18.99-26.57c4.04-5.43,9.87-7.94,16.57-6.54
                    c6.92,1.45,11.79,5.75,13.59,12.84C455.41,896.37,455.66,899.27,456.12,902.12z"/>
                  <path className="st6" d="M310.87,837.18c-10.95-0.21-17.07-7.76-16.01-17.42c0.8-7.25,3.51-13.98,8.57-19.62
                    c6.1-6.8,13.39-7.51,20.74-2.85c6.24,3.95,10.43,9.45,11.58,16.93c0.53,3.44-0.5,6.48-2.66,9.14c-4.06,4.99-8.83,9.19-14.75,11.78
                    C315.57,836.34,312.45,836.76,310.87,837.18z"/>
                </g>
                <g>
                  <path className="st8" d="M368.49,700.04c8.24-8.89,15.41-18.41,21.19-29.01c5.49-10.07,9.45-20.76,13.21-31.53
                    c2.38-6.8,5.11-13.28,11.11-17.73c5.24-3.89,11.18-6.31,17.52-7.48c6.41-1.18,12.88-2.09,19.37-2.69
                    c6.05-0.56,12.16-0.41,18.22-0.85c10.78-0.79,21.59-1.14,32.2-3.78c7.62-1.89,15.24-0.46,22.66,1.87
                    c8.25,2.59,16.59,4.94,24.75,7.77c8.21,2.84,16.19,6.33,24.39,9.19c6.53,2.28,13.16,4.32,19.87,5.95
                    c6.75,1.64,12.92-0.83,18.43-4.48c6.52-4.32,12.72-9.12,19.04-13.74c1.42-1.04,2.15-0.25,2.92,0.88
                    c3.76,5.55,7.4,11.19,11.36,16.6c8.82,12.05,17.7,24.06,26.76,35.93c9.79,12.84,19.69,25.6,29.78,38.2
                    c5.49,6.85,11.59,13.22,17.22,19.96c6.06,7.25,11.82,14.74,15.26,23.7c2.93,7.66,0.73,14.05-6.39,18.16
                    c-5.57,3.21-11.65,5.69-16.8,9.47c-6.9,5.07-13.12,11.07-19.63,16.68c-2.52,2.17-4.96,4.45-7.55,6.54
                    c-2.84,2.29-5.61,2.35-8.36-0.01c-5.97-5.11-11.54-10.74-17.78-15.5c-9.39-7.17-19.23-13.72-28.82-20.63
                    c-12.75-9.19-25.48-18.42-38.14-27.73c-10.38-7.63-20.62-15.47-31-23.11c-11.31-8.32-22.65-16.6-34.09-24.73
                    c-8.43-5.99-17.43-10.8-27.73-12.92c-4.95-1.02-9.66-3.41-14.63-4.04c-7.34-0.93-14.83-2.11-22.14,0.79
                    c-2.65,1.05-4.74,2.65-5.96,5.11c-5.32,10.75-13.57,18.81-23.03,25.84c-6.32,4.69-13.17,8.41-20.52,11.09
                    c-8.05,2.94-16.52,3.99-24.81,1.83C378.23,713.52,372.27,708.09,368.49,700.04z"/>
                  <path className="st9" d="M799.35,722.01c-13.28,12.38-27.76,22.32-43.25,30.92c-3.95-6.54-7.46-13-11.6-19.03
                    c-7.15-10.42-14.52-20.69-22.1-30.8c-8.25-11-17.13-21.54-25.21-32.66c-12.07-16.6-23.71-33.51-35.48-50.33
                    c-5.22-7.46-10.27-15.04-15.53-22.48c-2.92-4.13-6.09-8.08-9.08-12.01c14.59-10.83,28.63-21.25,42.93-31.88
                    C719.97,610.06,759.56,665.89,799.35,722.01z"/>
                  <path className="st8" d="M412.74,838.33c6.07-0.02,10.49,2.31,14.07,6.23c6.67,7.31,10.23,15.8,8.24,25.82
                    c-1.44,7.23-5.89,12.95-10.6,18.37c-4.69,5.39-9.65,10.53-14.35,15.9c-1.49,1.7-3.06,1.16-4.6,0.71
                    c-6.76-1.94-13.24-4.65-18.67-9.17c-3.84-3.19-7.52-6.72-10.62-10.62c-3.93-4.94-3.57-9.96,0.34-14.9
                    c3.4-4.3,7.19-8.32,10.32-12.8c4.1-5.86,8.5-11.39,14.37-15.49C404.8,839.88,408.76,838.02,412.74,838.33z"/>
                  <path className="st8" d="M359.34,816.31c9.83,0.11,16.6,5.17,20.46,13.84c4.83,10.86,4.63,21.58-2.77,31.53
                    c-4.72,6.34-14.21,10.07-24.41,7.01c-9.01-2.71-14.6-8.7-18.25-17.01c-1.8-4.1-1.81-8.33-0.21-12.34
                    c3.3-8.27,7.98-15.69,15.68-20.5c2.69-1.68,6.11-2.19,9.2-3.23C359.14,815.84,359.24,816.08,359.34,816.31z"/>
                  <path className="st8" d="M464.35,899.12c-0.46,10.93-3.54,20.5-12.43,27.48c-12.58,9.87-28.61,6.47-37.94-6.08
                    c-2.57-3.45-2.76-6.03-0.01-9.65c6.56-8.66,12.44-17.84,18.92-26.57c4.03-5.43,9.83-7.94,16.5-6.54
                    c6.89,1.45,11.75,5.75,13.54,12.84C463.65,893.37,463.9,896.27,464.35,899.12z"/>
                  <path className="st8" d="M319.63,834.18c-10.91-0.21-17.01-7.76-15.95-17.42c0.8-7.25,3.5-13.98,8.54-19.62
                    c6.08-6.8,13.34-7.51,20.67-2.85c6.21,3.95,10.39,9.45,11.54,16.93c0.53,3.44-0.5,6.48-2.65,9.14c-4.05,4.99-8.8,9.19-14.7,11.78
                    C324.31,833.34,321.2,833.76,319.63,834.18z"/>
                </g>
                <g id="fingernails">
                  <path className="st0" d="M376.76,701.73c-0.51-7.83,7.44-8.34,14.15-20.34c3.92-7.01,5.2-13.57,9.6-16.93
                    c6.01-4.58,19.48-0.55,22.86,6.57c4.17,8.76-7.69,21.71-18.19,28.42C388.38,710.19,376.99,705.39,376.76,701.73z"/>
                  <path className="st0" d="M395.78,855.8c1.55-7.65,14.02-12.79,21.6-9.09c4.08,1.99,8.04,6.06,10.23,11.37
                    c2.25,5.44,2.84,12.38,0,15.92c-4.13,5.14-19.47,2.12-27.28-6.82C398.73,865.34,394.76,860.8,395.78,855.8z"/>
                  <path className="st0" d="M439.35,885.01c3.48-3.77,9.99-2.46,13.64,0c5.35,3.61,7.44,15.22,4.11,19.21
                    c-3.47,4.15-16.37-3.64-20.72-8.84C433.49,891.91,435.88,888.76,439.35,885.01z"/>
                  <path className="st0" d="M375.49,843.58c2.84-3.07,2.26-8.44-1.52-13.14c-3.12-3.87-5.85-6.9-14.15-7.83
                    c-5.53-0.62-12.38,2.02-12.38,8.34c0,7.62,11.17,12.98,16.93,14.78C367.19,846.61,372.67,846.63,375.49,843.58z"/>
                  <path className="st0" d="M317.72,800c-1.53,3.5,4.33,8.65,4.98,9.22c4.22,3.73,11.7,6.92,14.2,4.25c3.99-4.25,0.48-11.61-5.43-15
                    C326.54,795.64,320.42,793.82,317.72,800z"/>
                </g>
              </svg>
            </div>

            <div id="calendar">
              <svg 
                version="1.1" 
                xmlns="http://www.w3.org/2000/svg" 
                xmlnsXlink="http://www.w3.org/1999/xlink" 
                x="0px" y="0px"
                viewBox="0 0 1110.06 1065.87"
              >
                <path className="st17" d="M420.88,199.99c0-13.15-10.5-24.24-23.46-24.77L53.88,161.09c-15.21-0.63-27.91,10.51-27.91,24.88V466.8
                  c0,14.37,12.66,25.63,27.87,25.17l8.21-0.27l0.39-0.62l334.75-13.99c12.96-0.39,23.7-6.89,23.7-20.04V199.99z M122.03,224.15
                  C109.06,223.79,98.5,213.42,98.5,201c0-12.42,10.56-22.09,23.53-21.6c12.89,0.49,23.29,10.85,23.29,23.14
                  S134.92,224.51,122.03,224.15z M232.18,227.23c-12.35-0.35-22.41-10.45-22.41-22.57c0-12.12,10.06-21.57,22.41-21.1
                  c12.28,0.46,22.18,10.57,22.18,22.56S244.45,227.57,232.18,227.23z M337.14,230.17c-11.77-0.33-21.36-10.19-21.36-22.02
                  c0-11.83,9.59-21.06,21.36-20.62c11.71,0.44,21.15,10.3,21.15,22.02S348.85,230.49,337.14,230.17z"/>
                <path className="st18" d="M427.89,240.54v-47.59c0-13.13-10.19-23.91-23.1-24.44L62.78,154.44c-6.38-0.26-12.34,1.44-17.03,4.72
                  c-6.41,4.49-10.6,11.57-10.6,19.84v64.57L427.89,240.54z M344.63,180.81c11.66,0.44,21.09,10.28,21.09,21.98
                  c0,11.7-9.42,20.91-21.09,20.59c-11.73-0.33-21.29-10.17-21.29-21.98C323.34,189.57,332.9,180.36,344.63,180.81z M240.03,176.86
                  c12.23,0.46,22.11,10.54,22.11,22.53c0,11.98-9.88,21.41-22.11,21.06c-12.3-0.34-22.33-10.43-22.33-22.52
                  C217.7,185.82,227.73,176.39,240.03,176.86z M130.27,172.71c12.84,0.48,23.21,10.82,23.21,23.1c0,12.27-10.37,21.92-23.21,21.56
                  c-12.91-0.36-23.45-10.7-23.45-23.1C106.82,181.87,117.36,172.22,130.27,172.71z"/>
                <path className="st19" d="M35.15,228.91v230.63c0,14.34,11.92,25.62,27.07,25.16l342.38-10.32c12.92-0.39,23.29-11.33,23.29-24.46
                  V238.66L35.15,228.91z"/>
                <g>
                  <path className="st20" d="M291.9,272.54c0-3.15-2.27-5.73-5.44-5.79l-33.73-0.58c-3.23-0.06-7.25,2.47-7.25,5.65v32.1
                    c0,3.18,4.18,5.78,7.41,5.81l32.8,0.25c3.18,0.03,6.22-2.51,6.22-5.66V272.54z"/>
                  <path className="st20" d="M350.46,273.5c0-3.11-2.59-5.66-5.69-5.72l-31.32-0.54c-3.14-0.06-5.54,2.46-5.54,5.6v31.67
                    c0,3.14,2.35,5.69,5.49,5.71l31.46,0.24c3.09,0.02,5.59-2.49,5.59-5.59V273.5z"/>
                  <path className="st20" d="M408.6,274.47c0-3.07-2.67-5.59-5.68-5.65l-30.83-0.56c-3.06-0.05-5.73,2.4-5.73,5.49V305
                    c0,3.09,2.56,5.64,5.62,5.66l30.91,0.25c3.01,0.02,5.72-2.43,5.72-5.5V274.47z"/>
                  <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="241.3603" y1="338.3579" x2="477.1996" y2="407.8511">
                    <stop offset="0" style={{stopColor: '#FF9085'}} />
                    <stop offset="1" style={{stopColor: '#FB6FBB'}} />
                  </linearGradient>
                  <path className="st21" d="M291.9,330.68c0-3.15-2.27-5.7-5.44-5.72l-33.73-0.15c-3.23-0.01-7.25,2.55-7.25,5.73v32.1
                    c0,3.18,4.18,5.75,7.41,5.73l32.8-0.18c3.18-0.02,6.22-2.59,6.22-5.74V330.68z"/>
                  <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="246.1201" y1="322.2051" x2="481.9594" y2="391.6982">
                    <stop offset="0" style={{stopColor: '#FF9085'}}/>
                    <stop offset="1" style={{stopColor: '#FB6FBB'}}/>
                  </linearGradient>
                  <path className="st22" d="M350.46,330.87c0-3.11-2.59-5.63-5.69-5.65l-31.32-0.13c-3.14-0.01-5.54,2.53-5.54,5.67v31.67
                    c0,3.14,2.35,5.66,5.49,5.64l31.46-0.17c3.09-0.02,5.59-2.55,5.59-5.66V330.87z"/>
                  <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="250.7916" y1="306.3508" x2="486.631" y2="375.8439">
                    <stop offset="0" style={{stopColor: '#FF9085'}}/>
                    <stop offset="1" style={{stopColor: '#FB6FBB'}}/>
                  </linearGradient>
                  <path className="st23" d="M408.6,331.06c0-3.07-2.67-5.56-5.68-5.57l-30.83-0.16c-3.06-0.01-5.73,2.47-5.73,5.56v31.24
                    c0,3.09,2.56,5.61,5.62,5.59l30.91-0.15c3.01-0.02,5.72-2.5,5.72-5.57V331.06z"/>
                  <path className="st24" d="M291.9,330.68c0-3.15-2.27-5.7-5.44-5.72l-33.73-0.15c-3.23-0.01-7.25,2.55-7.25,5.73v32.1
                    c0,3.18,4.18,5.75,7.41,5.73l32.8-0.18c3.18-0.02,6.22-2.59,6.22-5.74V330.68z"/>
                  <path className="st24" d="M350.46,330.87c0-3.11-2.59-5.63-5.69-5.65l-31.32-0.13c-3.14-0.01-5.54,2.53-5.54,5.67v31.67
                    c0,3.14,2.35,5.66,5.49,5.64l31.46-0.17c3.09-0.02,5.59-2.55,5.59-5.66V330.87z"/>
                  <path className="st24" d="M408.6,331.06c0-3.07-2.67-5.56-5.68-5.57l-30.83-0.16c-3.06-0.01-5.73,2.47-5.73,5.56v31.24
                    c0,3.09,2.56,5.61,5.62,5.59l30.91-0.15c3.01-0.02,5.72-2.5,5.72-5.57V331.06z"/>
                  <path className="st20" d="M106.45,330.12c0-3.28-3.04-5.97-6.49-5.99l-34.88-0.18c-3.51-0.02-6.1,2.63-6.1,5.95v33.47
                    c0,3.32,2.47,6.01,5.98,5.99l35.19-0.16c3.45-0.02,6.3-2.67,6.3-5.95V330.12z"/>
                  <path className="st24" d="M169.93,330.28c0-3.24-2.45-5.86-5.8-5.88l-34.64-0.16c-3.41-0.02-6.67,2.62-6.67,5.89v33
                    c0,3.27,3.39,5.91,6.81,5.89l34.19-0.18c3.36-0.02,6.11-2.66,6.11-5.89V330.28z"/>
                  <path className="st24" d="M231.14,330.54c0-3.19-2.48-5.82-5.74-5.83l-34.12-0.17c-3.32-0.02-6.9,2.57-6.9,5.79v32.55
                    c0,3.22,3.67,5.83,6.99,5.81l33.55-0.15c3.27-0.02,6.23-2.59,6.23-5.78V330.54z"/>
                  <path className="st24" d="M291.9,388.81c0-3.15-2.27-5.67-5.44-5.65l-33.73,0.28c-3.23,0.03-7.25,2.63-7.25,5.81v32.1
                    c0,3.18,4.18,5.71,7.41,5.65l32.8-0.6c3.18-0.06,6.22-2.66,6.22-5.81V388.81z"/>
                  <path className="st24" d="M350.46,388.24c0-3.11-2.59-5.6-5.69-5.58l-31.32,0.28c-3.14,0.03-5.54,2.6-5.54,5.74v31.67
                    c0,3.14,2.35,5.63,5.49,5.57l31.46-0.58c3.09-0.06,5.59-2.62,5.59-5.73V388.24z"/>
                  <path className="st24" d="M106.45,390.7c0-3.28-3.04-5.93-6.49-5.9l-34.88,0.28c-3.51,0.03-6.1,2.72-6.1,6.03v33.47
                    c0,3.32,2.47,5.97,5.98,5.91l35.19-0.63c3.45-0.06,6.3-2.76,6.3-6.04V390.7z"/>
                  <path className="st24" d="M169.93,390.02c0-3.24-2.45-5.83-5.8-5.8l-34.64,0.3c-3.41,0.03-6.67,2.7-6.67,5.97v33
                    c0,3.27,3.39,5.87,6.81,5.81l34.19-0.63c3.36-0.06,6.11-2.74,6.11-5.97V390.02z"/>
                  <path className="st24" d="M231.14,389.48c0-3.19-2.48-5.78-5.74-5.76L191.28,384c-3.32,0.03-6.9,2.65-6.9,5.88v32.55
                    c0,3.22,3.67,5.79,6.99,5.73l33.55-0.59c3.27-0.06,6.23-2.67,6.23-5.86V389.48z"/>
                </g>
                <path className="st25" d="M128.1,112.72c-21.74-1.11-39.78,16.72-41.53,42.71l18.41,0.76c1.5-15.52,11.5-27.01,23.11-26.46
                  c12.54,0.59,23.08,15.23,23.08,32.05c0,16.82-10.54,30.64-23.08,30.22c-6.54-0.22-12.56-4.3-16.85-10.54
                  c-2.49,3.45-6.78,11.4-2.72,21.21c5.85,3.83,12.51,6.11,19.57,6.32c22.66,0.68,40.93-20.14,40.93-46.48
                  C169.03,136.16,150.76,113.87,128.1,112.72z"/>
                <path className="st25" d="M237.77,118.24c-20.72-1.05-37.9,16.36-39.56,41.74l17.54,0.72c1.43-15.15,10.95-26.37,22.02-25.85
                  c11.95,0.56,21.99,14.84,21.99,31.27c0,16.42-10.04,29.92-21.99,29.52c-6.23-0.21-11.97-4.19-16.05-10.28
                  c-2.37,3.37-6.46,11.13-2.59,20.7c5.57,3.74,11.92,5.95,18.64,6.15c21.59,0.64,39.01-19.7,39.01-45.41
                  C276.78,141.08,259.37,119.34,237.77,118.24z"/>
                <path className="st25" d="M342.66,123.64c-19.74-1-36.11,16.02-37.7,40.78l16.71,0.69c1.36-14.79,10.44-25.76,20.99-25.26
                  c11.39,0.54,20.96,14.47,20.96,30.5c0,16.03-9.57,29.23-20.96,28.84c-5.94-0.2-11.4-4.08-15.3-10.02
                  c-2.26,3.29-6.15,10.87-2.47,20.21c5.31,3.65,11.36,5.8,17.77,5.99c20.58,0.61,37.19-19.26,37.19-44.37
                  C379.84,145.9,363.24,124.68,342.66,123.64z"/>
                <path className="st26" d="M427.89,441.27v8.64c0,13.13-10.19,24.08-23.1,24.47L62.43,484.7c-15.15,0.46-27.28-10.82-27.28-25.15V179
                  c0-8.27,4.08-15.49,10.49-19.98c13.91,4.97,31.8,18.88,40.94,56.68c16.1,66.51,29.93,94.8,91.63,92.67
                  c39.2-1.36,69.14,0.11,87.74,17.28c9.97,9.18,16.75,22.92,19.96,43.22c1.88,11.87,10.29,19.32,22.28,24.68
                  c11.72,5.26,26.93,8.51,42.73,12.01C379.85,411.96,412.11,419.21,427.89,441.27z"/>
              </svg>
            </div>

            <div id="dollar-sign-1" className="dollar-signs">
              <svg 
                version="1.1" 
                xmlns="http://www.w3.org/2000/svg" 
                xmlnsXlink="http://www.w3.org/1999/xlink" 
                x="0px" y="0px"
                viewBox="0 0 1110.06 1065.87"
              >
                <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="509.5265" y1="272.5157" x2="589.9317" y2="179.1883" gradientTransform="matrix(0.9999 -0.0155 0.0155 0.9999 70.0881 78.7941)">
                  <stop offset="0" style={{stopColor: '#E1473D'}}/>
                  <stop offset="1" style={{stopColor: '#E9605A'}}/>
                </linearGradient>
                <circle className="st27" cx="664.33" cy="246.91" r="59.95"/>
                <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="635.3659" y1="111.1187" x2="397.6056" y2="470.3068" gradientTransform="matrix(0.9999 -0.0155 0.0155 0.9999 70.0881 78.7941)">
                  <stop offset="0" style={{stopColor: '#FF928E'}}/>
                  <stop offset="1" style={{stopColor: '#FE7062'}}/>
                </linearGradient>
                <circle className="st28" cx="664.33" cy="246.91" r="57.95"/>
                <path className="st29" d="M616.21,225.52c-0.29,0-0.59-0.06-0.87-0.19c-0.96-0.47-1.36-1.63-0.9-2.59
                  c9.2-18.95,27.99-30.93,49.04-31.25c3.36-0.05,6.73,0.2,10.01,0.74c1.06,0.17,1.77,1.17,1.59,2.23
                  c-0.17,1.06-1.17,1.78-2.23,1.59c-3.05-0.51-6.19-0.74-9.32-0.69c-19.58,0.3-37.05,11.44-45.61,29.07
                  C617.59,225.11,616.91,225.51,616.21,225.52z"/>
                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 19.4831 543.3434)" className="st29" cx="665.62" cy="248.15" rx="49.05" ry="49.05"/>
                <g>
                  <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="-198.4398" y1="-173.0394" x2="-319.0106" y2="29.3789" gradientTransform="matrix(0.9691 0.2468 -0.2468 0.9691 861.6262 420.9442)">
                    <stop offset="0" style={{stopColor: '#FF928E'}}/>
                    <stop offset="1" style={{stopColor: '#FE7062'}}/>
                  </linearGradient>
                  <path className="st30" d="M661.37,284.69l-0.08-5.34c-5.57-0.14-11.09-1.36-15.02-3.01c-1.4-0.59-2.14-2.14-1.76-3.62l1.28-4.99
                    c0.47-1.83,2.44-2.8,4.19-2.1c3.9,1.56,8.71,2.78,13.87,2.7c5.36-0.08,9.32-1.96,9.27-5.68c-0.05-3.36-3.17-5.31-10.65-7.19
                    c-10.67-2.74-18.37-7.25-18.51-16.61c-0.13-8.45,5.94-14.99,16.63-16.88l-0.08-5.34c-0.03-1.72,1.34-3.13,3.06-3.16l3.14-0.05
                    c1.72-0.03,3.13,1.34,3.16,3.06l0.07,4.79c5.15,0.13,8.91,0.95,11.9,1.95c1.53,0.51,2.39,2.14,1.99,3.7l-1.18,4.62
                    c-0.45,1.78-2.33,2.78-4.06,2.16c-2.75-0.99-6.64-2.01-11.67-1.93c-6.36,0.1-8.13,2.76-8.09,5.12
                    c0.05,2.91,3.89,4.84,12.19,7.26c11.86,3.36,16.84,8.18,16.98,16.99c0.13,8.08-5.75,15.44-17.35,17.44l0.09,5.97
                    c0.03,1.72-1.34,3.13-3.06,3.16l-3.14,0.05C662.81,287.78,661.4,286.41,661.37,284.69z"/>
                </g>
              </svg>
            </div>

            <div id="dollar-sign-2" className="dollar-signs">
              <svg 
                version="1.1" 
                xmlns="http://www.w3.org/2000/svg" 
                xmlnsXlink="http://www.w3.org/1999/xlink" 
                x="0px" y="0px"
                viewBox="0 0 1110.06 1065.87"
              >
                <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="615.4379" y1="199.601" x2="746.659" y2="47.291" gradientTransform="matrix(0.9999 -0.0155 0.0155 0.9999 70.0881 78.7941)">
                  <stop offset="0" style={{stopColor: '#E1473D'}}/>
                  <stop offset="1" style={{stopColor: '#E9605A'}}/>
                </linearGradient>
                <circle className="st31" cx="820.01" cy="111.39" r="97.83"/>
                <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="820.8075" y1="-63.7984" x2="432.783" y2="522.396" gradientTransform="matrix(0.9999 -0.0155 0.0155 0.9999 70.0881 78.7941)">
                  <stop offset="0" style={{stopColor: '#FF928E'}}/>
                  <stop offset="1" style={{stopColor: '#FE7062'}}/>
                </linearGradient>
                <circle className="st32" cx="820.01" cy="111.39" r="94.58"/>
                <path className="st29" d="M741.44,75.14c-0.28,0-0.56-0.05-0.82-0.18c-0.9-0.44-1.28-1.53-0.84-2.43
                  c14.79-30.47,45-49.72,78.84-50.25c5.4-0.08,10.82,0.32,16.1,1.2c0.99,0.16,1.66,1.1,1.49,2.09c-0.16,0.99-1.1,1.67-2.09,1.49
                  c-5.07-0.84-10.26-1.23-15.44-1.15c-32.46,0.5-61.44,18.98-75.63,48.2C742.74,74.75,742.11,75.13,741.44,75.14z"/>
                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 160.5888 614.5324)" className="st29" cx="822.1" cy="113.42" rx="80.05" ry="80.05"/>
                <g>
                  <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="-58.471" y1="-376.3303" x2="-255.2424" y2="-45.9836" gradientTransform="matrix(0.9691 0.2468 -0.2468 0.9691 861.6262 420.9442)">
                    <stop offset="0" style={{stopColor: '#FF928E'}}/>
                    <stop offset="1" style={{stopColor: '#FE7062'}}/>
                  </linearGradient>
                  <path className="st33" d="M815.17,173.04l-0.14-8.71c-9.08-0.22-18.1-2.23-24.52-4.92c-2.29-0.96-3.49-3.5-2.88-5.91l2.09-8.15
                    c0.77-2.99,3.98-4.58,6.84-3.43c6.37,2.55,14.21,4.53,22.63,4.4c8.74-0.14,15.22-3.2,15.12-9.28
                    c-0.09-5.48-5.17-8.66-17.38-11.74c-17.41-4.47-29.98-11.84-30.22-27.1c-0.21-13.78,9.7-24.46,27.14-27.55l-0.14-8.71
                    c-0.04-2.8,2.19-5.11,4.99-5.15l5.12-0.08c2.8-0.04,5.11,2.19,5.15,4.99l0.12,7.82c8.4,0.21,14.54,1.55,19.42,3.18
                    c2.5,0.83,3.9,3.49,3.25,6.05l-1.92,7.53c-0.74,2.9-3.81,4.54-6.63,3.52c-4.49-1.62-10.84-3.28-19.05-3.15
                    c-10.37,0.16-13.27,4.5-13.21,8.36c0.07,4.74,6.35,7.91,19.9,11.85c19.36,5.48,27.48,13.36,27.71,27.73
                    c0.21,13.19-9.39,25.2-28.31,28.46l0.15,9.75c0.04,2.8-2.19,5.11-4.99,5.15l-5.12,0.08
                    C817.52,178.08,815.22,175.85,815.17,173.04z"/>
                </g>
              </svg>
            </div>

            <div id="dollar-sign-3" className="dollar-signs">
              <svg 
                version="1.1" 
                xmlns="http://www.w3.org/2000/svg" 
                xmlnsXlink="http://www.w3.org/1999/xlink" 
                x="0px" y="0px"
                viewBox="0 0 1110.06 1065.87"
              >
                <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="679.0381" y1="422.6111" x2="814.063" y2="265.8859" gradientTransform="matrix(0.9999 -0.0155 0.0155 0.9999 70.0881 78.7941)">
                  <stop offset="0" style={{stopColor: '#E1473D'}}/>
                  <stop offset="1" style={{stopColor: '#E9605A'}}/>
                </linearGradient>
                <circle className="st34" cx="890.87" cy="328.83" r="100.67"/>
                <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="890.3609" y1="151.5761" x2="491.0886" y2="754.7628" gradientTransform="matrix(0.9999 -0.0155 0.0155 0.9999 70.0881 78.7941)">
                  <stop offset="0" style={{stopColor: '#FF928E'}}/>
                  <stop offset="1" style={{stopColor: '#FE7062'}}/>
                </linearGradient>
                <circle className="st35" cx="890.87" cy="328.83" r="97.32"/>
                <path className="st29" d="M810.03,291.47c-0.27,0-0.56-0.05-0.82-0.18c-0.9-0.44-1.28-1.53-0.84-2.43
                  c15.21-31.33,46.28-51.14,81.08-51.68c5.55-0.09,11.12,0.33,16.55,1.23c0.99,0.16,1.66,1.1,1.49,2.09
                  c-0.16,0.99-1.09,1.66-2.09,1.49c-5.22-0.87-10.57-1.26-15.9-1.18c-33.42,0.52-63.26,19.54-77.87,49.63
                  C811.33,291.09,810.69,291.46,810.03,291.47z"/>          
                <ellipse transform="matrix(0.222 -0.975 0.975 0.222 372.1097 1128.1877)" className="st29" cx="893.02" cy="330.92" rx="82.37" ry="82.37"/>
                <g>
                  <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="65.5445" y1="-185.6233" x2="-136.9304" y2="154.2986" gradientTransform="matrix(0.9691 0.2468 -0.2468 0.9691 861.6262 420.9442)">
                    <stop offset="0" style={{stopColor: '#FF928E'}}/>
                    <stop offset="1" style={{stopColor: '#FE7062'}}/>
                  </linearGradient>
                  <path className="st36" d="M885.89,392.27l-0.14-8.96c-9.35-0.23-18.62-2.29-25.23-5.06c-2.36-0.99-3.59-3.6-2.96-6.08l2.15-8.38
                    c0.79-3.07,4.09-4.71,7.04-3.53c6.55,2.63,14.62,4.66,23.29,4.53c9-0.14,15.66-3.29,15.56-9.55
                    c-0.09-5.64-5.32-8.92-17.88-12.08c-17.92-4.6-30.85-12.18-31.09-27.89c-0.22-14.18,9.98-25.17,27.93-28.35l-0.14-8.96
                    c-0.04-2.88,2.26-5.26,5.14-5.3l5.26-0.08c2.88-0.04,5.26,2.26,5.3,5.14l0.13,8.05c8.65,0.21,14.96,1.59,19.98,3.27
                    c2.57,0.86,4.01,3.59,3.34,6.22l-1.97,7.75c-0.76,2.99-3.92,4.67-6.82,3.62c-4.62-1.67-11.15-3.38-19.6-3.24
                    c-10.67,0.17-13.66,4.64-13.59,8.6c0.08,4.88,6.53,8.13,20.48,12.19c19.92,5.64,28.28,13.74,28.51,28.54
                    c0.21,13.57-9.66,25.93-29.13,29.28l0.16,10.03c0.04,2.88-2.26,5.26-5.14,5.3l-5.27,0.08
                    C888.31,397.45,885.94,395.15,885.89,392.27z"/>
                </g>
              </svg>
            </div>

            <div id="dollar-sign-4" className="dollar-signs">
              <svg 
                version="1.1" 
                xmlns="http://www.w3.org/2000/svg" 
                xmlnsXlink="http://www.w3.org/1999/xlink" 
                x="0px" y="0px"
                viewBox="0 0 1110.06 1065.87"
              >
                <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="832.3237" y1="222.9475" x2="940.3118" y2="97.6043" gradientTransform="matrix(0.9999 -0.0155 0.0155 0.9999 70.0881 78.7941)">
                  <stop offset="0" style={{stopColor: '#E1473D'}}/>
                  <stop offset="1" style={{stopColor: '#E9605A'}}/>
                </linearGradient>
                <circle className="st37" cx="1013.96" cy="159.25" r="80.51"/>
                <linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="1001.332" y1="6.1835" x2="682.0084" y2="488.5905" gradientTransform="matrix(0.9999 -0.0155 0.0155 0.9999 70.0881 78.7941)">
                  <stop offset="0" style={{stopColor: '#FF928E'}}/>
                  <stop offset="1" style={{stopColor: '#FE7062'}}/>
                </linearGradient>
                <circle className="st38" cx="1013.96" cy="159.25" r="77.83"/>
                <path className="st29" d="M949.31,129.9c-0.3,0-0.61-0.06-0.9-0.2c-0.99-0.48-1.4-1.67-0.92-2.65
                  c12.25-25.24,37.28-41.19,65.31-41.63c4.47-0.07,8.95,0.26,13.34,0.99c1.08,0.18,1.81,1.2,1.63,2.28
                  c-0.18,1.08-1.19,1.83-2.28,1.63c-4.15-0.69-8.39-1-12.62-0.94c-26.53,0.41-50.21,15.51-61.81,39.39
                  C950.73,129.48,950.04,129.89,949.31,129.9z"/>
                <ellipse transform="matrix(0.9492 -0.3148 0.3148 0.9492 0.9811 327.9109)" className="st29" cx="1015.68" cy="160.92" rx="65.88" ry="65.88"/>
                <g>
                  <linearGradient id="SVGID_19_" gradientUnits="userSpaceOnUse" x1="130.9731" y1="-362.4794" x2="-30.959" y2="-90.6221" gradientTransform="matrix(0.9691 0.2468 -0.2468 0.9691 861.6262 420.9442)">
                    <stop offset="0" style={{stopColor: '#FF928E'}}/>
                    <stop offset="1" style={{stopColor: '#FE7062'}}/>
                  </linearGradient>
                  <path className="st39" d="M1009.98,209.99l-0.11-7.17c-7.48-0.18-14.89-1.83-20.18-4.05c-1.89-0.79-2.87-2.88-2.37-4.86l1.72-6.7
                    c0.63-2.46,3.27-3.77,5.63-2.82c5.24,2.1,11.7,3.73,18.63,3.62c7.2-0.11,12.52-2.64,12.45-7.64c-0.07-4.51-4.26-7.13-14.3-9.66
                    c-14.33-3.68-24.67-9.74-24.87-22.3c-0.18-11.34,7.98-20.13,22.34-22.67l-0.11-7.17c-0.04-2.31,1.8-4.2,4.11-4.24l4.21-0.07
                    c2.31-0.04,4.2,1.8,4.24,4.11l0.1,6.43c6.92,0.17,11.97,1.27,15.98,2.61c2.06,0.69,3.21,2.87,2.67,4.98l-1.58,6.2
                    c-0.61,2.39-3.13,3.73-5.45,2.9c-3.7-1.33-8.92-2.7-15.68-2.59c-8.54,0.13-10.92,3.71-10.87,6.88c0.06,3.9,5.22,6.51,16.38,9.75
                    c15.93,4.51,22.62,10.99,22.8,22.82c0.17,10.85-7.73,20.74-23.3,23.42l0.12,8.02c0.04,2.31-1.8,4.2-4.11,4.24l-4.21,0.07
                    C1011.91,214.13,1010.02,212.29,1009.98,209.99z"/>
                </g>
              </svg>
            </div>

            <div id="clock">
              <svg 
                version="1.1" 
                xmlns="http://www.w3.org/2000/svg" 
                xmlnsXlink="http://www.w3.org/1999/xlink" 
                x="0px" y="0px"
                viewBox="0 0 1110.06 1065.87"
              >
                <path className="st1" d="M245,519.15c-3.7,3.7-9.71,3.7-13.41,0l0,0c-3.7-3.7-3.7-9.71,0-13.41l16.01-16.01c3.7-3.7,9.71-3.7,13.41,0
                  l0,0c3.7,3.7,3.7,9.71,0,13.41L245,519.15z"/>
                <path className="st1" d="M358.34,519.15c3.7,3.7,9.71,3.7,13.41,0l0,0c3.7-3.7,3.7-9.71,0-13.41l-16.01-16.01
                  c-3.7-3.7-9.71-3.7-13.41,0l0,0c-3.7,3.7-3.7,9.71,0,13.41L358.34,519.15z"/>
                <path className="st46" d="M255.99,339.04c-2.83-4.36-4.26-9.03-4.26-13.87c0-18.47,21.67-33.5,48.3-33.5
                  c26.63,0,48.3,15.03,48.3,33.5c0,4.49-1.24,8.84-3.67,12.93l-7.1-4.23c1.66-2.79,2.51-5.72,2.51-8.7
                  c0-13.68-18.33-25.24-40.04-25.24c-21.7,0-40.04,11.56-40.04,25.24c0,3.23,0.99,6.38,2.93,9.38L255.99,339.04z"/>
                <rect className="st47" width="30.37" height="39.03" x="340.93" y="336.39" transform="matrix(0.54 -0.8417 0.8417 0.54 -135.7407 463.4395)"/>
                <path className="st48" d="M333.85,338.35c0.82-1.54,1.79-3.03,2.94-4.47c10.64-13.3,31.45-16.45,46.48-7.04
                  c15.03,9.41,18.59,27.82,7.96,41.12c-1.15,1.44-2.42,2.74-3.79,3.94L333.85,338.35z"/>
                <rect x="224.74" y="341.94" transform="matrix(0.8417 -0.54 0.54 0.8417 -154.1766 188.4453)" className="st47" width="39.03" height="30.37"/>
                <ellipse transform="matrix(0.241 -0.9705 0.9705 0.241 -191.3405 620.3724)" className="st48" cx="300.94" cy="432.51" rx="89.76" ry="89.76"/>
                <ellipse transform="matrix(0.2127 -0.9771 0.9771 0.2127 -185.6748 634.5956)" className="st49" cx="300.94" cy="432.51" rx="74.61" ry="74.61"/>
                <path className="st50" d="M303.7,367.58c0,1.52-1.23,2.75-2.75,2.75c-1.52,0-2.75-1.23-2.75-2.75c0-1.52,1.23-2.75,2.75-2.75
                  C302.46,364.83,303.7,366.06,303.7,367.58z"/>
                <path className="st50" d="M270.86,374.9c0.76,1.32,0.31,3-1.01,3.76c-1.32,0.76-3,0.31-3.76-1.01c-0.76-1.32-0.31-3,1.01-3.76
                  C268.42,373.13,270.1,373.59,270.86,374.9z"/>
                <path className="st50" d="M246.09,397.66c1.32,0.76,1.77,2.44,1.01,3.76c-0.76,1.32-2.44,1.77-3.76,1.01
                  c-1.32-0.76-1.77-2.44-1.01-3.76C243.09,397.35,244.77,396.9,246.09,397.66z"/>
                <path className="st50" d="M236.01,429.76c1.52,0,2.75,1.23,2.75,2.75c0,1.52-1.23,2.75-2.75,2.75c-1.52,0-2.75-1.23-2.75-2.75
                  C233.26,430.99,234.49,429.76,236.01,429.76z"/>
                <path className="st50" d="M243.33,462.6c1.32-0.76,3-0.31,3.76,1.01c0.76,1.32,0.31,3-1.01,3.76c-1.32,0.76-3,0.31-3.76-1.01
                  C241.57,465.04,242.02,463.36,243.33,462.6z"/>
                <path className="st50" d="M266.09,487.37c0.76-1.32,2.44-1.77,3.76-1.01c1.32,0.76,1.77,2.44,1.01,3.76
                  c-0.76,1.32-2.44,1.77-3.76,1.01C265.78,490.37,265.33,488.69,266.09,487.37z"/>
                <path className="st50" d="M298.19,497.45c0-1.52,1.23-2.75,2.75-2.75c1.52,0,2.75,1.23,2.75,2.75c0,1.52-1.23,2.75-2.75,2.75
                  C299.42,500.2,298.19,498.97,298.19,497.45z"/>
                <path className="st50" d="M331.03,490.12c-0.76-1.32-0.31-3,1.01-3.76c1.32-0.76,3-0.31,3.76,1.01c0.76,1.32,0.31,3-1.01,3.76
                  C333.47,491.89,331.79,491.44,331.03,490.12z"/>
                <path className="st50" d="M355.8,467.36c-1.32-0.76-1.77-2.44-1.01-3.76c0.76-1.32,2.44-1.77,3.76-1.01c1.32,0.76,1.77,2.44,1.01,3.76
                  C358.8,467.67,357.12,468.12,355.8,467.36z"/>
                <path className="st50" d="M365.88,435.27c-1.52,0-2.75-1.23-2.75-2.75c0-1.52,1.23-2.75,2.75-2.75c1.52,0,2.75,1.23,2.75,2.75
                  C368.63,434.03,367.4,435.27,365.88,435.27z"/>
                <path className="st50" d="M358.56,402.43c-1.32,0.76-3,0.31-3.76-1.01c-0.76-1.32-0.31-3,1.01-3.76c1.32-0.76,3-0.31,3.76,1.01
                  S359.87,401.67,358.56,402.43z"/>
                <path className="st50" d="M335.8,377.65c-0.76,1.32-2.44,1.77-3.76,1.01c-1.32-0.76-1.77-2.44-1.01-3.76
                  c0.76-1.32,2.44-1.77,3.76-1.01C336.1,374.66,336.56,376.34,335.8,377.65z"/>
                <rect x="281.96" y="404.9" transform="matrix(0.4687 -0.8834 0.8834 0.4687 -221.5149 475.8854)" className="st50" width="5.74" height="34.36"/>
                <rect x="298.68" y="416.6" transform="matrix(0.8757 -0.4828 0.4828 0.8757 -161.8364 208.7121)" className="st50" width="51.65" height="4.25"/>
                <path className="st18" d="M314.02,432.51c0,7.22-5.86,13.08-13.08,13.08c-7.22,0-13.08-5.86-13.08-13.08
                  c0-7.22,5.86-13.08,13.08-13.08C308.17,419.44,314.02,425.29,314.02,432.51z"/>
                <path className="st48" d="M266.52,339.58c-0.73-1.54-1.59-3.03-2.6-4.47c-9.41-13.3-27.82-16.45-41.12-7.04
                  c-13.3,9.41-16.45,27.82-7.04,41.12c1.02,1.43,2.14,2.74,3.35,3.94L266.52,339.58z"/>
              </svg>
            </div>

            <div id="health-shield">
              <svg 
                version="1.1" 
                xmlns="http://www.w3.org/2000/svg" 
                xmlnsXlink="http://www.w3.org/1999/xlink" 
                x="0px" y="0px"
                viewBox="0 0 1110.06 1065.87"
              >
                <path className="st51" d="M613.55,669.22c-7.9,0.23-15.6-1.76-22.27-5.76c-20.33-12.18-50.36-32.31-77.38-58.65
                  c-34.29-33.42-53.99-67.4-58.3-100.76l-0.07-0.52l-0.84-142.36c0-28.13,18.35-50.97,52.8-65.98
                  c28.46-12.4,66.19-18.54,106.05-17.39c39.39,1.14,75.84,9.27,102.82,22.81c32.07,16.1,48.9,38.72,48.9,65.55l-0.76,136.02
                  l-0.06,0.5c-3.94,31.95-22.06,64.97-54.1,98.36c-25.7,26.78-54.73,48.01-74.67,61.16C629.08,666.57,621.43,668.99,613.55,669.22z"
                  />
                <path className="st52" d="M771.08,366.16l-0.75,136.03l-0.07,0.49c-3.89,31.95-21.78,64.98-53.39,98.37
                  c-24.05,25.41-51.06,45.82-70.58,59.07c-1.06,0.71-2.09,1.4-3.1,2.09c-6.51,4.36-14.06,6.78-21.83,7
                  c-7.79,0.23-15.4-1.76-21.98-5.76c-20.06-12.18-49.7-32.3-76.35-58.64c-33.83-33.42-53.28-67.4-57.53-100.76l-0.07-0.52
                  l-0.82-142.35c0-28.14,18.1-50.97,52.09-65.98c22.19-9.79,50.06-15.68,80.16-17.15c8.02-0.4,16.21-0.48,24.49-0.24
                  c38.87,1.15,74.84,9.28,101.46,22.82C754.48,316.72,771.08,339.35,771.08,366.16z"/>
                <path className="st53" d="M474.01,361.28l0.83,141.64c9.32,72.27,94.25,130.78,131.24,153.01c10.6,6.37,24.14,5.99,34.67-0.93
                  c36.22-23.83,116.5-83.83,125.07-153.31l0.76-135.65C766.59,265.39,474.01,256.19,474.01,361.28z"/>
                <path className="st54" d="M623.46,634.09c-0.68,0.02-1.7-0.1-2.74-0.73c-15.23-9.22-44.75-28.54-70.44-53.77
                  c-27.74-27.25-43.74-53.75-47.41-78.63l-0.81-139.29c0.03-13.36,10.61-24.8,31.35-33.96c23.15-10.22,56.03-15.58,90.05-14.77
                  c33.69,0.8,65.6,7.58,87.72,18.55c19.56,9.71,29.45,21.15,29.48,34.05l-0.76,134.47c-3.42,24.08-18.44,50.04-44.81,77.33
                  c-24.79,25.66-53.75,46-68.89,55.89C625.17,633.91,624.14,634.07,623.46,634.09z"/>
                <path className="st18" d="M619.99,624.57c-15.38-9.47-42.73-27.77-66.59-51.25c-26.07-25.66-41.16-50.28-44.71-73.06l-0.81-138.48
                  c0.02-9.56,8.81-18.32,26.04-25.95c21.93-9.71,53.35-14.83,86.07-14.09c32.4,0.73,62.94,7.13,83.93,17.51
                  c16.31,8.07,24.56,16.87,24.58,26.19l-0.76,133.97c-3.33,22.1-17.56,46.28-42.45,71.99C662.19,595.26,635.3,614.45,619.99,624.57z"
                  />
                <g>
                  <rect x="591.35" y="389.67" className="st14" width="48.79" height="131"/>
                  <rect x="551.92" y="429.24" className="st14" width="125.65" height="46.79"/>
                </g>
                <path className="st55" d="M646.3,660.13c-1.06,0.71-2.09,1.4-3.1,2.09c-6.51,4.36-14.06,6.78-21.83,7c-7.79,0.23-15.4-1.76-21.98-5.76
                  c-20.06-12.18-49.7-32.3-76.35-58.64c-33.83-33.42-53.28-67.4-57.53-100.76l-0.07-0.52l-0.82-142.35
                  c0-28.14,18.1-50.97,52.09-65.98c22.19-9.79,50.06-15.68,80.16-17.15c1.75,2.62,3.64,5.48,5.57,8.54
                  c4.85,7.67,10.12,16.59,15.12,26.29c1.5,2.9,2.98,5.87,4.41,8.9c13.45,28.35,23.3,61.64,14.92,89.19
                  c-15.2,49.94-47.44,140.79-15.1,212.39c1.46,3.25,3.06,6.46,4.8,9.61c4.1,7.49,9.01,14.7,14.78,21.6
                  C642.95,656.45,644.59,658.29,646.3,660.13z"/>
              </svg>
            </div>

            <div id="gift-card">
              <svg 
                version="1.1" 
                xmlns="http://www.w3.org/2000/svg" 
                xmlnsXlink="http://www.w3.org/1999/xlink" 
                x="0px" y="0px"
                viewBox="0 0 1110.06 1065.87"
              >
                <path className="st56" d="M1044.87,769.67H801.82c-9.65,0-17.47,7.82-17.47,17.47v121.05c0,9.65,7.82,17.47,17.47,17.47h243.05
                  c9.65,0,17.47-7.82,17.47-17.47V787.14C1062.35,777.49,1054.52,769.67,1044.87,769.67z"/>
                <path className="st57" d="M1046.87,766.67H807.82c-9.65,0-17.47,7.82-17.47,17.47v117.05c0,9.65,7.82,17.47,17.47,17.47h239.05
                  c9.65,0,17.47-7.82,17.47-17.47V784.14C1064.35,774.49,1056.52,766.67,1046.87,766.67z"/>
                <rect x="864.29" y="766.47" className="st58" width="29.45" height="154.17"/>
                <g>
                  <polygon className="st59" points="896.93,821.83 936.01,856.19 917.44,858.24 913.24,875.36 887.58,838.93 		"/>
                  <path className="st58" d="M887.58,838.93l3.86,5.47c4.51-1.86,6-7.59,5.74-13.08l1.19-8c0-0.09-0.02-0.17-0.02-0.26l-1.41-1.24
                    L887.58,838.93z"/>
                  <path className="st59" d="M887.56,812.6c0,0,40.48-36.36,49.81-32.06c9.33,4.3,0.68,11.33,7.17,42.29
                    c2.95,14.09-11.6,25.54-50.11,12.18C883.28,831.15,887.56,812.6,887.56,812.6z"/>
                  <path className="st60" d="M937.37,780.54c-9.33-4.3-49.81,32.06-49.81,32.06s-0.22,0.98-0.44,2.53c7.28-5.19,31.9-22.61,38.93-25.59
                    c7.33-3.11,11.67-5.62,15.9,4.23C942.19,786.14,942.52,782.92,937.37,780.54z"/>
                  <path className="st58" d="M944.89,825.95c-0.56-2.32-1.81-4.88-4.46-6.71c-6.18-4.26-15.42-3.26-26.9,0.11
                    c-7.84,2.3-18.69,7.57-24.79,10.68c1.17,2.21,2.97,4.04,5.69,4.98C930.05,847.37,945.15,838.5,944.89,825.95z"/>
                  <polygon className="st59" points="861.22,823.49 825.5,861.33 844.18,861.65 849.95,878.3 872.12,839.65 		"/>
                  <path className="st58" d="M872.12,839.65l-3.33,5.81c-4.66-1.43-6.68-7-6.93-12.49l-1.92-7.86c0-0.09,0-0.17,0-0.26l1.29-1.36
                    L872.12,839.65z"/>
                  <path className="st59" d="M869.7,813.43c0,0-43.68-32.45-52.57-27.3c-8.89,5.15,0.37,11.35-3.21,42.77
                    c-1.63,14.3,13.92,24.35,51.02,7.48C875.68,831.5,869.7,813.43,869.7,813.43z"/>
                  <path className="st60" d="M817.13,786.13c8.89-5.15,52.57,27.3,52.57,27.3s0.31,0.96,0.67,2.48c-7.73-4.5-33.86-19.55-41.14-21.86
                    c-7.59-2.41-12.14-4.51-15.44,5.69C812.85,792.16,812.22,788.98,817.13,786.13z"/>
                  <path className="st58" d="M813.86,832.04c0.34-2.37,1.35-5.03,3.82-7.1c5.76-4.81,15.05-4.68,26.8-2.38
                    c8.02,1.57,19.31,5.8,25.67,8.33c-0.96,2.31-2.58,4.3-5.2,5.49C830.62,851.99,814.76,844.56,813.86,832.04z"/>
                  <path className="st60" d="M884.55,804.4l-11.85,0.55c-6.38,0.3-11.29,6.1-10.97,12.97l0.59,12.76c0.32,6.86,5.75,12.19,12.12,11.89
                    l11.85-0.55c6.38-0.3,11.29-6.1,10.97-12.97l-0.59-12.76C896.35,809.43,890.92,804.11,884.55,804.4z"/>
                  <path className="st59" d="M884.73,808.23l-11.85,0.55c-6.38,0.3-11.29,6.1-10.97,12.97l0.42,8.93c0.32,6.86,5.75,12.19,12.12,11.89
                    l11.85-0.55c6.38-0.3,11.29-6.1,10.97-12.97l-0.42-8.93C896.53,813.26,891.1,807.94,884.73,808.23z"/>
                </g>
                <path className="st61" d="M998.63,878.79l-0.09-5.73c-5.36-0.16-10.67-1.48-14.46-3.26c-1.35-0.64-2.06-2.31-1.7-3.89l1.22-5.36
                  c0.45-1.97,2.34-3.01,4.03-2.25c3.76,1.69,8.38,3,13.35,2.92c5.15-0.08,8.97-2.09,8.91-6.09c-0.06-3.61-3.06-5.71-10.26-7.74
                  c-10.27-2.96-17.69-7.82-17.84-17.86c-0.14-9.07,5.69-16.09,15.97-18.1l-0.09-5.73c-0.03-1.84,1.29-3.36,2.94-3.39l3.02-0.05
                  c1.65-0.03,3.01,1.45,3.04,3.29l0.08,5.15c4.96,0.14,8.58,1.03,11.45,2.11c1.47,0.55,2.3,2.3,1.92,3.98l-1.12,4.95
                  c-0.43,1.91-2.24,2.98-3.9,2.31c-2.65-1.07-6.39-2.17-11.23-2.1c-6.12,0.1-7.82,2.95-7.78,5.49c0.05,3.12,3.75,5.21,11.74,7.82
                  c11.42,3.63,16.22,8.82,16.36,18.28c0.13,8.68-5.51,16.57-16.66,18.69l0.1,6.41c0.03,1.84-1.29,3.36-2.94,3.39l-3.02,0.05
                  C1000.02,882.11,998.66,880.64,998.63,878.79z"/>
              </svg>
            </div>
          </div>
        </div>

        <div 
          ref={el => animateRefs.current[3] = el}
          className={`section caregivers-copy ${animationClassNames[3] || ''}`}
        >
          <div className="section-heading">
            {ReactHtmlParser(caringForCaregivers.heading)}
          </div>
          <div className="section-copy">
            {ReactHtmlParser(caringForCaregivers.copy)}<br /><br />
          </div>
          <div className="bullet-points">
            <div>{ReactHtmlParser(caringForCaregivers.bulletPoints.heading)}</div>
            <ul>
              {caringForCaregivers.bulletPoints.items.map((item, index) => {
                return (
                  <li key={'caregivers-'+index}>
                    <span>{ReactHtmlParser(item)}</span>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>

      </div>

      <div 
        id="reviews-container" 
        ref={el => animateRefs.current[4] = el}
        className={animationClassNames[4] || ''}
      >
        <div id="reviews">

          <div className="reviews-heading section-heading">
            <div>{reviews.heading}</div>
          </div>

          <div id="reviewers" className="bind-animation">


            <div 
              id="caregiver-1" 
              className={
                `reviewer-1
                ${reviewerEntering===1 ? ' speak' : ''}
                ${reviewerExiting===1 ? ' exit' : ''}
                ${[2, 3, 4].includes(reviewerEntering) ? ' sidestep-speak' : ''}
                ${[2, 3, 4].includes(reviewerExiting) ? ' sidestep-exit' : ''}
                `
              }
              onAnimationEnd={handleAnimationEnd}
            >
              <div className={animationClassNames[4] || ''}>
                <svg 
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" y="0px"
                  viewBox="0 0 300 680"
                  onClick={()=>selectReviewer(1)}
                >
                  <polygon className="st0" points="183.66,631.67 184.18,646.68 160.71,645.53 158.26,627.66 	"/>
                  <polygon className="st0" points="59.12,608.99 42.1,634.53 23.72,619.84 29.93,601.71 	"/>
                  <path className="st0" d="M290.57,217.29"/>
                  <path className="st0" d="M290.62,216.86c-1.96-3.81-14.01-0.54-18.41-0.22c-0.57,0.04-0.86-0.76-0.52-1.4
                    c5.29-9.85,5.29-10.94,4.39-11.32c-0.64-0.27-7.16,6.64-13.51,12.7l-7.6,7.6c-2.52,1.24-27.97,18.16-33.93,15.9
                    c-6.07-2.3-8.73-39.88-8.73-39.88l-26.71,6.91c0,0,10.78,56.3,26.79,63.05c8.63,3.64,48.19-31.79,48.19-31.79
                    c1.85,0.12,6.7,0.04,6.7,0.04c8.72-0.98,12.72-4.98,14.72-6.98c0.67-0.67,10-10,9-14"/>
                  <path className="st1" d="M100.94,48.42c-0.82,0.46-1.61,0.98-2.27,1.64c-2.25,2.23-2.92,5.76-5.34,7.79c-1.21,1.01-2.73,1.55-4.14,2.25
                    c-1.92,0.96-3.76,2.38-4.52,4.39c-0.43,1.14-0.47,2.38-0.51,3.6c-0.07,2.21-0.14,4.46,0.51,6.56c0.38,1.22,1,2.37,1.3,3.61
                    c0.61,2.47-0.04,5.08,0.34,7.6c0.29,1.9,1.15,3.65,2,5.37c0.45,0.9,0.9,1.81,1.59,2.54c1.76,1.87,4.62,2.15,7.17,1.9
                    c2.55-0.25,5.13-0.89,7.64-0.4c2.11,0.41,3.98,1.58,5.94,2.44c3.01,1.32,6.33,1.93,9.61,1.77c0.92-0.04,1.87-0.16,2.67-0.61
                    c0.83-0.47,1.43-1.27,2-2.04c3.14-4.25,6.3-8.55,8.44-13.38c2.14-4.83,3.22-10.31,1.99-15.46c-0.92-3.85-3.08-7.31-5.69-10.27
                    c-2.61-2.96-5.69-5.47-8.75-7.97C115.63,45.45,106.89,45.11,100.94,48.42z"/>
                  <path className="st1" d="M90.15,81.15c-0.82,0.46-1.61,0.98-2.27,1.64c-2.25,2.23-2.92,5.76-5.34,7.79c-1.21,1.01-2.73,1.55-4.14,2.25
                    c-1.92,0.96-3.76,2.38-4.52,4.39c-0.43,1.14-0.47,2.38-0.51,3.6c-0.07,2.21-0.14,4.46,0.51,6.56c0.38,1.22,1,2.37,1.31,3.61
                    c0.61,2.47-0.04,5.08,0.34,7.6c0.29,1.9,1.15,3.65,2,5.36c0.45,0.9,0.9,1.81,1.59,2.54c1.76,1.87,4.62,2.15,7.17,1.9
                    c2.55-0.25,5.13-0.89,7.64-0.4c2.11,0.41,3.98,1.58,5.94,2.44c3.01,1.32,6.33,1.93,9.61,1.77c0.92-0.04,1.87-0.16,2.67-0.61
                    c0.83-0.47,1.43-1.27,2-2.04c3.14-4.25,6.3-8.55,8.44-13.38c2.14-4.83,3.22-10.31,1.99-15.46c-0.92-3.85-3.08-7.31-5.69-10.27
                    c-2.61-2.96-5.69-5.47-8.75-7.97C104.84,78.18,96.11,77.85,90.15,81.15z"/>
                  <path className="st1" d="M81.85,115.08c-0.82,0.45-1.61,0.98-2.27,1.64c-2.25,2.22-2.92,5.76-5.34,7.79c-1.21,1.01-2.73,1.55-4.14,2.25
                    c-1.92,0.96-3.76,2.38-4.52,4.39c-0.43,1.14-0.47,2.38-0.51,3.6c-0.07,2.21-0.14,4.46,0.51,6.56c0.38,1.22,1,2.37,1.3,3.61
                    c0.61,2.47-0.04,5.08,0.34,7.6c0.29,1.9,1.15,3.65,2,5.37c0.45,0.9,0.9,1.81,1.59,2.54c1.76,1.87,4.62,2.15,7.17,1.9
                    c2.55-0.25,5.13-0.89,7.64-0.4c2.11,0.41,3.98,1.58,5.94,2.44c3.01,1.32,6.33,1.93,9.62,1.77c0.92-0.04,1.87-0.16,2.67-0.61
                    c0.83-0.47,1.43-1.27,2-2.04c3.14-4.25,6.3-8.55,8.44-13.38c2.14-4.83,3.22-10.31,1.99-15.46c-0.92-3.85-3.08-7.31-5.69-10.27
                    c-2.61-2.96-5.69-5.47-8.75-7.97C96.54,112.11,87.8,111.77,81.85,115.08z"/>
                  <path className="st1" d="M119.75,86.28c-0.82,0.45-1.61,0.98-2.28,1.64c-2.25,2.23-2.92,5.76-5.34,7.79c-1.21,1.01-2.73,1.55-4.14,2.25
                    c-1.92,0.96-3.76,2.38-4.52,4.39c-0.43,1.14-0.47,2.38-0.51,3.6c-0.07,2.2-0.14,4.46,0.51,6.56c0.38,1.22,1,2.37,1.3,3.61
                    c0.61,2.47-0.04,5.08,0.34,7.6c0.29,1.9,1.15,3.65,2,5.37c0.45,0.9,0.9,1.81,1.59,2.54c1.76,1.87,4.62,2.15,7.17,1.9
                    c2.55-0.25,5.13-0.89,7.64-0.4c2.11,0.41,3.98,1.58,5.94,2.44c3.01,1.32,6.33,1.93,9.62,1.77c0.92-0.04,1.87-0.16,2.67-0.61
                    c0.83-0.47,1.43-1.27,2-2.04c3.14-4.25,6.3-8.55,8.44-13.38c2.14-4.83,3.22-10.31,1.99-15.46c-0.92-3.85-3.08-7.31-5.69-10.27
                    c-2.61-2.96-5.69-5.47-8.75-7.97C134.44,83.31,125.7,82.97,119.75,86.28z"/>
                  <path className="st2" d="M170.65,119.71c0,0,32.8,12.98,45.5,81.02c0,0-8.21,11.14-26.53,12.38c-18.32,1.23-12.74-43.69-12.74-43.69
                    L170.65,119.71z"/>
                  <polygon className="st0" points="131.98,97.37 133.23,117.09 160.02,122.67 164.76,122.67 163.77,99.05 135.35,88.99 	"/>
                  <path className="st0" d="M174.01,128.94c1.21,13.25-4.25,15.71-4.25,15.71l-40.47-30.12c25.12,2.47,41.36,5.17,41.36,5.17
                    S172.74,129.3,174.01,128.94z"/>
                  <path className="st1" d="M109.01,58.92c0,0-7.71-31.71,10.65-43.58c18.36-11.88,37.81-10.55,51.06-3.44
                    c13.25,7.12,21.19,31.92,15.25,46.1l-43.99-13.54L109.01,58.92z"/>
                  <path className="st3" d="M135.54,312.25l4.2,144.63c0,0-2.93,98.79,15.86,177.65l30.88-0.52l17.07-321.76l-82.5-41.41L135.54,312.25z"
                    />
                  <path className="st4" d="M131.4,92.22c0,0,0.57,5.72,1.06,12.85c10.19,11.19,29.29,7.35,31.82,6.27c-0.16-3.59,0-10.84,0-10.84
                    L131.4,92.22z"/>
                  <path className="st5" d="M108.34,268.24c0,0-36.93,47.75-28.18,123.76c8.75,76.01,6.8,103.3,6.8,103.3s-47.61,43.85-62.19,107.2
                    c0,0,20.89,14.62,34.98,17.05c0,0,69.96-107.2,75.31-119.86c5.34-12.67,38.59-211.86,38.59-211.86L108.34,268.24z"/>
                  <path className="st4" d="M180.25,63.6c-1.57,4.27-0.08,8.74,3.33,10c3.4,1.26,7.43-1.18,9-5.45c1.57-4.27,0.08-8.74-3.33-10
                    C185.84,56.89,181.81,59.33,180.25,63.6z"/>
                  <path className="st0" d="M182.33,82.41c1.82-5.48,2.64-11.26,2.95-16.29c0.07-1.08,0.2-2.15,0.35-3.22c0.6-4.32,0.43-8.88-0.74-13.53
                    c-3.76-14.97-17.12-25.88-32.5-26.49c-20.71-0.82-37.24,16.12-36.54,36.35c-2.23-1.15-4.71-1.34-6.84-0.3
                    c-4.1,2.01-5.39,7.79-2.89,12.93c2.5,5.13,7.85,7.67,11.94,5.66c0.57-0.28,1.08-0.64,1.54-1.05c2.34,17.15,16.85,29.95,33.71,29.09
                    C166.07,104.9,177.98,96.3,182.33,82.41z"/>
                  <path className="st6" d="M148.35,64.05c0.09,1.82-1.31,3.38-3.12,3.47c-1.82,0.09-3.37-1.31-3.46-3.13c-0.09-1.82,1.31-3.37,3.12-3.47
                    C146.71,60.82,148.26,62.23,148.35,64.05z"/>
                  <path className="st6" d="M177.6,64.05c0.09,1.82-1.31,3.38-3.12,3.47c-1.82,0.09-3.37-1.31-3.46-3.13c-0.09-1.82,1.31-3.37,3.12-3.47
                    C175.96,60.82,177.51,62.23,177.6,64.05z"/>
                  <path className="st1" d="M151.12,58.54c-1.5-0.8-3.05-1.39-4.64-1.78c-1.59-0.38-3.21-0.52-4.8-0.37c-0.8,0.07-1.59,0.23-2.36,0.45
                    c-0.64,0.16-1.86,0.64-2.27,0.85c-1.47,0.7-2.86,1.63-4.21,2.65c1.01-1.37,2.29-2.56,3.78-3.47c0.74-0.45,1.54-0.84,2.37-1.11
                    c0.83-0.28,1.7-0.48,2.58-0.55c1.76-0.17,3.54,0.07,5.18,0.66c0.82,0.29,1.6,0.67,2.34,1.1C149.82,57.43,150.52,57.93,151.12,58.54
                    z"/>
                  <path className="st1" d="M164.4,58.54c0.6-0.61,1.3-1.11,2.03-1.56c0.74-0.44,1.52-0.82,2.34-1.1c1.63-0.59,3.42-0.83,5.18-0.66
                    c0.88,0.08,1.75,0.27,2.58,0.55c0.84,0.27,1.63,0.66,2.37,1.11c1.49,0.91,2.77,2.09,3.78,3.47c-1.36-1.03-2.74-1.95-4.22-2.65
                    c-0.4-0.21-1.63-0.69-2.27-0.85c-0.77-0.22-1.56-0.38-2.36-0.45c-1.59-0.15-3.21-0.01-4.8,0.37
                    C167.45,57.15,165.9,57.74,164.4,58.54z"/>
                  <path className="st7" d="M85.55,173.14c0.85-0.89,1.66-1.89,2.4-2.92c0.73-1.03,1.43-2.1,2.04-3.21c0.63-1.1,1.19-2.25,1.67-3.43
                    c0.51-1.17,0.88-2.4,1.27-3.63c-0.16,1.28-0.41,2.56-0.81,3.8c-0.18,0.63-0.42,1.23-0.65,1.84c-0.26,0.6-0.51,1.2-0.81,1.78
                    c-0.59,1.16-1.24,2.3-2,3.36c-0.6,0.86-1.24,1.68-1.96,2.47c0.65,0.13,1.33,0.22,2,0.25c0.93,0.04,1.86,0.01,2.79-0.13
                    c0.47-0.06,0.92-0.17,1.38-0.26c0.45-0.14,0.91-0.24,1.34-0.42c0.9-0.3,1.73-0.76,2.57-1.23c-0.74,0.61-1.53,1.18-2.42,1.59
                    c-0.87,0.44-1.81,0.75-2.76,0.96c-0.96,0.2-1.94,0.32-2.93,0.3c-0.99-0.01-1.95-0.11-2.95-0.37l-0.71-0.19L85.55,173.14z"/>
                  <path className="st4" d="M160.53,62.53c0.93,1.5,1.72,3.07,2.43,4.68c0.72,1.61,1.35,3.26,1.84,4.97c0.23,0.86,0.43,1.74,0.49,2.68
                    c0.01,0.24,0.01,0.48-0.02,0.74c-0.01,0.13-0.05,0.27-0.08,0.4c-0.06,0.14-0.09,0.28-0.19,0.43c-0.05,0.07-0.11,0.14-0.16,0.21
                    l-0.14,0.12c-0.09,0.08-0.18,0.16-0.28,0.23c-0.19,0.13-0.38,0.27-0.58,0.39c-0.78,0.48-1.61,0.82-2.44,1.12
                    c-0.84,0.28-1.69,0.52-2.56,0.67c1.58-0.75,3.18-1.5,4.53-2.49c0.17-0.12,0.32-0.26,0.48-0.39l0.22-0.2l0.11-0.1
                    c0.01-0.02,0.02-0.04,0.03-0.06c0.11-0.17,0.12-0.61,0.08-0.99c-0.08-0.8-0.28-1.64-0.51-2.46c-0.45-1.66-1.03-3.3-1.58-4.95
                    C161.63,65.86,161.06,64.21,160.53,62.53z"/>
                  <path className="st2" d="M91.95,229.37C91.95,229.37,91.95,229.37,91.95,229.37c-4.81-26.87-6.88-55.69-6.88-55.69
                    c-0.27-6.65-0.19-12.53,0.16-17.73c6.71-5.47,16.07-13.24,16.07-13.24l16-27.34c0,0-0.01-0.01-0.01-0.02
                    c6.08-0.57,12.53-0.83,12.53-0.83l39.94,30.14l0.89-24.94c0.2,0.15,0.4,0.3,0.6,0.46c17.26,14.13,28.46,34.57,30.87,57.04
                    c1.7,15.86,1.5,32.75,0.55,47.85h0c0,0,5.07,70.21,5.07,110.98c0,0-85.5-0.55-133.84-9.58C73.9,326.45,92.39,267.46,91.95,229.37z"
                    />
                  <path className="st0" d="M241.78,288.6c0.92-1.18-14.79-10.92-19.66-13.91c-0.63-0.39-0.57-1.32,0.09-1.63
                    c10.25-4.84,10.76-5.82,9.97-6.83c-0.56-0.72-10.78,0.59-20.41,1.27l-11.69,1.12c-3.28-0.78-101.44-24.03-106.79-30.49
                    c-5.44-6.57,9.16-42.07,9.16-42.07l-31.88-13.73c0,0-14.59,58.24-0.54,76.18c7.58,9.67,129.48,26.93,129.48,26.93
                    c1.93,1.48,7.17,5.03,7.17,5.03c7.3,4,5.09,3.14,9.3,5c0.85,0.37,5.92,1,7,1c2,0,16,0,19-8"/>
                  <path className="st0" d="M237.67,293.56"/>
                  <path className="st2" d="M117.29,115.36c0,0-35.71,13.56-58.32,66.28c0,0,11.1,24.61,47.85,25.45L117.29,115.36z"/>
                  <path className="st3" d="M58.98,181.64c2.42,4.07,5.61,7.6,9.19,10.59c3.55,3.04,7.52,5.54,11.7,7.55c4.27,1.84,8.62,3.47,13.18,4.41
                    c2.25,0.59,4.58,0.77,6.86,1.18c2.31,0.23,4.64,0.34,6.96,0.52l-1.21,0.92c1.17-4.67,2.5-9.25,3.91-13.83
                    c1.44-4.56,2.93-9.12,4.72-13.56c1.75-4.46,3.73-8.84,5.96-13.08c2.32-4.16,4.88-8.37,8.55-11.48c-3.27,3.49-5.54,7.71-7.49,12.01
                    c-2,4.29-3.76,8.7-5.28,13.2c-1.5,4.5-3.04,8.99-4.36,13.55c-1.34,4.55-2.58,9.16-3.68,13.75l-0.24,1.01l-0.97-0.09
                    c-2.39-0.22-4.78-0.37-7.15-0.65c-2.36-0.45-4.75-0.68-7.07-1.32c-2.33-0.58-4.66-1.17-6.9-2.05c-2.3-0.7-4.44-1.84-6.61-2.84
                    c-4.23-2.28-8.2-5.03-11.67-8.33C63.99,189.76,60.93,185.98,58.98,181.64z"/>
                  <path className="st1" d="M111.4,54.55l0.83-0.1l0.55-6.88l3.07-6.36c0,0,12.02-17.11,12.44-17.53c0.42-0.42,9.59-5.94,10.15-6.22
                    c0.56-0.28,13.39,1.84,13.39,1.84s22.57-4.87,18.75,7.78c-3.82,12.65-20.3,19.23-21.15,18.66c2.68-2.83,2.96-10.89,2.96-10.89
                    c-12.69,14.7-36.55,24.37-36.55,24.37s-2.5-1.91-6.84-0.3L111.4,54.55z"/>
                  <path className="st7" d="M167.85,84.37c-0.88,3.9-4.65,6.8-9.12,6.73c-4.47-0.08-8.14-3.1-8.87-7.04L167.85,84.37z"/>
                  <path className="st8" d="M222.17,670.17c0,0-4.01-10.52-13.93-15.58c-9.91-5.06-23.04-10.53-23.04-10.53l-25.16,0.58l-2.02,23.42
                    L222.17,670.17z"/>
                  <path className="st8" d="M63.53,673.53c0,0,1.75-11.13-4.33-20.45s-14.2-19.61-14.2-19.61l-22.66-13l-13.4,19.32L63.53,673.53z"/>
                </svg>
              </div>
            </div>

            <div 
              id="patient-1" 
              className={
                `reviewer-2
                ${reviewerEntering===2 ? ' speak' : ''}
                ${reviewerExiting===2 ? ' exit' : ''}
                ${[3, 4].includes(reviewerEntering) ? ' sidestep-speak' : ''}
                ${[3, 4].includes(reviewerExiting) ? ' sidestep-exit' : ''}
                `
              }
              onAnimationEnd={handleAnimationEnd}
            >
              <div className={animationClassNames[4] || ''}>
                <svg 
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" y="0px"
                  viewBox="0 0 300 700"
                  onClick={()=>selectReviewer(2)}
                >
                  <path className="st0" d="M72.19,169.01c0,0,63.06-37.48,60.88-83.77c-2.38-49.08,29.61-30.03,30.44,10.98
                    c0.83,41.42,50.74,60.26,50.74,60.26L72.19,169.01z"/>
                  <polygon className="st1" points="24.87,378.28 44.23,382 44.23,366.26 24.04,365.54 "/>
                  <polygon className="st1" points="17.31,506.15 43.5,518.79 43.5,500.87 20.31,494.14 "/>
                  <path className="st2" d="M213.21,649.04c0,0-3.93-10.67-13.87-15.95s-30.75-11.29-30.75-11.29l-17.91,0.52l-2.28,23.71L213.21,649.04z"
                    />
                  <path className="st2" d="M145.81,648.73c0,0-3.21-10.87-12.84-16.88s-30.03-13.25-30.03-13.25l-17.91-0.62l-3.83,23.5L145.81,648.73z"/>
                  <path className="st3" d="M194.06,396.29c-1.14,56.12-9.94,225.52-9.94,225.52l-38.52,5.49c0,0-10.25-181.82-10.25-200.57
                    s-6.21-72.9-6.21-72.9s-6.11,54.26-6.11,65.23c0,10.98-0.52,203.78-0.52,203.78l-44.01,2.17c0,0-10.98-220.03-10.98-248.61
                    s3.31-71.55,13.25-88.12s108.41,0,108.41,0C193.02,324.22,194.58,360.26,194.06,396.29z"/>
                  <polygon className="st4" points="33.56,680.11 44.64,330.64 51.68,330.64 44.64,681.45 "/>
                  <polygon className="st4" points="11.09,652.46 22.28,354.36 29.21,354.36 22.28,653.81 "/>
                  <polygon className="st2" points="28.9,693.16 32.63,679.69 46.61,680 50.96,692.64 "/>
                  <polygon className="st2" points="5.71,659.5 9.44,646.04 23.42,646.46 27.77,658.99 "/>
                  <path className="st2" d="M37.5,374.55h14.91c0,0,0.72-33.65-0.83-43.9c-0.41-2.9-16.46-2.49-16.46-2.49s-14.19-3.73-15.74,9.01
                    c-1.55,12.74-1.55,33.65-1.55,33.65h10.46c0,0-1.45-27.65,1.55-27.65c3,0,10.46-1.45,10.46,1.55
                    C40.29,347.73,37.5,374.55,37.5,374.55z"/>
                  <polygon className="st4" points="289.01,677.31 260.22,328.78 253.29,329.2 278.03,679.28 "/>
                  <polygon className="st4" points="249.14,641.59 237.96,347.63 231.02,347.63 237.96,642.94 "/>
                  <polygon className="st2" points="294.29,690.05 289.94,676.9 275.96,677.93 272.23,690.67 "/>
                  <polygon className="st2" points="254.53,652.77 250.8,639.31 236.82,639.73 232.47,652.25 "/>
                  <path className="st2" d="M234.85,357.36l-14.91-0.72c0,0,1.45-14.39,3-24.64c0.41-2.9,22.26-3.73,22.26-3.73s14.19-3.73,15.74,9.01
                    c1.55,12.74,1.55,33.65,1.55,33.65h-10.46c0,0,1.45-27.65-1.55-27.65s-15.53-3.73-15.53-0.72v14.81H234.85z"/>
                  <polygon className="st2" points="273.79,512.06 241.59,493.42 239.31,486.69 270.79,502.32 "/>
                  <polygon className="st2" points="44.95,420.11 262.5,413.38 262.5,423.83 45.68,429.84 "/>
                  <polygon className="st2" points="44.23,366.26 256.6,364.09 255.05,355.08 45.68,356.53 "/>
                  <path className="st0" d="M22.48,316.67c0,0-16.15,16.15-12.94,22.68s19.67,15.01,19.67,15.01l15.43-23.82l5.9,7.66
                    c0,0,1.04-19.47-4.35-24.85C40.92,307.97,22.48,316.67,22.48,316.67z"/>
                  <path className="st0" d="M250.08,316.67c0,0,16.26,16.15,12.94,22.68s-19.67,15.01-19.67,15.01l-15.43-23.82l-5.9,7.66
                    c0,0-1.04-19.47,4.35-24.85S250.08,316.67,250.08,316.67z"/>
                  <path className="st5" d="M22.48,316.67c0,0-1.45-79.73-1.45-90.6s30.65-88.01,39.24-98.78s67.93-18.33,67.93-18.33s5.59,41,23.92,39.97
                    s14.08-38.1,14.08-38.1l21.54,4.56c0,0,28.99,11.8,35.62,36.76c7.77,28.89,25.58,61.4,25.58,79.73s1.04,84.8,1.04,84.8l-23.82-3.21
                    l-9.32-70.31l-15.01-43.49c0,0-4.35,119.08-4.35,122.29s-132.23-2.07-132.23-2.07l9.11-127.15l-18.64,35.93l-9.42,84.8L22.48,316.67
                    z"/>
                  <g id="head">
                    <path className="st0" d="M182.05,62.33c-0.46,24.44-9.6,44.55-31.64,44.21s-39.52-20.56-39.07-45.01
                      c0.46-24.44,18.73-43.86,40.78-43.52C174.17,18.35,182.5,37.89,182.05,62.33z"/>
                    <path className="st6" d="M149.61,92.03c6.63,0.23,12.57-3.77,14.85-9.94l-28.56,0.46C135.9,82.55,140.24,92.03,149.61,92.03z"/>
                    <path className="st7" d="M171.2,43.83c-3.31-2.17-7.31-2.51-11.19-2.28c-0.8,0-1.48,0.69-1.48,1.48c0,0.8,0.69,1.48,1.48,1.48
                      c3.43-0.11,6.85,0,9.71,1.94c0.69,0.34,1.6,0.11,1.94-0.46C172.11,45.2,171.88,44.28,171.2,43.83L171.2,43.83z"/>
                    <path className="st7" d="M136.7,40.63c-3.88-0.34-7.65,1.48-10.85,3.43c-0.69,0.34-0.91,1.26-0.57,1.94s1.26,0.91,1.94,0.46l0,0
                      c2.86-1.71,6.05-3.31,9.48-3.08c0.8,0,1.48-0.69,1.48-1.48C138.18,41.08,137.5,40.63,136.7,40.63L136.7,40.63z"/>
                    <path className="st8" d="M146.98,61.42c0,0-3.88,7.2-2.74,8.57s5.83,1.94,5.83,1.94"/>
                    <circle className="st2" cx="163.54" cy="53.42" r="2.74"/>
                    <circle className="st2" cx="135.33" cy="53.42" r="2.74"/>
                    <path className="st9" d="M139.21,58.45c-3.31,1.83-6.85,3.43-10.74,2.97c-0.69,0-1.26,0.57-1.26,1.26s0.57,1.26,1.26,1.26
                      c4.23,0.46,8.34-1.26,11.99-3.31C141.95,59.82,140.7,57.65,139.21,58.45z"/>
                    <path className="st9" d="M156.46,60.96c3.66,1.94,7.77,3.77,11.99,3.31c0.69,0,1.26-0.57,1.26-1.26c0-0.69-0.57-1.26-1.26-1.26
                      c-3.77,0.46-7.42-1.14-10.74-2.97C156.35,57.88,154.98,60.16,156.46,60.96z"/>
                    <path className="st9" d="M160.8,28.06c-4,0.8-7.54-0.46-11.42-1.14c-3.77-0.91-7.77,0.23-10.51,3.08c-1.26,1.14,0.57,2.97,1.83,1.83
                      c1.37-1.37,3.08-2.17,4.91-2.51c1.48-0.11,2.97,0,4.45,0.46c3.77,0.91,7.42,1.6,11.31,0.91C163.09,30.23,162.4,27.72,160.8,28.06
                      L160.8,28.06z"/>
                    <path className="st9" d="M155.55,32.97c-0.46,0.11-1.03,0.11-1.6,0.11c-0.46,0-0.91,0-1.37-0.11c-0.91-0.11-1.83-0.46-2.74-0.57
                      c-0.91-0.23-1.94-0.23-2.97,0c-1.14,0.34-2.28,0.91-3.08,1.83c-0.46,0.46-0.46,1.26,0,1.83c0.46,0.46,1.26,0.46,1.83,0
                      c0.34-0.34,0.8-0.57,1.26-0.91c0.34-0.11,0.69-0.23,1.03-0.34c-0.23,0,0,0,0.11,0h0.34c0.23,0,0.46,0,0.57,0c0.23,0-0.11,0,0.11,0
                      h0.34l0.57,0.11c2.06,0.57,4.11,0.8,6.17,0.46c0.69-0.11,1.14-0.8,1.03-1.48c-0.11-0.69-0.8-1.14-1.48-1.03
                      C155.66,32.86,155.66,32.86,155.55,32.97L155.55,32.97z"/>
                    <path className="st0" d="M113.17,54.34c0,0-4.8-3.77-5.71-1.6s-2.06,15.65,0.8,21.7c2.86,6.05,8.57-6.51,8.57-6.51L113.17,54.34z"/>
                    <path className="st10" d="M112.03,62.79c3.77-19.88,4.68-19.42,17.02-36.67c16.33,3.31,26.73,1.83,40.32,9.14
                      c14.16,7.65,12.45,20.68,12.45,20.68s5.6-19.88-0.8-30.73C175.42,7.39,93.63-18.09,112.03,62.79z"/>
                    <path className="st0" d="M181.13,55.25c0,0,4.91-3.54,5.83-1.37s1.6,15.76-1.48,21.59c-3.08,5.83-8.45-6.74-8.45-6.74L181.13,55.25z"/>
                  </g>
                </svg>
              </div>
            </div>

            <div 
              id="caregiver-2" 
              className={
                `reviewer-3
                ${reviewerEntering===3 ? ' speak' : ''}
                ${reviewerExiting===3 ? ' exit' : ''}
                ${reviewerEntering===4 ? ' sidestep-speak' : ''}
                ${reviewerExiting===4 ? ' sidestep-exit' : ''}
                `
              }
              onAnimationEnd={handleAnimationEnd}  
            >
              <div className={animationClassNames[4] || ''}>
                <svg 
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" y="0px"
                  viewBox="0 0 190 640"
                  onClick={()=>selectReviewer(3)}
                >
                  <path className="st0" d="M11.4,634.65c0,0,3.9-10.5,13.7-15.8s30.4-11.1,30.4-11.1l17.7,0.5l2.3,23.4L11.4,634.65z"/>
                  <path className="st0" d="M77.9,634.35c0,0,3.2-10.8,12.7-16.6s29.6-13.1,29.6-13.1l17.7-0.6l3.8,23.2L77.9,634.35z"/>
                  <path className="st1" d="M47.8,185.55c0,0,32.5-52,31.1-99c-1.4-49.8,23-38,23.5,3.6c0.5,42,29.9,82.7,29.9,82.7L47.8,185.55z"/>
                  <path className="st1" d="M127.9,64.15c0,29.1-9.9,53.2-34.6,53.2s-44.8-23.5-44.8-52.6s20.1-52.6,44.8-52.6S127.9,35.05,127.9,64.15z"/>
                  <path className="st2" d="M89.7,98.85c7.2,0.2,13.8-4.2,16.3-11l-31.4,0.6C74.6,88.45,79.5,98.85,89.7,98.85z"/>
                  <path className="st0" d="M113.3,46.25c-3.5-2.4-8-2.7-12.1-2.5c-1.6,0.1-1.6,2.6,0,2.5c3.7-0.1,7.7,0.1,10.9,2.2
                    c0.6,0.3,1.4,0.1,1.7-0.4C114,47.35,113.8,46.55,113.3,46.25z"/>
                  <path className="st0" d="M75.6,42.75c-4.3-0.3-8.3,1.6-11.8,3.7c-0.6,0.3-0.8,1.1-0.5,1.7c0.3,0.6,1.1,0.8,1.7,0.4
                    c3.2-1.9,6.7-3.7,10.5-3.4c0.7,0,1.2-0.6,1.3-1.3C76.8,43.25,76.3,42.75,75.6,42.75L75.6,42.75z"/>
                  <path className="st3" d="M86.8,65.25c0,0-4.2,7.9-3,9.4s6.3,2.1,6.3,2.1"/>
                  <circle className="st0" cx="105" cy="56.55" r="3"/>
                  <circle className="st0" cx="74" cy="56.55" r="3"/>
                  <path className="st0" d="M48.4,66.75c4.1-21.9,1.2-21.4,14.8-40.3c18,3.6,33.3,2,48.2,10.1c15.5,8.4,16,16.3,16,16.3s3.9-15.4-3.1-27.3
                    C118,5.95,28.2-22.05,48.4,66.75z"/>
                  <path className="st4" d="M4.8,210.85l38.2,7.4l2.9-4.7c0,0-9.7,24.2-12.1,51.1c-1.2,13.3-4.9,46.5-4.9,46.5l126.2-0.6l-9.3-48.2
                    c0,0-3.4-10.5-4-27.1c-0.3-8.4-3.3-34.3-3.3-34.3l4.6,19.8l42.1-8.8c0,0-11.5-50.9-19.3-61.1c-7.8-10.2-57.3-26.4-57.3-26.4
                    s0.2,34.6-16.1,50.8c-19.5-22.9-18.9-48.8-18.9-48.8s-42.2,6.3-55.7,25.1C9.3,163.55,4.8,210.85,4.8,210.85z"/>
                  <path className="st1" d="M13.3,212.55c0,0,1.1,31.9,11.7,36.5s106.1-19.5,106.1-19.5s16.6-9,19.7-15.1c3.1-6.1,4.2-24.1-1-25.3
                    c-3.3-0.7-6.8,5.3-6.8,5.3l-5.3,9.1c0,0-0.8-6.8-3.8-6.8s-4.9,15.8-4.9,15.8l-17,3.7l-76,8.1l-1-8.4L13.3,212.55z"/>
                  <path className="st1" d="M49.7,57.45c0,0-5.2-4.1-6.3-1.7s-2.3,17.3,0.9,23.8s9.5-7.1,9.5-7.1L49.7,57.45z"/>
                  <path className="st1" d="M126.9,58.35c0,0,5.3-3.9,6.4-1.5c1.1,2.4,1.7,17.3-1.6,23.8c-3.3,6.5-9.2-7.5-9.2-7.5L126.9,58.35z"/>
                  <path className="st5" d="M22.2,213.95l3,0.4l62.8,24.4c0,0-53.9-12.1-58.5-15.1S22.2,213.95,22.2,213.95z"/>
                  <path className="st5" d="M86.3,218.95l47.8,9c0,0-40.2,1.1-45.4-0.9S86.3,218.95,86.3,218.95z"/>
                  <path className="st1" d="M172.2,214.85c0,0-2.3,32-9.9,38.8c-7.6,6.8-108-23-108-23s-15.2-5-23.6-8.2c-6.5-2.5-16.7-28-6.1-27.3
                    s15.1,4.5,15.1,4.5s-3.8-9.1,2.3-6c6.1,3.1,17.4,20.4,17.4,20.4l83.5,15.7l4.3-10.2L172.2,214.85z"/>
                  <path className="st4" d="M30.3,384.95c1.1,55.4,9.8,222.7,9.8,222.7l38,5.4c0,0,10.1-179.5,10.1-198s6.2-71.9,6.2-71.9
                    s6.1,53.6,6.1,64.4s0.5,201.2,0.5,201.2l43.5,2.2c0,0,10.9-217.3,10.9-245.6s-3.3-70.6-13-87c-9.7-16.4-107.1,0-107.1,0
                    C31.5,313.75,29.8,349.35,30.3,384.95z"/>
                </svg>
              </div>
            </div>

            <div 
              id="patient-2" 
              className={
                `reviewer-4
                ${reviewerEntering===4 ? ' speak' : ''}
                ${reviewerExiting===4 ? ' exit' : ''}
                `
              }
              
              onAnimationEnd={handleAnimationEnd}  
            >
              <div className={animationClassNames[4] || ''}>
                <svg 
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" y="0px"
                  viewBox="0 0 320 670"
                  onClick={()=>selectReviewer(4)}
                >
                  <path className="st0" d="M159.05,107.29c0,0,61.5-126.9,49.6-19.7c-1.5,13.8-6.4,20.6-15.3,22.6S159.05,107.29,159.05,107.29z"/>
                  <path className="st1" d="M127.15,174.59c0,0,29.2-46.8,28-89.1c-1.3-44.8,21.3-34.2,21.8,3.2c0.5,37.9,35.7,93.9,35.7,93.9
                    L127.15,174.59z"/>
                  <path className="st1" d="M199.25,65.29c0,26.2-8.9,47.9-31.2,47.9s-40.4-21.2-40.4-47.4s18.1-47.4,40.4-47.4S199.25,39.09,199.25,65.29z
                    "/>
                  <path className="st2" d="M166.35,96.59c6.5,0.2,12.3-3.8,14.6-9.8l-28.2,0.4C152.75,87.19,157.15,96.59,166.35,96.59z"/>
                  <path className="st3" d="M187.65,49.09c-3.2-2.1-7.2-2.4-11-2.3c-1.6,0.1-1.6,2.6,0,2.5c3.3-0.1,6.9,0.1,9.7,2c0.6,0.3,1.4,0.1,1.7-0.4
                    C188.45,50.19,188.25,49.39,187.65,49.09L187.65,49.09z"/>
                  <path className="st3" d="M153.65,45.89c-3.9-0.3-7.5,1.4-10.7,3.4c-0.6,0.3-0.8,1.1-0.5,1.7c0.3,0.6,1.1,0.8,1.7,0.4
                    c2.9-1.7,6-3.3,9.4-3c0.7,0,1.2-0.6,1.3-1.3C154.95,46.49,154.35,45.89,153.65,45.89L153.65,45.89z"/>
                  <path className="st4" d="M163.85,66.29c0,0-3.8,7.1-2.7,8.4s5.7,1.9,5.7,1.9"/>
                  <circle className="st5" cx="180.15" cy="58.39" r="2.7"/>
                  <circle className="st5" cx="152.35" cy="58.39" r="2.7"/>
                  <path className="st6" d="M5.05,374.39c0,0,2.7-37.1,27.4-35.1c24.8,2.1,22.1,41.3,22.1,41.3l6.5,270.8l-13.4,1.2l-7.5-280.3
                    c0,0-0.1-17.9-10.7-17.9s-11.8,19.6-11.8,19.6L5.05,374.39z"/>
                  <path className="st1" d="M41.35,325.09c0,0-28.5,12.7-29.1,26s22.3-3.6,22.3-3.6s6,12.6,11.4,12s4.8-26.5,4.8-26.5L41.35,325.09z"/>
                  <path className="st5" d="M85.95,658.39c0,0,4-10.7,13.9-16s30.8-11.3,30.8-11.3l17.9,0.5l2.3,23.7L85.95,658.39z"/>
                  <path className="st5" d="M153.25,658.19c0,0,3.3-10.9,12.8-16.8s30-13.2,30-13.2l17.9-0.6l3.8,23.4L153.25,658.19z"/>
                  <path className="st7" d="M112.15,413.19c1.1,56.1,2.9,218,2.9,218l38.5,5.5c0,0,6.2-181.8,6.2-200.5s8.7-112.9,8.7-112.9
                    s7.7,90.3,7.7,101.3s0.5,207.8,0.5,207.8l44,2.2c0,0,4.4-213,4.4-241.6s7.5-110-2.4-126.5s-111.9,5.3-111.9,5.3
                    S111.05,357.09,112.15,413.19z"/>
                  <path className="st8" d="M113.35,193.29l-63.4,143.2c-5.3-1.8-10-5-13.8-9.2c-6.2-7,25.2-89.9,29.8-100s25.6-73.6,30.2-79.8
                    s34.1-13.2,53.5-24.8c0,6.2-18.5,53,21.3,47.8c36,1.5,13.2-45.8,13.2-45.8s46.9,9.7,52.3,11.3s22.5,29.4,28.7,34.1
                    c4.1,2.9,8.9,4.5,14,4.6c0,0,0-19.4,7.8-24.8c6.2,13.2,18.6,7,20.2,3.1c6.2,24,5.4,34.9,5.4,34.9s-9.3,30.2-27.1,27.9
                    s-45-18.6-45-18.6l-9.3-13.2l-2.8,128.3c0,0-37.6-0.6-61.6-0.6s-56.4-3.1-56.4-3.1L113.35,193.29z"/>
                  <path className="st1" d="M300.85,118.19c0,0,2.7-12.3,2.7-18.5s-7.5-7.5-8.2-2.7s-2,21.9-2,21.9s-4.8,2-4.8,5.5c0.1,2.3,0.5,4.6,1.4,6.8
                    c0,0-3.6,0.8-3.6,3.5c0.2,2.3,0.6,4.5,1.3,6.7c0,0-3.1,1.5-3.1,3.6s2.3,7.7,2.3,7.7s0.2,3.7,5.8,5.3c2,0.6,15.1-0.2,18.5-2.9
                    s2.1-10.1,2.1-10.1s2.7-4.3,1.3-7.7s1.4-15-1.4-15.7S300.85,118.19,300.85,118.19z"/>
                  <polygon className="st5" points="44.25,662.79 47.65,650.09 60.85,650.39 64.95,662.29 "/>
                  <path className="st9" d="M156.15,63.69c-2.9,1.6-6,3-9.4,2.6c-0.6-0.1-1.2,0.4-1.2,1s0.4,1.2,1,1.2c0.1,0,0.1,0,0.2,0
                    c3.7,0.4,7.3-1.1,10.5-2.9C158.55,64.89,157.45,62.99,156.15,63.69z"/>
                  <path className="st9" d="M172.55,65.59c3.2,1.8,6.8,3.3,10.5,2.9c0.6,0,1.1-0.5,1.1-1.1s-0.5-1.1-1.1-1.1c-3.3,0.4-6.5-1-9.4-2.6
                    C172.35,62.99,171.25,64.89,172.55,65.59z"/>
                  <path className="st9" d="M172.85,37.09c-3.5,0.7-6.6-0.4-10-1c-3.3-0.8-6.8,0.2-9.2,2.7c-1.1,1,0.5,2.6,1.6,1.6c1.2-1.2,2.7-1.9,4.3-2.2
                    c1.3-0.1,2.6,0,3.9,0.4c3.3,0.8,6.5,1.4,9.9,0.8C174.85,38.99,174.25,36.79,172.85,37.09L172.85,37.09z"/>
                  <path className="st9" d="M168.25,41.39c-0.4,0.1-0.9,0.1-1.4,0.1c-0.4,0-0.8,0-1.2-0.1c-0.8-0.1-1.6-0.4-2.4-0.5c-0.8-0.2-1.7-0.2-2.6,0
                    c-1,0.3-2,0.8-2.7,1.6c-0.4,0.4-0.4,1.1,0,1.6l0,0c0.4,0.4,1.1,0.4,1.6,0c0.3-0.3,0.7-0.5,1.1-0.7c0.3-0.1,0.6-0.2,0.9-0.3
                    c-0.2,0,0,0,0.1,0h0.3h0.5h0.1l0.3,0.1l0.5,0.1c1.8,0.5,3.6,0.7,5.4,0.4c0.6-0.2,0.9-0.8,0.8-1.4
                    C169.45,41.49,168.85,41.19,168.25,41.39L168.25,41.39z"/>
                  <path className="st0" d="M132.05,19.69c0,0-20.3,2.9-18.9,59.7c0.5,19.7,12.8,34.1,39.7,30.6c2.7-0.3-42.2-34.7-11.8-78.7
                    c16.2,3.3,29.6,2.6,43.4,9.1c18.3,8.7,27.2,17.8,27.2,17.8s-2.3-16.5-8.5-27.2S166.95-9.51,132.05,19.69z"/>
                </svg>
              </div>
            </div>

            <div 
              id="caregiver-3" 
              className={
                `reviewer-5
                ${reviewerEntering===5 ? ' speak' : ''}
                ${reviewerExiting===5 ? ' exit' : ''}
                ${reviewerEntering===4 ? ' sidestep-speak' : ''}
                ${reviewerExiting===4 ? ' sidestep-exit' : ''}
                `
              }
              onAnimationEnd={handleAnimationEnd}
            >
              <div className={animationClassNames[4] || ''}>
                <svg 
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" y="0px"
                  viewBox="0 0 180 670"
                  onClick={()=>selectReviewer(5)}
                >
                  <path className="st1" d="M8.05,661.95c0,0,3.9-10.5,13.7-15.8s30.4-11.2,30.4-11.2l17.7,0.5l2.3,23.5L8.05,661.95z"/>
                  <path className="st1" d="M74.55,661.55c0,0,3.2-10.8,12.7-16.6s29.6-13,29.6-13l17.7-0.6l3.8,23.2L74.55,661.55z"/>
                  <path className="st0" d="M26.95,412.25c1.1,55.4,9.8,222.7,9.8,222.7l38,5.4c0,0,6.1-179.5,6.1-198s10.2-71.9,10.2-71.9
                    s6.1,49.6,6.1,60.4s0.5,205.2,0.5,205.2l43.5,2.2c0,0,10.9-217.3,10.9-245.5s-3.3-70.7-13-87c-9.7-16.4-101.1,0-101.1,0
                    S25.85,356.75,26.95,412.25z"/>
                  <path className="st2" d="M44.45,212.85c0,0,32.5-52,31.1-99c-1.4-49.8,23-38,23.5,3.6c0.5,42,29.9,82.7,29.9,82.7L44.45,212.85z"/>
                  <path className="st2" d="M124.55,91.35c0,29.1-9.9,53.3-34.6,53.3s-44.8-23.6-44.8-52.6s20.1-52.6,44.8-52.6S124.55,62.25,124.55,91.35z
                    "/>
                  <path className="st3" d="M86.35,126.15c7.2,0.2,13.8-4.2,16.3-11l-31.4,0.5C71.25,115.65,76.05,126.15,86.35,126.15z"/>
                  <path className="st1" d="M109.85,73.45c-3.5-2.4-8-2.7-12.1-2.5c-1.6,0-1.6,2.6,0,2.5c3.7-0.1,7.7,0.1,10.9,2.2c0.6,0.3,1.4,0.1,1.7-0.4
                    C110.65,74.65,110.45,73.85,109.85,73.45z"/>
                  <path className="st1" d="M72.25,69.95c-4.3-0.3-8.3,1.6-11.8,3.7c-0.6,0.3-0.8,1.1-0.5,1.7c0.3,0.6,1.1,0.8,1.7,0.5l0,0
                    c3.2-1.9,6.7-3.7,10.5-3.4c0.7,0,1.3-0.6,1.3-1.3C73.45,70.55,72.85,70.05,72.25,69.95L72.25,69.95z"/>
                  <path className="st4" d="M83.45,92.55c0,0-4.2,7.9-3,9.4s6.3,2.1,6.3,2.1"/>
                  <circle className="st1" cx="101.65" cy="83.75" r="3"/>
                  <circle className="st1" cx="70.65" cy="83.75" r="3"/>
                  <path className="st1" d="M44.95,93.95c4.1-21.9,1.2-21.4,14.8-40.3c18,3.6,33.3,2,48.2,10.1c15.5,8.4,16,16.3,16,16.3s3.9-15.4-3.1-27.3
                    C114.65,33.15,24.85,5.15,44.95,93.95z"/>
                  <path className="st0" d="M11.45,238.15l28.2,7.4l2.9-4.7c0,0,1.2,34.3-1.2,47.5s-14.4,31.6-14.4,31.6l124.6-3.1l-12-26.1
                    c-3.6-9.2-5.5-19-5.9-28.9c-0.3-8.4-1.8-24-1.8-24l4.2,10.4l35.9-9.2c0,0-11.5-50.9-19.3-61.1s-46-20.7-46-20.7s-1.3,29-17.5,45.2
                    c-19.6-22.9-20.7-42.2-20.7-42.2s-30.4-0.3-43.9,18.6C15.95,190.85,11.45,238.15,11.45,238.15z"/>
                  <path className="st2" d="M14.25,238.85c0,0-2.4,30.6,8.2,35.2s105.3-17.2,105.3-17.2s16.6-9,19.7-15.1s4.2-24.1-1-25.3
                    c-3.3-0.7-6.8,5.3-6.8,5.3l-5.3,9.1c0,0-0.8-6.8-3.8-6.8s-4.9,15.8-4.9,15.8l-17,3.7l-76,8.2l-1-8.4L14.25,238.85z"/>
                  <circle className="st1" cx="62.25" cy="25.25" r="17.2"/>
                  <path className="st2" d="M46.35,84.65c0,0-5.2-4.1-6.3-1.7c-1.1,2.4-2.3,17.2,0.9,23.8s9.5-7.1,9.5-7.1L46.35,84.65z"/>
                  <path className="st2" d="M123.55,85.65c0,0,5.3-3.9,6.4-1.5c1.1,2.4,1.7,17.3-1.6,23.8s-9.2-7.5-9.2-7.5L123.55,85.65z"/>
                  <path className="st5" d="M19.35,240.15l65.3,25.8c0,0-53.9-12.1-58.5-15.1S19.35,240.15,19.35,240.15z"/>
                  <path className="st5" d="M82.95,246.25l47.8,9c0,0-40.2,1.1-45.4-0.9S82.95,246.25,82.95,246.25z"/>
                  <path className="st2" d="M164.05,241.05c0,0,0.8,28.3-6.8,35.1s-106.2-18.2-106.2-18.2s-15.2-5-23.6-8.2c-6.5-2.5-16.7-28-6.1-27.3
                    s15.1,4.5,15.1,4.5s-3.8-9.1,2.3-6.1s17.4,20.4,17.4,20.4l83.5,15.7l2.1-11.7L164.05,241.05z"/>
                </svg>
              </div>
            </div>

            <div 
              id="patient-3" 
              className={
                `reviewer-6
                ${reviewerEntering===6 ? ' speak' : ''}
                ${reviewerExiting===6 ? ' exit' : ''}
                ${[4, 5].includes(reviewerEntering) ? ' sidestep-speak' : ''}
                ${[4, 5].includes(reviewerExiting) ? ' sidestep-exit' : ''}
                `
              }
              onAnimationEnd={handleAnimationEnd}
            >
              <div className={animationClassNames[4] || ''}>
                <svg 
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" y="0px"
                  viewBox="0 0 460 615"
                  onClick={()=>selectReviewer(6)}
                >
                  <path className="background st0" d="M456.1,361.9c0,62.5-16.6,148.8-94.7,195.1S155,629.6,97.8,595.6
                    C37.8,560.1-10.9,548.9,8.1,414C28.4,270,121.3,60.8,257.3,50.8S456.1,299.4,456.1,361.9z"/>
                  <polygon className="st1" points="331.1,301.5 346.7,527.1 353.2,514.1 357.6,283.8 "/>
                  <path className="st2" d="M359.9,520.1c0,0,17.5-71.6,11.4-126.8c-3.9-35.3-25.3-81.2-25.3-81.2l-0.3,23.1c0,0,9.4,12.5,15.4,61.8
                    s-6,99.5-6,99.5"/>
                  <path className="st1" d="M370.4,484.2c0,0,22-99.1-14.3-153.3c0-7.1,2.9-4.8,2.9-4.8s20.5,22.7,23.7,86.1c1.8,35.5-10.9,81.9-10.9,81.9
                    L370.4,484.2z"/>
                  <ellipse className="st2" cx="351.4" cy="550.8" rx="7.3" ry="37.3"/>
                  <polygon className="st1" points="133.1,301.5 117.5,527.1 111,514.1 121.8,289.7 "/>
                  <polygon className="st3" points="139.1,260.3 142.6,295.9 121.1,301 121.8,284.8 105.9,286.4 107.1,263.9 "/>
                  <path className="st2" d="M104.3,520.1c0,0-17.5-71.6-11.4-126.8c3.9-35.4,25.3-81.3,25.3-81.3l0.3,23.1c0,0-9.5,12.5-15.5,61.8
                    s6.1,99.5,6.1,99.5"/>
                  <path className="st1" d="M93.9,484.2c0,0-22-99.1,14.3-153.3c0-7.1-2.9-4.8-2.9-4.8s-20.5,22.7-23.7,86.1c-1.8,35.5,10.9,81.9,10.9,81.9
                    L93.9,484.2z"/>
                  <polygon className="st4" points="173.2,396.4 300.5,465 303.9,452.9 182.1,383.1 "/>
                  <polygon className="st4" points="174.4,458.4 302.8,392 296.1,378.7 163.3,451.8 "/>
                  <polygon className="st3" points="161.1,383.1 326,383.1 326,344.4 154.4,336.6 "/>
                  <polygon className="st3" points="153.3,182.2 333.2,169.5 337.9,308.4 147.6,306.1 "/>
                  <path className="st5" d="M175.8,174.6c0,0,69.9-41.5,67.5-92.9c-2.6-54.4,32.8-33.3,33.8,12.2c1,45.9,56.3,66.8,56.3,66.8L175.8,174.6z"/>
                  <path className="st5" d="M295.3,59.8c-0.5,24.6-9.7,44.8-31.8,44.4S223.7,83.5,224.2,59s18.8-44.1,41-43.7S295.7,35.2,295.3,59.8z"/>
                  <path className="st6" d="M321.9,191.4l10.4,54.4c7.6-2.3,15.7-2.6,23.4-0.9c13.1,2.8,2.1,25.6,2.1,25.6s20.4,13.8,27.9,5.3
                    s-0.9-30.9-6.6-43.1s-30.9-89.1-36.6-96.6s-41.3-15.9-64.7-30c0,7.5-1.9,39.4-15.9,48.7c-9.4-3.8-25.3-45-25.3-45
                    S179.5,120.1,173,122s-27.2,35.6-34.7,41.2s-16.9,5.6-16.9,5.6s0-23.4-9.4-30c-7.5,15.9-22.5,8.4-24.4,3.8
                    c-7.5,29.1-6.6,42.2-6.6,42.2s11.3,36.6,32.8,33.8s54.4-22.5,54.4-22.5l11.3-15.9l3.4,119.7c0,0,45.5,15.3,74.6,15.3
                    s68.3-19.7,68.3-19.7L321.9,191.4z"/>
                  <path className="st5" d="M95.1,100.6c0,0-3.3-14.9-3.3-22.3s9.1-9.1,9.9-3.3s2.5,26.4,2.5,26.4s5.8,2.5,5.8,6.6
                    c-0.1,2.8-0.6,5.6-1.6,8.3c0,0,4.3,0.9,4.3,4.2c-0.2,2.8-0.7,5.5-1.6,8.1c0,0,3.8,1.9,3.8,4.3s-2.7,9.3-2.7,9.3s-0.2,4.5-7,6.4
                    c-2.4,0.7-18.2-0.2-22.4-3.5S80.1,133,80.1,133s-3.2-5.1-1.6-9.3s-1.6-18.2,1.7-19S95.1,100.6,95.1,100.6z"/>
                  <ellipse className="st3" cx="157.2" cy="554.6" rx="50.7" ry="4"/>
                  <ellipse className="st3" cx="298.9" cy="554.6" rx="50.7" ry="4"/>
                  <polygon className="st3" points="360.5,265.1 356.6,315.8 342.7,316 341.6,292.3 327.3,291.1 328.5,268.6 "/>
                  <path className="st5" d="M328.9,248.9c0,0-19.4,12.6-19.4,19.7s34.3,13,41.5,13s13.2-21,14.4-24.5C368.3,248.6,347,234.4,328.9,248.9z"/>
                  <polygon className="st5" points="166.4,491 165.2,514.9 191.7,516.1 190,491.9 "/>
                  <polygon className="st5" points="300.9,489.6 301.4,517 326.9,514.7 327,489.6 "/>
                  <path className="st7" d="M262.6,89.6c6.6,0.2,12.6-3.8,14.9-10l-28.7,0.5C248.8,80.1,253.3,89.6,262.6,89.6z"/>
                  <path className="st8" d="M284.3,41.3c-3.3-2.2-7.3-2.5-11.2-2.3c-1.6,0-1.6,2.6,0,2.5c3.4-0.1,7,0,9.9,2c0.6,0.4,1.3,0.3,1.7-0.3
                    c0.4-0.6,0.3-1.3-0.3-1.7C284.4,41.4,284.3,41.4,284.3,41.3L284.3,41.3z"/>
                  <path className="st8" d="M249.7,38.1c-3.9-0.3-7.6,1.5-10.9,3.4c-0.6,0.4-0.8,1.1-0.4,1.7s1.1,0.8,1.7,0.4c2.9-1.8,6.1-3.4,9.6-3.1
                    c0.7,0,1.2-0.6,1.3-1.3C251,38.6,250.4,38.1,249.7,38.1z"/>
                  <path className="st9" d="M260.1,58.9c0,0-3.9,7.2-2.8,8.6s5.9,1.9,5.9,1.9"/>
                  <circle className="st2" cx="276.7" cy="50.8" r="2.8"/>
                  <circle className="st2" cx="248.3" cy="50.8" r="2.8"/>
                  <ellipse className="st2" cx="112.8" cy="550.8" rx="7.3" ry="37.3"/>
                  <path className="st10" d="M232.2,554.8c0,0,6.2-16.6,21.7-24.9s48-17.6,48-17.6l27.9,0.9l3.6,37L232.2,554.8z"/>
                  <path className="st2" d="M220.5,303.7c0,0-62-30.4-86.5-8.2s2.8,202.5,2.8,202.5l63.8-5.8l-14-145.2l14,19.9l66.7,2.3
                    c0,0,19.9-1.1,19.9,11.7s-2.5,111.4-2.5,111.4h56.6c0,0,9.1-137.1,9.1-151.1s2-36.1-9.4-42.1c-24.6-12.9-69,5.9-69,5.9L220.5,303.7z"/>
                  <path className="st10" d="M95.8,554.8c0,0,6.2-16.6,21.7-24.9s48-17.6,48-17.6l27.9,0.9l3.6,37L95.8,554.8z"/>
                  <path className="st11" d="M253.3,56c-2.9,1.6-6,2.9-9.3,2.5c-0.7,0-1.2,0.6-1.3,1.3c0,0.7,0.6,1.2,1.3,1.3c3.7,0.4,7.4-1.1,10.6-2.9
                    C256,57.4,254.7,55.2,253.3,56L253.3,56z"/>
                  <path className="st11" d="M264.9,24.8c-2.9,1.6-6,2.9-9.3,2.5c-0.7,0-1.2,0.6-1.3,1.3c0,0.7,0.6,1.2,1.3,1.3c3.7,0.4,7.3-1.1,10.6-2.9
                    C267.6,26.2,266.3,24.1,264.9,24.8L264.9,24.8z"/>
                  <path className="st11" d="M269.2,29.1c-2.9,1.6-6,2.9-9.3,2.5c-0.7,0-1.2,0.6-1.3,1.2c0,0.7,0.6,1.2,1.3,1.3c3.8,0.5,7.4-1.1,10.6-2.9
                    C271.8,30.5,270.6,28.3,269.2,29.1z"/>
                  <path className="st11" d="M269.7,58.1c3.2,1.8,6.8,3.3,10.6,2.9c0.7,0,1.2-0.6,1.3-1.3c0-0.7-0.6-1.2-1.3-1.3c-3.3,0.4-6.4-1-9.3-2.5
                    C269.5,55.2,268.3,57.4,269.7,58.1L269.7,58.1z"/>
                  <path className="st12" d="M224.9,60.2c3.8-20,4.7-19.6,17.1-36.9c16.4,3.3,26.8,1.9,40.5,9.2c14.2,7.7,12.4,21.3,12.4,21.3
                    s5.8-20.5-0.6-31.4C288.6,4.5,206.4-21.1,224.9,60.2z"/>
                  <path className="st5" d="M226.7,52c0,0-4.8-3.8-5.8-1.6s-2.1,15.8,0.8,21.8c2.9,6,8.7-6.5,8.7-6.5L226.7,52z"/>
                  <path className="st5" d="M294.7,52.5c0,0,4.9-3.6,5.8-1.4s1.6,15.8-1.5,21.7s-8.5-6.8-8.5-6.8L294.7,52.5z"/>
                </svg>
              </div>
            </div>

            <div 
              id="caregiver-4" 
              className={
                `reviewer-7
                ${reviewerEntering===7 ? ' speak' : ''}
                ${reviewerExiting===7 ? ' exit' : ''}
                ${[4, 5, 6].includes(reviewerEntering) ? ' sidestep-speak' : ''}
                ${[4, 5, 6].includes(reviewerExiting) ? ' sidestep-exit' : ''}
                `
              }
              onAnimationEnd={handleAnimationEnd}  
            >
              <div className={animationClassNames[4] || ''}>
                <svg 
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" y="0px"
                  viewBox="0 0 210 710"
                  onClick={()=>selectReviewer(7)}
                >
                  <path className="st0" d="M165.73,392.61c1.31-3.92,2.95-8.15,4.5-12.6c1.55-4.45,1.8-2.7,4.5-14.4c0,0,0.66-21.02,4.5-69.28
                    l-13.5-63.88l33.98-9.04c0,0,0.27,41.74,0.9,48.69c0.62,6.95,3.42,26.51,0.21,46.73c-3.21,20.22-6.3,43.19-6.3,43.19
                    s-4.69,14.9-7.2,21.59c-2.7,7.2,0.28,7.53-5.4,13.5c-5.68,5.97-8.26,10.46-12.6,11.7c-6.3,1.8-5.4,0.9-9-0.9
                    c-4.02-2.01-4.12-6.55,0.9-9.9"/>
                  <path className="st0" d="M18.13,222.97c0,0-11.03,54.43-9.8,59.8c1.23,5.37,19.59,40.46,41.03,54.55l2.1-21.19
                    c0,0-7.95-33.46-15.3-39.59l17.7-39.93L18.13,222.97z"/>
                  <path className="st1" d="M130.95,139.12c-5.34-3.77-2.98-28.27-2.98-28.27H85.91c0,0,2.36,24.5-2.98,28.27
                    c-5.34,3.77-10.05,4.08-10.05,4.08s18.18,39.27,35.69,39.27c17.51,0,32.45-39.27,32.45-39.27S136.3,142.89,130.95,139.12z"/>
                  <path className="st0" d="M148.98,82.15c-3.98,9.92-7.05,7.42-7.05,7.42s-1.96,15.29-8.63,20.47c-5.33,4.14-16.02,9.55-20.08,11.53
                    c-1.04,0.52-2.19,0.79-3.37,0.79h-5.83c-1.17,0-2.33-0.27-3.39-0.79c-4.06-1.98-14.75-7.39-20.08-11.53
                    c-6.64-5.18-8.63-20.47-8.63-20.47s-3.6-1.38-7.6-10.38c-0.18-0.41-1.18-0.52-2-5c-0.36-1.97-0.15-8.4,0-9c1.87-8.07,4-3,4-3
                    l1.42-3.68c0-33.81,25.28-36.93,35.73-36.77c2.29,0.04,4.6,0.04,6.91,0c10.44-0.15,35.73,2.97,35.73,36.77l2.15,2.41
                    c0,0,3.9,2.27,3.6,10.55c-0.13,3.54-0.6,5.13-1.26,6.57C149.33,80.88,149.15,81.74,148.98,82.15z"/>
                  <path d="M106.51,8.4c-9.18,0.02-18.2,2.69-25.81,7.82c-1.36,0.92-2.73,1.92-4.1,3.03c-22.27,17.97-13.8,44.69-13.8,44.69l2.89-0.51
                    c1.84,1.49,4.2,15.72,4.2,15.72L74,81.92l-1.13-26.88c5.12-3.49,8.81-13.82,8.81-13.82s26.3,4.45,49.93,0
                    c0,0,3.68,10.34,8.81,13.82l-1.13,26.88l4.09-2.76c0,0,2.36-14.23,4.2-15.72l2.89,0.51c0,0,8.47-26.72-13.8-44.69
                    c-1.37-1.1-2.73-2.11-4.1-3.03c-7.61-5.13-16.63-7.8-25.81-7.82c-0.04,0-0.09,0-0.13,0C106.6,8.4,106.56,8.4,106.51,8.4z"/>
                  <path className="st2" d="M93.21,69.67c0,1.81-1.47,3.27-3.27,3.27c-1.81,0-3.27-1.47-3.27-3.27c0-1.81,1.47-3.27,3.27-3.27
                    C91.74,66.4,93.21,67.86,93.21,69.67z"/>
                  <path d="M78.23,59.93c0.8-0.76,1.54-1.32,2.36-1.89c0.82-0.55,1.67-1.05,2.55-1.5c1.76-0.89,3.66-1.56,5.63-1.93
                    c1.96-0.37,4-0.54,6.01-0.33c1.02,0.04,2,0.26,2.99,0.43c0.99,0.2,1.94,0.53,2.91,0.79c1.18,0.32,1.88,1.55,1.56,2.73
                    c-0.32,1.18-1.55,1.88-2.73,1.56c-0.05-0.01-0.09-0.03-0.14-0.04l-0.28-0.1c-0.78-0.27-1.55-0.62-2.35-0.85
                    c-0.8-0.2-1.6-0.47-2.43-0.55c-1.64-0.31-3.31-0.3-4.98-0.13c-1.67,0.17-3.32,0.61-4.9,1.25c-1.56,0.62-3.14,1.52-4.37,2.5
                    l-0.09,0.07c-0.58,0.46-1.41,0.36-1.87-0.22C77.68,61.18,77.74,60.4,78.23,59.93z"/>
                  <path className="st2" d="M120.13,69.73c0,1.78,1.44,3.21,3.21,3.21c1.78,0,3.21-1.44,3.21-3.21c0-1.78-1.44-3.21-3.21-3.21
                    C121.57,66.52,120.13,67.96,120.13,69.73z"/>
                  <path d="M135.65,59.93c-0.8-0.76-1.54-1.32-2.36-1.89c-0.82-0.55-1.67-1.05-2.55-1.5c-1.76-0.89-3.66-1.56-5.63-1.93
                    c-1.96-0.37-4-0.54-6.01-0.33c-1.02,0.04-2,0.26-2.99,0.43c-0.99,0.2-1.94,0.53-2.91,0.79c-1.18,0.32-1.88,1.55-1.56,2.73
                    c0.32,1.18,1.55,1.88,2.73,1.56c0.05-0.01,0.09-0.03,0.14-0.04l0.28-0.1c0.78-0.27,1.55-0.62,2.35-0.85c0.8-0.2,1.6-0.47,2.43-0.55
                    c1.64-0.31,3.31-0.3,4.98-0.13c1.67,0.17,3.32,0.61,4.9,1.25c1.56,0.62,3.14,1.52,4.37,2.5l0.09,0.07
                    c0.58,0.46,1.41,0.36,1.87-0.22C136.2,61.18,136.14,60.4,135.65,59.93z"/>
                  <path className="st2" d="M106.88,89.66c-0.15,0-0.29-0.03-0.42-0.1l-6-2.98c-0.47-0.23-0.66-0.81-0.43-1.28
                    c0.23-0.47,0.81-0.66,1.28-0.43l5.58,2.78l5.7-2.78c0.47-0.23,1.04-0.03,1.27,0.44c0.23,0.47,0.03,1.04-0.44,1.27l-6.13,2.98
                    C107.16,89.62,107.02,89.66,106.88,89.66z"/>
                  <path className="st3" d="M117.33,658.13c0,0-10.48,21.55-12.81,25.62c-2.33,4.07-2.13,17.85,17.47,17.85s23.87-17.66,26.98-21.74
                    c3.11-4.08,14.56-10.81,15.14-20.64C164.68,649.39,117.33,658.13,117.33,658.13z"/>
                  <path className="st3" d="M46.72,658.32c0,0-7.02,7.57-15.36,9.12c-8.34,1.55-24.26,0.58-23.68,14.17c0,0,26.78,2.91,43.08,1.94
                    c16.3-0.97,39.2-2.72,43.47-1.94c4.27,0.78,1.94-21.43-0.78-25.17C90.74,652.69,46.72,658.32,46.72,658.32z"/>
                  <path className="st4" d="M42.63,355.41c0,0-1.29,55.69-1.48,71.65c-0.19,15.96-3.74,58.52-2.67,72c1.06,13.48,6.03,152.36,6.03,152.36
                    s-2.68,6.31-3.11,10.86c0,0,13.05-1.36,22.62,0c9.58,1.36,21.99,5.56,31.92,0.8c0,0-1.42-6.14-1.77-11.66
                    c-0.35-5.52-2.48-11.2-2.48-11.2s4.61-57.46,4.26-80.87c-0.35-23.41-7.09-61.36-6.74-70.58c0.35-9.22,22.35-87.25,22.35-87.25
                    s6.38,88.32,7.8,100.02c1.42,11.7-0.71,136.95-2.48,146.15c-1.77,9.2-2.84,15.38-2.84,15.38s11.35-12.09,41.85,2.21
                    c0,0,6.74,3.62,10.64-2.21c3.9-5.83-2.84-45.91,0-89.89c2.84-43.98,2.85-61.72,1.25-75.9c0,0,9.42-101.73,6.92-140.25L42.63,355.41
                    z"/>
                  <path className="st5" d="M106.94,188.73c0,0,27.38-28.32,37.21-45.8c-2.93-1.73-5.6-3.35-7.56-4.55c-1.74-1.07-4-0.42-4.93,1.39
                    c-7.6,14.68-24.72,31.12-24.72,31.12c-10.94-8.63-20.71-24.59-24.58-31.38c-0.98-1.72-3.18-2.29-4.87-1.26
                    c-1.78,1.09-4.2,2.56-6.88,4.15C76.07,151.96,92.1,178.59,106.94,188.73z"/>
                  <path className="st5" d="M167.28,295.21c2.04-4.46-0.56-8.18-1.67-12.45c-1.11-4.27,0.19-44.04,0.19-44.04
                    c18.4-0.19,35.5-9.66,35.5-9.66s-1.86-3.72-6.32-35.31c-4.46-31.59-24.16-39.03-30.66-40.51c-3.6-0.82-12.73-5.92-20.16-10.3
                    c-9.83,17.48-37.21,45.8-37.21,45.8c-14.84-10.13-30.87-36.77-36.32-46.32c-7.58,4.49-17.3,9.97-21.05,10.83
                    c-6.5,1.49-26.2,8.92-30.66,40.51s-6.32,35.31-6.32,35.31s17.1,9.48,35.5,9.66c0,0,1.3,39.77,0.19,44.04
                    c-1.12,4.27-3.72,7.99-1.67,12.45c2.04,4.46-2.42,27.5-5.2,46.27c-2.79,18.77-3.72,27.47-3.72,27.47s40.7,4.86,65.42,6.16
                    c24.72,1.3,73.1-6.16,73.1-6.16s-0.93-8.7-3.72-27.47S165.23,299.67,167.28,295.21z"/>
                  <path className="st6" d="M107.33,109.19c7.2,0.2,13.8-4.2,16.3-11l-31.4,0.5C92.23,98.69,97.03,109.19,107.33,109.19z"/>
                </svg>
              </div>
            </div>

            <div 
              id="patient-4" 
              className={
                `reviewer-8
                ${reviewerEntering===8 ? ' speak' : ''}
                ${reviewerExiting===8 ? ' exit' : ''}
                ${[4, 5, 6, 7].includes(reviewerEntering) ? ' sidestep-speak' : ''}
                ${[4, 5, 6, 7].includes(reviewerExiting) ? ' sidestep-exit' : ''}
                `
              }
              onAnimationEnd={handleAnimationEnd}
            >
              <div className={animationClassNames[4] || ''}>
                <svg 
                  version="1.1" 
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  x="0px" y="0px"
                  viewBox="0 0 250 700"
                  onClick={()=>selectReviewer(8)}
                >
                  <path className="st0" d="M121.34,694.83c0,0,3.01-10.19,12.04-15.82c9.03-5.63,28.15-12.43,28.15-12.43l16.79-0.58l3.59,22.04
                    L121.34,694.83z"/>
                  <path className="st0" d="M57.18,691.24c0,0,3.69-10,13.01-14.95c9.32-4.95,28.83-10.58,28.83-10.58l16.79,0.49l2.14,22.23L57.18,691.24
                    z"/>
                  <path className="st1" d="M175.68,567.04c2.1,33.86,2.88,72.62,3.48,98.91c-12.19,9.46-23.79,2.18-23.79,2.18s-16.76-55.29-18.96-104.95
                    C156.57,559.56,175.68,567.04,175.68,567.04z"/>
                  <path className="st1" d="M179.16,666.15c-2.35,8.2-27.26,9.56-32.01,8.2c-4.75-1.36,5.31-4.57,8.22-6.22
                    C157.66,666.84,179.16,666.15,179.16,666.15z"/>
                  <path className="st1" d="M124.29,576.62c-2.77,31.88-6.13,64.34-7.63,89.33c-12.19,9.46-23.79,2.18-23.79,2.18s-2.38-39.33-8.22-91.51
                    C104.8,572.99,124.29,576.62,124.29,576.62z"/>
                  <path className="st1" d="M116.65,666.15c-2.7,8.2-27.26,9.56-32.01,8.2c-4.75-1.36,5.31-4.57,8.22-6.22
                    C95.15,666.84,116.65,666.15,116.65,666.15z"/>
                  <path className="st2" d="M223.51,599.3c-8.66,3.41-27.16,7.47-50.04,10.32c-40.74,5.06-95.39,6.27-133.25-6.91
                    c0.87-71.66,22.62-293.32,22.62-293.32l102.36-3.42l17.7-0.79C190.2,326.25,223.51,491.22,223.51,599.3z"/>
                  <path className="st3" d="M223.44,599.63c-8.66,3.41-27.16,7.47-50.04,10.32l-0.01-0.01c-3.38-104.54-27.51-289.81-27.51-289.81
                    l19.25-13.83l17.7-0.79C190.14,326.59,223.44,491.56,223.44,599.63z"/>
                  <path className="st4" d="M11.28,256.16c-10.69-5.02-7.01-17.54-3.41-26.47c0,0,12.34-34.07,17.78-48.15
                    c8.32-21.5,24.47-28.24,29.7-26.47c6.68,2.28,4.19,24.19-4.23,44.72l-14.97,49.08C34.93,252.45,24.82,262.51,11.28,256.16z"/>
                  <path className="st1" d="M48.63,313.62L9.4,255c-2.49-4.31-8.38-15.72,5.1-18.06c13.16-2.28,19.3,5.78,21.8,10.1l27.55,63.02
                    C66.35,314.37,50.56,316.98,48.63,313.62z"/>
                  <path className="st4" d="M14.15,235.74c-13.48,2.33-10.76,13.29-8.27,17.61c0,0,34.92,53.61,37.58,54.86
                    c4.58,2.14,16.92-0.76,19.77-4.11c1.37-1.61-26.87-61.34-26.87-61.34C33.86,238.44,27.31,233.46,14.15,235.74z"/>
                  <path className="st1" d="M74.61,324.9l-4.37-6.81l1.2,0.64c1.19,0.64,2.96,0.53,3.95-0.25c0.97-0.77,0.8-1.9-0.39-2.54l-6.65-3.54
                    l-2.25-1.99c-2.21-3.46-6.28-3.84-11.56-2.43c-5.27,1.4-9.02,2.98-6.8,6.44l3.39,6.04l0,0l4.63,7.22c1.31,1.54,1.51,1.4,2.59,2.07
                    c1.33,0.82,3.08,1.22,4.39,1.54c0.93,0.23,5.48,0.82,6.83,0.8c2.21-0.02,3.72-1.06,3.72-1.06c0.83-0.58,1.04-0.89,1.56-2.12
                    C75.35,327.75,75.11,325.69,74.61,324.9z"/>
                  <path className="st5" d="M152.45,150.92l-1.08,162.16l-63.32-1.67l-14.98-0.22l-3.21-34.5l-13.95-64.33l16.26-65.27
                    c20.79-4.56,59.14,0.53,59.14,0.53L152.45,150.92z"/>
                  <path className="st1" d="M88.71,12.6c-15.91,2.67-37.1,22.8-30.14,62.46c6.97,39.76,28.16,48.38,38.51,47.47
                    c12.52-1.1,40.25-8.51,47.35-42.5C151.54,45.95,143.75,3.39,88.71,12.6z"/>
                  <path className="st1" d="M91.21,115.58c0,0-1.55,29.74-11.24,29.74c5.27,11.37,27.22,34.21,51.35,2.31c-8.6-9.99-9.2-35.04-9.2-35.04
                    L91.21,115.58z"/>
                  <path className="st4" d="M66.28,229.14c2.4,23.19,11.11,97.17,11.11,97.17l-16.31-8.46c0,0-2.47-45.91-11.06-71.21
                    c-8.59-25.31-11.27-22.75-13.72-44.33c1.47-11.28,2.02-15.82,2.02-15.82s1.47-5.89,4.23-15.08c2.08-6.93-0.55-4.72,6.81-16
                    c14.9-5.64,17.84-7.17,32.76-10.61c0,0-26.28,38.06-17.79,70.43C65.2,218.6,65.9,225.53,66.28,229.14z"/>
                  <path className="st4" d="M246.14,252.3c-0.64,21.21-16.58,53.23-49.57,67.76c-1.05-3.37-2.01-6.16-2.88-8.43v0
                    c-2.46-6.48-4.04-8.83-4.04-8.83s1.6-3.91,3.92-9.49c5.36-12.91,16.44-43.26,18.5-46.16c-14.64-13.2-36.88-41.82-37.82-43.55
                    c0.24,0.47,1.78,3.6,3.62,9.93c4.15,14.21,9.84,44.56,5.84,97.22c-0.25,3.26-4.43,6.66-10.53,9.39
                    c-10.71,4.81-27.34,7.6-38.96,4.19c5.41-52.51-9.92-74.35-4.86-112.36c5.12-38.5,1.95-64.34,1.95-64.34
                    c15.32-0.42,38.49-3.77,71.78,31.87C236.39,215.14,246.74,232.57,246.14,252.3z"/>
                  <path className="st6" d="M161.7,63.66c-0.97,30.5-31.17,55.98-39.81,50.54c18.47-9.24,15.61-40.1,10.16-48.89
                    C104.03,62.51,87.1,40.22,86.87,22c-0.05-3.92,7.04-15.91,17.95-16.65c33.18-2.27,51.5,16.27,56.43,50.2
                    C161.65,58.28,161.78,60.99,161.7,63.66z"/>
                  <path className="st6" d="M89.94,30.53c-3.36,7.17-23.56,28.34-30.12,30.4c-1.33,8.45-1.59,21.01,6.01,37.94
                    c-7.99-4.85-14.54-17.74-15.86-33.14c-0.9-10.46,0.6-22.07,5.69-33.11C68.24,5.32,96.28,6.45,97.49,7.67
                    C98.7,8.89,89.94,30.53,89.94,30.53z"/>
                  <path d="M108.07,73.82c0.27,2.35-1.42,4.47-3.77,4.74c-2.35,0.27-4.47-1.42-4.74-3.77c-0.27-2.35,1.42-4.47,3.77-4.74
                    C105.68,69.79,107.8,71.47,108.07,73.82z"/>
                  <path d="M77.41,79.03c0.27,2.35-1.42,4.47-3.77,4.74c-2.35,0.27-4.47-1.42-4.74-3.77c-0.27-2.35,1.42-4.47,3.77-4.74
                    C75.02,75,77.14,76.68,77.41,79.03z"/>
                  <path className="st6" d="M111.89,64.31c-0.47,0-0.95-0.17-1.33-0.51c-4.07-3.68-10.55-1.82-10.62-1.8c-1.05,0.31-2.15-0.29-2.46-1.34
                    c-0.31-1.05,0.29-2.15,1.34-2.46c0.35-0.1,8.7-2.5,14.4,2.66c0.81,0.73,0.87,1.99,0.14,2.8C112.97,64.09,112.43,64.31,111.89,64.31
                    z"/>
                  <path className="st6" d="M64.1,70c-0.37,0-0.74-0.11-1.07-0.35c-0.81-0.59-0.98-1.72-0.39-2.53c3.73-5.1,11.19-5.23,11.5-5.23
                    c0.99-0.01,1.82,0.79,1.82,1.79c0.01,1-0.79,1.81-1.79,1.82c-0.1,0-5.98,0.14-8.62,3.75C65.21,69.75,64.66,70,64.1,70z"/>
                  <path className="st7" d="M108.04,98.96c1.03-0.34,2.08,0.44,2.08,1.52c-0.02,3.88-3.38,7.87-8.4,9.54c-5.04,1.68-10.14,0.48-12.47-2.64
                    c-0.64-0.86-0.23-2.09,0.78-2.43c0,0,4.34-0.21,9.33-1.87C104.48,101.38,108.04,98.96,108.04,98.96z"/>
                  <path className="st1" d="M144.27,78.53c-2.56,5.62-8.34,8.49-12.92,6.41c-4.57-2.08-6.21-8.33-3.65-13.95
                    c2.56-5.62,8.08-9.36,12.66-7.27C144.93,65.79,146.83,72.91,144.27,78.53z"/>
                  <path className="st1" d="M196.57,320.06c-4.84,5.78-8.42,15.16-26.49,14.98c-18.07-0.18-11.33-15.41,0.71-17.47s10.12-2.73,11.61-6.08
                    c1.6-3.59,1.91-8.59,1.91-8.59s-2.31-9.9,2.45-5.84c1.47,1.25,3.28,4.1,4.04,6.42C193.18,310.78,196.57,320.06,196.57,320.06z"/>
                  <path className="st8" d="M90.07,84.29c0,0-4.08,7.67-2.91,9.13c1.16,1.46,6.12,2.04,6.12,2.04"/>
                </svg>
              </div>
            </div>

          </div>

          <div className="reviews-copy">
            {reviews.items.map((item, index) => {
              const i = index+1;
              return (
                <div 
                  key={'review-'+index} 
                  className={
                    `review-copy review-copy-${i}
                    ${reviewerEntering===i ? ' speak' : ''}
                    ${reviewerExiting===i ? ' exit' : ''}
                    `
                  }
                >
                  <div className="ellipse-container">
                    <div>
                      <svg 
                        className="ellipse-svg"
                        version="1.1" 
                        xmlns="http://www.w3.org/2000/svg" 
                        xmlnsXlink="http://www.w3.org/1999/xlink" 
                        x="0px" y="0px"
                        viewBox="0 0 1210 560"
                      >
                        <defs>
                          <ellipse id={'SVGID_20_'+i} cx="605" cy="280" rx="605" ry="280"/>
                        </defs>

                        <clipPath id={'SVGID_21_'+i}>
                          <use className="scale" xlinkHref={'#SVGID_20_'+i} style={{overflow: "visible"}} />
                        </clipPath>

                        {item.type==='Caregiver' &&
                          <polygon className="st0 scale" points="143.86,237.32 71.35,3.85 309.79,57.79 "/>
                        }
                        <ellipse className="st0 scale" cx="605" cy="280" rx="605" ry="280"/>
                      </svg>
                      <svg 
                        className="stars-svg"
                        version="1.1" 
                        xmlns="http://www.w3.org/2000/svg" 
                        xmlnsXlink="http://www.w3.org/1999/xlink" 
                        x="0px" y="0px"
                        viewBox="0 0 1210 560"
                      >
                        <g className={'clipped-star-groups clipped-star-group-'+i}>
                          <g 
                            className={
                              `animated-star-groups animated-star-group-${i}
                              ${reviewerEntering===i ? ' enter' : ''}
                              ${reviewerExiting===i ? ' exit' : ''}
                              `
                            }
                          >
                            <g id="star-1">
                              <linearGradient id={'SVGID_LG1_'+i} gradientUnits="userSpaceOnUse" x1="-7.5074" y1="178.5968" x2="-133.5195" y2="487.6266" gradientTransform="matrix(0.8418 -0.5398 0.5398 0.8418 -62.3937 53.4669)">
                                <stop offset="0" style={{stopColor: '#FE7062'}}/>
                                <stop offset="1" style={{stopColor: '#FF928E'}}/>
                              </linearGradient>
                              <path className={'st1 st1-'+i} d="M145.39,493.43l-28.66,20.43c-2.23,1.59-3.53,4.17-3.49,6.91l0.58,35.19c0.11,6.89-7.72,10.92-13.26,6.82
                                l-28.28-20.94c-2.2-1.63-5.06-2.07-7.65-1.18l-33.28,11.43c-6.52,2.24-12.77-3.97-10.59-10.5l11.17-33.37
                                c0.87-2.6,0.41-5.45-1.24-7.64L9.55,472.45c-4.14-5.51-0.17-13.38,6.72-13.31l35.19,0.32c2.74,0.02,5.31-1.3,6.88-3.54l20.21-28.81
                                c3.96-5.64,12.67-4.3,14.74,2.28l10.57,33.57c0.82,2.61,2.88,4.65,5.49,5.45L143,478.71C149.59,480.73,151,489.43,145.39,493.43z"
                                />
                              <path className="st0" d="M67.8,534.13c-1.84-0.01-3.66,0.29-5.4,0.89l-21.74,7.46c-2.29,0.78-4.77,0.21-6.48-1.49
                                c-1.71-1.7-2.3-4.18-1.54-6.47l7.3-21.79c1.71-5.11,0.8-10.72-2.44-15.03l-13.82-18.37c-1.45-1.93-1.68-4.47-0.59-6.62
                                c1.09-2.16,3.27-3.48,5.68-3.46l11.74,0.11l-0.02,1.79l-11.74-0.1c-1.76-0.02-3.27,0.92-4.07,2.48c-0.79,1.57-0.63,3.34,0.42,4.75
                                l13.82,18.37c3.59,4.77,4.6,11.01,2.71,16.67l-7.3,21.79c-0.56,1.66-0.15,3.4,1.1,4.63c1.25,1.24,2.98,1.64,4.64,1.06l21.74-7.46
                                c1.93-0.66,3.95-0.99,5.99-0.98L67.8,534.13z"/>
                              <path className="st0" d="M105.06,549.21c-0.59,0.98-1.45,1.79-2.52,2.34c-2.15,1.1-4.69,0.9-6.63-0.54l-14.13-10.46l1.06-1.44
                                l14.13,10.46c1.41,1.05,3.19,1.19,4.75,0.39c1.56-0.8,2.48-2.33,2.45-4.09l-0.38-22.98c-0.1-5.9,2.8-11.65,7.61-15.08l18.71-13.34
                                c1.43-1.02,2.11-2.67,1.83-4.4c-0.28-1.73-1.45-3.08-3.13-3.59l-21.97-6.74c-5.71-1.75-10.19-6.2-11.98-11.89l-6.91-21.92
                                c-0.53-1.68-1.88-2.84-3.61-3.1c-1.74-0.27-3.37,0.43-4.38,1.87l-13.2,18.82c-1.37,1.95-3.07,3.58-5.08,4.86l-0.96-1.51
                                c1.81-1.15,3.35-2.63,4.57-4.38l13.2-18.82c1.39-1.98,3.73-2.98,6.12-2.61c2.39,0.37,4.32,2.03,5.05,4.33l6.91,21.92
                                c1.62,5.14,5.66,9.14,10.8,10.72l21.98,6.74c2.31,0.71,3.98,2.63,4.37,5.01c0.39,2.38-0.6,4.74-2.56,6.14l-18.71,13.34
                                c-4.34,3.09-6.95,8.27-6.86,13.59l0.38,22.98C105.98,547.06,105.66,548.22,105.06,549.21z"/>
                            </g>
                            <g id="star-2">
                              <linearGradient id={'SVGID_LG2_'+i} gradientUnits="userSpaceOnUse" x1="96.1605" y1="-81.9857" x2="-116.8604" y2="338.0558" gradientTransform="matrix(0.7361 -0.6769 0.6769 0.7361 57.462 103.3688)">
                                <stop offset="0" style={{stopColor: '#FE7062'}}/>
                                <stop offset="1" style={{stopColor: '#FF928E'}}/>
                              </linearGradient>
                              <path className={'st2 st2-'+i} d="M254.06,356.9l-37.81,13.19c-2.94,1.03-5.19,3.43-6.01,6.44l-10.57,38.63c-2.07,7.57-11.92,9.47-16.66,3.23
                                l-24.23-31.89c-1.88-2.48-4.87-3.87-7.98-3.73l-40.01,1.89c-7.83,0.37-12.69-8.41-8.22-14.85l22.84-32.9
                                c1.78-2.56,2.18-5.83,1.08-8.74l-14.16-37.46c-2.77-7.34,4.07-14.67,11.58-12.41l38.35,11.55c2.98,0.9,6.22,0.27,8.65-1.68
                                l31.26-25.04c6.12-4.9,15.21-0.66,15.38,7.18l0.86,40.04c0.07,3.11,1.66,6,4.27,7.71l33.47,21.99
                                C262.7,344.37,261.47,354.32,254.06,356.9z"/>
                              <path className="st0" d="M156.54,374.4c-2.01-0.6-4.09-0.85-6.18-0.75l-26.13,1.23c-2.75,0.13-5.28-1.28-6.61-3.69
                                c-1.33-2.4-1.18-5.3,0.38-7.56l14.91-21.49c3.49-5.03,4.29-11.46,2.12-17.19l-9.25-24.47c-0.97-2.57-0.41-5.42,1.47-7.43
                                c1.88-2.01,4.68-2.76,7.31-1.97l12.8,3.85l-0.59,1.95l-12.8-3.85c-1.92-0.58-3.87-0.04-5.24,1.41c-1.36,1.46-1.76,3.45-1.05,5.32
                                l9.25,24.47c2.4,6.36,1.52,13.49-2.35,19.07l-14.91,21.49c-1.14,1.64-1.24,3.66-0.27,5.41c0.97,1.75,2.74,2.74,4.73,2.64
                                l26.13-1.23c2.32-0.11,4.63,0.17,6.85,0.83L156.54,374.4z"/>
                              <path className="st0" d="M192.45,402.74c-0.96,0.88-2.15,1.49-3.49,1.76c-2.7,0.52-5.41-0.51-7.07-2.7l-12.11-15.93l1.62-1.23
                                l12.11,15.93c1.21,1.59,3.1,2.31,5.06,1.93c1.96-0.38,3.45-1.76,3.98-3.69l6.9-25.23c1.77-6.48,6.77-11.84,13.11-14.05l24.7-8.62
                                c1.89-0.66,3.16-2.24,3.4-4.22c0.24-1.98-0.61-3.82-2.28-4.92l-21.86-14.36c-5.68-3.73-9.16-10.02-9.3-16.81l-0.57-26.15
                                c-0.04-2-1.15-3.7-2.96-4.54c-1.81-0.85-3.82-0.6-5.38,0.65l-20.41,16.36c-2.11,1.69-4.5,2.94-7.09,3.7l-0.57-1.95
                                c2.34-0.69,4.49-1.81,6.39-3.33l20.41-16.36c2.14-1.72,5.02-2.07,7.51-0.9c2.49,1.16,4.08,3.59,4.13,6.34l0.57,26.15
                                c0.13,6.13,3.27,11.79,8.39,15.15l21.86,14.36c2.3,1.51,3.52,4.14,3.18,6.87c-0.34,2.73-2.16,4.99-4.75,5.89l-24.7,8.62
                                c-5.72,2-10.23,6.82-11.82,12.67l-6.9,25.23C194.14,400.69,193.42,401.85,192.45,402.74z"/>
                            </g>
                            <g id="star-3">
                              <linearGradient id={'SVGID_LG3_'+i} gradientUnits="userSpaceOnUse" x1="368.835" y1="-53.1684" x2="152.813" y2="297.8673" gradientTransform="matrix(0.9299 -0.3678 0.3678 0.9299 83.2191 116.1553)">
                                <stop offset="0" style={{stopColor: '#FE7062'}}/>
                                <stop offset="1" style={{stopColor: '#FF928E'}}/>
                              </linearGradient>
                              <path className={'st3 st3-'+i}  d="M447.48,273.54l-54.78-1.7c-4.26-0.13-8.31,1.84-10.84,5.27l-32.46,44.16c-6.36,8.65-19.87,6.25-22.87-4.05
                                l-15.31-52.62c-1.19-4.09-4.32-7.34-8.36-8.68l-52.03-17.22c-10.19-3.37-12.09-16.96-3.21-23l45.32-30.82
                                c3.52-2.4,5.64-6.37,5.67-10.64l0.3-54.8c0.06-10.73,12.4-16.74,20.88-10.16l43.31,33.58c3.37,2.61,7.81,3.4,11.87,2.1l52.21-16.65
                                c10.23-3.26,19.75,6.62,16.12,16.72l-18.55,51.57c-1.44,4.01-0.82,8.47,1.67,11.94l31.97,44.51
                                C464.66,261.77,458.2,273.88,447.48,273.54z"/>
                              <path className="st0" d="M314.33,248.02c-2.27-1.75-4.8-3.09-7.53-3.99l-33.97-11.25c-3.57-1.18-6.11-4.23-6.63-7.96
                                c-0.52-3.72,1.09-7.35,4.2-9.47l29.59-20.13c6.93-4.71,11.1-12.53,11.15-20.92l0.2-35.79c0.02-3.76,2.13-7.12,5.52-8.77
                                c3.39-1.64,7.33-1.23,10.3,1.07l14.45,11.2l-1.7,2.2l-14.45-11.2c-2.16-1.68-4.93-1.95-7.38-0.77c-2.46,1.2-3.94,3.54-3.95,6.28
                                l-0.2,35.79c-0.05,9.3-4.68,17.98-12.37,23.21l-29.59,20.13c-2.26,1.54-3.39,4.07-3.01,6.78c0.38,2.71,2.15,4.84,4.75,5.7
                                l33.97,11.25c3.02,1,5.83,2.49,8.35,4.43L314.33,248.02z"/>
                              <path className="st0" d="M346.29,301.86c-1.65,0.65-3.49,0.85-5.32,0.53c-3.7-0.66-6.66-3.31-7.71-6.92l-7.65-26.29l2.67-0.78
                                l7.65,26.29c0.76,2.63,2.83,4.48,5.52,4.96c2.69,0.48,5.27-0.55,6.89-2.76l21.2-28.83c5.44-7.41,14.45-11.8,23.64-11.51l35.77,1.11
                                c2.73,0.08,5.13-1.31,6.42-3.72c1.29-2.41,1.1-5.18-0.49-7.4L414,217.46c-5.42-7.56-6.78-17.29-3.63-26.04l12.11-33.68
                                c0.93-2.58,0.35-5.29-1.55-7.26c-1.9-1.97-4.59-2.65-7.19-1.82l-34.1,10.87c-3.53,1.12-7.19,1.54-10.88,1.24l0.23-2.77
                                c3.33,0.27,6.63-0.1,9.8-1.12l34.1-10.87c3.58-1.14,7.43-0.17,10.04,2.54c2.61,2.71,3.44,6.59,2.17,10.13l-12.11,33.68
                                c-2.84,7.89-1.61,16.66,3.28,23.47l20.88,29.07c2.19,3.05,2.46,7.01,0.69,10.33c-1.77,3.32-5.2,5.31-8.96,5.19l-35.77-1.11
                                c-8.29-0.26-16.41,3.69-21.31,10.38l-21.2,28.83C349.46,300.06,347.96,301.19,346.29,301.86z"/>
                            </g>
                            <g id="star-4">
                              <linearGradient id={'SVGID_LG4_'+i} gradientUnits="userSpaceOnUse" x1="722.9494" y1="-128.763" x2="506.9277" y2="204.2705" gradientTransform="matrix(0.9944 -0.106 0.106 0.9944 27.7869 59.3932)">
                                <stop offset="0" style={{stopColor: '#FE7062'}}/>
                                <stop offset="1" style={{stopColor: '#FF928E'}}/>
                              </linearGradient>
                              <path className={'st4 st4-'+i}  d="M662.85,218.34l-53.23-16.55c-4.14-1.29-8.65-0.46-12.06,2.23l-43.82,34.47c-8.58,6.75-21.18,0.73-21.31-10.19
                                l-0.71-55.74c-0.05-4.33-2.24-8.37-5.84-10.78l-46.32-31.02c-9.07-6.07-7.24-19.91,3.1-23.42l52.8-17.9
                                c4.11-1.39,7.26-4.71,8.45-8.89l15.19-53.64c2.97-10.5,16.7-13.04,23.23-4.29l33.33,44.68c2.59,3.47,6.73,5.45,11.06,5.29
                                l55.71-2.13c10.91-0.42,17.56,11.86,11.26,20.77l-32.19,45.51c-2.5,3.54-3.11,8.08-1.61,12.15l19.24,52.32
                                C682.89,211.46,673.27,221.58,662.85,218.34z"/>
                              <path className="st0" d="M539.26,157.14c-1.75-2.33-3.87-4.34-6.29-5.96l-30.25-20.26c-3.18-2.13-4.84-5.81-4.34-9.6
                                c0.5-3.79,3.07-6.91,6.69-8.14l34.48-11.69c8.08-2.74,14.29-9.27,16.61-17.48l9.92-35.03c1.04-3.68,4.03-6.4,7.79-7.09
                                c3.77-0.69,7.52,0.78,9.81,3.85l11.12,14.91l-2.27,1.69l-11.12-14.91c-1.66-2.23-4.3-3.25-7.03-2.76c-2.74,0.51-4.82,2.4-5.58,5.08
                                l-9.92,35.03c-2.58,9.1-9.47,16.35-18.43,19.39l-34.48,11.69c-2.63,0.89-4.42,3.07-4.79,5.83c-0.37,2.76,0.8,5.33,3.11,6.88
                                l30.25,20.26c2.69,1.8,5.04,4.02,6.98,6.61L539.26,157.14z"/>
                              <path className="st0" d="M555.96,218.6c-1.8,0.19-3.65-0.11-5.36-0.93c-3.45-1.65-5.63-5.05-5.68-8.88l-0.35-27.85l2.83-0.03
                                l0.35,27.85c0.03,2.78,1.55,5.16,4.06,6.36c2.51,1.2,5.32,0.89,7.5-0.83l28.61-22.51c7.35-5.78,17.37-7.64,26.31-4.86L649,197.73
                                c2.66,0.82,5.39,0.11,7.3-1.9c1.92-2.02,2.49-4.78,1.53-7.39l-12.57-34.17c-3.26-8.88-1.95-18.79,3.51-26.51l21.02-29.73
                                c1.61-2.27,1.78-5.09,0.45-7.53c-1.33-2.45-3.78-3.84-6.56-3.73l-36.38,1.39c-3.76,0.14-7.46-0.44-11-1.74l0.98-2.66
                                c3.19,1.17,6.52,1.7,9.92,1.57l36.38-1.39c3.82-0.15,7.33,1.85,9.15,5.21c1.82,3.36,1.58,7.4-0.63,10.52l-21.02,29.73
                                c-4.93,6.96-6.11,15.9-3.17,23.9l12.57,34.17c1.32,3.59,0.5,7.54-2.13,10.32c-2.64,2.77-6.54,3.79-10.2,2.66l-34.76-10.81
                                c-8.06-2.51-17.09-0.84-23.71,4.38l-28.61,22.51C559.55,217.7,557.78,218.4,555.96,218.6z"/>
                            </g>
                            <g id="star-5">
                              <linearGradient id={'SVGID_LG5_'+i} gradientUnits="userSpaceOnUse" x1="1036.0378" y1="-174.1431" x2="760.01" y2="230.8977" gradientTransform="matrix(0.9962 -0.0876 0.0876 0.9962 21.9316 76.1428)">
                                <stop offset="0" style={{stopColor: '#FE7062'}}/>
                                <stop offset="1" style={{stopColor: '#FF928E'}}/>
                              </linearGradient>
                              <path className={'st5 st5-'+i}  d="M905.97,290.36l-55.36-45.52c-4.31-3.54-10.08-4.73-15.43-3.19l-68.89,19.8c-13.49,3.88-25.57-9.4-20.44-22.46
                                l26.18-66.72c2.04-5.19,1.39-11.05-1.73-15.67l-40.12-59.4c-7.86-11.63,1.04-27.22,15.05-26.38l71.55,4.29
                                c5.56,0.33,10.94-2.09,14.36-6.49l44.09-56.51c8.63-11.07,26.21-7.43,29.74,6.16L923,87.63c1.4,5.39,5.37,9.75,10.61,11.66
                                l67.37,24.47c13.19,4.79,15.16,22.63,3.34,30.19l-60.4,38.59c-4.7,3-7.62,8.12-7.81,13.69l-2.46,71.63
                                C933.17,291.89,916.81,299.27,905.97,290.36z"/>
                              <path className="st0" d="M788.47,157.57c-0.96-3.63-2.51-7.04-4.61-10.14l-26.2-38.79c-2.75-4.08-2.95-9.26-0.51-13.54
                                c2.44-4.27,7-6.74,11.91-6.45l46.73,2.8c10.95,0.66,21.51-4.11,28.26-12.76l28.8-36.91c3.03-3.88,7.9-5.67,12.71-4.67
                                c4.82,1,8.58,4.58,9.82,9.34l6.02,23.15l-3.52,0.92l-6.02-23.15c-0.9-3.47-3.55-5.95-7.03-6.69c-3.5-0.72-6.91,0.53-9.11,3.35
                                l-28.8,36.91c-7.49,9.59-19.2,14.88-31.34,14.16l-46.73-2.8c-3.57-0.21-6.76,1.51-8.53,4.62c-1.77,3.11-1.64,6.73,0.37,9.7
                                l26.2,38.79c2.33,3.44,4.05,7.23,5.11,11.25L788.47,157.57z"/>
                              <path className="st0" d="M778.56,238.85c-2.23-0.64-4.29-1.9-5.93-3.71c-3.31-3.64-4.25-8.74-2.45-13.32l13.08-33.34l3.39,1.33
                                l-13.08,33.34c-1.31,3.33-0.65,6.9,1.76,9.54c2.41,2.65,5.9,3.64,9.34,2.64l44.98-12.93c11.55-3.32,24.39-0.67,33.68,6.97
                                l36.16,29.73c2.77,2.27,6.36,2.74,9.62,1.28c3.26-1.47,5.28-4.49,5.4-8.06l1.61-46.79c0.42-12.16,6.79-23.32,17.04-29.87
                                l39.45-25.21c3.02-1.93,4.58-5.2,4.19-8.75c-0.39-3.56-2.64-6.41-6-7.63l-44-15.98c-4.55-1.65-8.67-4.14-12.25-7.4l2.45-2.69
                                c3.23,2.94,6.94,5.18,11.05,6.67l44,15.98c4.62,1.68,7.83,5.76,8.37,10.65c0.54,4.89-1.7,9.57-5.85,12.22l-39.45,25.21
                                c-9.24,5.9-14.98,15.97-15.36,26.93l-1.6,46.79c-0.17,4.92-3.06,9.23-7.54,11.25c-4.48,2.02-9.63,1.34-13.43-1.78l-36.16-29.73
                                c-8.38-6.89-19.95-9.28-30.36-6.28l-44.99,12.93C783.28,239.52,780.83,239.5,778.56,238.85z"/>
                            </g>
                          </g>
                        </g>

                      </svg>
                    </div>

                  </div>
                  <div className="text-inside-ellipse">
                    <div>
                      <div>
                        <div className="open-quote"></div>
                        <div className="close-quote"></div>
                        <span>
                          {ReactHtmlParser(item.copy)}

                          {language==='en' && 
                            <div className="text-initials">
                              <div>&mdash; {ReactHtmlParser(item.initials)}</div>
                            </div>
                          }
                        </span>
                      </div>
                    </div>

                  </div>

                </div>
              )
            })}
          </div>


          <div 
            id="speak-button" 
            className={speakButtonClass}
            onClick={()=>selectReviewer(1)}
          >
            <div></div>
          </div>

        </div>

        <div 
          className={`arrow-left${speakButtonClass==='exit' ? ' enter' : ''}`}
          onClick={() => incrementReviewer(-1)}
        >  
        </div>
        <div 
          className={`arrow-right${speakButtonClass==='exit' ? ' enter' : ''}`}
          onClick={() => incrementReviewer(1)}
        >
        </div>

      </div>

    {/*
      <div id="ending" className="bind-animation">
        <div className="bind-animation">
          <div id="end-lion" className="bind-animation"></div>
          <div id="end-stars" className="bind-animation">
            <svg 
              version="1.1" 
              xmlns="http://www.w3.org/2000/svg" 
              xmlnsXlink="http://www.w3.org/1999/xlink" 
              x="0px" y="0px"
              viewBox="0 0 600 800"
            >
                <linearGradient id="SVGID_31_" gradientUnits="userSpaceOnUse" x1="213.9931" y1="445.1666" x2="87.9811" y2="754.1964" gradientTransform="matrix(-0.2727 -0.9621 0.9621 -0.2727 -211.6254 975.8218)">
                  <stop offset="0" style={{stopColor: '#FE7062'}}/>
                  <stop offset="1" style={{stopColor: '#FF928E'}}/>
                </linearGradient>
                <g>
                  <path className="st0" d="M465.73,618.61l11.25,33.34c0.87,2.59,2.97,4.59,5.6,5.34l33.85,9.64c6.63,1.89,8.22,10.56,2.69,14.67
                    l-28.24,21c-2.2,1.63-3.45,4.24-3.35,6.98l1.3,35.17c0.25,6.89-7.5,11.08-13.12,7.09L447,731.48c-2.23-1.58-5.1-1.97-7.67-1.03
                    l-33.05,12.1c-6.47,2.37-12.85-3.71-10.8-10.29l10.5-33.59c0.82-2.61,0.3-5.46-1.39-7.61l-21.72-27.69
                    c-4.25-5.42-0.44-13.37,6.45-13.45l35.19-0.4c2.74-0.03,5.28-1.41,6.81-3.68l19.62-29.21
                    C454.79,610.91,463.52,612.08,465.73,618.61z"/>
                  <path className="st1" d="M482.2,704.67c-0.54,1.76-0.78,3.58-0.71,5.42l0.85,22.97c0.09,2.41-1.18,4.63-3.3,5.78
                    c-2.12,1.15-4.67,0.99-6.64-0.4l-18.75-13.3c-4.39-3.12-10.03-3.87-15.09-2.02l-21.58,7.9c-2.27,0.83-4.76,0.31-6.51-1.35
                    c-1.75-1.67-2.39-4.14-1.67-6.44l3.5-11.21l1.71,0.53l-3.5,11.21c-0.53,1.68-0.07,3.4,1.19,4.61c1.27,1.21,3.01,1.57,4.66,0.97
                    l21.58-7.9c5.61-2.05,11.87-1.22,16.74,2.24l18.75,13.3c1.43,1.02,3.21,1.12,4.75,0.29c1.55-0.83,2.43-2.38,2.36-4.14l-0.84-22.97
                    c-0.08-2.04,0.19-4.06,0.79-6.01L482.2,704.67z"/>
                  <path className="st1" d="M507.43,673.38c0.76,0.85,1.29,1.91,1.51,3.09c0.44,2.38-0.5,4.75-2.44,6.19l-14.11,10.5l-1.07-1.43
                    l14.11-10.49c1.41-1.05,2.06-2.7,1.75-4.43c-0.32-1.73-1.51-3.05-3.2-3.53l-22.1-6.29c-5.68-1.61-10.34-6.05-12.23-11.65
                    l-7.35-21.78c-0.56-1.66-1.94-2.8-3.68-3.03c-1.74-0.23-3.37,0.5-4.34,1.96l-12.82,19.08c-3.33,4.96-8.88,7.96-14.85,8.02
                    l-22.98,0.26c-1.76,0.02-3.26,0.98-4.02,2.56c-0.76,1.58-0.57,3.35,0.52,4.74l14.19,18.09c1.47,1.87,2.54,3.98,3.18,6.27
                    l-1.72,0.48c-0.58-2.06-1.55-3.96-2.87-5.65l-14.19-18.08c-1.49-1.9-1.77-4.43-0.72-6.61c1.04-2.18,3.19-3.55,5.61-3.57l22.98-0.26
                    c5.38-0.06,10.39-2.76,13.39-7.23l12.82-19.08c1.35-2,3.67-3.05,6.06-2.73c2.39,0.32,4.36,1.94,5.13,4.23l7.35,21.78
                    c1.7,5.05,5.9,9.05,11.02,10.5l22.1,6.29C505.64,671.87,506.66,672.52,507.43,673.38z"/>
                </g>
                <g>
                  <path className="st0" d="M357.68,609.26l-13.96,37.54c-1.09,2.92-0.66,6.19,1.13,8.73l23.01,32.78c4.51,6.42-0.3,15.22-8.14,14.89
                    l-40.02-1.67c-3.11-0.13-6.09,1.28-7.96,3.77l-24.06,32.02c-4.71,6.27-14.57,4.41-16.68-3.14l-10.78-38.57
                    c-0.84-3-3.1-5.4-6.04-6.41l-37.88-12.99c-7.42-2.54-8.7-12.49-2.17-16.83l33.36-22.17c2.59-1.72,4.17-4.61,4.22-7.73l0.65-40.04
                    c0.13-7.84,9.19-12.13,15.34-7.26l31.39,24.87c2.44,1.93,5.68,2.54,8.66,1.63l38.29-11.76
                    C353.53,594.62,360.41,601.91,357.68,609.26z"/>
                  <path className="st1" d="M308.95,695.53c-1.74,1.17-3.26,2.61-4.52,4.28l-15.71,20.91c-1.65,2.2-4.36,3.25-7.06,2.74
                    c-2.7-0.51-4.84-2.47-5.58-5.12l-7.04-25.19c-1.65-5.9-6.09-10.61-11.89-12.6l-24.74-8.48c-2.6-0.89-4.43-3.14-4.78-5.86
                    c-0.35-2.73,0.86-5.37,3.14-6.89l11.13-7.4l1.13,1.69l-11.13,7.4c-1.67,1.11-2.5,2.96-2.25,4.93c0.26,1.98,1.54,3.55,3.43,4.2
                    l24.74,8.48c6.43,2.21,11.36,7.43,13.19,13.98l7.04,25.19c0.54,1.92,2.03,3.29,3.99,3.66c1.96,0.37,3.85-0.36,5.05-1.96
                    l15.71-20.91c1.4-1.86,3.08-3.46,5.01-4.75L308.95,695.53z"/>
                  <path className="st1" d="M353.69,685.95c0.07,1.3-0.22,2.61-0.88,3.81c-1.32,2.41-3.84,3.84-6.59,3.72l-19.99-0.83l0.09-2.03
                    l19.99,0.83c2,0.08,3.76-0.91,4.72-2.67c0.96-1.75,0.85-3.78-0.3-5.41l-15.03-21.41c-3.86-5.49-4.8-12.76-2.46-19.06l9.11-24.52
                    c0.69-1.87,0.29-3.86-1.08-5.31c-1.37-1.45-3.33-1.97-5.24-1.38l-25.01,7.68c-6.5,1.99-13.56,0.67-18.88-3.56l-20.5-16.24
                    c-1.57-1.24-3.58-1.47-5.39-0.62c-1.81,0.86-2.9,2.56-2.94,4.56l-0.42,26.16c-0.04,2.71-0.61,5.34-1.68,7.82l-1.87-0.8
                    c0.96-2.24,1.47-4.61,1.51-7.05l0.42-26.15c0.04-2.75,1.61-5.19,4.1-6.36c2.48-1.18,5.37-0.85,7.52,0.86l20.5,16.24
                    c4.8,3.81,11.17,5,17.02,3.21l25.01-7.68c2.63-0.81,5.43-0.07,7.32,1.93c1.89,2,2.46,4.84,1.51,7.42l-9.11,24.52
                    c-2.11,5.68-1.27,12.23,2.22,17.18l15.03,21.41C353.18,683.34,353.62,684.64,353.69,685.95z"/>
                </g>
                <g>
                  <path className="st0" d="M290.36,509.37l-47.67,27.04c-3.71,2.1-6.14,5.9-6.51,10.14l-4.75,54.6c-0.93,10.69-13.72,15.68-21.64,8.44
                    l-40.45-36.98c-3.15-2.88-7.5-4.02-11.66-3.06L104.3,581.9c-10.46,2.42-19.15-8.2-14.71-17.97l22.67-49.89
                    c1.76-3.88,1.5-8.38-0.69-12.03l-28.25-46.96c-5.53-9.2,1.88-20.75,12.55-19.54l54.46,6.14c4.23,0.48,8.43-1.16,11.23-4.38
                    l35.93-41.38c7.04-8.1,20.31-4.62,22.46,5.89l10.99,53.69c0.85,4.18,3.71,7.66,7.63,9.33l50.46,21.39
                    C298.9,490.37,299.69,504.07,290.36,509.37z"/>
                  <path className="st1" d="M163.37,556.84c-2.85-0.31-5.71-0.14-8.5,0.51L120,565.42c-3.66,0.85-7.42-0.43-9.8-3.35
                    c-2.38-2.91-2.89-6.84-1.34-10.27l14.8-32.59c3.47-7.63,2.96-16.48-1.36-23.67l-18.45-30.67c-1.94-3.22-1.88-7.19,0.15-10.36
                    c2.04-3.16,5.62-4.87,9.36-4.45l18.17,2.05l-0.31,2.77l-18.17-2.05c-2.72-0.31-5.22,0.9-6.7,3.19c-1.48,2.3-1.52,5.07-0.11,7.42
                    l18.45,30.67c4.79,7.97,5.36,17.78,1.51,26.25l-14.8,32.59c-1.13,2.49-0.77,5.24,0.96,7.36c1.73,2.12,4.36,3.01,7.02,2.39
                    l34.87-8.07c3.1-0.72,6.27-0.91,9.43-0.56L163.37,556.84z"/>
                  <path className="st1" d="M218.67,586.19c-1.07,1.42-2.53,2.54-4.27,3.22c-3.5,1.37-7.41,0.64-10.18-1.9l-20.21-18.47l1.88-2.05
                    l20.21,18.47c2.02,1.85,4.74,2.35,7.29,1.36c2.55-0.99,4.21-3.21,4.45-5.94l3.1-35.65c0.79-9.15,6.2-17.59,14.21-22.13l31.13-17.66
                    c2.38-1.35,3.7-3.79,3.55-6.52c-0.16-2.73-1.75-5-4.27-6.07l-32.96-13.97c-8.56-3.63-14.79-11.24-16.65-20.35l-7.18-35.07
                    c-0.55-2.68-2.45-4.69-5.1-5.39c-2.65-0.69-5.3,0.13-7.09,2.19L173.1,447.3c-2.43,2.79-5.33,5.05-8.64,6.72l-1.25-2.49
                    c2.98-1.5,5.6-3.54,7.79-6.06l23.47-27.03c2.47-2.84,6.26-4.01,9.89-3.06c3.64,0.95,6.37,3.84,7.12,7.52l7.18,35.07
                    c1.68,8.21,7.29,15.07,15.01,18.34l32.96,13.97c3.46,1.47,5.75,4.71,5.97,8.47c0.21,3.75-1.68,7.24-4.95,9.1l-31.13,17.66
                    c-7.22,4.09-12.09,11.69-12.8,19.95l-3.1,35.65C220.44,583.01,219.76,584.75,218.67,586.19z"/>
                </g>
                <g>
                  <path className="st0" d="M263.1,361.63l-55.54-4.77c-4.32-0.37-8.54,1.41-11.3,4.75l-35.42,43.05c-6.94,8.43-20.53,5.25-23-5.39
                    l-12.63-54.3c-0.98-4.22-3.98-7.69-8.01-9.28l-51.89-20.38c-10.16-3.99-11.33-17.9-1.99-23.54l47.74-28.79
                    c3.71-2.24,6.09-6.16,6.35-10.49l3.35-55.65c0.66-10.9,13.52-16.31,21.77-9.16l42.13,36.51c3.28,2.84,7.74,3.88,11.94,2.79
                    l53.96-14.01c10.57-2.74,19.69,7.82,15.44,17.88l-21.7,51.35c-1.69,3.99-1.3,8.56,1.03,12.22l30,46.99
                    C281.21,350.62,273.98,362.56,263.1,361.63z"/>
                  <path className="st1" d="M129.28,328.31c-2.21-1.9-4.71-3.41-7.42-4.47l-33.88-13.31c-3.56-1.4-5.97-4.64-6.29-8.45
                    c-0.32-3.81,1.52-7.41,4.79-9.38l31.18-18.8c7.3-4.4,11.97-12.12,12.48-20.63l2.19-36.34c0.23-3.82,2.56-7.11,6.09-8.6
                    c3.53-1.48,7.52-0.85,10.41,1.66l14.06,12.18l-1.85,2.14l-14.06-12.18c-2.1-1.82-4.9-2.25-7.46-1.19
                    c-2.56,1.08-4.19,3.38-4.36,6.16l-2.19,36.34c-0.57,9.45-5.75,18-13.85,22.89l-31.18,18.8c-2.38,1.44-3.66,3.95-3.43,6.72
                    c0.23,2.77,1.92,5.03,4.51,6.05l33.88,13.31c3.01,1.18,5.78,2.85,8.23,4.96L129.28,328.31z"/>
                  <path className="st1" d="M158.75,384.77c-1.71,0.57-3.59,0.67-5.44,0.24c-3.73-0.87-6.58-3.73-7.44-7.46l-6.31-27.13l2.76-0.64
                    l6.31,27.13c0.63,2.71,2.62,4.71,5.33,5.34c2.71,0.63,5.38-0.27,7.15-2.42l23.13-28.11c5.94-7.22,15.33-11.18,24.66-10.38
                    l36.27,3.12c2.77,0.23,5.29-1.05,6.73-3.42c1.44-2.38,1.41-5.2-0.09-7.55l-19.59-30.69c-5.09-7.98-5.93-17.94-2.25-26.65
                    l14.17-33.54c1.08-2.56,0.65-5.35-1.17-7.46c-1.82-2.11-4.51-2.94-7.2-2.24l-35.24,9.15c-3.64,0.95-7.38,1.17-11.12,0.65l0.38-2.8
                    c3.36,0.46,6.74,0.26,10.02-0.59l35.24-9.15c3.7-0.96,7.56,0.24,10.06,3.13c2.5,2.9,3.13,6.88,1.64,10.41l-14.17,33.54
                    c-3.32,7.85-2.56,16.84,2.02,24.02l19.59,30.69c2.06,3.22,2.11,7.26,0.13,10.54c-1.98,3.27-5.58,5.1-9.39,4.78l-36.27-3.12
                    c-8.41-0.72-16.88,2.84-22.23,9.36l-23.13,28.11C162.07,383.12,160.49,384.19,158.75,384.77z"/>
                </g>
                <g>    
                  <path className="st0" d="M436.87,331.39l-55.36-45.52c-4.31-3.54-10.08-4.73-15.43-3.19l-68.89,19.8c-13.49,3.88-25.57-9.4-20.44-22.46
                    l26.18-66.72c2.04-5.19,1.39-11.05-1.73-15.67l-40.12-59.4c-7.86-11.63,1.04-27.22,15.05-26.38l71.55,4.29
                    c5.56,0.33,10.94-2.09,14.36-6.49l44.09-56.51c8.63-11.07,26.21-7.43,29.74,6.16l18.03,69.37c1.4,5.39,5.37,9.75,10.61,11.66
                    l67.37,24.47c13.19,4.79,15.16,22.63,3.34,30.19l-60.4,38.59c-4.7,3-7.62,8.12-7.81,13.69l-2.46,71.63
                    C464.07,332.92,447.72,340.31,436.87,331.39z"/>
                  <path className="st1" d="M319.37,198.61c-0.96-3.63-2.51-7.04-4.61-10.14l-26.2-38.79c-2.75-4.08-2.95-9.26-0.51-13.54
                    c2.44-4.27,7-6.74,11.91-6.45l46.73,2.8c10.95,0.66,21.51-4.11,28.26-12.76l28.8-36.91c3.03-3.88,7.9-5.67,12.71-4.67
                    c4.82,1,8.58,4.58,9.82,9.34l6.02,23.15l-3.52,0.92l-6.02-23.15c-0.9-3.47-3.55-5.95-7.03-6.69c-3.5-0.72-6.91,0.53-9.11,3.35
                    l-28.8,36.91c-7.49,9.59-19.2,14.88-31.34,14.16l-46.73-2.8c-3.57-0.21-6.76,1.51-8.53,4.62c-1.77,3.11-1.64,6.73,0.37,9.7
                    l26.2,38.79c2.33,3.44,4.05,7.23,5.11,11.25L319.37,198.61z"/>
                  <path className="st1" d="M309.46,279.88c-2.23-0.64-4.29-1.9-5.93-3.71c-3.31-3.64-4.25-8.74-2.45-13.32l13.08-33.34l3.39,1.33
                    l-13.08,33.34c-1.31,3.33-0.65,6.9,1.76,9.54c2.41,2.65,5.9,3.64,9.34,2.64l44.98-12.93c11.55-3.32,24.39-0.67,33.68,6.97
                    l36.16,29.73c2.77,2.27,6.36,2.74,9.62,1.28c3.26-1.47,5.28-4.49,5.4-8.06l1.61-46.79c0.42-12.16,6.79-23.32,17.04-29.87
                    l39.45-25.21c3.02-1.93,4.58-5.2,4.19-8.75c-0.39-3.56-2.64-6.41-6-7.63l-44-15.98c-4.55-1.65-8.67-4.14-12.25-7.4l2.45-2.69
                    c3.23,2.94,6.94,5.18,11.05,6.67l44,15.98c4.62,1.68,7.83,5.76,8.37,10.65c0.54,4.89-1.7,9.57-5.85,12.22L466,219.77
                    c-9.24,5.9-14.98,15.97-15.36,26.93l-1.6,46.79c-0.17,4.92-3.06,9.23-7.54,11.25c-4.48,2.02-9.63,1.34-13.43-1.78l-36.16-29.73
                    c-8.38-6.89-19.95-9.28-30.36-6.28l-44.99,12.93C314.18,280.55,311.73,280.54,309.46,279.88z"/>
                </g>
            </svg>
          </div>
        </div>
      </div>

      */}
    </div>
  )
}

export default Caregivers;
